import React from "react";

const RunWalkSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Run Walk</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="_1.6_km_run_walk" data-name="1.6 km run/walk">
            <circle className="cls-2" style={{fill: color}} cx="295.85" cy="86.86" r="36.74"/>
            <path className="cls-2" style={{fill: color}}
                  d="M406.48,255.52C357.69,244.76,334,201,318.3,172.11c-4.8-8.83-8.58-15.81-12.65-21.21-13.47-17.89-28.22-12.41-32.42-10.31-61.18,30.68-84.89,71-94,99.38-10.05,31.32-5.21,55.24-4.62,57.87a18.85,18.85,0,0,0,36.79-8.21c-.13-.61-11.26-54.8,52.16-99.55L251.78,313c-9.39,36.81-9.67,37.17-31.83,66-8.43,10.94-36.26,35-61.71,44a18.85,18.85,0,1,0,12.55,35.55c33.25-11.74,66.81-40.67,79-56.54,21.92-28.47,26.41-34.88,33.78-61.64,8.36,10.5,22.24,28.6,36.31,49.75,8.58,12.92,10.73,24.37,17.11,58.37,1,5.36,2.11,11.2,3.34,17.62a18.85,18.85,0,0,0,37-7.1c-1.22-6.36-2.31-12.16-3.31-17.47-6.83-36.38-9.94-53-22.77-72.28a756.05,756.05,0,0,0-45.76-61.68c6-18.71,10.26-42.05,11.57-64.86,0-.78.09-1.56.13-2.36q2.67,3.28,5.54,6.5c21.39,23.9,46.82,39.23,75.58,45.58a18.85,18.85,0,0,0,8.13-36.81Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default RunWalkSvg;
