import React from "react";

const BentKneeSitUpsSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Sit Ups</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="bent_knee_sit_ups" data-name="bent knee sit ups">
            <path className="cls-2" style={{fill: color}}
                  d="M296.36,358.12c9.63-11.57,24.15-29,32-33.49l1-.57,1.07.58c22.74,13.18,47,64.47,57,93.7a22.79,22.79,0,0,0,43.16-14.66,406.76,406.76,0,0,0-21.76-50.44c-19.42-37.64-40.4-61.72-62.38-71.56-11.26-5-29.32-7.21-51.4,11-11.29,9.33-22.17,22.38-33.68,36.2-9.44,11.33-17.75,20.87-25.28,28.61l.21-3.11h0l.05-.64.17-2.46h0l6.65-97.06h0l.14-2,53-3.48h.1A22.92,22.92,0,0,0,319,232.88a23.18,23.18,0,0,0-6-23.4L247.34,140a22.79,22.79,0,0,0-32.27,32.2l34.25,34.4,18.08,16a2,2,0,1,1-2.64,3l-17.45-15.41-38.87-1.52c-.72,0-1.43,0-2.12,0a44.49,44.49,0,0,0-39.18,28.62c-7,18.53-19.83,54.65-26.58,88.31-3.78,18.81-15.26,76,21.49,94.16,18.37,9.06,47.39,14.95,81.31-8.39C263.59,397.44,281,376.55,296.36,358.12Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M240.62,203.54,212.24,175a26.79,26.79,0,0,1,38-37.81L278.32,167a48.32,48.32,0,1,0-37.7,36.59Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default BentKneeSitUpsSvg;
