import React from "react";

const InclinedPullUpsSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Pull Ups</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="inclined_pull_ups" data-name="inclined pull ups">
            <path className="cls-2" style={{fill: color}} d="M42.12,224.51a41.89,41.89,0,1,0,56.74-17,41.89,41.89,0,0,0-56.74,17"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M107.54,308.9" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M264.13,359.08" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M268.89,348.71" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M107.54,308.9" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M243.44,171.17a17.86,17.86,0,0,0-2.57,1.89L97.53,299.34a19,19,0,1,0,25.11,28.5l120.8-106.42Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M267.67,174.76a18.38,18.38,0,0,0-2.41-2.27v29.7l.72-.63a19,19,0,0,0,1.69-26.8Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M254.35,435.75c-4.37,0-7.91-2.14-7.91-4.78V153c0-2.64,3.54-4.78,7.91-4.78s7.91,2.14,7.91,4.78V431C262.26,433.61,258.72,435.75,254.35,435.75Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M94.22,295.59,107.37,284c-1,.2-1.94.41-2.82.64-9.13,2.35-14.07,9.31-15.5,17.4A23.86,23.86,0,0,1,94.22,295.59Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M243.44,309.44l-3-1.72c-18.95-10.87-41.32-17.8-62.78-21.72l-51.75,45.59a23.85,23.85,0,0,1-11.38,5.58c75.42,15.2,87.26,46.4,112.91,58.19a89.89,89.89,0,0,0,16,5.54Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M491.65,395.33,298.4,361.88C295.69,348,284.77,336.26,265.26,323v80.19a50.63,50.63,0,0,0,10.57-1.6l208.49,36.1A22.08,22.08,0,0,0,488,438a21.5,21.5,0,0,0,3.64-42.68Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default InclinedPullUpsSvg;
