import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
// COMPONENTS
import { MyDetailsHeader } from '../../../components/MyDetailsHeader';
import {
    UserDefinedTextField,
    UserDefinedStudentIdTextField,
    RadioFieldsGenderSelection,
    BirthDropdownField
} from '../../../components/FormInputs.js';

// ACTIONS
import {
    setStudentId,
    setMothersHeight,
    setFathersHeight,
    setExpandedMyInfo
} from '../../../store/actions/myDetails.js';

import {
    setUserGender,
    setUserId,
    setUserClassName,
    setUserAddressState,
    setUserAddressCountry
} from '../../../store/actions/actions.js';

// MATERIALUI
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { B2C_ROLE_NAME, BACKEND_URL, ENVIRONMENT_PATH } from '../../../store/constants';

import IM from 'convert-units';

import { setUserUOM } from '../../../store/actions/actions';
import { ParentSliderField } from '../../../components/myDetails/parentHeightSlider';

const MyInfo = ({ myInfoExpanded }) => {
    const dispatch = useDispatch();

    // Redux State
    const state = useSelector(state => state)
    const userState = state.user;
    const infoSectionExpanded = state.myDetails.infoSectionExpanded;
    const [openNotMeDialog, setOpenNotMeDialog] = useState(false);

    const usingB2CAthlete = userState.userRole == B2C_ROLE_NAME;

    const [firstLoading, setFirstLoading] = useState(true); // determines if this is the first time on the page, and not peforming any actions


    // Retrieves the JSON of a user, with the exception of an object being injected into the function
    // If an object is present, it will use the values of that instead for specific properties from the user state
    const retrieveJSONOfUser = (obj) => {
        var jsonRequest = "";
        var userGender = userState.userGender || "unspecified";
        var userFirstName = userState.userId || "";
        var userClassName = userState.userClassName || "";
        var uom = userState.userUOM;

        var motherHeight = uom == "Imperial" ? IM(state.myDetails.mothersHeight).from('in').to('cm') : state.myDetails.mothersHeight;
        var fatherHeight = uom == "Imperial" ? IM(state.myDetails.fathersHeight).from('in').to('cm') : state.myDetails.fathersHeight;

        var userBirthMonth = userState.userBirthMonth;
        var userBirthYear = userState.userBirthYear;

        var userEmail = userState.userEmail;
        var userAddressState = userState.addressState;
        var userAddressCountry = userState.addressCountry;

        if (obj.name == "gender") {
            userGender = obj.value;
        }

        if (obj.name == "mother_height") {
            motherHeight = obj.value;
        }

        if (obj.name == "father_height") {
            fatherHeight = obj.value;
        }

        if (obj.name == "email") userEmail = obj.value;
        if (obj.name == "address_state") userAddressState = obj.value;
        if (obj.name == "address_country") userAddressCountry = obj.value;

        jsonRequest = {
            "user_id": typeof userState.userBackendId !== 'undefined' && userState.userBackendId !== "" ? userState.userBackendId : '2' + "", //field used to update the specific row in the db
            "username": userFirstName + "",
            "user_birth_month": userBirthMonth + "",
            "user_birth_year": userBirthYear + "",
            'gender': userGender + "",
            "user_class_name": userClassName + "",
            "mother_height": motherHeight + "",
            "father_height": fatherHeight + "",
        }

        if (usingB2CAthlete) {
            jsonRequest['user_email'] = userEmail + "";
            jsonRequest['user_address_state'] = userAddressState + "";
            jsonRequest['user_address_country'] = userAddressCountry + "";
        }

        return jsonRequest;
    }

    // Unit of mesurement
    const reduxState = useSelector(state => state);
    const usingImperial = reduxState.user.userUOM == "Imperial";
    const [uom, setUOM] = useState(reduxState.user.userUOM);

    if (firstLoading) {
        setUOM(reduxState.user.userUOM);
        setFirstLoading(false);
    }

    // Functions enabling child components to dispatch actions
    const dispatchUserId = (input) => {
        if (!usingB2CAthlete) {
            if (input == "") {
                dispatch(setUserId("No Name"))
            } else {
                dispatch(setUserId(input))
            }
        } else {
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/UserDetails`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify(retrieveJSONOfUser({ name: 'email', value: input }))
            }).then(res => {
                res.json().then(result => { })
            });
        }
    }

    const dispatchMothersHeight = (input) => {
        var motherHeight = input;

        if (state.user.userUOM == "Imperial") {
            motherHeight = IM(motherHeight).from('in').to('cm');
        }

        dispatch(setMothersHeight(input));

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/UserDetails`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(retrieveJSONOfUser({ name: 'mother_height', value: motherHeight }))
        }).then(res => {
            res.json().then(result => { })
        });
    }

    const dispatchFathersHeight = (input) => {
        var fatherHeight = input;

        if (state.user.userUOM == "Imperial") {
            fatherHeight = IM(fatherHeight).from('in').to('cm');
        }

        dispatch(setFathersHeight(input));

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/UserDetails`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(retrieveJSONOfUser({ name: 'father_height', value: fatherHeight }))
        }).then(res => {
            res.json().then(result => { })
        });
    }

    const dispatchGender = (input) => {
        dispatch(setUserGender(input));

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/UserDetails`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(retrieveJSONOfUser({ name: 'gender', value: input }))
        }).then(res => {
            res.json().then(result => { })
        })
    };

    const dispatchUserClassName = (input) => {
        dispatch(setUserClassName(input));
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const renderNotMeDialog = () => {
        if (openNotMeDialog) {
            return (
                <Dialog
                    open={openNotMeDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => { setOpenNotMeDialog(false) }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"This isn't you?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            If these details are wrong please let your teacher/supervisor know and they will change them for you.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenNotMeDialog(false) }} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }
    };

    const getUOMLabel = () => {
        if (state.user.userUOM == "Imperial") {
            return "in"
        } else {
            return "cm";
        }
    };

    const renderClassSection = () => {
        return (
            <div className="class-info-container">
                <div className="class-info-inputs">
                    <UserDefinedTextField _value={state.user.userOrganisationName} label={"School name"} placeholder={"St. Peters"} disabled={true} />
                    <UserDefinedTextField _value={state.user.userClassName} action={dispatchUserClassName} label={"Class name"} placeholder={"12HB"} disabled={true} />
                </div>

                {renderNotMeDialog()}

                <button onClick={() => { setOpenNotMeDialog(true) }}>Not me</button>
            </div>
        )
    };

    const saveUserAddressDetails = (input, type) => {
        if (type == "address_state") {
            dispatch(setUserAddressState(input));
        }

        if (type == "address_country") {
            dispatch(setUserAddressCountry(input));
        }

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/UserDetails`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(retrieveJSONOfUser({ name: type, value: input }))
        }).then(res => {
            res.json().then(result => { })
        });
    }

    const renderLocationSection = () => {
        return (
            <div className="class-info-container">
                <div className="class-info-inputs">
                    <UserDefinedTextField _value={state.user.addressState} action={(input) => saveUserAddressDetails(input, 'address_state')} label={"State"} placeholder={"State"} disabled={false} />
                    <UserDefinedTextField _value={state.user.addressCountry} action={(input) => saveUserAddressDetails(input, 'address_country')} label={"Country"} placeholder={"Country"} />
                </div>
            </div>
        )
    }

    return (
        <div className="screen-section" >
            <div className={`myDetails-fields expanded`}>
                <UserDefinedStudentIdTextField
                    _value={usingB2CAthlete ? state.user.userEmail : state.user.userId}
                    action={dispatchUserId}
                    label={usingB2CAthlete ? "Email" : "User ID"}
                    placeholder={usingB2CAthlete ? "Email address" : "ID Number"}
                    isB2CAthlete={usingB2CAthlete}
                />
                <RadioFieldsGenderSelection onChange={dispatchGender} genderSelected={state.user.userGender} label={"Gender"} />
                <BirthDropdownField label={""} placeholder={"DD/MM/YYYY"} />
                {!usingB2CAthlete && <>
                    <ParentSliderField
                        action={dispatchMothersHeight}
                        label={"Mother's height (" + getUOMLabel() + ")"}
                        usingUOM={state.user.userUOM}
                        enteredValue={state.myDetails.mothersHeight}
                        minValue={state.user.userUOM == "Imperial" ? IM(100).from('cm').to('in') : 100}
                        maxValue={state.user.userUOM == "Imperial" ? IM(220).from('cm').to('in') : 220}
                    />

                    <ParentSliderField
                        action={dispatchFathersHeight}
                        label={"Fathers's height (" + getUOMLabel() + ")"}
                        usingUOM={state.user.userUOM}
                        enteredValue={state.myDetails.fathersHeight}
                        minValue={state.user.userUOM == "Imperial" ? IM(100).from('cm').to('in') : 100}
                        maxValue={state.user.userUOM == "Imperial" ? IM(220).from('cm').to('in') : 220}
                    />
                </>}

                {usingB2CAthlete ? renderLocationSection() : renderClassSection()}
            </div>
        </div>
    );
}

export default MyInfo;
