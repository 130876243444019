import React, { useState } from 'react';

// MATERIAL UI COMPONENTS
import Button from '@material-ui/core/Button';

// USER DEFINED IMAGES (non-Visited)
import BodyShapeAndSizeImage from '../../../assets/startTestAssets/body_size_and_shape.png';
import AerobicFitnessImage from '../../../assets/startTestAssets/aerobic_fitness.png';
import FlexibilityImage from '../../../assets/startTestAssets/flexibility.png';
import MuscularEnduranceImage from '../../../assets/startTestAssets/muscular_endurance.png';
import MuscularStrengthAndPowerImage from '../../../assets/startTestAssets/muscular_strength_and_power.png';
import SpeedAndAgilityImage from '../../../assets/startTestAssets/speed_and_agility.png';

// USER DEFINED IMAGES (Visited)
import BodyShapeAndSizeVisitedImage from '../../../assets/startTestAssets/visited/body_size_and_shape_visited.png';
import AerobicFitnessVisitedImage from '../../../assets/startTestAssets/visited/aerobic_fitness_visited.png';
import FlexibilityVisitedImage from '../../../assets/startTestAssets/visited/flexibility_visited.png';
import MuscularEnduranceVisitedImage from '../../../assets/startTestAssets/visited/muscular_endurance_visited.png';
import MuscularStrengthAndPowerVisitedImage from '../../../assets/startTestAssets/visited/muscular_strength_and_power_visited.png';
import SpeedAndAgilityVisitedImage from '../../../assets/startTestAssets/visited/speed_and_agility_visited.png';

// USER DEFINED IMAGES (Visited) MOUSE DOWN
import BodyShapeAndSizeVisitedMouseDownImage from '../../../assets/startTestAssets/visitedMouseDown/body_size_and_shape_visited_mousedown.png';
import AerobicFitnessVisitedMouseDownImage from '../../../assets/startTestAssets/visitedMouseDown/aerobic_fitness_visited_mousedown.png';
import FlexibilityVisitedMouseDownImage from '../../../assets/startTestAssets/visitedMouseDown/flexibility_visited_mousedown.png';
import MuscularEnduranceVisitedMouseDownImage from '../../../assets/startTestAssets/visitedMouseDown/muscular_endurance_visited_mousedown.png';
import MuscularStrengthAndPowerVisitedMouseDownImage from '../../../assets/startTestAssets/visitedMouseDown/muscular_strength_and_power_visited_mousedown.png';
import SpeedAndAgilityVisitedMouseDownImage from '../../../assets/startTestAssets/visitedMouseDown/speed_and_agility_visited_mousedown.png';

// SVGs
import BodySizeShapeSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/BodySizeShapeSvg.js';
import AerobicSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/AerobicSvg.js';
import SitReachSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/sitReachSvg.js';
import InclinedPullUpsSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/InclinedPullUpsSvg.js';
import MuscularStrengthSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/MuscularStrengthSvg.js';
import SpeedAgilitySvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/SpeedAgilitySvg.js';

// Right/Left arrow buttons
import rightArrow from '../../../assets/startTestAssets/right.png'
import leftArrow from '../../../assets/startTestAssets/left.png'
import RightArrowSvg from '../../../assets/svg/rightArrowSvg.js'
import LeftArrowSvg from '../../../assets/svg/leftArrowSvg.js'

// REDUX
import { useSelector } from 'react-redux';


import {
    hasBodyShapeAndSizeFilledOut,
    hasFlexibilityFilledOut,
    hasMuscularStrengthAndPowerFilledOut,
    hasMuscularEnduranceFilledOut,
    hasSpeedAndAgilityFilledOut,
    hasAerobicFitnessFilledOut
} from './FilledOutFunctions.js';
import DownloadSVG from "../../../assets/svg/downloadSvg";

const TestNavigationSection = ({
    gotoTestScreen: gotoTestScreen,
    flexibilityVisited: flexibilityVisited,
    muscularStrengthAndPowerVisited: muscularStrengthAndPowerVisited,
    muscularEnduranceVisited: muscularEnduranceVisited,
    speedAgilityVisited: speedAgilityVisited,
    aerobicFitnessVisited: aerobicFitnessVisited,
    bodySizeShapeVisited: bodySizeShapeVisited,
    onPreviousClick: previousButtonHandler,
    onNextClick: nextButtonHandler,
    screenSelection: screenSelection }) => {

    var reduxState = useSelector(state => state);
    const organisationLocalState = useSelector(state => state.organisationState);

    const renderImage = (imageName) => {

        if (imageName === "BodySizeAndShape") {
            if (hasBodyShapeAndSizeFilledOut(reduxState) == "filled-out") {
                if (screenSelection === 1) {
                    return <BodySizeShapeSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                } else {
                    return <BodySizeShapeSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                }
            } else {
                return <BodySizeShapeSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />;
            }
        }

        if (imageName === "Flexibility") {
            if (hasFlexibilityFilledOut(reduxState) == "filled-out") {
                if (screenSelection === 2) {
                    return <SitReachSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                } else {
                    return <SitReachSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                }
            } else {
                return <SitReachSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />;
            }
        }

        if (imageName === "MuscularStrengthAndPower") {
            if (hasMuscularStrengthAndPowerFilledOut(reduxState) == "filled-out") {
                if (screenSelection === 3) {
                    return <MuscularStrengthSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                } else {
                    return <MuscularStrengthSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                }
            } else {
                return <MuscularStrengthSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />;
            }
        }

        if (imageName === "MuscularEnduranceImage") {
            if (hasMuscularEnduranceFilledOut(reduxState) == "filled-out") {
                if (screenSelection === 4) {
                    return <InclinedPullUpsSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                } else {
                    return <InclinedPullUpsSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                }
            } else {
                return <InclinedPullUpsSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />;
            }
        }

        if (imageName === "SpeedAndAgilityImage") {
            if (hasSpeedAndAgilityFilledOut(reduxState) == "filled-out") {
                if (screenSelection === 5) {
                    return <SpeedAgilitySvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                } else {
                    return <SpeedAgilitySvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                }
            } else {
                return <SpeedAgilitySvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />;
            }
        }

        if (imageName === "AerobicFitnessImage") {
            if (hasAerobicFitnessFilledOut(reduxState) == "filled-out") {
                if (screenSelection === 6) {
                    return <AerobicSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                } else {
                    return <AerobicSvg color={organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383'} />;
                }
            } else {
                return <AerobicSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />;
            }
        }
    }

    return (
        <React.Fragment>
            <div className="test-navigation-bar">
                <div style={{fontSize: '100px', color: 'rgb(154, 98, 179)', fontFamily: 'monospace', cursor: 'pointer'}} onClick={() => {
                    previousButtonHandler();
                }}>
                    <LeftArrowSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}/>
                </div>
                <Button style={{
                    background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '',
                    borderRadius: '30px',
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    textTransform: 'capitalize',
                    width: '100px',
                    height: '35px'
                }} onClick={() => gotoTestScreen(0)} color={'primary'} variant={'contained'}>Back</Button>
                <div style={{fontSize: '100px', color: 'rgb(154, 98, 179)', fontFamily: 'monospace', cursor: 'pointer'}} onClick={() => {
                    nextButtonHandler();
                }}>
                    <RightArrowSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}/>
                </div>
            </div>

            <div className={screenSelection === 1 ? "test-navigation-buttons body-size-navigation" : "test-navigation-buttons"}>
                <div>
                    <div style={{boxShadow: screenSelection === 1 ? '-4px -4px #c9c9c9' : '', filter: screenSelection === 1 ? 'brightness(0.95)' : ''}} onClick={() => gotoTestScreen(1)} className={(screenSelection === 1 ? 'selected' : '') + (hasBodyShapeAndSizeFilledOut(reduxState) == "filled-out" ? ' filled-out' : '') + " test-navigation-buttons-icons"}>
                        {renderImage("BodySizeAndShape")}
                    </div>
                </div>
                <div>
                    <div style={{boxShadow: screenSelection === 2 ? '-4px -4px #c9c9c9' : '', filter: screenSelection === 2 ? 'brightness(0.95)' : ''}} onClick={() => gotoTestScreen(2)} className={(screenSelection === 2 ? 'selected' : '') + (hasBodyShapeAndSizeFilledOut(reduxState) == "filled-out" ? ' filled-out' : '') + " test-navigation-buttons-icons"}>
                        {renderImage("Flexibility")}
                    </div>
                </div>
                <div>
                    <div style={{boxShadow: screenSelection === 3 ? '-4px -4px #c9c9c9' : '', filter: screenSelection === 3 ? 'brightness(0.95)' : ''}} onClick={() => gotoTestScreen(3)} className={(screenSelection === 3 ? 'selected' : '') + (hasBodyShapeAndSizeFilledOut(reduxState) == "filled-out" ? ' filled-out' : '') + " test-navigation-buttons-icons"}>
                        {renderImage("MuscularStrengthAndPower")}
                    </div>
                </div>
                <div>
                    <div style={{boxShadow: screenSelection === 4 ? '-4px -4px #c9c9c9' : '', filter: screenSelection === 4 ? 'brightness(0.95)' : ''}} onClick={() => gotoTestScreen(4)} className={(screenSelection === 4 ? 'selected' : '') + (hasBodyShapeAndSizeFilledOut(reduxState) == "filled-out" ? ' filled-out' : '') + " test-navigation-buttons-icons"}>
                        {renderImage("MuscularEnduranceImage")}
                    </div>
                </div>
                <div>
                    <div style={{boxShadow: screenSelection === 5 ? '-4px -4px #c9c9c9' : '', filter: screenSelection === 5 ? 'brightness(0.95)' : ''}} onClick={() => gotoTestScreen(5)} className={(screenSelection === 5 ? 'selected' : '') + (hasBodyShapeAndSizeFilledOut(reduxState) == "filled-out" ? ' filled-out' : '') + " test-navigation-buttons-icons"}>
                        {renderImage("SpeedAndAgilityImage")}
                    </div>
                </div>
                <div>
                    <div style={{boxShadow: screenSelection === 6 ? '-4px -4px #c9c9c9' : '', filter: screenSelection === 6 ? 'brightness(0.95)' : ''}} onClick={() => gotoTestScreen(6)} className={(screenSelection === 6 ? 'selected' : '') + (hasBodyShapeAndSizeFilledOut(reduxState) == "filled-out" ? ' filled-out' : '') + " test-navigation-buttons-icons"}>
                        {renderImage("AerobicFitnessImage")}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TestNavigationSection;
