// REACT
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './style.css';

const FilterByComponent = ({ onChange = null }) => {
    const organisationLocalState = useSelector(state => state.organisationState);

    const [organisationSelectedSportFilterStatus, setOrganisationSelectedSportFilterStatus] = useState(true);
    const [allFilterStatus, setAllFilterStatus] = useState(false);

    const radioOnClickHandler = name => {
        if(name == "all") {
            onChange("all");
            setOrganisationSelectedSportFilterStatus(false);
            setAllFilterStatus(true);
        } else {
            onChange("organisation");
            setOrganisationSelectedSportFilterStatus(true);
            setAllFilterStatus(false); 
        }
    }

    return (
        <div className="filter-by-talent-id-container">
            <span className="filter-by-talent-id-heading">Filter by:</span>

            <div className="radio-button-container">
                <div style={{position: 'relative'}}>
                    <input onChange={() => radioOnClickHandler("")} style={{visibility: "hidden"}} id="organisation-selected-sports-filter-radio" type="radio" name="filter-by-talent-id-group" />
                    <label onClick={() => radioOnClickHandler("")} for="sort-by-all" className={"sort-by-radio-visible" + (organisationSelectedSportFilterStatus ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: organisationSelectedSportFilterStatus ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : ''}}></label>
                    <label for="organisation-selected-sports-filter-radio">Organisation selected sports</label>
                </div>
                <div style={{position: 'relative'}}>
                    <input onChange={() => radioOnClickHandler("all")} style={{visibility: "hidden"}} id="all-filter-radio" type="radio" name="filter-by-talent-id-group" />
                    <label onClick={() => radioOnClickHandler("all")} for="sort-by-all" className={"sort-by-radio-visible" + (allFilterStatus ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: allFilterStatus ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : ''}}></label>
                    <label for="all-filter-radio">All</label>
                </div>
            </div>
        </div>
    )
};

export default FilterByComponent;