// REACTJS
import React, { useEffect, useState } from 'react';

// IMAGE
import DownloadImage from '../../../../../../assets/download.png';
import DownloadSVG from '../../../../../../assets/svg/downloadSvg.js';

import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../../../store/constants';

import GroupReport from '../../../groupReport/GroupReport';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux';

import { CheckIfUserSessionIsValid } from '../../../../../../api/Helper';
import { GetGroupReport } from '../../../../../../api/endpoints';

import { CircularProgress } from '@material-ui/core';
import Axios from 'axios';

let groupDownloadCancelToken = Axios.CancelToken.source();

const GroupRow = ({ headerIcons, anthropometryicons, radarRef, groupName, groupCount }) => {
    const reduxState = useSelector(state => state);
    const organisationLocalState = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    const [loadingGroupReport, setLoadingGroupReport] = useState(false);

    const dispatch = useDispatch();

    // React Toast Notifications Object
    const { addToast } = useToasts();

    const cancelRequests = () => {
        groupDownloadCancelToken.cancel();
        groupDownloadCancelToken = Axios.CancelToken.source();
        setLoadingGroupReport(false);
    }

    useEffect(() => {
        cancelRequests();
    }, [
        reduxState.organisationState.genderFilters, 
        JSON.stringify(reduxState.organisationState.groupNameFilters), 
        reduxState.organisationState.dateRangeFromFilter, 
        reduxState.organisationState.dateRangeToFilter, 
        JSON.stringify(reduxState.organisationState.ageFilters), 
    ]);

    const downloadGroupReportPDF = () => {
        if (groupName) {
            cancelRequests();

            setLoadingGroupReport(true);

            var params = new URLSearchParams();
            params.append("using_org_sports", true);

            if (groupName != "") params.append("group", groupName);

            GetGroupReport(params, groupDownloadCancelToken).then(res => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    if (Object.keys(res.data.averages).length > 0) {
                        GroupReport(organisationLocalState, res.data, headerIcons, anthropometryicons, radarRef, customCallback, userState.userUOM == "Imperial");
                        addToast("Retrieving group data - please wait", {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    } else {
                        addToast("No test data associated with group", {
                            appearance: 'error',
                            autoDismiss: true
                        });
                        setLoadingGroupReport(false);
                    }
                })
            }).catch(error => {
                console.log("An error occured: ", error);
            });
        }
    };

    const customCallback = () => {
        setLoadingGroupReport(false);
    }

    return (
        <div className="row data-row">
            <div className="col class-name">
                <span>{groupName}</span>
            </div>
            <div className="col member-count">
                <span>{groupCount} {groupCount > 1 ? "members" : "member"}</span>
            </div>
            <div className="col image-column">
                <div className="download-icon" onClick={() => downloadGroupReportPDF()} height={22}>
                    <DownloadSVG color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'} />
                </div>
                &ensp;
                {loadingGroupReport ? <CircularProgress disableShrink={true} size={20} /> : null}
            </div>
        </div>
    )
}

export default GroupRow;
