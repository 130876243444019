// REACT
import React, { useState, useEffect } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import Results from '../dashboard/components/results/index.js';
import FitnessAgeComponent from '../dashboard/components/fitnessAgeComponent/index.js';
import TalentIDComponent from '../dashboard/components/talentIDComponent/index.js';
import Top5SportsComponent from '../dashboard/components/top5Sports/index.js';
import SportTypesComponent from '../dashboard/components/sportTypes/index.js';
import ClassInfoScreen from '../classInfo/index.js';

// MOMENTJS
import moment from 'moment';

// STYLES
import './style.css';

// CONSTANTS
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../store/constants';

// FILTER COMPONENT
import Filter from '../filter/index.js';
import { CheckIfUserSessionIsValid } from '../../../../api/Helper.js';
import Axios from 'axios';

let compareResultsSource = Axios.CancelToken.source();
let fitnessAgeSource = Axios.CancelToken.source();
let talentIDSource = Axios.CancelToken.source();

const ResultsOverviewComponent = () => {
    const dispatch = useDispatch();

    const organisationStateLocal = useSelector(state => state.organisationState);

    const [age, setAgeAverage] = useState(0);
    const [fitnessAgeAverage, setFitnessAgeAverage] = useState(0);
    const [difference, setDifference] = useState(0);

    const [top5Sports, setTop5Sports] = useState([]);
    const [finishedCollectingSportTypes, setFinishedCollectingSportTypes] = useState(false);

    // Top sport type variables
    const [powerSportTypeRank, setPowerSportTypeRank] = useState(1);
    const [speedSportTypeRank, setSpeedSportTypeRank] = useState(1);
    const [aerobicSportTypeRank, setAerobicSportTypeRank] = useState(1);
    const [gameFitnessSportTypeRank, setGameFitnessSportTypeRank] = useState(1);
    const [strengthSportTypeRank, setStrengthSportTypeRank] = useState(1);
    const [skillSportTypeRank, setSkillSportTypeRank] = useState(1);
    const [compareResults, setCompareResults] = useState([]);

    // Loading Indicators
    const [topSportsTypesLoading, setTopSportsTypesLoading] = useState(true);
    const [compareResultsLoading, setCompareResultsLoading] = useState(true);
    const [fitnessAgeLoading, setFitnessAgeLoading] = useState(true);

    const controller = useSelector(state => state.user.fetchController);
    const signal = controller.signal;

    const [modeDisplay, setModeDisplay] = useState("graph");

    const [talentIDData, setTalentIDData] = useState([]);

    const [topSportTypesFromEndpoint, setTopSportTypesFromEndpoint] = useState([]);

    const generateFilterString = (isNew) => {
        // Checking if filters have been applied
        var filters = "";
        if (organisationStateLocal.genderFilters.length != 0) {
            if (isNew) {
                filters += "?gender=" + organisationStateLocal.genderFilters;
            } else {
                filters += "&gender=" + organisationStateLocal.genderFilters;
            }
        }
        if (organisationStateLocal.groupNameFilters.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
                } else {
                    filters += "?groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
                }
            } else {
                filters += "&groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
            }
        }
        if (organisationStateLocal.ageFilters.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
                } else {
                    filters += "?ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
                }
            } else {
                filters += "&ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
            }
        }
        if (organisationStateLocal.dateRangeFromFilter.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
                } else {
                    filters += "?startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
                }
            } else {
                filters += "&startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
            }
        }
        if (organisationStateLocal.dateRangeToFilter.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
                } else {
                    filters += "?endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
                }
            } else {
                filters += "&endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
            }
        }

        return filters;
    }

    // Work out Ranking of each sport type 
    const getSportRankings = (data) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].name == "powerScore") {
                setPowerSportTypeRank(data[i].value);
            }
        }

        for (var i = 0; i < data.length; i++) {
            if (data[i].name == "skillScore") {
                setSkillSportTypeRank(data[i].value);
            }
        }
        for (var i = 0; i < data.length; i++) {
            if (data[i].name == "aerobicFitnessScore") {
                setAerobicSportTypeRank(data[i].value);
            }
        }

        for (var i = 0; i < data.length; i++) {
            if (data[i].name == "strengthScore") {
                setStrengthSportTypeRank(data[i].value);
            }
        }

        for (var i = 0; i < data.length; i++) {
            if (data[i].name == "gameFitnessScore") {
                setGameFitnessSportTypeRank(data[i].value);
            }
        }

        for (var i = 0; i < data.length; i++) {
            if (data[i].name == "speedScore") {
                setSpeedSportTypeRank(data[i].value);
            }
        }
    };

    const renderFitnessAgeComponent = () => {
        return new Promise((resolve, reject) => {
            var requestString = "fitness_age";
            requestString = encodeURIComponent(requestString);

            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=dashboard_actions&dashboard_actions=${requestString}${generateFilterString(false)}`, {
                withCredentials: true,
                cancelToken: fitnessAgeSource.token
            }).then(res => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    if (res.data.fitness_age) {
                        setAgeAverage(res.data.fitness_age.actual_age);
                        setFitnessAgeAverage(res.data.fitness_age.fitness_age);
                        setDifference(res.data.fitness_age.difference)
                    }

                    setFitnessAgeLoading(false);
                    resolve();
                })
            }).catch(error => {
                if (Axios.isCancel(error)) console.log("An error occured");
            });
        })
    };

    const renderCompareResults = () => {
        return new Promise((resolve, reject) => {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=dashboard_actions&dashboard_actions=compare_results${generateFilterString(false)}`, {
                withCredentials: true,
                cancelToken: compareResultsSource.token
            }).then(res => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    if (res.data.compare_results) {
                        setCompareResults(res.data.compare_results);
                        setCompareResultsLoading(false);
                    }
                    setCompareResultsLoading(false);
                    resolve();
                })
            }).catch(error => {
                if (Axios.isCancel(error)) console.log("An error occured");
            });
        })
    }

    const renderResultsOverviewPage = () => {
        setTopSportsTypesLoading(true);
        setCompareResultsLoading(true);
        setFitnessAgeLoading(true);

        compareResultsSource.cancel();
        compareResultsSource = Axios.CancelToken.source();

        fitnessAgeSource.cancel();
        fitnessAgeSource = Axios.CancelToken.source();

        talentIDSource.cancel();
        talentIDSource = Axios.CancelToken.source();

        renderCompareResults().then(() => {
            renderFitnessAgeComponent().then(() => {
                if (generateFilterString(false) == "") {
                    Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/gettiofast?pageSize=20&pageNumber=0&using_org_sports=true`, {
                        withCredentials: true,
                        cancelToken: talentIDSource.token
                    }).then(res => {
                        if (typeof res.data != 'undefined' && typeof res.data.talent_ids != 'undefined' && res.data.talent_ids.length == 0) {
                            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentID?save_sports_to_db=true${generateFilterString(false)}`, {
                                withCredentials: true,
                                cancelToken: talentIDSource.token
                            }).then(res1 => {
                                CheckIfUserSessionIsValid(dispatch).then(() => {
                                    var resultData = [];

                                    for (var i = 0; i < res1.data.length; i++) resultData.push(res1.data[i]);

                                    setTalentIDData(resultData);

                                    setFinishedCollectingSportTypes(true);
                                })
                            }).catch(error => {
                                if(Axios.isCancel(error)) {
                                    setFinishedCollectingSportTypes(true);
                                }
                            });
                        } else if (res.data.result.length != 0 && res.data.result.toLowerCase() != 'success') {
                            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentID?save_sports_to_db=true${generateFilterString(false)}`, {
                                withCredentials: true,
                                cancelToken: talentIDSource.token
                            }).then(res1 => {
                                CheckIfUserSessionIsValid(dispatch).then(() => {
                                    var resultData = [];

                                    for (var i = 0; i < res1.data.length; i++) resultData.push(res1.data[i]);

                                    setTalentIDData(resultData);

                                    setFinishedCollectingSportTypes(true);
                                })
                            }).catch(error => {
                                if(Axios.isCancel(error)) {
                                    setFinishedCollectingSportTypes(true);
                                }
                            })
                        } else {
                            var resultData = [];

                            for (var i = 0; i < res.data.talent_ids.length; i++) {
                                var talentIdObj = res.data.talent_ids[i];
                                talentIdObj.sport = talentIdObj.sport.split(",");
                                talentIdObj.sport_name = talentIdObj.sport[0];
                                resultData.push(talentIdObj);
                            }

                            setTalentIDData(resultData);

                            setFinishedCollectingSportTypes(true);
                        }
                    }).catch(error => {
                        if (Axios.isCancel(error)) console.log("An error occured");
                    });
                } else {
                    Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentID${generateFilterString(true)}&using_org_sports=true`, {
                        withCredentials: true,
                        cancelToken: talentIDSource.token
                    }).then(res => {
                        CheckIfUserSessionIsValid(dispatch).then(() => {
                            var resultData = [];

                            for (var i = 0; i < res.data.length; i++) {
                                resultData.push(res.data[i]);
                            }
                            setTalentIDData(resultData);

                            setFinishedCollectingSportTypes(true);
                        })
                    }).catch((error) => {
                        if(Axios.isCancel(error)) {
                            setFinishedCollectingSportTypes(true);
                        }
                    });
                }
            })
        })
    }

    useEffect(() => {
        getSportRankings(topSportTypesFromEndpoint);
    }, [topSportTypesFromEndpoint]);

    useEffect(() => {
        renderResultsOverviewPage();
    }, [
        JSON.stringify(organisationStateLocal.groupNameFilters),
        JSON.stringify(organisationStateLocal.ageFilters),
        organisationStateLocal.genderFilters,
        organisationStateLocal.dateRangeFromFilter,
        organisationStateLocal.dateRangeToFilter
    ])

    return (
        <div className={modeDisplay == "graph" ? "result-overview-container" : "result-overview-container list-view"}>
            <Filter />
            <div className="graph-column">
                <Results loading={compareResultsLoading} data={compareResults} modeDisplay={modeDisplay} setModeDisplay={setModeDisplay} />
                <div className="column-bottom" style={{ display: modeDisplay != "graph" ? "none" : "flex" }}>
                    <Top5SportsComponent
                        loading={topSportsTypesLoading}
                        data={top5Sports}
                        setData={(e) => setTop5Sports(e)}
                        includeTitle={true} />
                    <SportTypesComponent
                        data={top5Sports}
                        powerSportTypeRank={powerSportTypeRank}
                        speedSportTypeRank={speedSportTypeRank}
                        aerobicSportTypeRank={aerobicSportTypeRank}
                        gameFitnessSportTypeRank={gameFitnessSportTypeRank}
                        strengthSportTypeRank={strengthSportTypeRank}
                        skillSportTypeRank={skillSportTypeRank}
                        loading={topSportsTypesLoading}
                    />
                </div>
                <ClassInfoScreen disableUserEditing={true} modeDisplay={modeDisplay} type={"students"} setModeDisplay={setModeDisplay} />
            </div>
            <div className="data-column">
                <FitnessAgeComponent
                    loading={fitnessAgeLoading}
                    age={age}
                    fitnessAgeAverage={fitnessAgeAverage}
                    difference={difference}
                />
                <TalentIDComponent
                    isFinishedLoading={finishedCollectingSportTypes}
                    data={talentIDData}
                    finishedCollectingSportTypes={finishedCollectingSportTypes}
                />
            </div>
        </div>
    )
}

export default ResultsOverviewComponent;