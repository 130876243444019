import React from "react";

const SittingHeight5Svg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>sitting_height_5</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="sitting_height_5" data-name="sitting height 5">
            <circle className="cls-2" style={{fill: color}} cx="297.59" cy="91.39" r="38.59"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M306.79,325.58" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M296.41,327.28" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M408.21,354.78H384.83v-301h23.38a5,5,0,0,0,0-10H351.46a5,5,0,0,0,0,10h23.37v301H351.46a5,5,0,0,0,0,10h56.75a5,5,0,1,0,0-10Z"
                  transform="translate(-2.83 -7.46)"/>
            <rect className="cls-2" style={{fill: color}} x="195.7" y="360.12" width="143.95" height="120.41" rx="18.85" ry="18.85"/>
            <path className="cls-2" style={{fill: color}}
                  d="M278.82,156.08,197.11,304.6c-4.7,8.53-1.39,19.93,7.36,25.41a17.9,17.9,0,0,0,14.1,2.35,15.92,15.92,0,0,0,10.06-7.83L310.34,176c4.7-8.53,1.39-19.92-7.36-25.4a18.46,18.46,0,0,0-9.8-2.9,16.91,16.91,0,0,0-4.3.55A15.92,15.92,0,0,0,278.82,156.08Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M332.44,303c.14-28.31-22.2-52.19-3.57-129.29,5.34-22.1-22.9-43.39-38.63-28.76a21.09,21.09,0,0,1,14.33,3.15c10.08,6.31,13.85,19.49,8.4,29.39l-58.31,106a291.61,291.61,0,0,0,4.6,33.95H236L231.26,326a19,19,0,0,1-11.93,9.29,20.29,20.29,0,0,1-5.06.64,21.45,21.45,0,0,1-11.39-3.36,23.32,23.32,0,0,1-10.45-15.16H171.89a22.5,22.5,0,0,0-22.5,22.5V451.33a22.5,22.5,0,1,0,45,0V362.39h95.27c1,0,2,0,2.9,0h.52c.49,0,1,0,1.44-.05l.25,0a22.29,22.29,0,0,0,4.66-.84,34.73,34.73,0,0,0,18.47-11.09h0a42,42,0,0,0,3.86-5c6.89-10.39,10.49-24.87,10.68-38.18h-.11C332.4,305.8,332.44,304.37,332.44,303Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default SittingHeight5Svg;
