import React from "react";

const StandingBroadJumpSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Standing Broad Jump</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="standing_broad_jump" data-name="standing broad jump">
            <circle className="cls-2" style={{fill: color}} cx="289.03" cy="112.54" r="43.06"/>
            <path className="cls-2" style={{fill: color}}
                  d="M456,240.34,266.46,160a22.09,22.09,0,0,0-24.09,4.57,641.36,641.36,0,0,0-45,49.89c-43.54,54-46,79.56-44.1,94.1,1.71,12.94,9.7,30.08,38.06,38.16,14.45,4.13,32,5.42,49,6.68,13.25,1,26.95,2,37.79,4.27a67,67,0,0,1,9,2.48C279.83,367,262.73,380,223.31,400c-33.41,16.95-65.5,30.06-65.94,30.24A22.09,22.09,0,1,0,174,471.15c1.46-.59,36.15-14.76,72.48-33.4,71.11-36.48,84.6-57,88.41-72.3a32.81,32.81,0,0,0-6.71-29.66c-18-21.59-51.86-24.08-84.62-26.5l-5.41-.4,52.39-90.74L438.78,281A22.09,22.09,0,1,0,456,240.34Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default StandingBroadJumpSvg;
