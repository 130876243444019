import React from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

import { Card, CardActions, CardContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import {
    changeScreen
} from '../../../store/actions/actions.js';

import styled from 'styled-components';

/** Required to show the dialog appearing from the bottom of the screen */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TestMessageBegin = () => {
    return (
        <Card>
            <CardContent>
                <h2>Click on one of the icons to start!</h2>
            </CardContent>
        </Card>
    );
};



export const TestMessageCard = ({ showDialog, toggleHandler }) => {
    const dispatch = useDispatch();

    const organisationState = useSelector(state => state.organisationState);

    const BeginButton = styled.button`
        background-color: ${ organisationState.organisationPrimaryColor != "" ? organisationState.organisationPrimaryColor + " !important" : "#9962B1" }
    `;

    return (
        <Dialog open={showDialog} TransitionComponent={Transition} keepMounted onClose={null}>
            <Card className="first-time-making-test-card">
                <CardContent>
                    <span className="card-body-text-span">
                        You are about to start a SportMatch test.<br/>
                        You cannot change your test results once they have been submitted.<br/>
                        To update your SportMatch detail with new results, you must begin a new test.
                    </span>

                    <div className="card-button-container">
                        <button style={{marginLeft: 'auto', width: '80px'}} onClick={() => dispatch(changeScreen(1))}>Back</button>
                        <BeginButton onClick={() => toggleHandler()} className="begin-btn">Begin</BeginButton>
                    </div>
                </CardContent>
            </Card>
        </Dialog>
    );
}