import { CHANGE_SELECTED_NORM } from '../constants';

const initialState = {
    selectedNorm: parseInt(localStorage.getItem("selectedNorm")) || 0
}

export const compareMyResultsReducer = (state=initialState, action={}) => {
    switch(action.type) {
        case CHANGE_SELECTED_NORM:
            localStorage.setItem("selectedNorm", action.payload);
            return {...state, selectedNorm: action.payload};
        default:
            return state;
    }
}