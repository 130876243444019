// REACTJS
import React, { useRef, useEffect } from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLES
import './style.css';

// ACTIONS
import { changeScreen } from '../../../../../../store/actions/actions.js';

import GroupRow from './GroupRowComponent.js';
import GroupReportResources from '../../../groupReport/GroupReportResources';
import { CircularProgress } from '@material-ui/core';

const GroupListComponent = ({ loading, userGroups }) => {
    const dispatch = useDispatch();

    const organisationStateLocal = useSelector(state => state.organisationState);

    // Header images for Group report
    const sitAndReachImage = useRef(null);
    const standingBroadJumpImage = useRef(null);
    const bentKneeSitUpsImage = useRef(null);
    const gripStrengthImage = useRef(null);
    const verticalJumpImage = useRef(null);
    const inclinedPullUpsImage = useRef(null);
    const shuttleRunImage = useRef(null);
    const sprintSpeedImage = useRef(null);
    const walkRunImage = useRef(null);
    const beepTestImage = useRef(null);

    // Anthropometry Images
    const heightImage = useRef(null);
    const weightImage = useRef(null);
    const armSpanImage = useRef(null);
    const brachialIndexImage = useRef(null);
    const sittingHeightImage = useRef(null);

    // Radar ref
    const radarRef = useRef(null);

    const calTotalMember = () => {
        let total = 0;
        for (var j = 0; j < userGroups.length; j++) {
            total += userGroups[j].group_count;
        }
        return total;
    }

    const renderGroups = () => {
        var dom = [];
        for (var i = 0; i < userGroups.length; i++) {
            var groupObj = userGroups[i];
            dom.push(
                <GroupRow
                    headerIcons={[
                        sitAndReachImage,
                        standingBroadJumpImage,
                        bentKneeSitUpsImage,
                        gripStrengthImage,
                        verticalJumpImage,
                        inclinedPullUpsImage,
                        shuttleRunImage,
                        sprintSpeedImage,
                        walkRunImage,
                        beepTestImage
                    ]}
                    anthropometryicons={[
                        heightImage,
                        weightImage,
                        armSpanImage,
                        sittingHeightImage,
                        brachialIndexImage
                    ]}
                    radarRef={radarRef}
                    groupName={groupObj.group_name}
                    groupCount={groupObj.group_count}
                />
            )
        }

        return dom;
    }

    return (
        <div className="segment-container segment-small group-list-segment">
            <div className="row heading-row">
                <span className="group-list-span">Group list</span>
                {organisationStateLocal.plan != "Starter" ?
                    <>
                        {loading ? <CircularProgress disableShrink={true} size={20} /> : null}
                        <span className="plus-symbol" style={{ display: 'none', color: organisationStateLocal.organisationPrimaryColor ? organisationStateLocal.organisationPrimaryColor : '' }}>+</span>
                        <span>{calTotalMember()} members</span>

                        <button onClick={() => {
                            dispatch(changeScreen(4));
                        }} className="view-btn">View</button>
                    </>
                : null}
            </div>
            {organisationStateLocal.plan != "Starter" ?
                <>
                    {renderGroups()}

                    {/* Loads in the header images for the PDF */}
                    <GroupReportResources
                        anthropometryIcons={[
                            heightImage,
                            weightImage,
                            armSpanImage,
                            brachialIndexImage,
                            sittingHeightImage
                        ]}
                        headerImageReferrences={[
                            sitAndReachImage,
                            standingBroadJumpImage,
                            bentKneeSitUpsImage,
                            gripStrengthImage,
                            verticalJumpImage,
                            inclinedPullUpsImage,
                            shuttleRunImage,
                            sprintSpeedImage,
                            walkRunImage,
                            beepTestImage
                        ]}
                        loaded={true}
                        radarRef={radarRef}
                    />
                </>
                :
                <>
                    <div className="not-available-in-package-container">
                        <h2>Not available in package</h2>
                    </div>
                </>}
        </div>
    )
}

export default GroupListComponent;
