import React from "react";

const ArmSpanSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>arm_span_3</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="arm_span_3" data-name="arm span 3">
            <path className="cls-2" style={{fill: color}}
                  d="M306.07,130.31a41.61,41.61,0,0,0-22.14-6.4,42.22,42.22,0,0,0-9.45,1.08,41.86,41.86,0,0,0,9.36,82.66A41.92,41.92,0,0,0,319.32,188a41.57,41.57,0,0,0,5.33-31.59h0A41.62,41.62,0,0,0,306.07,130.31Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M498.38,220H69.32a22.5,22.5,0,1,0,0,45H230V378.61a53.89,53.89,0,0,0,53.89,53.89h0a53.89,53.89,0,0,0,53.89-53.89V265H498.38a22.5,22.5,0,0,0,0-45Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M506.41,160.79a5,5,0,0,0-5,5v13.62H327.65a46.18,46.18,0,0,1-4.51,10H501.41V203a5,5,0,1,0,10,0V165.79A5,5,0,0,0,506.41,160.79Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M240.07,179.41H65.52V165.79a5,5,0,1,0-10,0V203a5,5,0,1,0,10,0V189.41h179A45.14,45.14,0,0,1,240.07,179.41Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default ArmSpanSvg;
