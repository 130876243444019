// REACTJS
import React, { useEffect, useState } from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// COUNT UP
import CountUp from 'react-countup';

// JQUERY
import $ from 'jquery';

// STYLE
import './style.css';

// ACTIONS
import { changeScreen } from '../../../../../../store/actions/actions.js';
import {Card, CardContent, CircularProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Slide from "@material-ui/core/Slide/Slide";
import { MARKETING_DOMAIN } from '../../../../../../store/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RemainingTestsComponent = ({ data }) => {
    const dispatch = useDispatch();

    const [guageLevel, setGuageLevel] = useState(0);
    const [productNum, setProductNum] = useState(505);
    const [numberOfTestsAvailable, setNumberOfTestsAvailable] = useState(0);
    const [numberOfTestsAvailableDisplay, setNumberOfTestsAvailableDisplay] = useState(0);

    const [maximumAllowedTests, setMaxmimAllowedTests] = useState(0);

    const reduxState = useSelector(state => state);
    const user = useSelector(state => state.user);

    const [contactYourOrganisationPopupOpen, setContactYourOrganisationPopupOpen] = useState(false);

    // Redux
    const organisationLocalState = useSelector(state => state.organisationState);
    //Fuel End Line
    function setCircleTo(target, percent) {
        var path = target.find('.line').get(0);
        if (path) {
            var pathLen = path.getTotalLength();
            var adjustedLen = percent * pathLen / 100;
            path.setAttribute('stroke-dasharray', adjustedLen + ' ' + pathLen);
        }
    }

    //Fuel End Dot & Needle
    function setLevelTo(target, percent) {
        var needle = target.find('.needle line').get(0);
        if (needle) {
            var rotation = percent * 180 / 100;
            needle.setAttribute('transform', 'rotate(' + rotation + ')');
        }
    }

    function setGauge(target, level, fuelEnd) {
        setCircleTo(target, fuelEnd);
        setLevelTo(target, level);
    }

    useEffect(() => {
        setGauge($('#gauge-id'), 0, 0);
        if ($('#gauge-id').length != 0) {
            setTimeout(() => {
                setGauge($('#gauge-id'), guageLevel, guageLevel);
                var i = 0;
                var interval = setInterval(() => {
                    i++;
                    if (i > numberOfTestsAvailable) {
                        clearInterval(interval);
                    } else {
                        setNumberOfTestsAvailableDisplay(i);
                    }
                }, 5);
            }, 10);
        }
    }, [guageLevel]);

    useEffect(() => {
        if (data != null) {
            if (data.tests_available != null) {
                setMaxmimAllowedTests(data.tests_available);
            }
            if (data.tests_used != null && data.tests_available != null) {

                if (data.tests_used > data.tests_available) {
                    setNumberOfTestsAvailable(data.tests_available);
                } else {
                    setNumberOfTestsAvailable(data.tests_used);
                }
            }

            if (data.tests_available != null && data.tests_used != null) {
                var guageLevelLocal = ((data.tests_available - data.tests_used) / data.tests_available) * 100;

                if (guageLevelLocal > 100) {
                    guageLevelLocal = 100;
                }
                if (guageLevelLocal < 0) {
                    guageLevelLocal = 0;
                }

                setGuageLevel(guageLevelLocal);
            }
            // setting product id for buy more tests buttons, numbers will have to be updated if products are updated
            let num,plan;
            switch (data.pricing_id){
                case 1: num = 505; break;
                case 2: num = 506; break;
                case 3: num = 507; break;
                default: num = 505;
            }
            setProductNum(num)
        }
    }, [data])

    const renderBuyMoreTests = () => {
        if (user.userRole !== 'teacher') {
            return (
                <div className="button-container">
                    <a type="submit" style={{textDecoration:'none',background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : ''}} href={`${MARKETING_DOMAIN}remote-login/?product=${productNum}&user=${reduxState.user.userId}`} className="button">Buy more tests</a>
                </div>
                )
        }
        else {
            return (
                <div className="button-container">
                    <a type="submit" style={{textDecoration:'none',background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : ''}} onClick={() => {
                        setContactYourOrganisationPopupOpen(true)}} className="button">Buy more tests</a>
                </div>
                )
        }
    }

    return (
        <div className="segment-container segment-small remaining-tests-segment">
            <div className="remaining-tests-statistics">
                <span>Remaining tests: </span>
                {!data ? <CircularProgress size={20} disableShrink /> : null}
                <span className="numbers"
                    style={{ color: organisationLocalState.organisationAccentColor ? organisationLocalState.organisationAccentColor : '' }}
                >
                    <CountUp delay={2} start={0} end={maximumAllowedTests - numberOfTestsAvailable} duration={1.5} /> / {maximumAllowedTests}
                </span>
            </div>
            <div className="fuel-guage-container">
                <svg width={200} xmlns="http://www.w3.org/2000/svg" version="1.1" class="gauge" viewBox="4 -10 155 75" enable-background="new 0 0 135 65" data-start="89" data-end="23" id="gauge-id">
                    <linearGradient id="e" x1="0" y1="210" x2="150" y2="210" gradientUnits="userSpaceOnUse">
                        <stop stop-color={organisationLocalState.organisationAccentColor ? organisationLocalState.organisationAccentColor : "#50b383"} offset="0" />
                        <stop stop-color={organisationLocalState.organisationPrimaryColor ? organisationLocalState.organisationPrimaryColor : "#9a62b3"} offset="1" />
                    </linearGradient>
                    <g>
                        <path class="line2" fill="none" stroke="#e6f0ff" stroke-width="20" stroke-linecap="butt" d="M131.3,58.1 c0-27.6-22.4-50-50-50s-50,22.4-50,50" transform="scale(-1,1) translate(-162.7,0)" />
                        <path class="line" fill="none" stroke="url(#e)" stroke-width="20" stroke-linecap="butt" d="M131.3,58.1 c0-27.6-22.4-50-50-50s-50,22.4-50,50" transform="scale(-1,1) translate(-162.7,0)" />
                    </g>
                    <g class="needle" style={{transform: 'translate(77.5px, 55px) rotate(180deg)'}}>
                        <line  fill="none" stroke="#778599" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="60.2" y1="0" x2="0" y2="0" />
                    </g>
                </svg>
            </div>
            <div className="button-container">
                <button onClick={() => {
                    dispatch(changeScreen(6));
                }} className="sportMatchButton" style={{ background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '' }}>Plan details</button>
                <button onClick={() => {
                    dispatch(changeScreen(4));
                }} className="sportMatchButton" style={{ background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '' }}>Allocate tests</button>
            </div>

            {renderBuyMoreTests()}

            <Dialog className="contact-your-organisation-popup" open={contactYourOrganisationPopupOpen} TransitionComponent={Transition} keepMounted onClose={null}>
                <Card>
                    <CardContent>
                        <div className="card-body">
                            <span>
                                Please contact your Organisation admin.
                            </span>
                        </div>

                        <div className="card-button-container">
                            <Button variant="contained" onClick={() => {
                                setContactYourOrganisationPopupOpen(false);
                            }}>
                                Close
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Dialog>

        </div>
    )
}

export default RemainingTestsComponent;
