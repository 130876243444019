export const chartResults = {
    datasets: [
        {
            label: 'Seperator',
            lineTension: 0.1,
            borderColor: '#757575',
            borderWidth: 1,
            showLabel: false,
            fill: false,
            showLine: true,
            pointBorderColor: 'rgba(0,0,0,0)',
            pointBackgroundColor: 'rgba(0,0,0,0)',
            data: [
              { x: 5, y: 5 },
              { x: 20, y: 20 },
            ]
        },
        {
            label: 'Results',
            fill: false,
            showLine: true,
            pointBackgroundColor: '#9962B1',
            pointBorderColor: '#9962B1',
            pointBorderWidth: 0,
            pointHoverBackgroundColor: '#9962B1',
            pointHoverBorderColor: '#9962B1',
            pointHoverBorderWidth: 2,
            pointHitRadius: 10,
            data: [
                // populate with component state
            ]
        }
    ]
};

export const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    layout: {
        padding: {
            top: 20,
            right: 20,
            bottom: 5,
            left: 5,
        },
        width: 500
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                max: 20,
                min: 5,
                stepSize: 5,
            },
            scaleLabel: {
                display: true,
                fontSize: 16,
                fontColor: '#000',
                padding: 10,
                labelString: 'Fitness Age (years)'
            },
        }],
        xAxes: [{
            ticks: {
                beginAtZero: true,
                max: 20,
                min: 5,
                stepSize: 5,
            },
            scaleLabel: {
                display: true,
                fontSize: 16,
                fontColor: '#000',
                padding: 10,
                labelString: 'Actual Age (years)'
            },
        }],
    },
    tooltips: {
        callbacks: {
            label: (item, data) => {
                return [`Actual age: ${item.label}`,`Fitness age: ${item.value}`];
            },
        },
        displayColors: false,
        xPadding: 10,
        yPadding: 10,
        bodyFontFamily: 'Alegreya Sans Black',
        bodyFontSize: 16,
        bodyFontColor: 'dimgrey',
        backgroundColor: '#FFFFFF88',
        borderColor: 'dimgrey',
        borderWidth: 1
    }
};
