export const YOYOLEVEL1VALUES = [
    "5-1",
    "9-1",
    "11-1",
    "11-2",
    "12-1",
    "12-2",
    "12-3",
    "13-1",
    "13-2",
    "13-3",
    "13-4",
    "14-1",
    "14-2",
    "14-3",
    "14-4",
    "14-5",
    "14-6",
    "14-7",
    "14-8",
    "15-1",
    "15-2",
    "15-3",
    "15-4",
    "15-5",
    "15-6",
    "15-7",
    "15-8",
    "16-1",
    "16-2",
    "16-3",
    "16-4",
    "16-5",
    "16-6",
    "16-7",
    "16-8",
    "17-1",
    "17-2",
    "17-3",
    "17-4",
    "17-5",
    "17-6",
    "17-7",
    "17-8",
    "18-1",
    "18-2",
    "18-3",
    "18-4",
    "18-5",
    "18-6",
    "18-7",
    "18-8",
    "19-1",
    "19-2",
    "19-3",
    "19-4",
    "19-5",
    "19-6",
    "19-7",
    "19-8",
    "20-1",
    "20-2",
    "20-3",
    "20-4",
    "20-5",
    "20-6",
    "20-7",
    "20-8",
    "21-1",
    "21-2",
    "21-3",
    "21-4",
    "21-5",
    "21-6",
    "21-7",
    "21-8",
    "22-1",
    "22-2",
    "22-3",
    "22-4",
    "22-5",
    "22-6",
    "22-7",
    "22-8",
    "23-1",
    "23-2",
    "23-3",
    "23-4",
    "23-5",
    "23-6",
    "23-7",
    "23-8",
];

export const YOYOLEVEL2VALUES = [
    "11-1",
    "15-1",
    "17-1",
    "17-2",
    "18-1",
    "18-2",
    "18-3",
    "19-1",
    "19-2",
    "19-3",
    "19-4",
    "20-1",
    "20-2",
    "20-3",
    "20-4",
    "20-5",
    "20-6",
    "20-7",
    "20-8",
    "21-1",
    "21-2",
    "21-3",
    "21-4",
    "21-5",
    "21-6",
    "21-7",
    "21-8",
    "22-1",
    "22-2",
    "22-3",
    "22-4",
    "22-5",
    "22-6",
    "22-7",
    "22-8",
    "23-1",
    "23-2",
    "23-3",
    "23-4",
    "23-5",
    "23-6",
    "23-7",
    "23-8",
    "24-1",
    "24-2",
    "24-3",
    "24-4",
    "24-5",
    "24-6",
    "24-7",
    "24-8",
    "25-1",
    "25-2",
    "25-3",
    "25-4",
    "25-5",
    "25-6",
    "25-7",
    "25-8",
    "26-1",
    "26-2",
    "26-3",
    "26-4",
    "26-5",
    "26-6",
    "26-7",
    "26-8",
    "27-1",
    "27-2",
    "27-3",
    "27-4",
    "27-5",
    "27-6",
    "27-7",
    "27-8",
    "28-1",
    "28-2",
    "28-3",
    "28-4",
    "28-5",
    "28-6",
    "28-7",
    "28-8",
    "29-1",
    "29-2",
    "29-3",
    "29-4",
    "29-5",
    "29-6",
    "29-7",
    "29-8",
];