import React, { useState, useEffect } from 'react';

// COMPONENTS
import { DownloadButton } from '../components/Buttons.js';
import { useSelector } from 'react-redux';
import { QasReport } from '../../src/containers/screens/reports/QasReport/QasReportResource';

import { QAS_ORG_NAME } from '../store/constants';

export const ReportTable = (props) => {
    const state = useSelector(state => state);
    
    // Redux state
    const reduxState = useSelector(state => state);

    // Local state variables
    const [headerImages, setHeaderImages] = useState([]);
    const [anthropometryIcons, setAnthropometryIcons] = useState([]);

    useEffect(() => {
        setHeaderImages(props.localHeaderImages);
    }, [props.localHeaderImages]);

    useEffect(() => {
        setAnthropometryIcons(props.anthropometryImages);
    }, [props.anthropometryImages])


    if (props.data.length !== 0) {
        const reportDataset = props.userReports;
        const testData = props.data;
        const headerElement = React.createRef();
        const bodyElement = React.createRef();
        const scrollbarElement = React.createRef();

        /**
            * renderDownloadButtons: Renders the buttons on the screen (on the far-right of tbe Results table)
            * @param {int} rows the number of buttons to render on the screen (one per row)
        */
        const renderDownloadButtons = (rows) => {
            var elem = [];

            console.log(state.user);
            
            if (props.loaded) {
                for (var i = 0; i < props.data.length; i++) {

                    if (state.user.userOrganisationName == QAS_ORG_NAME) {
                        elem.push(
                            <QasReport test={props.data[i]} title={'Download report'} />
                        )
                    } else {
                        elem.push(
                            <DownloadButton
                                loaded={props.loaded}
                                isHeaderButton={false}
                                title={'Download report'}
                                link={''}
                                test={props.data[i]}
                                _className={"download-button-side"}
                                headerImages={headerImages}
                                anthropometryImages={anthropometryIcons}
                                showNameInTitle={true}
                            />
                        )
                    }
                }
            }


            return elem;
        }

        const renderTable = (rows, elems) => {

            var noOfRows = 0;

            var rowElements = [];

            for (var i = 0; i < rows; i++) {
                noOfRows = i;
                rowElements.push(<div>{renderElements(elems, noOfRows)}</div>);
            }

            return rowElements;
        }

        const renderElements = (elems, noOfRows) => {
            var elemElements = [];
            for (var x = 2; x < elems; x++) {
                var noOfElems = x;
                elemElements.push(<div className="data-elem"><input type="text" value={reportDataset[noOfRows][noOfElems].value} /></div>);
            }

            return elemElements;
        }

        const reportTableBodyScorllHandler = (e) => {
            headerElement.current.scrollLeft = e.target.scrollLeft;
            bodyElement.current.scrollLeft = e.target.scrollLeft;
        }

        const leftArrowScrollbarClickHandler = () => {
            scrollbarElement.current.scrollLeft -= 20;
            headerElement.current.scrollLeft = scrollbarElement.current.scrollLeft;
            bodyElement.current.scrollLeft = scrollbarElement.current.scrollLeft;
        }

        const rightArrowScrollbarClickHandler = () => {
            scrollbarElement.current.scrollLeft += 20;
            headerElement.current.scrollLeft = scrollbarElement.current.scrollLeft;
            bodyElement.current.scrollLeft = scrollbarElement.current.scrollLeft;
        }

        return (
            <React.Fragment>
                <div className="reports-container">
                    <div className="report-table">
                        {/** Table Header */}
                        <div className="report-table-header" ref={headerElement}>
                            <span>Test date</span>
                            <span>Height</span>
                            <span>Weight</span>
                            <span>Sit &amp; reach</span>
                            <span>Standing broad jump</span>
                            <span>Grip strength</span>
                            <span>Vertical Jump</span>
                            <span>Sit-ups (1 min)</span>
                            <span>Inclined pull-ups (30 sec)</span>
                            <span>{reduxState.user.userUOM == "Imperial" ? "4 x 11 shuttle" : "4 x 10 shuttle"}</span>
                            <span>Sprint Speed</span>
                            <span>{reduxState.user.userUOM == "Imperial" ? "1 mile run/walk" : "1.6 km run/walk"}</span>
                            <span>Aerobic fitness (mL/kg/min)</span>
                            <span>Arm Span</span>
                            <span>Forearm length</span>
                            <span>Upperarm length</span>
                            <span>Sitting Height</span>
                        </div>
                        <div className="report-table-body" onScroll={reportTableBodyScorllHandler} ref={bodyElement}>
                            {renderTable(props.userReports.length, 19)}
                        </div>
                    </div>

                    <div className="download-report-section">
                        {renderDownloadButtons(2)}
                    </div>
                </div>

                <div className="scroll-bar-external-container" onScroll={reportTableBodyScorllHandler}>
                    <button className="left-button" onClick={() => leftArrowScrollbarClickHandler()}></button>
                    <button className="right-button" onClick={() => rightArrowScrollbarClickHandler()}></button>
                    <div className="scroll-bar-external" ref={scrollbarElement}>
                        <div></div>
                    </div>
                </div>
            </React.Fragment>
        )
    } else {
        return null;
    }
}