import React, { useEffect, useState, useRef, createRef } from 'react';
import { BACKEND_URL, ENVIRONMENT_PATH } from '../../../../store/constants';

import './style.css';

// SHEETJS
import XLSX from "xlsx";
import { CircularProgress, Switch, FormControlLabel } from '@material-ui/core';

import moment from 'moment';
import RedoIcon from '../../../../assets/RedoIcon';

export const TalentIDSuperUser = () => {
    // References
    const sportSelectorRef = createRef(null);
    const orgaisationSelectorRef = createRef(null);
    const organisationTypeSelectorRef = createRef(null);
    const countrySelectorRef = createRef(null);
    const stateSelectorRef = createRef(null);
    const organisationSearchRef = createRef(null);
    const sportSearchRef = createRef(null);

    // Search related state variables
    const [sportSearchTerm, setSportSearchTerm] = useState("");
    const [organisationSearchTerm, setOrganisationSearchTerm] = useState("");

    // Organisation and Sport data
    const [sports, setSports] = useState([]);
    const [organisations, setOrganisations] = useState([]);

    // Loading state variable
    const [loading, setLoading] = useState(false);

    // Filters (-1 implies that the filter has not been used)
    const [minimumRangeOverlap, setMinimumRangeOverlap] = useState(-1);
    const [maximumRangeOverlap, setMaximumRangeOverlap] = useState(-1);

    const [topTestTakersFilter, setTopTestTakersFilter] = useState(-1);

    const [testDateStartFilter, setTestDateStartFilter] = useState(-1);
    const [testDateEndFilter, setTestDateEndFilter] = useState(-1);

    const [ageFilter, setAgeFilter] = useState([]);
    const [genderFilter, setGenderFilter] = useState([]);

    const [organisationIdFilter, setOrganisationIdFilter] = useState(-1);
    const [organisationTypeFilter, setOrganisationTypeFilter] = useState(-1);

    const [countryFilter, setCountryFilter] = useState(-1);
    const [stateFilter, setStateFilter] = useState(-1);
    const [postcodeFilter, setPostcodeFilter] = useState(-1);

    // Use as sportname|gender,sportname|gender, ...
    const [sportFilter, setSportFilter] = useState(-1);

    const [verifiedFilter, setVerifiedFilter] = useState(false);

    // Filter conditional checks to see if values have been changed in the given areas
    let userFilterDirty = ageFilter.length != 0 || genderFilter.length != 0;
    let sportFilterDirty = sportFilter != -1 || sportSearchTerm != "";
    let organisationFilterDirty = organisationIdFilter != -1 || organisationTypeFilter != -1 || organisationSearchTerm != "";
    let countryFilterDirty = countryFilter != -1 || stateFilter != -1 || postcodeFilter != -1;
    let otherFilterDirty = minimumRangeOverlap != -1 || maximumRangeOverlap != -1 || topTestTakersFilter != -1 || verifiedFilter;

    // Resets all given filters back to defaults
    const resetAll = () => {
        resetUserSection();
        resetOrganisationSection();
        resetCountrySection();
        resetSportSection();
        resetOtherSection();
    };

    // Resets all given fields for the "User filter" group
    const resetUserSection = () => {
        setAgeFilter([]);
        setGenderFilter([]);
    };

    // Resets all given fields for the "Sport filter" group
    const resetSportSection = () => {
        setSportFilter(-1);

        setSportSearchTerm("");
        sportSearchRef.current.value = "";
        sportSelectorRef.current.value = null;
    };

    // Resets all given fields for the "Organisation filter" group
    const resetOrganisationSection = () => {
        setOrganisationIdFilter(-1);
        setOrganisationTypeFilter(-1);

        setOrganisationSearchTerm("");
        organisationSearchRef.current.value = "";
        orgaisationSelectorRef.current.value = null;
        organisationTypeSelectorRef.current.value = null;
    };

    // Resets all given fields for the "Country filter" group
    const resetCountrySection = () => {
        setCountryFilter(-1);
        setStateFilter(-1);
        setPostcodeFilter(-1);

        countrySelectorRef.current.value = null;
        stateSelectorRef.current.value = null;
    };

    // Resets all given fields for the "Other filter" group
    const resetOtherSection = () => {
        setVerifiedFilter(false);
        setMinimumRangeOverlap(-1);
        setMaximumRangeOverlap(-1);
        setTopTestTakersFilter(-1);
        setTestDateStartFilter(-1);
        setTestDateEndFilter(-1);
    };

    /**
     * generateFilterString: generates a filter for the report generation functionality
     */
    const generateFilterString = () => {
        var jsonObject = {};

        // Overlap range
        if (minimumRangeOverlap != -1 && maximumRangeOverlap != -1) {
            jsonObject.overlap_1 = minimumRangeOverlap;
            jsonObject.overlap_2 = maximumRangeOverlap;
        }

        // Top test takers
        if (topTestTakersFilter != -1)
            jsonObject.top_test_takers = topTestTakersFilter;

        // Test date
        if (testDateStartFilter != -1 && testDateEndFilter != -1) {
            jsonObject.test_date_start = moment(testDateStartFilter).format("DD/MM/YYYY");
            jsonObject.test_date_end = moment(testDateEndFilter).format("DD/MM/YYYY");
        }

        // Ages
        if (ageFilter.length !== 0) jsonObject.ages = ageFilter;

        // Gender
        if (genderFilter.length !== 0) jsonObject.genders = genderFilter;

        // Organisation
        if (organisationIdFilter != -1) jsonObject.organisations = organisationIdFilter;

        // Organisation type
        if (organisationTypeFilter != -1) jsonObject.organisation_types = organisationTypeFilter;

        // Country
        if (countryFilter != -1) jsonObject.countries = countryFilter;

        // State
        if (stateFilter != -1) jsonObject.states = stateFilter;

        // Postcode
        if (postcodeFilter != -1) jsonObject.postcode = [postcodeFilter.map(e => e)];

        // Sport
        if (sportFilter != -1) jsonObject.sports = sportFilter;

        // Verified
        if (verifiedFilter) jsonObject.verified = verifiedFilter + "";

        return jsonObject;
    }

    useEffect(() => {
        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/EliteAthleteProfilesServlet`, {
            credentials: 'include'
        }).then(res => {
            res.json().then(result => {
                setSports(result);
            }).catch(er => console.log("error = ", er));
        });

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/getallorganisations?sortByAlphabetical=true&sortAtoZ=true`, {
            credentials: 'include'
        }).then(res => {
            res.json().then(result => {
                setOrganisations(result);
            })
        })
    }, []);

    const renderStates = (country) => {
        var states = [];

        if (country == "Australia" || true) {
            states.push("South Australia");
            states.push("Western Australia");
            states.push("Queensland");
            states.push("New South Wales");
            states.push("Northern Territory");
            states.push("Victoria");
            states.push("Tasmaina");
        }

        return states;
    }

    const onAgeSelection = (age, checked) => {
        var selectedAges = ageFilter;

        if (!checked) {
            selectedAges = selectedAges.filter(function (e) { return e !== age })
            setAgeFilter(selectedAges);
        } else {
            setAgeFilter([...ageFilter, age])
        }
    }

    const onGenderSelection = (gender, checked) => {
        var selectedGenders = genderFilter;

        if (!checked) {
            selectedGenders = selectedGenders.filter(function (e) { return e !== gender })
            setGenderFilter(selectedGenders);
        } else {
            setGenderFilter([...genderFilter, gender])
        }
    }

    const isValidNumerical = (data) => {
        var allowedToConvert = true;

        var charactersNotAllowedToFormat = ["-"];

        charactersNotAllowedToFormat.forEach(e => {
            if(data.indexOf(e) != -1) allowedToConvert = false;
        });

        return (allowedToConvert && !isNaN(parseFloat(data)));
    }

    /**
     * Generates the report for Super User TalentID
     */
    const generateReport = () => {
        setLoading(true);

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/talent_ids_super_admin`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(generateFilterString())
        }).then(res => {
            res.text().then(result => {
                setLoading(false);

                var rows = result.split("\r\n");
                var rowSplitted;

                var importData = [];

                for (var i = 0; i < rows.length; i++) {
                    rowSplitted = rows[i].split(",");

                    rowSplitted.forEach((e, index) => {
                        if(isValidNumerical(e)) {
                            rowSplitted[index] = parseFloat(e);
                        }
                    });

                    importData.push(rowSplitted);
                }

                var wb = XLSX.utils.book_new(); // Instantiate a new workbook
                wb.SheetNames.push("class_import");

                var ws = XLSX.utils.aoa_to_sheet(importData);
                wb.Sheets["class_import"] = ws;

                XLSX.writeFile(wb, "Export class data (csv) from Results overview screen.xlsx");

            })
        }).catch(() => { })
    }

    return (
        <div className="screen-section admin-screen-section">
            <div className="talent-id-super-user-container">
                <div className="talent-id-filter-container">
                    <div className="talent-id-filter-heading">
                        <span>Filter by</span>
                    </div>
                    <div className="talent-id-filter-body">
                        <div className="talent-id-row">
                            <div className="talent-id-filter-individual-container">
                                <span>User filters</span>

                                <div className="redo-btn-container">
                                    <RedoIcon resetBtnHandler={() => resetUserSection()} fieldChanged={userFilterDirty} size={30} color={'#9a62b3'} />
                                </div>

                                <div>
                                    {/* Age filter */}
                                    <div className="talent-id-filter-component">
                                        <span className="filter-component-heading">Age</span>

                                        <div className="filter-component-body">
                                            <div>
                                                <input checked={ageFilter.includes("8")} onClick={(e) => onAgeSelection("8", e.target.checked)} type="checkbox" />
                                                <label>8</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("9")} onClick={(e) => onAgeSelection("9", e.target.checked)} type="checkbox" />
                                                <label>9</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("10")} onClick={(e) => onAgeSelection("10", e.target.checked)} type="checkbox" />
                                                <label>10</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("11")} onClick={(e) => onAgeSelection("11", e.target.checked)} type="checkbox" />
                                                <label>11</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("12")} onClick={(e) => onAgeSelection("12", e.target.checked)} type="checkbox" />
                                                <label>12</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("13")} onClick={(e) => onAgeSelection("13", e.target.checked)} type="checkbox" />
                                                <label>13</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("14")} onClick={(e) => onAgeSelection("14", e.target.checked)} type="checkbox" />
                                                <label>14</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("15")} onClick={(e) => onAgeSelection("15", e.target.checked)} type="checkbox" />
                                                <label>15</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("16")} onClick={(e) => onAgeSelection("16", e.target.checked)} type="checkbox" />
                                                <label>16</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("17")} onClick={(e) => onAgeSelection("17", e.target.checked)} type="checkbox" />
                                                <label>17</label>
                                            </div>

                                            <div>
                                                <input checked={ageFilter.includes("18")} onClick={(e) => onAgeSelection("18", e.target.checked)} type="checkbox" />
                                                <label>18</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Gender filter */}
                                    <div className="talent-id-filter-component" style={{ margin: '0 55px 0 25px' }}>
                                        <span className="filter-component-heading">Gender</span>
                                        <div className="filter-component-body">
                                            <div>
                                                <input checked={genderFilter.includes("male")} name="gender-filters" type="checkbox" onClick={(e) => onGenderSelection('male', e.target.checked)} />
                                                <label>Male</label>
                                            </div>

                                            <div>
                                                <input checked={genderFilter.includes("female")} name="gender-filters" type="checkbox" onClick={(e) => onGenderSelection('female', e.target.checked)} />
                                                <label>Female</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="talent-id-filter-individual-container">
                                <span>Sport filters</span>

                                <div className="redo-btn-container">
                                    <RedoIcon resetBtnHandler={() => resetSportSection()} fieldChanged={sportFilterDirty} size={30} color={'#9a62b3'} />
                                </div>

                                <div>
                                    {/* Sport selection filter */}
                                    <div className="talent-id-filter-component">
                                        <span className="filter-component-heading">Sport Selection</span>

                                        <div style={{ display: 'flex', margin: '5px' }}>
                                            <label for="sport-search">Search:&nbsp;</label>
                                            <input ref={sportSearchRef} onChange={(e) => setSportSearchTerm(e.target.value)} value={sportSearchTerm} style={{ width: '100%', maxWidth: '200px' }} id="sport-search" type="text" />
                                        </div>

                                        <select ref={sportSelectorRef} onChange={(e) => {
                                            let selectedSports = Array.from(
                                                e.target.selectedOptions,
                                                option => {
                                                    return (option.getAttribute("data-sport-name") + "|" + option.getAttribute("data-gender"))
                                                }
                                            );

                                            if (selectedSports.length == 0) {
                                                setSportFilter(-1);
                                            } else {
                                                setSportFilter(selectedSports);
                                            }
                                        }} className="large-selection" multiple>
                                            {sports.map(e => {
                                                if (e.sport_name.toLowerCase().includes(sportSearchTerm.toLowerCase())) {
                                                    return <option data-sport-name={`${e.sport_name}`} data-gender={`${e.sport_gender}`}>{e.sport_name} ({e.sport_gender})</option>
                                                } else if (sportSearchTerm == "") {
                                                    return <option data-sport-name={`${e.sport_name}`} data-gender={`${e.sport_gender}`}>{e.sport_name} ({e.sport_gender})</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="talent-id-filter-individual-container">
                                <span>Organisation filters</span>

                                <div className="redo-btn-container">
                                    <RedoIcon resetBtnHandler={() => resetOrganisationSection()} fieldChanged={organisationFilterDirty} size={30} color={'#9a62b3'} />
                                </div>

                                <div>
                                    {/* Organisation selection filter */}
                                    <div className="talent-id-filter-component">
                                        <span className="filter-component-heading">Organisation Selection</span>

                                        <div style={{ display: 'flex', margin: '5px' }}>
                                            <label for="organisation-search">Search:&nbsp;</label>
                                            <input ref={organisationSearchRef} onChange={(e) => setOrganisationSearchTerm(e.target.value)} style={{ width: '100%', maxWidth: '200px' }} id="organisation-search" type="text" />
                                        </div>

                                        <select ref={orgaisationSelectorRef} onChange={(e) => {
                                            let selectedOrganisations = Array.from(e.target.selectedOptions, option => option.getAttribute("data-id"));

                                            if (selectedOrganisations.length == 0) {
                                                setOrganisationIdFilter(-1);
                                            } else {
                                                let previouslySelectedOrganisation = organisationIdFilter != -1 ? organisationIdFilter : [];
                                                setOrganisationIdFilter([...previouslySelectedOrganisation, ...selectedOrganisations]);
                                            }
                                            
                                        }} className="large-selection" multiple>
                                            {organisations.map(e => {
                                                const ifSelected = (id) => organisationIdFilter != -1 && organisationIdFilter.find(item => item == id) !== undefined;

                                                if (e.name) {
                                                    if (e.name.toLowerCase().includes(organisationSearchTerm.toLowerCase())) {
                                                        return <option data-id={e.organisation_id} {...{selected: ifSelected(e.organisation_id)}}>{e.name}</option>
                                                    } else if (organisationSearchTerm == "") {
                                                        return <option data-id={e.organisation_id} {...{selected: ifSelected(e.organisation_id)}}>{e.name}</option>
                                                    }
                                                } else if (organisationSearchTerm == "") {
                                                    return <option data-id={e.organisation_id} {...{selected: ifSelected(e.organisation_id)}}>{e.name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>

                                    {/* Organisation type filter */}
                                    <div className="talent-id-filter-component">
                                        <span className="filter-component-heading">Organisation type</span>
                                        <select ref={organisationTypeSelectorRef} onChange={(e) => {
                                            let selectedTypes = Array.from(e.target.selectedOptions, option => option.value);

                                            if (selectedTypes.length == 0) {
                                                setOrganisationTypeFilter(-1);
                                            } else {
                                                setOrganisationTypeFilter(selectedTypes);
                                            }
                                        }} className="large-selection" multiple>
                                            <option>Independent school</option>
                                            <option>Government school</option>
                                            <option>Tertiary institution</option>
                                            <option>Sports institute/training centre</option>
                                            <option>Gym/fitness centre</option>
                                            <option>Private testing consultancy</option>
                                            <option>Sports club</option>
                                            <option>Government department</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="talent-id-row">

                            <div className="talent-id-filter-individual-container">
                                <span>Country filters</span>

                                <div className="redo-btn-container">
                                    <RedoIcon resetBtnHandler={() => resetCountrySection()} fieldChanged={countryFilterDirty} size={30} color={'#9a62b3'} />
                                </div>

                                <div>
                                    {/* Country filter */}
                                    <div className="talent-id-filter-component">
                                        <span className="filter-component-heading">Country</span>
                                        <select ref={countrySelectorRef} onChange={(e) => {
                                            let selectedCountries = Array.from(e.target.selectedOptions, option => option.value);

                                            if (selectedCountries.length == 0) {
                                                setCountryFilter(-1);
                                            } else {
                                                setCountryFilter(selectedCountries);
                                            }
                                        }} className="large-selection" multiple>
                                            <option>Australia</option>
                                        </select>
                                    </div>

                                    {/* State filter */}
                                    <div className="talent-id-filter-component">
                                        <span className="filter-component-heading">State</span>

                                        <select ref={stateSelectorRef} onChange={(e) => {
                                            let selectedStates = Array.from(e.target.selectedOptions, option => option.value);

                                            if (selectedStates.length == 0) {
                                                setStateFilter(-1);
                                            } else {
                                                setStateFilter(selectedStates);
                                            }
                                        }} className="large-selection" multiple>
                                            {renderStates().map((e) => <option>{e}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="talent-id-filter-individual-container">
                                <span>Other filters</span>

                                <div className="redo-btn-container">
                                    <RedoIcon resetBtnHandler={() => resetOtherSection()} fieldChanged={otherFilterDirty} size={30} color={'#9a62b3'} />
                                </div>


                                <div>
                                    {/* Overlap '%' range filter */}
                                    <div className="talent-id-filter-component">
                                        <span className="filter-component-heading">Overlap % Range</span>
                                        <div className="overlap-range-container">
                                            <label>Minimum: {minimumRangeOverlap == -1 ? "50" : minimumRangeOverlap}</label>
                                            <input className={minimumRangeOverlap == -1 ? "initial-status" : ""} value={minimumRangeOverlap == -1 ? 50 : minimumRangeOverlap} onChange={(e) => setMinimumRangeOverlap(e.target.value)} type="range" min="0" max="100" style={{ background: "linear-gradient(to right, " + (minimumRangeOverlap == -1 ? "#a9a9a9" :"#007bff")+" " + (minimumRangeOverlap == -1 ? 50 : minimumRangeOverlap) + "%, rgba(213, 213, 213,0.3) " + (parseInt(minimumRangeOverlap == -1 ? 50 : minimumRangeOverlap)+1) + "%,  rgba(213, 213, 213,0.3))"}}/>
                                        </div>
                                        <div className="overlap-range-container">
                                            <label>Maximum: {maximumRangeOverlap == -1 ? "50" : maximumRangeOverlap}</label>
                                            <input className={maximumRangeOverlap == -1 ? "initial-status" : ""} value={maximumRangeOverlap == -1 ? 50 : maximumRangeOverlap} onChange={(e) => setMaximumRangeOverlap(e.target.value)} type="range" min="0" max="100" style={{ background: "linear-gradient(to right," + (maximumRangeOverlap == -1 ? "#a9a9a9" : "#007bff") +" " + (maximumRangeOverlap == -1 ? 50 : maximumRangeOverlap) + "%, rgba(213, 213, 213,0.3) " + (parseInt(maximumRangeOverlap == -1 ? 50 : maximumRangeOverlap) + 1) + "%,  rgba(213, 213, 213,0.3))" }}/>
                                        </div>
                                    </div>

                                    {/* State filter */}
                                    <div className="talent-id-filter-component" style={{ justifyContent: 'center', marginBottom: '30px' }}>
                                        <span className="filter-component-heading">Sort by</span>
                                        <select onChange={(e) => {
                                            var targetValue = e.target.selectedOptions[0].getAttribute("data-top-no");
                                            setTopTestTakersFilter(targetValue);
                                        }} style={{ padding: '5px', fontSize: '14px', margin: '3px', }}>
                                            <option selected={topTestTakersFilter == -1} data-top-no={"-1"}>All</option>
                                            <option selected={topTestTakersFilter == 10} data-top-no={"10"}>Top 10</option>
                                            <option selected={topTestTakersFilter == 25} data-top-no={"25"}>Top 25</option>
                                            <option selected={topTestTakersFilter == 50} data-top-no={"50"}>Top 50</option>
                                            <option selected={topTestTakersFilter == 100} data-top-no={"100"}>Top 100</option>
                                        </select>
                                    </div>

                                    {/* Test date filters */}
                                    <div className="talent-id-filter-component">
                                        <span className="filter-component-heading">Test Date</span>
                                        <div className="test-date-container-filter">
                                            <label>From date</label>
                                            <input value={testDateStartFilter != -1 ? testDateStartFilter : ""} onChange={(e) => setTestDateStartFilter(e.target.value)} type="date" />
                                        </div>
                                        <div className="test-date-container-filter">
                                            <label>To date</label>
                                            <input value={testDateEndFilter != -1 ? testDateEndFilter : ""} onChange={(e) => setTestDateEndFilter(e.target.value)} type="date" />
                                        </div>
                                    </div>

                                    {/* Verified filter */}
                                    <div className="talent-id-filter-component" style={{ marginLeft: '0px', marginRight: '60px', justifyContent: 'center', marginBottom: '30px'}}>
                                        <span>Verified</span>
                                        <Switch
                                            checked={verifiedFilter}
                                            onChange={function(event) {
                                                setVerifiedFilter(event.target.checked);
                                            }}
                                            color="primary"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <input onClick={() => generateReport()} type="button" name="generate-report" value="Generate Report" />
                    {loading ? <CircularProgress disableShrink={true} size={20} /> : null}

                    <input style={{ opacity: userFilterDirty || sportFilterDirty || organisationFilterDirty || countryFilterDirty || otherFilterDirty ? 1 : .5 }} onClick={() => resetAll()} type="button" name="reset-all" value="Reset all" />
                </div>
            </div>
        </div>
    )
}

export default TalentIDSuperUser;