import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';

import $ from 'jquery';

import IM from 'convert-units';

// COMPONENTS
import { MyDetailsHeader } from '../../../components/MyDetailsHeader';
import {
    UserDefinedTextField,
    BodySizeAndShapeSliderFields,
    RadioField,
    RenderFeetInchesFields
} from '../../../components/FormInputs.js';
// ACTIONS
import {
    setHeight,
    setWeight,
    setArmSpan,
    setForearmLength,
    setSittingHeight,
    setExpandedBodySizeAndShape,
    setUpperArmLength,
    setMothersHeight,
    setFathersHeight
} from '../../../store/actions/myDetails.js';

import {
    setUserInputHeight,
    setUserInputWeight,
    setUserInputArmSpan,
    setUserInputForearmLength,
    setUserInputSittingHeight,
    setUserInputUpperArmLength
} from '../../../store/actions/myDetails.js';

import { fieldInfo } from './fieldInfoContent.js';
// ICONS
import BodySizeShapeSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/BodySizeShapeSvg.js';
import { B2C_ROLE_NAME, BACKEND_URL, ENVIRONMENT_PATH } from '../../../store/constants';
import { ParentSliderField } from '../../../components/myDetails/parentHeightSlider';
import { retrieveJSONOfUser } from '../../../helper/helper';

const BodySizeShape = ({infoOpen, setInfoOpen, validation }) => {
    const state = useSelector(store => store.myDetails);
    const sportMatchTestState = useSelector(store => store.sportMatchTest);
    const bodySizeAndShapeSectionExpanded = state.bodySizeAndShapeSectionExpanded;
    const dispatch = useDispatch();
    const organisationStateLocal = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);
    const reduxState = useSelector(state => state);

    // Local State
    const [expanded, setExpanded] = useState(false);

    // Functions enabling child components to dispatch actions
    const dispatchHeight = (input) => {
        dispatch(setUserInputHeight(input));
    }
    const dispatchWeight = (input) => {
        dispatch(setUserInputWeight(input));
    }
    const dispatchArmSpan = (input) => {
        dispatch(setUserInputArmSpan(input));
    }
    const dispatchForearmLength = (input) => {
        dispatch(setUserInputForearmLength(input));
    }
    const dispatchUpperArmLength = (input) => {
        dispatch(setUserInputUpperArmLength(input));
    }
    const dispatchSittingHeight = (input) => {
        dispatch(setUserInputSittingHeight(input));
    }

    const expand = () => {
        dispatch(setExpandedBodySizeAndShape(!bodySizeAndShapeSectionExpanded));
        setExpanded(!expanded);
    }

    const getFieldsChanged = () => {
        if(sportMatchTestState.userInputHeight != "" && state.height == "") {
            return true;
        } else if(sportMatchTestState.userInputWeight != "" && state.weight == "") {
            return true;
        } else if(sportMatchTestState.userInputArmSpan != "" && state.armSpan == "") {
            return true;
        }  else if(sportMatchTestState.userInputForearmLength != "" && state.forearmLength == "") {
            return true;
        } else if(sportMatchTestState.userInputUpperArmLength != "" && state.upperArmLength == "") {
            return true;
        } else if(sportMatchTestState.userInputSittingHeight != "" && state.sittingHeight == "") {
            return true;
        } else {
            return false;
        }
    }

    const resetButtonHandler = () => {
        if(state.height == "") {
            dispatch(setUserInputHeight(""));
        }

        if(state.weight == "") {
            dispatch(setUserInputWeight(""));
        }

        if(state.armSpan == "") {
            dispatch(setUserInputArmSpan(""));
        }

        if(state.forearmLength == "") {
            dispatch(setUserInputForearmLength(""));
        }

        if(state.upperArmLength == "") {
            dispatch(setUserInputUpperArmLength(""));
        }

        if(state.sittingHeight == "") {
            dispatch(setUserInputSittingHeight(""));
        }
    };

    /**
     * Dispatches the mother height
     * @param {var} input The value of the input field 
     */
    const dispatchMothersHeight = (input) => {
        var motherHeight = input;

        if(userState.userUOM == "Imperial") {
            motherHeight = IM(motherHeight).from('in').to('cm');
        }

        dispatch(setMothersHeight(input));

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/UserDetails`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(retrieveJSONOfUser({ name: 'mother_height', value: motherHeight }, reduxState, reduxState.user.userRole == B2C_ROLE_NAME, IM))
        }).then(res => {
            res.json().then(result => { })
        });
    };

    /**
     * Dispatches the father height
     * @param {var} input The value of the input field 
     */
     const dispatchFathersHeight = (input) => {
        var fatherHeight = input;

        if(userState.userUOM == "Imperial") {
            fatherHeight = IM(fatherHeight).from('in').to('cm');
        }

        dispatch(setFathersHeight(input));

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/UserDetails`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(retrieveJSONOfUser({ name: 'father_height', value: fatherHeight }, reduxState, reduxState.user.userRole == B2C_ROLE_NAME, IM))
        }).then(res => {
            res.json().then(result => { })
        });
    }

    const bodySizeShapeIcon = () => {
        return <BodySizeShapeSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'} />;
    };

    return (
        <div className="screen-section test-section">
            <MyDetailsHeader
                children={[]}
                title={"Body size and shape"}
                icon={bodySizeShapeIcon()}
                isIconSvg={false} expand={expand} expanded={true}
                fieldChanged={getFieldsChanged()}
                resetBtnHandler={resetButtonHandler} />

            <div className="myDetails-fields expanded">
                {userState.userRole == B2C_ROLE_NAME && 
                <>
                    <ParentSliderField
                        action={dispatchMothersHeight}
                        label={`Mother's height (${userState.userUOM == "Imperial" ? "in" : "cm"})`}
                        usingUOM={userState.userUOM}
                        enteredValue={state.mothersHeight}
                        minValue={userState.userUOM == "Imperial" ? IM(100).from('cm').to('in') : 100}
                        maxValue={userState.userUOM == "Imperial" ? IM(220).from('cm').to('in') : 220}
                    />

                    <ParentSliderField
                        action={dispatchFathersHeight}
                        label={`Father's height (${userState.userUOM == "Imperial" ? "in" : "cm"})`}
                        usingUOM={userState.userUOM}
                        enteredValue={state.fathersHeight}
                        minValue={userState.userUOM == "Imperial" ? IM(100).from('cm').to('in') : 100}
                        maxValue={userState.userUOM == "Imperial" ? IM(220).from('cm').to('in') : 220}
                    />
                    <br />
                </>}

                <BodySizeAndShapeSliderFields
                    action={dispatchHeight}
                    requiredField={true}
                    label={"Height"}
                    showValidation={sportMatchTestState.userInputHeight === "" && validation}
                    labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}
                    placeholder={userState.userUOM == "Imperial" ? "in" : "cm"}
                    required={true}
                    enteredValue={sportMatchTestState.userInputHeight}
                    minValue={100}
                    maxValue={220}
                    step={0.5}
                    formatToFeetAndInches={userState.userUOM == "Imperial"}
                    info={fieldInfo.height}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.height != ""} />

                <BodySizeAndShapeSliderFields
                    action={dispatchWeight}
                    requiredField={true}
                    label={"Weight"}
                    showValidation={sportMatchTestState.userInputWeight === "" && validation}
                    labelUOM={userState.userUOM == "Imperial" ? "lbs" : "kg"}
                    placeholder={userState.userUOM == "Imperial" ? "lbs" : "kg"}
                    required={true}
                    enteredValue={sportMatchTestState.userInputWeight}
                    minValue={20}
                    maxValue={150}
                    fixed={userState.userUOM == "Imperial" ? 1 : -1}
                    info={fieldInfo.weight}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.weight != ""} />

                {/* Arm Span */}
                <BodySizeAndShapeSliderFields
                    action={dispatchArmSpan}
                    label={"Arm span"}
                    labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}
                    placeholder={userState.userUOM == "Imperial" ? "in" : "cm"}
                    required={false}
                    enteredValue={sportMatchTestState.userInputArmSpan}
                    minValue={100}
                    maxValue={220}
                    step={0.5}
                    formatToFeetAndInches={userState.userUOM == "Imperial"}
                    info={fieldInfo.armSpan}
                    infoOpen={infoOpen}
                    setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.armSpan != ""} />

                <BodySizeAndShapeSliderFields
                    action={dispatchSittingHeight}
                    label={"Sitting height"}
                    labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}
                    placeholder={userState.userUOM == "Imperial" ? "in" : "cm"}
                    required={false}
                    enteredValue={sportMatchTestState.userInputSittingHeight}
                    minValue={40}
                    maxValue={150}
                    info={fieldInfo.sittingHeight}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.sittingHeight != ""} />

                <BodySizeAndShapeSliderFields
                    action={dispatchForearmLength}
                    label={"Forearm length"}
                    labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}
                    placeholder={userState.userUOM == "Imperial" ? "in" : "cm"}
                    required={false}
                    enteredValue={sportMatchTestState.userInputForearmLength}
                    minValue={userState.userUOM == "Imperial" ? 5.90 : 15}
                    convertMinimum={false}
                    maxValue={40}
                    fixed={userState.userUOM == "Imperial" ? 2 : -1}
                    info={fieldInfo.forearmLength}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.forearmLength != ""} />

                <BodySizeAndShapeSliderFields
                    action={dispatchUpperArmLength}
                    label={"Upper arm length"}
                    labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}
                    placeholder={userState.userUOM == "Imperial" ? "in" : "cm"}
                    required={false}
                    enteredValue={sportMatchTestState.userInputUpperArmLength}
                    minValue={userState.userUOM == "Imperial" ? 5.90 : 15}
                    convertMinimum={false}
                    maxValue={50}
                    fixed={userState.userUOM == "Imperial" ? 2 : -1}
                    info={fieldInfo.upperArmLength}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.upperArmLength != ""} />
            </div>
        </div>
    );
}

export default BodySizeShape;
