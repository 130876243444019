import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './sidemenu.css';

// ASSETS
import DefaultUserIcon from '../../assets/user_icon_default.png';

// ACTIONS
import { changeScreen } from '../../store/actions/actions.js';
import {B2C_ROLE_NAME, BACKEND_URL, ENVIRONMENT_PATH} from "../../store/constants";
import {setOrgLogo} from "../../store/actions/OrganisationActions";

// STYLE
import styled from 'styled-components';
import { hexToRgbA } from '../../helper/hexToRgbA';

const SideMenu = () => {
    const screen = useSelector(state => state.user.screen);
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const organisationLocalState = useSelector(state => state.organisationState);

    const abortController = useSelector(state => state.user.fetchController);

    useEffect(() => {
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/ImageUpload`, {
            method: 'GET',
            credentials: "include"
        }).then(res => {
            res.text().then(text => {
                if (text == '' || text == null) {
                    dispatch(setOrgLogo(""));
                } else {
                    dispatch(setOrgLogo(text));
                }
            })
        })
    }, []);


    const FaderDiv = styled.div`
         &:hover:not(.disabled) {
             box-shadow: ${ 'inset 4px 4px 0px '+ (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor, 0.3) : '#9a62b34d')};
          }
         &:active:not(.disabled) {
             box-shadow: ${ 'inset 6px 6px 0px '+ (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor, 0.3) : '#9a62b34d')};
          }`;

    const renderMenu = () => {
        if(state.user.userRole === "admin" && state.organisationState.accessUser != "true") {
            return (
                <div className="sidemenu-container">
                    <div className="sidemenu-user-container" style={{borderBottomColor: (state.organisationState.organisationPrimaryColor == '' && state.organisationState.organisationSecondaryColor == '' && state.organisationState.organisationAccentColor == '') || (state.organisationState.organisationPrimaryColor == '#9a62b3' && state.organisationState.organisationSecondaryColor == '#778599' && state.organisationState.organisationAccentColor == '#50B383') ? '#5A5380' : 'white'}}>
                        <div className="sidemenu-user">
                            <img src={DefaultUserIcon} />
                        </div>
                        <div className="sidemenu-username" >
                            {state.user.userId}
                        </div>
                    </div>
                    <div className="sidemenu-buttons-container organisation-sidemenu-button-container">
                        <div onClick={() => dispatch(changeScreen(1))} className={screen === 1 ? "sidemenu-button selected" : "sidemenu-button"}>
                            User list
                        </div>
                        <div onClick={() => dispatch(changeScreen(2))} className={screen === 2 ? "sidemenu-button selected" : "sidemenu-button"}>
                            Edit norms
                        </div>
                        <div onClick={() => dispatch(changeScreen(3))} className={screen === 3 ? "sidemenu-button selected" : "sidemenu-button"}>
                            TalentID
                        </div>
                        <div onClick={() => dispatch(changeScreen(4))} className={screen === 2 ? "sidemenu-button selected" : "sidemenu-button"}>
                            Custom Weighting
                        </div>
                    </div>
                </div>
            )
        } else if (state.user.userRole === "organisation" || state.user.userRole == 'teacher') {
            return (
                <div className="sidemenu-container"  >
                    <div className="sidemenu-user-container" style={{borderBottomColor: (state.user.userRole == 'organisation' || state.user.userRole == 'teacher') && ((state.organisationState.organisationPrimaryColor == '' && state.organisationState.organisationSecondaryColor == '' && state.organisationState.organisationAccentColor == '') || (state.organisationState.organisationPrimaryColor == '#9a62b3' && state.organisationState.organisationSecondaryColor == '#778599' && state.organisationState.organisationAccentColor == '#50B383')) ? '#5A5380' : 'white'}}>
                        <div className="sidemenu-user">
                            <img src={state.organisationState.organisationLogo != "" ? state.organisationState.organisationLogo : DefaultUserIcon} />
                        </div>
                        <div className="sidemenu-username" >
                            {state.user.userOrganisationName}
                        </div>
                    </div>
                    <div className="sidemenu-buttons-container organisation-sidemenu-button-container">
                        <FaderDiv onClick={() => {
                            //abortController.abort();
                            dispatch(changeScreen(1));
                        }} className={screen === 1 ? "sidemenu-button selected" : "sidemenu-button"} style={{ background: screen === 1 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 1 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Dashboard
                        </FaderDiv>
                        <FaderDiv onClick={() => dispatch(changeScreen(2))} className={screen === 2 ? "sidemenu-button selected" : "sidemenu-button"} style={{ background: screen === 2 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 2 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Results overview
                        </FaderDiv>
                        <FaderDiv onClick={() => dispatch(changeScreen(3))} className={screen === 3 ? "sidemenu-button selected" : "sidemenu-button"} style={{ background: screen === 3 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 3 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Teachers' resources
                        </FaderDiv>
                        <FaderDiv onClick={() => dispatch(changeScreen(4))} className={screen === 4 ? "sidemenu-button selected" : "sidemenu-button"} style={{ background: screen === 4 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 4 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Test takers
                        </FaderDiv>
                        <FaderDiv onClick={() => dispatch(changeScreen(5))} className={screen === 5 ? "sidemenu-button selected" : "sidemenu-button"} style={{ background: screen === 5 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 5 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Talent ID
                        </FaderDiv>
                        {state.user.userRole == 'teacher' ? '':<FaderDiv onClick={() => dispatch(changeScreen(6))} className={screen === 6 ? "sidemenu-button selected" : "sidemenu-button"} style={{ background: screen === 6 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 6 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Options
                        </FaderDiv>}
                        <FaderDiv onClick={() => dispatch(changeScreen(8))} className={screen === 8 ? "sidemenu-button selected" : "sidemenu-button"} style={{ background: screen === 8 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 8 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Scout page
                        </FaderDiv>
                        <FaderDiv onClick={() => dispatch(changeScreen(7))} className={screen === 99 ? "sidemenu-button selected" : "sidemenu-button"} style={{ background: screen === 7 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 7 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Access as user
                        </FaderDiv>
                    </div>
                </div>
            );
        } else if(state.user.userRole === B2C_ROLE_NAME) {
            return (
                <div className="sidemenu-container">
                <div className="sidemenu-user-container" style={{borderBottomColor: (state.organisationState.organisationPrimaryColor == '' && state.organisationState.organisationSecondaryColor == '' && state.organisationState.organisationAccentColor == '') || (state.organisationState.organisationPrimaryColor == '#9a62b3' && state.organisationState.organisationSecondaryColor == '#778599' && state.organisationState.organisationAccentColor == '#50B383') ? '#CEE2FF' : 'white'}}>
                    <div className="sidemenu-user">
                        <img src={state.organisationState.organisationLogo != "" ? state.organisationState.organisationLogo : DefaultUserIcon} />
                    </div>
                    <div className="sidemenu-username">
                        {state.user.userId}
                    </div>
                </div>
                <div className="sidemenu-buttons-container user-test-sidemenu-button-container">
                    <FaderDiv
                        onClick={() => {
                            dispatch(changeScreen(1));
                        }}
                        className={screen === 1 ? "sidemenu-button selected" : "sidemenu-button"}
                        style={{ background: screen === 1 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 1 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                        SportMatch test
                    </FaderDiv>
                    <FaderDiv
                        onClick={() => {
                            if (state.user.userViewingTest) {
                                dispatch(changeScreen(2));
                            }
                        }}
                        className={(screen === 2 ? "sidemenu-button selected" : "sidemenu-button") + (!state.user.userViewingTest ? " disabled" : "")}
                        style={{ background: screen === 2 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 2 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                        Find my sport
                    </FaderDiv>
                    <FaderDiv
                        onClick={() => {
                            if (state.user.userViewingTest) {
                                dispatch(changeScreen(3));
                            }
                        }}
                        className={(screen === 3 ? "sidemenu-button selected" : "sidemenu-button") + (!state.user.userViewingTest ? " disabled" : "")}
                        style={{ background: screen === 3 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 3 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                        Compare my results
                    </FaderDiv>
                    <FaderDiv
                        onClick={() => {
                            if (state.user.userViewingTest) {
                                dispatch(changeScreen(4));
                            }
                        }}
                        className={(screen === 4 ? "sidemenu-button selected" : "sidemenu-button") + (!state.user.userViewingTest ? " disabled" : "")}
                        style={{ background: screen === 4 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 4 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                        Elite athlete profiles
                    </FaderDiv>
                    <FaderDiv
                        onClick={() => {
                            if (state.user.userViewingTest) {
                                dispatch(changeScreen(5));
                            }
                        }}
                        className={(screen === 5 ? "sidemenu-button selected" : "sidemenu-button") + (!state.user.userViewingTest ? " disabled" : "")}
                        style={{ background: screen === 5 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 5 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                        My fitness age
                    </FaderDiv>
                    <FaderDiv
                        onClick={() => {
                            dispatch(changeScreen(6));
                        }}
                        className={screen === 6 ? "sidemenu-button selected" : "sidemenu-button"}
                        style={{ background: screen === 6 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 6 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                        Reports
                    </FaderDiv>
                    <FaderDiv
                        onClick={() => {
                            dispatch(changeScreen(7));
                        }}
                        className={(screen === 7 ? "sidemenu-button selected" : "sidemenu-button")}
                        style={{ background: screen === 7 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 7 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                        My details
                    </FaderDiv>
                </div>
            </div>
            )
        } else {
            return (
                <div className="sidemenu-container">
                    <div className="sidemenu-user-container" style={{borderBottomColor: (state.organisationState.organisationPrimaryColor == '' && state.organisationState.organisationSecondaryColor == '' && state.organisationState.organisationAccentColor == '') || (state.organisationState.organisationPrimaryColor == '#9a62b3' && state.organisationState.organisationSecondaryColor == '#778599' && state.organisationState.organisationAccentColor == '#50B383') ? '#CEE2FF' : 'white'}}>
                        <div className="sidemenu-user">
                            <img src={state.organisationState.organisationLogo != "" ? state.organisationState.organisationLogo : DefaultUserIcon} />
                        </div>
                        <div className="sidemenu-username">
                            {state.user.userId}
                        </div>
                    </div>
                    <div className="sidemenu-buttons-container user-test-sidemenu-button-container">
                        <FaderDiv
                            onClick={() => {
                                dispatch(changeScreen(1));
                            }}
                            className={(screen === 1 ? "sidemenu-button selected" : "sidemenu-button")}
                            style={{ background: screen === 1 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 1 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            My details
                        </FaderDiv>
                        <FaderDiv
                            onClick={() => {
                                dispatch(changeScreen(2));
                            }}
                            className={screen === 2 ? "sidemenu-button selected" : "sidemenu-button"}
                            style={{ background: screen === 2 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 2 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            SportMatch test
                        </FaderDiv>
                        <FaderDiv
                            onClick={() => {
                                if (state.user.userViewingTest) {
                                    dispatch(changeScreen(3));
                                }
                            }}
                            className={(screen === 3 ? "sidemenu-button selected" : "sidemenu-button") + (!state.user.userViewingTest ? " disabled" : "")}
                            style={{ background: screen === 3 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 3 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Find my sport
                        </FaderDiv>
                        <FaderDiv
                            onClick={() => {
                                if (state.user.userViewingTest) {
                                    dispatch(changeScreen(4));
                                }
                            }}
                            className={(screen === 4 ? "sidemenu-button selected" : "sidemenu-button") + (!state.user.userViewingTest ? " disabled" : "")}
                            style={{ background: screen === 4 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 4 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Compare my results
                        </FaderDiv>
                        <FaderDiv
                            onClick={() => {
                                if (state.user.userViewingTest) {
                                    dispatch(changeScreen(5));
                                }
                            }}
                            className={(screen === 5 ? "sidemenu-button selected" : "sidemenu-button") + (!state.user.userViewingTest ? " disabled" : "")}
                            style={{ background: screen === 5 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 5 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Elite athlete profiles
                        </FaderDiv>
                        <FaderDiv
                            onClick={() => {
                                if (state.user.userViewingTest) {
                                    dispatch(changeScreen(6));
                                }
                            }}
                            className={(screen === 6 ? "sidemenu-button selected" : "sidemenu-button") + (!state.user.userViewingTest ? " disabled" : "")}
                            style={{ background: screen === 6 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 6 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            My fitness age
                        </FaderDiv>
                        <FaderDiv
                            onClick={() => {
                                dispatch(changeScreen(7));
                            }}
                            className={screen === 7 ? "sidemenu-button selected" : "sidemenu-button"}
                            style={{ background: screen === 7 ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.3) : 'rgb(154, 98, 179, .3)') : 'white', boxShadow: screen === 7 ? (organisationLocalState.organisationPrimaryColor != '' ? ('inset 4px 4px 0px ' + organisationLocalState.organisationPrimaryColor + '4d') : "inset 4px 4px 0px #9a62b34d") : '' }}>
                            Reports
                        </FaderDiv>
                    </div>
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            {renderMenu()}
        </React.Fragment>
    );
}

export default SideMenu;
