import React, { useState, useEffect } from 'react';

export const ScoutSlider = ({ isChecked, showAsTransparent, setMandatorySelected, label, uom, value, setValue = null, loadedData }) => {

    return (
        <div>
            <div className={`slider-label ${showAsTransparent ? "transparent" : ""}`}>{label} {uom ? "(" + uom + ")" : null}</div>
            <div className="stats-selector-container">

            <div className="stats-holder sliders">
                    <span style={{left: '3px', visibility: value >= 1 ? "hidden" : "visible"}}></span>
                    <span style={{visibility: value >= 2 ? "hidden" : "visible"}}></span>
                    <span style={{left: '-3px', visibility: value >= 3 ? "hidden" : "visible"}}></span>
                    <span style={{visibility: 'hidden'}}></span>
                </div>

                <div className={`slider ${showAsTransparent ? "transparent" : ""}`}>
                    <span style={{visibility: 'hidden'}}>x 1/3</span>
                    <input disabled={!loadedData} type="range" onChange={e => setValue(e.target.value)} value={value} min={0} max={4} />
                    <span style={{visibility: 'hidden'}}>x 3</span>
                </div>

                <div className='mandatory-check-container'>
                    <input checked={isChecked} type="checkbox" onChange={(val) => {
                        setMandatorySelected(val.currentTarget.checked);
                    }}/>
                </div>
            </div>
        </div>
    )
}