// TOP 5 sports

// REACTJS
import React, { useState, useEffect } from 'react';

// IMAGES
import AustralianFootballCircle from '../../../../../../images/American football (running back)circle.png';
import BasketballCenterCircle from '../../../../../../images/Basketball (centre) Malecircle.png';
import DivingCircle from '../../../../../../images/Diving Malecircle.png';
import RugbyLeagueBackCircle from '../../../../../../images/Rugby league (back) Malecircle.png';
import SkijumpingCircle from '../../../../../../images/Skijumping Malecircle.png';

import './style.css';

// CONSTANTS
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../../../store/constants';

import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { CheckIfUserIsLoggedIn, CheckIfUserSessionIsValid } from '../../../../../../api/Helper';

import Axios from 'axios';
import { hexToRgbA } from '../../../../../../helper/hexToRgbA';

let cancelTopSports = Axios.CancelToken.source();

const Top5SportsComponent = ({ loading, data, setData, includeTitle, forceLoad, setForceLoad }) => {
    const dispatch = useDispatch();
    const organisationStateLocal = useSelector(state => state.organisationState);
    const [top5Sports, setTop5Sports] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const getImageForSport = (imageUrl) => {
        try {
            var image = images("./" + imageUrl);
            return image;
        } catch (err) {
            console.log("Error = ", err);
        }
    }

    const generateFilterString = (isNew) => {
        // Checking if filters have been applied
        var filters = "";
        if (organisationStateLocal.genderFilters.length != 0) {
            if (isNew) {
                filters += "?gender=" + organisationStateLocal.genderFilters;
            } else {
                filters += "&gender=" + organisationStateLocal.genderFilters;
            }
        }
        if (organisationStateLocal.groupNameFilters.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
                } else {
                    filters += "?groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
                }
            } else {
                filters += "&groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
            }
        }
        if (organisationStateLocal.ageFilters.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
                } else {
                    filters += "?ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
                }
            } else {
                filters += "&ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
            }
        }
        if (organisationStateLocal.dateRangeFromFilter.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
                } else {
                    filters += "?startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
                }
            } else {
                filters += "&startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
            }
        }
        if (organisationStateLocal.dateRangeToFilter.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
                } else {
                    filters += "?endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
                }
            } else {
                filters += "&endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
            }
        }

        return filters;
    }

    const requestSportTypesUsingOrgSelectedSports = (runCallback = true) => {
        Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/sporttypes`, {
            withCredentials: true,
            cancelToken: cancelTopSports.token
        }).then(res => {
            CheckIfUserSessionIsValid(dispatch).then(() => {
                    if (res.data.sport_types && res.data.sport_types.length > 0) {
                        var arrayOfObjects = [
                            {
                                "name": "power",
                                "value": res.data.sport_types[0].power_score
                            },
                            {
                                "name": "skill",
                                "value": res.data.sport_types[0].skill_score
                            },
                            {
                                "name": "aerobic-fitness",
                                "value": res.data.sport_types[0].aerobic_fitness_score
                            },
                            {
                                "name": "strength",
                                "value": res.data.sport_types[0].strength_score
                            },
                            {
                                "name": "game-fitness",
                                "value": res.data.sport_types[0].game_fitness_score
                            },
                            {
                                "name": "speed",
                                "value": res.data.sport_types[0].speed_score
                            },
                        ]
                        setData(arrayOfObjects);
                    } else if (res.data.callback) {
                        if (res.data.callback == "generate_top_sports") {
                            var type = res.data.type;
                            var requestString = "";

                            if (type == "organisation_selected_sports") {
                                requestString = "?using_org_sports=true";
                            } else {
                                requestString = "?all_sports=true";
                            }

                            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/orgtopsports${requestString}&save_sport_types_to_db=true&save_top_sports_to_db=true`, {
                                withCredentials: true,
                                cancelToken: cancelTopSports.token
                            }).then(res1 => {
                                setTop5Sports(res1.data.top_sports);
                                if (runCallback) requestSportTypesUsingOrgSelectedSports(false);
                            }).catch((error) => {
                                if (Axios.isCancel(error)) {
                                    console.log("An error occured: ", error);
                                }
                            });
                        }
                    }
            })
        }).catch((error) => {
            if (Axios.isCancel(error)) {
                console.log("An error occured: ", error);
            }
        });
    }

    const getTop5Sports = () => {
        cancelTopSports.cancel();
        cancelTopSports = Axios.CancelToken.source();

        if (generateFilterString(false) == "") {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/gettopsports?using_org_sports=true`, {
                withCredentials: true,
                cancelToken: cancelTopSports.token
            }).then(res => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    if (res.data.top_sports && res.data.top_sports.top_sports.length > 0 && res.data.top_sports.top_sports[0].length > 0) {
                        setTop5Sports(res.data.top_sports.top_sports[0]);
                        requestSportTypesUsingOrgSelectedSports();
                    } else if (res.data.top_sports && res.data.top_sports.top_sports.length > 0) {
                        setTop5Sports(res.data.top_sports.top_sports);
                        requestSportTypesUsingOrgSelectedSports();
                    } else {
                        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/orgtopsports?all_sports=true${generateFilterString(false)}&save_top_sports_to_db=true&save_sport_types_to_db=true`, {
                            headers: {
                                'Accept': 'application/json'
                            },
                            credentials: "include"
                        }).then(res1 => {
                            res1.json().then(result => {
                                setTop5Sports(result.top_sports);
                                requestSportTypesUsingOrgSelectedSports();
                            }).catch(err1 => {
                                console.log("An error occured: ", err1);
                            });
                        }).catch(err => {
                            console.log("An error occured: ", err);
                        })
                    }
                })
            }).catch((error) => {
                if (Axios.isCancel(error)) {
                    console.log("An error occured: ", error);
                }
            })
        } else {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/orgtopsports?using_org_sports=true${generateFilterString(false)}`, {
                withCredentials: true,
                cancelToken: cancelTopSports.token
            }).then(res => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    if (res.data.top_sport_types) {
                        var arrayOfObjects = [
                            {
                                "name": "power",
                                "value": res.data.top_sport_types.length > 0 ? res.data.top_sport_types[0].value : 0
                            },
                            {
                                "name": "skill",
                                "value": res.data.top_sport_types.length > 0 ? res.data.top_sport_types[1].value : 0
                            },
                            {
                                "name": "aerobic-fitness",
                                "value": res.data.top_sport_types.length > 0 ? res.data.top_sport_types[2].value : 0
                            },
                            {
                                "name": "strength",
                                "value": res.data.top_sport_types.length > 0 ? res.data.top_sport_types[3].value : 0
                            },
                            {
                                "name": "game-fitness",
                                "value": res.data.top_sport_types.length > 0 ? res.data.top_sport_types[4].value : 0
                            },
                            {
                                "name": "speed",
                                "value": res.data.top_sport_types.length > 0 ? res.data.top_sport_types[5].value : 0
                            },
                        ]
                    }

                    setTop5Sports(res.data.top_sports);
                    setData(arrayOfObjects);
                })
            }).catch((error) => {
                if (Axios.isCancel(error)) {
                    console.log("An error occured: ", error);
                }
            })
        }
    }

    const images = require.context('../../../../../../images', true);

    const renderTop5SportDOMElements = () => {
        var dom = [];
        if (top5Sports) {
            if (top5Sports.length > 0) {
                for (var i = 0; i < top5Sports.length; i++) {
                    if (i > 4) {
                        break;
                    }
                    if (top5Sports[i] && top5Sports[i].sport) {
                        dom.push(
                            <div className="sport-container">
                                <img className="top-5-sport-element" src={getImageForSport(top5Sports[i].sport.image_path)} />
                                {includeTitle ? <div style={{ width: '130px' }}>{i + 1 + ". " + top5Sports[i].sport.sport_name}</div> : null}
                            </div>
                        )
                    }
                }
            } else {
                return (
                    <div style={{ width: '100%' }}>
                        <span className="no-top-5-sports-span" style={{ color: organisationStateLocal.organisationPrimaryColor != '' ? hexToRgbA(organisationStateLocal.organisationPrimaryColor, 0.5) : 'rgb(154, 98, 179, 0.5)' }}>No tests uploaded</span>
                    </div>
                )
            }
        }

        return dom;
    }

    useEffect(() => {
        getTop5Sports();
    }, [
        JSON.stringify(organisationStateLocal.groupNameFilters),
        JSON.stringify(organisationStateLocal.ageFilters),
        organisationStateLocal.genderFilters,
        organisationStateLocal.dateRangeFromFilter,
        organisationStateLocal.dateRangeToFilter
    ]);

    useEffect(() => {
        setLoading(false);
    }, [top5Sports]);

    useEffect(() => {
        if (forceLoad) {
            getTop5Sports();
            setForceLoad(false);
        }
    }, [forceLoad])

    return (
        <div className="segment-container segment-small top-5-sports-segment">
            <div className="heading">
                <span>Top 5 sports</span>

                {isLoading ? <CircularProgress disableShrink={true} size={20} /> : null}
            </div>

            <div className="component-body">
                {renderTop5SportDOMElements()}
            </div>
        </div>
    )
}

export default Top5SportsComponent;
