export const hexToRgbA = (hex, opacity) => {
    var hexToTest = hex;

    if(hexToTest.indexOf("#") == -1) {
        hexToTest = ("#" + hexToTest);
    }

    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexToTest)) {
        c = hexToTest.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }
    
}