import React, { useState, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import { chartResults, lineOptions } from './chartConfig.js';
import { useSelector, useDispatch } from 'react-redux';
import './myFitnessAge.css';

// BACKEND URL
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../store/constants';

import moment from 'moment';

// Styled Components
import styled from 'styled-components';

const MyFitnessAge = () => {
    //const FitnessAgeProcedures = require('./MyFitnessAgeProc.js');

    const userFitnessTests = useSelector(state => state.myDetails);
    const userFitnessAge = useSelector(state => state.user.userFitnessAge).toFixed(1);
    const userState = useSelector(state => state.user);
    const organisationState = useSelector(state => state.organisationState);

    const [hasMoreThanOneTest, setHasMoreThanOneTest] = useState(false);
    const [userAgeFormatted, setUserAgeFormatted] = useState(0);

    const [compareOverTime, setCompareOverTime] = useState(false);

    const [compareResults, setCompareResults] = useState([]);

    const [chartData, setChartData] = useState({ ...chartResults });

    var duration;

    /***
     * returnDateOfUser
     * 
     * Returns the age of the user
     */
    const returnDateOfUser = () => {
        if (userState.userBirthMonth !== -1 && userState.userBirthYear !== -1) {
            if (userFitnessTests.testDate != "") {
                var dob = moment((userState.userBirthMonth + 1) + '/01/' + userState.userBirthYear);
                var age = moment.duration(moment(userFitnessTests.testDate).diff(dob)).asYears();
                duration = age.toFixed(2);
                return parseFloat(duration).toFixed(2);
            } else {
                var currentDateMoment = moment(new Date());
                var month = (parseInt(userState.userBirthMonth + 1) < 10) ? "0" + (userState.userBirthMonth + 1) : userState.userBirthMonth + 1;
                var studentDate = userState.userBirthYear + "/" + month + "/" + "01";
                var studentDateMoment = moment(studentDate);
                var numberOfYears = (currentDateMoment.diff(studentDateMoment, 'days') / 365) + "";
                var numberOfYearsFormatted = "";
    
                if (numberOfYears.indexOf(".") !== -1)
                    numberOfYearsFormatted = numberOfYears.split('.')[0] + "." + numberOfYears.split('.')[1].substring(0, 1);
                else
                    numberOfYearsFormatted = numberOfYears;
    
                return numberOfYearsFormatted;
            }
        } else return '';
    }

    const [singleResult, setSingleResult] = useState([{ x: returnDateOfUser(), y: userFitnessAge }]);

    const checkIfUserHasPreviousTestRecords = () => {
        var hasPreviousTests = false;

        if (userState.userPreviousTests.length !== 0) {
            var json = JSON.parse(userState.userPreviousTests);
            hasPreviousTests = json.tests.length !== 0 && json.tests.length > 1;
        }

        return hasPreviousTests;
    }

    // Initially insert single result data into chart data object
    useEffect(() => {
        var chartDataCopy = chartData;
        
        chartDataCopy.datasets.forEach(element => {
            if(element && element.label && element.label == "Results") {
                element.pointBackgroundColor = organisationState.organisationPrimaryColor != "" ? organisationState.organisationPrimaryColor : '#9962B1';
                element.pointBorderColor = organisationState.organisationPrimaryColor != "" ? organisationState.organisationPrimaryColor : '#9962B1';
                element.pointHoverBackgroundColor = organisationState.organisationPrimaryColor != "" ? organisationState.organisationPrimaryColor : '#9962B1';
                element.pointHoverBorderColor = organisationState.organisationPrimaryColor != "" ? organisationState.organisationPrimaryColor : '#9962B1';
            }
        });

        setUserAgeFormatted(returnDateOfUser());

        // Get the most recent tests performed by the user
        fetch(BACKEND_URL + ENVIRONMENT_PATH + '/UserDetails', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify({user_id: userState.userBackendId, getPreviousTests: true}),
            credentials: "include"
        }).then(function (res) {
            res.json().then(function (data) {
                if (data.length !== 0) {
                    setHasMoreThanOneTest(data.length > 1);

                    var previousRecords = [];

                    for (var i = 0; i < data.length; i++) {
                        var userTest = data[i].user_test;

                        if (userTest && userTest.fitness_age) {
                            previousRecords.push({ x: userTest.user_age, y: userTest.fitness_age, current_time: i == 0 });
                        }
                    }

                    setCompareResults(previousRecords.reverse());
                } else {
                    setSingleResult([{ x: returnDateOfUser(), y: userFitnessAge, current_time: true }]);
                    setCompareResults([{ x: returnDateOfUser(), y: userFitnessAge, current_time: true }]);
                }
            })
        }).catch(err => {
            console.log("An error was thrown");
        })
    }, []);

    useEffect(() => {
        changeChartData(false);

        let chartResultFull = chartResults.datasets;

        chartResultFull[1] = {
            ...chartResultFull[1],
            pointRadius: compareResults.map(item => item.current_time ? 15 : 10),
            pointHoverRadius: compareResults.map(item => item.current_time ? 18 : 12),
        }
        setChartData({ datasets: chartResultFull });

    }, [compareResults]);

    // Toggles fitness age chart between single (current) result
    // and results over time including all previous results.
    const changeChartData = (compare) => {
        let changedData = chartData;
        if (compare) {
            changedData.datasets[1].data = [...compareResults];
            setChartData(changedData);
        }
        else {
            changedData.datasets[1].data = [...singleResult];
            setChartData(changedData);
        }
    }

    const toggleChartDataChange = () => {
        changeChartData(!compareOverTime);
        localStorage.setItem("myFitnessAgeCompare", !compareOverTime);
        setCompareOverTime(!compareOverTime);
    };

    const CompareButton = styled.div`
        border: 2px solid ${ organisationState.organisationPrimaryColor != "" ? organisationState.organisationPrimaryColor : "#9962B1" }
    `;

    const CompareButtonInner = styled.div`
        background: ${ organisationState.organisationPrimaryColor != "" ? organisationState.organisationPrimaryColor : "#9962B1" }
    `;

    const renderShowFitnessAgeOverTime = () => {
        if (hasMoreThanOneTest) {
            return (
                <div onClick={() => { toggleChartDataChange() }} className="myFitnessAge-results-line myFitnessAge-compare">
                    <CompareButton className="myFitnessAge-compare-button">
                        <CompareButtonInner style={{ display: (compareOverTime ? '' : 'none') }} className="myFitnessAge-button-inner"></CompareButtonInner>
                    </CompareButton>
                    <div className="myFitnessAge-compare-text">
                        Show fitness age across time
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ width: '500px', fontWeight: 'bold' }}>
                    You do not have any previous test results
                </div>
            )
        }
    };

    return (
        <div className="screen-section">
            <div className="myFitnessAge-graph-container">
                <div className="myFitnessAge-graph-and-results-container">
                    <div className="myFitnessAge-graph">
                        <Scatter
                            width={500}
                            data={chartData}
                            options={lineOptions}
                        />
                    </div>

                    <div className="myFitnessAge-graph-results">
                        <div className="myFitnessAge-results-line">Actual age (years): <span>{returnDateOfUser()}</span></div>
                        <div className="myFitnessAge-results-line">Fitness age (years): <span>{userFitnessAge}</span></div>
                    </div>

                </div>

                {/* <div className="myFitnessAge-graph-and-results-container"> */}
                    {renderShowFitnessAgeOverTime()}
                {/* </div> */}
            </div>
        </div>
    );
}

export default MyFitnessAge;
