export const RenderStudentBars = ({
    doc,
    data,
    sitAndReachPercentage,
    sitAndReachAdultPercentageOutOf100,
    standingBroadJumpPercentage,
    standingBroadJumpAdultPercentageOutOf100,
    gripStrengthPercentage,
    gripStrengthAdultPercentageOutOf100,
    verticalJumpPercentage,
    verticalJumpAdultPercentageOutOf100,
    sitUpsPercentage,
    sitUpsAdultPercentageOutOf100,
    inclinedPullUpsPercentage,
    inclinedPullUpsAdultPercentageOutOf100,
    shuttleRunPercentage,
    shuttleRunAdultPercentageOutOf100,
    sprintPercentage,
    sprintAdultPercentageOutOf100,
    walkRunPercentage,
    walkRunAdultPercentageOutOf100,
    beepTestPercentage,
    beepTestAdultPercentageOutOf100,
    primaryColor,
    secondaryColor,
    accentColor
}) => {
    var arrayOfPercentages = [
        {
            studentPercentage: sitAndReachPercentage,
            adultPercentage: sitAndReachAdultPercentageOutOf100
        },
        {
            studentPercentage: standingBroadJumpPercentage,
            adultPercentage: standingBroadJumpAdultPercentageOutOf100
        },
        {
            studentPercentage: sitUpsPercentage,
            adultPercentage: sitUpsAdultPercentageOutOf100
        },
        {
            studentPercentage: gripStrengthPercentage,
            adultPercentage: gripStrengthAdultPercentageOutOf100
        },
        {
            studentPercentage: verticalJumpPercentage,
            adultPercentage: verticalJumpAdultPercentageOutOf100
        },
        {
            studentPercentage: inclinedPullUpsPercentage,
            adultPercentage: inclinedPullUpsAdultPercentageOutOf100
        },
        {
            studentPercentage: shuttleRunPercentage,
            adultPercentage: shuttleRunAdultPercentageOutOf100
        },
        {
            studentPercentage: sprintPercentage,
            adultPercentage: sprintAdultPercentageOutOf100
        },
        {
            studentPercentage: walkRunPercentage,
            adultPercentage: walkRunAdultPercentageOutOf100
        },
        {
            studentPercentage: beepTestPercentage,
            adultPercentage: beepTestAdultPercentageOutOf100
        },
    ]

    const renderBar = (studentPercentage, adultPercentage, xNumber) => {
        if (studentPercentage && studentPercentage !== 0) {
            doc.rect(xNumber, 120 - studentPercentage, 4, studentPercentage, 'F'); // 1 //done // Student result
            doc.setFillColor(accentColor != '' ? accentColor : '84, 179, 132');
            doc.rect((xNumber + 4), 120 - adultPercentage, 4, adultPercentage, 'F'); // 1 //done // Adult percentile
            doc.setFillColor(primaryColor != '' ? primaryColor : '90, 83, 128');
        }
    }

    var xNumber = 20;

    for (var i = 0; i < 10; i++) {
        renderBar(arrayOfPercentages[i].studentPercentage, arrayOfPercentages[i].adultPercentage, xNumber);
        xNumber += 18;
    }
}
