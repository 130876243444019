import React, { useState, useEffect } from 'react';

const SignupStageTwo = ({ setSignupStage, backStage, selectedPlan, setSelectedPlan }) => {

    const [numberOfTests, setNumberOfTests] = useState(100);

    const formatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <div className="signup-wrapper signup-stage-two">
            <div className="signup-step-circle-wrapper"><div className="signup-step-circle" id="signup-step-circle-1"></div></div>
            <div className="signup-step-dash"></div>
            <div className="signup-step-circle-wrapper"><div className="signup-step-circle selected" id="signup-step-circle-2"></div></div>
            <div className="signup-step-dash"></div>
            <div className="signup-step-circle-wrapper"><div className="signup-step-circle" id="signup-step-circle-3"></div></div>

            <div className="signup-title">
                Choose your plan
            </div>

            <div className="signup-box-container">
                <div className={"signup-box" + (selectedPlan == 1 ? ' selected' : '') } onClick={() => setSelectedPlan(1)}>
                    <span className="level-text">Level 1</span>

                    <div className="descriptive-container">
                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>Basic features</span>
                        </div>

                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>0000 tests</span>
                        </div>
                    </div>

                    <div className="cost-container">
                        <span>$00.00</span>
                    </div>
                </div>

                <div className={"signup-box" + (selectedPlan == 2 ? ' selected' : '') } onClick={() => setSelectedPlan(2)}>
                    <span className="level-text">Level 2</span>
                    <div className="descriptive-container">
                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>Basic features</span>
                        </div>

                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>Compare results</span>
                        </div>

                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>0000 tests</span>
                        </div>
                    </div>

                    <div className="cost-container">
                        <span>$00.00</span>
                    </div>
                </div>

                <div className={"signup-box" + (selectedPlan == 3 ? ' selected' : '') } onClick={() => setSelectedPlan(3)}>
                    <span className="level-text">Level 3</span>
                    <div className="descriptive-container">
                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>Basic features</span>
                        </div>

                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>Compare results</span>
                        </div>

                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>Organisation branding</span>
                        </div>

                        <div className="row">
                            <i class="fa fas fa-star"></i>
                            <span>0000 tests</span>
                        </div>
                    </div>
                    
                    <div className="cost-container">
                        <span>$00.00</span>
                    </div>
                </div>
            </div>


            <div className="signup-next-button">
                <input type="submit" value="Previous" onClick={() => {
                    backStage();
                }} />
                <input className={selectedPlan != 0 ? '' : 'disabled'} type="submit" value="Next" onClick={() => {
                    if(selectedPlan != 0) {
                        setSignupStage();
                    }
                }} />
            </div>

        </div>
    )
}

export default SignupStageTwo;