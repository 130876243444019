import React, { useState } from 'react';
import 'font-awesome/css/font-awesome.min.css';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useSelector, useDispatch } from 'react-redux';

import { changeScreen, setUserPassword, setUserBirthMonth, setUserBirthYear, setUserGender, setUserClassName, setUserAge, setUserViewingTest, setFirstTimeViewingTest, setTestDateDay, setTestDateMonth, setTestDateYear, setAdultLeftGripStrength, setAdultRightGripStrength, setUserPreviousTests, setUserBackendId, setUserEditingTest, setUserRole, setUserUOM, setUserBirthDay, setTokenAllocationType } from "../../store/actions/actions";

import {
	setStudentId,
	setMothersHeight,
	setFathersHeight,
	setExpandedMyInfo,
	setExpandedFlexibility,
	setExpandedMuscularStrengthAndPower,
	setExpandedMuscularEndurance,
	setExpandedSpeedAndAgility,
	setExpandedCardiorespiratoryFitness,
	setExpandedBodySizeAndShape,
	setSitAndReach,
	setStandingBroadJump,
	setGripStrength,
	setLeftGripStrength,
	setRightGripStrength,
	setVerticalJump,
	setSitUps,
	setInclinedPullUps,
	setShuttleRun,
	setSprint,
	setSprintDistance,
	setSprintTime,
	setRunWalkMinutes,
	setRunWalkSeconds,
	setVo2Max,
	setBeepTest,
	setHeight,
	setWeight,
	setArmSpan,
	setForearmLength,
	setUpperArmLength,
	setSittingHeight,
	setTestDate,
	resetUserTestData,
	setAllFieldsProvided, setUserInputHeight, setUserInputWeight, setUserInputArmSpan, setUserInputSittingHeight, setUserInputForearmLength, setUserInputUpperArmLength, setUserInputSitAndReach, setUserInputStandingBroadJump, setUserInputGripStrengthLeft, setUserInputGripStrengthRight, setUserInputVerticalJump, setUserInputBentKneeSitUps, setUserInputInclinedPullUps, setUserInputShuttleRun, setUserInputSprintDistance, setUserInputSprintTime, setUserInputWalkRunMinutes, setUserInputWalkRunSeconds, setUserInputVO2MAX, setUserInputShuttleBeepTest, setUserInputYoyoTest, setUserInputYoyoStage, setUserInputYoyoVO2MAX, setUserInputBeepTestVO2MAX, setYoyoStage, setYoyoTest
} from '../../store/actions/myDetails.js'

// ACTIONS
import {
	setUserFitnessAge,
	setAdult50mSprint,
	setAdultGripStrength,
	setAdultInclinedPullUps,
	setAdultHeight,
	setAdultShuttleRun,
	setAdultShuttleBeepTest,
	setAdultSitAndReach,
	setAdultSitUps,
	setAdultStandingBroadJump,
	setAdultVerticalJump,
	setAdultWalkRun,
	setAdultWeight,
	setAdultVo2Max,
	setAdultArmSpan,
	setAdultForearmLength,
	setAdultSittingHeight,
	setSportMatchBodyShapeAndSizeSectionVisited,
	setSportMatchFlexibilitySectionVisited,
	setSportMatchMuscularStrengthAndPowerSectionVisited,
	setSportMatchMuscularEnduranceSectionVisited,
	setSportMatchSpeedAndAgilitySectionVisited,
	setSportMatchAerobicFitnessSectionVisited,
	setPredictedAdultFitnessId,
	setTestId,
	setTestTokenId,
	resetAdultValues,
	setUserId,
	resetUserDetailsValues,
	removeScreenData
} from '../../store/actions/actions.js';

import {
	BACKEND_URL,
	ENVIRONMENT_PATH
} from '../../store/constants';
import { setAccentColor, setAccessUser, setPendingRefreshCompletion, setPlan, setPrimaryColor, setSecondaryColor } from '../../store/actions/OrganisationActions';
import { CircularProgress } from '@material-ui/core';

const Header = () => {
	const user = useSelector(state => state.user);

	const organisationStateLocal = useSelector(state => state.organisationState);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	// Loading Indicator
	const [leaveAccessUserLoading, setLeaveAccessUserLoading] = useState(false);

	const dispatch = useDispatch();

	const handleMenu = event => {
		if (user.userId !== null && user.userId !== '') {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = (elem) => {
		if (elem.currentTarget.getAttribute('data-type') === 'logout') {
			fetch(BACKEND_URL + ENVIRONMENT_PATH + '/Logout', {
				headers: {
					'Accept': 'application/json'
				},
				credentials: "include"
			}).then(function (res) {
				dispatch(setUserId(""));
				window.localStorage.clear();
				setAnchorEl(null);
			});
		} else if (elem.currentTarget.getAttribute('data-type') === 'profile') {
			dispatch(changeScreen(1));
		} else {
			setAnchorEl(null);
		}
	};

	return (
		<div className="header-container" style={{ 'backgroundColor': organisationStateLocal.organisationPrimaryColor != "" ? organisationStateLocal.organisationPrimaryColor : "" }}>
			<div className="logo">
				SportMatch
            </div>
			<div onClick={handleMenu} className="header-menu">
				<i className="fa fa-ellipsis-v"></i>
			</div>

			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={handleClose} data-type={'profile'}>{user.userRole == "organisation" || user.userRole == 'teacher' ? "Dashboard" : "My details"}</MenuItem>
				{organisationStateLocal.accessUser != "" ? <MenuItem onClick={() => {
					setLeaveAccessUserLoading(true);
					fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/AccessUser`, {
						credentials: 'include',
						method: 'POST',
						body: JSON.stringify({ leave: true })
					}).then(res => {
						res.json().then(result => {
							if(result.backToAdmin) dispatch(setAccessUser(""));
							if(!result.showAccessUserControl) dispatch(setAccessUser(""));

							setLeaveAccessUserLoading(false);

                            if (result.is_admin) {
                                dispatch(changeScreen(1));
                                // Unit of mesurement
                            	dispatch(setUserUOM(""));
								dispatch(setTokenAllocationType(""));
                            } else {
                                dispatch(changeScreen(7));
                            }

							dispatch(setTestTokenId(''))
							dispatch(setUserPassword(''))
							dispatch(setUserBirthDay(-1))
							dispatch(setUserBirthMonth(-1))
							dispatch(setUserBirthYear(-1))
							dispatch(setUserGender(''))
							dispatch(setUserClassName(''))
							dispatch(setUserAge(''))
							dispatch(setUserViewingTest(false))
							dispatch(setFirstTimeViewingTest(false))
							dispatch(setUserFitnessAge(0.0))
							dispatch(setTestDateDay(''))
							dispatch(setTestDateMonth(''))
							dispatch(setTestDateYear(''))
							dispatch(setSportMatchBodyShapeAndSizeSectionVisited(false))
							dispatch(setSportMatchFlexibilitySectionVisited(false))
							dispatch(setSportMatchMuscularStrengthAndPowerSectionVisited(false))
							dispatch(setSportMatchMuscularEnduranceSectionVisited(false))
							dispatch(setSportMatchSpeedAndAgilitySectionVisited(false))
							dispatch(setSportMatchAerobicFitnessSectionVisited(false))
							dispatch(setAdultSitAndReach(0))
							dispatch(setAdultStandingBroadJump(0))
							dispatch(setAdultGripStrength(0))
							dispatch(setAdultLeftGripStrength(0))
							dispatch(setAdultRightGripStrength(0))
							dispatch(setAdultVerticalJump(0))
							dispatch(setAdultSitUps(0))
							dispatch(setAdultInclinedPullUps(0))
							dispatch(setAdultShuttleRun(0))
							dispatch(setAdult50mSprint(0))
							dispatch(setAdultWalkRun(0))
							dispatch(setAdultShuttleBeepTest(0))
							dispatch(setAdultVo2Max(0))
							dispatch(setAdultHeight(0))
							dispatch(setAdultWeight(0))
							dispatch(setAdultSittingHeight(0))
							dispatch(setAdultForearmLength(0))
							dispatch(setAdultArmSpan(0))
							dispatch(setPredictedAdultFitnessId(0))
							dispatch(setTestId(0))
							dispatch(setUserPreviousTests([]))
							dispatch(setUserBackendId(''))
							dispatch(setStudentId(''));
							dispatch(setMothersHeight(''));
							dispatch(setUserEditingTest(''));
							dispatch(setFathersHeight(''));
							dispatch(setSitAndReach(''));
							dispatch(setStandingBroadJump(''));
							dispatch(setGripStrength(''));
							dispatch(setLeftGripStrength(''));
							dispatch(setRightGripStrength(''));
							dispatch(setVerticalJump(''));
							dispatch(setSitUps(''));
							dispatch(setInclinedPullUps(''));
							dispatch(setShuttleRun(''));
							dispatch(setSprint(''));
							dispatch(setSprintDistance(''));
							dispatch(setSprintTime(''));
							dispatch(setRunWalkMinutes(''));
							dispatch(setRunWalkSeconds(''));
							dispatch(setVo2Max(''));
							dispatch(setBeepTest(''));
							dispatch(setYoyoStage(''));
							dispatch(setYoyoTest(''));
							dispatch(setHeight(''));
							dispatch(setWeight(''));
							dispatch(setArmSpan(''));
							dispatch(setAllFieldsProvided(false));
							dispatch(setForearmLength(''));
							dispatch(setUpperArmLength(''));
							dispatch(setSittingHeight(''));
							dispatch(setPlan(""));
							dispatch(setTestDate(new Date()));

							// Body shape and size fields
							dispatch(setUserInputHeight(''));
							dispatch(setUserInputWeight(''));
							dispatch(setUserInputArmSpan(''));
							dispatch(setUserInputSittingHeight(''));
							dispatch(setUserInputForearmLength(''));
							dispatch(setUserInputUpperArmLength(''));

							// Flexibility
							dispatch(setUserInputSitAndReach(''));

							// Muscular strength and power
							dispatch(setUserInputStandingBroadJump(''));
							dispatch(setUserInputGripStrengthLeft(''));
							dispatch(setUserInputGripStrengthRight(''));
							dispatch(setUserInputVerticalJump(''));

							// Muscular endurance
							dispatch(setUserInputBentKneeSitUps(''));
							dispatch(setUserInputInclinedPullUps(''));

							// Speed and agility
							dispatch(setUserInputShuttleRun(''));
							dispatch(setUserInputSprintDistance(''));
							dispatch(setUserInputSprintTime(''));

							// Aerobic fitness
							dispatch(setUserInputWalkRunMinutes(''));
							dispatch(setUserInputWalkRunSeconds(''));
							dispatch(setUserInputVO2MAX(''));
							dispatch(setUserInputShuttleBeepTest(''));

							// Unit of mesurement
							dispatch(setUserUOM(""));
							dispatch(setUserInputYoyoTest(''));
							dispatch(setUserInputYoyoStage(''));
							dispatch(setUserInputYoyoVO2MAX(''));
							dispatch(setUserInputBeepTestVO2MAX(''));

							dispatch(setPendingRefreshCompletion(false));

							// Organisation Branding
							if (result.is_admin) {
								dispatch(setPrimaryColor(""));
								dispatch(setSecondaryColor(""));
								dispatch(setAccentColor(""));
							}

							if(result.returnType) {
								// This needs to account for if the organisation is viewing a teacher viewing a student
								if(result.returnType == "teacher") {
									dispatch(setUserRole("teacher"));
								}

								if(result.returnType == "organisation") {
									dispatch(setUserRole("organisation"));
								}
							} else {
								if (result.is_admin) {
									dispatch(setUserId(result.username));
									dispatch(setUserRole("admin"));
								}
							}

							if(result.returnId) {
								dispatch(setUserBackendId(result.returnId));
							}
						})
					})
				}} data-type={'leave'}>Leave Access User {leaveAccessUserLoading && <CircularProgress style={{marginLeft: '10px'}} disableShrink size={20} />}</MenuItem> : null}
				<MenuItem onClick={handleClose} data-type={'logout'}>Logout</MenuItem>
			</Menu>
		</div>
	);
}

export default Header;
