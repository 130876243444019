// TOP SPORT TYPES

// REACTJS
import React, { useState, useEffect } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

// CHARTJS
import { Radar } from 'react-chartjs-2';

import './style.css';
import { CircularProgress } from '@material-ui/core';

// MOMENTJS
import moment from 'moment';
import { ENVIRONMENT_PATH, BACKEND_URL } from '../../../../../../store/constants';
import { CheckIfUserIsLoggedIn } from '../../../../../../api/Helper';

const SportTypesComponent = ({ data: data, forceLoad: forceLoad, setForceLoad: setForceLoad }) => {
    const organisationStateLocal = useSelector(state => state.organisationState);

    const backgroundColorForGraph = organisationStateLocal.organisationPrimaryColor ? organisationStateLocal.organisationPrimaryColor + "a3" : 'rgb(154, 98, 179, .3)';
    const backgroundColorForGraphBorder = organisationStateLocal.organisationPrimaryColor ? organisationStateLocal.organisationPrimaryColor : 'rgb(154, 98, 179)';

    const [rankingOfSportTypes, setRankingOfSportTypes] = useState([['Power']]);

    const [isLoading, setLoading] = useState(true);

    const [top5Sports, setTopSportTypes] = useState(data);

    const dispatch = useDispatch();

    const generateFilterString = (isNew) => {
        // Checking if filters have been applied
        var filters = "";
        if (organisationStateLocal.genderFilters.length != 0) {
            if (isNew) {
                filters += "?gender=" + organisationStateLocal.genderFilters;
            } else {
                filters += "&gender=" + organisationStateLocal.genderFilters;
            }
        }
        if (organisationStateLocal.groupNameFilters.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
                } else {
                    filters += "?groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
                }
            } else {
                filters += "&groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
            }
        }
        if (organisationStateLocal.ageFilters.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
                } else {
                    filters += "?ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
                }
            } else {
                filters += "&ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
            }
        }
        if (organisationStateLocal.dateRangeFromFilter.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
                } else {
                    filters += "?startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
                }
            } else {
                filters += "&startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
            }
        }
        if (organisationStateLocal.dateRangeToFilter.length != 0) {
            if (isNew) {
                if (filters != "") {
                    filters += "&endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
                } else {
                    filters += "?endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
                }
            } else {
                filters += "&endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
            }
        }

        return filters;
    }

    useEffect(() => {
        getRankingForEachSportType();
        setLoading(false);
    }, [JSON.stringify(data)]);

    useEffect(() => {
        if (forceLoad) {
            setForceLoad(false);
        }
    }, [forceLoad]);

    const getValueFromArrayOfObjects = (name, array) => {
        if(array) {
            for(var i = 0; i < array.length; i++) {
                if(array[i].name == name) {
                    return array[i];
                }
            }
        }
    }

    // Generates the raking labels (doesn't work out the ranking overrall)
    const getRankingForEachSportType = () => {
        // If every value is available
        var arrayOfSportTypeValues = [
            { name: 'power', value: getValueFromArrayOfObjects("power", data) ? getValueFromArrayOfObjects("power", data).value : 0 },
            { name: 'speed', value: getValueFromArrayOfObjects("speed", data) ? getValueFromArrayOfObjects("speed", data).value : 0 },
            { name: 'aerobic-fitness', value: getValueFromArrayOfObjects("aerobic-fitness", data) ? getValueFromArrayOfObjects("aerobic-fitness", data).value : 0 },
            { name: 'game-fitness', value: getValueFromArrayOfObjects("game-fitness", data) ? getValueFromArrayOfObjects("game-fitness", data).value : 0 },
            { name: 'skill', value: getValueFromArrayOfObjects("skill", data) ? getValueFromArrayOfObjects("skill", data).value : 0 },
            { name: 'strength', value: getValueFromArrayOfObjects("strength", data) ? getValueFromArrayOfObjects("strength", data).value : 0 },
        ];

        var sortedArrayOfSportTypeValues = arrayOfSportTypeValues.sort(function (a, b) {
            return a.value - b.value;
        });

        setRankingOfSportTypes([
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'power') + 1) + '. Power'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'strength') + 1) + '. Strength'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'speed') + 1) + '. Speed'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'aerobic-fitness') + 1) + '. Aerobic fitness'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'game-fitness') + 1) + '. Game fitness'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'skill') + 1) + '. Skill']
        ])
    }

    return (
        <div className="segment-container segment-small sport-types-segment">
            <div className="heading">
                <span>Sport types</span>
                {isLoading ? <CircularProgress disableShrink={true} size={20} /> : null}
            </div>

            <Radar
                height={70}
                width={200}
                legend={{ display: false }}
                data={{
                    labels: rankingOfSportTypes,
                    datasets: [
                        {
                            label: 'My Second dataset2',
                            backgroundColor: backgroundColorForGraph,
                            borderColor: backgroundColorForGraphBorder,
                            pointBackgroundColor: 'rgba(255,99,132,1)',
                            pointBorderColor: '#fff',
                            pointRadius: 0,
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(255,99,132,1)',
                            data: [
                                data && data[0] ? data[0].value : 0,
                                data && data[3] ? data[3].value : 0,
                                data && data[5] ? data[5].value : 0,
                                data && data[2] ? data[2].value : 0,
                                data && data[4] ? data[4].value : 0,
                                data && data[1] ? data[1].value : 0
                            ],
                        }
                    ]
                }}
                options={{
                    responsive: true,
                    scale: {
                        angleLines: {
                            display: true,
                        },
                        pointLabels: {
                            display: true,
                            fontSize: 15
                        },
                        ticks: {
                            display: false,
                            reverse: true,
                            suggestedMin: 0,
                            suggestedMax: 50,
                            maxTicksLimit: 6,
                            backdropColor: 'rgb(50, 168, 82)'
                        }
                    }
                }} />
        </div>
    )
}

export default SportTypesComponent;