import React from "react";

const SittingHeight2Svg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>sitting_height_2</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="sitting_height_2" data-name="sitting height 2">
            <circle className="cls-2" style={{fill: color}} cx="297.59" cy="112.09" r="38.59"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M306.79,325.58" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M296.41,327.28" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M408.21,354.78H384.83V83.55h23.38a5,5,0,0,0,0-10H351.46a5,5,0,0,0,0,10h23.37V354.78H351.46a5,5,0,0,0,0,10h56.75a5,5,0,1,0,0-10Z"
                  transform="translate(-2.83 -7.46)"/>
            <rect className="cls-2" style={{fill: color}} x="195.7" y="360.12" width="143.95" height="120.41" rx="18.85" ry="18.85"/>
            <path className="cls-2" style={{fill: color}}
                  d="M278.79,175.74,197.07,310a14.69,14.69,0,0,0-1.59,11.44,18.54,18.54,0,0,0,8.93,11.24c8.72,4.94,19.6,2.73,24.25-4.91L310.38,193.5A14.7,14.7,0,0,0,312,182.06,18.54,18.54,0,0,0,303,170.82a20.15,20.15,0,0,0-9.89-2.64C287.28,168.18,281.76,170.85,278.79,175.74Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M328.87,191.51c5.32-19.9-22.66-39-38.42-26.14a22.88,22.88,0,0,1,14.06,2.83,21.53,21.53,0,0,1,10.36,13.11,17.63,17.63,0,0,1-1.93,13.75l-58.27,95.76a265.67,265.67,0,0,0,3.7,26.57H238.5l-7.28,11.95c-3.53,5.81-10.05,9-16.94,9a23.13,23.13,0,0,1-11.34-3,21.61,21.61,0,0,1-10.37-13.11,18.92,18.92,0,0,1-.58-4.82h-20.1a22.5,22.5,0,0,0-22.5,22.5V451.33a22.5,22.5,0,1,0,45,0V362.39h95.27c1,0,2,0,2.9,0h.52c.49,0,1,0,1.44-.05l.25,0a22.25,22.25,0,0,0,4.71-.86,35.64,35.64,0,0,0,14.37-7.1c12.19-9.24,18.5-28.71,18.59-45.95C332.58,282.82,310.24,261.23,328.87,191.51Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default SittingHeight2Svg;
