import React from "react";

const ArmSpanSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>arm_span_5</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="arm_span_5" data-name="arm span 5">
            <path className="cls-2" style={{fill: color}} d="M283.88,336.52" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M283.88,338.45" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M520.86,220H46.91a22.5,22.5,0,1,0,0,45H230V378.61a53.89,53.89,0,0,0,53.89,53.89h0a53.89,53.89,0,0,0,53.89-53.89V265H520.86a22.5,22.5,0,0,0,0-45Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M248.41,143.56a41.86,41.86,0,1,0,76.27,12.86h0a41.86,41.86,0,0,0-76.27-12.86Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M240.11,179.41H42.62V165.79a5,5,0,0,0-10,0V203a5,5,0,0,0,10,0V189.41h202A45.61,45.61,0,0,1,240.11,179.41Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M530.15,160.79a5,5,0,0,0-5,5v13.62H327.65a45.9,45.9,0,0,1-4.5,10h202V203a5,5,0,0,0,10,0V165.79A5,5,0,0,0,530.15,160.79Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default ArmSpanSvg;
