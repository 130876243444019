import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';

// Field Info
import { fieldInfo } from './fieldInfoContent.js';

// COMPONENTS
import { MyDetailsHeader } from '../../../components/MyDetailsHeader';
import {
    UserDefinedTextField,
    SliderField,
    RadioField,
    UserDefinedSprintTestControls,
    UserDefinedSprintSpeedValue
} from '../../../components/FormInputs.js';
// ACTIONS
import {
    setShuttleRun,
    setSprint,
    setSprintDistance,
    setSprintTime,
    setExpandedSpeedAndAgility,
    setUserInputShuttleRun,
    setUserInputSprintTime,
    setUserInputSprintDistance
} from '../../../store/actions/myDetails.js';

// ICONS
import SpeedAgilitySvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/SpeedAgilitySvg.js';

const IM = require("convert-units");

const SpeedAgility = ({infoOpen, setInfoOpen:setInfoOpen}) => {
    const dispatch = useDispatch();
    const state = useSelector(store => store.myDetails);
    const sportMatchState = useSelector(store => store.sportMatchTest);
    const organisationStateLocal = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    // Local State
    const [expanded, setExpanded] = useState(false);
    const [localDistanceValue, setLocalDistanceValue] = useState(sportMatchState.userInputSprintDistance || 0); // Distance Value
    const [localTimeValue, setLocalTimeValue] = useState(sportMatchState.userInputSprintTime || 0); // Time Value

    const[localAverageSpeed, setLocalAverageSpeed] = useState(0);

    const dispatchShuttleRun = (input) => {
        dispatch(setUserInputShuttleRun(input));
    }

    // Save Sprint Distance data to Redux
    const dispatchSprintDistance = input => {
        dispatch(setUserInputSprintDistance(input));
    }

    // Save Sprint Time data to Redux
    const dispatchSprintTime = input => {
        dispatch(setUserInputSprintTime(input));
    };

    const getUOMValue = (convertTo, userState, value, explicitFromUOM = "") => {
        if(convertTo == "foot" || convertTo == "ft") {
            if(userState.userUOM == "Imperial") {
                return parseFloat(IM(value).from(explicitFromUOM != "" ? explicitFromUOM : "cm").to('ft')).toFixed(2);
            } else {
                return value;
            }
        }
    
        if(convertTo == "lbs") {
            if(userState.userUOM == "Imperial") {
                return parseFloat(IM(value).from('kg').to('lb')).toFixed(2);
            } else {
                return value;
            }
        }
    
        if(convertTo == "in") {
            if(userState.userUOM == "Imperial") {
                return parseFloat(IM(value).from('cm').to('in')).toFixed(2);
            } else {
                return value;
            }
        }
    }

    const minimumDistance = userState.userUOM == "Imperial" ? parseFloat(getUOMValue('ft', userState, 20, 'm') / 3) : 20;
    const maximumDistance = userState.userUOM == "Imperial" ? parseFloat(getUOMValue('ft', userState, 100, 'm') / 3) : 100;

    const getFieldsChanged = () => {
        if (sportMatchState.userInputShuttleRun != "" && state.shuttleRun == "") {
            return true;
        } else if (sportMatchState.userInputSprintDistance != "" && state.sprintDistance == "") {
            return true;
        } else if (sportMatchState.userInputSprintTime != "" && state.sprintTime == "") {
            return true;
        } else {
            return false;
        }
    }

    const resetButtonHandler = () => {
        if (state.shuttleRun == "") {
            dispatch(setUserInputShuttleRun(""));
        }

        if (state.sprintDistance == "") {
            dispatch(setUserInputSprintDistance(""));
            setLocalDistanceValue(0);
        }

        if (state.sprintTime == "") {
            dispatch(setUserInputSprintTime(""));
            setLocalTimeValue(0);
        }
    }

    // Save local Sprint Speed
    const averageSprintSpeedLocal = () => {
        var returnedArray = [];
        if (localDistanceValue !== 0 && localTimeValue !== 0) {
            var distance = localDistanceValue;

            if(userState.userUOM == "Imperial") distance = (distance * 3);

            if(userState.userUOM == "Imperial") distance = IM(distance).from("ft").to("m");

            var averageSpeed = (parseFloat(distance) / (parseFloat(localTimeValue))) * 60 * 60 / 1000;
            var metersPerMinute = (parseFloat(distance) * (60 / parseFloat(localTimeValue)));
            var kilometersPerHour = parseFloat((metersPerMinute * 60) / 1000).toFixed(1);

            returnedArray.push(kilometersPerHour);

            var topSpeedPercentage = ((-0.000007 * Math.pow(distance, 4)) + (0.001809 * Math.pow(distance, 3)) - (0.171475 * Math.pow(distance, 2)) + (7.07494368 * distance) - 8 - (0.028 * distance));
            var topSpeed =  (averageSpeed / topSpeedPercentage) * 100;
            returnedArray.push(topSpeed.toFixed(1));


            if(userState.userUOM == "Imperial") {
                returnedArray[0] = parseFloat((returnedArray[0] / 1.609)).toFixed(2);
                returnedArray[1] = parseFloat((returnedArray[1] / 1.609)).toFixed(2);
            }

            return returnedArray;
        } else {
            returnedArray.push(0);
            returnedArray.push(0);
            return returnedArray;
        }
    }

    const calculateTopSpeed = () => {
        var maxSpeedPercentage = 0;
        if(localAverageSpeed !== 0 && localDistanceValue !== 0) {
            maxSpeedPercentage = ((-0.000007 * Math.pow(localDistanceValue, 4)) + (0.001809 * Math.pow(localDistanceValue, 3)) - (0.171475 * Math.pow(localDistanceValue, 2)) + (7.07494368 * localDistanceValue) - 8 - (0.028 * localDistanceValue));
        }

        return maxSpeedPercentage;
    }

    const speedAgilityIcon = () => {
        return <SpeedAgilitySvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'} />;
    }

    return (
        <div className="screen-section test-section">
            <MyDetailsHeader children={[]} title={"Speed and agility"} icon={speedAgilityIcon()} isIconSvg={false} expand={true} expanded={true} fieldChanged={getFieldsChanged()} resetBtnHandler={resetButtonHandler}/>
            <div className={`myDetails-fields speed-and-agility-fields expanded`}>

                <SliderField
                    action={dispatchShuttleRun}
                    label={userState.userUOM == "Imperial" ? "4 x 11 yd shuttle run" : "4 x 10 m shuttle run"}
                    placeholder={"seconds"}
                    info={userState.userUOM == "Imperial" ? fieldInfo.shuttleRun_imperial : fieldInfo.shuttleRun}
                    enteredValue={sportMatchState.userInputShuttleRun}
                    minValue={7}
                    maxValue={20}
                    incrementalValue={0.1}
                    labelUOM={"seconds"}
                    infoOpen={infoOpen}
                    setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.shuttleRun != ""}
                />
                <UserDefinedSprintTestControls
                    distanceValue={sportMatchState.userInputSprintDistance}
                    timeValue={sportMatchState.userInputSprintTime}
                    localDistanceValue={localDistanceValue}
                    localTimeValue={localTimeValue}
                    setLocalDistanceValue={setLocalDistanceValue}
                    setLocalTimeValue={setLocalTimeValue}
                    actionDistance={dispatchSprintDistance}
                    actionTime={dispatchSprintTime}
                    label={"Sprint"}
                    placeholder={"seconds"}
                    info={userState.userUOM == "Imperial" ? fieldInfo.sprint_imperial : fieldInfo.sprint}
                    distanceMinimum={minimumDistance}
                    distanceMaximum={maximumDistance}
                    minValue={2}
                    maxValue={30}
                    infoOpen={infoOpen}
                    setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.sprintDistance != "" && state.sprintTime != ""}
                />
                <UserDefinedSprintSpeedValue
                    averageSprintSpeed={averageSprintSpeedLocal()}
                    topSpeed={calculateTopSpeed()}
                    info={fieldInfo.sprint}
                    averageSpeedInfo={userState.userUOM == "Imperial" ? fieldInfo.averageSpeed_imperial : fieldInfo.averageSpeed}
                    maximumSpeedInfo={userState.userUOM == "Imperial" ? fieldInfo.maximumSpeed_imperial : fieldInfo.maximumSpeed}
                    infoOpen={infoOpen}
                    setInfoOpen={setInfoOpen}
                />
            </div>
        </div>
    );
}

export default SpeedAgility;
