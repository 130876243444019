import React from 'react';
// REDUX
import { useSelector } from 'react-redux';
import { ConvertToFeetAndInches } from '../../../helper/helper';

const FindMySportBars = ({ userScore, showAdultValues, adultScore, userThumbHoverOver, setAdultScore, setUserThumbHoverOver, calculatePercentage, minimum, maximum, isTimedBased, minuteSecondsFormatter, onMouseUpHandler, isHeightMesurement = false, step }) => {
    const organisationLocalState = useSelector(state => state.organisationState);
    const reduxState = useSelector(state => state);

    const renderUserContent = () => {
        if (minuteSecondsFormatter) {
            return (
                <span style={{color: organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#1db30c'}}>{minuteSecondsFormatter(parseFloat(userScore).toFixed(1))}</span>
            )
        } else {
            if (step != null && step.indexOf(".") == -1) {
                return (
                    <span style={{color: organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#1db30c'}}>
                        {isHeightMesurement && reduxState.user.userUOM == "Imperial" ? ConvertToFeetAndInches(userScore) : parseFloat(userScore).toFixed(0)}
                    </span>
                )
            } else {
                return (
                    <span style={{color: organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#1db30c'}}>
                        {isHeightMesurement && reduxState.user.userUOM == "Imperial" ? ConvertToFeetAndInches(userScore) : parseFloat(userScore).toFixed(1)}
                    </span>
                )
            }
        }
    }
    
    const renderAthleteContent = () => {
        if (minuteSecondsFormatter) {
            return (
                <span style={{color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}}>{minuteSecondsFormatter(parseFloat(adultScore).toFixed(1))}</span>
            )
        } else {
            if (step != null && step.indexOf(".") == -1) {
                return (
                    <span style={{color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}}>
                        {isHeightMesurement && reduxState.user.userUOM == "Imperial" ? ConvertToFeetAndInches(adultScore) : parseFloat(adultScore).toFixed(0)}
                    </span>
                )
            } else {
                return (
                    <span style={{color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}}>
                        {isHeightMesurement && reduxState.user.userUOM == "Imperial" ? ConvertToFeetAndInches(adultScore) : parseFloat(adultScore).toFixed(1)}
                    </span>
                )
            }
        }
    }
    const maxPercentage = (min, max, user) => {
        var calc = calculatePercentage(min, parseFloat(user), max);
        if (calc < -8.75 && !isTimedBased) {
            return 0;
        } else if (calc < -8.75 && isTimedBased) {
            return 0;
        } else if (calc > 100) {
            return 100
        } else {
            return calc;
        }
    }
    const changeHandler = (e) =>{
        setAdultScore(e)
    }

    return (
        <div className="new-slider slider-section">
            <div className={`${isTimedBased ? "timed-based" : ""} additional-slider-section`}>
                {userScore ?
                    <React.Fragment>
                        <div className="elite-athlete-score-tag" style={{ opacity: showAdultValues ? '1' : '0', bottom: adultScore !== "" ? maxPercentage(minimum, maximum, parseFloat(adultScore)) + "%" : "0px" }}>
                            {renderAthleteContent()}
                        </div>
                        <div className="student-athlete-score-tag" style={{ opacity: (!showAdultValues || userThumbHoverOver) && userScore !== '' ? '1' : '0', bottom: userScore !== "" ? maxPercentage(minimum, maximum, parseFloat(userScore)) + "%" : "0px" }}>
                            {renderUserContent()}
                        </div>
                        <div
                            onMouseEnter={() => {
                                setUserThumbHoverOver(true);
                            }}
                            onMouseLeave={() => {
                                setUserThumbHoverOver(false);
                            }} className="studentValue" style={{ display: userScore !== "" ? "inline-block" : "none", bottom: userScore !== "" ? maxPercentage(minimum, maximum, parseFloat(userScore)) + "%" : "0px", border: organisationLocalState.organisationAccentColor != '' ? '3px solid ' + organisationLocalState.organisationAccentColor : '3px solid #50B383' }}>

                        </div>
                    </React.Fragment> :
                    <div className="elite-athlete-score-tag" style={{ opacity: showAdultValues ? '1' : '0', bottom: adultScore !== "" ? maxPercentage(minimum, maximum, parseFloat(adultScore)) + "%" : "0px" }}>
                        {renderAthleteContent()}
                    </div>
                }
            </div>

            <input 
                onMouseUp={onMouseUpHandler} 
                onChange={(e) => changeHandler(e.target.value)} 
                className={`${isTimedBased ? "timed-based " : ""}findMySport-myResults-field-slider ` + (showAdultValues ? " show-adult-values" : "")} 
                type="range" step={step ? step : "0.5"} min={minimum} max={maximum} value={adultScore} 
                style={{"--value": organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}} />
        </div>
    )
}

export default FindMySportBars;
