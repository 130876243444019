import {
    SET_ADULT_SIT_AND_REACH,
    SET_ADULT_STANDING_BROAD_JUMP,
    SET_ADULT_GRIP_STRENGTH,
    SET_ADULT_LEFT_GRIP_STRENGTH,
    SET_ADULT_RIGHT_GRIP_STRENGTH,
    SET_ADULT_VERTICAL_JUMP,
    SET_ADULT_SIT_UPS,
    SET_ADULT_INCLINED_PULL_UPS,
    SET_ADULT_SHUTTLE_RUN,
    SET_ADULT_50M_SPRINT,
    SET_ADULT_WALK_RUN,
    SET_ADULT_SHUTTLE_BEEP_TEST,
    SET_ADULT_VO2_MAX,
    SET_ADULT_HEIGHT,
    SET_ADULT_WEIGHT,
    SET_PREDICTED_ADULT_FITNESS_ID,
    SET_TEST_ID,
    SET_ADULT_FOREARM_LENGTH,
    SET_ADULT_SITTING_HEIGHT,
    SET_ADULT_ARM_SPAN,
    RESET_APP
} from '../constants';

const initialState = {
    adultSitAndReach: parseFloat(localStorage.getItem("adultSitAndReach")) || 0,
    adultStandingBroadJump: parseFloat(localStorage.getItem("adultStandingBroadJump")) || 0,
    adultGripStrength: parseFloat(localStorage.getItem("adultGripStrength")) || 0,
    adultLeftGripStrength: parseFloat(localStorage.getItem("adultLeftGripStrength")) || 0,
    adultRightGripStrength: parseFloat(localStorage.getItem("adultRightGripStrength")) || 0,
    adultVerticalJump: parseFloat(localStorage.getItem("adultVerticalJump")) || 0,
    adultSitUps: parseFloat(localStorage.getItem("adultSitUps")) || 0,
    adultInclinedPullUps: parseFloat(localStorage.getItem("adultInclinedPullUps")) || 0,
    adultShuttleRun: parseFloat(localStorage.getItem("adultShuttleRun")) || 0,
    adult50mSprint: parseFloat(localStorage.getItem("adult50mSprint")) || 0,
    adultWalkRun: parseFloat(localStorage.getItem("adultWalkRun")) || 0,
    adultBeepTest: parseFloat(localStorage.getItem("adultBeepTest")) || 0,
    adultVo2Max: parseFloat(localStorage.getItem("adultVo2Max")) || 0,
    adultHeight: parseFloat(localStorage.getItem("adultHeight")) || 0,
    adultWeight: parseFloat(localStorage.getItem("adultWeight")) || 0,
    predictedAdultFitnessId: parseFloat(localStorage.getItem("predictedAdultFitnessId")) || 0,
    testId: parseFloat(localStorage.getItem("testId")) || 0,
    adultForearmLength: parseFloat(localStorage.getItem("adultForearmLength")) || 0,
    adultSittingHeight: parseFloat(localStorage.getItem("adultSittingHeight")) || 0,
    adultArmSpan: parseFloat(localStorage.getItem("adultArmSpan")) || 0
}

export const adultValuesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case RESET_APP:
            return initialState;
        case SET_ADULT_SIT_AND_REACH:
            localStorage.setItem("adultSitAndReach", action.payload);
            return { ...state, adultSitAndReach: action.payload };
        case SET_ADULT_STANDING_BROAD_JUMP:
            localStorage.setItem("adultStandingBroadJump", action.payload);
            return { ...state, adultStandingBroadJump: action.payload };
        case SET_ADULT_GRIP_STRENGTH:
            localStorage.setItem("adultGripStrength", action.payload);
            return { ...state, adultGripStrength: action.payload };
        case SET_ADULT_LEFT_GRIP_STRENGTH:
            localStorage.setItem("adultLeftGripStrength", action.payload);
            return { ...state, adultGripStrength: action.payload };
        case SET_ADULT_RIGHT_GRIP_STRENGTH:
            localStorage.setItem("adultRightGripStrength", action.payload);
            return { ...state, adultGripStrength: action.payload };
        case SET_ADULT_VERTICAL_JUMP:
            localStorage.setItem("adultVerticalJump", action.payload);
            return { ...state, adultVerticalJump: action.payload };
        case SET_ADULT_SIT_UPS:
            localStorage.setItem("adultSitUps", action.payload);
            return { ...state, adultSitUps: action.payload };
        case SET_ADULT_INCLINED_PULL_UPS:
            localStorage.setItem("adultInclinedPullUps", action.payload);
            return { ...state, adultInclinedPullUps: action.payload };
        case SET_ADULT_SHUTTLE_RUN:
            localStorage.setItem("adultShuttleRun", action.payload);
            return { ...state, adultShuttleRun: action.payload };
        case SET_ADULT_50M_SPRINT:
            localStorage.setItem("adult50mSprint", action.payload);
            return { ...state, adult50mSprint: action.payload };
        case SET_ADULT_WALK_RUN:
            localStorage.setItem("adultWalkRun", action.payload);
            return { ...state, adultWalkRun: action.payload };
        case SET_ADULT_SHUTTLE_BEEP_TEST:
            localStorage.setItem("adultBeepTest", action.payload);
            return { ...state, adultBeepTest: action.payload };
        case SET_ADULT_VO2_MAX:
            localStorage.setItem("adultVo2Max", action.payload);
            return { ...state, adultVo2Max: action.payload };
        case SET_ADULT_HEIGHT:
            localStorage.setItem("adultHeight", action.payload);
            return { ...state, adultHeight: action.payload };
        case SET_ADULT_WEIGHT:
            localStorage.setItem("adultWeight", action.payload);
            return { ...state, adultWeight: action.payload };
        case SET_PREDICTED_ADULT_FITNESS_ID:
            localStorage.setItem("predictedAdultFitnessId", action.payload);
            return { ...state, predictedAdultFitnessId: action.payload };
        case SET_TEST_ID:
            localStorage.setItem("testId", action.payload);
            return { ...state, testId: action.payload };
        case SET_ADULT_FOREARM_LENGTH:
            localStorage.setItem("adultForearmLength", action.payload);
            return { ...state, adultForearmLength: action.payload };
        case SET_ADULT_SITTING_HEIGHT:
            localStorage.setItem("adultSittingHeight", action.payload);
            return { ...state, adultSittingHeight: action.payload };
        case SET_ADULT_ARM_SPAN:
            localStorage.setItem("adultArmSpan", action.payload);
            return { ...state, adultArmSpan: action.payload };
        default:
            return state;
    }
}