import {
    SET_STUDENT_ID,
    SET_MOTHERS_HEIGHT,
    SET_FATHERS_HEIGHT,
    SET_SIT_AND_REACH,
    SET_STANDING_BROAD_JUMP,
    SET_GRIP_STRENGTH,
    SET_LEFT_GRIP_STRENGTH,
    SET_RIGHT_GRIP_STRENGTH,
    SET_VERTICAL_JUMP,
    SET_SIT_UPS,
    SET_INCLINED_PULL_UPS,
    SET_SHUTTLE_RUN,
    SET_SPRINT,
    SET_SPRINT_DISTANCE,
    SET_SPRINT_TIME,
    SET_RUN_WALK_MINUTES,
    SET_RUN_WALK_SECONDS,
    SET_BEEP_TEST,
    SET_YOYO_TEST,
    SET_YOYO_STAGE,
    SET_VO2_MAX,
    SET_YOYO_VO2_MAX,
    SET_HEIGHT,
    SET_WEIGHT,
    SET_ARM_SPAN,
    SET_FOREARM_LENGTH,
    SET_UPPER_ARM_LENGTH,
    SET_SITTING_HEIGHT,
    SET_TEST_DATE,
    SET_INFO_SECTION_EXPANDED,
    SET_FLEXABILITY_SECTION_EXPANDED,
    SET_MUSCULAR_STRENGTH_AND_POWER_EXPANDED,
    SET_MUSCULAR_ENDURANCE_EXPANDED,
    SET_SPEED_AND_AGILITY_EXPANDED,
    SET_CARDIORESPIRATORY_FITNESS_EXPANDED,
    SET_BODY_SIZE_AND_SHAPE_EXPANDED,
    // Sections visited
    SET_SPORT_MATCH_BODY_SHAPE_AND_SIZE_SECTION_VISITED,
    SET_SPORT_MATCH_FLEXIBILITY_SECTION_VISITED,
    SET_SPORT_MATCH_MUSCULAR_STRENGTH_AND_POWER_SECTION_VISITED,
    SET_SPORT_MATCH_MUSCULAR_ENDURANCE_SECTION_VISITED,
    SET_SPORT_MATCH_SPEED_AND_AGILITY_SECTION_VISITED,
    SET_SPORT_MATCH_AEROBIC_FITNESS_SECTION_VISITED,
    SET_ALL_FIELDS_PROVIDED
} from '../constants';

const initialState = {
    mothersHeight: localStorage.getItem("mothersHeight") || "",
    fathersHeight: localStorage.getItem("fathersHeight") || "",
    sitAndReach: localStorage.getItem("sitAndReach") || "",
    standingBroadJump: localStorage.getItem("standingBroadJump") || "",
    gripStrength: localStorage.getItem("gripStrength") || "",
    leftGripStrength: localStorage.getItem("leftGripStrength") || "",
    rightGripStrength: localStorage.getItem("rightGripStrength") || "",
    verticalJump: localStorage.getItem("verticalJump") || "",
    sitUps: localStorage.getItem("sitUps") || "",
    inclinedPullUps: localStorage.getItem("inclinedPullUps") || "",
    shuttleRun: localStorage.getItem("shuttleRun") || "",
    sprint: localStorage.getItem("sprint") || "",
    sprintDistance: localStorage.getItem("sprintDistance") || "",
    sprintTime: localStorage.getItem("sprintTime") || "",
    runWalkMinutes: localStorage.getItem("runWalkMinutes") || "",
    runWalkSeconds: localStorage.getItem("runWalkSeconds") || "",
    beepTest: localStorage.getItem("beepTest") || "",
    yoyoTest: localStorage.getItem("yoyoTest") || "",
    yoyoStage: localStorage.getItem("yoyoStage") || "",
    vo2Max: localStorage.getItem("vo2Max") || "",
    yoyoVo2Max: localStorage.getItem("yoyoVo2Max") || "",
    height: localStorage.getItem("height") || "",
    weight: localStorage.getItem("weight") || "",
    armSpan: localStorage.getItem("armSpan") || "",
    forearmLength: localStorage.getItem("forearmLength") || "",
    upperArmLength: localStorage.getItem("upperArmLength") || "",
    sittingHeight: localStorage.getItem("sittingHeight") || "",
    testDate: localStorage.getItem("testDate") || new Date(),
    infoSectionExpanded: true,
    flexabilitySectionExpanded: false,
    muscularStrengthAndPowerSectionExpanded: false,
    muscularEnduranceSectionExpanded: false,
    speedAndAgilitySectionExpanded: false,
    cardiorespiratoryFitnessSectionExpanded: false,
    bodySizeAndShapeSectionExpanded: false,
    sportMatchBodyShapeAndSizeSectionVisited: false,
    sportMatchFlexibilitySectionVisited: false,
    sportMatchMuscularStrengthAndPowerSectionVisited: false,
    sportMatchMuscularEnduranceSectionVisited: false,
    sportMatchSpeedAndAgilitySectionVisited: false,
    sportMatchAerobicFitnessSectionVisited: false,
    allFieldsProvided: localStorage.getItem("allFieldsProvided") || false
}

export const myDetailsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case "RESET_DATA":
            return initialState;
        case SET_STUDENT_ID:
            return { ...state, studentId: action.payload }
        case SET_MOTHERS_HEIGHT:
            localStorage.setItem("mothersHeight", action.payload);
            return { ...state, mothersHeight: action.payload }
        case SET_FATHERS_HEIGHT:
            localStorage.setItem("fathersHeight", action.payload);
            return { ...state, fathersHeight: action.payload }
        case SET_SIT_AND_REACH:
            localStorage.setItem("sitAndReach", action.payload);
            return { ...state, sitAndReach: action.payload }
        case SET_STANDING_BROAD_JUMP:
            localStorage.setItem("standingBroadJump", action.payload);
            return { ...state, standingBroadJump: action.payload }
        case SET_GRIP_STRENGTH:
            localStorage.setItem("gripStrength", action.payload);
            return { ...state, gripStrength: action.payload }
        case SET_LEFT_GRIP_STRENGTH:
            localStorage.setItem("leftGripStrength", action.payload);
            return { ...state, leftGripStrength: action.payload }
        case SET_RIGHT_GRIP_STRENGTH:
            localStorage.setItem("rightGripStrength", action.payload);
            return { ...state, rightGripStrength: action.payload }
        case SET_VERTICAL_JUMP:
            localStorage.setItem("verticalJump", action.payload);
            return { ...state, verticalJump: action.payload }
        case SET_SIT_UPS:
            localStorage.setItem("sitUps", action.payload);
            return { ...state, sitUps: action.payload }
        case SET_INCLINED_PULL_UPS:
            localStorage.setItem("inclinedPullUps", action.payload);
            return { ...state, inclinedPullUps: action.payload }
        case SET_SHUTTLE_RUN:
            localStorage.setItem("shuttleRun", action.payload);
            return { ...state, shuttleRun: action.payload }
        case SET_SPRINT:
            localStorage.setItem("sprint", action.payload);
            return { ...state, sprint: action.payload }
        case SET_SPRINT_DISTANCE:
            localStorage.setItem("sprintDistance", action.payload);
            return { ...state, sprintDistance: action.payload }
        case SET_SPRINT_TIME:
            localStorage.setItem("sprintTime", action.payload);
            return { ...state, sprintTime: action.payload }
        case SET_RUN_WALK_MINUTES:
            localStorage.setItem("runWalkMinutes", action.payload);
            return { ...state, runWalkMinutes: action.payload }
        case SET_RUN_WALK_SECONDS:
            localStorage.setItem("runWalkSeconds", action.payload);
            return { ...state, runWalkSeconds: action.payload }
        case SET_BEEP_TEST:
            localStorage.setItem("beepTest", action.payload);
            return { ...state, beepTest: action.payload }
        case SET_YOYO_STAGE:
            localStorage.setItem("yoyoStage", action.payload);
            return { ...state, yoyoStage: action.payload }
        case SET_YOYO_TEST:
            localStorage.setItem("yoyoTest", action.payload);
            return { ...state, yoyoTest: action.payload }
        case SET_VO2_MAX:
            localStorage.setItem("vo2Max", action.payload);
            return { ...state, vo2Max: action.payload }
        case SET_YOYO_VO2_MAX:
            localStorage.setItem("yoyoVo2Max", action.payload);
            return { ...state, yoyoVo2Max: action.payload }
        case SET_HEIGHT:
            localStorage.setItem("height", action.payload);
            return { ...state, height: action.payload }
        case SET_WEIGHT:
            localStorage.setItem("weight", action.payload);
            return { ...state, weight: action.payload }
        case SET_ARM_SPAN:
            localStorage.setItem("armSpan", action.payload);
            return { ...state, armSpan: action.payload }
        case SET_FOREARM_LENGTH:
            localStorage.setItem("forearmLength", action.payload);
            return { ...state, forearmLength: action.payload }
        case SET_UPPER_ARM_LENGTH:
            localStorage.setItem("upperArmLength", action.payload);
            return { ...state, upperArmLength: action.payload }
        case SET_SITTING_HEIGHT:
            localStorage.setItem("sittingHeight", action.payload);
            return { ...state, sittingHeight: action.payload }
        case SET_TEST_DATE:
            localStorage.setItem("testDate", action.payload);
            return { ...state, testDate: action.payload }
        case SET_INFO_SECTION_EXPANDED:
            localStorage.setItem("infoSectionExpanded", action.payload);
            return { ...state, infoSectionExpanded: action.payload }
        case SET_FLEXABILITY_SECTION_EXPANDED:
            localStorage.setItem("flexabilitySectionExpanded", action.payload);
            return { ...state, flexabilitySectionExpanded: action.payload }
        case SET_MUSCULAR_STRENGTH_AND_POWER_EXPANDED:
            localStorage.setItem("muscularStrengthAndPowerSectionExpanded", action.payload);
            return { ...state, muscularStrengthAndPowerSectionExpanded: action.payload }
        case SET_MUSCULAR_ENDURANCE_EXPANDED:
            localStorage.setItem("muscularEnduranceSectionExpanded", action.payload);
            return { ...state, muscularEnduranceSectionExpanded: action.payload }
        case SET_SPEED_AND_AGILITY_EXPANDED:
            localStorage.setItem("speedAndAgilitySectionExpanded", action.payload);
            return { ...state, speedAndAgilitySectionExpanded: action.payload }
        case SET_CARDIORESPIRATORY_FITNESS_EXPANDED:
            localStorage.setItem("cardiorespiratoryFitnessSectionExpanded", action.payload);
            return { ...state, cardiorespiratoryFitnessSectionExpanded: action.payload }
        case SET_BODY_SIZE_AND_SHAPE_EXPANDED:
            localStorage.setItem("bodySizeAndShapeSectionExpanded", action.payload);
            return { ...state, bodySizeAndShapeSectionExpanded: action.payload }
        // Test sections visited status
        case SET_SPORT_MATCH_BODY_SHAPE_AND_SIZE_SECTION_VISITED:
            localStorage.setItem("sportMatchBodyShapeAndSizeSectionVisited", action.payload);
            return { ...state, sportMatchBodyShapeAndSizeSectionVisited: action.payload }
        case SET_SPORT_MATCH_FLEXIBILITY_SECTION_VISITED:
            localStorage.setItem("sportMatchFlexibilitySectionVisited", action.payload);
            return { ...state, sportMatchFlexibilitySectionVisited: action.payload }
        case SET_SPORT_MATCH_MUSCULAR_STRENGTH_AND_POWER_SECTION_VISITED:
            localStorage.setItem("sportMatchMuscularStrengthAndPowerSectionVisited", action.payload);
            return { ...state, sportMatchMuscularStrengthAndPowerSectionVisited: action.payload }
        case SET_SPORT_MATCH_MUSCULAR_ENDURANCE_SECTION_VISITED:
            localStorage.setItem("sportMatchMuscularEnduranceSectionVisited", action.payload);
            return { ...state, sportMatchMuscularEnduranceSectionVisited: action.payload }
        case SET_SPORT_MATCH_SPEED_AND_AGILITY_SECTION_VISITED:
            localStorage.setItem("sportMatchSpeedAndAgilitySectionVisited", action.payload);
            return { ...state, sportMatchSpeedAndAgilitySectionVisited: action.payload }
        case SET_SPORT_MATCH_AEROBIC_FITNESS_SECTION_VISITED:
            localStorage.setItem("sportMatchAerobicFitnessSectionVisited", action.payload);
            return { ...state, sportMatchAerobicFitnessSectionVisited: action.payload }
        case SET_ALL_FIELDS_PROVIDED:
            localStorage.setItem("allFieldsProvided", action.payload);
            return { ...state, allFieldsProvided: action.payload }
        default:
            return state
    }

}
