import React from 'react';

// MATERIAL UI
import { TextField } from '@material-ui/core';

const AccountDetailsTypography = ({type, labelText, labelDescriptionText}) => {
    const styles = {
        organisationLinkHeading: {
            flex: '2'
        },
        organisationLinkDescription: {
            paddingLeft: '0px',
            flex: '3',
            wordBreak: 'break-all',
            fontSize: '15px',
            textDecoration: 'underline',
            color: 'blue',
            cursor: 'pointer'
        }
    }
    return (
        <div className="account-credentials-container">
            <div className="label-text" style={type == "organisationLink" ? styles.organisationLinkHeading : null}>{labelText}</div>
            <div className="label-description" onClick={type == "organisationLink" ? e => window.location.href = e.target.textContent : null} style={type == "organisationLink" ? styles.organisationLinkDescription : null}>{labelDescriptionText}</div>
        </div>
    )
}

export default AccountDetailsTypography;