export const CHANGE_SCREEN = "CHANGE_SCREEN";

export const RESET_APP = "RESET_APP";

export const RESET_USER_DATA = "RESET_USER_DATA";

//export const BACKEND_URL = "https://tomcat-sm.jamesanthony.consulting";
// export const BACKEND_URL = "https://app-sm.jamesanthony.consulting";
export const BACKEND_URL = "https://app.sportmatch.ai";
// export const BACKEND_URL = "https://app.sportmatch.com.au";
// export const BACKEND_URL = "http://localhost:8081"; //84

export const ENVIRONMENT_PATH = "/sportmatch-backend";
//export const ENVIRONMENT_PATH = "/sportmatch-backend-2";

//export const MARKETING_DOMAIN = "https://marketing-sm.jamesanthony.consulting/";
export const MARKETING_DOMAIN = "https://sportmatch.com.au/";
//export const MARKETING_DOMAIN = "https://test02.jamesanthony.consulting/";

export const SET_FETCH_CONTROLLER = "SET_FETCH_CONTROLLER";

// USER DETAILS
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_PASSWORD = "SET_USER_PASSWORD";
export const SET_USER_BIRTH_DAY = "SET_USER_BIRTH_DAY";
export const SET_USER_BIRTH_MONTH = "SET_USER_BIRTH_MONTH";
export const SET_USER_BIRTH_YEAR = "SET_USER_BIRTH_YEAR";
export const SET_USER_FITNESS_AGE = "SET_USER_FITNESS_AGE";
export const SET_USER_GENDER = "SET_USER_GENDER";
export const SET_USER_CLASS_NAME = "SET_USER_CLASS_NAME";
export const SET_USER_ORGANISATION_NAME = "SET_USER_ORGANISATION_NAME"; // This should be in a whole separate section
export const SET_USER_ADDRESS_COUNTRY = "SET_USER_ADDRESS_COUNTRY";
export const SET_USER_ADDRESS_STATE = "SET_USER_ADDRESS_STATE";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_BACKEND_ID = "SET_USER_BACKEND_ID";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_AGE = "SET_USER_AGE";
export const SET_USER_VIEWING_TEST = "SET_USER_VIEWING_TEST";
export const SET_USER_EDITING_TEST = "SET_USER_EDITING_TEST";

export const RESET_USER_DETAILS = "RESET_USER_DETAILS";

/* MY DETAILS */
// My Info
export const SET_TEST_ID = "SET_TEST_ID";
export const SET_STUDENT_ID = "SET_STUDENT_ID";
export const SET_MOTHERS_HEIGHT = "SET_MOTHERS_HEIGHT";
export const SET_FATHERS_HEIGHT = "SET_FATHERS_HEIGHT";
export const SET_TEST_TOKEN_ID = "SET_TEST_TOKEN_ID";

// Flexibility
export const SET_SIT_AND_REACH = "SET_SIT_AND_REACH";

// Muscular Strength and Power
export const SET_STANDING_BROAD_JUMP = "SET_STANDING_BROAD_JUMP";
export const SET_GRIP_STRENGTH = "SET_GRIP_STRENGTH"; //Going to be deprecated soon (due to Left and Right grip strength)
export const SET_LEFT_GRIP_STRENGTH = "SET_LEFT_GRIP_STRENGTH";
export const SET_RIGHT_GRIP_STRENGTH = "SET_RIGHT_GRIP_STRENGTH";
export const SET_VERTICAL_JUMP = "SET_VERTICAL_JUMP";

// Muscular Endurance
export const SET_SIT_UPS = "SET_SIT_UPS";
export const SET_INCLINED_PULL_UPS = "SET_INCLINED_PULL_UPS";

// Speed and Agility
export const SET_SHUTTLE_RUN = "SET_SHUTTLE_RUN";
export const SET_SPRINT = "SET_SPRINT";
export const SET_SPRINT_DISTANCE = "SET_SPRINT_DISTANCE";
export const SET_SPRINT_TIME = "SET_SPRINT_TIME";

// Cardiorespiratory Fitness
export const SET_RUN_WALK_MINUTES = "SET_RUN_WALK_MINUTES";
export const SET_RUN_WALK_SECONDS = "SET_RUN_WALK_SECONDS";
export const SET_BEEP_TEST = "SET_BEEP_TEST";
export const SET_VO2_MAX = "SET_VO2_MAX";
export const SET_YOYO_TEST = "SET_YOYO_TEST";
export const SET_YOYO_STAGE = "SET_YOYO_STAGE";
export const SET_YOYO_VO2_MAX = "SET_YOYO_VO2_MAX";

// Body Shape and Size
export const SET_HEIGHT = "SET_HEIGHT";
export const SET_WEIGHT = "SET_WEIGHT";
export const SET_ARM_SPAN = "SET_ARM_SPAN";
export const SET_FOREARM_LENGTH = "SET_FOREARM_LENGTH";
export const SET_UPPER_ARM_LENGTH = "SET_UPPER_ARM_LENGTH";
export const SET_SITTING_HEIGHT = "SET_SITTING_HEIGHT";

// ALL FIELDS PROVIDED
export const SET_ALL_FIELDS_PROVIDED = "SET_ALL_FIELDS_PROVIDED";

// TEST DATE
export const SET_TEST_DATE = "SET_TEST_DATE";

// FIRST TIME VIEWING TEST
export const SET_FIRST_TIME_VIEWING_TEST = "SET_FIRST_TIME_VIEWING_TEST";

// Predicted Adult Fitness ID
export const SET_PREDICTED_ADULT_FITNESS_ID = "SET_PREDICTED_ADULT_FITNESS_ID";

// Sections of the My Info page
export const SET_INFO_SECTION_EXPANDED = "SET_INFO_SECTION_EXPANDED";
export const SET_FLEXABILITY_SECTION_EXPANDED = "SET_FLEXABILITY_SECTION_EXPANDED";
export const SET_MUSCULAR_STRENGTH_AND_POWER_EXPANDED = "SET_MUSCULAR_STRENGTH_AND_POWER_EXPANDED";
export const SET_MUSCULAR_ENDURANCE_EXPANDED = "SET_MUSCULAR_ENDURANCE_EXPANDED";
export const SET_SPEED_AND_AGILITY_EXPANDED = "SET_SPEED_AND_AGILITY_EXPANDED";
export const SET_CARDIORESPIRATORY_FITNESS_EXPANDED = "SET_CARDIORESPIRATORY_FITNESS_EXPANDED";
export const SET_BODY_SIZE_AND_SHAPE_EXPANDED = "SET_BODY_SIZE_AND_SHAPE_EXPANDED";


// Sections of SportMatch test visited
export const SET_SPORT_MATCH_BODY_SHAPE_AND_SIZE_SECTION_VISITED = "SET_SPORT_MATCH_BODY_SHAPE_AND_SIZE_SECTION_VISITED";
export const SET_SPORT_MATCH_FLEXIBILITY_SECTION_VISITED = "SET_SPORT_MATCH_FLEXIBILITY_SECTION_VISITED";
export const SET_SPORT_MATCH_MUSCULAR_STRENGTH_AND_POWER_SECTION_VISITED = "SET_SPORT_MATCH_MUSCULAR_STRENGTH_AND_POWER_SECTION_VISITED";
export const SET_SPORT_MATCH_MUSCULAR_ENDURANCE_SECTION_VISITED = "SET_SPORT_MATCH_MUSCULAR_ENDURANCE_SECTION_VISITED";
export const SET_SPORT_MATCH_SPEED_AND_AGILITY_SECTION_VISITED = "SET_SPORT_MATCH_SPEED_AND_AGILITY_SECTION_VISITED";
export const SET_SPORT_MATCH_AEROBIC_FITNESS_SECTION_VISITED = "SET_SPORT_MATCH_AEROBIC_FITNESS_SECTION_VISITED";

// Compare my Results page
export const CHANGE_SELECTED_NORM = "CHANGE_SELECTED_NORM";

/** Elite Athlete Sports */
export const CHANGE_SELECTED_SPORT = "CHANGE_SELECTED_SPORT";

/** NORM NAMES */
export const AUS_NZ_NORM_NAME = 'australian and nz';

/** SPORT MATCH TEST DATE CONSTANTS */
export const SET_SPORT_MATCH_TEST_DATE_DAY = "SET_SPORT_MATCH_TEST_DATE_DAY";
export const SET_SPORT_MATCH_TEST_DATE_MONTH = "SET_SPORT_MATCH_TEST_DATE_MONTH";
export const SET_SPORT_MATCH_TEST_DATE_YEAR = "SET_SPORT_MATCH_TEST_DATE_YEAR";

// Adult Values
export const SET_ADULT_SIT_AND_REACH = "SET_ADULT_SIT_AND_REACH";
export const SET_ADULT_STANDING_BROAD_JUMP = "SET_ADULT_STANDING_BROAD_JUMP";
export const SET_ADULT_GRIP_STRENGTH = "SET_ADULT_GRIP_STRENGTH"; //going to be deprecated soon as left and right grip strength will replace these
export const SET_ADULT_LEFT_GRIP_STRENGTH = "SET_ADULT_LEFT_GRIP_STRENGTH";
export const SET_ADULT_RIGHT_GRIP_STRENGTH = "SET_ADULT_RIGHT_GRIP_STRENGTH";
export const SET_ADULT_VERTICAL_JUMP = "SET_ADULT_VERTICAL_JUMP";
export const SET_ADULT_SIT_UPS = "SET_ADULT_SIT_UPS";
export const SET_ADULT_INCLINED_PULL_UPS = "SET_ADULT_INCLINED_PULL_UPS";
export const SET_ADULT_SHUTTLE_RUN = "SET_ADULT_SHUTTLE_RUN";
export const SET_ADULT_50M_SPRINT = "SET_ADULT_50M_SPRINT";
export const SET_ADULT_WALK_RUN = "SET_ADULT_WALK_RUN";
export const SET_ADULT_SHUTTLE_BEEP_TEST = "SET_ADULT_SHUTTLE_BEEP_TEST";
export const SET_ADULT_VO2_MAX = "SET_ADULT_VO2_MAX";
export const SET_ADULT_HEIGHT = "SET_ADULT_HEIGHT";
export const SET_ADULT_WEIGHT = "SET_ADULT_WEIGHT";
export const SET_ADULT_FOREARM_LENGTH = "SET_ADULT_FOREARM_LENGTH";
export const SET_ADULT_SITTING_HEIGHT = "SET_ADULT_SITTING_HEIGHT";
export const SET_ADULT_ARM_SPAN = "SET_ADULT_ARM_SPAN";

// USER PREVIOUS TESTS
export const SET_USER_PREVIOUS_TESTS = "SET_USER_PREVIOUS_TESTS";

// USER TEST INPUTS (different from the above user test constants, as these are the values which are only shown on the "SportMatch test" screen)
export const SET_USER_INPUT_HEIGHT = "SET_USER_INPUT_HEIGHT";
export const SET_USER_INPUT_WEIGHT = "SET_USER_INPUT_WEIGHT";
export const SET_USER_INPUT_ARM_SPAN = "SET_USER_INPUT_ARM_SPAN";
export const SET_USER_INPUT_SITTING_HEIGHT = "SET_USER_INPUT_SITTING_HEIGHT";
export const SET_USER_INPUT_FOREARM_LENGTH = "SET_USER_INPUT_FOREARM_LENGTH";
export const SET_USER_INPUT_UPPER_ARM_LENGTH = "SET_USER_INPUT_UPPER_ARM_LENGTH";
export const SET_USER_INPUT_SIT_AND_REACH = "SET_USER_INPUT_SIT_AND_REACH";
export const SET_USER_INPUT_STANDING_BROAD_JUMP = "SET_USER_INPUT_STANDING_BROAD_JUMP";
export const SET_USER_INPUT_GRIP_STRENGTH_LEFT = "SET_USER_INPUT_GRIP_STRENGTH_LEFT";
export const SET_USER_INPUT_GRIP_STRENGTH_RIGHT = "SET_USER_INPUT_GRIP_STRENGTH_RIGHT";
export const SET_USER_INPUT_VERTICAL_JUMP = "SET_USER_INPUT_VERTICAL_JUMP";
export const SET_USER_INPUT_BENT_KNEE_SIT_UPS = "SET_USER_INPUT_BENT_KNEE_SIT_UPS";
export const SET_USER_INPUT_INCLINED_PULL_UPS = "SET_USER_INPUT_INCLINED_PULL_UPS";
export const SET_USER_INPUT_SHUTTLE_RUN = "SET_USER_INPUT_SHUTTLE_RUN";
export const SET_USER_INPUT_SPRINT_DISTANCE = "SET_USER_INPUT_SPRINT_DISTANCE";
export const SET_USER_INPUT_SPRINT_TIME = "SET_USER_INPUT_SPRINT_TIME";
export const SET_USER_INPUT_WALK_RUN_MINUTES = "SET_USER_INPUT_WALK_RUN_MINUTES";
export const SET_USER_INPUT_WALK_RUN_SECONDS = "SET_USER_INPUT_WALK_RUN_SECONDS";
export const SET_USER_INPUT_SHUTTLE_BEEP_TEST = "SET_USER_INPUT_SHUTTLE_BEEP_TEST";

// Unit of mesurement
export const SET_UOM = "SET_UOM";

// YOYO
export const SET_USER_INPUT_YOYO_STAGE = "SET_USER_INPUT_YOYO_STAGE";
export const SET_USER_INPUT_YOYO_TEST = "SET_USER_INPUT_YOYO_TEST";
export const SET_USER_INPUT_VO2_MAX = "SET_USER_INPUT_VO2_MAX";
export const SET_USER_INPUT_YOYO_VO2_MAX = "SET_USER_INPUT_YOYO_VO2_MAX";
export const SET_USER_INPUT_BEEP_TEST_VO2_MAX = "SET_USER_INPUT_BEEP_TEST_VO2_MAX";

// B2C Constants
export const B2C_ROLE_NAME = "B2C_Athlete";

// QAS Constants
export const QAS_ORG_NAME = "QueenslandAcademySport";

// Token Allocation Type
export const SET_TOKEN_ALLOCATION_TYPE = "SET_TOKEN_ALLOCATION_TYPE";

// Allowed IDs to Login using Email
export const EMAIL_ALLOWED_IDS = ["232", "346", "246", "349", "238"];