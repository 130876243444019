// REACTJS
import React, { useState, useEffect } from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLES
import './style.css';

// IMAGE IMPORT
import FilterPurple from '../../../../../../assets/filter_purple.png';

// ACTIONS
import { changeScreen } from '../../../../../../store/actions/actions.js';

// CONSTANTS
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../../../store/constants';
import { CircularProgress } from '@material-ui/core';

import moment from 'moment';

const TalentIDComponent = ({ isFinishedLoading, data, finishedCollectingSportTypes, modeDisplay }) => {
    const dispatch = useDispatch();

    const organisationStateLocal = useSelector(state => state.organisationState);

    // Constructs the filter string
    const generateFilterString = () => {
        // Checking if filters have been applied
        var filters = "";
        if (organisationStateLocal.genderFilters.length != 0) {
            filters += "?gender=" + organisationStateLocal.genderFilters;
        }
        if (organisationStateLocal.groupNameFilters.length != 0) {
            if (filters != "") {
                filters += "&groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
            } else {
                filters += "?groups=" + btoa("[" + organisationStateLocal.groupNameFilters.map(e => e) + "]");
            }
        }
        if (organisationStateLocal.ageFilters.length != 0) {
            if (filters != "") {
                filters += "&ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
            } else {
                filters += "?ages=" + btoa("[" + organisationStateLocal.ageFilters.map(e => "\"" + e + "\"") + "]");
            }
        }
        if (organisationStateLocal.dateRangeFromFilter.length != 0) {
            if (filters != "") {
                filters += "&startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
            } else {
                filters += "?startDate=" + moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY');
            }
        }
        if (organisationStateLocal.dateRangeToFilter.length != 0) {
            if (filters != "") {
                filters += "&endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
            } else {
                filters += "?endDate=" + moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY');
            }
        }

        return filters;
    }

    const renderData = () => {
        var dom = [];

        if (!isFinishedLoading) {
            dom.push(
                <CircularProgress disableShrink={true} size={20} />
            )
        }

        if (data.length > 0) {
            
            for (var i = 0; i < (data.length < 20 ? data.length : 20); i++) {

                if (data[i].sports_in_order) {
                    // The below presumes there is data in both sports_in_order and keys
                    var objectKey = "";
                    if (data[i].sports_in_order.length > 0 && Object.keys(data[i].sports_in_order[0]).length > 0) {
                        objectKey = Object.keys(data[i].sports_in_order[0])[0];
                    }

                    dom.push(
                        <div className="row data-row">
                            <div className="column name-column">
                                <span>{data[i].name}</span>
                            </div>
                            <div className="column sport-column">
                                <span>{objectKey != "" ? data[i].sports_in_order[0][objectKey].sport : null}</span>
                            </div>
                            <div className="column degree-overlap-column">
                                <span>{parseFloat(data[i].overlap).toFixed(2)}</span>
                            </div>
                        </div>
                    )
                } else {
                    // Presume the talent id data is coming from the database table directly
                    dom.push(
                        <div className="row data-row">
                            <div className="column name-column">
                                <span>{data[i].user_name}</span>
                            </div>
                            <div className="column sport-column">
                                <span>{data[i].sport_name.split("|")[0]}</span>
                            </div>
                            <div className="column degree-overlap-column">
                                <span>{parseFloat(data[i].final_distance).toFixed(2)}</span>
                            </div>
                        </div>
                    )
                }

            }
        }
        return dom;
    }

    return (
        <div className="segment-container segment-small talent-id-segment">
            <div className="row">
                <span className="talent-id-heading">Talent ID</span>
                <button
                    className="view-all-btn"
                    style={{ background: organisationStateLocal.organisationPrimaryColor ? organisationStateLocal.organisationPrimaryColor : '', marginLeft: '60px' }}
                    onClick={() => {
                        dispatch(changeScreen(5));
                    }}>View all</button>
            </div>
            {organisationStateLocal.plan != "Starter" ?
                <>
                    <div className="row heading">
                        <div className="column name-column">
                            <span>Name</span>
                        </div>
                        <div className="column sport-column">
                            <span>Sport</span>
                        </div>
                        <div className="column degree-overlap-column">
                            <span>Degree overlap</span>
                        </div>
                    </div>
                    {renderData()}
                </>
                :
                    <div className="not-available-in-package-container">
                        <h2>Not available in package</h2>
                    </div>
                }
        </div>
    )
}

export default TalentIDComponent;
