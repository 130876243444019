import React, { useEffect, useState } from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// MOMENT JS
import Moment from 'react-moment';

// MATERIAL UI
import CircularProgress from '@material-ui/core/CircularProgress';

// ACTIONS
import {
    setUserViewingTest
} from "../../../store/actions/actions.js";
import { hexToRgbA } from '../../../helper/hexToRgbA.js';

const PreviousTestElement = ({ testElement, onClickHandler, loaded, onCloseHandler, setTestStartDate }) => {
    const dispatch = useDispatch();

    // Redux State
    const state = useSelector(state => state);

    const [loading, setLoading] = useState(false);

    const organisationLocalState = useSelector(state => state.organisationState);

    useEffect(() => {
        if (loaded == false) {
            setLoading(false);
        }
    }, [loaded]);

    const clickHandler = (e) => {
        if(testElement.test_no == state.user.userEditingTest) {
            setLoading(true);
            dispatch(setUserViewingTest(false));
            setTimeout(() => {
                onCloseHandler();
            }, 500);
            setTestStartDate(new Date());

        } else {
            var testNo = e.currentTarget.getAttribute('data-test-no');
            setLoading(true);
            dispatch(setUserViewingTest(true));
            setTimeout(() => {
                onClickHandler(testNo);
            }, 500);
            setTestStartDate(testElement.date_of_test);
        }
    }

    return (
        <div className={"test-reports-body-content" + (testElement.test_no == state.user.userEditingTest ? " selected" : "")} style={{border: testElement.test_no == state.user.userEditingTest ? (organisationLocalState.organisationPrimaryColor != '' ? "3px solid "+organisationLocalState.organisationPrimaryColor : '3px solid rgb(154, 98, 179)') : '', background: testElement.test_no == state.user.userEditingTest ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.5) : 'rgb(154, 98, 179, .5)') : ''}}>
            <span class="data test-num">{testElement.test_no}</span>
            <span class="data test-date"><Moment format="DD/MM/YYYY">{testElement.date_of_test}</Moment></span>
            <span class="data actual-age">{testElement.user_age}</span>
            <span class="data fit-age">{testElement.fitness_age}</span>
            <button
                data-test-no={`${testElement.test_no}`}
                onClick={(e) => {
                    clickHandler(e);
                }}
                className="view-button">{testElement.test_no == state.user.userEditingTest ? "Close" : "Open"}</button>
            <div style={{ opacity: loading ? '1' : '0', position: 'absolute', top: '17px', right: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress disableShrink={true} size={20} />
            </div>
        </div>
    )
}

export default PreviousTestElement;
