import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { CheckIfUserIsLoggedIn, CheckIfUserSessionIsValid } from '../../../../../api/Helper';
import { setOrgLogo } from '../../../../../store/actions/OrganisationActions';
import { BACKEND_URL, ENVIRONMENT_PATH } from '../../../../../store/constants';

// ASSETS
import DefaultUserIcon from '../../../../../assets/user_icon_default.png';

const BrandLogo = () => {
    const reduxState = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);

    const [hasSetBrandLogo, setHasSetBrandLogo] = useState(false);
    const [logoFile, setLogoFile] = useState(null);

    // React Toast Notifications Object
    const { addToast } = useToasts();

    const dispatch = useDispatch();

    const renderFileUploadInput = () => {
        if (reduxState.organisationState.plan !== "Starter" && reduxState.organisationState.plan !== "Professional") {
            return (
                <input
                    type="file"
                    onChange={(file) => brandLogoOnChangeHandler(file)}
                    style={{ display: "none" }}
                />
            )
        }
    }

    useEffect(() => {
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/ImageUpload`, {
            method: 'GET',
            credentials: "include"
        }).then(res => {
            CheckIfUserIsLoggedIn(res, dispatch).then(() => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    res.text().then(text => {
                        if (text == '' || text == null) {
                            dispatch(setOrgLogo(""));
                            setLogoFile(null);
                            setHasSetBrandLogo(false);
                        } else {
                            dispatch(setOrgLogo(text));
                            setLogoFile(text);
                            setHasSetBrandLogo(true);
                        }
                    })
                })
            })
        })
    }, []);

    const brandLogoOnChangeHandler = (e) => {
        if (e.target.files && e.target.files[0]) {
            setHasSetBrandLogo(false);
            var img = document.querySelector('img');  // $('img')[0]

            var logoDisplay = document.querySelector('#brandLogo');
            logoDisplay.src = URL.createObjectURL(e.target.files[0]); // set src to blob url

            const formData = new FormData();
            formData.append("file", e.target.files[0]);

            setLoading(true);

            CheckIfUserSessionIsValid(dispatch).then(() => {
                fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/ImageUpload`, {
                    method: 'POST',
                    body: formData,
                    credentials: "include"
                }).then(res => {
                    CheckIfUserSessionIsValid(dispatch).then(() => {
                        res.text().then(text => {
                            setLoading(false);
                            dispatch(setOrgLogo(text));
                            setLogoFile(text);
                            setHasSetBrandLogo(true);
                        })
                    })
                })
            });

            setLogoFile(e.target.files[0]);
            img.onload = true;
            setHasSetBrandLogo(true);
        }
    }

    const removeLogoHandler = () => {
        CheckIfUserSessionIsValid(dispatch).then(() => {
            setRemoveLoading(true);
            fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/ImageUpload?type=remove`, {
                method: 'POST',
                credentials: 'include'
            }).then(res => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    setRemoveLoading(false);
                    setHasSetBrandLogo(false);
                    document.querySelector('#brandLogo').src = null;
                    document.querySelector(".sidemenu-user img").src = DefaultUserIcon;
                    setLogoFile(null);
                    dispatch(setOrgLogo(""));

                    addToast(`Successfully remove logo`, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
            }).catch(er => {
                addToast(`Unable to remove logo`, {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
        })
    };

    return (
        <div className="screen-section plan-section brand-logo">
            {/** Header */}
            <div className="my-account-details-header-container plan-heading">
                <span>Brand logo</span>
            </div>

            {/** Body */}
            <div className="my-account-details-body-container">
                <div style={{ backgroundColor: hasSetBrandLogo ? '#F6F9FC' : '#bbbbbb' }} className="brand-logo-section">
                    <img name="file" style={{ display: logoFile != null || reduxState.organisationState.organisationLogo != "" ? 'initial' : 'none' }} id="brandLogo" src="#" alt="your logo" height="auto" width="100%" src={logoFile != null ? logoFile : (reduxState.organisationState.organisationLogo != "" ? reduxState.organisationState.organisationLogo : null)} />
                    <span style={{ display: logoFile != null || reduxState.organisationState.organisationLogo != "" ? 'none' : 'initial' }} className="logo-selection">None</span>
                </div>

                <div className="brand-button-container" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {loading && <CircularProgress disableShrink size={24} />}
                    <Button component="label" variant="contained" style={{marginLeft: '10px'}}>
                        Upload
                        {renderFileUploadInput()}
                    </Button>

                    {removeLoading && <CircularProgress disableShrink size={24} />}
                    <Button onClick={removeLogoHandler} component="label" variant="contained">
                        Remove
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BrandLogo;