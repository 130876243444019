import React from "react";

const ShuttleBeepTestSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Aerobic Fitness</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="aerobic_fitness" data-name="shuttle beep test">
            <path className="cls-2" style={{fill: color}}
                  d="M84.28,361.39l-9.46-31.65-8.18-27.39c-1.24-4.13-5.46-4.13-6.7,0l-8.18,27.39L42.3,361.39c-1,3.31.77,6.94,3.35,6.94H80.93C83.51,368.33,85.26,364.7,84.28,361.39ZM50.87,346.73l3.44-11.53.81-2.7H71.46l.8,2.7,3.45,11.53Z"
                  transform="translate(-2.83 -7.46)"/>
            <rect className="cls-2" style={{fill: color}} x="24.56" y="364.23" width="71.8" height="11" rx="3.97" ry="3.97"/>
            <path className="cls-2" style={{fill: color}}
                  d="M524.63,361.39l-9.46-31.65L507,302.35c-1.24-4.13-5.46-4.13-6.7,0l-8.18,27.39-9.46,31.65c-1,3.31.77,6.94,3.35,6.94h35.28C523.86,368.33,525.61,364.7,524.63,361.39Zm-33.41-14.66,3.45-11.53.8-2.7h16.34l.8,2.7,3.45,11.53Z"
                  transform="translate(-2.83 -7.46)"/>
            <rect className="cls-2" style={{fill: color}} x="464.91" y="364.23" width="71.8" height="11" rx="3.97" ry="3.97"/>
            <rect className="cls-2" style={{fill: color}} x="60.46" y="388.44" width="440.35" height="4"/>
            <g id="Group_181" data-name="Group 181">
                <path id="Path_16" data-name="Path 16" className="cls-2" style={{fill: color}}
                      d="M342.32,184.63a31.47,31.47,0,1,0-31.47-31.47,31.47,31.47,0,0,0,31.47,31.47Z"
                      transform="translate(-2.83 -7.46)"/>
                <path id="Path_17" data-name="Path 17" className="cls-2" style={{fill: color}}
                      d="M394.07,175.57c-18.07,25.3-42.25,28.23-68.29,12.78-1.77-1-9.62-5.38-11.37-6.42-41.88-24.86-83.7-15.1-111.75,24.17-11.92,16.71,15.64,32.55,27.43,16C244.59,201.84,263,196,283.17,202.4c-10.32,17.91-19.34,35.71-32.08,61s-40.85,45.5-67.86,29.7c-19.5-11.39-37.08,16.61-17.64,28,36.88,21.56,80.12,8.32,102.21-19.7a16.88,16.88,0,0,0,2.46,1.09c18,6.29,41.68,23,48.87,28.94s19.58,36.14,26.93,51.31c8.91,18.42,37.7,5,28.75-13.49C366.48,352,352.5,317.65,341.6,309c-8.75-7-25.73-19.95-40.14-27.31q14.63-28.31,30.57-55.89c34.05,10.43,66.41-1.86,89.46-34.15C433.43,174.89,405.87,159.05,394.07,175.57Z"
                      transform="translate(-2.83 -7.46)"/>
            </g>
        </g>
    </svg>
)

export default ShuttleBeepTestSvg;
