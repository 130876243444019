import {
    SET_SPORT_MATCH_TEST_DATE_DAY,
    SET_SPORT_MATCH_TEST_DATE_MONTH,
    SET_SPORT_MATCH_TEST_DATE_YEAR,
    SET_USER_INPUT_HEIGHT,
    SET_USER_INPUT_WEIGHT,
    SET_USER_INPUT_ARM_SPAN,
    SET_USER_INPUT_SITTING_HEIGHT,
    SET_USER_INPUT_FOREARM_LENGTH,
    SET_USER_INPUT_UPPER_ARM_LENGTH,
    SET_USER_INPUT_SIT_AND_REACH,
    SET_USER_INPUT_STANDING_BROAD_JUMP,
    SET_USER_INPUT_GRIP_STRENGTH_LEFT,
    SET_USER_INPUT_GRIP_STRENGTH_RIGHT,
    SET_USER_INPUT_VERTICAL_JUMP,
    SET_USER_INPUT_BENT_KNEE_SIT_UPS,
    SET_USER_INPUT_INCLINED_PULL_UPS,
    SET_USER_INPUT_SHUTTLE_RUN,
    SET_USER_INPUT_SPRINT_DISTANCE,
    SET_USER_INPUT_SPRINT_TIME,
    SET_USER_INPUT_WALK_RUN_MINUTES,
    SET_USER_INPUT_WALK_RUN_SECONDS,
    SET_USER_INPUT_SHUTTLE_BEEP_TEST,
    SET_USER_INPUT_YOYO_TEST,
    SET_USER_INPUT_YOYO_STAGE,
    SET_USER_INPUT_VO2_MAX,
    SET_USER_INPUT_YOYO_VO2_MAX,
    SET_USER_INPUT_BEEP_TEST_VO2_MAX
} from '../constants';

const initialState = {
    sportMatchTestDateDay: localStorage.getItem("sportMatchTestDateDay") || "",
    sportMatchTestDateMonth: localStorage.getItem("sportMatchTestDateMonth") || "",
    sportMatchTestDateYear: localStorage.getItem("sportMatchTestDateYear") || "",
    userInputHeight: localStorage.getItem("userInputHeight") || "",
    userInputWeight: localStorage.getItem("userInputWeight") || "",
    userInputArmSpan: localStorage.getItem("userInputArmSpan") || "",
    userInputSittingHeight: localStorage.getItem("userInputSittingHeight") || "",
    userInputForearmLength: localStorage.getItem("userInputForearmLength") || "",
    userInputUpperArmLength: localStorage.getItem("userInputUpperArmLength") || "",
    userInputSitAndReach: localStorage.getItem("userInputSitAndReach") || "",
    userInputStandingBroadJump: localStorage.getItem("userInputStandingBroadJump") || "",
    userInputGripStrengthLeft: localStorage.getItem("userInputGripStrengthLeft") || "",
    userInputGripStrengthRight: localStorage.getItem("userInputGripStrengthRight") || "",
    userInputVerticalJump: localStorage.getItem("userInputVerticalJump") || "",
    userInputBentKneeSitUps: localStorage.getItem("userInputBentKneeSitUps") || "",
    userInputInclinedPullUps: localStorage.getItem("userInputInclinedPullUps") || "",
    userInputShuttleRun: localStorage.getItem("userInputShuttleRun") || "",
    userInputSprintDistance: localStorage.getItem("userInputSprintDistance") || "",
    userInputSprintTime: localStorage.getItem("userInputSprintTime") || "",
    userInputWalkRunMinutes: localStorage.getItem("userInputWalkRunMinutes") || "",
    userInputWalkRunSeconds: localStorage.getItem("userInputWalkRunSeconds") || "",
    userInputShuttleBeepTest: localStorage.getItem("userInputShuttleBeepTest") || "",
    userInputYoyoTest: localStorage.getItem("userInputYoyoTest") || "",
    userInputYoyoStage: localStorage.getItem("userInputYoyoStage") || 1,
    userInputVO2MAX: localStorage.getItem("userInputVO2MAX") || "",
    userInputYoyoVO2MAX: localStorage.getItem("userInputYoyoVO2MAX") || 0,
    userInputBeepTestVO2MAX: localStorage.getItem("userInputBeepTestVO2MAX") || 0,
}

export const sportMatchTestReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SPORT_MATCH_TEST_DATE_DAY:
            localStorage.setItem("sportMatchTestDateDay", action.payload);
            return { ...state, sportMatchTestDateDay: action.payload };
        case SET_SPORT_MATCH_TEST_DATE_MONTH:
            localStorage.setItem("sportMatchTestDateMonth", action.payload);
            return { ...state, sportMatchTestDateMonth: action.payload };
        case SET_SPORT_MATCH_TEST_DATE_YEAR:
            localStorage.setItem("sportMatchTestDateYear", action.payload);
            return { ...state, sportMatchTestDateYear: action.payload };
        case SET_USER_INPUT_HEIGHT:
            localStorage.setItem("userInputHeight", action.payload);
            return { ...state, userInputHeight: action.payload };
        case SET_USER_INPUT_WEIGHT:
            localStorage.setItem("userInputWeight", action.payload);
            return { ...state, userInputWeight: action.payload };
        case SET_USER_INPUT_ARM_SPAN:
            localStorage.setItem("userInputArmSpan", action.payload);
            return { ...state, userInputArmSpan: action.payload };
        case SET_USER_INPUT_SITTING_HEIGHT:
            localStorage.setItem("userInputSittingHeight", action.payload);
            return { ...state, userInputSittingHeight: action.payload };
        case SET_USER_INPUT_FOREARM_LENGTH:
            localStorage.setItem("userInputForearmLength", action.payload);
            return { ...state, userInputForearmLength: action.payload };
        case SET_USER_INPUT_UPPER_ARM_LENGTH:
            localStorage.setItem("userInputUpperArmLength", action.payload);
            return { ...state, userInputUpperArmLength: action.payload };
        case SET_USER_INPUT_SIT_AND_REACH:
            localStorage.setItem("userInputSitAndReach", action.payload);
            return { ...state, userInputSitAndReach: action.payload };
        case SET_USER_INPUT_STANDING_BROAD_JUMP:
            localStorage.setItem("userInputStandingBroadJump", action.payload);
            return { ...state, userInputStandingBroadJump: action.payload };
        case SET_USER_INPUT_GRIP_STRENGTH_LEFT:
            localStorage.setItem("userInputGripStrengthLeft", action.payload);
            return { ...state, userInputGripStrengthLeft: action.payload };
        case SET_USER_INPUT_GRIP_STRENGTH_RIGHT:
            localStorage.setItem("userInputGripStrengthRight", action.payload);
            return { ...state, userInputGripStrengthRight: action.payload };
        case SET_USER_INPUT_VERTICAL_JUMP:
            localStorage.setItem("userInputVerticalJump", action.payload);
            return { ...state, userInputVerticalJump: action.payload };
        case SET_USER_INPUT_BENT_KNEE_SIT_UPS:
            localStorage.setItem("userInputBentKneeSitUps", action.payload);
            return { ...state, userInputBentKneeSitUps: action.payload };
        case SET_USER_INPUT_INCLINED_PULL_UPS:
            localStorage.setItem("userInputInclinedPullUps", action.payload);
            return { ...state, userInputInclinedPullUps: action.payload };
        case SET_USER_INPUT_SHUTTLE_RUN:
            localStorage.setItem("userInputShuttleRun", action.payload);
            return { ...state, userInputShuttleRun: action.payload };
        case SET_USER_INPUT_SPRINT_DISTANCE:
            localStorage.setItem("userInputSprintDistance", action.payload);
            return { ...state, userInputSprintDistance: action.payload };
        case SET_USER_INPUT_SPRINT_TIME:
            localStorage.setItem("userInputSprintTime", action.payload);
            return { ...state, userInputSprintTime: action.payload };
        case SET_USER_INPUT_WALK_RUN_MINUTES:
            localStorage.setItem("userInputWalkRunMinutes", action.payload);
            return { ...state, userInputWalkRunMinutes: action.payload };
        case SET_USER_INPUT_WALK_RUN_SECONDS:
            localStorage.setItem("userInputWalkRunSeconds", action.payload);
            return { ...state, userInputWalkRunSeconds: action.payload };
        case SET_USER_INPUT_SHUTTLE_BEEP_TEST:
            localStorage.setItem("userInputShuttleBeepTest", action.payload);
            return { ...state, userInputShuttleBeepTest: action.payload };
        case SET_USER_INPUT_YOYO_TEST:
            localStorage.setItem("userInputYoyoTest", action.payload);
            return { ...state, userInputYoyoTest: action.payload };
        case SET_USER_INPUT_YOYO_STAGE:
            localStorage.setItem("userInputYoyoStage", action.payload);
            return { ...state, userInputYoyoStage: action.payload };
        case SET_USER_INPUT_VO2_MAX:
            localStorage.setItem("userInputVO2MAX", action.payload);
            return { ...state, userInputVO2MAX: action.payload };
        case SET_USER_INPUT_YOYO_VO2_MAX:
            localStorage.setItem("userInputYoyoVO2MAX", action.payload);
            return { ...state, userInputYoyoVO2MAX: action.payload };
        case SET_USER_INPUT_BEEP_TEST_VO2_MAX:
            localStorage.setItem("userInputBeepTestVO2MAX", action.payload);
            return { ...state, userInputBeepTestVO2MAX: action.payload };
        default:
            return state;
    }
}