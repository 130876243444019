// REACT
import React, { useState, useEffect } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

// CSS
import './style.css';

// USER DEFINED COMPONENTS
import UserSection from './Components/UserSection.js';
import SportSection from './Components/SportSection.js';

// CONSTANTS
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../store/constants';
import Filter from '../filter';

import InaccessibleComponent from '../inaccessibleComponent';
import { CheckIfUserSessionIsValid } from '../../../../api/Helper';

import {
    GetTalentIDData,
    GetTalentIDFromTable
} from '../../../../api/endpoints';
import { GenerateFilterString, GenerateFilterParams, GenerateFilterWithSportsArrayParams } from '../../../../helper/helper';

// or if you prefer no Base64 namespace
import { Base64 } from 'js-base64';
import Axios from 'axios';

let cancelSource = Axios.CancelToken.source();

const TalentIDScreen = () => {
    const dispatch = useDispatch();

    // Redux State Variables
    const reduxState = useSelector(state => state);

    // Local State Variables
    const [studentRows, setStudentRows] = useState([]);
    const [sportSelections, setSportSelections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(100);
    const [previousPageSize, setPreviousPageSize] = useState(100);

    const [data, setData] = useState([]);

    const [sportSelectionRequestString, setSportSelectionRequestString] = useState("");

    const [filterBySelection, setFilterBySelection] = useState("organisation"); // This should be a enum or something

    useEffect(() => {
        var requestStringUnmodified = sportSelectionRequestString;

        if (requestStringUnmodified.substring(0, 1) == "?") {
            requestStringUnmodified = "&" + requestStringUnmodified.substring(1, requestStringUnmodified.length);

            setSportSelectionRequestString(requestStringUnmodified);
        }
    }, [sportSelectionRequestString]);

    // Determines whether we need to load more data from the backend
    const needToLoadData = () => {
        if(previousPageSize == -1) {
            return pageSize != -1;
        } else {
            return pageSize == -1;
        }
    }

    useEffect(() => {
        var requestParameter = "";
        var filterParams = "";

        cancelSource.cancel();
        cancelSource = Axios.CancelToken.source();

        if (sportSelections.length > 1) {
            filterParams = GenerateFilterWithSportsArrayParams(reduxState, Base64.encode("[" + sportSelections.map(e => e) + "]"));

            if (needToLoadData()) {
                requestParameter = `&sportnamearray=${btoa("[" + sportSelections.map(e => e) + "]")}`;
            } else {
                requestParameter = `?sportnamearray=${btoa("[" + sportSelections.map(e => e) + "]")}`;
            }
        } else {
            filterParams = GenerateFilterParams(reduxState);

            if (needToLoadData()) {
                requestParameter = `&sportname=${encodeURI(sportSelections.map(e => e))}`;
            } else {
                requestParameter = `?sportname=${encodeURI(sportSelections.map(e => e))}`;
            }
        }

        if(needToLoadData()) {
            setLoading(true);
            filterParams.append("using_org_sports", filterBySelection == "organisation");

            GetTalentIDFromTable("", filterParams, cancelSource).then(res => {
                setStudentRows(res.data.talent_ids);
                setLoading(false);
            })
        }

        if (sportSelections.length != 0) setSportSelectionRequestString(requestParameter);

        setPreviousPageSize(pageSize);
    }, [pageSize]);

    useEffect(() => {
        var requestParameter = "";
        var firstTimeLoading = false;

        setLoading(true);

        cancelSource.cancel();
        cancelSource = Axios.CancelToken.source();

        if (sportSelections.length > 1) {
            requestParameter = `?sportnamearray=${btoa("[" + sportSelections.map(e => e + "]"))}`;
            setSportSelectionRequestString(requestParameter);
        } else if (sportSelections.length == 1) {
            requestParameter = `?sportname=${encodeURI(sportSelections.map(e => e))}`;
            setSportSelectionRequestString(requestParameter);
        } else {
            requestParameter = "";
            firstTimeLoading = true;
        }

        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/CheckSessionIsValid`, {
            credentials: 'include'
        }).then(res => {
            CheckIfUserSessionIsValid(dispatch).then(() => {
                var filterParams = GenerateFilterWithSportsArrayParams(reduxState, Base64.encode("[" + sportSelections.map(e => e) + "]"));
                filterParams.append("using_org_sports", filterBySelection == "organisation");

                var hasFromDate = false; // Flag for checking if 'fromDate' has been provided
                var hasToDate = false; // Flag for checking if 'toDate' has been provided

                // This loads first when navigating to the Talent ID page
                for(var key of filterParams.keys()) {
                    if(key == "endDate") hasToDate = true;
                    if(key == "startDate") hasFromDate = true
                }

                if(hasFromDate && hasToDate) {
                    GetTalentIDData(false, filterParams, cancelSource).then(res => {
                        if(res && res.data) {
                            setStudentRows(res.data);
                            setLoading(false);
                        }
                    })
                } else {
                    GetTalentIDFromTable("", filterParams, cancelSource).then(res => {
                        setStudentRows(res.data.talent_ids);
                        setLoading(false);
                    });
                }
            })
        }).catch(() => { })
    }, [
        JSON.stringify(reduxState.organisationState.groupNameFilters),
        JSON.stringify(reduxState.organisationState.ageFilters),
        reduxState.organisationState.genderFilters,
        reduxState.organisationState.dateRangeFromFilter,
        reduxState.organisationState.dateRangeToFilter,
        filterBySelection
    ]);

    const changeSportSelection = sportSelections => {
        var requestParameter = "";

        cancelSource.cancel();
        cancelSource = Axios.CancelToken.source();

        if (sportSelections.length > 1) {
            if (pageSize != -1) {
                requestParameter = `&sportnamearray=${Base64.encode("[" + sportSelections.map(e => e.name + "|" + e.gender) + "]")}`;
            } else {
                requestParameter = `?sportnamearray=${Base64.encode("[" + sportSelections.map(e => e.name + "|" + e.gender) + "]")}`;
            }
        } else {
            if (pageSize != -1) {
                requestParameter = `&sportname=${encodeURI(sportSelections.map(e => e.name + "|" + e.gender))}`;
            } else {
                requestParameter = `?sportname=${encodeURI(sportSelections.map(e => e.name + "|" + e.gender))}`;
            }
        }

        if (sportSelections.length != 0) {
            setSportSelectionRequestString(requestParameter);
            setSportSelections(sportSelections.map(e => e.name + "|" + e.gender));

            var filterParams = GenerateFilterWithSportsArrayParams(reduxState, Base64.encode("[" + sportSelections.map(e => e.name + "|" + e.gender) + "]"));

            filterParams.append("using_org_sports", filterBySelection == "organisation");

            GetTalentIDFromTable("", filterParams, cancelSource).then(res => {
                setStudentRows(res.data.talent_ids);
                setLoading(false);
            })
        } else {
            setSportSelections([]);

            var filterParams = GenerateFilterParams(reduxState);

            filterParams.append("using_org_sports", filterBySelection == "organisation");

            GetTalentIDFromTable("", filterParams, cancelSource).then(res => {
                setStudentRows(res.data.talent_ids);
                setLoading(false);
            })
        }
    };

    return (
        <div className="talent-id-container">
            {reduxState.organisationState.plan !== "Starter" ?
                <>
                    <Filter />
                    <UserSection
                        studentData={studentRows}
                        loading={loading}
                        pageSize={pageSize}
                        setPageSize={(value) => setPageSize(value)}
                        sportSelectionRequest={sportSelectionRequestString}
                        usingOrgSports={filterBySelection == "organisation"}
                        filterString={GenerateFilterString(false, reduxState)} />
                    <SportSection 
                        setLoading={(e) => setLoading(e)}
                        sportSelectionHandler={changeSportSelection} 
                        setFilterBySelection={setFilterBySelection}
                        setSportSelectionRequestStringHandler={() => {
                            setSportSelectionRequestString('');
                            setData([]);
                        }} 
                    />
                </>
                : <InaccessibleComponent />}
        </div>
    )
}

export default TalentIDScreen;