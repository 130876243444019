// REACT
import React, { useState, useEffect } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

// Image
import filterPNG from '../classInfo/assets/filter.png';
import FilterComponent from '../classInfo/FilterComponent';
import { BACKEND_URL, ENVIRONMENT_PATH } from '../../../../store/constants';
import { CheckIfUserSessionIsValid } from '../../../../api/Helper';

const Filter = () => {
    const dispatch = useDispatch();
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [groupsAvailable, setGroupsAvailable] = useState([]);

    const organisationState = useSelector(state => state.organisationState);

    const retrieveListOfAvailableGroupName = (data) => {
        if (data[0]) {
            var arrayOfGroupNames = [];
            var jsonGroupData = data[0].org_groups;

            if (jsonGroupData.length > 0) {
                for (var i = 0; i < jsonGroupData.length; i++) {
                    if (jsonGroupData[i]) {
                        if (!arrayOfGroupNames.includes(jsonGroupData[i])) {
                            arrayOfGroupNames.push(jsonGroupData[i]);
                        }
                    }
                }
            }

            setGroupsAvailable(arrayOfGroupNames);
        }
    }

    useEffect(() => {
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=retrieveUsers`,
            { credentials: "include" }
        ).then((data) => {
            CheckIfUserSessionIsValid(dispatch).then(() => {
                data.json().then((result) => {
                    retrieveListOfAvailableGroupName(result);
                }).catch(er => {
                    console.log("Error = ", er);
                })
            })
        })
    }, []);

    return (
        <React.Fragment>
            {organisationState.plan != "Starter" ? 
                <>
                    <div className="filter-options" onClick={() => setShowFilterOptions(!showFilterOptions)}>
                        <img src={filterPNG} width={55} height={55} />
                    </div>
        
                    <FilterComponent showFilterFunction={() => setShowFilterOptions(!showFilterOptions)}  show={showFilterOptions} listOfGroups={groupsAvailable} />
                </>
                : null
            }

        </React.Fragment>
    )
}

export default Filter;