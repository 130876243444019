import { CircularProgress } from '@material-ui/core';
import React from 'react';

export const AdminLoadingIndicator = ({value}) => {
    return (
        <div className="loading-norms-container-admin">
            <CircularProgress disableShrink size={20} />
            &ensp;{value} 
        </div>
    )
}