import { NativeSelect, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setUserInputYoyoStage, setUserInputYoyoTest, setUserInputYoyoVO2MAX } from '../../store/actions/myDetails';
import { BACKEND_URL, ENVIRONMENT_PATH } from '../../store/constants';
import { YOYOLEVEL1VALUES, YOYOLEVEL2VALUES } from './yoyoValues';

export const YoyoTestSection = ({ action, userVO2MAX, confirmedValueProvided }) => {
    // Redux (global state)
    const dispatch = useDispatch();
    const organisationLocalState = useSelector(state => state.organisationState);
    const sportMatchState = useSelector(state => state.sportMatchTest);

    const MyNativeSelect = withStyles({
        icon: {
            background: organisationLocalState.organisationPrimaryColor? (organisationLocalState.organisationPrimaryColor + " !important"):"#9a62b3 !important"
        }
    })(NativeSelect);

    const getVO2MaxValue = (value, stage) => {
        var splittedVal = value.split("-");
        var level = splittedVal[0];
        var shuttle = splittedVal[1];

        if(stage == "") {
            stage = 1;
        } else {
            stage = parseInt(stage);
        }
            
        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/getyoyotestdata`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                stage: stage,
                level: level,
                shuttle: shuttle
            })
        }).then(res => {
            res.json().then(result => {
                dispatch(setUserInputYoyoVO2MAX(result.vo2));

                var vo2MaxScore = parseFloat(result.vo2).toFixed(1);
                action(vo2MaxScore);
            })
        })
    }

    const renderYoyoValues = () => {
        var dom = [];

        if(sportMatchState.userInputYoyoStage == "" || sportMatchState.userInputYoyoStage == "1") {
            for(var i = 0; i < YOYOLEVEL1VALUES.length; i++) {
                dom.push(
                    <option>{YOYOLEVEL1VALUES[i]}</option>
                )
            }
        } else {
            for(var i = 0; i < YOYOLEVEL2VALUES.length; i++) {
                dom.push(
                    <option>{YOYOLEVEL2VALUES[i]}</option>
                )
            }
        }

        return dom;
    };

    const setStageHandler = (val) => {
        var value = ""

        dispatch(setUserInputYoyoTest(""));

        if(val == 1) {
            value = "5-1";
        } else {
            value = "11-1";
        }

        getVO2MaxValue(value, val);

        dispatch(setUserInputYoyoStage(val + ""));
    }

    return (
        <div className="myDetails-field drop-down-field">
            <div className="myDetails-field-title">
                <div>
                    Yo-Yo test
                    
                    <div style={{display: 'flex'}}>
                        <div>
                            <label>Level 1</label>
                            <input 
                                disabled={confirmedValueProvided} 
                                checked={sportMatchState.userInputYoyoStage == "1" || sportMatchState.userInputYoyoStage == ""} 
                                defaultChecked={sportMatchState.userInputYoyoStage == "1" || true} 
                                onClick={() => setStageHandler(1)} 
                                name="level" type="radio"
                            />
                        </div>
                        <div>
                            <label>Level 2</label>
                            <input 
                                disabled={confirmedValueProvided} 
                                checked={sportMatchState.userInputYoyoStage == "2"} 
                                defaultChecked={sportMatchState.userInputYoyoStage == "2"} 
                                onClick={() => setStageHandler(2)} 
                                name="level" 
                                type="radio" 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <MyNativeSelect
                onChange={(e) => {
                    if(!sportMatchState.userInputYoyoStage) {
                        dispatch(setUserInputYoyoStage(1 + ""));
                    }

                    dispatch(setUserInputYoyoTest(e.target.value));
                    getVO2MaxValue(e.target.value, sportMatchState.userInputYoyoStage);
                }}
                data-type={"beepTest"}
                value={sportMatchState.userInputYoyoTest}
                displayEmpty
                disabled={confirmedValueProvided}
                renderValue={selected => {
                    if (selected) {
                        return selected;
                    } else {
                        return <span>&ensp;</span>;
                    }
                }}>
                {renderYoyoValues()}
            </MyNativeSelect>
            <span className="level-span">level</span>
            <div className="vo2MaxSection">
                <span>VO</span>
                <span className="vo2MaxSecondText">2max </span>
                <span>:</span>
                <span className="vo2MaxText" style={{color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}}>{userVO2MAX ? parseFloat(userVO2MAX).toFixed(1) : 0} </span>
                <span>(mL/kg/min)</span>
            </div>
        </div>
    )
}