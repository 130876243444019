import React from "react";

const RightArrowSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="74.287" height="95.912" viewBox="0 0 74.287 95.912">
        <defs>
            <filter id="rightarrow" x="0" y="0" width="74.287" height="95.912" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
            </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#rightarrow)">
            <path id="rightarrow-2" data-name="rightarrow" d="M23.269,68.26h-7.21A5,5,0,0,1,12.027,60.3L45.967,14.016a5,5,0,0,1,8.065,0L87.973,60.3a5,5,0,0,1-4.032,7.957h-7.21L51.613,34.006a2,2,0,0,0-3.227,0L23.269,68.26Z" transform="translate(77.26 -5.04) rotate(90)" fill={color}/>
        </g>
    </svg>



)

export default RightArrowSvg;
