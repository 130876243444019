// REACTJS
import React, { useState } from 'react';

// REDUX
import { useSelector } from 'react-redux';

// STYLES
import './style.css';
import Button from "@material-ui/core/Button/Button";
import { Card, CardContent, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Slide from "@material-ui/core/Slide/Slide";

// MOMENTJS
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FitnessAgeComponent = ({ loading, age, fitnessAgeAverage, difference }) => {
    const organisationStateLocal = useSelector(state => state.organisationState);
    const [whatIsThisPopupOpen, setWhatIsThisPopupOpen] = useState(false);

    var actual_age = parseFloat(age).toFixed(2);
    var fitness_age = parseFloat(fitnessAgeAverage).toFixed(2);
    var difference_age = parseFloat(fitness_age - actual_age).toFixed(2);

    return (
        <div className="segment-container segment-small fitness-age-segment">
            <div className="heading">
                <span className="heading-header">
                    Fitness Age
                </span>
                {loading ? <CircularProgress disableShrink={true} size={20} /> : null}
                {/*<button>What's this?</button>*/}
                <Button style={{ backgroundColor: 'white', color: 'black', textTransform: 'none' }} onClick={() => {
                    setWhatIsThisPopupOpen(true)
                }} variant={'contained'}>What's this?</Button>
            </div>

            <div className="row">
                <div className="column">
                    <span>Actual age:</span>
                </div>
                <div className="column">
                    <span className="result" style={{ color: organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '' }}>{actual_age}</span>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <span>Fitness age:</span>
                </div>
                <div className="column">
                    <span className="result" style={{ color: organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '' }}>{fitness_age}</span>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <span>Difference:</span>
                </div>
                <div className="column">
                    <span style={parseFloat(difference_age) < 0 ? {color: 'red'} : (organisationStateLocal.organisationAccentColor != '' ? {color: organisationStateLocal.organisationAccentColor} : null)} className="result difference-result">{difference_age} years</span>
                </div>
            </div>
            <Dialog className="fitness-age-what-is-this-popup" open={whatIsThisPopupOpen} TransitionComponent={Transition} keepMounted onClose={null}>
                <Card>
                    <CardContent>
                        <div className="card-body">
                            <h2>Fitness Age</h2>
                            <span>
                                Fitness age calculations can use up to 10 fitness test performances. The fitness age
                                for each person is calculated using an average of a weighted combination of all
                                performance variables that have been assessed. Each variable is calculated as an age
                                equivalence, or the age where a physical performance is equal to the average level
                                in the population. The value shown on this screen is the average for the entire
                                group.
                            </span>
                        </div>

                        <div className="card-button-container">
                            <Button variant="contained" onClick={() => {
                                setWhatIsThisPopupOpen(false);
                            }}>
                                Close
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Dialog>
        </div>
    )
}

export default FitnessAgeComponent;
