/**
 * create the pdf
 * 
 * @param {String} primaryColor hex value of the primary color of the pdf 
 * @param {String} name first + last name
 * @param {String} dob
 * @param {int} age 
 * @param {String} venue test venue name
 * @param {String} date test date
 * @param {Boolean} downloadReport boolean determining whether report should be downloaded
 * @param {Array} physique array of content, each array element is a object in format: 
 * @param {Array} performance array of content, each array element is a object in format: 
 * { 
 * 	'image': image data, 
 * 	'data': text to display above percentage, 
 * 	'percentage': percentage of the bar
 * }
 * @param {String} header1 left header image data
 * @param {String} header2 right header image data
 * @param {String} govLogo bottom right logo
 * @param {String} sm1Line 1 line sportmatch logo text
 * @param {String} sm2Line 2 lines sportmatch logo text
 */
export const generateQasReportPDF = async (
	primaryColor,
	name,
	dob,
	age,
	venue,
	date,
	downloadReport,
	physique,
	performance,
	header1,
	header2,
	govLogo,
	sm1Line,
	sm2Lines,
) => {
	let jsPDF = require('jspdf');
	let doc = new jsPDF();

	doc.addFont('Helvetica');
	doc.setFont('Helvetica');
	// doc.addFont("IntegralCF-DemiBoldOblique.ttf", "IntegralCF", "Bold");

	doc.addImage(header1, 'PNG', 9.5, 1, 94.5, 79, undefined, 'FAST');
	doc.addImage(header2, 'PNG', 106, 1, 94.5, 79, undefined, 'FAST');

	// PERSONAL INFO SECTION
	let section_1_y_axis = 80;

	// personal information banner
	doc.setFillColor(primaryColor);
	doc.rect(10, section_1_y_axis, 190, 10, 'F');
	doc.setFontSize(12);
	doc.setTextColor('white');
	doc.setFontStyle('bold');
	doc.text('PERSONAL INFORMATION', 110, section_1_y_axis + 6.5, {
		align: 'center'
	});

	// personal info box
	doc.setDrawColor(primaryColor);
	doc.setLineWidth(1.1);
	doc.rect(10, section_1_y_axis, 190, 30);

	// personal info content
	doc.setFontSize(10);
	doc.setTextColor('black');
	doc.text('NAME:', 35, section_1_y_axis + 15);
	doc.text('DOB:', 35, section_1_y_axis + 21);
	doc.text('AGE AT TEST:', 35, section_1_y_axis + 27);
	doc.text('TEST VENUE:', 110, section_1_y_axis + 15);
	doc.text('TEST DATE:', 110, section_1_y_axis + 21);

	doc.setFontStyle('normal');
	doc.text(name.toUpperCase(), 65, section_1_y_axis + 15);
	doc.text(dob, 65, section_1_y_axis + 21);
	doc.text(age, 65, section_1_y_axis + 27);
	doc.text(venue != null && venue.toUpperCase() ? venue.toUpperCase() : '', 140, section_1_y_axis + 15);
	doc.text(date.toUpperCase(), 140, section_1_y_axis + 21);

	// PHYSIQUE SECTION
	// Change the agility		
	// let section_2_y_axis = 115;
	let section_2_y_axis = 120;

	/// physique banner
	doc.setFillColor(primaryColor);
	doc.rect(10, section_2_y_axis, 190, 10, 'F');
	doc.setFontSize(12);
	doc.setTextColor('white');
	doc.setFontStyle('bold');
	doc.text('PHYSIQUE', 110, section_2_y_axis + 6.5, {
		align: 'center'
	});

	// physique box
	doc.setDrawColor(primaryColor);
	doc.setLineWidth(1.1);
	doc.rect(10, section_2_y_axis, 190, 65);

	// physique content
	doc.setFontSize(9);
	doc.setTextColor('black');
	doc.text('Physique values presented as absolute (top) and percentile ranking (bottom)', 55, section_2_y_axis + 13.5);
	doc.setFontSize(10);
	doc.setTextColor('#4B4B4B');
	doc.text('HEIGHT', 52, section_2_y_axis + 22, { align: 'right' });
	doc.text('ARM SPAN', 52, section_2_y_axis + 32, { align: 'right' });
	doc.text('SITTING HEIGHT', 52, section_2_y_axis + 42, { align: 'right' });

	renderChart(doc, 55, section_2_y_axis + 17, primaryColor, physique);

	doc.setFontSize(9);
	doc.text('PERCENTILE RANKING', 137.5, section_2_y_axis + 55, { align: 'center' });

	doc.setFontStyle('normal');
	doc.setFontSize(9);
	doc.text('Data powered by', 15, section_2_y_axis + 55);
	doc.setFontSize(10);
	// doc.setFont('IntegralCF');
	// doc.text('SPORTMATCH', 15, section_2_y_axis + 62);
	doc.addImage(sm1Line, 'PNG', 15, section_2_y_axis + 56, 30, 4.5
		, undefined, 'FAST');
	doc.setFont('Helvetica');
	doc.setFontSize(9);
	doc.text('Percentile ranking is between 1 and 99, with 50 being average.\nFor example, a percentile ranking of 75% means you performed better than 75% of the population.', 197, section_2_y_axis + 59.5, { align: 'right' });


	// PHYSICAL TEST RESULT SECTION
	// Change the agility	
	// let section_3_y_axis = 185;
	let section_3_y_axis = 195;

	// physical test result banner
	doc.setFillColor(primaryColor);
	doc.rect(10, section_3_y_axis, 190, 10, 'F');
	doc.setFontSize(12);
	doc.setTextColor('white');
	doc.setFontStyle('bold');
	doc.text('PHYSICAL TEST RESULTS', 110, section_3_y_axis + 6.5, {
		align: 'center'
	});

	// physical test result box
	doc.setDrawColor(primaryColor);
	doc.setLineWidth(1.1);
	// Change the agility	
	// doc.rect(10, section_3_y_axis, 190, 85);
	doc.rect(10, section_3_y_axis, 190, 75);

	// physical test content
	doc.setFontSize(9);
	doc.setTextColor('black');
	doc.text('Performance values presented as absolute (top) and percentile ranking (bottom)', 55, section_3_y_axis + 13.5);
	doc.setFontSize(10);
	doc.setTextColor('#4B4B4B');
	doc.text('VERTICAL JUMP', 52, section_3_y_axis + 22, { align: 'right' });
	doc.text('INCLINED PULL-UPS', 52, section_3_y_axis + 32, { align: 'right' });
	doc.text('BEEP TEST', 52, section_3_y_axis + 42, { align: 'right' });
	doc.text('20m SPRINT TIME', 52, section_3_y_axis + 52, { align: 'right' });
	// Change the agility
	// doc.text('4x10m AGILITY', 52, section_3_y_axis + 52, { align: 'right' });
	// doc.text('20m SPRINT TIME', 52, section_3_y_axis + 62, { align: 'right' });

	renderChart(doc, 55, section_3_y_axis + 17, primaryColor, performance);

	doc.setFontSize(9);
		// Change the agility
		// doc.text('PERCENTILE RANKING', 137.5, section_3_y_axis + 75, { align: 'center' });
	doc.text('PERCENTILE RANKING', 137.5, section_3_y_axis + 65, { align: 'center' });

	doc.setFontStyle('normal');
	doc.setFontSize(10);
	// doc.setFont('IntegralCF');
	// doc.text('SPORT\nMATCH', 15, section_3_y_axis + 78);

		// Change the agility
		// doc.addImage(sm2Lines, 'PNG', 15, section_3_y_axis + 72, 20, 7.5, undefined, 'FAST');
	doc.addImage(sm2Lines, 'PNG', 15, section_3_y_axis + 62, 20, 7.5, undefined, 'FAST');
	doc.setFont('Helvetica');
	doc.setFontSize(9);
		// Change the agility
		// doc.text('Percentile ranking is between 1 and 99, with 50 being average.\nFor example, a percentile ranking of 75% means you performed better than 75% of the population.', 197, section_3_y_axis + 79.5, { align: 'right' });
	doc.text('Percentile ranking is between 1 and 99, with 50 being average.\nFor example, a percentile ranking of 75% means you performed better than 75% of the population.', 197, section_3_y_axis + 69.5, { align: 'right' });

	// BOTTOM FOOTER SECTION
	let section_4_y_axis = 285;

	doc.setFontStyle('bold');
	doc.setFontSize(10);
	doc.setTextColor('black');
	doc.text('Queensland Academy of Sport', 10, section_4_y_axis);
	doc.setFontStyle('normal');
	doc.setFontSize(8);
	doc.text('Thank you for your participation in the QAS "You for 2032 campaign". If you\nhave any further questions, please look at the FAQ page on our website:\nwww.qld.gov.au/youfor2032', 115, section_4_y_axis, { align: 'center' });

	doc.addImage(govLogo, 'PNG', 170, section_4_y_axis - 5, 30, 12, undefined, 'FAST');

	// save the pdf
	if (downloadReport) {
		doc.save(name + '.pdf');
	}

	return btoa(doc.output());
}

/**
 * render the charts in the pdf
 * 
 * @param {jsDoc} doc document file
 * @param {num} x start of x axis (top left)
 * @param {num} y start of y axis (top left)
 * @param {String} primaryColor hex value of the primary color of the pdf
 * @param {Array} content array of content, each array element is a object in format: 
 * { 
 * 	'image': image data, 
 * 	'data': text to display above percentage, 
 * 	'percentage': percentage of the bar
 * }
 */
const renderChart = (
	doc,
	x,
	y,
	primaryColor,
	content,
) => {
	// the box outside the chart
	doc.setDrawColor('black');
	doc.setLineWidth(0.2);
	doc.rect(x, y - 1, 195 - x, (content.length * 10));
	doc.setLineWidth(0.3);

	let barRenderBox = 190 - x;

	// x axis indicator
	doc.setFontSize(7);
	doc.setTextColor('#4B4B4B');
	let boxBottom = y - 1 + (content.length * 10);
	[0, 0.25, 0.50, 0.75, 1].forEach((e) => {
		let x1 = x + e * barRenderBox + 3;
		doc.line(x1, boxBottom, x1, boxBottom + 1.5);
		doc.setDrawColor('#D5D5D5');
		doc.line(x1, y, x1, boxBottom);
		doc.setDrawColor('black');
		doc.text((e * 100).toString(), x1, boxBottom + 5, { align: 'center' });
	});
	doc.setFontSize(9);

	// draw each individual bar
	content.forEach((entry, index) => {
		let image = entry['image'];
		let data = entry['data'];
		let percentage = parseFloat(entry['percentage'].toString()).toFixed(1);
		percentage = isNaN(percentage) ? 0.0 : percentage;

		let length = barRenderBox * percentage / 100;
		let base = y + index * 10;

		// y axis indicators + background line
		doc.line(x - 1.5, base + 4, x, base + 4);
		doc.setDrawColor('#D5D5D5');
		doc.line(x, base + 4, 195, base + 4);
		doc.setDrawColor('black');

		// draw the bar
		doc.setFillColor(primaryColor);
		doc.rect(x + 3, base, length, 8, 'F');

		// draw the text, draw inside the bar if have enough space, outside the bar otherwise
		if (percentage < 25) {
			if (percentage > 10) {
				doc.addImage(image, 'PNG', x + 4, base + 1, 6, 6, undefined, 'FAST');
			}
			doc.setTextColor('black');
			doc.text(data.toString(), x + length + 10, base + 3.5, { align: 'center' });
			doc.text(percentage + '%', x + length + 10, base + 6.5, { align: 'center' });
		} else {
			// draw the icon if have enough space
			doc.setFillColor('white');
			doc.circle(x + 7, base + 4, 3, 'F');

			doc.addImage(image, 'PNG', x + 4, base + 1, 6, 6, undefined, 'FAST');

			doc.setTextColor('white');
			doc.text(data.toString(), x + length - 10, base + 3.5, { align: 'center' });
			doc.text(percentage + '%', x + length - 10, base + 6.5, { align: 'center' });
		}

		doc.setTextColor('black');
	});
}