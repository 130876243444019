import React, { useState, useEffect } from 'react';

import TestRowComponent from './TestRowComponent.js';
import { BACKEND_URL, ENVIRONMENT_PATH } from '../../../store/constants';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { CheckIfUserSessionIsValid } from '../../../api/Helper.js';
import Axios from 'axios';

let cancelIndividualReportToken = Axios.CancelToken.source();

const TestBodyComponent = ({studentId, showTest, iconsForReport}) => {
    const dispatch = useDispatch();

    const [testsToShow, setTestsToShow] = useState(null);
    const organisationStateLocal = useSelector(state => state.organisationState);

    const userState = useSelector(state => state.user);
    const usingImperial = userState.userUOM == "Imperial";

    useEffect(() => {
        if(showTest) {
            if(organisationStateLocal.dateRangeFromFilter && organisationStateLocal.dateRangeToFilter) {
                Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/GetUserTest?userId=${studentId}&from=${moment(organisationStateLocal.dateRangeFromFilter).format('DD/MM/YYYY')}&to=${moment(organisationStateLocal.dateRangeToFilter).format('DD/MM/YYYY')}`, {
                    withCredentials: true,
                    cancelToken: cancelIndividualReportToken.token
                }).then(res => {
                    CheckIfUserSessionIsValid(dispatch).then(() => {
                        setTestsToShow(res.data.student_tests);
                    })
                });
            } else {
                Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/GetUserTest?userId=${studentId}`, {
                    withCredentials: true,
                    cancelToken: cancelIndividualReportToken.token
                }).then(res => {
                    CheckIfUserSessionIsValid(dispatch).then(() => {
                        setTestsToShow(res.data.student_tests);
                    })
                });
            }
        } else {
            setTimeout(() => setTestsToShow([]), 500);
        }
    }, [showTest, organisationStateLocal.dateRangeFromFilter, organisationStateLocal.dateRangeToFilter]);

    const renderTestRow = () => {
        var dom = [];
        if(testsToShow != null) {
            for(var i = 0; i < testsToShow.length; i++) {
                dom.push(
                    <TestRowComponent test={testsToShow[i]} iconsForReport={iconsForReport} />
                );
            }
        }

        return dom;
    };

    return (
        <div style={{maxHeight: showTest ? '250px' : '0px'}} className="test-container">
            <span>Number of tests {testsToShow != null ? testsToShow.length : 0}</span>
            <div className="test-results-section">
                <div className="test-results-heading">
                    <div className="test-results-column-heading">
                        <span>Test date</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Height</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Weight</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Sit-ups (1<br />min)</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Standing broad jump</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Sit & reach</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Inclined pull-ups (30 sec)</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>4 x {usingImperial ? "11" : "10"}<br />shuttle run</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>{usingImperial ? "1 mile walk/run" : "1.6 km walk/run"}</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Arm span</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Forearm length</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Grip strength left</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Grip strength right</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Shuttle beep</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Sitting height</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Sprint distance</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Sprint time</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Upper arm length</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Vertical jump</span>
                    </div>

                    <div className="test-results-column-heading">
                        <span>Yo-Yo test</span>
                    </div>
                </div>

                <div className="test-results-body">
                    {renderTestRow()}
                </div>
            </div>
        </div>
    )
}

export default TestBodyComponent;
