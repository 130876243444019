import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// MATERIAL UI COMPONENTS
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { DownloadButton } from '../../../components/Buttons.js'

import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../store/constants';

import IM from 'convert-units';

import { CheckIfUserSessionIsValid } from '../../../api/Helper.js';
import { ConvertToFeetAndInches } from '../../../helper/helper.js';

const TestRowComponent = ({ test, iconsForReport }) => {
    const dispatch = useDispatch();

    const userState = useSelector(state => state.user);

    const usingImperial = userState.userUOM == "Imperial";

    const getAverageGripStrength = (gripStrengthLeft, gripStrengthRight) => {
        var isNegativeOne = gripStrengthLeft == -1 && gripStrengthRight == -1;

        var gripStrengthLeftLocal = gripStrengthLeft;
        var gripStrengthRightLocal = gripStrengthRight;

        var hasGripStrengthLeft = gripStrengthLeftLocal != -1;
        var hasGripStrengthRight = gripStrengthRightLocal != -1;

        if (hasGripStrengthLeft && !hasGripStrengthRight) {
            gripStrengthRightLocal = gripStrengthLeftLocal;
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (hasGripStrengthRight && !hasGripStrengthLeft) {
            gripStrengthLeftLocal = gripStrengthRightLocal;
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (hasGripStrengthLeft && hasGripStrengthRight) {
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (isNegativeOne) {
            return -1;
        } else {
            return 0;
        }
    }

    const [reportDataset, setReportDataset] = useState([]);
    const [verified, setVerified] = useState(test.verified);

    const controller = useSelector(state => state.user.fetchController);
    const signal = controller.signal;

    const getValueFromReportDataset = (name) => {
        for (var i = 0; i < reportDataset.length; i++) {
            if (reportDataset[i].name == name) {
                return reportDataset[i];
            }
        }
    }

    // Sets the verified status of a test
    const setVerifiedHandler = () => {
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/usertest`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                test_id: test.test_id + "",
                user_id: test.user_id + "",
                shuttle_test_20m: "-1",
                verified: verified ? false : true
            })
        }).then(res => {
            CheckIfUserSessionIsValid(dispatch).then(() => {
                res.json().then(result => {
                    if (result.hasOwnProperty("verified")) {
                        setVerified(result.verified);
                    }
                }).catch(er => {
                    console.log("Error = ", er);
                })
            })
        }).catch(error => {
            console.log("Error = ", error);
        })
    }

    const walkRunTimeConverter = () => {
        let minutes;
        let seconds;

        if (test.walk_run != '-1') {
            minutes = Math.floor(test.walk_run / 60).toString();
            seconds = (test.walk_run - minutes * 60);
            if (seconds < 10) {
                seconds = '0' + seconds.toString();
            }
        }
        return minutes + ':' + seconds;
    }

    return (
        <div className="test-results-body-row">
            <div className="test-inputs">
                <svg className="verified-star" style={{ display: verified ? "inline-block" : "none" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                    <title>Verified</title>
                </svg>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.test_date} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.height != "-1 " ? (usingImperial ? ConvertToFeetAndInches(IM(test.height).from('cm').to('in')) : test.height) : ""} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.weight != "-1" ? (usingImperial ? parseFloat(IM(test.weight).from('kg').to('lb')).toFixed(1) : test.weight)  : ""} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.sit_ups != "-1" ? test.sit_ups : ""} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.standing_broad_jump != "-1" ? (usingImperial ? ConvertToFeetAndInches(IM(test.standing_broad_jump).from('cm').to('in')) : test.standing_broad_jump) : ""} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.sit_and_reach != "-1" ? (usingImperial ? parseFloat(IM(test.sit_and_reach).from('cm').to('in')).toFixed(2) : test.sit_and_reach) : ""} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.inclined_pull_ups != "-1" ? test.inclined_pull_ups : ""} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.shuttle_run != "-1" ? test.shuttle_run : ""} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.walk_run != "-1" ? walkRunTimeConverter() : ""} />
                </div>

                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.arm_span != "-1" ? (usingImperial ?  ConvertToFeetAndInches(parseFloat(IM(test.arm_span).from('cm').to('in'))) : test.arm_span) : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.forearm_length != "-1" ? (usingImperial ? parseFloat(IM(test.forearm_length).from('cm').to('in')).toFixed(2) : test.forearm_length) : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.grip_strength_left != "-1" ? (usingImperial ? parseFloat(IM(test.grip_strength_left).from('kg').to('lb')).toFixed(1) : test.grip_strength_left) : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.grip_strength_right != "-1" ? (usingImperial ? parseFloat(IM(test.grip_strength_right).from('kg').to('lb')).toFixed(1) : test.grip_strength_right) : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.shuttle_beep != "-1" ? test.shuttle_beep : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.sitting_height != "-1" ? (usingImperial ? parseFloat(IM(test.sitting_height).from('cm').to('in')).toFixed(2) : test.sitting_height) : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.sprint_distance != "-1" ? (usingImperial ? parseFloat(IM(test.sprint_distance).from('m').to('ft') / 3).toFixed(2) : test.sprint_distance) : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.sprint_time != "-1" ? test.sprint_time : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.upper_arm_length != "-1" ? (usingImperial ? parseFloat(IM(test.upper_arm_length).from('cm').to('in')).toFixed(2) : test.upper_arm_length) : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.vertical_jump != "-1" ? (usingImperial ? parseFloat(IM(test.vertical_jump).from('cm').to('in')).toFixed(1) : test.vertical_jump) : ""} />
                </div>
                <div className="text-field-small" onClick={(e) => e.stopPropagation()}>
                    <input type="text" variant="filled" placeholder={""} value={test.yoyo_test != "-1" ? test.yoyo_test : ""} />
                </div>
            </div>

            <Button className="extra-btns verify-btn" variant="contained" onClick={(e) => setVerifiedHandler()}>
                {verified ? <React.Fragment>
                    Unverify
                </React.Fragment>
                    :
                    <React.Fragment>
                        Verify <i class="fa fas fa-star"></i>
                    </React.Fragment>
                }
            </Button>

            <DownloadButton
                test={test}
                loaded={true}
                isHeaderButton={true}
                title={'Download'}
                link={''}
                _className={"download-button"}
                headerImages={[
                    iconsForReport[0],
                    iconsForReport[1],
                    iconsForReport[2],
                    iconsForReport[3],
                    iconsForReport[4],
                    iconsForReport[5],
                    iconsForReport[6],
                    iconsForReport[7],
                    iconsForReport[8],
                    iconsForReport[9]
                ]}
                anthropometryImages={[
                    iconsForReport[10],
                    iconsForReport[11],
                    iconsForReport[12],
                    iconsForReport[13],
                    iconsForReport[14]
                ]}
                showNameInTitle={true}
            />

        </div>
    )
};

export default TestRowComponent;
