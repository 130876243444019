import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import TestBodyComponent from './TestBodyComponent';

import './style.css';
import TokenIcon from './assets/token';
import { UpdateStudentDetails } from '../../../api/endpoints';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'
import { hexToRgbA } from '../../../helper/hexToRgbA';
import { CircularProgress } from '@material-ui/core';

const StudentRowComponent = ({
    validToken,
    studentName,
    studentGender,
    studentDOB,
    studentGroup,
    studentTests,
    index,
    studentId,
    localUserIdsSelectedArray,
    setIdsSelected,
    iconsForReport
}) => {
    const organisationLocalState = useSelector(state => state.organisationState);

    const [showTest, setShowTest] = useState(false);
    const [selected, setSelected] = useState(false);

    // Username
    const [originalUsername, setOriginalUsername] = useState(studentName);
    const [newUsername, setNewUsername] = useState(studentName);

    // Gender
    const [originalGender, setOriginalGender] = useState(studentGender);
    const [newGender, setNewGender] = useState(studentGender);

    // Date of birth
    const [originalDateOfBirth, setOriginalDateOfBirth] = useState(studentDOB);
    const [newDateOfBirth, setNewDateOfBirth] = useState(studentDOB);

    // Group name
    const [originalGroupName, setOriginalGroupName] = useState(studentGroup);
    const [newGroupName, setNewGroupName] = useState(studentGroup);

    const [saveUserDetailsLoading, setSaveUserDetailsLoading] = useState(false);

    // React Toast Notifications Object
    const { addToast } = useToasts();

    const setRowAsSelected = () => {
        setSelected(!selected);
        var array = localUserIdsSelectedArray;

        if (array.includes(studentId)) {
            var index = array.indexOf(studentId);

            if (index !== -1) {
                array.splice(index, 1);
            }
        } else {
            array.push(studentId);
        }

        setIdsSelected(array);
    }

    useEffect(() => {
        setSelected(localUserIdsSelectedArray.includes(studentId));
    });

    const renderViewTestIcon = () => {
        if (!showTest) {
            return (
                <i class="fa fas fa-sort-down"></i>
            )
        } else {
            return (
                <i class="fa fas fa-caret-up"></i>
            )
        }
    };

    const saveUserDetailsOnClickHandler = () => {
        var objectToSendToBackend = {
            setUserDetails: true,
            user_id: studentId + ""
        };

        if (originalUsername != newUsername) objectToSendToBackend.username = newUsername;
        if (originalGroupName != newGroupName) objectToSendToBackend.groupName = newGroupName;
        if (originalDateOfBirth != newDateOfBirth) objectToSendToBackend.dateOfBirth = newDateOfBirth;
        if (originalGender != newGender) objectToSendToBackend.gender = newGender;

        var validUsername = true;
        var validGender = true;
        var validDOB = true;
        var validGroupName = true;

        setSaveUserDetailsLoading(true);

        UpdateStudentDetails(objectToSendToBackend).then(res => {
            if (res.data.errors && res.data.errors.length > 0) {

                for (var i = 0; i < res.data.errors.length; i++) {
                    var jsonObject = res.data.errors[i];

                    if (jsonObject.name) {
                        if (jsonObject.name == "username") validUsername = false;
                        if (jsonObject.name == "gender") validGender = false;
                        if (jsonObject.name == "dob") validDOB = false;
                        if (jsonObject.name == "group") validGroupName = false;
                        
                        if (jsonObject.message) {
                            addToast(`Error - ${jsonObject.message}`, {
                                appearance: 'error',
                                autoDismiss: true
                            });
                        }
                    }
                }
            } else {
                addToast("Successfully Saved", {
                    appearance: 'success',
                    autoDismiss: true
                });
            }

            if(validUsername) setOriginalUsername(newUsername);
            if(validGender) setOriginalGender(newGender);
            if(validDOB) setOriginalDateOfBirth(newDateOfBirth);
            if(validGroupName) setOriginalGroupName(newGroupName);
            
            setSaveUserDetailsLoading(false);
        });
    }

    // Responsible for checking if any of the user values have changed from their original values
    const checkIfUserValuesHaveChanged = () => {
        const usernameChanged = originalUsername != newUsername;
        const genderChanged = originalGender != newGender;
        const dobChanged = originalDateOfBirth != newDateOfBirth;
        const groupNameChanged = originalGroupName != newGroupName;

        return usernameChanged || genderChanged || dobChanged || groupNameChanged;
    }

    return (
        <div className={`user-row ${selected ? 'selected' : ''}`} style={{
            top: index !== 0 ? ((index * 4) * -1) + "px" : "",
            borderColor: selected ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgb(154, 98, 179)') : '',
            backgroundColor: selected ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor, 0.1) : 'rgb(154, 98, 179, .1)') : ''
        }} onClick={() => { setRowAsSelected() }}>

            {validToken ?
                <div className="token-icon-container">
                    <TokenIcon />
                </div>
                : null
            }

            <div className="body-row">
                <div className="text-field-large" onClick={(e) => e.stopPropagation()}>
                    <input
                        type="text"
                        variant="filled"
                        placeholder={""}
                        value={newUsername}
                        defaultValue={originalUsername}
                        onChange={e => setNewUsername(e.target.value)}
                    />
                </div>
                <div className="text-field-large" onClick={(e) => e.stopPropagation()}>
                    <input
                        type="text"
                        variant="filled"
                        placeholder={""}
                        value={newGender}
                        defaultValue={originalGender}
                        onChange={e => setNewGender(e.target.value)}
                    />
                </div>
                <div className="text-field-large" onClick={(e) => e.stopPropagation()}>
                    <input
                        type="text"
                        variant="filled"
                        placeholder={""}
                        value={newDateOfBirth}
                        defaultValue={originalDateOfBirth}
                        onChange={e => setNewDateOfBirth(e.target.value)}
                    />
                </div>
                <div className="text-field-large" onClick={(e) => e.stopPropagation()}>
                    <input
                        disabled={organisationLocalState.plan == "Starter"}
                        type="text"
                        variant="filled"
                        placeholder={""}
                        value={newGroupName}
                        defaultValue={originalGroupName}
                        onChange={e => setNewGroupName(e.target.value)}
                    />
                </div>

                <Button onClick={(e) => { e.stopPropagation(); setShowTest(!showTest); }} className={showTest ? "extra-btns view-tests-btn" : "extra-btns view-tests-btn collapsed"} variant="contained" style={{ background: showTest ? (organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor, 0.3) : 'rgb(154, 98, 179, 0.3)') : 'white' }}>
                    View tests {renderViewTestIcon()}
                </Button>

                <>
                    <Button
                        className="extra-btns"
                        variant="contained"
                        style={{ opacity: checkIfUserValuesHaveChanged() ? "1" : "0" }}
                        onClick={() => checkIfUserValuesHaveChanged() ? saveUserDetailsOnClickHandler() : null}
                    >
                        Save User Details
                    </Button> {saveUserDetailsLoading && <CircularProgress disableShrink size={20} />}
                </>
            </div>

            <TestBodyComponent
                studentId={studentId}
                showTest={showTest}
                tests={studentTests}
                iconsForReport={iconsForReport}
            />
        </div>
    )
}

export default StudentRowComponent;
