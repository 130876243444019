import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// REDO SVG
import RedoIcon from '../assets/RedoIcon.js';

export const MyDetailsHeader = ({children, title, icon, isIconSvg, fieldChanged, resetBtnHandler }) => {
    const organisationLocalState = useSelector(state => state.organisationState);

    const renderIcon = () => {
        if(icon !== "") {
            if(isIconSvg) {
                return icon;
            } else {
                // return <img style={(title === "Cardiorespiratory Fitness") ?  {position: "relative", left: "-3px"} : null} src={icon} height={50} />;
                return (
                    <div className="my-details-header-icons" style={(title === "Cardiorespiratory Fitness") ?  {position: "relative", left: "-3px"} : null}>
                        {icon}
                    </div>
                )
            }

        } else {
            return (
                <i className="fa fa-clipboard"></i>
            )
        }
    }

    return (
        <div className="myDetails-screen-section-header">
            <div className="myDetails-screen-section-icon">
                {renderIcon()}
            </div>
            <div className="myDetails-screen-section-title">{title}</div>

            <RedoIcon fieldChanged={fieldChanged} resetBtnHandler={resetBtnHandler} color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}/>
        </div>
    );
}
