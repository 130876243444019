// REACTJS
import React, { useState, useEffect } from 'react';

// REDUX
import {useDispatch, useSelector} from 'react-redux';

// STYLES
import './style.css';

// CONSTANTS
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../../../store/constants';

// ACTIONS
import { changeScreen } from '../../../../../../store/actions/actions.js';

// IMAGES
import SitReachSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/sitReachSvg.js';
import StandingBroadJumpSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/StandingBroadJumpSvg.js';
import GripStrengthSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/GripStrengthSvg.js';
import VerticalJumpSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/VerticalJumpSvg.js';
import BentKneeSitUpsSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/BentKneeSitUpsSvg.js';
import InclinedPullUpsSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/InclinedPullUpsSvg.js';
import ShuttleRunSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/ShuttleRunSvg.js';
import SprintSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/SprintSvg.js';
import RunWalkSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/RunWalkSvg.js';
import ShuttleBeepTestSvg from '../../../../../../assets/compareMyResultsIcons/16032020/svgFiles/ShuttleBeepTestSvg.js';

import Filter from '../../../filter';
import { CircularProgress } from '@material-ui/core';

const ResultComponent = ({ loading, data, modeDisplay, setModeDisplay, location }) => {
    const dispatch = useDispatch();
    const organisationLocalState = useSelector(state => state.organisationState);

    const [sitAndReach, setSitAndReach] = useState(null);
    const [standingBroadJump, setStandingBroadJump] = useState(null);
    const [gripStrength, setGripStrength] = useState(null);
    const [verticalJump, setVerticalJump] = useState(null);
    const [bentKneeSitUps, setBentKneeSitUps] = useState(null);
    const [inclinedPullUps, setInclinedPullUps] = useState(null);
    const [shuttleRun, setShuttleRun] = useState(null);
    const [sprintSpeed, setSprintSpeed] = useState(null);
    const [runWalk, setRunWalk] = useState(null);
    const [shuttleBeepTest, setShuttleBeepTest] = useState(null);

    const renderPercentileLabel = (value) => {
        value = parseFloat(value);
        if (value < 1 && value != 0) {
          return "<1%";
        } else {
          return value.toFixed(0) + "%";
        }
      }
    useEffect(() => {
        if (data) {
            setSitAndReach(data.sit_reach || 0);
            setStandingBroadJump(data.board_jump || 0);
            setGripStrength(data.grip_strength || 0);
            setVerticalJump(data.power || 0);
            setBentKneeSitUps(data.situps || 0);
            setInclinedPullUps(data.pullup || 0);
            setSprintSpeed(data.speed || 0);
            setShuttleRun(data.shuttle || 0);
            setRunWalk(data.walk_run || 0);
            setShuttleBeepTest(data.endurance || 0);
        }
    }, [data]);

    const renderModeButton = () => {
        if(modeDisplay == 'graph') {

            return (
                <button className="more-btn" onClick={() => {
                    setModeDisplay("list");
                }}>List view</button>
            );
        } else {
            return (
                <button className="more-btn" onClick={() => {
                    setModeDisplay("graph");
                }}>Graph view</button>
            )
        }
    }

    return (
        <div className="segment-container segment-small result-segment" style={{ display: modeDisplay != "graph" ? "none" : "flex" }}>
            <div className="heading">
                {loading ? <CircularProgress disableShrink={true} size={20} /> : null}

                {location == "dashboard" ?
                    <button
                        className="more-btn"
                        onClick={() => {
                            dispatch(changeScreen(2));
                        }}
                    >More</button>
                    : renderModeButton()
                }
            </div>

            <div className="results-heading">
                <div className="image-container">
                    <SitReachSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <StandingBroadJumpSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <GripStrengthSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <VerticalJumpSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <BentKneeSitUpsSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <InclinedPullUpsSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <ShuttleRunSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <SprintSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <RunWalkSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
                <div className="image-container">
                    <ShuttleBeepTestSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} />
                </div>
            </div>

            <div className="result-body">
                <div className="best-category"></div>
                <div className="good-category"></div>
                <div className="average-category"></div>
                <div className="needs-improvement-category"></div>

                <div className="result-bars-container">
                    <div className="bar">
                        <div style={{ height: sitAndReach + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !sitAndReach ? "none" : "block"}}>
                                {renderPercentileLabel((sitAndReach))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: standingBroadJump + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !standingBroadJump ? "none" : "block"}}>
                                {renderPercentileLabel((standingBroadJump))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: gripStrength + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !gripStrength ? "none" : "block"}}>
                                {renderPercentileLabel((gripStrength))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: verticalJump + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !verticalJump ? "none" : "block"}}>
                                {renderPercentileLabel((verticalJump))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: bentKneeSitUps + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !bentKneeSitUps ? "none" : "block"}}>
                                {renderPercentileLabel((bentKneeSitUps))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: inclinedPullUps + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !inclinedPullUps ? "none" : "block"}}>
                                {renderPercentileLabel((inclinedPullUps))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: shuttleRun + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !shuttleRun ? "none" : "block"}}>
                                {renderPercentileLabel((shuttleRun))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: sprintSpeed + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !sprintSpeed ? "none" : "block"}}>
                                {renderPercentileLabel((sprintSpeed))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: runWalk + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !runWalk ? "none" : "block"}}>
                                {renderPercentileLabel((runWalk))}
                            </div>
                        </div>
                    </div>
                    <div className="bar">
                        <div style={{ height: shuttleBeepTest + "%", background: 'linear-gradient(180deg, ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgba(154,98,179,1)') + ' 0%, rgba(9,9,121,0) 100%)' }}>
                            <div className={"score"} style={{display: !shuttleBeepTest ? "none" : "block"}}>
                                {renderPercentileLabel((shuttleBeepTest))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResultComponent;
