import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	setUserId,
	setUserPassword,
	setUserOrganisationName,
	setUserPreviousTests,
	setUserBackendId,
	setUserGender,
	setUserBirthMonth,
	setUserBirthYear,
	setUserFitnessAge,
	setUserClassName,
	setUserRole,
	setUserUOM,
	setUserEmail,
	setUserBirthDay,
	setTestTokenId,
	setUserAddressCountry,
	setUserAddressState,
	setTokenAllocationType
} from '../../store/actions/actions.js';

import {
	setPrimaryColor,
	setSecondaryColor,
	setAccentColor,
	setPlan,
	setOrganisationCountry
} from '../../store/actions/OrganisationActions.js';

import {
	setMothersHeight,
	setFathersHeight
} from '../../store/actions/myDetails.js';

// MATERIALUI
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useToasts } from 'react-toast-notifications'

import {
	BACKEND_URL,
	EMAIL_ALLOWED_IDS,
	ENVIRONMENT_PATH,
	MARKETING_DOMAIN
} from '../../store/constants';

// ZXCVBN - Password rating
const zxcvbn = require("zxcvbn");

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Login = ({ orgId }) => {
	const dispatch = useDispatch();

	const [loginUserId, setLoginUserId] = useState("");
	const [loginUserPwd, setLoginUserPwd] = useState("");

	const [isFirstTimeViewing, setIsFirstTimeViewing] = useState(false);

	const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
	const [StudentPasswordScore, setStudentPasswordScore] = useState(0);

	const renderPasswordScore = (score) => {
		var scoreWord = "poor";

		switch (score) {
			case 0:
				scoreWord = "poor";
				break;
			case 1:
				scoreWord = "poor";
				break;
			case 2:
				scoreWord = "weak";
				break;
			case 3:
				scoreWord = "average";
				break;
			case 4:
				scoreWord = "excellent";
				break;
		}
		return (
			<div className="password-strength-levels">
				<div className={`level-${scoreWord} first-level ${score < 2 ? 'empty' : ''}`}></div>
				<div className={`level-${scoreWord} second-level ${score < 3 ? 'empty' : ''}`}></div>
				<div className={`level-${scoreWord} third-level ${score < 4 ? 'empty' : ''}`}></div>
				<span className={`level-${scoreWord} password-level-span`}>{scoreWord}</span>
			</div>
		)
	}

	const passwordOnChangeHandler = obj => {

		const passwordInputValue = obj;

		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
		const minLengthRegExp = /.{8,}/;
		const maxLengthRegExp = /^.{8,64}$/;

		const passwordLength = passwordInputValue.length;
		const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
		const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
		const digitsPassword = digitsRegExp.test(passwordInputValue);
		const specialCharPassword = specialCharRegExp.test(passwordInputValue);
		const minLengthPassword = minLengthRegExp.test(passwordInputValue);
		const maxLengthPassword = maxLengthRegExp.test(passwordInputValue);

		let errMsg = "";
		if (orgId == 349 || orgId == 238) {
			if (passwordLength === 0) {
				errMsg = "Password is empty";
			} else if (!uppercasePassword) {
				errMsg = "At least one Uppercase";
			} else if (!lowercasePassword) {
				errMsg = "At least one Lowercase";
			} else if (!digitsPassword) {
				errMsg = "At least one digit";
			} else if (!specialCharPassword) {
				errMsg = "At least one Special Characters";
			} else if (!minLengthPassword) {
				errMsg = "At least minumum 8 characters";
			} else if (!maxLengthPassword) {
				errMsg = "Maximum length is 64 characters";
			} else {
				errMsg = "";
			}

			setStudentPasswordScore(zxcvbn(obj).score);
		}

		setNewPassword(obj);
		setPasswordErr(errMsg);
	}

	const [newPassword, setNewPassword] = useState("");
	const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

	const [passwordError, setPasswordErr] = useState("");

	const [usingEmail, setUsingEmail] = useState(false);
	const [storedUsername, setStoredUsername] = useState("");

	const state = useSelector(state => state);

	// React Toast Notifications Object
	const { addToast } = useToasts();

	const initialiseUserData = (data, loginUserId, loginUserPwd, emailProvided) => {
		var isNotAStudent = false;

		if (data[0].organisation_name) {
			dispatch(setUserOrganisationName(data[0].organisation_name));
			// window.location.href = `${data[0].organisation_name.replace(' ', '_')}`;
		}

		if (data[0].plan) {
			dispatch(setPlan(data[0].plan));
		}

		if (data[0].org_country) {
			dispatch(setOrganisationCountry(data[0].org_country));
		}

		if (typeof data[0].user_gender !== "undefined" && data[0].user_gender) {
			dispatch(setUserGender(data[0].user_gender));
		}

		//Setting of athlete address
		if (typeof data[0].address_country !== 'undefined' && data[0].address_country !== 'null') {
			dispatch(setUserAddressCountry(data[0].address_country));
		}

		if (typeof data[0].address_state !== 'undefined' && data[0].address_state !== 'null') {
			dispatch(setUserAddressState(data[0].address_state));
		}

		if (typeof data[0].user_birth_day !== 'undefined' && data[0].user_birth_day !== 'null') {
			dispatch(setUserBirthDay(data[0].user_birth_day));
		}

		if (typeof data[0].user_birth_month !== 'undefined' && data[0].user_birth_month !== 'null') {
			dispatch(setUserBirthMonth(data[0].user_birth_month));
		}

		if (typeof data[0].user_birth_year !== 'undefined' && data[0].user_birth_year !== 'null') {
			dispatch(setUserBirthYear(data[0].user_birth_year));
		}

		if (typeof data[0].user_group !== 'undefined' && data[0].user_group !== 'null') {
			dispatch(setUserClassName(data[0].user_group));
		}

		if (typeof data[0].user_uom !== 'undefined' && data[0].user_uom !== 'null') {
			dispatch(setUserUOM(data[0].user_uom));
		}

		if (typeof data[0].token_allocation_type !== 'undefined' && data[0].token_allocation_type !== 'null')
			dispatch(setTokenAllocationType(data[0].token_allocation_type));

		if (data[0].is_organisation !== 'undefined' && data[0].is_organisation !== 'null' && data[0].is_organisation) {
			isNotAStudent = true;
			dispatch(setUserRole("organisation"));

			var urlencoded = new URLSearchParams();
			urlencoded.append("email", loginUserId);
			urlencoded.append("password", loginUserPwd);

			// remote login for marketing site, will have to update url for go-live
			fetch(`${MARKETING_DOMAIN}wp-json/hestia-child/v1/login/`, {
				method: "POST",
				body: urlencoded
			});
		} else if (data[0].is_teacher !== 'undefined' && data[0].is_teacher !== 'null' && data[0].is_teacher) {
			isNotAStudent = true;
			dispatch(setUserRole("teacher"));
		} else if (data[0].is_admin !== 'undefined' && data[0].is_admin !== 'null' && data[0].is_admin) {
			isNotAStudent = true;
			dispatch(setUserRole("admin"));
		} else {
			dispatch(setUserRole("student"));
		}

		if (typeof data[0].user_role !== 'undefined') {
			dispatch(setUserRole(data[0].user_role));

			if (data[0].user_role == "B2C_Athlete") {
				dispatch(setTestTokenId("Valid"));
			}
		}

		if (typeof data[0].user_email !== 'undefined') {
			dispatch(setUserEmail(data[0].user_email));
		}

		// getOrgLogo();

		if (typeof data[0].mother_height !== 'undefined' && data[0].mother_height !== 'null' && data[0].mother_height !== '') {
			dispatch(setMothersHeight(data[0].mother_height));
		}

		if (typeof data[0].father_height !== 'undefined' && data[0].father_height !== 'null' && data[0].father_height !== '') {
			dispatch(setFathersHeight(data[0].father_height));
		}

		if (!data[0].is_admin) {
			if (data[0].org_primary_color) {
				dispatch(setPrimaryColor(data[0].org_primary_color));
			}
			if (data[0].org_secondary_color) {
				dispatch(setSecondaryColor(data[0].org_secondary_color));
			}
			if (data[0].org_accent_color) {
				dispatch(setAccentColor(data[0].org_accent_color));
			}
		}

		dispatch(setUserBackendId(data[0].backend_user_id + ""));

		var arrayOfPreviousTests = '{ \"tests\": [';
		for (var i = 0; i < data.length - 1; i++) {
			arrayOfPreviousTests += JSON.stringify(data[i + 1]) + (i !== data.length - 2 ? "," : "");
		}

		arrayOfPreviousTests += ']}';

		dispatch(setUserPreviousTests(arrayOfPreviousTests));

		// Setting the previous assigned test to the user
		if (data.length > 1) {
			var lastTestFitnessAge = typeof data[1].fitness_age !== 'undefined' && data[1].fitness_age ? data[1].fitness_age : "";
			var lastTestGripStrengthLeft = typeof data[1].grip_strength_left !== 'undefined' && data[1].grip_strength_left && data[1].grip_strength_left !== -1 ? data[1].grip_strength_left : "";
			var lastTestGripStrengthRight = typeof data[1].grip_strength_right !== 'undefined' && data[1].grip_strength_right && data[1].grip_strength_right !== -1 ? data[1].grip_strength_right : "";

			var averageGripStrength = "";
			if (lastTestGripStrengthLeft === "" && lastTestGripStrengthRight !== "") {
				averageGripStrength = lastTestGripStrengthRight;
			} else if (lastTestGripStrengthLeft !== "" && lastTestGripStrengthRight === "") {
				averageGripStrength = lastTestGripStrengthLeft;
			} else {
				averageGripStrength = ((parseFloat(lastTestGripStrengthLeft) + parseFloat(lastTestGripStrengthRight)) / 2);
			}

			dispatch(setUserFitnessAge(lastTestFitnessAge));
		}

		if (data[0].is_first_time_viewing && !isNotAStudent) {
			setIsFirstTimeViewing(true);

			if (emailProvided && typeof data[0].username != 'undefined') {
				setStoredUsername(data[0].username);
				setUsingEmail(true);
			}
		} else {
			if (emailProvided && typeof data[0].username != 'undefined') {
				setStoredUsername(data[0].username);
				dispatch(setUserId(data[0].username));
				setUsingEmail(true);
			} else {
				dispatch(setUserId(loginUserId));
			}
		}
	}

	const loginOnClick = async (event) => {
		var emailProvided = false;
		var urlencoded = new URLSearchParams();
		urlencoded.append("userId", loginUserId);
		urlencoded.append("password", loginUserPwd);
		urlencoded.append("orgId", orgId);

		console.log("Has ID = ", EMAIL_ALLOWED_IDS.includes(orgId));

		if (EMAIL_ALLOWED_IDS.includes(orgId)) {
			urlencoded.append("email", loginUserId);
			emailProvided = true;
		}

		event.preventDefault()
		setLoading(true);
		dispatch(setUserId(""));
		dispatch(setUserPassword(""));
		await fetch(BACKEND_URL + ENVIRONMENT_PATH + '/Login', {
			method: 'POST',
			body: urlencoded,
			credentials: "include",
		}).then(function (res) {
			res.json().then(result => {
				const data = result;

				if (data[0].success === true) {
					sessionStorage.setItem("isLoggedIn", "true");
					setLoading(false);
				}

				if (typeof data[0] !== 'undefined' && data[0].success) {
					initialiseUserData(data, loginUserId, loginUserPwd, emailProvided);
				} else if (data.success) {
					setLoading(false);
					if (data.organisation_name) {
						dispatch(setUserOrganisationName(data.organisation_name));
					}

					if (emailProvided && typeof data[0].username != 'undefined') {
						setStoredUsername(data[0].username);
						dispatch(setUserId(data[0].username));
						setUsingEmail(true);
					} else {
						dispatch(setUserId(loginUserId));
					}

					var arrayOfPreviousTests = '{ \"tests\": []}';

					dispatch(setUserPreviousTests(arrayOfPreviousTests));
					setLoading(false);
				} else {
					console.log("Error");

					addToast("Invalid username or password - if you are unable to login, please contact the head of your organisation.", {
						appearance: 'error',
						autoDismiss: true
					});
					setLoading(false);
				}
			})

		});
	}
	const [loading, setLoading] = useState(false);

	// This section renders when the user is logging in for the first time
	const renderChangePasswordSection = () => {
		if (orgId == 349 || orgId == 238) {
			return (
				<>
					<div className="login-box-container">
						<div className="new-password-login">
							<div className="section">
								<label>New password</label>
								{/* <input type="password" onChange={(e) => setNewPassword(e.target.value)} /> */}
								<input type="password" onChange={(e) => passwordOnChangeHandler(e.target.value)} />
								<p className="text-danger">{passwordError}</p>
							</div>

							<div className="section">
								<label>Confirm password</label>
								<input type="password" onChange={(e) => setNewPasswordConfirm(e.target.value)} />
							</div>
							<div className="section">
								{renderPasswordScore(StudentPasswordScore)}
							</div>
						</div>
					</div>

					<div className="login-button">
						<input type="button" value="Set password" onClick={() => setNewPasswordFunc()} />
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="login-box-container">
						<div className="new-password-login">
							<div className="section">
								<label>New password</label>
								<input type="password" onChange={(e) => setNewPassword(e.target.value)} />
								{/* <input type="password" onChange={(e) => passwordOnChangeHandler(e.target.value)} /> */}
							</div>

							<div className="section">
								<label>Confirm password</label>
								<input type="password" onChange={(e) => setNewPasswordConfirm(e.target.value)} />
							</div>
						</div>
					</div>

					<div className="login-button">
						<input type="button" value="Set password" onClick={() => setNewPasswordFunc()} />
					</div>
				</>
			)
		}
	}

	const setNewPasswordFunc = () => {
		if (newPassword == newPasswordConfirm && newPassword != null && newPassword != "" && passwordError == "") {
			fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/NewPassword`, {
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify({
					user_id: state.user.userBackendId,
					password: newPassword,
					organisation_id: orgId
				})
			}).then(res => {
				res.json().then(result => {
					if (usingEmail) {
						dispatch(setUserId(storedUsername));
					} else {
						dispatch(setUserId(loginUserId));
					}
				}).catch(error => {
					console.log("An error occured: ", error);
				})
			}).catch(er => {
				console.log("An error occured: ", er);
			})
		} else if (newPassword == null || newPassword == "") {
			addToast("Password cannot be empty", {
				appearance: 'error',
				autoDismiss: true
			});
		} else if (passwordError != "") {
			addToast("Please follow the hint to set a strong password", {
				appearance: 'error',
				autoDismiss: true
			});
		} else {
			addToast("New password and confirmed password do not match", {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}

	return (
		<div className="login-wrapper">
			<div className="login-box">
				<div className="login-heading"><span>{!isFirstTimeViewing ? "Login" : "Change password"}</span></div>
				{!isFirstTimeViewing ?
					<form onSubmit={loginOnClick} >
						<div className="login-box-container">
							<div className="login-box-userid">
								<label className="login-box-userid-title" for={"login-box-userid-input"}>
									Username/Email
								</label>
								<input
									type="text"
									name="userid"
									id="login-box-userid-input"
									value={loginUserId}
									onChange={(e) => setLoginUserId(e.target.value)}
									placeholder={"Enter student number"}
								/>
							</div>
							<div className="login-box-pwd">
								<label className="login-box-pwd-title" for={"login-box-userpwd-input"}>
									Password
								</label>
								<input
									type="password"
									name="password"
									id="login-box-userpwd-input"
									onChange={(e) => setLoginUserPwd(e.target.value)}
									placeholder={"Enter password"}
								/>
							</div>

						</div>
						{/** End of container */}
						<div className="login-button">
							<input disabled={loading && true} type="submit" value="Login" />
						</div>
					</form>
					:
					renderChangePasswordSection()
				}

				<div onClick={() => {
					setForgotPasswordDialog(true);
				}} className="login-forgot-pwd">Forgotten your password?</div>
			</div>

			<Dialog
				open={forgotPasswordDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => { setForgotPasswordDialog(false) }}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">Forgot your password?</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						If you've forgotten your password - please contact your group/class administrator.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { setForgotPasswordDialog(false) }} color="primary">
						OK
					</Button>
				</DialogActions>
			</Dialog>


		</div>
	);
}

export default Login;
