import { CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { GetGlobalWeightings, UpdateGlobalWeightings } from '../../../../api/endpoints';

import './style.css';

let saveCancelToken = Axios.CancelToken.source();
let retrieveCancelToken = Axios.CancelToken.source();

const CustomWeighting = () => {
    const MINIMUM_VALUE = 0.1;
    const MAXIMUM_VALUE = 1;
    const STEP_INCREMENT = 0.1;
    const DEFAULT_GLOBAL_MODIFIER_VALUE = 0.1;

    const [globalModifier, setGlobalModifier] = useState(0.1);
    const [loading, setLoading] = useState(true);

    // React Toast Notifications Object
    const { addToast } = useToasts();

    /**
     *  On change handler responsible for handling the change of the Global Modifier
     */
    const globalModiferOnChangeHandler = e => {
        if (e.target.value > 1) {
            setGlobalModifier(1);
        } else if (e.target.value < 0.1) {
            setGlobalModifier(0.1);
        } else {
            setGlobalModifier(parseFloat(e.target.value).toFixed(1));
        }
    }

    const resetOnClickHandler = () => {
        setGlobalModifier(DEFAULT_GLOBAL_MODIFIER_VALUE);
        onSaveClickHandler(DEFAULT_GLOBAL_MODIFIER_VALUE);
    }

    const onSaveClickHandler = (modifier = globalModifier) => {
        saveCancelToken.cancel();
        saveCancelToken = Axios.CancelToken.source();
        setLoading(true);

        var object = {};
        object.final_distance_multiplier = modifier + "";

        UpdateGlobalWeightings(object, saveCancelToken.token).then(res => {
            setLoading(false);
            addToast("Saved Global Configuration", {
                appearance: 'success',
                autoDismiss: true
            });
        });
    }

    useEffect(() => {
        retrieveCancelToken.cancel();
        retrieveCancelToken = Axios.CancelToken.source();

        GetGlobalWeightings(retrieveCancelToken.token).then(res => {
            if(res && res.data) {
                var data = res.data;

                if(data.final_distance_multiplier) {
                    setGlobalModifier(parseFloat(data.final_distance_multiplier).toFixed(1));
                    setLoading(false);
                }
            }
        })
    }, []);

    return (
        <div className="screen-section admin-screen-section custom-weightings">
            <div className="custom-weighting-container">
                <div className="custom-weighting-heading">
                    <span>Custom Weighting</span>
                    {loading && <>&nbsp;<CircularProgress disableShrink size={20} /></> }
                </div>

                <div className="global-modifier-control-container">
                    <input
                        type="range"
                        value={globalModifier}
                        onChange={e => globalModiferOnChangeHandler(e)}
                        min={MINIMUM_VALUE}
                        max={MAXIMUM_VALUE}
                        step={STEP_INCREMENT}
                    />

                    <input
                        type="number"
                        value={globalModifier}
                        onChange={e => globalModiferOnChangeHandler(e)}
                        min={MINIMUM_VALUE}
                        max={MAXIMUM_VALUE}
                        step={STEP_INCREMENT}
                    />
                </div>

                <div className="global-modifier-buttons-container">
                    <input
                        type="button"
                        className="sportmatch-button"
                        value="Reset"
                        onClick={resetOnClickHandler}
                    />

                    <input
                        type="button"
                        className="sportmatch-button"
                        value="Save"
                        onClick={() => onSaveClickHandler()}
                    />

                    {/* {loading && <CircularProgress disableShrink size={20} />} */}
                </div>

            </div>
        </div>
    )
}

export default CustomWeighting;