// REACT
import React, { useState, useEffect } from "react";

// MATERIAL UI
import { Select, MenuItem, CircularProgress } from "@material-ui/core";

// IMAGES
import Download from "../../../../../assets/download.png";
import DownloadSVG from "../../../../../assets/svg/downloadSvg.js";

// CONSTANTS
import {
	BACKEND_URL,
	ENVIRONMENT_PATH,
} from "../../../../../store/constants";
import { useSelector } from "react-redux";

import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

import XLSX from 'xlsx';

import IM from 'convert-units';
import { ConvertToFeetAndInches } from "../../../../../helper/helper";

const CSVHEADING = [
	"User ID",
	"Gender (m/f)",
	"Group",
	"Test date (yyyy/mm/dd)",
	"Age (yr)",
	"Fitness age (yr)",
	"% Overlap No 1 sport",
	"Sport No.1",
	"% Overlap No 2 sport",
	"Sport No.2",
	"% Overlap No 3 sport",
	"Sport No.3",
	"% Overlap No 4 sport",
	"Sport No.4",
	"% Overlap No 5 sport",
	"Sport No.5",
	"Sport type #1",
	"Sport type #2",
	"Sport type #3",
	"Sport type #4",
	"Sport type #5",
	"Sport type #6",
	"Height (cm)",
	"Weight (kg)",
	"Arm span (cm)",
	"Forearm length (cm)",
	"Grip strength - left (kg)",
	"Grip strength - right (kg)",
	"Inclined pull-ups (in 30 s)",
	"Aerobic fitness (VO2max)",
	"Sit & reach (cm)",
	"Sitting height (cm)",
	"Bent-knee sit-ups (in 60 s)",
	"Sprint distance (m)",
	"Sprint time (s)",
	"Standing broad jump (cm)",
	"Upper arm length (cm)",
	"Vertical jump (cm)",
	"1.6 km run/walk (s)",
	"4 x 10 m shuttle run (s)",
	"Yo-Yo Test (level-shuttle-stage)",
	"Shuttle (beep) test"
];

const CSVHEADING_IMPERIAL = [
	"User ID",
	"Gender (m/f)",
	"Group",
	"Test date (yyyy/mm/dd)",
	"Age (yr)",
	"Fitness age (yr)",
	"% Overlap No 1 sport",
	"Sport No.1",
	"% Overlap No 2 sport",
	"Sport No.2",
	"% Overlap No 3 sport",
	"Sport No.3",
	"% Overlap No 4 sport",
	"Sport No.4",
	"% Overlap No 5 sport",
	"Sport No.5",
	"Sport type #1",
	"Sport type #2",
	"Sport type #3",
	"Sport type #4",
	"Sport type #5",
	"Sport type #6",
	"Height (feet'inches\")",
	"Weight (lb)",
	"Arm span (feet'inches\")",
	"Forearm length (in)",
	"Grip strength - left (lb)",
	"Grip strength - right (lb)",
	"Inclined pull-ups (in 30 s)",
	"Aerobic fitness (VO2max)",
	"Sit & reach (in)",
	"Sitting height (lb)",
	"Bent-knee sit-ups (in 60 s)",
	"Sprint distance (yd)",
	"Sprint time (s)",
	"Standing broad jump (feet'inches\")",
	"Upper arm length (in)",
	"Vertical jump (in)",
	"1 mile run/walk (s)",
	"4 x 11 yd shuttle run (s)",
	"Yo-Yo Test (level-shuttle-stage)",
	"Shuttle (beep) test"
];

const UserSection = ({ studentData, loading, pageSize, setPageSize, sportSelectionRequest, usingOrgSports, filterString }) => {
	const organisationLocalState = useSelector((state) => state.organisationState);
	const userState = useSelector(state => state.user);

	const usingImperial = userState.userUOM == "Imperial";

	const [topFilter, setTopFilter] = useState("Top 10");

	const headerElement = React.createRef();
	const bodyElement = React.createRef();

	const [loadingCSVDownload, setLoadingCSVDownload] = useState(false); // Indicator for whether the "Download as CSV" button is performing actions
	const [loadingCSVDownloadSingle, setLoadingCSVDownloadSingle] = useState(-1) // download csv indication, but this value will be corresponding to to student row index

	var fitness_arr = null;
	var testDate = null;
	var user_age = null;
	var fitness_age = null;
	var shuttleTest = null;

	const downloadSingleUserFromTable = (userID) => {
		fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentIDReportGenerator?userIDs=${userID}&using_org_sports=${usingOrgSports}`, {
			credentials: 'include'
		}).then(res => {
			res.json().then(result => {
				let csvContent = "data:text/csv;charset=utf-8,";
				let userData = result[0].user_object;
				let testData = result[0].test;
				let topFiveSports = result[0].top_five_sports;
				let topSportTypes = result[0].top_sport_types;
				let singleRow = [];
				let rows = [];

				var csvString = "";

				if (usingImperial) {
					csvString = CSVHEADING_IMPERIAL;
				} else {
					csvString = CSVHEADING;
				}

				rows.push(csvString);

				singleRow.push(userData.name);
				singleRow.push(userData.gender);
				singleRow.push(testData.group_name);
				singleRow.push("'" + testData.test_date.slice(0, 10));
				singleRow.push(parseFloat(testData.age.toFixed(1)));
				singleRow.push(parseFloat(parseFloat(testData.fitness_age).toFixed(1)));

				// Drawing top sports
				for (var i = 0; i < 6; i++) {
					if (Object.keys(topFiveSports).length < i) {
						singleRow.push("");
						singleRow.push("");
					} else {
						if (topFiveSports[i]) {
							singleRow.push(parseFloat(topFiveSports[i].final_distance));
							singleRow.push(topFiveSports[i].sport_name);
						}
					}
				}

				// Drawing top sport types
				if (topSportTypes) {
					if (topSportTypes.indexOf(",") != -1) {
						var sportTypeCollection = topSportTypes.split(",");

						for (var x = 0; x < sportTypeCollection.length; x++) {
							var sportType = sportTypeCollection[x];

							switch (sportType) {
								case "af":
									sportType = "Aerobic fitness";
									break;
								case "gf":
									sportType = "Game fitness";
									break;
								case "speed":
									sportType = "Speed";
									break;
								case "strength":
									sportType = "Strength";
									break;
								case "skill":
									sportType = "Skill";
									break;
								case "power":
									sportType = "Power";
									break;
							}
							singleRow.push(sportType);
						}
					}
				} else {
					for (var i = 0; i < 6; i++) {
						singleRow.push("");
					}
				}

				// Adding in raw-test values
				drawTestData(singleRow, usingImperial, testData);

				rows.push(singleRow);

				rows.forEach(function (rowArray) {
					let row = rowArray.join(",");
					csvContent += row + "\r\n";
				});

				var wb = XLSX.utils.book_new(); // Instantiate a new workbook
				wb.SheetNames.push("talent_id_single");

				var arrayOfValues = [];

				for (var i = 0; i < rows.length; i++) {
					arrayOfValues.push(rows[i])
				}

				var ws = XLSX.utils.aoa_to_sheet(rows);
				wb.Sheets["talent_id_single"] = ws;

				setLoadingCSVDownloadSingle(-1);

				XLSX.writeFile(wb, "INDIVIDUAL datasheet download from Talent ID.xlsm");
			})
		});
	}

	const downloadMultipleReportsFromTable = () => {
		var ids = "";

		for (var i = 0; i < studentData.length; i++) {
			if (i == studentData.length - 1) {
				ids += studentData[i].user_id ? studentData[i].user_id : (studentData[i].userId ? studentData[i].userId : "");
			} else {
				ids += studentData[i].user_id ? studentData[i].user_id + "," : (studentData[i].userId ? studentData[i].userId + "," : "");
			}
		}

		var urlencoded = new URLSearchParams();
		urlencoded.append("userIDs", ids);

		fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentIDReportGenerator?using_org_sports=${usingOrgSports}`, {
			method: 'POST',
			body: urlencoded,
			credentials: 'include'
		}).then(res => {
			res.json().then(result => {
				let csvContent = "data:text/csv;charset=utf-8,";
				let rows = [];

				if (usingImperial) {
					rows.push(CSVHEADING_IMPERIAL);
				} else {
					rows.push(CSVHEADING);
				}

				for (var l = 0; l < result.length; l++) {
					let userData = result[l].user_object;
					let testData = result[l].test;
					let topFiveSports = result[l].top_five_sports;
					let topSportTypes = result[l].top_sport_types;
					let singleRow = [];

					singleRow.push(userData.name);
					singleRow.push(userData.gender);
					singleRow.push(testData.group_name);
					singleRow.push("'" + testData.test_date.slice(0, 10));
					singleRow.push(parseFloat(testData.age.toFixed(1)));
					singleRow.push(parseFloat(parseFloat(testData.fitness_age).toFixed(1)));

					// Drawing top sports
					for (var i = 0; i < 6; i++) {
						if (Object.keys(topFiveSports).length < i) {
							singleRow.push("");
							singleRow.push("");
						} else {
							if (topFiveSports[i]) {
								singleRow.push(parseFloat(topFiveSports[i].final_distance));
								singleRow.push(topFiveSports[i].sport_name);
							}
						}
					}

					// Drawing top sport types
					if (topSportTypes) {
						if (topSportTypes.indexOf(",") != -1) {
							var sportTypeCollection = topSportTypes.split(",");

							for (var x = 0; x < sportTypeCollection.length; x++) {
								var sportType = sportTypeCollection[x];

								switch (sportType) {
									case "af":
										sportType = "Aerobic fitness";
										break;
									case "gf":
										sportType = "Game fitness";
										break;
									case "speed":
										sportType = "Speed";
										break;
									case "strength":
										sportType = "Strength";
										break;
									case "skill":
										sportType = "Skill";
										break;
									case "power":
										sportType = "Power";
										break;
								}
								singleRow.push(sportType);
							}
						}
					} else {
						for (var i = 0; i < 6; i++) {
							singleRow.push("");
						}
					}

					// Adding in raw-test values
					drawTestData(singleRow, usingImperial, testData);

					rows.push(singleRow);

					rows.forEach(function (rowArray) {
						let row = rowArray.join(",");
						csvContent += row + "\r\n";
					});

					var wb = XLSX.utils.book_new(); // Instantiate a new workbook
					wb.SheetNames.push("talent_id_single");

					var arrayOfValues = [];

					for (var i = 0; i < rows.length; i++) {
						arrayOfValues.push(rows[i])
					}
				}


				var ws = XLSX.utils.aoa_to_sheet(rows);
				wb.Sheets["talent_id_single"] = ws;

				setLoadingCSVDownload(false);

				XLSX.writeFile(wb, "Group datasheet download from Talent ID.xlsm");
			})
		});
	}

	// Downloads a single talentid report
	const downloadSingleHandler = (name) => {
		fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentID?thing=true${sportSelectionRequest}`, {
			credentials: 'include'
		}).then(res => {
			res.json().then(response => {
				var data = response;

				let csvContent = "data:text/csv;charset=utf-8,";
				var rows = [];

				if (usingImperial) {
					rows.push(CSVHEADING_IMPERIAL);
				} else {
					rows.push(CSVHEADING);
				}

				var fitness_arr;

				for (var i = 0; i < data.length; i++) {
					let singleRow = [];

					if (data[i].name) {
						if (data[i].name == name) {
							singleRow.push(data[i].name);
						} else {
							continue;
						}
					} else if (data[i].user_name) {
						if (data[i].user_name == name) {
							singleRow.push(data[i].user_name);
						} else {
							continue;
						}
					} else {
						continue;
					}

					if (data[i].gender) {
						singleRow.push(data[i].gender);
					} else {
						singleRow.push("");
					}

					singleRow.push(data[i].group);
					testDate = data[i].test_date;
					singleRow.push("'" + testDate.slice(0, 10));
					user_age = data[i].user_test.age;
					singleRow.push(parseFloat(parseFloat(user_age.toFixed(1))));
					fitness_arr = data[i].user_test.predictedAdultFitnesses;
					fitness_age = parseFloat(parseFloat(fitness_arr[0].predicted_fitness_age).toFixed(1));
					singleRow.push(parseFloat(fitness_age));

					// Drawing top sports
					renderTop5Sports(!data[i].sports_in_order, data[i], singleRow);

					// Drawing top sport types
					renderTopSportTypes(data[i], singleRow);

					// Adding in raw-test values
					drawTestData(singleRow, usingImperial, data[i].user_test);

					rows.push(singleRow);
				}

				rows.forEach(function (rowArray) {
					let row = rowArray.join(",");
					csvContent += row + "\r\n";
				});

				var wb = XLSX.utils.book_new(); // Instantiate a new workbook
				wb.SheetNames.push("talent_id_single");

				var arrayOfValues = [];

				for (var i = 0; i < rows.length; i++) {
					arrayOfValues.push(rows[i])
				}

				var ws = XLSX.utils.aoa_to_sheet(rows);
				wb.Sheets["talent_id_single"] = ws;

				setLoadingCSVDownloadSingle(-1);

				XLSX.writeFile(wb, "INDIVIDUAL datasheet download from Talent ID.xlsm");
			})
		});
	};

	const renderStudentRows = (topNumber) => {
		var dom = [];

		if (studentData) {
			for (var i = 0; i < studentData.length; i++) {
				let name = studentData[i].name ? studentData[i].name : studentData[i].user_name;
				let group = studentData[i].test_group ? studentData[i].test_group : (studentData[i].group ? studentData[i].group : null);
				let testDate = "";
				if(studentData[i].hasOwnProperty("user_test")) {
					testDate = studentData[i].user_test.testDate ? studentData[i].user_test.testDate : null;
				} else {
					testDate = studentData[i].test_date ? studentData[i].test_date : null;
				}
				let overlap = studentData[i].overlap ? parseFloat(studentData[i].overlap).toFixed(2) : parseFloat(studentData[i].final_distance).toFixed(2);
				let sport = studentData[i].sports_in_order ? studentData[i].sports_in_order : studentData[i].sport;
				let topSports = studentData[i].sports_in_order ? studentData[i].sports_in_order : [];
				let data = studentData[i];
				let userID = data.user_id ? data.user_id : (data.userId ? data.userId : "");

				if (pageSize != -1) {
					if (i >= pageSize) {
						break;
					}
				}

				var fromTable = true;

				var sportString = "";

				try {
					sport = sport.split(",");
				} catch (error) {
					console.log("Unable to split string");
				}

				var firstKey = Object.keys(sport[0])[0];

				if (isNaN(parseInt(firstKey))) {
					fromTable = false;
				}

				if (!fromTable) {
					sportString = firstKey;
				} else {
					if (topSports.length > 1) {
						sportString = Object.keys(topSports[0]);
					} else {
						if (typeof sport != "string") {
							if (fromTable) {
								sportString = sport[0];
								sportString = sportString.split("|")[0];
							}
						} else {
							sportString = sport;
						}
					}
				}

				let index = i;

				dom.push(
					<div className="test-row">
						<div>{name}</div>
						<div>{group != null ? group : "unavaliable"}</div>
						<div>
							{testDate != null
								? moment(testDate).format("DD/MM/YYYY")
								: "unavailable"}
						</div>
						<div>{overlap}</div>
						<div className="sport-name">{sportString}</div>
						<div className="download-container">
							{loadingCSVDownloadSingle === i && <CircularProgress disableShrink={true} size={20} />}
							<div onClick={() => {
								if (loadingCSVDownloadSingle === -1) {
									setLoadingCSVDownloadSingle(index);

									if (sportSelectionRequest.length == 0) {
										downloadSingleUserFromTable(userID);
									} else {
										downloadSingleHandler(name);
									}
								}

							}} className="talent-id-download-icon">
								<DownloadSVG color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'} />
							</div>
						</div>
					</div>
				);
			}
		}


		return dom;
	};

	// Responsible for drawing the test data onto the CSV
	const drawTestData = (singleRow, usingImperial, userTest) => {
		singleRow.push(usingImperial ? ConvertToFeetAndInches(IM(userTest.height).from('cm').to('in')) : userTest.height);
		singleRow.push(usingImperial ? parseFloat(IM(userTest.weight).from('kg').to('lb').toFixed(1)) : userTest.weight);
		singleRow.push(usingImperial && userTest.armSpan > 0 ? ConvertToFeetAndInches(IM(userTest.armSpan).from('cm').to('in')) : userTest.armSpan);
		singleRow.push(usingImperial && userTest.forearmLength > 0 ? parseFloat(IM(userTest.forearmLength).from('cm').to('in').toFixed(1)) : userTest.forearmLength);
		singleRow.push(usingImperial && userTest.gripStrengthLeft > 0 ? parseFloat(IM(userTest.gripStrengthLeft).from('kg').to('lb').toFixed(1)) : userTest.gripStrengthLeft);
		singleRow.push(usingImperial && userTest.gripStrengthRight > 0 ? parseFloat(IM(userTest.gripStrengthRight).from('kg').to('lb').toFixed(1)) : userTest.gripStrengthRight);
		singleRow.push(userTest.inclinedPullup);

		if (userTest.shuttleTest20m != undefined) {
			if (userTest.shuttleTest20m == "") {
				shuttleTest = 0;
			} else {
				shuttleTest = userTest.shuttleTest20m;
			}
		}

		singleRow.push(shuttleTest != "" ? parseFloat(shuttleTest.toFixed(1)) : "");
		singleRow.push(usingImperial && userTest.sitReach > 0 ? parseFloat(IM(userTest.sitReach).from('cm').to('in').toFixed(2)) : userTest.sitReach);
		singleRow.push(usingImperial && userTest.sittingHeight > 0 ? parseFloat(IM(userTest.sittingHeight).from('cm').to('in').toFixed(1)) : userTest.sittingHeight);
		singleRow.push(userTest.situps);
		singleRow.push(usingImperial && userTest.sprintDistance > 0 ? parseFloat(parseFloat(IM(userTest.sprintDistance).from('m').to('ft') / 3).toFixed(2)) : userTest.sprintDistance);
		singleRow.push(userTest.sprintTime);
		singleRow.push(usingImperial && userTest.standingBroadJump > 0 ? ConvertToFeetAndInches(IM(userTest.standingBroadJump).from('cm').to('in')) : userTest.standingBroadJump);
		singleRow.push(usingImperial && userTest.upperArmLength > 0 ? parseFloat(IM(userTest.upperArmLength).from('cm').to('in').toFixed(2)) : userTest.upperArmLength);
		singleRow.push(usingImperial && userTest.verticalJump > 0 ? parseFloat(IM(userTest.verticalJump).from('cm').to('in').toFixed(1)) : userTest.verticalJump);
		singleRow.push(userTest.walkRun);
		singleRow.push(userTest.shuttleRun);

		if(userTest.yoyoTest && userTest.yoyoTest != -1) {
			singleRow.push(userTest.yoyoTest);
		} else if(userTest.yoyo && userTest.yoyo != -1) {
			singleRow.push(userTest.yoyo);
		} else {
			singleRow.push("");
		}

		if(userTest.shuttleLevel && userTest.shuttleLevel != -1) {
			singleRow.push(userTest.shuttleLevel);
		}
	}

	const downloadFileHandler = () => {
		fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentID?thing=true${sportSelectionRequest}`, {
			credentials: 'include'
		}).then(res => {
			res.json().then(response => {
				var data = response;

				let csvContent = "data:text/csv;charset=utf-8,";
				var rows = [];

				if (usingImperial) {
					rows.push(CSVHEADING_IMPERIAL);
				} else {
					rows.push(CSVHEADING);
				}

				var fitness_arr;

				for (var i = 0; i < data.length; i++) {
					let singleRow = [];

					if (pageSize != -1 && i >= pageSize) {
						break;
					}

					if (data[i].name) {
						singleRow.push(data[i].name);
					} else if (data[i].user_name) {
						singleRow.push(data[i].user_name); 
					} else {
						singleRow.push("unavailable");
					}

					if (data[i].gender) {
						singleRow.push(data[i].gender);
					} else {
						singleRow.push("");
					}

					singleRow.push(data[i].group);
					testDate = data[i].test_date;
					singleRow.push("'" + testDate.slice(0, 10));
					user_age = data[i].user_test.age;
					singleRow.push(parseFloat(user_age.toFixed(1)));
					fitness_arr = data[i].user_test.predictedAdultFitnesses;
					fitness_age = parseFloat(fitness_arr[0].predicted_fitness_age).toFixed(1);
					singleRow.push(parseFloat(fitness_age));

					// Drawing top sports
					renderTop5Sports(!data[i].sports_in_order, data[i], singleRow);

					// Drawing top sport types
					renderTopSportTypes(data[i], singleRow);

					// Adding in raw-test values
					drawTestData(singleRow, usingImperial, data[i].user_test);

					rows.push(singleRow);
				}

				rows.forEach(function (rowArray) {
					let row = rowArray.join(",");
					csvContent += row + "\r\n";
				});

				var wb = XLSX.utils.book_new(); // Instantiate a new workbook
				wb.SheetNames.push("talent_id_group");

				var arrayOfValues = [];

				for (var i = 0; i < rows.length; i++) {
					arrayOfValues.push(rows[i])
				}

				var ws = XLSX.utils.aoa_to_sheet(rows);
				wb.Sheets["talent_id_group"] = ws;

				setLoadingCSVDownload(false);

				XLSX.writeFile(wb, "Group datasheet download from Talent ID.xlsm");
			})
		});
	};

	const useStyles = makeStyles({
		icon: {
			background:
				organisationLocalState.organisationPrimaryColor != ""
					? organisationLocalState.organisationPrimaryColor + "!important"
					: "#9a62b3 !important",
		},
	});

	const classes = useStyles();

	const filterTalentID = (topNumber) => {
		setTopFilter(topNumber);
		var pageSize = topNumber.split(" ")[1];

		if (pageSize == undefined) {
			pageSize = -1;
		}
		setPageSize(pageSize);
		renderStudentRows(topNumber);
	};

	const talentIDBodyScrollHandler = (e) => {
		headerElement.current.scrollLeft = e.target.scrollLeft;
		bodyElement.current.scrollLeft = e.target.scrollLeft;
	}

	return (
		<div className="user-section-container">
			{/** Header */}
			<div className="header">
				<div className="talentid-loading-container">
					{loading ? <CircularProgress size={20} disableShrink /> : null}
				</div>

				<Select
					onChange={(e) => filterTalentID(e.target.value)}
					data-type={""}
					displayEmpty
					inputProps={{
						classes: {
							icon: classes.icon,
						},
					}}
					renderValue={(selected) => {
						if (selected) {
							return selected;
						} else {
							return <span>Top 100</span>;
						}
					}}
				>
					<MenuItem disabled value="">
						<span>Select</span>
					</MenuItem>

					<MenuItem value={"All"}>
						<span>All</span>
					</MenuItem>

					<MenuItem value={"Top 10"}>
						<span>Top 10</span>
					</MenuItem>

					<MenuItem value={"Top 25"}>
						<span>Top 25</span>
					</MenuItem>

					<MenuItem value={"Top 50"}>
						<span>Top 50</span>
					</MenuItem>

					<MenuItem value={"Top 100"}>
						<span>Top 100</span>
					</MenuItem>
				</Select>
			</div>

			<div className="body">
				<div className="heading" ref={headerElement}>
					<div style={{
						minWidth: "660px",
						width: "100%",
						display: "inline-flex",
						padding: "0"
					}}>
						<div>Name</div>
						<div>Group</div>
						<div>Test date</div>
						<div>% Overlap</div>
						<div>Sport</div>
						<div>&nbsp;</div>
					</div>
				</div>

				<div className="test-row-body" onScroll={talentIDBodyScrollHandler} ref={bodyElement}>
					{renderStudentRows(topFilter)}
				</div>

				<div className="test-row-btn-container">
					<div>
						<div
							className={`test-row-download-button ${loadingCSVDownload ? "loading" : ""}`}
							onClick={() => {
								if (!loadingCSVDownload) {
									setLoadingCSVDownload(true);
									if (sportSelectionRequest.length == 0) {
										downloadMultipleReportsFromTable();
									} else {
										downloadFileHandler();
									}
								}
							}}
							style={{
								background:
									organisationLocalState.organisationPrimaryColor != ""
										? organisationLocalState.organisationPrimaryColor
										: "",
							}}
						>
							<span style={{ fontSize: '15px', fontWeight: '400' }}>Download as CSV</span>
						</div>
						{loadingCSVDownload && <CircularProgress disableShrink={true} size={20} />}
					</div>
				</div>
			</div>
		</div>
	);
};

function renderTopSportTypes(studentData, singleRow) {
	if (studentData.sport_type_collection) {
		if (studentData.sport_type_collection.indexOf(",") != -1) {
			var sportTypeCollection = studentData.sport_type_collection.split(",");

			for (var x = 0; x < sportTypeCollection.length; x++) {
				var sportType = sportTypeCollection[x];

				switch (sportType) {
					case "af":
						sportType = "Aerobic fitness";
						break;
					case "gf":
						sportType = "Game fitness";
						break;
					case "speed":
						sportType = "Speed";
						break;
					case "strength":
						sportType = "Strength";
						break;
					case "skill":
						sportType = "Skill";
						break;
					case "power":
						sportType = "Power";
						break;
				}
				singleRow.push(sportType);
			}
		}
	} else {
		for (var i = 0; i < 6; i++) {
			singleRow.push("");
		}
	}
}

// Drawing the top 5 sports within the CSV document
function renderTop5Sports(isLoadingFromTable, studentData, singleRow) {
	if (isLoadingFromTable) {
		var sports = studentData.sport.split(",");
		var finalDistanceCollection = studentData.final_distance_collection.split("|");

		for (var x = 0; x < 5; x++) {
			singleRow.push(parseFloat(parseFloat(finalDistanceCollection[x]).toFixed(1)));
			if (sports[x]) {
				singleRow.push(sports[x].split("|")[0]);
			} else {
				singleRow.push("");
			}
		}
	} else {
		var arrayOfSportNamesInOrder = [];
		for (var l = 0; l < 5; l++) {

			if (l < studentData.sports_in_order.length) {
				var sportObject = studentData.sports_in_order[l];
				var keyName = Object.keys(sportObject);
				arrayOfSportNamesInOrder.push({ name: keyName, distance: sportObject[keyName].final_distance });
			} else {
				arrayOfSportNamesInOrder.push({ name: "", distance: "" });
			}
		}

		// Rendering top 5 sports
		for (var x = 0; x < arrayOfSportNamesInOrder.length; x++) {
			if (isNaN(parseFloat(arrayOfSportNamesInOrder[x].distance))) {
				singleRow.push("");
			} else {
				singleRow.push(parseFloat(parseFloat(arrayOfSportNamesInOrder[x].distance).toFixed(1)));
			}

			singleRow.push(arrayOfSportNamesInOrder[x].name);
		}
	}

}

export default UserSection;
