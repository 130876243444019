import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Radar } from 'react-chartjs-2';

// MATERIAL UI COMPONENTS
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

// Actions
import { changeNormSelection } from '../../../store/actions/compareMyResults.js';

// Stylesheet
import './style.css';

// Components
import SportBox from '../../../components/SportBox';

// MATERIAL UI STYLES
import { makeStyles } from '@material-ui/core/styles';

import {
	BACKEND_URL,
	ENVIRONMENT_PATH
} from '../../../store/constants';

//MOMENTJS
import moment from 'moment';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications';

// IMAGES
import HeightSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/HeightSvg.js';
import WeightSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/WeightSvg.js';
import ArmSpanSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/ArmSpanSvg.js';
import ForearmLengthSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/ForearmLengthSvg.js';
import SittingHeightSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/SittingHeightSvg.js';
import $ from "jquery";
import { CircularProgress } from '@material-ui/core';
import { CompareNorms } from '../../../components/compareNormsComponent.js';

const useStyles = makeStyles(theme => ({
	label: {
		width: '200px'
	}
}));

const arrayOfIcons = [
	{ name: 'Sit & reach', icon: require('../../../assets/compareMyResultsIcons/16032020/sit_reach.png') },
	{ name: 'Standing broad jump', icon: require('../../../assets/compareMyResultsIcons/16032020/standing_broad_jump.png') },
	{ name: 'Grip strength', icon: require('../../../assets/compareMyResultsIcons/16032020/grip_strength.png') },
	{ name: 'Vertical jump', icon: require('../../../assets/compareMyResultsIcons/16032020/vertical_jump.png') },
	{ name: 'Bent-knee sit-ups', icon: require('../../../assets/compareMyResultsIcons/16032020/sit_ups.png') },
	{ name: 'Inclined pull-ups', icon: require('../../../assets/compareMyResultsIcons/16032020/pull_ups.png') },
	{ name: '4 x 10 m shuttle run', icon: require('../../../assets/compareMyResultsIcons/16032020/4x10_shuttle.png') },
	{ name: 'Maximum sprint speed', icon: require('../../../assets/compareMyResultsIcons/16032020/50m_sprint.png') },
	{ name: '1.6 km run/walk', icon: require('../../../assets/compareMyResultsIcons/16032020/walk_run.png') },
	{ name: 'Aerobic fitness', icon: require('../../../assets/compareMyResultsIcons/16032020/beep_test.png') },
]

const data = require('./data/sportsDataset.js');
const sports = data.sportsDataset;
var sportsNorms = data.sportsNorms;

var norms = [];

/** Searches in an array of objects and returns the object */
const search = (nameKey, myArray) => {
	for (var i = 0; i < myArray.length; i++) {
		if (myArray[i].name === nameKey) {
			return myArray[i];
		}
	}
}

//gripStrength
const CompareMyResults = () => {
	const userState = useSelector(state => state).user;
	const adultState = useSelector(state => state).adultValues;
	const organisationState = useSelector(state => state.organisationState);
	const organisationLocalState = useSelector(state => state.organisationState);

	const [showSitAndReach, setShowSitAndReach] = useState(true);
	const [showStandingBroadJump, setShowStandingBroadJump] = useState(true);
	const [showGripStrength, setShowGripStrength] = useState(true);
	const [showVerticalJump, setShowVerticalJump] = useState(true);
	const [showShowSitUps, setShowSitUps] = useState(true);
	const [showPullUps, setShowPullUps] = useState(true);
	const [showShuttleRun, setShowShuttleRun] = useState(true);
	const [showSprint, setShowSprint] = useState(true);
	const [showWalkRun, setShowWalkRun] = useState(true);
	const [showShuttleBeepTest, setShowShuttleBeepTest] = useState(true);

	const [retrieveDBMaterial, setRetrieveDBMaterial] = useState(false);

	const [hideAllToggleStatus, setHideAllToggleStatus] = useState(true);

	// Loading Indicator
	const [loadingNorms, setLoadingNorms] = useState(false);

	// React Toast Notifications Object
	const { addToast } = useToasts();

	const toggleSportSection = (sportName) => {
		switch (sportName) {
			case 'Sit & reach':
				setShowSitAndReach(!showSitAndReach);
				break;
			case 'Standing broad jump':
				setShowStandingBroadJump(!showStandingBroadJump);
				break;
			case 'Grip strength':
				setShowGripStrength(!showGripStrength);
				break;
			case 'Vertical jump':
				setShowVerticalJump(!showVerticalJump);
				break;
			case 'Bent-knee sit-ups':
				setShowSitUps(!showShowSitUps);
				break;
			case 'Inclined pull-ups':
				setShowPullUps(!showPullUps);
				break;
			case '4 x 10 m shuttle run':
				setShowShuttleRun(!showShuttleRun);
				break;
			case 'Maximum sprint speed':
				setShowSprint(!showSprint);
				break;
			case '1.6 km run/walk':
				setShowWalkRun(!showWalkRun);
				break;
			case 'Aerobic fitness':
				setShowShuttleBeepTest(!showShuttleBeepTest);
				break;
		}
	}

	const hideAllBtnToggleHandler = () => {
		if (hideAllToggleStatus) {
			setShowSitAndReach(false);
			setShowStandingBroadJump(false);
			setShowGripStrength(false);
			setShowVerticalJump(false);
			setShowSitUps(false);
			setShowPullUps(false);
			setShowShuttleRun(false);
			setShowSprint(false);
			setShowWalkRun(false);
			setShowShuttleBeepTest(false);
		} else {
			setShowSitAndReach(true);
			setShowStandingBroadJump(true);
			setShowGripStrength(true);
			setShowVerticalJump(true);
			setShowSitUps(true);
			setShowPullUps(true);
			setShowShuttleRun(true);
			setShowSprint(true);
			setShowWalkRun(true);
			setShowShuttleBeepTest(true);
		}

		setHideAllToggleStatus(!hideAllToggleStatus);
	}
	const [selectedDropDown, setSelectedDropDown] = useState('world');
	const [selectedNorms, setSelectedNorms] = useState([
		{
			name: 'Height',
			normValue: 0
		},
		{
			name: 'Weight',
			normValue: 0
		},
		{
			name: 'Arm span',
			normValue: 0
		},
		{
			name: 'Sitting height',
			normValue: 0
		},
		{
			name: 'Bent-knee sit-ups',
			normValue: 0
		},
		{
			name: 'Standing broad jump',
			normValue: 0
		},
		{
			name: 'Sit & reach',
			normValue: 0
		},
		{
			name: 'Inclined pull-ups',
			normValue: 0
		},
		{
			name: '4 x 10 m shuttle run',
			normValue: 0
		},
		{
			name: '1.6 km run/walk',
			normValue: 0
		},
		{
			name: 'Grip strength',
			normValue: 0
		},
		// Unknown Norm (not recorded in supplied documentation)
		{
			name: 'Maximum sprint speed',
			normValue: 0
		},
		{
			name: 'Vertical jump',
			normValue: 0
		},
		{
			name: 'Aerobic fitness',
			normValue: 0
		},
		{
			name: 'Arm span height ratio',
			normValue: 0
		},
		{
			name: 'Forearm length',
			normValue: 0
		}
	]);

	const changeNormsDropDownHandler = (index, value) => {

		// TODO: make this take into account the test date the user partook in a test
		var usersAge = 8;
		var userGender = "male";
		if (userState.userBirthYear !== -1 && userState.userBirthMonth !== -1) {
			var dob = moment((userState.userBirthMonth + 1) + '/01/' + userState.userBirthYear);
			usersAge = Math.floor(moment.duration(moment(new Date()).diff(dob)).asYears()).toFixed(0);
		}

		if (usersAge > 17) {
			usersAge = 17;
		} else if (usersAge < 8) {
			usersAge = 8;
		}

		if (typeof userState.userGender !== 'undefined' && userState.userGender !== "") userGender = userState.userGender;

		setLoadingNorms(true);
		setSelectedDropDown(value);

		fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/norms?name=${value}&gender=${userGender}&age=${usersAge}&user_test_id=${adultState.testId}`,
			{ credentials: "include" }
		).then((res) => {
			res.json().then((data) => {
				sportsNorms = [
					{
						name: 'Height',
						normValue: data.height
					},
					{
						name: 'Weight',
						normValue: data.weight
					},
					{
						name: 'Arm span',
						normValue: 0
					},
					{
						name: 'Sitting height',
						normValue: data.sitting_height_ration
					},
					{
						name: 'Bent-knee sit-ups',
						normValue: data.situps
					},
					{
						name: 'Standing broad jump',
						normValue: data.board_jump
					},
					{
						name: 'Sit & reach',
						normValue: data.sit_reach
					},
					{
						name: 'Inclined pull-ups',
						normValue: data.pullup
					},
					{
						name: '4 x 10 m shuttle run',
						normValue: data.shuttle
					},
					{
						name: '1.6 km run/walk',
						normValue: data.walk_run
					},
					{
						name: 'Grip strength',
						normValue: data.grip_strength
					},
					// Unknown Norm (not recorded in supplied documentation)
					{
						name: 'Maximum sprint speed',
						normValue: data.speed
					},
					{
						name: 'Vertical jump',
						normValue: data.power
					},
					{
						name: 'Aerobic fitness',
						normValue: data.endurance
					},
					{
						name: 'Arm span height ratio',
						normValue: data.arm_span_height_ratio
					},
					{
						name: 'Forearm length',
						normValue: data.brachial_index
					}
				]

				setSelectedNorms(sportsNorms);
				setRetrieveDBMaterial(true);
				setLoadingNorms(false);
			}).catch((e) => {
				setRetrieveDBMaterial(false);
				console.log("An error occured: ", e);
				addToast("Unable to retrieve data from backend", {
					appearance: 'error',
					autoDismiss: true
				});
				setLoadingNorms(false);
			})
		})

		dispatch(changeNormSelection(index));
	}

	const dispatch = useDispatch();

	// Re-orders the array of sports based on user input
	// Way to do this:
	// Have two arrays (non entered and entered)
	// Iterate through the array and for each sport, find the value from the user
	// If the value of the user is entered, then add it to the array of values
	// else add it to the array of non-entered values
	// At the end, combine the array of non-entered values with the array of entered values

	const reorderBasedOnUserInput = (array) => {
		var arrayOfValues = [];
		var arrayOfNoValues = [];
		var arrayFinal = [];
		var value = "";

		for (var i = 0; i < array.length; i++) {
			value = findUserValueForSportName(array[i].name);

			if (value && value !== "" && parseInt(value) !== 0) {
				arrayOfValues.push(array[i].label)
			} else {
				arrayOfNoValues.push(array[i].label)
			}
		}

		arrayFinal = arrayOfValues.concat(arrayOfNoValues);

		return arrayFinal;
	}

	const renderSportsSquares = (sports) => {
		var elem = [];

		//sports = reorderBasedOnUserInput(sports);

		var arrayOfLabels = [];

		for (var i = 0; i < sports.length; i++) {
			arrayOfLabels.push(sports[i].label);
		}

		for (var i = 0; i < sports.length; i++) {
			var obj = search(arrayOfLabels[i], arrayOfIcons);
			elem.push(
				<SportBox sportIcon={obj.icon} sportName={obj.name} name={sports[i].label} onClickHandler={(name) => toggleSportSection(name)} />
			);
		}

		return elem;
	}

	const classes = useStyles();

	const testElements = React.createRef();
	const state = useSelector(state => state).myDetails;
	const adultValueState = useSelector(state => state).adultValues;
	const compareMyResultsState = useSelector(state => state).compareMyResults;
	const [sitUps, setSitUps] = useState(0);
	const [standingBroadJump, setStandingBroadJump] = useState(0);
	const [sitAndReach, setSitAndReach] = useState(0);
	const [pullUps, setPullUps] = useState(0);
	const [shuttleRun, setShuttleRun] = useState(0);
	const [sprint, setSprint] = useState(0);
	const [shuttleBeepTest, setShuttleBeepTest] = useState(0);
	const [runWalk, setRunWalk] = useState(0);
	const [gripStrength, setGripStrength] = useState(0);
	const [verticalJump, setVerticalJump] = useState(0);
	const [showingCompareResults, setShowingCompareResults] = useState(true);

	const [stateNorms, setNorms] = useState([]);

	// Finds a sport with a name and returns an value
	const findUserValueForSportName = (sportName) => {
		for (var p in state) {
			if (p === sportName) {
				return state[p];
			}
		}
	}

	const getAverageGripStrength = () => {
		var gripStrengthLeftLocal = state.leftGripStrength;
		var gripStrengthRightLocal = state.rightGripStrength;

		var hasGripStrengthLeft = (gripStrengthLeftLocal !== '' && gripStrengthLeftLocal !== '0');
		var hasGripStrengthRight = (gripStrengthRightLocal !== '' && gripStrengthRightLocal !== '0');

		if (hasGripStrengthLeft && !hasGripStrengthRight) {
			gripStrengthRightLocal = gripStrengthLeftLocal;
			return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
		} else if (hasGripStrengthRight && !hasGripStrengthLeft) {
			gripStrengthLeftLocal = gripStrengthRightLocal;
			return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
		} else if (hasGripStrengthLeft && hasGripStrengthRight) {
			return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
		} else {
			return 0;
		}
	}

	useEffect(() => {
		setSitUps(state.sitUps);
		setStandingBroadJump(state.standingBroadJump);
		setSitAndReach(state.sitAndReach);
		setPullUps(state.inclinedPullUps);
		setVerticalJump(state.verticalJump);
		setSprint(state.sprintDistance !== '' && state.sprintTime !== '' ? 1 : 0);
		setShuttleBeepTest(state.vo2Max);

		// TODO: work out how you judge the score for timed based events out of 100%?
		setRunWalk(state.runWalkMinutes !== '' ? parseFloat(state.runWalkMinutes) * 60 : 0 + state.runWalkSeconds !== '' ? state.runWalkSeconds : 0);
		setGripStrength(getAverageGripStrength());
		setShuttleRun(state.shuttleRun);

		// Initate the retrieval of norm data from the backend on page load
		changeNormsDropDownHandler(4, "world");
	}, [retrieveDBMaterial])

	const scrollElem = (e) => {
		testElements.current.scrollLeft = e.target.scrollLeft;
	}

	/** Sets the height for the bar */
	const getHeightForBar = (value) => {
		value = parseFloat(value);
		if (value < 3 && value != 0) {
			return 3;
		} else if (value > 1) {
			return value.toFixed(0);
		} else {
			return 0;
		}
	}

	const renderPercentileLabel = (value) => {
		value = parseFloat(value);
		if (value < 1 && value != 0) {
			return "<1%";
		} else {
			return value.toFixed(0) + "%";
		}
	}

	const renderTestResults = (sports) => {
		var elem = [];

		for (var i = 0; i < sports.length; i++) {
			if (sports[i].label === 'Bent-knee sit-ups') {
				elem.push(
					<div className={"test-bar test-bar-1" + (showShowSitUps ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search('Bent-knee sit-ups', selectedNorms).normValue).toFixed(9)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search('Bent-knee sit-ups', selectedNorms).normValue).toFixed(9) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showShowSitUps ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search('Bent-knee sit-ups', selectedNorms).normValue).toFixed(9))}
							</div>
						</div>
					</div>
				);
			} else if (sports[i].label === 'Standing broad jump') {
				elem.push(
					<div className={"test-bar test-bar-2" + (showStandingBroadJump ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search('Standing broad jump', selectedNorms).normValue).toFixed(9)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search('Standing broad jump', selectedNorms).normValue).toFixed(9) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showStandingBroadJump ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search('Standing broad jump', selectedNorms).normValue).toFixed(9))}
							</div>
						</div>
					</div>
				)
			} else if (sports[i].label === 'Sit & reach') {
				elem.push(
					<div className={"test-bar test-bar-3" + (showSitAndReach ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search("Sit & reach", selectedNorms).normValue).toFixed(9)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search("Sit & reach", selectedNorms).normValue).toFixed(9) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showSitAndReach ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search("Sit & reach", selectedNorms).normValue).toFixed(9))}
							</div>
						</div>
					</div>
				)
			} else if (sports[i].label === 'Inclined pull-ups') {
				elem.push(
					<div className={"test-bar test-bar-4" + (showPullUps ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search('Inclined pull-ups', selectedNorms).normValue).toFixed(9)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search('Inclined pull-ups', selectedNorms).normValue).toFixed(9) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showPullUps ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search('Inclined pull-ups', selectedNorms).normValue).toFixed(9))}
							</div>
						</div>
					</div>
				)
			} else if (sports[i].label === '4 x 10 m shuttle run') {
				elem.push(
					<div className={"test-bar test-bar-5" + (showShuttleRun ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search('4 x 10 m shuttle run', selectedNorms).normValue).toFixed(15)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search('4 x 10 m shuttle run', selectedNorms).normValue).toFixed(15) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showShuttleRun ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search('4 x 10 m shuttle run', selectedNorms).normValue).toFixed(15))}
							</div>
						</div>
					</div>
				)
			} else if (sports[i].label === '1.6 km run/walk') {
				elem.push(
					<div className={"test-bar test-bar-6" + (showWalkRun ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search("1.6 km run/walk", selectedNorms).normValue).toFixed(15)) + "%", maxHeight: '100%', display: (parseFloat(search("1.6 km run/walk", selectedNorms).normValue).toFixed(15) > 0 && retrieveDBMaterial) ? 'block' : 'none' }}>
							<div className={"score" + (showWalkRun ? '' : ' hidden')} style={{ display: (parseFloat(search("1.6 km run/walk", selectedNorms).normValue).toFixed(15) > 0 && retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search("1.6 km run/walk", selectedNorms).normValue).toFixed(15))}
							</div>
						</div>
					</div>
				)
			} else if (sports[i].label === 'Grip strength') {
				elem.push(
					<div className={"test-bar test-bar-7" + (showGripStrength ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search('Grip strength', selectedNorms).normValue).toFixed(9)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search('Grip strength', selectedNorms).normValue).toFixed(9) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showGripStrength ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search('Grip strength', selectedNorms).normValue).toFixed(9))}
							</div>
						</div>
					</div>
				)
			} else if (sports[i].label === 'Vertical jump') {
				elem.push(
					<div className={"test-bar test-bar-8" + (showVerticalJump ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search('Vertical jump', selectedNorms).normValue).toFixed(9)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search('Vertical jump', selectedNorms).normValue).toFixed(9) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showVerticalJump ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search('Vertical jump', selectedNorms).normValue).toFixed(9))}
							</div>
						</div>
					</div>
				) //
			} else if (sports[i].label === 'Maximum sprint speed') {
				elem.push(
					<div className={"test-bar test-bar-9" + (showSprint ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search('Maximum sprint speed', selectedNorms).normValue).toFixed(9)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search('Maximum sprint speed', selectedNorms).normValue).toFixed(9) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showSprint ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search('Maximum sprint speed', selectedNorms).normValue).toFixed(9))}
							</div>
						</div>
					</div>
				)
			} else if (sports[i].label === 'Aerobic fitness') {
				elem.push(
					<div className={"test-bar test-bar-10" + (showShuttleBeepTest ? '' : ' hidden')}>
						<div style={{ background: organisationLocalState.organisationPrimaryColor != '' ? ('linear-gradient(' + organisationLocalState.organisationPrimaryColor + ", #e9f4f8)") : 'linear-gradient(#9A62B3, #e9f4f8)', height: getHeightForBar(parseFloat(search('Aerobic fitness', selectedNorms).normValue).toFixed(9)) + "%", maxHeight: '100%', display: (retrieveDBMaterial && parseFloat(search('Aerobic fitness', selectedNorms).normValue).toFixed(9) > 0) ? 'block' : 'none' }}>
							<div className={"score" + (showShuttleBeepTest ? '' : ' hidden')} style={{ display: (retrieveDBMaterial) ? 'block' : 'none' }}>
								{renderPercentileLabel(parseFloat(search('Aerobic fitness', selectedNorms).normValue).toFixed(9))}
							</div>
						</div>
					</div>
				)
			}
		}
		return elem;
	}

	const getRenderPercentileRanking = () => {
		var numToRemove = 10;

		var shuttleBeepTestPercentileRank;
		var sprintPercentileRank;
		var gripStrengthPercentileRank;
		var verticalJumpPercentileRank;
		var walkRunPercentileRank;
		var shuttleRunPercentileRank;
		var pullUpsPercentileRank;
		var sitAndReachPercentileRank;
		var standingBroadJumpPercentileRank;
		var sitUpsPercentileRank;

		if (parseFloat(search('Aerobic fitness', selectedNorms).normValue) > 0) {
			shuttleBeepTestPercentileRank = parseFloat(search('Aerobic fitness', selectedNorms).normValue)
		} else {
			shuttleBeepTestPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search('Maximum sprint speed', selectedNorms).normValue) > 0) {
			sprintPercentileRank = parseFloat(search('Maximum sprint speed', selectedNorms).normValue);
		} else {
			sprintPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search('Grip strength', selectedNorms).normValue) > 0) {
			gripStrengthPercentileRank = parseFloat(search('Grip strength', selectedNorms).normValue);
		} else {
			gripStrengthPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search('Vertical jump', selectedNorms).normValue) > 0) {
			verticalJumpPercentileRank = parseFloat(search('Vertical jump', selectedNorms).normValue);
		} else {
			verticalJumpPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search("1.6 km run/walk", selectedNorms).normValue) > 0) {
			walkRunPercentileRank = parseFloat(search("1.6 km run/walk", selectedNorms).normValue);
		} else {
			walkRunPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search('4 x 10 m shuttle run', selectedNorms).normValue) > 0) {
			shuttleRunPercentileRank = parseFloat(search('4 x 10 m shuttle run', selectedNorms).normValue);
		} else {
			shuttleRunPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search('Inclined pull-ups', selectedNorms).normValue) > 0) {
			pullUpsPercentileRank = parseFloat(search('Inclined pull-ups', selectedNorms).normValue);
		} else {
			pullUpsPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search("Sit & reach", selectedNorms).normValue) > 0) {
			sitAndReachPercentileRank = parseFloat(search("Sit & reach", selectedNorms).normValue);
		} else {
			sitAndReachPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search('Standing broad jump', selectedNorms).normValue) > 0) {
			standingBroadJumpPercentileRank = parseFloat(search('Standing broad jump', selectedNorms).normValue);
		} else {
			standingBroadJumpPercentileRank = 0;
			numToRemove--;
		}

		if (parseFloat(search('Bent-knee sit-ups', selectedNorms).normValue) > 0) {
			sitUpsPercentileRank = parseFloat(search('Bent-knee sit-ups', selectedNorms).normValue);
		} else {
			sitUpsPercentileRank = 0;
			numToRemove--;
		}
		var average = '';
		if (numToRemove > 0) {
			average = ((parseFloat(shuttleBeepTestPercentileRank) +
				parseFloat(sprintPercentileRank) +
				parseFloat(gripStrengthPercentileRank) +
				parseFloat(verticalJumpPercentileRank) +
				parseFloat(walkRunPercentileRank) +
				parseFloat(shuttleRunPercentileRank) +
				parseFloat(pullUpsPercentileRank) +
				parseFloat(sitAndReachPercentileRank) +
				parseFloat(standingBroadJumpPercentileRank) +
				parseFloat(sitUpsPercentileRank)) / numToRemove).toFixed(1) + "%";
		} else {
			average = "No Results"
		}
		return (
			<span style={{ 'color': organisationLocalState.organisationPrimaryColor != "" ? organisationLocalState.organisationPrimaryColor : "" }}>
				{average}
			</span>
		)
	}

	// Renders the norms available for the end-user to select (to compare their results against)
	const renderNormSelection = () => {
		var dom = [];

		dom.push(
			<>
				<option value="world" selected={selectedDropDown === "world" ? true : false}>
					World
        		</option>
				<option value="australian and nz" selected={selectedDropDown === "australian and nz" ? true : false}>
					Australian and NZ
        		</option>
				<option value="european" selected={selectedDropDown === "european" ? true : false}>
					European
        		</option>
				<option value="american" selected={selectedDropDown === "american" ? true : false}>
					American
        		</option>
				<option value="singapore" selected={selectedDropDown === "singapore" ? true : false}>
					Singapore
        		</option>
			</>
		);


		return dom;
	}

	// Returns an array of values
	const getAnthropometryValues = () => {
		var returnedValues = [
			{
				name: "Height",
				value: "-"
			},
			{
				name: "Weight",
				value: "-"
			},
			{
				name: "Sitting height",
				value: "-"
			},
			{
				name: "Forearm length",
				value: "-"
			},
			{
				name: "Arm span height ratio",
				value: "-"
			}
		];

		for (var i = 0; i < returnedValues.length; i++) {
			var normValue = search(returnedValues[i].name, selectedNorms).normValue;

			if (normValue) {
				if (normValue < 1 && normValue != 0) {
					returnedValues[i].value = "<1";
				} else {
					returnedValues[i].value = parseFloat(normValue).toFixed(0);
				}
			} else {
				returnedValues[i].value = "-"
			}
		}

		var array = [
			[search("Height", returnedValues).value + '%'],
			[search("Weight", returnedValues).value + '%'],
			[search("Arm span height ratio", returnedValues).value + '%'],
			[search("Forearm length", returnedValues).value + '%'],
			[search("Sitting height", returnedValues).value + '%']
		];

		return (array)
	};

	var cw = $('.sport-box-image').width();
	$('.sport-box-image').css({ 'height': cw + 'px' });
	window.onload = window.onresize = function (event) {
		var cw = $('.sport-box-image').width();
		$('.sport-box-image').css({ 'height': cw + 'px' });
	};


	return (
		<div className="screen-section container">
			<div className="results-container" style={{ display: showingCompareResults ? 'initial' : 'none', }}>
				<div className="top-heading">
					<div className='sports-heading-vertical-right'>
						<CompareNorms 
							onChangeHandler={(elem) => { changeNormsDropDownHandler(elem.target.selectedIndex, elem.target.value) }} 
							isLoading={loadingNorms}
							selectedNorm={selectedDropDown}
						/>
					</div>
				</div>

				{/* Compare my results content */}
				<div className="compare-results-screen-container">
					<div className="background-container">
						<div className="best-category"></div>
						<div className="good-category"></div>
						<div className="average-category"></div>
						<div className="needs-improvement-category"></div>

						{/* Content which houses the bar scores for each test */}
						<div className="content-box">
							<div ref={testElements} className="sports-heading-vertical">
								{renderSportsSquares(sports)}
								<div className="whitepace-sport">
									<div></div>
								</div>
							</div>
							<div className="compare-results-content" onScroll={scrollElem}>
								{renderTestResults(sports)}

								<div className="whitespace"></div>
							</div>

						</div>
						{/* Content which houses the description for each section of the content, i.e. "Good", "Average", etc. */}
						<div className="compare-results-content-side">
							<div className="best"><span>Best</span></div>
							<div className="good"><span>Good</span></div>
							<div className="average"><span>Average</span></div>
							<div className="needs-improvement"><span>Needs improvement</span></div>
						</div>
					</div>
				</div>

				{/* Bottom bar for Compare my Results screen (the "Hide All", "Average ranking", etc. content) */}
				<div className="bottom-container">
					<div className="bottom-content">
						<input id="hide-all-btn" type="button" name="hide-all-btn" value={hideAllToggleStatus ? "Hide all" : "Show all"} onClick={() => hideAllBtnToggleHandler()} style={{ 'backgroundColor': organisationLocalState.organisationPrimaryColor != "" ? organisationLocalState.organisationPrimaryColor : "" }} />
						<span className="average-ranking-span">Average ranking: <span>{getRenderPercentileRanking()}</span></span>

						<div className="body-shape-and-size-container body-size-and-shape-button-container">
							<Button
								onClick={() => {
									setShowingCompareResults(false);
								}}
								variant={'contained'}
								style={{ fontSize: '13px', width: '175px', borderRadius: '20px', height: '30px' }}
								endIcon={<Icon className="fa fa-chevron-right"></Icon>}
							>Body size and shape</Button>
						</div>

					</div>
				</div>
			</div>

			<div style={{ display: showingCompareResults ? 'none' : 'flex', height: '650px', flexDirection: 'column', width: '100%' }} className='compare-results-screen-container'>
				<div className="top-heading">
					<Button
						onClick={() => {
							setShowingCompareResults(true);
						}}
						color={'secondary'}
						variant={'contained'}
						style={{ width: '170px', marginBottom: '10px', marginLeft: '15px', textTransform: 'initial' }}
						startIcon={<Icon className="fa fa-chevron-left"></Icon>}
					>Fitness test results</Button>

					<CompareNorms 
						onChangeHandler={(elem) => { changeNormsDropDownHandler(elem.target.selectedIndex, elem.target.value) }} 
						isLoading={loadingNorms}
						selectedNorm={selectedDropDown}
					/>
				</div>

				<div className="body-shape-size-chart-container" style={{ position: 'relative', margin: '0 auto', marginTop: '50px' }}>
					<div id="body-shape-size-height-container" className="body-shape-size-label">
						<span>({getAnthropometryValues()[0]})</span>
						<div className="circle"><HeightSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} /></div>
					</div>

					<div id="body-shape-size-weight-container" className="body-shape-size-label">
						<span>({getAnthropometryValues()[1]})</span>
						<div className="circle"><WeightSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} /></div>
					</div>

					<div id="body-shape-size-arm-span-container" className="body-shape-size-label">
						<span>({getAnthropometryValues()[2]})</span>
						<div className="circle"><ArmSpanSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} /></div>
					</div>

					<div id="body-shape-size-sitting-height-container" className="body-shape-size-label">
						<span>({getAnthropometryValues()[3]})</span>
						<div className="circle"><ForearmLengthSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} /></div>
					</div>

					<div id="body-shape-size-forearm-length-container" className="body-shape-size-label">
						<span>({getAnthropometryValues()[4]})</span>
						<div className="circle"><SittingHeightSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380'} /></div>
					</div>
					
					<Radar className="compare-results-bodysize-graph" height={400} width={600} legend={{ display: false }} data={{

						labels: [[''], [''], [''], [''], ['']],
						datasets: [
							{
								label: 'My Second dataset2',
								backgroundColor: organisationLocalState.organisationPrimaryColor ? organisationLocalState.organisationPrimaryColor + "a3" : 'rgb(154, 98, 179, .3)',
								borderColor: organisationLocalState.organisationPrimaryColor ? organisationLocalState.organisationPrimaryColor + "a3" : 'rgb(154, 98, 179, .3)',
								pointBackgroundColor: 'rgba(255,99,132,1)',
								pointBorderColor: '#fff',
								pointRadius: 0,
								pointHoverBackgroundColor: '#fff',
								pointHoverBorderColor: 'rgba(255,99,132,1)',
								data: [
									(search('Height', selectedNorms).normValue ? search('Height', selectedNorms).normValue.toFixed(0) : 0),
									(search('Weight', selectedNorms).normValue ? search('Weight', selectedNorms).normValue.toFixed(0) : 0),
									(search('Arm span height ratio', selectedNorms).normValue ? search('Arm span height ratio', selectedNorms).normValue.toFixed(0) : 0),
									(search('Forearm length', selectedNorms).normValue ? search('Forearm length', selectedNorms).normValue.toFixed(0) : 0),
									(search('Sitting height', selectedNorms).normValue ? search('Sitting height', selectedNorms).normValue.toFixed(0) : 0)
								],
							}
						]
					}}
						options={{
							responsive: true,
							scale: {
								angleLines: {
									display: true,
								},
								pointLabels: {
									display: true
								},
								ticks: {
									display: false,
									suggestedMin: 0,
									suggestedMax: 100,
									maxTicksLimit: 6,
									backdropColor: 'rgb(50, 168, 82)'
								},
								pointLabels: {
									fontSize: 16
								},
							}
						}} />
				</div>


			</div>
		</div>
	);
}

export default CompareMyResults;
