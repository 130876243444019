import React from "react";

const DownloadSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="35.673" viewBox="0 0 33 35.673">
        <path id="download" d="M-.283,49H-.3A2.7,2.7,0,0,1-3,46.3s0,0,0-.007,0,0,0-.007V37.044A2.718,2.718,0,0,1-.283,34.327a2.718,2.718,0,0,1,2.718,2.717v6.55H24.565v-6.55a2.718,2.718,0,0,1,5.435,0v9.238s0,0,0,.007,0,0,0,.007A2.7,2.7,0,0,1,27.3,49Zm11.3-8.2-5.46-6.6a3,3,0,0,1,2.312-4.912h2.691V16.044a2.718,2.718,0,1,1,5.435,0V29.287h2.794A3,3,0,0,1,21.106,34.2l-5.46,6.6a3,3,0,0,1-4.623,0Z" transform="translate(3 -13.326)" fill={color}/>
    </svg>

)

export default DownloadSvg;
