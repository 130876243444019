import React, { useEffect, useRef, useState } from 'react';
import '../style.css';
import { ListedUsersComponent } from './listedUsers';
import SearchIcon from '../../../../assets/Organisation/TalentID/SearchIcon';
import { Dialog, Card, CardContent, Slide, makeStyles } from '@material-ui/core';
import { BACKEND_URL, ENVIRONMENT_PATH } from '../../../../store/constants';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux';
import { setAccentColor, setAccessUser, setPlan, setPrimaryColor, setSecondaryColor } from '../../../../store/actions/OrganisationActions';
import { setMothersHeight, setFathersHeight } from '../../../../store/actions/myDetails';
import { setUserBackendId, setUserOrganisationName, setUserGender, setUserBirthMonth, setUserBirthYear, setUserClassName, setUserRole, setUserPreviousTests, setUserFitnessAge, setUserId, changeScreen, setUserUOM, setUserEmail } from '../../../../store/actions/actions';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { CheckIfUserIsLoggedIn } from '../../../../api/Helper';

// Convert Units
import IM from 'convert-units';

/** Required to show the dialog appearing from the bottom of the screen */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// ZXCVBN - Password rating
const zxcvbn = require("zxcvbn");

export const ListOfUsersComponent = ({ type }) => {
    const dispatch = useDispatch();

    // React Toast Notifications Object
    const { addToast } = useToasts();

    const [viewOrg, setViewOrg] = useState(""); // The id of the organisation the super user is viewing
    const [showDialog, setShowDialog] = useState(false);

    const [organisations, setOrganisations] = useState([]);
    const [users, setUsers] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);
    const [dialogOrgName, setDialogOrgName] = useState("");
    const [selectedOrgId, setSelectedOrgId] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const [numberOfFreeTokens, setNumberOfFreeTokens] = useState(0);

    const [reversedAlphabetical, setReversedAlphabetical] = useState(false);

    // Create organisation popup state fields:
    const [showCreateOrgPopup, setCreateOrgPopup] = useState("");
    const [createOrgName, setCreateOrgName] = useState("");
    const [createOrgPlanID, setCreateOrgPlanID] = useState("502");
    const [createOrgEmail, setCreateOrgEmail] = useState("");
    const [createOrgPassword, setCreateOrgPassword] = useState("");
    const [createOrgLink, setCreateOrgLink] = useState("");

    const userState = useSelector(state => state.user);

    const [generatedFile, setGeneratedFile] = useState(false);

    // Loading indicators
    const [loading, setLoading] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [accessUserLoading, setAccessUserLoading] = useState(false);
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
    const [createOrganisationLoading, setCreateOrganisationLoading] = useState(false);

    const datadumpStatusInterval = useRef(null);

    const useStyles = makeStyles({
        root: {
            height: '420px',
            fontFamily: 'Dubai Regular'
        }
    });

    const classes = useStyles();

    const addTestButtonHandler = (org_name, org_id) => {
        setSelectedOrgId(org_id);
        setDialogOrgName(org_name);
        setShowDialog(true);
    }

    const checkSessionIsValid = () => {
        return new Promise((resolve, reject) => {
            fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/CheckSessionIsValid`, {
                credentials: 'include'
            }).then(res => {
                CheckIfUserIsLoggedIn(res, dispatch).then(() => {
                    resolve();
                })
            }).catch(() => { })
        })
    }

    const viewUsersInOrgButtonHandler = (org_id) => {
        setViewOrg(org_id);

        checkSessionIsValid().then(() => {
            setLoadingUsers(true);
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/getusersinorg?organisationId=${org_id}`, {
                credentials: 'include'
            }).then(res => {
                res.json().then(result => {
                    setDisplayedData(result.result);
                    setUsers(result.result);
                    setLoadingUsers(false);
                }).catch(() => setLoadingUsers(false));
            }).catch(() => setLoadingUsers(false));
        }).catch(() => setLoadingUsers(false));
    }

    const resetPasswordButtonHandler = (user_id) => {
        setResetPasswordLoading(true);

        checkSessionIsValid().then(() => {
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/password?method=reset&userId=${user_id}`, {
                credentials: 'include'
            }).then(res => {
                res.json().then(result => {
                    setResetPasswordLoading(false);
                    if (result.success) {
                        addToast("Password successfully reset", {
                            appearance: 'success',
                            autoDismiss: true
                        })
                    }
                }).catch(er => {
                    console.log("An error occured: ", er);
                    addToast("An error occured", {
                        appearance: 'error',
                        autoDismiss: true
                    })
                })
            }).catch(error => {
                setResetPasswordLoading(false);
                console.log("An error occured: ", error);
                addToast("An error occured", {
                    appearance: 'error',
                    autoDismiss: true
                })
            })
        })
    }

    const accessUserButtonHandler = (userId, userName, isOrganisation) => {
        setAccessUserLoading(true);

        checkSessionIsValid().then(() => {
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/AccessUser`, {
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({ user_id: userId, isOrganisation: isOrganisation })
            }).then(res => {
                var urlencoded = new URLSearchParams();
                urlencoded.append("userId", userName);

                var accessing = "";

                res.json().then(result => {
                    if (result.accessing) {
                        accessing = result.accessing;
                    }
                })

                fetch(BACKEND_URL + ENVIRONMENT_PATH + '/Login', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json'
                    },
                    body: urlencoded,
                    credentials: "include"
                }).then(function (res) {
                    res.json().then(function (data) {
                        setAccessUserLoading(false);
                        dispatch(setAccessUser("true"));

                        var isViewingTeacher = data[0] && data[0].is_teacher;

                        if (isOrganisation) {
                            dispatch(setUserRole("organisation"));
                        } else if (isViewingTeacher) {
                            dispatch(setUserRole("teacher"));
                        } else {
                            dispatch(setUserRole("student"));
                        }

                        if (accessing != "") {
                            dispatch(setUserRole(accessing));
                        }

                        if (data[0].success === true) {
                            sessionStorage.setItem("isLoggedIn", "true");
                        }

                        if (typeof data[0] !== 'undefined' && data[0].success) {
                            if (data[0].organisation_name) {
                                dispatch(setUserOrganisationName(data[0].organisation_name));
                            }

                            if (data[0].user_uom) {
                                dispatch(setUserUOM(data[0].user_uom));
                            }

                            if (data[0].plan) {
                                dispatch(setPlan(data[0].plan));
                            }

                            if (typeof data[0].user_gender !== "undefined" && data[0].user_gender) {
                                dispatch(setUserGender(data[0].user_gender));
                            }

                            if (typeof data[0].user_birth_month !== 'undefined' && data[0].user_birth_month !== 'null') {
                                dispatch(setUserBirthMonth(data[0].user_birth_month));
                            }

                            if (typeof data[0].user_birth_year !== 'undefined' && data[0].user_birth_year !== 'null') {
                                dispatch(setUserBirthYear(data[0].user_birth_year));
                            }

                            if (typeof data[0].user_group !== 'undefined' && data[0].user_group !== 'null') {
                                dispatch(setUserClassName(data[0].user_group));
                            }


                            if (typeof data[0].user_email !== 'undefined') {
                                dispatch(setUserEmail(data[0].user_email));
                            }

                            if (data[0].org_primary_color) dispatch(setPrimaryColor(data[0].org_primary_color));
                            if (data[0].org_secondary_color) dispatch(setSecondaryColor(data[0].org_secondary_color));
                            if (data[0].org_accent_color) dispatch(setAccentColor(data[0].org_accent_color));

                            if (typeof data[0].mother_height !== 'undefined' && data[0].mother_height !== 'null' && data[0].mother_height !== '') {
                                if (data[0].user_uom && data[0].user_uom == "Imperial") {
                                    dispatch(setMothersHeight(IM(data[0].mother_height).from('cm').to('in')));
                                } else {
                                    dispatch(setMothersHeight(data[0].mother_height));
                                }
                            }

                            if (typeof data[0].father_height !== 'undefined' && data[0].father_height !== 'null' && data[0].father_height !== '') {
                                if (data[0].user_uom && data[0].user_uom == "Imperial") {
                                    dispatch(setFathersHeight(IM(data[0].father_height).from('cm').to('in')));
                                } else {
                                    dispatch(setFathersHeight(data[0].father_height));
                                }
                            }

                            dispatch(setUserBackendId(data[0].backend_user_id + ""));

                            var arrayOfPreviousTests = '{ \"tests\": [';
                            for (var i = 0; i < data.length - 1; i++) {
                                arrayOfPreviousTests += JSON.stringify(data[i + 1]) + (i !== data.length - 2 ? "," : "");
                            }

                            arrayOfPreviousTests += ']}';

                            dispatch(setUserPreviousTests(arrayOfPreviousTests));

                            // Setting the previous assigned test to the user
                            if (data.length > 1) {
                                var lastTestFitnessAge = typeof data[1].fitness_age !== 'undefined' && data[1].fitness_age ? data[1].fitness_age : "";
                                var lastTestGripStrengthLeft = typeof data[1].grip_strength_left !== 'undefined' && data[1].grip_strength_left && data[1].grip_strength_left !== -1 ? data[1].grip_strength_left : "";
                                var lastTestGripStrengthRight = typeof data[1].grip_strength_right !== 'undefined' && data[1].grip_strength_right && data[1].grip_strength_right !== -1 ? data[1].grip_strength_right : "";

                                var averageGripStrength = "";
                                if (lastTestGripStrengthLeft === "" && lastTestGripStrengthRight !== "") {
                                    averageGripStrength = lastTestGripStrengthRight;
                                } else if (lastTestGripStrengthLeft !== "" && lastTestGripStrengthRight === "") {
                                    averageGripStrength = lastTestGripStrengthLeft;
                                } else {
                                    averageGripStrength = ((parseFloat(lastTestGripStrengthLeft) + parseFloat(lastTestGripStrengthRight)) / 2);
                                }

                                dispatch(setUserFitnessAge(lastTestFitnessAge));
                            }

                            dispatch(setUserId(userName + ""));
                            dispatch(changeScreen(1));
                        } else if (data.success) {
                            if (data.organisation_name) {
                                dispatch(setUserOrganisationName(data.organisation_name));
                            }

                            dispatch(setUserId(userId));

                            var arrayOfPreviousTests = '{ \"tests\": []}';

                            dispatch(setUserPreviousTests(arrayOfPreviousTests));
                            dispatch(changeScreen(1));
                        } else {
                            console.log("Error");

                            addToast("Invalid username or password - if you are unable to login, please contact the head of your organisation.", {
                                appearance: 'error',
                                autoDismiss: true
                            });
                        }
                    }).catch((er) => {
                        console.log("An error occured: ", er);
                        setAccessUserLoading(false);
                    })
                }, (error) => {
                    console.log("Error|Login:", error);
                    setAccessUserLoading(false);
                })
            }).catch(error1 => {
                console.log("An error occured: ", error1);
                addToast("An error occured", {
                    appearance: 'error',
                    autoDismiss: true
                })
            })
        })
    }

    const requestOrganisations = () => {
        let sortByParams = `?sortByAlphabetical=true&sortAtoZ=${!reversedAlphabetical}`;

        checkSessionIsValid().then(() => {
            setLoadingUsers(true);
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/getallorganisations${sortByParams}`, {
                method: 'GET',
                credentials: 'include',
            }).then(res => {
                res.json().then(result => {
                    setLoadingUsers(false);
                    if (result.loggedOut == "true") {
                        addToast("Unable to retrieve organisations - please logout and in to refresh login session", {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    } else {
                        setOrganisations(result);
                    }
                }).catch(error => {
                    setLoadingUsers(false);
                    addToast("An error occured: " + error, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                })
            }).catch(er => {
                setLoadingUsers(false);
                addToast("An error occured: " + er, {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
        }).catch(() => setLoadingUsers(false));
    }

    const requestStudentsWithinOrganisation = () => {
        checkSessionIsValid().then(() => {
            setLoadingUsers(true);

            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/getusersinorg`, {
                credentials: 'include'
            }).then(res => {
                res.json().then(result => {
                    setDisplayedData(result.result);
                    setUsers(result.result);
                    setLoadingUsers(false);
                }).catch(() => setLoadingUsers(false))
            }).catch(() => setLoadingUsers(false))
        })
    }

    const downloadUserDumpHandler = () => {
        setLoading(true);

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/userdatadump`, {
            method: 'POST',
            credentials: 'include'
        }).then(res => {
            res.text().then(text => {
                datadumpStatusInterval.current = setInterval(() => {
                    fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/datadumpfile?method=status`, {
                        method: 'GET',
                        credentials: 'include'
                    }).then(res => {
                        res.text().then(data => {
                            if (data === '-1.0') {
                                setLoading(false);
                                setGeneratedFile(true);
                                clearInterval(datadumpStatusInterval.current);
                            }
                        })
                    })
                }, 1000);
            })
        })
    }

    const downloadUserDumpFileHandler = () => {
        if (generatedFile == true) {
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/datadumpfile?method=download`, {
                method: 'GET',
                credentials: 'include'
            }).then(res => {
                res.text().then(data => {

                    let csvContent = "data:text/csv;charset=utf-8,";
                    data = csvContent + data;

                    data = data.replace(/-1.0/g, "").replace(/,-1/g, ",");

                    var encodedUri = encodeURI(data);

                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "users_data.csv");
                    document.body.appendChild(link);

                    link.click();
                })
            })
        }
    }

    useEffect(() => {
        if (type != "organisationAdmin") {
            requestOrganisations();
        } else {
            setViewOrg("true");
            requestStudentsWithinOrganisation();
        }

        // Clear interval for getting status on data dump, as we don't want to add unnessecarry load to the backend
        return () => {
            clearInterval(datadumpStatusInterval.current);
        }
    }, []);

    useEffect(() => {
        if (type != "organisationAdmin") {
            requestOrganisations();
        }
    }, [reversedAlphabetical])

    useEffect(() => {
        setDisplayedData(organisations);
    }, [organisations]);

    useEffect(() => {
        if (searchTerm !== "") {
            var displayedList = [];
            if (viewOrg == "") {
                for (var i = 0; i < organisations.length; i++) {
                    if (organisations[i].name) {
                        if (organisations[i].name.toLowerCase().includes(searchTerm.toLowerCase()))
                            displayedList.push(organisations[i]);
                    }
                }
            } else {
                for (var i = 0; i < users.length; i++) {
                    if (users[i].first_name) {
                        if (users[i].first_name.toLowerCase().includes(searchTerm.toLowerCase()))
                            displayedList.push(users[i]);
                    }
                }
            }

            setDisplayedData(displayedList);
        } else {
            if (viewOrg == "") {
                setDisplayedData(organisations);
            } else {
                setDisplayedData(users);
            }
        }
    }, [searchTerm]);

    // Function for adding free test tokens to an organisation
    const addFreeTokens = () => {
        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/freetokenstoorg?organisation_id=${selectedOrgId}&free_tests=${numberOfFreeTokens + ""}`, {
            type: 'GET',
            credentials: 'include',
        }).then(res => {
            res.json().then(result => {
                if (result.result == "Success") {
                    addToast(`Successfully added ${numberOfFreeTokens} tokens`, {
                        autoDismiss: true,
                        appearance: 'success'
                    });
                } else {
                    addToast(`An error occured`, {
                        autoDismiss: true,
                        appearance: 'error'
                    });
                }

                setDialogOrgName("");
                setShowDialog(false);
                setNumberOfFreeTokens(0);
            })
        })
    };

    // TODO: Add loading indicator for when backend is creating organisation
    const createOrganisation = () => {

        if(createOrgPassword != "" && passwordError == "") {
            setCreateOrganisationLoading(true);
            var urlencoded = new URLSearchParams();
            urlencoded.append("method", "update_organisation");
            urlencoded.append("planID", createOrgPlanID);
            urlencoded.append("orgName", createOrgName);
            urlencoded.append("orgType", "School");
            urlencoded.append("orgEmail", createOrgEmail);
            urlencoded.append("orgAddress1", "");
            urlencoded.append("orgState", "SA"); // TODO: add state here
            urlencoded.append("orgPostcode", "5000"); // TODO: add postcode here
            urlencoded.append("orgContactNo", ""); // TODO: add phone number here (see if it's required)
            urlencoded.append("orgCountry", "Australia");
            urlencoded.append("orgPassword", createOrgPassword);
    
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/Organisation`, {
                credentials: 'include',
                method: 'POST',
                body: urlencoded
            }).then(res => res.json().then(result => {
                urlencoded = new URLSearchParams();
                urlencoded.append("method", "get_app_link");
                urlencoded.append("userEmail", createOrgEmail);
                setCreateOrganisationLoading(false);
    
                if (result.name_message == 'ok' && result.email_message == "ok") {
                    fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/Organisation`, {
                        credentials: 'include',
                        method: 'POST',
                        body: urlencoded
                    }).then(res => res.json().then(result => {
                        setCreateOrgLink(result.link);
                        addToast(`Successfully added organisation`, {
                            autoDismiss: true,
                            appearance: 'success'
                        });
                    }))
                } else if (result.name_message != 'ok' && result.email_message == "ok") {
                    setCreateOrgLink(result.name_message);
                    setCreateOrganisationLoading(false);
                } else if ((result.name_message == 'ok' && result.email_message != "ok") || (result.name_message != 'ok' && result.email_message != "ok")) {
                    setCreateOrgLink(result.email_message);
                    setCreateOrganisationLoading(false);
                }
            })).catch(error => {
                console.log("An error occured");
                setCreateOrganisationLoading(false);
            })
        } else if (createOrgPassword == "") {
            addToast("Password cannot be empty", {
                appearance: 'error',
                autoDismiss: true
            });
            setLoading(false);
        } else if (passwordError != "") {
            addToast("Please follow the hint to set a strong password", {
                appearance: 'error',
                autoDismiss: true
            });
            setLoading(false);
        } else {
            addToast("Please follow the hint to set a strong password", {
                appearance: 'error',
                autoDismiss: true
            });
            setLoading(false);
        }
    }

    const renderPasswordScore = (score) => {
        var scoreWord = "poor";

        switch (score) {
            case 0:
                scoreWord = "poor";
                break;
            case 1:
                scoreWord = "poor";
                break;
            case 2:
                scoreWord = "weak";
                break;
            case 3:
                scoreWord = "average";
                break;
            case 4:
                scoreWord = "excellent";
                break;
        }
        return (
            <div className="password-strength-levels organisation-password-meter">
                <div className={`level-${scoreWord} first-level ${score < 2 ? 'empty' : ''}`}></div>
                <div className={`level-${scoreWord} second-level ${score < 3 ? 'empty' : ''}`}></div>
                <div className={`level-${scoreWord} third-level ${score < 4 ? 'empty' : ''}`}></div>
                <span className={`level-${scoreWord} password-level-span`}>{scoreWord}</span>
            </div>
        )
    }

    const [organisationPasswordScore, setOrganisationPasswordScore] = useState(0);
    const [passwordError, setPasswordErr] = useState("");

    const orgPasswordChangeHandler = value => {

        const passwordInputValue = value;

		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
		const minLengthRegExp = /.{8,}/;
		const maxLengthRegExp = /^.{8,64}$/;

		const passwordLength = passwordInputValue.length;
		const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
		const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
		const digitsPassword = digitsRegExp.test(passwordInputValue);
		const specialCharPassword = specialCharRegExp.test(passwordInputValue);
		const minLengthPassword = minLengthRegExp.test(passwordInputValue);
		const maxLengthPassword = maxLengthRegExp.test(passwordInputValue);

		let errMsg = "";

        if (passwordLength === 0) {
            errMsg += "Password is empty";
        }
        if (!uppercasePassword) {
            errMsg += "At least one Uppercase";
        } 
        if (!lowercasePassword) {
            errMsg += "At least one Lowercase";
        } 
        if (!digitsPassword) {
            errMsg += "At least one digit";
        }
        if (!specialCharPassword) {
            errMsg += "At least one Special Characters";
        }
        if (!minLengthPassword) {
            errMsg += "At least minumum 8 characters";
        }
        if (!maxLengthPassword) {
            errMsg += "Maximum length is 64 characters";
        }

        setOrganisationPasswordScore(zxcvbn(value).score);
        setCreateOrgPassword(value);
        setPasswordErr(errMsg);

    };

    return (
        <div className="screen-section admin-screen-section listed-users">
            {/* Header */}
            <div className="super-user-org-header">
                <div className="sort-component">
                    <span>Sort:</span>
                    <select onChange={(e) => setReversedAlphabetical(e.target.value == "reversedAlphabetical")}>
                        <option value="alphabetical">Alphabetical (A - Z)</option>
                        <option value="reversedAlphabetical">Reversed alphabetical (Z - A)</option>
                    </select>
                </div>

                <div className="search-container">
                    <input type="text" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder={"Search... "} />
                    <div onClick={() => null}>
                        <SearchIcon />
                    </div>
                </div>

                <div style={{ position: 'relative' }}>
                    {type != "organisationAdmin" ? <button onClick={() => downloadUserDumpHandler()}>Generate data file</button> : null}
                    <div>
                        {type != "organisationAdmin" ? <div className="export-pdf-loading-container" style={{ opacity: loading ? '1' : '0', top: '8px', right: '-10px' }}>
                            <CircularProgress disableShrink={true} size={20} />
                        </div> : null}
                    </div>
                </div>

                <div>
                    {type != "organisationAdmin" ? <button onClick={() => downloadUserDumpFileHandler()} style={{ opacity: generatedFile ? '1' : '0', top: '25px', right: '240px' }}>Download File</button> : null}
                </div>

                {userState.userRole == "admin" ?
                    <button onClick={() => setCreateOrgPopup(true)}>
                        Create organisation
                    </button> : null
                }

                {loadingUsers || accessUserLoading || resetPasswordLoading ? <CircularProgress size={20} disableShrink /> : null}
            </div>

            {viewOrg != "" && type != "organisationAdmin" ?
                <div style={{ display: "flex", marginBottom: "15px" }}>
                    <button onClick={() => {
                        setViewOrg("");
                        setDisplayedData(organisations);
                        setSearchTerm("");
                    }} style={{ marginLeft: "0px" }}>Back</button>
                </div> : null}

            {/* Body */}
            <ListedUsersComponent
                type={viewOrg != "" ? "user_list" : "organisation_list"}
                data={displayedData}
                addTestButtonHandler={addTestButtonHandler}
                viewUsersInOrgButtonHandler={viewUsersInOrgButtonHandler}
                resetPasswordButtonHandler={resetPasswordButtonHandler}
                accessUserButtonHandler={accessUserButtonHandler}
            />

            <Dialog className="super-user-assign-tokens" open={showDialog} TransitionComponent={Transition} keepMounted onClose={null}>
                <Card className={"first-time-making-test-card"}>
                    <CardContent>
                        <span className="card-body-text-span">
                            Assign test tokens to {dialogOrgName}
                        </span>

                        <div className="no-of-test-tokens-section">
                            <span>Number of test tokens</span>
                            <input onChange={e => setNumberOfFreeTokens(e.target.value)} type="number" min="0" value={numberOfFreeTokens} />
                        </div>

                        <div className="card-button-container">
                            <button style={{ marginLeft: 'auto', width: '80px' }} onClick={() => {
                                setDialogOrgName("");
                                setShowDialog(false);
                            }}>Cancel</button>
                            <button onClick={() => addFreeTokens()} className="begin-btn">Apply</button>
                        </div>
                    </CardContent>
                </Card>
            </Dialog>

            <Dialog open={showCreateOrgPopup} TransitionComponent={Transition} keepMounted onClose={null}>
                <Card>
                    <CardContent className={classes.root}>
                        <form autoComplete={false} action="javascript:myFunction(); return false;">
                            <div className="new-organisation-field">
                                <span>Organisation name:</span>
                                <input type="text" value={createOrgName} onChange={e => setCreateOrgName(e.target.value)} />
                            </div>

                            <div className="new-organisation-field">
                                <span>Organisation email:</span>
                                <input type="text" value={createOrgEmail} onChange={e => setCreateOrgEmail(e.target.value)} />
                            </div>

                            <div className="new-organisation-field">
                                <span>Plan type:</span>
                                <select onChange={e => {
                                    var index = e.target.selectedIndex;
                                    var optionElement = e.target.childNodes[index]
                                    var option = optionElement.getAttribute('plan-id');
                                    setCreateOrgPlanID(option);
                                }}>
                                    <option plan-id={"502"}>Starter</option>
                                    <option plan-id={"503"}>Professional</option>
                                    <option plan-id={"504"}>Enterprise</option>
                                </select>
                            </div>

                            <div className="new-organisation-field" id="password">
                                <span>Password:</span>
                                <input type="password" value={createOrgPassword} onChange={e => orgPasswordChangeHandler(e.target.value)} />
                            </div>
                            <p class="text-danger organisation-password-hint">Password must contain at least 1 uppercase, 1 lowercase, a number, a special character and minumum 8 characters.</p>

                            <div className="row">
                                {renderPasswordScore(organisationPasswordScore)}
                            </div>

                            <span id="link">{createOrgLink.length == 0 ? 'Link: ' : (createOrgLink.includes('org_name') ? 'Link: ' : 'Error: ')}</span>
                            <span id="link-value">{createOrgLink}</span>

                            <div className="card-button-container" style={{ alignItems: 'center' }}>
                                <button
                                    style={{ marginLeft: '25px', width: '80px' }}
                                    onClick={() => {
                                        setCreateOrgPopup(false);
                                    }}
                                >Cancel</button>

                                <button
                                    onClick={() => {
                                        createOrganisation();
                                    }}
                                >Create</button>

                                {createOrganisationLoading && <>&nbsp;<CircularProgress disableShrink size={20} /></>}
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Dialog>
        </div>
    )
}
