// REACT
import React, { useState, useEffect, Fragment } from 'react';

// USER DEFINED IMAGES (non-Visited)
import BodyShapeAndSizeImage from '../../../assets/startTestAssets/body_size_and_shape.png';
import AerobicFitnessImage from '../../../assets/startTestAssets/aerobic_fitness.png';
import FlexibilityImage from '../../../assets/startTestAssets/flexibility.png';
import MuscularEnduranceImage from '../../../assets/startTestAssets/muscular_endurance.png';
import MuscularStrengthAndPowerImage from '../../../assets/startTestAssets/muscular_strength_and_power.png';
import SpeedAndAgilityImage from '../../../assets/startTestAssets/speed_and_agility.png';

// USER DEFINED IMAGES (non-Visited mousedown)
import BodyShapeAndSizeMousedownImage from '../../../assets/startTestAssets/notVisitedMouseDown/body_size_and_shape_mousedown.png';
import AerobicFitnessMousedownImage from '../../../assets/startTestAssets/notVisitedMouseDown/aerobic_fitness_mousedown.png';
import FlexibilityMousedownImage from '../../../assets/startTestAssets/notVisitedMouseDown/flexibility_mousedown.png';
import MuscularEnduranceMousedownImage from '../../../assets/startTestAssets/notVisitedMouseDown/muscular_endurance_mousedown.png';
import MuscularStrengthAndPowerMousedownImage from '../../../assets/startTestAssets/notVisitedMouseDown/muscular_strength_and_power_mousedown.png';
import SpeedAndAgilityMousedownImage from '../../../assets/startTestAssets/notVisitedMouseDown/speed_and_agility_mousedown.png';

// USER DEFINED IMAGES (visited)
import BodyShapeAndSizeVisitedImage from '../../../assets/startTestAssets/visited/body_size_and_shape_visited.png';
import AerobicFitnessVisitedImage from '../../../assets/startTestAssets/visited/aerobic_fitness_visited.png';
import FlexibilityVisitedImage from '../../../assets/startTestAssets/visited/flexibility_visited.png';
import MuscularEnduranceVisitedImage from '../../../assets/startTestAssets/visited/muscular_endurance_visited.png';
import MuscularStrengthAndPowerVisitedImage from '../../../assets/startTestAssets/visited/muscular_strength_and_power_visited.png';
import SpeedAndAgilityVisitedImage from '../../../assets/startTestAssets/visited/speed_and_agility_visited.png';

// SVGs
import BodySizeShapeSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/BodySizeShapeSvg.js';
import SitReachSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/sitReachSvg.js';
import MuscularStrengthSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/MuscularStrengthSvg.js';
import InclinedPullUpsSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/InclinedPullUpsSvg.js';
import SpeedAgilitySvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/SpeedAgilitySvg.js';
import AerobicSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/AerobicSvg.js';

// MATERIAL UI COMPONENTS
import Button from '@material-ui/core/Button';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// Actions
import {
    setSportMatchBodyShapeAndSizeSectionVisited,
    setSportMatchFlexibilitySectionVisited,
    setSportMatchMuscularStrengthAndPowerSectionVisited,
    setSportMatchMuscularEnduranceSectionVisited,
    setSportMatchSpeedAndAgilitySectionVisited,
    setSportMatchAerobicFitnessSectionVisited,
} from '../../../store/actions/actions.js';

// Custom Component
import { TestMessageCard, TestMessageBegin } from './startTestMessage';
import AgeMessageCard from './startTestAge';

import {
    hasBodyShapeAndSizeFilledOut,
    hasFlexibilityFilledOut,
    hasMuscularStrengthAndPowerFilledOut,
    hasMuscularEnduranceFilledOut,
    hasSpeedAndAgilityFilledOut,
    hasAerobicFitnessFilledOut
} from '../myDetails/FilledOutFunctions';
import GenderConfirmation from './genderConfirmation';
import { retrieveJSONOfUser } from '../../../helper/helper';
import { B2C_ROLE_NAME, BACKEND_URL, ENVIRONMENT_PATH } from '../../../store/constants';

import IM from 'imperial-metric';

const StartTestMainScreen = ({ onSubmitClick: onSubmitClick, gotoTestScreen: gotoTestScreen, setTestStartDate:setTestStartDate }) => {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const organisationStateLocal = useSelector(state => state.organisationState);
    const [firstTimeStartingSportMatchTestToggle, setFirstTimeStartingSportMatchTestToggle] = useState(false);
    const [ageValid, setAgeValid] = useState(false);
    const [showValidation, setShowValidation] = useState(false); // Responsible for whether to display the validation screens
    const [showGenderConfirmation, setShowGenderConfirmation] = useState(false);

    const imageOnClick = (screenNo) => {
        if (screenNo === 1) {
            dispatch(setSportMatchBodyShapeAndSizeSectionVisited(true));
        }
        if (screenNo === 2) {
            dispatch(setSportMatchFlexibilitySectionVisited(true));
        }
        if (screenNo === 3) {
            dispatch(setSportMatchMuscularStrengthAndPowerSectionVisited(true));
        }
        if (screenNo === 4) {
            dispatch(setSportMatchMuscularEnduranceSectionVisited(true));
        }
        if (screenNo === 5) {
            dispatch(setSportMatchSpeedAndAgilitySectionVisited(true));
        }
        if (screenNo === 6) {
            dispatch(setSportMatchAerobicFitnessSectionVisited(true));
        }
        gotoTestScreen(screenNo);
    };

    const saveUserDetails = (val) => {
        var customObject = {name: "gender", value: val}
        var object = retrieveJSONOfUser(customObject, reduxState, reduxState.user.userRole == B2C_ROLE_NAME, IM);

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/UserDetails`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(object)
        });
    }

    const renderImage = screenNo => {
        switch (screenNo) {
            case 1:
                if (hasBodyShapeAndSizeFilledOut(reduxState) == "filled-out") {
                    return <BodySizeShapeSvg color={organisationStateLocal.organisationAccentColor != '' ? organisationStateLocal.organisationAccentColor : '#50B383'}/>;
                } else {
                    return <BodySizeShapeSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
                }
            case 2:
                if (hasFlexibilityFilledOut(reduxState) == "filled-out") {
                    return <SitReachSvg color={organisationStateLocal.organisationAccentColor != '' ? organisationStateLocal.organisationAccentColor : '#50B383'}/>;
                } else {
                    return <SitReachSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
                }
            case 3:
                if (hasMuscularStrengthAndPowerFilledOut(reduxState) == "filled-out") {
                    return <MuscularStrengthSvg color={organisationStateLocal.organisationAccentColor != '' ? organisationStateLocal.organisationAccentColor : '#50B383'}/>;
                } else {
                    return <MuscularStrengthSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
                }
            case 4:
                if (hasMuscularEnduranceFilledOut(reduxState) == "filled-out") {
                    return <InclinedPullUpsSvg color={organisationStateLocal.organisationAccentColor != '' ? organisationStateLocal.organisationAccentColor : '#50B383'}/>;
                } else {
                    return <InclinedPullUpsSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
                }
            case 5:
                if (hasSpeedAndAgilityFilledOut(reduxState) == "filled-out") {
                    return <SpeedAgilitySvg color={organisationStateLocal.organisationAccentColor != '' ? organisationStateLocal.organisationAccentColor : '#50B383'}/>;
                } else {
                    return <SpeedAgilitySvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
                }
            case 6:
                if (hasAerobicFitnessFilledOut(reduxState) == "filled-out") {
                    return <AerobicSvg color={organisationStateLocal.organisationAccentColor != '' ? organisationStateLocal.organisationAccentColor : '#50B383'}/>;
                } else {
                    return <AerobicSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
                }
        }
    }

    const setToggleFirstTimeStartingSportMatchTest = () => {
        setTestStartDate(new Date());
        setFirstTimeStartingSportMatchTestToggle(true);

    }

    const renderFirstTimeMakingTestMessage = () => {
        var showDialog = !reduxState.myDetails.sportMatchBodyShapeAndSizeSectionVisited
            && !reduxState.myDetails.sportMatchFlexibilitySectionVisited
            && !reduxState.myDetails.sportMatchMuscularStrengthAndPowerSectionVisited
            && !reduxState.myDetails.sportMatchMuscularEnduranceSectionVisited
            && !reduxState.myDetails.sportMatchSpeedAndAgilitySectionVisited
            && !reduxState.myDetails.sportMatchAerobicFitnessSectionVisited
            && !firstTimeStartingSportMatchTestToggle
            && reduxState.user.testTokenId === "Valid"
            && !reduxState.user.userViewingTest;

        if (showDialog) {
            return (
                <TestMessageCard showDialog={showDialog} toggleHandler={setToggleFirstTimeStartingSportMatchTest} />
            )
        }
    }

    // Checks if the submit button is able to be used (i.e. all test sections have been visited)
    const checkSubmitAvailable = () => {
        var submitAvailiable = false;

        if (reduxState.myDetails.sportMatchBodyShapeAndSizeSectionVisited
            && reduxState.myDetails.sportMatchFlexibilitySectionVisited
            && reduxState.myDetails.sportMatchMuscularStrengthAndPowerSectionVisited
            && reduxState.myDetails.sportMatchMuscularEnduranceSectionVisited
            && reduxState.myDetails.sportMatchSpeedAndAgilitySectionVisited
            && reduxState.myDetails.sportMatchAerobicFitnessSectionVisited) {
            submitAvailiable = true;
            return [true, 'Submit'];
        } else {
            return [false, "Start"];
        }

    };

    const renderSubmitButton = () => {
        return (
            <Button
                onClick={() => submitHandler()}
                style={{ 'backgroundColor': organisationStateLocal.organisationPrimaryColor != "" ? organisationStateLocal.organisationPrimaryColor : 'rgb(154, 98, 179)', color: 'white', textTransform: 'capitalize' }}>
                Submit
            </Button>
        )
    }
    const submitHandler =() => {
        var dob = moment((reduxState.user.userBirthMonth + 1) + '/01/' + reduxState.user.userBirthYear);
        var age = moment.duration(moment(new Date()).diff(dob)).asYears();
        setShowValidation(true);
        if ((reduxState.user.userBirthMonth === -1 && reduxState.user.userBirthYear === -1) || (age < 7)) {
            setAgeValid(true);
        } else if(!reduxState.user.userGender || reduxState.user.userGender == "") {
            setShowGenderConfirmation(true);
        } else {
            setShowValidation(false);
            setShowGenderConfirmation(false);
            onSubmitClick();
        }
    }
    const renderAgeVerification = () => {
        if (ageValid) {
            return (
                // showDialog={showDialog} toggleHandler={setToggleFirstTimeStartingSportMatchTest}
                <AgeMessageCard showDialog={true} onSubmitClick={onSubmitClick} setAgeValid={setAgeValid}/>
            )
        }
    };

    const renderVerification = () => {
        if(showValidation) {
            if (ageValid) {
                return (
                    <AgeMessageCard showDialog={true} onSubmitClick={() => {
                        if(!reduxState.user.userGender || reduxState.user.userGender == "") {
                            setShowGenderConfirmation(true);
                        } else {
                            onSubmitClick();
                        }
                    }} setAgeValid={setAgeValid}/>
                )
            } else if(showGenderConfirmation) {
                return (
                    <GenderConfirmation showDialog={true} onSubmitClick={(gender) => {
                        setShowGenderConfirmation(false);
                        saveUserDetails(gender);
                        onSubmitClick();
                    }} />
                )
            }
        }
    }

    return (
        <React.Fragment>
            {!checkSubmitAvailable()[0] ? <TestMessageBegin /> : null}
            {renderVerification()}
            {renderFirstTimeMakingTestMessage()}
            <div className="screen-sections test-sections">
                <div className="test-menu-selection-container">
                    <div className="row">
                        <div className="test-menu-icons-wrapper">
                            {/*<img onClick={() => imageOnClick(1)} src={renderImage(1)} />*/}
                            <div className="test-menu-icons" onClick={() => imageOnClick(1)}>
                                {renderImage(1)}
                            </div>
                            <span>Body size and shape</span>
                        </div>
                        <div className="test-menu-icons-wrapper">
                            {/*<img onClick={() => imageOnClick(2)} src={renderImage(2)} />*/}
                            <div className="test-menu-icons" onClick={() => imageOnClick(2)}>
                                {renderImage(2)}
                            </div>
                            <span>Flexibility</span>
                        </div>
                        <div className="test-menu-icons-wrapper">
                            {/*<img onClick={() => imageOnClick(3)} src={renderImage(3)} />*/}
                            <div className="test-menu-icons" onClick={() => imageOnClick(3)}>
                                {renderImage(3)}
                            </div>
                            <span>Muscular strength and power</span>
                        </div>
                    {/* </div>
                    <div className="row"> */}
                        <div className="test-menu-icons-wrapper">
                            {/*<img onClick={() => imageOnClick(4)} src={renderImage(4)} />*/}
                            <div className="test-menu-icons" onClick={() => imageOnClick(4)}>
                                {renderImage(4)}
                            </div>
                            <span>Muscular endurance</span>
                        </div>
                        <div className="test-menu-icons-wrapper">
                            {/*<img onClick={() => imageOnClick(5)} src={renderImage(5)} />*/}
                            <div className="test-menu-icons" onClick={() => imageOnClick(5)}>
                                {renderImage(5)}
                            </div>
                            <span>Speed and agility</span>
                        </div>
                        <div className="test-menu-icons-wrapper">
                            {/*<img onClick={() => imageOnClick(6)} src={renderImage(6)} />*/}
                            <div className="test-menu-icons" onClick={() => imageOnClick(6)}>
                                {renderImage(6)}
                            </div>
                            <span>Aerobic fitness</span>
                        </div>
                    </div>
                    <div className="row submit-row">
                        {renderSubmitButton()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default StartTestMainScreen;
