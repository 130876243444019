// REACT
import React, { useState, useEffect } from "react";
import { CircularProgress, Select, MenuItem, makeStyles } from "@material-ui/core";
import Zoom from 'react-reveal/Zoom';
import { useSelector } from "react-redux";

// Stylesheet
import './style.css';

import styled from 'styled-components';

import ReportRow from "./reportRowComponent.js";

const OverlapReportComponent = ({ studentData = [], showReportOverlay, setShowReportOverlay, downloadAllHandler, downloadIndividualHandler, getScoutingData }) => {
    // Redux organisation state
    const organisationLocalState = useSelector(state => state.organisationState);

    // Local state variables
    const [topSort, setTopSort] = useState(-1);
    const [previousPageSize, setPreviousPageSize] = useState(100);
    const [loading, setLoading] = useState(false);
    const [loadingTopX, setLoadingTopX] = useState(false);

    // React references
    const headerElement = React.createRef();
    const bodyElement = React.createRef();

    const useStyles = makeStyles({
        icon: {
            background:
                organisationLocalState.organisationPrimaryColor != ""
                    ? organisationLocalState.organisationPrimaryColor + "!important"
                    : "#9a62b3 !important",
        },
    });

    const renderData = (pageSize) => {
        var dom = [];

        if (studentData) {
            for (var i = 0; i < studentData.length; i++) {
                if(pageSize != -1 && i > pageSize) break;
                var student = studentData[i];

                let name = student.first_name ? student.first_name : student.first_name;
                let gender = student.gender ? student.gender : "";
                let group = student.group ? student.group : null;
                let testDate = student.test_date ? student.test_date : null;
                let overlap = student.overlap ? parseFloat(student.overlap).toFixed(2) : "";
                let sport = student.sport ? student.sport : "";

                dom.push(
                    <ReportRow 
                        name={name}
                        group={group}
                        student={student}
                        overlap={overlap}
                        sport={sport}
                        downloadIndividualHandler={(name) => downloadIndividualHandler(name)}
                        testDate={testDate}
                    />
                );
            }
        }

        return dom;
    };

    const scoutingPageBodyScrollHandler = (e) => {
		headerElement.current.scrollLeft = e.target.scrollLeft;
		bodyElement.current.scrollLeft = e.target.scrollLeft;
    }

    const classes = useStyles();

    // Styled Components
    const ButtonDiv = styled.div`
        background-color: ${organisationLocalState.organisationPrimaryColor != "" ? (organisationLocalState.organisationPrimaryColor  + " !important") : "#9a62b3"}
    `;

    return (
        <>
            <div className={`overlay${!showReportOverlay ? " hidden" : ""}`}></div>
            {showReportOverlay ?
                <div className="overlay-container">
                    <Zoom disabled={!showReportOverlay} duration={400}>
                        <div className={`user-section-container`}>
                            {/** Header */}
                            <div className="header">
                                {loadingTopX && <CircularProgress disableShrink size={24} />}
                                <Select
                                    style={{marginLeft: '10px'}}
                                    onChange={(e) => {
                                        switch (e.target.value) {
                                            case "Top 10":
                                                if(previousPageSize == -1) {
                                                    setLoadingTopX(true);
                                                    getScoutingData(100).then(() => {
                                                        setLoadingTopX(false);
                                                    });
                                                } 

                                                setTopSort(10);
                                                setPreviousPageSize(10);
                                                break;
                                            case "Top 25":
                                                if(previousPageSize == -1) {
                                                    setLoadingTopX(true);
                                                    getScoutingData(100).then(() => {
                                                        setLoadingTopX(false);
                                                    });
                                                } 

                                                setTopSort(25);
                                                setPreviousPageSize(25);
                                                break;
                                            case "Top 50":
                                                if(previousPageSize == -1) {
                                                    setLoadingTopX(true);
                                                    getScoutingData(100).then(() => {
                                                        setLoadingTopX(false);
                                                    });
                                                } 

                                                setTopSort(50);
                                                setPreviousPageSize(50);
                                                break;
                                            case "Top 100":
                                                if(previousPageSize == -1) {
                                                    setLoadingTopX(true);
                                                    getScoutingData(100).then(() => {
                                                        setLoadingTopX(false);
                                                    });
                                                } 

                                                setTopSort(100);
                                                setPreviousPageSize(100);
                                                break;
                                            case "All":
                                                if(previousPageSize != -1) {
                                                    setLoadingTopX(true);
                                                    getScoutingData(-1).then(() => {
                                                        setLoadingTopX(false);
                                                    });
                                                } 

                                                setTopSort(-1);
                                                setPreviousPageSize(-1);
                                                break;
                                        }
                                    }}
                                    studentData-type={""}
                                    displayEmpty
                                    inputProps={{
                                        classes: {
                                            icon: classes.icon,
                                        },
                                    }}
                                    renderValue={(selected) => {
                                        if (selected) {
                                            return selected;
                                        } else {
                                            return <span>Top 100</span>;
                                        }
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>Select</span>
                                    </MenuItem>

                                    <MenuItem value={"All"}>
                                        <span>All</span>
                                    </MenuItem>

                                    <MenuItem value={"Top 10"}>
                                        <span>Top 10</span>
                                    </MenuItem>

                                    <MenuItem value={"Top 25"}>
                                        <span>Top 25</span>
                                    </MenuItem>

                                    <MenuItem value={"Top 50"}>
                                        <span>Top 50</span>
                                    </MenuItem>

                                    <MenuItem value={"Top 100"}>
                                        <span>Top 100</span>
                                    </MenuItem>
                                </Select>
                            </div>

                            <div className="body">
                                <div className="heading" ref={headerElement}>
                                    <div style={{
                                        minWidth: "660px",
                                        width: "100%",
                                        display: "inline-flex",
                                        padding: "0"
                                    }}>
                                        <div>Name</div>
                                        <div>Group</div>
                                        <div>Test date</div>
                                        <div>% Overlap</div>
                                        <div>Sport</div>
                                        <div>
                                            <div style={{width: '80px'}}>&ensp;</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="test-row-body" ref={bodyElement} onScroll={scoutingPageBodyScrollHandler}>
                                    {renderData(topSort)}
                                </div>

                                <div className="button-row">
                                    {loading && <CircularProgress disableShrink size={24} />}

                                    <ButtonDiv
                                        className="test-row-download-button"
                                        style={{marginLeft: '5px'}}
                                        onClick={downloadAllHandler ? () => {
                                            setLoading(true);
                                            downloadAllHandler().then(() => setLoading(false));
                                        } : () => { }}
                                    >
                                        
                                        <span style={{ fontSize: '15px', fontWeight: '400' }}>Download as CSV</span>
                                    </ButtonDiv>

                                    <ButtonDiv className="sportmatch-button" onClick={() => setShowReportOverlay(false)}>Close</ButtonDiv>
                                </div>

                            </div>
                        </div>
                    </Zoom>
                </div> : null}
        </>

    )
}

export default OverlapReportComponent;