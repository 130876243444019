import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//REACT TOAST NOTIFICATIONS
import { ToastProvider } from "react-toast-notifications";

//COMPONENTS
import Header from "../components/header/Header.js";
import Sidemenu from "./sidemenu/Sidemenu.js";
import Screens from "./screens";
import User from "./user";
import Logout from "./user/Logout";

const App = () => {
	const state = useSelector((state) => state);
	const organisationStateLocal = useSelector(
		(state) => state.organisationState
	);

	// Checks if the user has the correct login credentials
	const hasValidLoginCredentials = () => {
		if (state.user.userId !== null && state.user.userId !== "") {
			return true;
		} else {
			return false;
		}
	};

	const checkIfAllowedToProceed = () => {
		if (hasValidLoginCredentials()) {
			return (
				<React.Fragment>
					<Route>
						<Sidemenu />
						<Screens />
					</Route>
					<Route exact path="/logout">
						<Logout />
					</Route>
				</React.Fragment>
			);
		} else {
			return <User />;
		}
	};

	const role = state.user.userRole;
	const pColor = organisationStateLocal.organisationPrimaryColor;
	const sColor = organisationStateLocal.organisationSecondaryColor;
	const aColor = organisationStateLocal.organisationAccentColor;

	if (role == "organisation" || role == "teacher" || role == "admin") {
		if (
			(pColor == "" && sColor == "" && aColor == "") ||
			(pColor == "#9a62b3" && sColor == "#778599" && aColor == "#50B383")
		) {
			return (
				<div className="App" onClick={checkLoginStatus}>
					<ToastProvider>
						<Header />
						<div
							className="body-container"
							style={{ backgroundColor: "#5A5380" }}
						>
							<Router>{checkIfAllowedToProceed()}</Router>
						</div>
					</ToastProvider>
				</div>
			);
		} else {
			return (
				<div className="App" onClick={checkLoginStatus}>
					<ToastProvider>
						<Header />
						<div
							className="body-container"
							style={{ backgroundColor: "white" }}
						>
							<Router>{checkIfAllowedToProceed()}</Router>
						</div>
					</ToastProvider>
				</div>
			);
		}
	} else {
		if (
			(pColor == "" && sColor == "" && aColor == "") ||
			(pColor == "#9a62b3" && sColor == "#778599" && aColor == "#50B383")
		) {
			return (
				<div className="App" onClick={checkLoginStatus}>
					<ToastProvider>
						<Header />
						<div
							className="body-container"
							style={{ backgroundColor: "#CEE2FF" }}
						>
							<Router>{checkIfAllowedToProceed()}</Router>
						</div>
					</ToastProvider>
				</div>
			);
		} else {
			return (
				<div className="App" onClick={checkLoginStatus}>
					<ToastProvider>
						<Header />
						<div
							className="body-container"
							style={{ backgroundColor: "white" }}
						>
							<Router>{checkIfAllowedToProceed()}</Router>
						</div>
					</ToastProvider>
				</div>
			);
		}
	}

	function checkLoginStatus() { }
};

export default App;
