import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

// USER DEFINED FIELDS
import {
    BodySizeAndShapeSliderFields
} from '../../../components/FormInputs.js';

// ACTIONS
import {
    setHeight,
    setWeight,
    setUserInputHeight,
    setUserInputWeight
} from '../../../store/actions/myDetails.js';

// STYLED COMPONENTS
import styled from 'styled-components';
import { QAS_ORG_NAME } from '../../../store/constants.ts';

/** Required to show the dialog appearing from the bottom of the screen */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SubmitTestSection = ({ submitHandler, showDialog, closeDialogHandler }) => {
    const dispatch = useDispatch();
    const state = useSelector(store => store.myDetails);
    const sportMatchState = useSelector(store => store.sportMatchTest);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [sudoHeight, setSudoHeight] = useState(sportMatchState.userInputHeight);
    const [sudoWeight, setSudoWeight] = useState(sportMatchState.userInputWeight);

    const [errors, setErrors] = useState("");

    const userState = useSelector(state => state.user);

    const isQAS = userState.userOrganisationName == QAS_ORG_NAME;

    const organisationState = useSelector(state => state.organisationState);

    /** Handles back button click */
    const backButtonHandler = () => {
        setErrors("");
        closeDialogHandler();
    }

    /** Handles submit button click */
    const submitButtonHandler = (saveSudoFields) => {
        if (saveSudoFields) {

            if (sudoHeight != "" && sudoWeight != "") {
                dispatch(setUserInputHeight(sudoHeight));
                dispatch(setUserInputWeight(sudoWeight));

                closeDialogHandler();
                finalSubmitFunction(sudoHeight, sudoWeight);
            } else {
                setErrors("Please provide a value for the mandatory fields");
            }
        } else {
            setSubmitButtonClicked(true);
        }
    }

    const finalSubmitFunction = (height, weight) => {
        setSubmitButtonClicked(false);
        submitHandler(height, weight);
    }

    const dispatchHeight = (input) => {
        setSudoHeight(input);
    };

    const dispatchWeight = (input) => {
        setSudoWeight(input);
    };

    const SubmitButton = styled.button`
        background-color: ${organisationState.organisationPrimaryColor != "" ? organisationState.organisationPrimaryColor + " !important" : "#9962B1"}
    `;

    /** Renders the "Are you sure..." screen */
    const renderConfirmationCard = () => {
        return (
            <div className="confirmation-body-container">
                <div className="confirmation-heading">
                    <span>Are you sure you want to submit these test results?</span>
                </div>

                <span className="card-body-text-span">
                    You won't be able to edit your results once they are submitted.
                    Make sure all of your results are correct!
                </span>

                <div className="card-button-container">
                    <button onClick={() => backButtonHandler()} className="back-btn">Wait, go back</button>
                    <SubmitButton onClick={() => submitButtonHandler(sportMatchState.userInputHeight !== '' && sportMatchState.userInputWeight !== '')} className="submit-btn">I'm sure, submit my results</SubmitButton>
                </div>
            </div>
        )
    };

    const renderRequiredFieldsContent = () => {
        if (sportMatchState.userInputHeight === '' && sportMatchState.userInputWeight === '') {
            return (
                <React.Fragment>
                    <span className="card-body-text-span">
                        You need to enter your height and weight.
                    </span>

                    <div className="fields">
                        <BodySizeAndShapeSliderFields
                            action={dispatchHeight}
                            label={userState.userUOM == "Imperial" ? "Height" : "Height"}
                            placeholder={"cm"}
                            info={""}
                            labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}
                            required={true}
                            enteredValue={sportMatchState.userInputHeight}
                            formatToFeetAndInches={userState.userUOM == "Imperial"}
                            minValue={100}
                            maxValue={220}
                        />

                        <BodySizeAndShapeSliderFields
                            action={dispatchWeight}
                            label={userState.userUOM == "Imperial" ? "Weight" : "Weight"}
                            placeholder={"kg"}
                            info={""}
                            labelUOM={userState.userUOM == "Imperial" ? "lbs" : "kg"}
                            required={true}
                            enteredValue={sportMatchState.userInputWeight}
                            minValue={20} maxValue={150} />
                    </div>
                </React.Fragment>
            )
        } else if (sportMatchState.userInputHeight !== '' && sportMatchState.userInputWeight === '') {
            return (
                <React.Fragment>
                    <span className="card-body-text-span">
                        You need to enter your weight.
                    </span>

                    <div className="fields">
                        <BodySizeAndShapeSliderFields
                            action={dispatchWeight}
                            label={userState.userUOM == "Imperial" ? "Weight" : "Weight"}
                            placeholder={"kg"}
                            info={""}
                            labelUOM={userState.userUOM == "Imperial" ? "lbs" : "kg"}
                            required={true}
                            enteredValue={sportMatchState.userInputWeight}
                            minValue={20} maxValue={150} />
                    </div>
                </React.Fragment>

            )
        } else if (sportMatchState.userInputHeight === '' && sportMatchState.userInputWeight !== '') {
            return (
                <React.Fragment>
                    <span className="card-body-text-span">
                        You need to enter your height.
                    </span>

                    <div className="fields">
                        <BodySizeAndShapeSliderFields
                            action={dispatchHeight}
                            label={userState.userUOM == "Imperial" ? "Height" : "Height"}
                            placeholder={"cm"}
                            info={""}
                            labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}
                            required={true}
                            enteredValue={sportMatchState.userInputHeight}
                            formatToFeetAndInches={userState.userUOM == "Imperial"}
                            minValue={100}
                            maxValue={220}
                        />
                    </div>
                </React.Fragment>

            )
        }
    };

    const renderErrors = () => {
        if (errors != "") {
            return (
                <div className="validation-errors">
                    {errors}
                </div>
            )
        } else {
            return null;
        }
    }

    const renderRequiredFieldsCard = () => {
        return (
            <div className="confirmation-body-container required-fields">
                {renderRequiredFieldsContent()}

                {renderErrors()}

                <div className="card-button-container">
                    <button onClick={() => submitButtonHandler(true)} className="submit-btn">Submit</button>
                    <button onClick={() => backButtonHandler()} className="back-btn">Back</button>
                </div>
            </div>
        )
    }

    const renderCardContent = () => {
        if (!submitButtonClicked) {
            return renderConfirmationCard();
        } else if (sportMatchState.userInputHeight === '' || sportMatchState.userInputWeight === '') {
            return renderRequiredFieldsCard();
        }
    }

    return (
        <Dialog className="required-field-dialog" open={showDialog} TransitionComponent={Transition} keepMounted onClose={() => backButtonHandler()}>
            <Card className="confirmation-card">
                <CardContent>
                    {renderCardContent()}
                </CardContent>
            </Card>
        </Dialog>
    );
}

export default SubmitTestSection;