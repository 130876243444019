import React from "react";

const HeightSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Height</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="height">
            <path className="cls-2" style={{fill: color}} d="M287.22,317.48V262.85c-2.58,9.8-5,18.17-4.91,27A83.12,83.12,0,0,0,287.22,317.48Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M328,140.43c-15.41-18.76-47.82,2.75-42.06,25.12.44,1.7.85,3.37,1.25,5v-7.08a22.3,22.3,0,1,1,44.59,0V343.9A22.29,22.29,0,0,1,293.14,359V508.23a22.5,22.5,0,0,0,45,0V338c15.61-10.42,21-32.74,24.37-72C366.73,216.51,343.43,159.19,328,140.43Z"
                  transform="translate(-2.83 -7.46)"/>
            <circle className="cls-2" style={{fill: color}} cx="312.31" cy="77.56" r="39.56"/>
            <path className="cls-3" style={{stroke:'#9d1f23',strokeLinecap:'round',strokeMiterlimit:'10',strokeWidth:'45px'}} d="M315.14,154.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-3" style={{stroke:'#9d1f23',strokeLinecap:'round',strokeMiterlimit:'10',strokeWidth:'45px'}} d="M308.61,309.38" transform="translate(-2.83 -7.46)"/>
            <path className="cls-3" style={{stroke:'#9d1f23',strokeLinecap:'round',strokeMiterlimit:'10',strokeWidth:'45px'}} d="M319.25,311.12" transform="translate(-2.83 -7.46)"/>
            <path className="cls-3" style={{stroke:'#9d1f23',strokeLinecap:'round',strokeMiterlimit:'10',strokeWidth:'45px'}} d="M315.14,154.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M262.82,516.32H238.73V50.45h24.09a5,5,0,0,0,0-10H204.64a5,5,0,0,0,0,10h24.09V516.32H204.64a5,5,0,0,0,0,10h58.18a5,5,0,1,0,0-10Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M309.51,145.19a18.31,18.31,0,0,0-18.29,18.3V343.9a18.3,18.3,0,1,0,36.59,0V163.49A18.32,18.32,0,0,0,309.51,145.19Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default HeightSvg;
