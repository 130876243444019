import {
    SET_NORM_NAME_FILTER,
    SET_NORM_GENDER_FILTER,
    SET_NORM_AGE_FILTER
} from '../adminConstants.js';

const initialState = {
    normNameFilter: [],
    normGenderFilter: [],
    normAgeFilter: []
}

export const AdminReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_NORM_NAME_FILTER:
            return { ...state, normNameFilter: action.payload }
        case SET_NORM_GENDER_FILTER:
            return { ...state, normGenderFilter: action.payload }
        case SET_NORM_AGE_FILTER:
            return { ...state, normAgeFilter: action.payload }
        default:
            return initialState
    }
}