import React from "react";

const DownArrowSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="95.91" height="74.288" viewBox="0 0 95.91 74.288">
        <defs>
            <filter id="downarrow" x="0" y="0" width="95.91" height="74.288" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
            </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#downarrow)">
            <path id="downarrow-2" data-name="downarrow" d="M23.269,68.26h-7.21A5,5,0,0,1,12.027,60.3L45.967,14.016a5,5,0,0,1,8.065,0L87.973,60.3a5,5,0,0,1-4.032,7.957h-7.21L51.613,34.006a2,2,0,0,0-3.227,0L23.269,68.26Z" transform="translate(97.96 74.26) rotate(180)" fill={color}/>
        </g>
    </svg>


)

export default DownArrowSvg;
