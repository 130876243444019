import React, { useState, useEffect } from 'react';

// MATERIAL UI
import { NativeSelect, Select, MenuItem, Button, TextField } from '@material-ui/core';

const SignupStageThree = ({ setSignupStage, backStage, planSelected }) => {

    const renderPaymentSummary = () => {

        var columnDOM = [
            <span>Basic features</span>
        ];

        var priceColumnDOM = []

        if (planSelected == 1) {
            columnDOM.push(
                <>
                    <span>100 tests</span>
                </>
            )

            priceColumnDOM.push(
                <>
                    <div className="row">
                        <span>$00.00</span>
                        <span className="plus-symbol">+</span>
                    </div>

                    <div className="row row-before-final-cost">
                        <span className="before-price">$00.00</span>
                        <span className="discounted-cost">$00.00</span>
                    </div>
                </>
            )
        }

        if (planSelected == 2) {
            columnDOM.push(
                <>
                    <span>Compare results</span>
                    <span>200 tests</span>
                </>
            )

            priceColumnDOM.push(
                <>
                    <div className="row">
                        <span>$00.00</span>
                        <span className="plus-symbol">+</span>
                    </div>

                    <div className="row">
                        <span>$00.00</span>
                        <span className="plus-symbol">+</span>
                    </div>

                    <div className="row row-before-final-cost">
                        <span className="before-price">$00.00</span>
                        <span className="discounted-cost">$00.00</span>
                    </div>
                </>
            )
        }

        if (planSelected == 3) {
            columnDOM.push(
                <>
                    <span>Compare results</span>
                    <span>Organisation branding</span>
                    <span>500 tests</span>
                </>
            )

            priceColumnDOM.push(
                <>
                    <div className="row">
                        <span>$00.00</span>
                        <span className="plus-symbol">+</span>
                    </div>

                    <div className="row">
                        <span>$00.00</span>
                        <span className="plus-symbol">+</span>
                    </div>

                    <div className="row">
                        <span>$00.00</span>
                        <span className="plus-symbol">+</span>
                    </div>

                    <div className="row row-before-final-cost">
                        <span className="before-price">$00.00</span>
                        <span className="discounted-cost">$00.00</span>
                    </div>
                </>
            )
        }

        return (
            <div className="signup-box-container">
                <div className="col">
                    {columnDOM}

                    <div style={{
                        position: 'relative',
                        width: 'calc(100% + 80px)',
                        position: 'relative',
                        background: 'grey',
                        height: '1px',
                        top: '0',
                        right: '20px'
                    }}></div>
                    <span className="final-label">Yearly fee</span>
                </div>

                <div className="col">
                    {priceColumnDOM}

                    <div className="row">
                        <span className="final-cost">$000.00</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="signup-wrapper signup-stage-three">
            <div className="signup-step-circle-wrapper"><div className="signup-step-circle" id="signup-step-circle-1"></div></div>
            <div className="signup-step-dash"></div>
            <div className="signup-step-circle-wrapper"><div className="signup-step-circle" id="signup-step-circle-2"></div></div>
            <div className="signup-step-dash"></div>
            <div className="signup-step-circle-wrapper"><div className="signup-step-circle selected" id="signup-step-circle-3"></div></div>

            <div className="signup-title">
                Billing information
            </div>

            <div className="signup-box">
                <div className="signup-box-container">
                    <div className="signup-field signup-box-card-type">
                        <div className="signup-field-title signup-box-card-type-title">
                            Card Type
                        </div>
                        <Select
                            displayEmpty
                            onChange={(e) => {

                            }}
                            value={""}
                            renderValue={selected => {
                                if (selected) {
                                    return selected;
                                } else {
                                    return <span></span>;
                                }
                            }}>
                            <MenuItem value="Mastercard">
                                <span>Mastercard</span>
                            </MenuItem>

                            <MenuItem value="Visa">
                                <span>Visa</span>
                            </MenuItem>
                        </Select>
                    </div>

                    <div className="signup-field">
                        <div className="signup-field-title">
                            Card number
                        </div>
                        <input className="signup-field-text" type="text" name="card-number-1" onChange={(e) => null} />
                    </div>

                    <div className="signup-field">
                        <div className="signup-field-title">
                            Expiration date
                        </div>

                        <div style={{ display: 'flex', width: 'calc(80% + 30px)', marginRight: '50px' }}>
                            <input style={{ width: '50%' }} className="signup-field-text" type="text" name="expiration-date" onChange={(e) => null} />

                            <div class="signup-field-title" style={{ marginLeft: '0px', marginRight: '0px', width: '75px' }}>CVV</div>
                            <input style={{ width: '50%', marginRight: '0px' }} type="text" className="signup-field-text" />
                        </div>

                    </div>
                </div>
            </div>

            <div className="signup-box payment-overview">
                {renderPaymentSummary()}

                <div>
                    <input id="terms-and-conditions-check" name="terms-and-conditions-check" type="checkbox" />
                    <label for="terms-and-conditions-check">
                        By clicking "submit" you acknowledge that you have read and agree to the <a href="#">Terms and Conditions</a>
                    </label>
                </div>
            </div>
            

            <div className="signup-next-button">
                <input type="submit" value="Previous" onClick={() => {
                    backStage();
                }} />
                <input type="submit" value="Next" onClick={() => {
                    setSignupStage();
                }} />
            </div>
        </div>
    )
}

export default SignupStageThree;