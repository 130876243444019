import React from 'react';
import {useSelector} from "react-redux";

// SVGs
import SitReachSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/sitReachSvg";
import StandingBroadJumpSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/StandingBroadJumpSvg";
import GripStrengthSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/GripStrengthSvg";
import VerticalJumpSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/VerticalJumpSvg";
import BentKneeSitUpsSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/BentKneeSitUpsSvg";
import InclinedPullUpsSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/InclinedPullUpsSvg";
import ShuttleRunSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/ShuttleRunSvg";
import SprintSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/SprintSvg";
import RunWalkSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/RunWalkSvg";
import ShuttleBeepTestSvg from "../assets/compareMyResultsIcons/16032020/svgFiles/ShuttleBeepTestSvg";

const SportBox = data => {
    const organisationStateLocal = useSelector(state => state.organisationState);

    const findSportByName = () => {
        switch (data.name) {
            case 'Sit & reach':
                return <SitReachSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 'Standing broad jump':
                return <StandingBroadJumpSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 'Grip strength':
                return <GripStrengthSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 'Vertical jump':
                return <VerticalJumpSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 'Bent-knee sit-ups':
                return <BentKneeSitUpsSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 'Inclined pull-ups':
                return <InclinedPullUpsSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case '4 x 10 m shuttle run':
                return <ShuttleRunSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 'Maximum sprint speed':
                return <SprintSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case '1.6 km run/walk':
                return <RunWalkSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 'Aerobic fitness':
                return <ShuttleBeepTestSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            default:
                return <SitReachSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
        }
    }

    return (
        <div className="sport-box-parent-container" onClick={() =>data.onClickHandler(data.name)}>
            {/*<img title={data.name} alt={data.name} className="sport-box-image"  src={data.sportIcon} />*/}
            <div className="sport-box-image">
                {findSportByName()}
            </div>

        </div>
    );
}

export default SportBox;
