import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import './myDetails.css';
import 'font-awesome/css/font-awesome.min.css';

// JQUERY
import $ from 'jquery';

import {
    changeScreen
} from '../../../store/actions/actions.js';

// COMPONENTS
import MyInfo from './MyInfo.js';
import PreviousTest from './PreviousTest.js';
import TestMessageCard from './startTestAge.js';
import { B2C_ROLE_NAME } from '../../../store/constants';

const MyDetails = ({setTestStartDate:setTestStartDate}) => {
    const state = useSelector(state => state);
    const organisationLocalState = useSelector(state => state.organisationState);
    const dispatch = useDispatch();
    // Local State
    const [myInfoExpanded] = useState(true);

    const goToSportMatchTestScreen = () => {
        var dob = moment((state.user.userBirthMonth + 1) + '/01/' + state.user.userBirthYear);
        var age = moment.duration(moment(new Date()).diff(dob)).asYears();
        if ((state.user.userBirthMonth === -1 && state.user.userBirthYear === -1)
            || (age < 7)) {
            setShowDialog(true);
        } else {
            $("#save-icon").click();

            if(state.user.userRole == B2C_ROLE_NAME) {
                dispatch(changeScreen(1));
            } else {
                dispatch(changeScreen(2));
            }
        }
    }

    const [showDialog, setShowDialog] = useState(false)
    const renderFirstTimeMakingTestMessage = () => {
        if (showDialog) {
            return (
                // showDialog={showDialog} toggleHandler={setToggleFirstTimeStartingSportMatchTest}
                <TestMessageCard showDialog={true} />
            )
        }
    }

    const hasAnyFieldsFilledOut = () => {
        if(state.myDetails.height != "") {
            return true;
        }
        if(state.myDetails.weight != "") {
            return true;
        }
        if(state.myDetails.forearmLength != "") {
            return true;
        }
        if(state.myDetails.inclinedPullUps != "") {
            return true;
        }
        if(state.myDetails.leftGripStrength != "") {
            return true;
        }
        if(state.myDetails.rightGripStrength != "") {
            return true;
        }
        if(state.myDetails.runWalkMinutes != "") {
            return true;
        }
        if(state.myDetails.runWalkSeconds != "") {
            return true;
        }
        if(state.myDetails.shuttleRun != "") {
            return true;
        }
        if(state.myDetails.sitAndReach != "") {
            return true;
        }
        if(state.myDetails.sitUps != "") {
            return true;
        }
        if(state.myDetails.sittingHeight != "") {
            return true;
        }
        if(state.myDetails.sprintDistance != "") {
            return true;
        }
        if(state.myDetails.sprintTime != "") {
            return true;
        }
        if(state.myDetails.standingBroadJump != "") {
            return true;
        }
        if(state.myDetails.upperArmLength != "") {
            return true;
        }
        if(state.myDetails.verticalJump != "") {
            return true;
        }
        if(state.myDetails.vo2Max != "") {
            return true;
        }
        if(state.myDetails.armSpan != "") {
            return true;
        }
    }

    return (
        <React.Fragment>
            {renderFirstTimeMakingTestMessage()}
            <div className="screen-sections">
                <MyInfo expanded={myInfoExpanded} />
                <PreviousTest setTestStartDate={setTestStartDate}/>
                <div style={{ width: "calc(80% + 30px)", maxWidth: "980px", margin: "0px 20px 20px 20px" }}>
                    <button 
                        onClick={() => goToSportMatchTestScreen()} 
                        className="start-test-button" 
                        style={{background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : ''}}>
                            {state.myDetails.allFieldsProvided ? "View test" : (hasAnyFieldsFilledOut() ? "Resume test" : "Start test")}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MyDetails;
