import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';

import './screen.css';
// COMPONENTS
import Screen from './Screen.js';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'

// MATERIALUI
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// SVG
import { ReactComponent as Icon } from '../../assets/save-solid.svg';

// PNG
import CalendarWhite from '../../assets/calendar_white.png';
import InformationIconWhite from '../../assets/info_white.png';

import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../store/constants';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

import enAU from 'date-fns/locale/en-AU';

// ACTIONS
import {
    setTestDate, setStudentId, setMothersHeight, setFathersHeight, setExpandedMyInfo, setExpandedFlexibility, setSitAndReach, setStandingBroadJump, setGripStrength, setLeftGripStrength, setRightGripStrength, setVerticalJump, setSitUps, setInclinedPullUps, setShuttleRun, setSprint, setSprintDistance, setSprintTime, setRunWalkMinutes, setRunWalkSeconds, setVo2Max, setBeepTest, setHeight, setWeight, setArmSpan, setAllFieldsProvided, setForearmLength, setUpperArmLength, setSittingHeight, setUserInputGripStrengthRight, setUserInputGripStrengthLeft, setUserInputHeight, setUserInputWeight, setUserInputArmSpan, setUserInputSittingHeight, setUserInputForearmLength, setUserInputUpperArmLength, setUserInputSitAndReach, setUserInputStandingBroadJump, setUserInputVerticalJump, setUserInputBentKneeSitUps, setUserInputInclinedPullUps, setUserInputShuttleRun, setUserInputSprintDistance, setUserInputSprintTime, setUserInputWalkRunMinutes, setUserInputWalkRunSeconds, setUserInputVO2MAX, setUserInputShuttleBeepTest, setUserInputYoyoTest, setUserInputYoyoStage, setUserInputYoyoVO2MAX, setUserInputBeepTestVO2MAX, setYoyoStage, setYoyoTest
} from '../../store/actions/myDetails.js'

import {
    setUserAge, changeScreen, setUserId, setTestTokenId, setUserPassword, setUserBirthMonth, setUserBirthYear, setUserGender, setUserClassName, setUserRole, setUserViewingTest, setFirstTimeViewingTest, setUserOrganisationName, setUserFitnessAge, setTestDateDay, setTestDateMonth, setTestDateYear, setSportMatchBodyShapeAndSizeSectionVisited, setSportMatchFlexibilitySectionVisited, setSportMatchMuscularStrengthAndPowerSectionVisited, setSportMatchMuscularEnduranceSectionVisited, setSportMatchSpeedAndAgilitySectionVisited, setSportMatchAerobicFitnessSectionVisited, setAdultSitAndReach, setAdultStandingBroadJump, setAdultGripStrength, setAdultLeftGripStrength, setAdultRightGripStrength, setAdultVerticalJump, setAdultSitUps, setAdultInclinedPullUps, setAdultShuttleRun, setAdult50mSprint, setAdultWalkRun, setAdultShuttleBeepTest, setAdultVo2Max, setAdultHeight, setAdultWeight, setAdultSittingHeight, setAdultForearmLength, setAdultArmSpan, setPredictedAdultFitnessId, setTestId, setUserPreviousTests, setUserBackendId, setUserEditingTest, setUserUOM, setUserBirthDay, setTokenAllocationType
} from '../../store/actions/actions.js';
import { setAccentColor, setAccessUser, setPendingRefreshCompletion, setPlan, setPrimaryColor, setSecondaryColor } from '../../store/actions/OrganisationActions';
import { CircularProgress } from '@material-ui/core';

const Screens = () => {

    var enAULocale = {
        ...enAU,
        options: {
            weekStartsOn: 1,
            firstWeekContainsDate: 1
        }
    }

    const dispatch = useDispatch();
    const screen = useSelector(state => state.user.screen);
    const user = useSelector(state => state.user)
    const testState = useSelector(state => state.myDetails);
    const organisationStateLocal = useSelector(state => state.organisationState);

    const [screenTitle, setScreenTitle] = useState("");
    const [startDate, setStartDate] = useState(testState.testDate || new Date());
    const [showEliteAthleteProfileInfoPopup, setShowEliteAthleteProfileInfoPopup] = useState(false);
    const [showResultsOverviewInfoPopup, setShowResultsOverviewInfoPopup] = useState(false);
    const [showTeachersResourcesInfoPopup, setShowTeachersResourcesInfoPopup] = useState(false);

    // Loading Indicator
    const [leaveAccessUserLoadingIndicator, setLeaveAccessUserLoadingIndicator] = useState(false);

    // React Toast Notifications Object
    const { addToast } = useToasts();

    useEffect(() => {
        setScreenTitle(getTitle(screen));
    }, [screen]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        if (user.userId !== null && user.userId !== '') {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = (elem) => {
        setAnchorEl(null);
    }

    const getTitle = (screen) => {
        if (user.userRole === "admin" && organisationStateLocal.accessUser == "") {
            switch (screen) {
                case 1:
                    return "User list - organisations";
                case 2:
                    return "Edit norms";
                case 3:
                    return "TalentID";
                case 4:
                    return "Custom Weighting";

            }
        } else if (user.userRole === "organisation" || user.userRole === 'teacher' && organisationStateLocal.accessUser == "") {
            switch (screen) {
                case 1:
                    return "Admin Dashboard";
                case 2:
                    return "Results overview";
                case 3:
                    return "Teachers' resources";
                case 4:
                    return "Test takers";
                case 5:
                    return "Talent ID";
                case 6:
                    return "Options";
                case 8:
                    return "Scout page";
                default:
                    return "Access a user"
            }
        } else {
            switch (screen) {
                case 1:
                    return "My details";
                case 2:
                    return "SportMatch test";
                case 3:
                    return "Find my sport";
                case 4:
                    return "Compare my results";
                case 5:
                    return "Elite athlete profiles";
                case 6:
                    return "My fitness age";
                case 7:
                    return "Reports";
                default:
                    return "My details";
            }
        }
    }

    const isUserLoggedIn = () => {
        return user.userId !== null && user.userId !== '';
    }

    const renderScreen = (setTestStartDate) => {
        return (
            <Screen setTestStartDate={setTestStartDate} />
        )
    };

    /**
     * Renders the content for the page
     * - checks if the user is logged in, if not - reject them from being able to use any of the features
     */
    const renderContent = () => {

        // Handler for setting test date
        const setTestStartDate = (date) => {
            setStartDate(date);
            dispatch(setTestDate(date));
        }

        const renderDatePicker = () => {
            if (screen == 2 && user.userRole == "student") {
                return (
                    <div className="test-date-picker-container" style={{ display: 'flex' }}>
                        <img className="test-date-calendar-icon" src={CalendarWhite} style={{ height: '50px' }} />
                        <DatePicker
                            selected={moment(startDate).toDate()}
                            onChange={date => setTestStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showTimeSelect={false}
                            locale={enAULocale}
                            formatWeekDay={d => {
                                return d.substring(0, 3);
                            }}
                            dayClassName={(d) => {
                                var isWeekend = moment(d).day() % 6 == 0;

                                if (isWeekend) {
                                    return "--weekend";
                                } else {
                                    return "";
                                }
                            }}
                        />
                    </div>
                )
            } else {
                return null;
            }
        }

        const retrieveJSONOfUser = () => {
            var jsonRequest = "";

            var birthMonth = user.userBirthMonth || "0";
            var birthYears = user.userBirthYear || "";
            var userGender = user.userGender || "unspecified";
            var userFirstName = user.userId || "";
            var userClassName = user.userClassName || "";
            var motherHeight = testState.mothersHeight || "";
            var fatherHeight = testState.fathersHeight || "";

            jsonRequest = {
                "user_id": typeof user.userBackendId !== 'undefined' && user.userBackendId !== "" ? user.userBackendId : '2' + "", //field used to update the specific row in the db
                "username": userFirstName + "",
                "user_birth_month": birthMonth + "",
                "user_birth_year": birthYears + "",
                'gender': userGender + "",
                "user_class_name": userClassName + "",
                "mother_height": motherHeight,
                "father_height": fatherHeight
            }

            return jsonRequest;
        }

        const leaveAccessUser = () => {
            if (organisationStateLocal.accessUser != "") {
                return (
                    <>
                    <button onClick={() => {
                        setLeaveAccessUserLoadingIndicator(true);
                        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/AccessUser`, {
                            credentials: 'include',
                            method: 'POST',
                            body: JSON.stringify({ leave: true,  })
                        }).then(res => {
                            res.json().then(result => {
                                if(result.backToAdmin) dispatch(setAccessUser(""));

                                if(!result.showAccessUserControl) dispatch(setAccessUser(""));

                                setLeaveAccessUserLoadingIndicator(false);

                                if (result.is_admin) {
                                    dispatch(changeScreen(1));
                                    // Unit of mesurement
                                    dispatch(setUserUOM(""));
                                    dispatch(setTokenAllocationType(""));
                                } else {
                                    dispatch(changeScreen(7));
                                }

                                dispatch(setTestTokenId(''))
                                dispatch(setUserPassword(''))
                                dispatch(setUserBirthDay(-1))
                                dispatch(setUserBirthMonth(-1))
                                dispatch(setUserBirthYear(-1))
                                dispatch(setUserGender(''))
                                dispatch(setUserClassName(''))
                                dispatch(setUserAge(''))
                                dispatch(setUserViewingTest(false))
                                dispatch(setFirstTimeViewingTest(false))
                                dispatch(setUserFitnessAge(0.0))
                                dispatch(setTestDateDay(''))
                                dispatch(setTestDateMonth(''))
                                dispatch(setTestDateYear(''))
                                dispatch(setSportMatchBodyShapeAndSizeSectionVisited(false))
                                dispatch(setSportMatchFlexibilitySectionVisited(false))
                                dispatch(setSportMatchMuscularStrengthAndPowerSectionVisited(false))
                                dispatch(setSportMatchMuscularEnduranceSectionVisited(false))
                                dispatch(setSportMatchSpeedAndAgilitySectionVisited(false))
                                dispatch(setSportMatchAerobicFitnessSectionVisited(false))
                                dispatch(setAdultSitAndReach(0))
                                dispatch(setAdultStandingBroadJump(0))
                                dispatch(setAdultGripStrength(0))
                                dispatch(setAdultLeftGripStrength(0))
                                dispatch(setAdultRightGripStrength(0))
                                dispatch(setAdultVerticalJump(0))
                                dispatch(setAdultSitUps(0))
                                dispatch(setAdultInclinedPullUps(0))
                                dispatch(setAdultShuttleRun(0))
                                dispatch(setAdult50mSprint(0))
                                dispatch(setAdultWalkRun(0))
                                dispatch(setAdultShuttleBeepTest(0))
                                dispatch(setAdultVo2Max(0))
                                dispatch(setAdultHeight(0))
                                dispatch(setAdultWeight(0))
                                dispatch(setAdultSittingHeight(0))
                                dispatch(setAdultForearmLength(0))
                                dispatch(setAdultArmSpan(0))
                                dispatch(setPredictedAdultFitnessId(0))
                                dispatch(setTestId(0))
                                dispatch(setUserPreviousTests([]))
                                dispatch(setUserBackendId(''))
                                dispatch(setStudentId(''));
                                dispatch(setMothersHeight(''));
                                dispatch(setUserEditingTest(''));
                                dispatch(setFathersHeight(''));
                                dispatch(setSitAndReach(''));
                                dispatch(setStandingBroadJump(''));
                                dispatch(setGripStrength(''));
                                dispatch(setLeftGripStrength(''));
                                dispatch(setRightGripStrength(''));
                                dispatch(setVerticalJump(''));
                                dispatch(setSitUps(''));
                                dispatch(setInclinedPullUps(''));
                                dispatch(setShuttleRun(''));
                                dispatch(setSprint(''));
                                dispatch(setSprintDistance(''));
                                dispatch(setSprintTime(''));
                                dispatch(setRunWalkMinutes(''));
                                dispatch(setRunWalkSeconds(''));
                                dispatch(setVo2Max(''));
                                dispatch(setBeepTest(''));
                                dispatch(setHeight(''));
                                dispatch(setWeight(''));
                                dispatch(setArmSpan(''));
                                dispatch(setAllFieldsProvided(false));
                                dispatch(setForearmLength(''));
                                dispatch(setUpperArmLength(''));
                                dispatch(setSittingHeight(''));
                                dispatch(setTestDate(new Date()));

                                dispatch(setYoyoStage(''));
                                dispatch(setYoyoTest(''));

                                // Setting user test values back to defaults

                                // Body shape and size fields
                                dispatch(setUserInputHeight(''));
                                dispatch(setUserInputWeight(''));
                                dispatch(setUserInputArmSpan(''));
                                dispatch(setUserInputSittingHeight(''));
                                dispatch(setUserInputForearmLength(''));
                                dispatch(setUserInputUpperArmLength(''));

                                // Flexibility
                                dispatch(setUserInputSitAndReach(''));

                                // Muscular strength and power
                                dispatch(setUserInputStandingBroadJump(''));
                                dispatch(setUserInputGripStrengthLeft(''));
                                dispatch(setUserInputGripStrengthRight(''));
                                dispatch(setUserInputVerticalJump(''));

                                // Muscular endurance
                                dispatch(setUserInputBentKneeSitUps(''));
                                dispatch(setUserInputInclinedPullUps(''));

                                // Speed and agility
                                dispatch(setUserInputShuttleRun(''));
                                dispatch(setUserInputSprintDistance(''));
                                dispatch(setUserInputSprintTime(''));

                                // Aerobic fitness
                                dispatch(setUserInputWalkRunMinutes(''));
                                dispatch(setUserInputWalkRunSeconds(''));
                                dispatch(setUserInputVO2MAX(''));
                                dispatch(setUserInputShuttleBeepTest(''));
                                dispatch(setUserInputYoyoTest(''));
                                dispatch(setUserInputYoyoStage(''));
                                dispatch(setUserInputYoyoVO2MAX(''));
                                dispatch(setUserInputBeepTestVO2MAX(''));

                                dispatch(setPendingRefreshCompletion(false));

                                // Organisation Branding
                                if (result.is_admin) {
                                    dispatch(setPrimaryColor(""));
                                    dispatch(setSecondaryColor(""));
                                    dispatch(setAccentColor(""));
                                }

                                if(result.returnType) {
                                    // This needs to account for if the organisation is viewing a teacher viewing a student
                                    if(result.returnType == "teacher") {
                                        dispatch(setUserRole("teacher"));
                                    }

                                    if(result.returnType == "organisation") {
                                        dispatch(setUserRole("organisation"));
                                    }
                                } else {
                                    if (result.is_admin) {
                                        dispatch(setUserId(result.username));
                                        dispatch(setUserRole("admin"));
                                    }
                                }

                                if(result.returnId) {
                                    dispatch(setUserBackendId(result.returnId));
                                }

                            }).catch(err => {
                                setLeaveAccessUserLoadingIndicator(false);
                                console.log("An error occured");
                            })
                        })
                    }} className="leave-access-user-btn" style={{ position: "fixed", left: "210px", top: "-15px", zIndex: "999" }}>
                        Leave access user {leaveAccessUserLoadingIndicator && 
                        <div className="loading-indicator">
                            <CircularProgress disableShrink size={20} />
                        </div>}
                    </button> 
                    </>
                    
                    
                )
            }
        }

        const renderSaveIcon = () => {
            if (screen === 2 && (user.userRole !== "organisation" && user.userRole !== 'teacher' && user.userRole !== 'admin')) {
                return (
                    <div style={{ position: 'relative', display: 'flex', marginRight: '15px' }}>
                        <img onClick={() => setShowResultsOverviewInfoPopup(!showResultsOverviewInfoPopup)} className="information-icon" src={InformationIconWhite} style={{ height: '50px' }} />

                        <div className="results-overview-information-popup" style={{ opacity: showResultsOverviewInfoPopup ? '1' : '0' }}>
                            <span className="descriptive-text">
                                The SportMatch test protocols are divided into five fitness categories, and one for
                                anthropometry measurements (body size and shape). Height and weight are the only two
                                variables that must be entered in order to run SportMatch. You may complete as many or as
                                few of the remaining protocols as you wish. If you want, you can enter several fitness
                                parameters (plus height and weight) and then run SportMatch. Additional measures can be
                                added to a test profile at some point in the future, for example, following another
                                class/session and SportMatch will then analyse the entire variable list. Note: the more
                                protocols that are completed, the more accurate the SportMatch analysis will be.
                            </span>
                        </div>

                    </div>
                )
            } else if (screen === 3 && (user.userRole !== "student" && user.userRole !== "admin")) {
                return (
                    <div style={{ position: 'relative', display: 'flex', marginRight: '15px' }}>
                        <img onClick={() => setShowTeachersResourcesInfoPopup(!showTeachersResourcesInfoPopup)} className="information-icon" src={InformationIconWhite} style={{ height: '50px' }} />

                        <div className="teachers-resources-information-popup" style={{ opacity: showTeachersResourcesInfoPopup ? '1' : '0', zIndex: showTeachersResourcesInfoPopup ? '3000' : '0', pointerEvents: showTeachersResourcesInfoPopup ? 'initial' : 'none' }}>
                            <span>
                                The SportMatch program consists of the core software tool plus a number of teaching resources that dovetail into the practical components measured in PE classes.
                                The teaching resources cover aspects of primary, middle and senior secondary school curricula.
                            </span>
                            <span>
                                SportMatch has been designed to help improve physical literacy.
                                Physical literacy is the motivation, confidence, physical competence, knowledge and understanding that establishes purposeful physical pursuits as an integral part of lifestyles.
                            </span>
                            <span>
                                SportMatch has a range of physical activities /fitness tests that students can undertake and then make meaning of these results.
                                Since it is an experiential subject, this practical involvement is part of the ‘participation in’ physical activity which is fundamental to physical literacy.
                                Physical performance tests reinforce movement elements such as running fast, agility and balance, jumping horizontally and vertically, and muscular endurance, strength and flexibility.
                                The key focus areas of ‘participation in’ physical activities that are reinforced in SportMatch are summarised in Table 1.
                            </span>
                            <span className="info-para-headings"><strong>Focus Area: ‘participation in’ movement</strong></span>
                            <span>SportMatch helps students participate in physical activity and apply knowledge of movement
                            concepts and strategies. Students can:</span>
                            <ul>
                                <li>understand different components of fitness and how to measure their capabilities</li>
                                <li>investigate how the body responds to physical activity and fitness challenges</li>
                                <li>apply biophysical knowledge to improve performance in physical activity.</li>
                            </ul>
                            <span><strong>Table 1.</strong> Participation in movement</span>
                            <table>
                                <tr>
                                    <th><strong>Key ideas</strong></th>
                                    <th><strong>Considerations</strong></th>
                                </tr>
                                <tr>
                                    <th>Analysis of movement concepts and strategies</th>
                                    <th><div className="square" style={{ display: "inline-block" }}></div>collecting valid and reliable data<br></br><div className="square" style={{ display: "inline-block" }}></div>applying understanding of movement concepts
                                        and strategies to consider ways to improve
                                        performance</th>
                                </tr>
                                <tr>
                                    <th>Application of energy sources affecting physical
                                        performance</th>
                                    <th><div className="square" style={{ display: "inline-block" }}></div>the contribution of energy systems in specific
                                        activities<br></br><div className="square" style={{ display: "inline-block" }}></div>energy contributions and fatigue</th>
                                </tr>
                                <tr>
                                    <th>Application of the effects of training on physical
                                        performance</th>
                                    <th><div className="square" style={{ display: "inline-block" }}></div>analysis of the demands of physical activity<br></br><div className="square" style={{ display: "inline-block" }}></div>measurement and monitoring of fitness and
                                        energy components relevant to participation
                                        and performance</th>
                                </tr>
                            </table>
                            <span>The SportMatch teacher resources include relevant background information, worksheets and project ideas.
                            These help to reinforce the ‘performance of’ physical activity.
                            That is, students can develop a better understanding of human capabilities, links between form and function, how to identify relative strengths and weakness of performance, and key elements of training programs that may help improve performance.
                            </span>
                            <span>The teaching resources and worksheets also help students understand the practical measurements in terms of the biophysical basis for their inclusion.
                            For example, the energy systems involved, limits of human capacities and differences among people, the links between size, shape and suitability of performance (anthropometry and biomechanical concepts), how performances develop with age and how genetics plays a role, specificity of fitness, and the importance of physical fitness relative to health, sport and competition.
                            </span>
                            <span className="info-para-headings"><strong>Focus Area: ‘performance of’ movement</strong></span>
                            <span>Students can use SportMatch and associated resources to develop theoretical knowledge ‘about’ physical activity. This involves:</span>
                            <ul>
                                <li>understanding the diversity of capabilities, and the link to genetics, training and sports
                                    performance</li>
                                <li>introducing biophysical contexts of physical activity and performance such as exercise physiology
                                    and biomechanical concepts.</li>
                                <li>applying their knowledge to real-life experiences to evaluate performance outcomes, suitability to
                                    different types of sports, and predict effects of training.</li>
                            </ul>
                            <span><strong>Table 2.</strong> Performance of movement</span>
                            <table>
                                <tr>
                                    <th><strong>Key ideas</strong></th>
                                    <th><strong>Considerations</strong></th>
                                </tr>
                                <tr>
                                    <th>The body’s response to physical activity</th>
                                    <th><div className="square" style={{ display: "inline-block" }}></div>musculoskeletal system<br></br><div className="square" style={{ display: "inline-block" }}></div>cardiorespiratory system<br></br><div className="square" style={{ display: "inline-block" }}></div>energy systems and fatigue<br></br><div className="square" style={{ display: "inline-block" }}></div>biomechanical principles — force, motion, levers</th>
                                </tr>
                                <tr>
                                    <th>The effect of training on the body</th>
                                    <th><div className="square" style={{ display: "inline-block" }}></div>fitness components and fitness testing</th>
                                </tr>
                            </table>

                        </div>
                    </div>
                )
            } else if (screen === 5 && (user.userRole !== "organisation" && user.userRole !== 'teacher' && user.userRole !== 'admin')) {
                return (
                    <div style={{ position: 'relative', display: 'flex', marginRight: '15px' }}>
                        <img onClick={() => setShowEliteAthleteProfileInfoPopup(!showEliteAthleteProfileInfoPopup)} className="information-icon" src={InformationIconWhite} style={{ height: '50px' }} />

                        <div className="elite-athlete-profile-information-popup" style={{ opacity: showEliteAthleteProfileInfoPopup ? '1' : '0' }}>
                            <span className="descriptive-text">
                                This screen shows the fitness performances and body size of elite-level athletes across a
                                range of sports. It also shows the user’s current performance levels so that comparisons can
                                be made. The user has 4 choices of which athletes to graph. The ‘All’ choice lists all 172
                                sports in alphabetical order in the table. Clicking on any of the sports will upload athletes
                                from that sport to the graph, show a picture of the sport and also provide a brief description
                                of the sport (hover the mouse over the sport photograph). The user can also choose to show
                                only ‘Male’ or ‘Female’ athletes in the table. Finally the user can click ‘My SportMatch list’ to
                                see the rank order of all sports that they are best, down to least, suited to.<br /><br />
                                How closely matched you are to each of the sports listed can be found by your teacher on the Administrators portal under the Talent ID section
                            </span>

                            <span className="graph-legend">Graph legend:</span>
                            <span>Elite athlete:</span>
                            <div className="elite-athlete-legend-bar" style={{ backgroundColor: organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#9a62b3' }}></div>
                            <span>Me:</span>
                            <div className="me-legend-bar" style={{ backgroundColor: organisationStateLocal.organisationAccentColor != '' ? organisationStateLocal.organisationAccentColor : '#50B383' }}></div>
                        </div>
                    </div>
                )
            } else {
                if (user.userRole !== 'admin' && organisationStateLocal.accessUser == "") {
                    return (
                        <div id="save-icon" onClick={() => {
                            var jsonRequest = retrieveJSONOfUser();

                            fetch(BACKEND_URL + ENVIRONMENT_PATH + '/UserDetails', {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json'
                                },
                                credentials: "include",
                                body: JSON.stringify(jsonRequest)
                            }).then(async function (res) {
                                res.json().then((data) => {

                                    if (data.error) {
                                        if (data.error === "username_taken") {
                                            addToast("Unable to save user - username taken", {
                                                appearance: 'error',
                                                autoDismiss: true
                                            });
                                        } else {
                                            addToast("Unable to save user - internal server error", {
                                                appearance: 'error',
                                                autoDismiss: true
                                            });
                                        }
                                    } else {
                                        addToast("Data have been saved successfully ", {
                                            appearance: 'success',
                                            autoDismiss: true
                                        });

                                        dispatch(setUserAge(data.age + ""));
                                    }
                                }).catch(error => {
                                    console.log("An error occured: ", error);
                                    addToast("Unable to save 'Test' - internal server error", {
                                        appearance: 'error',
                                        autoDismiss: true
                                    });
                                })
                            })
                        }}>
                            <Icon />
                        </div>
                    );
                }
            }
        }

        if (isUserLoggedIn()) {
            return (
                <React.Fragment>
                    <div className="screen-header" style={{ 'backgroundColor': organisationStateLocal.organisationSecondaryColor != "" ? organisationStateLocal.organisationSecondaryColor : "" }}>
                        <div className="screen-title">{screenTitle}</div>
                        <div className="screen-info">
                            {leaveAccessUser()}
                            {renderDatePicker()}
                            {renderSaveIcon()}
                        </div>
                    </div>
                    <div className="screen">
                        {renderScreen(setTestStartDate)}
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <div>Nope</div>
            )
        }
    }

    return (
        <div className="screen-container">
            {renderContent()}
        </div>
    );
}

export default Screens;
