import React, { useEffect, useState } from 'react';

import './style.css';
import { ENVIRONMENT_PATH, BACKEND_URL } from '../../../../store/constants';
import SportSection from '../talentID/Components/SportSection';
import Zoom from 'react-reveal/Zoom';
import { ScoutSlider } from './sliderComponent';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { SaveWeightings, GetScoutingData } from '../../../../api/endpoints';
import { useToasts } from 'react-toast-notifications';

import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';

import XLSX from 'xlsx';
import Filter from '../filter';
import OverlapReportComponent from '../../../../components/overlapReport';
import { ScoutSliderHeading } from './sliderHeading';
import { ConvertToFeetAndInches } from '../../../../helper/helper';

import IM from 'convert-units';

import styled from "styled-components";

let cancelToken = Axios.CancelToken.source();

const ScoutComponent = () => {
    // Redux
    const reduxState = useSelector(state => state);

    // React Toast Notifications Object
    const { addToast } = useToasts();

    // Unit of mesurement
    const usingImperial = reduxState.user.userUOM == "Imperial";

    // Weightings URL
    const WEIGHTING_URL = `${BACKEND_URL}${ENVIRONMENT_PATH}/weightings`;

    // Local state variables
    const [heightSelection, setHeightSelection] = useState(2);
    const [weightSelection, setWeightSelection] = useState(2);
    const [armSpanSelection, setArmSpanSelection] = useState(2);
    const [sittingHeightSelection, setSittingHeightSelection] = useState(2);
    const [brachialIndexSelection, setBrachialIndexSelection] = useState(2);
    const [sitAndReachSelection, setSitAndReachSelection] = useState(2);
    const [standingBroadJumpSelection, setStandingBroadJumpSelection] = useState(2);
    const [gripStrengthSelection, setGripStrengthSelection] = useState(2);
    const [verticalJumpSelection, setVerticalJumpSelection] = useState(2);
    const [sitUpsSelection, setSitUpsSelection] = useState(2);
    const [pullUpsSelection, setPullUpsSelection] = useState(2);
    const [shuttleRunSelection, setShuttleRunSelection] = useState(2);
    const [sprintSpeedSelection, setSprintSpeedSelection] = useState(2);
    const [aerobicFitnessSelection, setAerobicFitnessSelection] = useState(2);
    const [errorList, setErrorList] = useState([]);
    const [showError, setShowError] = useState(false);
    const [generatingReport, setGeneratingReport] = useState(false);
    const [studentRows, setStudentRows] = useState([]);
    const [showReportOverlay, setShowReportOverlay] = useState(false); // Responsible for showing report overlay

    // This determins if at least one mandatory field is selected
    const [mandatorySelection, setMandatorySelection] = useState([
        {
            field: 'sitAndReach',
            selected: false
        },
        {
            field: 'standingBroadJump',
            selected: false
        },
        {
            field: 'gripStrength',
            selected: false
        },
        {
            field: 'verticalJump',
            selected: false
        },
        {
            field: 'sitUps',
            selected: false
        },
        {
            field: 'pullUps',
            selected: false
        },
        {
            field: 'shuttleRun',
            selected: false
        },
        {
            field: 'sprintSpeed',
            selected: false
        },
        {
            field: 'aerobicFitness',
            selected: false
        }
    ]);

    const getMandatoryStatusSelection = (fieldName) => {
        var status = false;

        if(mandatorySelection.length > 0) {
            mandatorySelection.forEach(obj => {
                if(obj["field"] == fieldName) {
                    status = obj["selected"];
                }
            });
        }

        return status;
    };

    // Two rules to judge whether the field should be shown as transparent
    const getShowAsTransparent = (fieldName) => {
        var showAsTransparent = false;

        var hasAtLeastOneFieldAsSelected = false;

        // Step 1 - checking if atleast one field in the array has a selected property of true
        if(mandatorySelection.length > 0) {
            mandatorySelection.forEach(obj => {
                if(obj["selected"]) {
                    hasAtLeastOneFieldAsSelected = true;
                }
            })
        }

        // Step 2 - checking if the field in question has a selected property of true
        if(hasAtLeastOneFieldAsSelected) {
            showAsTransparent = !getMandatoryStatusSelection(fieldName);
        }

        return showAsTransparent;
    }

    const setMandatoryStatusSelection = (fieldName, value) => {
        var copyOfMandatorySelection = [...mandatorySelection];

        if(copyOfMandatorySelection.length > 0) {
            copyOfMandatorySelection.forEach(obj => {
                if(obj["field"] == fieldName) {
                    obj["selected"] = value;
                }
            });
        }

        setMandatorySelection(copyOfMandatorySelection);
    }

    const [sportName, setSportName] = useState("");
    const [sportGender, setSportGender] = useState("");

    // Loading indicators
    const [loadedData, setLoadedData] = useState(false);
    const [loadingSaveWeightings, setLoadingSaveWeightings] = useState(false);
    const [loadingResetWeightings, setLoadingResetWeightings] = useState(false);

    const CSVHEADING = [
        "User ID",
        "Gender (m/f)",
        "Group",
        "Test date (yyyy/mm/dd)",
        "Age (yr)",
        "Fitness age (yr)",
        "% Overlap No 1 sport",
        "Sport No.1",
        "Height (cm)",
        "Weight (kg)",
        "Arm span (cm)",
        "Forearm length (cm)",
        "Grip strength - left (kg)",
        "Grip strength - right (kg)",
        "Inclined pull-ups (in 30 s)",
        "Aerobic fitness (VO2max)",
        "Sit & reach (cm)",
        "Sitting height (cm)",
        "Bent-knee sit-ups (in 60 s)",
        "Sprint distance (m)",
        "Sprint time (s)",
        "Standing broad jump (cm)",
        "Upper arm length (cm)",
        "Vertical jump (cm)",
        "1.6 km run/walk (s)",
        "4 x 10 m shuttle run (s)",
        "Yo-Yo Test (level-shuttle-stage)",
        "Shuttle (beep) test"
    ];

    const CSVHEADING_IMPERIAL = [
        "User ID",
        "Gender (m/f)",
        "Group",
        "Test date (yyyy/mm/dd)",
        "Age (yr)",
        "Fitness age (yr)",
        "% Overlap No 1 sport",
        "Sport No.1",
        "Height (feet'inches\")",
        "Weight (lb)",
        "Arm span (feet'inches\")",
        "Forearm length (in)",
        "Grip strength - left (lb)",
        "Grip strength - right (lb)",
        "Inclined pull-ups (in 30 s)",
        "Aerobic fitness (VO2max)",
        "Sit & reach (in)",
        "Sitting height (lb)",
        "Bent-knee sit-ups (in 60 s)",
        "Sprint distance (yd)",
        "Sprint time (s)",
        "Standing broad jump (feet'inches\")",
        "Upper arm length (in)",
        "Vertical jump (in)",
        "1 mile run/walk (s)",
        "4 x 11 yd shuttle run (s)",
        "Yo-Yo Test (level-shuttle-stage)",
        "Shuttle (beep) test"
    ];

    // This is for when changing the slider value
    // Converts the value provided to a acceptable data format (i.e. if user selects 1, backend will interpret it as 2/3)
    const convertValueToBackendAcceptableData = (value, type) => {
        var valueReturned = 0;

        if (type == "TO") {
            switch (parseInt(value + "")) {
                case 0:
                    valueReturned = 0.33;
                    break;
                case 1:
                    return 0.66;
                case 2:
                    valueReturned = 1;
                    break;
                case 3:
                    valueReturned = 2;
                    break;
                case 4:
                    valueReturned = 3;
                    break;
            }
        } else if (type == "FROM") {
            switch (parseFloat(value + "")) {
                case 0.33:
                    valueReturned = 0;
                    break;
                case 0.66:
                    valueReturned = 1;
                    break;
                case 1:
                    valueReturned = 2;
                    break;
                case 2:
                    valueReturned = 3;
                    break;
                case 3:
                    valueReturned = 4;
                    break;
            }
        }

        return valueReturned;
    };

    const resetAllSelections = () => {
        return new Promise((resolve, reject) => {
            setHeightSelection(2);
            setWeightSelection(2);
            setArmSpanSelection(2);
            setSittingHeightSelection(2);
            setBrachialIndexSelection(2);
            setSitAndReachSelection(2);
            setStandingBroadJumpSelection(2);
            setGripStrengthSelection(2);
            setVerticalJumpSelection(2);
            setSitUpsSelection(2);
            setPullUpsSelection(2);
            setShuttleRunSelection(2);
            setSprintSpeedSelection(2);
            setAerobicFitnessSelection(2);
            saveWeightingsOfSameValue(2);

            resolve();
        })
    }

    useEffect(() => {
        Axios.get(WEIGHTING_URL, {
            withCredentials: true
        }).then(res => {
            const data = res.data && res.data.result ? res.data.result : null;

            if (data != null) {
                if (data.height) setHeightSelection(convertValueToBackendAcceptableData(data.height, "FROM"));
                if (data.weight) setWeightSelection(convertValueToBackendAcceptableData(data.weight, "FROM"));
                if (data.arm_span) setArmSpanSelection(convertValueToBackendAcceptableData(data.arm_span, "FROM"));
                if (data.sitting_height) setSittingHeightSelection(convertValueToBackendAcceptableData(data.sitting_height, "FROM"));
                if (data.forearm_length) setBrachialIndexSelection(convertValueToBackendAcceptableData(data.forearm_length, "FROM"));
                if (data.sit_reach) setSitAndReachSelection(convertValueToBackendAcceptableData(data.sit_reach, "FROM"));
                if (data.standing_broad_jump) setStandingBroadJumpSelection(convertValueToBackendAcceptableData(data.standing_broad_jump, "FROM"));
                if (data.grip_strength) setGripStrengthSelection(convertValueToBackendAcceptableData(data.grip_strength, "FROM"));
                if (data.vertical_jump) setVerticalJumpSelection(convertValueToBackendAcceptableData(data.vertical_jump, "FROM"));
                if (data.situps) setSitUpsSelection(convertValueToBackendAcceptableData(data.situps, "FROM"));
                if (data.pullups) setPullUpsSelection(convertValueToBackendAcceptableData(data.pullups, "FROM"));
                if (data.shuttle) setShuttleRunSelection(convertValueToBackendAcceptableData(data.shuttle, "FROM"));
                if (data.speed) setSprintSpeedSelection(convertValueToBackendAcceptableData(data.speed, "FROM"));
                if (data.endurance) setAerobicFitnessSelection(convertValueToBackendAcceptableData(data.endurance, "FROM"));
            }

            // Setting mandatory fields from backend
            var tempMandatoryFieldsSelected = [
                {
                    field: 'sitAndReach',
                    selected: data["sitAndReachMandatory"] ? data["sitAndReachMandatory"] : false
                },
                {
                    field: 'standingBroadJump',
                    selected: data["standingBroadJumpMandatory"] ? data["standingBroadJumpMandatory"] : false
                },
                {
                    field: 'gripStrength',
                    selected: data["gripStrengthMandatory"] ? data["gripStrengthMandatory"] : false
                },
                {
                    field: 'verticalJump',
                    selected: data["verticalJumpMandatory"] ? data["verticalJumpMandatory"] : false
                },
                {
                    field: 'sitUps',
                    selected: data["sitUpsMandatory"] ? data["sitUpsMandatory"] : false
                },
                {
                    field: 'pullUps',
                    selected: data["pullUpsMandatory"] ? data["pullUpsMandatory"] : false
                },
                {
                    field: 'shuttleRun',
                    selected: data["shuttleRunMandatory"] ? data["shuttleRunMandatory"] : false
                },
                {
                    field: 'sprintSpeed',
                    selected: data["sprintSpeedMandatory"] ? data["sprintSpeedMandatory"] : false
                },
                {
                    field: 'aerobicFitness',
                    selected: data["aerobicFitnessMandatory"] ? data["aerobicFitnessMandatory"] : false
                }
            ];

            setMandatorySelection(tempMandatoryFieldsSelected);

            setLoadedData(true);
        }).catch(err => {
            console.log("An error occured: ", err);
            setLoadedData(true);
        })
    }, []);

    const removeMandatorySelections = (jsonRequest) => {
        jsonRequest["sit_and_reach_mandatory"] = false;
        jsonRequest["standing_broad_jump_mandatory"] = false;
        jsonRequest["grip_strength_mandatory"] = false;
        jsonRequest["vertical_jump_mandatory"] = false;
        jsonRequest["sit_ups_mandatory"] = false;
        jsonRequest["pull_ups_mandatory"] = false;
        jsonRequest["shuttle_run_mandatory"] = false;
        jsonRequest["sprint_speed_mandatory"] = false;
        jsonRequest["aerobic_fitness_mandatory"] = false;

        // Removing frontend rendering of mandatory selections
        var tempMandatoryFieldsSelected = [
            {
                field: 'sitAndReach',
                selected: false
            },
            {
                field: 'standingBroadJump',
                selected: false
            },
            {
                field: 'gripStrength',
                selected: false
            },
            {
                field: 'verticalJump',
                selected: false
            },
            {
                field: 'sitUps',
                selected: false
            },
            {
                field: 'pullUps',
                selected: false
            },
            {
                field: 'shuttleRun',
                selected: false
            },
            {
                field: 'sprintSpeed',
                selected: false
            },
            {
                field: 'aerobicFitness',
                selected: false
            }
        ];

        setMandatorySelection(tempMandatoryFieldsSelected);

        return jsonRequest;
    }

    const saveWeightingsOfSameValue = (val) => {
        var requestPayload = {
            "height": convertValueToBackendAcceptableData(val, "TO"),
            "weight": convertValueToBackendAcceptableData(val, "TO"),
            "arm_span": convertValueToBackendAcceptableData(val, "TO"),
            "sitting_height": convertValueToBackendAcceptableData(val, "TO"),
            "forearm_length": convertValueToBackendAcceptableData(val, "TO"),
            "sit_reach": convertValueToBackendAcceptableData(val, "TO"),
            "standing_broad_jump": convertValueToBackendAcceptableData(val, "TO"),
            "grip_strength": convertValueToBackendAcceptableData(val, "TO"),
            "vertical_jump": convertValueToBackendAcceptableData(val, "TO"),
            "situps": convertValueToBackendAcceptableData(val, "TO"),
            "pullups": convertValueToBackendAcceptableData(val, "TO"),
            "shuttle": convertValueToBackendAcceptableData(val, "TO"),
            "speed": convertValueToBackendAcceptableData(val, "TO"),
            "endurance": convertValueToBackendAcceptableData(val, "TO")
        }

        requestPayload = removeMandatorySelections(requestPayload);

        SaveWeightings(requestPayload).then(() => {
            addToast(`Successfully saved`, {
                appearance: 'success',
                autoDismiss: true
            });
        });
    };

    /**
     * formatToFixedPoint
     * 
     * The point of this function is to determine if the value needs to be fixed to a 1 decimal place
     * 
     * @param {any} value The value to parse to a floating point,
     */
    const formatToFixedPoint = (value) => {
        var rightHandValue = -1;
        var fixedValue = -1;
        var fixedValueString = "";

        if(value && parseFloat(value)) {
            value = parseFloat(value).toFixed(1);
            fixedValue = value;

            fixedValueString = (fixedValue + "");

            if(fixedValueString.indexOf('.') != -1) {
                if(fixedValueString.split('.').length > 1) {
                    rightHandValue = (fixedValueString.split('.')[1] + "");

                    if(rightHandValue == "0") {
                        value = parseFloat(value).toFixed(0);
                    }
                }
            }
        }

        return parseFloat(value);
    }

    /**
     * Allows for generating a CSV spreadsheet of students within the supplied dataset
     * @param {object[]} data 
     * @param {string} name 
     */
    const generateCSVWorksheet = (data, name = "") => {
        var wb = XLSX.utils.book_new(); // Instantiate a new workbook
        wb.SheetNames.push("Scouting Page");

        let rows = [];
        let singleRow = [];

        var csvString = "";

        csvString = CSVHEADING;

        if (usingImperial) csvString = CSVHEADING_IMPERIAL;

        rows.push(csvString);

        if (Array.isArray(data)) {
            for (var i = 0; i < data.length; i++) {
                var studentData = data[i];

                if (name != "")
                    if (studentData.first_name.toLowerCase() != name.toLowerCase()) continue;

                singleRow = [];
                singleRow.push(studentData.first_name ? studentData.first_name : "");
                singleRow.push(studentData.gender ? studentData.gender : "");
                singleRow.push(studentData.group ? studentData.group : "");
                singleRow.push(studentData.test_date ? studentData.test_date : "");
                singleRow.push(studentData.age && parseFloat(studentData.age) ? formatToFixedPoint(studentData.age) : "");
                singleRow.push(studentData.fitness_age && parseFloat(studentData.fitness_age) ? formatToFixedPoint(studentData.fitness_age) : "");
                singleRow.push(studentData.overlap && parseFloat(studentData.overlap) ? formatToFixedPoint(studentData.overlap) : "");
                singleRow.push(studentData.sport ? studentData.sport : "");

                // Test data
                singleRow.push(studentData.height && studentData.height > 0 && parseFloat(studentData.height) ? (usingImperial ? ConvertToFeetAndInches(IM(studentData.height).from('cm').to('in')) : formatToFixedPoint(studentData.height)) : ""); // Check
                singleRow.push(studentData.weight && studentData.weight > 0 && parseFloat(studentData.weight) ? (usingImperial ? parseFloat(IM(studentData.weight).from('kg').to('lb').toFixed(1)) : formatToFixedPoint(studentData.weight)) : ""); // Check
                singleRow.push(studentData.arm_span && studentData.arm_span > 0 && parseFloat(studentData.arm_span) ? (usingImperial ? ConvertToFeetAndInches(IM(studentData.arm_span).from('cm').to('in')) : formatToFixedPoint(studentData.arm_span)) : ""); // Check
                singleRow.push(studentData.brachial_index && studentData.brachial_index > 0 && parseFloat(studentData.brachial_index) ? (usingImperial ? parseFloat(IM(studentData.brachial_index).from('cm').to('in').toFixed(1)) : formatToFixedPoint(studentData.brachial_index)) : "");  // Check
                singleRow.push(studentData.grip_strength_left && studentData.grip_strength_left > 0 && parseFloat(studentData.grip_strength_left) ? (usingImperial ? parseFloat(IM(studentData.grip_strength_left).from('kg').to('lb').toFixed(1)) : formatToFixedPoint(studentData.grip_strength_left)) : "");  // Check
                singleRow.push(studentData.grip_strength_right && studentData.grip_strength_right > 0 && parseFloat(studentData.grip_strength_right) ? (usingImperial ? parseFloat(IM(studentData.grip_strength_right).from('kg').to('lb').toFixed(1)) : formatToFixedPoint(studentData.grip_strength_right)) : ""); // Check
                singleRow.push(studentData.pullups && studentData.pullups > 0 ? studentData.pullups : ""); // Check
                singleRow.push(studentData.vo2max && studentData.vo2max > 0 && parseFloat(studentData.vo2max) ? formatToFixedPoint(studentData.vo2max) : ""); 
                singleRow.push(studentData.sit_reach && studentData.sit_reach > 0 && parseFloat(studentData.sit_reach) ? (usingImperial ? parseFloat(IM(studentData.sit_reach).from('cm').to('in').toFixed(1)) : formatToFixedPoint(studentData.sit_reach)) : "");
                singleRow.push(studentData.sitting_height && studentData.sitting_height > 0 && parseFloat(studentData.sitting_height) ? (usingImperial ? parseFloat(IM(studentData.sitting_height).from('cm').to('in').toFixed(1)) : formatToFixedPoint(studentData.sitting_height)) : "");
                singleRow.push(studentData.situps && studentData.situps > 0 ? studentData.situps : "");
                singleRow.push(studentData.sprint_distance && studentData.sprint_distance > 0 && parseFloat(studentData.sprint_distance) ? (usingImperial ? parseFloat(parseFloat(IM(studentData.sprint_distance).from('m').to('ft') / 3).toFixed(1)) : formatToFixedPoint(studentData.sprint_distance)) : "");
                singleRow.push(studentData.sprint_time && studentData.sprint_time > 0 && parseFloat(studentData.sprint_time) ? formatToFixedPoint(studentData.sprint_time) : "");
                singleRow.push(studentData.broad_jump && studentData.broad_jump > 0 ? (usingImperial ? ConvertToFeetAndInches(IM(studentData.broad_jump).from('cm').to('in')) : formatToFixedPoint(studentData.broad_jump)) : "");
                singleRow.push(studentData.upper_arm_length && studentData.upper_arm_length > 0 ? (usingImperial ? parseFloat(IM(studentData.upper_arm_length).from('cm').to('in').toFixed(1)) : formatToFixedPoint(studentData.upper_arm_length)) : "");
                singleRow.push(studentData.vertical_jump && studentData.vertical_jump > 0 ? (usingImperial ? parseFloat(IM(studentData.vertical_jump).from('cm').to('in').toFixed(1)) : formatToFixedPoint(studentData.vertical_jump)) : "");
                singleRow.push(studentData.run_walk && studentData.run_walk > 0 ? studentData.run_walk : "");
                singleRow.push(studentData.shuttle_run && studentData.shuttle_run > 0 && parseFloat(studentData.shuttle_run) ? formatToFixedPoint(studentData.shuttle_run) : "");
                singleRow.push(studentData.yoyo_test && studentData.yoyo_test != "-1" ? studentData.yoyo_test : "");
                singleRow.push(studentData.shuttle_level && studentData.shuttle_level != "-1" ? studentData.shuttle_level : "");

                rows.push(singleRow);
            }
        }

        var ws = XLSX.utils.aoa_to_sheet(rows);
        wb.Sheets["Scouting Page"] = ws;

        XLSX.writeFile(wb, "Scouting Page Results.xlsm");
    }

    const saveWeightings = async (showNotification) => {
        await new Promise((resolve, reject) => {
            var requestPayload = {
                "height": convertValueToBackendAcceptableData(heightSelection, "TO"),
                "weight": convertValueToBackendAcceptableData(weightSelection, "TO"),
                "arm_span": convertValueToBackendAcceptableData(armSpanSelection, "TO"),
                "sitting_height": convertValueToBackendAcceptableData(sittingHeightSelection, "TO"),
                "forearm_length": convertValueToBackendAcceptableData(brachialIndexSelection, "TO"),
                "sit_reach": convertValueToBackendAcceptableData(sitAndReachSelection, "TO"),
                "standing_broad_jump": convertValueToBackendAcceptableData(standingBroadJumpSelection, "TO"),
                "grip_strength": convertValueToBackendAcceptableData(gripStrengthSelection, "TO"),
                "vertical_jump": convertValueToBackendAcceptableData(verticalJumpSelection, "TO"),
                "situps": convertValueToBackendAcceptableData(sitUpsSelection, "TO"),
                "pullups": convertValueToBackendAcceptableData(pullUpsSelection, "TO"),
                "shuttle": convertValueToBackendAcceptableData(shuttleRunSelection, "TO"),
                "speed": convertValueToBackendAcceptableData(sprintSpeedSelection, "TO"),
                "endurance": convertValueToBackendAcceptableData(aerobicFitnessSelection, "TO"),
                // Mandatory fields
                "sit_and_reach_mandatory": getMandatoryStatusSelection("sitAndReach"),
                "standing_broad_jump_mandatory": getMandatoryStatusSelection("standingBroadJump"),
                "grip_strength_mandatory": getMandatoryStatusSelection("gripStrength"),
                "vertical_jump_mandatory": getMandatoryStatusSelection("verticalJump"),
                "sit_ups_mandatory": getMandatoryStatusSelection("sitUps"),
                "pull_ups_mandatory": getMandatoryStatusSelection("pullUps"),
                "shuttle_run_mandatory": getMandatoryStatusSelection("shuttleRun"),
                "sprint_speed_mandatory": getMandatoryStatusSelection("sprintSpeed"),
                "aerobic_fitness_mandatory": getMandatoryStatusSelection("aerobicFitness"),
            }

            SaveWeightings(requestPayload).then(() => {
                if (showNotification) {
                    addToast(`Successfully saved`, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                }

                resolve();
            });
        })
    };

    const exportReport = (name = "") => {
        return new Promise((resolve, reject) => {
            saveWeightings(false).then(() => {
                var requestPayload = {}

                if (sportName != "") requestPayload["sport"] = sportName;
                if (reduxState.organisationState.ageFilters.length != 0) requestPayload["ages"] = reduxState.organisationState.ageFilters;
                if (reduxState.organisationState.genderFilters.length != 0) requestPayload["genders"] = [reduxState.organisationState.genderFilters];
                if (reduxState.organisationState.groupNameFilters.length != 0) requestPayload["groups"] = reduxState.organisationState.groupNameFilters;
                if (reduxState.organisationState.dateRangeFromFilter != "") requestPayload["test_date_start"] = moment(reduxState.organisationState.dateRangeFromFilter).format("DD/MM/YYYY");
                if (reduxState.organisationState.dateRangeToFilter != "") requestPayload["test_date_end"] = moment(reduxState.organisationState.dateRangeToFilter).format("DD/MM/YYYY");

                generateCSVWorksheet(studentRows, name);
                resolve();
            })
        })
    };

    const requestScoutingInformation = (pageSize = -1) => {
        return new Promise((resolve, reject) => {
            cancelToken.cancel();
            cancelToken = Axios.CancelToken.source();

            saveWeightings(false).then(() => {
                setGeneratingReport(true);

                var requestPayload = {}
                requestPayload.pageSize = pageSize;

                if (sportName != "") requestPayload["sport"] = sportName;
                if (sportGender != "") requestPayload["genders"] = [sportGender];

                if (reduxState.organisationState.ageFilters.length != 0) requestPayload["ages"] = reduxState.organisationState.ageFilters;
                if (reduxState.organisationState.genderFilters.length != 0) requestPayload["genders"] = [reduxState.organisationState.genderFilters];
                if (reduxState.organisationState.groupNameFilters.length != 0) requestPayload["groups"] = reduxState.organisationState.groupNameFilters;
                if (reduxState.organisationState.dateRangeFromFilter != "") requestPayload["test_date_start"] = moment(reduxState.organisationState.dateRangeFromFilter).format("DD/MM/YYYY");
                if (reduxState.organisationState.dateRangeToFilter != "") requestPayload["test_date_end"] = moment(reduxState.organisationState.dateRangeToFilter).format("DD/MM/YYYY");

                GetScoutingData(requestPayload, cancelToken).then((data) => {
                    if (data.success) {
                        if (data.rows) setStudentRows(data.rows);
                        setShowReportOverlay(true);
                        setErrorList([]);
                    } else {
                        if (data.errors) {
                            setShowError(true);
                            setErrorList(data.errors);
                        }
                    }

                    resolve();

                    setGeneratingReport(false);
                })
            })
        })
    }

    const Button = styled.button`
        background-color: ${reduxState.organisationState.organisationPrimaryColor != "" ? (reduxState.organisationState.organisationPrimaryColor  + " !important") : "#9a62b3"}
    `;

    return (
        <div className="container scouting-container" style={{ background: "#F6F9FC" }}>
            <Filter />
            <div className="sport-selector">
                <SportSection
                    allowMultipleSports={false}
                    hideFilterBy={true}
                    hideSortBy={true}
                    sportSelectionHandler={(e) => {
                        if (Array.isArray(e)) {
                            if (e.length > 0) {
                                if (e[0].name) setSportName(e[0].name);
                                if (e[0].gender) setSportGender(e[0].gender)
                            }
                        }
                    }}
                    setSportSelectionRequestStringHandler={() => {
                        setSportName("");
                    }}
                />

                <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {loadingResetWeightings && <CircularProgress disableShrink size={24} />}
                            <Button
                                onClick={() => {
                                    setLoadingResetWeightings(true);
                                    resetAllSelections().then(res => {
                                        setLoadingResetWeightings(false);
                                    });
                                }}
                                className="sportMatchButton"
                            >Reset Weightings</Button>
                        </div>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {loadingSaveWeightings && <CircularProgress disableShrink size={24} />}
                            <Button
                                onClick={() => {
                                    setLoadingSaveWeightings(true);
                                    saveWeightings(true).then(res => {
                                        setLoadingSaveWeightings(false);
                                    });
                                }}
                                className="sportMatchButton"
                            >Save Weightings</Button>
                        </div>
                    </div>

                    <div className="export-scouting-report-container">
                        <Button onClick={() => {
                            requestScoutingInformation(100);
                        }} className="sportMatchButton">Run report</Button>
                        {generatingReport && <CircularProgress disableShrink={true} size={20} />}
                    </div>
                </div>

                {showError &&
                    <>
                        <div className={`overlay`}></div>
                        <Zoom disabled={!showError} duration={400}>
                            <div className={`error-popup-container`}>
                                <div className={`error-popup`}>
                                    {errorList.length != 0 &&
                                        <div className="error-list">
                                            <h2 style={{ textAlign: 'center' }}>Error</h2>
                                            <ul>
                                                {errorList.map(e => {
                                                    return <li>{e}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    }

                                    <div className="sportmatch-button" onClick={() => {
                                        setShowError(false);
                                        setErrorList([]);
                                    }}>Close</div>
                                </div>
                            </div>
                        </Zoom>
                    </>
                }
            </div>

            <OverlapReportComponent
                setShowReportOverlay={(val) => setShowReportOverlay(val)}
                showReportOverlay={showReportOverlay}
                studentData={studentRows}
                downloadAllHandler={() => exportReport()}
                downloadIndividualHandler={val => exportReport(val)}
                getScoutingData={(val) => requestScoutingInformation(val)}
            />

            <div className="stats-selector">
                <ScoutSliderHeading showIndicators={true} label={"Height"} uom={usingImperial ? "feet'inches\"" : "cm"} value={heightSelection} setValue={e => setHeightSelection(e)} loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("sitAndReach")}
                    showAsTransparent={getShowAsTransparent("sitAndReach")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("sitAndReach", val)}
                    label={"Sit & reach"} 
                    uom={usingImperial ? "in" : "cm"} 
                    value={sitAndReachSelection} 
                    setValue={e => setSitAndReachSelection(e)} 
                    loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("standingBroadJump")}
                    showAsTransparent={getShowAsTransparent("standingBroadJump")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("standingBroadJump", val)}
                    label={"Standing broad jump"} 
                    uom={usingImperial ? "feet'inches\"" : "cm"} 
                    value={standingBroadJumpSelection} 
                    setValue={e => setStandingBroadJumpSelection(e)} 
                    loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("gripStrength")}
                    showAsTransparent={getShowAsTransparent("gripStrength")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("gripStrength", val)}
                    label={"Grip strength"} 
                    uom={usingImperial ? "lb" : "kg"} 
                    value={gripStrengthSelection} 
                    setValue={e => setGripStrengthSelection(e)} 
                    loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("verticalJump")}
                    showAsTransparent={getShowAsTransparent("verticalJump")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("verticalJump", val)}
                    label={"Vertical jump"} 
                    uom={usingImperial ? "in" : "cm"} 
                    value={verticalJumpSelection} 
                    setValue={e => setVerticalJumpSelection(e)} 
                    loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("sitUps")}
                    showAsTransparent={getShowAsTransparent("sitUps")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("sitUps", val)}
                    label={"Sit ups"} 
                    uom={""} value={sitUpsSelection} 
                    setValue={e => setSitUpsSelection(e)} 
                    loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("pullUps")}
                    showAsTransparent={getShowAsTransparent("pullUps")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("pullUps", val)}
                    label={"Pull ups"} 
                    uom={""} 
                    value={pullUpsSelection} 
                    setValue={e => setPullUpsSelection(e)} 
                    loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("shuttleRun")}
                    showAsTransparent={getShowAsTransparent("shuttleRun")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("shuttleRun", val)}
                    label={usingImperial ? "4 x 11 yd shuttle" : "4 x 10 m shuttle"} 
                    uom={"s"} 
                    value={shuttleRunSelection} 
                    setValue={e => setShuttleRunSelection(e)} 
                    loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("sprintSpeed")}
                    showAsTransparent={getShowAsTransparent("sprintSpeed")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("sprintSpeed", val)}
                    label={"Sprint speed"} 
                    uom={usingImperial ? "mph" : "km/h"} 
                    value={sprintSpeedSelection} 
                    setValue={e => setSprintSpeedSelection(e)} 
                    loadedData={loadedData} />
                <ScoutSlider 
                    isChecked={getMandatoryStatusSelection("aerobicFitness")}
                    showAsTransparent={getShowAsTransparent("aerobicFitness")}
                    setMandatorySelected={(val) => setMandatoryStatusSelection("aerobicFitness", val)}
                    label={"Aerobic fitness"} 
                    uom={"mL/kg/min"} 
                    value={aerobicFitnessSelection} 
                    setValue={e => setAerobicFitnessSelection(e)} 
                    loadedData={loadedData} />
            </div>
        </div>
    )
}

export default ScoutComponent;