// REACT
import React, { useState, useEffect } from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications';

// MOMENTJS
import moment from 'moment';

// JQUERY
import 'jquery-ui/ui/effects/effect-shake';
import $ from 'jquery';

// CONVERT UNITS
import IM from 'convert-units';

// COMPONENTS
import Flexibility from '../myDetails/Flexibility.js';
import StrengthPower from '../myDetails/StrengthPower.js';
import MuscularEndurance from '../myDetails/MuscularEndurance.js';
import SpeedAgility from '../myDetails/SpeedAgility.js';
import CardioRespiratory from '../myDetails/CardioRespiratory.js';
import BodySizeShape from '../myDetails/BodySizeShape.js';
import TestDateSection from './TestDateSection.js';
import SaveTest from '../myDetails/SaveTest.js';

import StartTestMainScreen from './startTestMainScreen.js';
import SubmitTestSection from './submitTestSection.js';

import TestNavigationSection from '../myDetails/TestNavigationSection.js';

import {
    AUS_NZ_NORM_NAME,
    BACKEND_URL,
    ENVIRONMENT_PATH,
    QAS_ORG_NAME
} from '../../../store/constants';

// STYLES
import './style.css';

// ACTIONS
import {
    setUserFitnessAge,
    setAdult50mSprint,
    setAdultGripStrength,
    setAdultInclinedPullUps,
    setAdultHeight,
    setAdultShuttleRun,
    setAdultShuttleBeepTest,
    setUserViewingTest,
    setAdultSitAndReach,
    setAdultSitUps,
    setAdultStandingBroadJump,
    setAdultVerticalJump,
    setAdultWalkRun,
    setAdultWeight,
    setAdultVo2Max,
    setAdultArmSpan,
    setAdultForearmLength,
    setAdultSittingHeight,
    setSportMatchBodyShapeAndSizeSectionVisited,
    setSportMatchFlexibilitySectionVisited,
    setSportMatchMuscularStrengthAndPowerSectionVisited,
    setSportMatchMuscularEnduranceSectionVisited,
    setSportMatchSpeedAndAgilitySectionVisited,
    setSportMatchAerobicFitnessSectionVisited,
    setPredictedAdultFitnessId,
    setTestId,
    setTestTokenId,
    setUserEditingTest
} from '../../../store/actions/actions.js';

import {
    setSitAndReach,
    setStandingBroadJump,
    setGripStrength,
    setLeftGripStrength,
    setRightGripStrength,
    setVerticalJump,
    setSitUps,
    setInclinedPullUps,
    setShuttleRun,
    setSprintDistance,
    setSprintTime,
    setRunWalkMinutes,
    setRunWalkSeconds,
    setVo2Max,
    setBeepTest,
    setHeight,
    setWeight,
    setArmSpan,
    setForearmLength,
    setUpperArmLength,
    setSittingHeight,
    setAllFieldsProvided,
    setYoyoTest,
    setYoyoStage
} from '../../../store/actions/myDetails.js';
import Axios from 'axios';
import { useGenerateQASReport } from '../reports/QasReport/QASReportHelper.ts';

const cancelDownloadToken = Axios.CancelToken.source();

const SportMatchTest = ({ setTestStartDate: setTestStartDate }) => {
    const { loadingReport, doGenerateQASReport } = useGenerateQASReport();

    const [loading, setLoading] = useState(false);

    var stateMyDetails = useSelector(state => state.myDetails);
    const userState = useSelector(state => state.user);

    const sportMatchState = useSelector(state => state.sportMatchTest);

    const adultState = useSelector(state => state.adultValues);

    const [showCompulsoryFieldValidation, setShowCompulsoryFieldValidation] = useState(false);
    // React Toast Notifications Object
    const { addToast } = useToasts();

    const dispatch = useDispatch();

    // Sets the current screen the user is viewing
    const [testScreenNo, setTestScreenNo] = useState(0);

    // Sets what tests the user has visited
    const [flexibilityVisited, setFlexibilityVisited] = useState(false);
    const [muscularStrengthAndPowerVisited, setmuscularStrengthAndPowerVisited] = useState(false);
    const [muscularEnduranceVisited, setMuscularEnduranceVisited] = useState(false);
    const [speedAgilityVisited, setSpeedAgilityVisited] = useState(false);
    const [aerobicFitnessVisited, setAerobicFitnessVisited] = useState(false);
    const [bodySizeShapeVisited, setBodySizeShapeVisited] = useState(false);

    // Checks whether the "Submit" button has been clicked locally
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

    // Sets the visited sport
    const setVisited = (testNo) => {
        if (testNo === 1) {
            setBodySizeShapeVisited(true);
            dispatch(setSportMatchBodyShapeAndSizeSectionVisited(true));
        }
        if (testNo === 2) {
            setFlexibilityVisited(true);
            dispatch(setSportMatchFlexibilitySectionVisited(true));
        }
        if (testNo === 3) {
            setmuscularStrengthAndPowerVisited(true);
            dispatch(setSportMatchMuscularStrengthAndPowerSectionVisited(true));
        }
        if (testNo === 4) {
            setMuscularEnduranceVisited(true);
            dispatch(setSportMatchMuscularEnduranceSectionVisited(true));
        }
        if (testNo === 5) {
            setSpeedAgilityVisited(true);
            dispatch(setSportMatchSpeedAndAgilitySectionVisited(true));
        }
        if (testNo === 6) {
            setAerobicFitnessVisited(true);
            dispatch(setSportMatchAerobicFitnessSectionVisited(true));
        }
    }

    // On previous button click
    const previousButtonHandler = () => {
        if (testScreenNo > 1) {
            setTestScreenNo(testScreenNo - 1);
        } else {
            setTestScreenNo(0);
        }
    }

    // On next button click
    const nextButtonHandler = () => {
        if (testScreenNo < 6) {
            // If the user is on the "Body shape and size" screen and the user hasn't filled out either 'Height' or 'Weight', then stop them
            if (testScreenNo === 1) {
                if (sportMatchState.userInputHeight === "" || sportMatchState.userInputWeight === "") {
                    setShowCompulsoryFieldValidation(true);
                    if (sportMatchState.userInputHeight === "") {
                        $(".required-field-height").effect("shake");
                    }
                    if (sportMatchState.userInputWeight === "") {
                        $(".required-field-weight").effect("shake");
                    }

                } else {
                    setTestScreenNo(testScreenNo + 1);
                }
            } else {
                setTestScreenNo(testScreenNo + 1);
            }
        } else {
            setTestScreenNo(0);
        }
    }

    const gotoTestScreen = (no) => {
        setTestScreenNo(no);
    };

    const submitDataToBackend = (height, weight) => {
        setLoading(true);

        const isQAS = userState.userOrganisationName == QAS_ORG_NAME;
        var isUsingImperial = userState.userUOM == "Imperial";

        var heightToSend = height;
        var weightToSend = weight;
        var heightToSendUserInput = sportMatchState.userInputHeight;
        var weightToSendUserInput = sportMatchState.userInputWeight;
        var standingBroadJumpToSendUserInput = sportMatchState.userInputStandingBroadJump;
        var sitReachToSendUserInput = sportMatchState.userInputSitAndReach;
        var gripStrengthLeftToSendUserInput = sportMatchState.userInputGripStrengthLeft;
        var gripStrengthRightToSendUserInput = sportMatchState.userInputGripStrengthRight;
        var armSpanToSendUserInput = sportMatchState.userInputArmSpan;
        var sittingHeightToSendUserInput = sportMatchState.userInputSittingHeight;
        var forearmLengthToSendUserInput = sportMatchState.userInputForearmLength;
        var upperArmLengthToSendUserInput = sportMatchState.userInputUpperArmLength;
        var sprintDistanceToSendUserInput = sportMatchState.userInputSprintDistance;
        var verticalJumpToSendUserInput = sportMatchState.userInputVerticalJump;
        var fathersHeightToSend = stateMyDetails.fathersHeight;
        var mothersHeightToSend = stateMyDetails.mothersHeight;

        // Height
        if (isUsingImperial && heightToSendUserInput != '') {
            heightToSendUserInput = IM(heightToSendUserInput).from('in').to('cm');
        }

        if (isUsingImperial && heightToSend) {
            heightToSend = IM(parseFloat(heightToSend)).from('in').to('cm');
            heightToSend = parseFloat(heightToSend).toFixed(2) + "";
        }

        // Weight
        if (isUsingImperial && weightToSendUserInput != '') {
            weightToSendUserInput = IM(weightToSendUserInput).from('lb').to('kg');
        }

        if (isUsingImperial && weightToSend) {
            weightToSend = IM(parseFloat(weightToSend)).from('lb').to('kg') + "";
        }

        if (isUsingImperial && standingBroadJumpToSendUserInput) {
            standingBroadJumpToSendUserInput = parseFloat(IM(parseFloat(standingBroadJumpToSendUserInput)).from('in').to('cm')) + "";
        }

        if (isUsingImperial && sitReachToSendUserInput) {
            sitReachToSendUserInput = parseFloat(IM(parseFloat(sitReachToSendUserInput)).from('in').to('cm')) + "";
        }

        if (isUsingImperial && gripStrengthLeftToSendUserInput) {
            gripStrengthLeftToSendUserInput = parseFloat(IM(parseFloat(gripStrengthLeftToSendUserInput)).from('lb').to('kg')) + "";
        }

        if (isUsingImperial && gripStrengthRightToSendUserInput) {
            gripStrengthRightToSendUserInput = parseFloat(IM(parseFloat(gripStrengthRightToSendUserInput)).from('lb').to('kg')) + "";
        }

        if (isUsingImperial && armSpanToSendUserInput) {
            armSpanToSendUserInput = parseFloat(IM(parseFloat(armSpanToSendUserInput)).from('in').to('cm')) + "";
        }

        if (isUsingImperial && sittingHeightToSendUserInput) {
            sittingHeightToSendUserInput = parseFloat(IM(parseFloat(sittingHeightToSendUserInput)).from('in').to('cm')) + "";
        }

        if (isUsingImperial && forearmLengthToSendUserInput) {
            forearmLengthToSendUserInput = parseFloat(IM(parseFloat(forearmLengthToSendUserInput)).from('in').to('cm')) + "";
        }

        if (isUsingImperial && upperArmLengthToSendUserInput) {
            upperArmLengthToSendUserInput = parseFloat(IM(parseFloat(upperArmLengthToSendUserInput)).from('in').to('cm')) + "";
        }

        // TODO: make sure this is converting properly
        if (isUsingImperial && sprintDistanceToSendUserInput) {
            sprintDistanceToSendUserInput = parseFloat(IM(parseFloat(sprintDistanceToSendUserInput * 3)).from('ft').to('m')) + "";
        }

        if (isUsingImperial && verticalJumpToSendUserInput) {
            verticalJumpToSendUserInput = parseFloat(IM(parseFloat(verticalJumpToSendUserInput)).from('in').to('cm')) + "";
        }

        if (isUsingImperial && fathersHeightToSend) {
            fathersHeightToSend = parseFloat(IM(parseFloat(fathersHeightToSend)).from('in').to('cm')) + "";
        }

        if (isUsingImperial && mothersHeightToSend) {
            mothersHeightToSend = parseFloat(IM(parseFloat(mothersHeightToSend)).from('in').to('cm')) + "";
        }

        const jsonExample = {
            "test_id": userState.userEditingTest != "" ? userState.userEditingTest + "" : "",
            "user_id": typeof userState.userBackendId !== 'undefined' && userState.userBackendId !== "" ? userState.userBackendId : '2', //todo: get data for this
            "height": heightToSend ? heightToSend : (heightToSendUserInput !== '' ? heightToSendUserInput : '-1'),
            "weight": weightToSend ? weightToSend : (weightToSendUserInput !== '' ? weightToSendUserInput : '-1'),
            "test_date": moment(stateMyDetails.testDate).format('YYYY-MM-DD HH:mm:ss'),
            "situps": sportMatchState.userInputBentKneeSitUps !== '' ? sportMatchState.userInputBentKneeSitUps : '-1',
            "standing_broad_jump": standingBroadJumpToSendUserInput ? standingBroadJumpToSendUserInput : '-1',
            "sit_reach": sitReachToSendUserInput !== '' ? sitReachToSendUserInput : '-1',
            "inclined_pullup": sportMatchState.userInputInclinedPullUps !== '' ? sportMatchState.userInputInclinedPullUps : '-1',
            "shuttle_run": sportMatchState.userInputShuttleRun !== '' ? sportMatchState.userInputShuttleRun : '-1',
            "grip_strength_left": gripStrengthLeftToSendUserInput !== '' ? gripStrengthLeftToSendUserInput : '-1',
            "grip_strength_right": gripStrengthRightToSendUserInput !== '' ? gripStrengthRightToSendUserInput : '-1',
            "walk_run": sportMatchState.userInputWalkRunMinutes !== '' && sportMatchState.userInputWalkRunMinutes !== '' ? (parseFloat(sportMatchState.userInputWalkRunMinutes * 60) + parseFloat(sportMatchState.userInputWalkRunSeconds)) + "" : '-1',
            "arm_span": armSpanToSendUserInput !== '' ? armSpanToSendUserInput : '-1',
            "sitting_height": sittingHeightToSendUserInput !== '' ? sittingHeightToSendUserInput : '-1',
            "forearm_length": forearmLengthToSendUserInput !== '' ? forearmLengthToSendUserInput : '-1',
            "upper_arm_length": upperArmLengthToSendUserInput !== '' ? upperArmLengthToSendUserInput : '-1',
            "shuttle_test_20m": sportMatchState.userInputShuttleBeepTest !== '' ? sportMatchState.userInputShuttleBeepTest : '-1', //todo: find out if shuttleRun is the same as shuttleTest20m?
            "sprint_time": sportMatchState.userInputSprintTime !== '' ? sportMatchState.userInputSprintTime : '-1', //Passed in seconds
            "sprint_distance": sprintDistanceToSendUserInput !== '' ? sprintDistanceToSendUserInput : '-1', //Passed in metres
            "vertical_jump": verticalJumpToSendUserInput !== '' ? verticalJumpToSendUserInput : '-1',
            "fathers_height": fathersHeightToSend !== '' ? fathersHeightToSend : '-1',
            "mothers_height": mothersHeightToSend !== '' ? mothersHeightToSend : '-1',
            "yoyo": sportMatchState.userInputYoyoTest != "" && sportMatchState.userInputYoyoStage != "" ? sportMatchState.userInputYoyoTest + "-" + sportMatchState.userInputYoyoStage : "-1",
            "verified": "false", //todo: get data for this
            "group": userState.userClassName && userState.userClassName != undefined ? userState.userClassName : "",
        };

        if (sportMatchState.userInputHeight != '') {
            dispatch(setHeight(sportMatchState.userInputHeight));
        } else if (height) {
            dispatch(setHeight(height));
        } else {
            dispatch(setHeight(""));
        }

        if (sportMatchState.userInputWeight != '') {
            dispatch(setWeight(sportMatchState.userInputWeight));
        } else if (weight) {
            dispatch(setWeight(weight));
        } else {
            dispatch(setWeight(""));
        }

        if (sportMatchState.userInputBentKneeSitUps != '') {
            dispatch(setSitUps(sportMatchState.userInputBentKneeSitUps));
        } else {
            dispatch(setSitUps(""));
        }

        if (sportMatchState.userInputStandingBroadJump != '') {
            dispatch(setStandingBroadJump(sportMatchState.userInputStandingBroadJump));
        } else {
            dispatch(setStandingBroadJump(""));
        }

        if (sportMatchState.userInputSitAndReach != '') {
            dispatch(setSitAndReach(sportMatchState.userInputSitAndReach));
        } else {
            dispatch(setSitAndReach(""));
        }

        if (sportMatchState.userInputInclinedPullUps != '') {
            dispatch(setInclinedPullUps(sportMatchState.userInputInclinedPullUps));
        } else {
            dispatch(setInclinedPullUps(""));
        }

        if (sportMatchState.userInputShuttleRun != '') {
            dispatch(setShuttleRun(sportMatchState.userInputShuttleRun));
        } else {
            dispatch(setShuttleRun(""));
        }

        if (sportMatchState.userInputGripStrengthLeft != '') {
            dispatch(setLeftGripStrength(sportMatchState.userInputGripStrengthLeft));
        } else {
            dispatch(setLeftGripStrength(""));
        }

        if (sportMatchState.userInputGripStrengthRight != '') {
            dispatch(setRightGripStrength(sportMatchState.userInputGripStrengthRight));
        } else {
            dispatch(setRightGripStrength(""));
        }

        if (sportMatchState.userInputWalkRunMinutes != '') {
            dispatch(setRunWalkMinutes(sportMatchState.userInputWalkRunMinutes));
        } else {
            dispatch(setRunWalkMinutes(""));
        }

        if (sportMatchState.userInputWalkRunSeconds != '') {
            dispatch(setRunWalkSeconds(sportMatchState.userInputWalkRunSeconds));
        } else {
            dispatch(setRunWalkSeconds(""));
        }

        if (sportMatchState.userInputArmSpan != '') {
            dispatch(setArmSpan(sportMatchState.userInputArmSpan));
        } else {
            dispatch(setArmSpan(""));
        }

        if (sportMatchState.userInputSittingHeight != '') {
            dispatch(setSittingHeight(sportMatchState.userInputSittingHeight));
        } else {
            dispatch(setSittingHeight(""));
        }

        if (sportMatchState.userInputForearmLength != '') {
            dispatch(setForearmLength(sportMatchState.userInputForearmLength));
        } else {
            dispatch(setForearmLength(""));
        }

        if (sportMatchState.userInputUpperArmLength != '') {
            dispatch(setUpperArmLength(sportMatchState.userInputUpperArmLength));
        } else {
            dispatch(setUpperArmLength(""));
        }

        if (sportMatchState.userInputShuttleBeepTest != '') {
            dispatch(setBeepTest(sportMatchState.userInputShuttleBeepTest));
        } else {
            dispatch(setBeepTest(""));
        }

        if (sportMatchState.userInputVO2MAX != '') {
            dispatch(setVo2Max(sportMatchState.userInputVO2MAX));
        } else {
            dispatch(setVo2Max(""));
        }

        if (sportMatchState.userInputSprintTime != '') {
            dispatch(setSprintTime(sportMatchState.userInputSprintTime));
        } else {
            dispatch(setSprintTime(""));
        }

        if (sportMatchState.userInputSprintDistance != '') {
            dispatch(setSprintDistance(sportMatchState.userInputSprintDistance));
        } else {
            dispatch(setSprintDistance(""));
        }

        if (sportMatchState.userInputVerticalJump != '') {
            dispatch(setVerticalJump(sportMatchState.userInputVerticalJump));
        } else {
            dispatch(setVerticalJump(""));
        }

        //predicted_adult_fitness_id
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/usertest`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify(jsonExample),
            credentials: "include"
        }).then(async function (res) {
            res.json().then(async function (data) {
                dispatch(setUserFitnessAge(data.predicted_fitness_age));
                dispatch(setAdultSitUps(data.situps));
                dispatch(setAdultInclinedPullUps(data.pullup));
                dispatch(setAdultHeight(parseFloat(userState.userUOM == "Imperial" ? IM(data.height).from('cm').to('in') : data.height)));
                dispatch(setAdultGripStrength(parseFloat(userState.userUOM == "Imperial" ? IM(data.grip_strength).from('kg').to('lb') : data.grip_strength)));
                dispatch(setAdultSittingHeight(data.sitting_height_ration));
                dispatch(setAdultForearmLength(data.brachial_index));
                dispatch(setAdultArmSpan(data.arm_span_height_ratio));
                dispatch(setTestId(data.user_test_id));
                dispatch(setPredictedAdultFitnessId(data.predicted_adult_fitness_id));
                dispatch(setAdultSitAndReach(parseFloat(userState.userUOM == "Imperial" ? IM(data.sit_reach).from('cm').to('in') : data.sit_reach)));
                dispatch(setAdultStandingBroadJump(parseFloat(userState.userUOM == "Imperial" ? IM(data.board_jump).from('cm').to('in') : data.board_jump)));
                dispatch(setAdultVerticalJump(parseFloat(userState.userUOM == "Imperial" ? IM(data.power).from('cm').to('in') : data.power)));
                dispatch(setAdultShuttleRun(data.shuttle));
                dispatch(setAdult50mSprint(parseFloat(userState.userUOM == "Imperial" ? IM(data.speed).from('km/h').to('m/h') : data.speed)));
                dispatch(setAdultWalkRun(data.walk_run));
                dispatch(setAdultVo2Max(data.endurance));
                dispatch(setAdultWeight(parseFloat(userState.userUOM == "Imperial" ? IM(data.weight).from('kg').to('lb') : data.weight)));
                dispatch(setAdultSittingHeight(data.sitting_height_ration));
                dispatch(setAdultForearmLength(data.brachial_index));
                dispatch(setAdultArmSpan(data.arm_span_height_ratio));

                // Setting Yo-Yo 
                if (sportMatchState.userInputYoyoTest && sportMatchState.userInputYoyoTest != "")
                    dispatch(setYoyoTest(sportMatchState.userInputYoyoTest))

                if (sportMatchState.userInputYoyoStage && sportMatchState.userInputYoyoStage != "")
                    dispatch(setYoyoStage(sportMatchState.userInputYoyoStage));

                setSubmitButtonClicked(false);
                dispatch(setUserEditingTest(""));

                window.setTimeout(function () {
                    if (isQAS === true) {
                        const qasTestParam = {
                            test_id: data.user_test_id,
                            user_group: jsonExample["group"],
                            user_gender: userState.userGender ?? '',
                            user_age: userState.userAge
                        }

                        const requestQASReportParams = {
                            test: qasTestParam,
                            normSelected: encodeURI(AUS_NZ_NORM_NAME),
                            usingImperial: isUsingImperial,
                            downloadReport: false,
                            sendAsEmail: true,
                            cancelDownloadToken: cancelDownloadToken,
                            refreshEmailLog: false
                        }

                        doGenerateQASReport(requestQASReportParams);
                    }

                    dispatch(setTestTokenId(""));
                    setLoading(false);
                }, randomNumber(isQAS));

                if (data.situps && data.height && data.grip_strength && data.weight && data.sit_reach && data.pullup && data.speed && data.walk_run && data.endurance && data.power && data.shuttle && data.board_jump && data.arm_span_height_ratio && data.sitting_height_ration && data.brachial_index) {
                    dispatch(setAllFieldsProvided(true));
                }
            }).catch(error => {
                console.log("Error: ", error);
                setSubmitButtonClicked(false);
                addToast("Unable to save 'Test' - internal server error", {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
        })

        dispatch(setUserViewingTest(true));
    }

    // Submit handler
    const submitClickHandler = () => {
        setSubmitButtonClicked(true);
    }

    const randomNumber = (isQAS) => {
        if (isQAS) {
            return 10000;
        } else {
            return Math.random() * (2000 - 1000) + 1000;
        }
    }

    const renderNavigationSection = () => {
        if (testScreenNo === 0) {
            return null;
        } else {
            return (
                <TestNavigationSection
                    gotoTestScreen={gotoTestScreen}
                    flexibilityVisited={flexibilityVisited}
                    muscularStrengthAndPowerVisited={muscularStrengthAndPowerVisited}
                    muscularEnduranceVisited={muscularEnduranceVisited}
                    speedAgilityVisited={speedAgilityVisited}
                    aerobicFitnessVisited={aerobicFitnessVisited}
                    bodySizeShapeVisited={bodySizeShapeVisited}
                    screenSelection={testScreenNo}
                    onPreviousClick={previousButtonHandler}
                    onNextClick={nextButtonHandler}
                />
            )
        }
    }

    const [infoOpen, setInfoOpen] = useState('')
    const renderTestScreen = () => {
        switch (testScreenNo) {
            case 0:
                return (<StartTestMainScreen onSubmitClick={submitClickHandler} gotoTestScreen={gotoTestScreen} setTestStartDate={setTestStartDate} />);
            case 1:
                return (<BodySizeShape validation={showCompulsoryFieldValidation} infoOpen={infoOpen} setInfoOpen={setInfoOpen} />);
            case 2:
                return (<Flexibility infoOpen={infoOpen} setInfoOpen={setInfoOpen} />);
            case 3:
                return (<StrengthPower infoOpen={infoOpen} setInfoOpen={setInfoOpen} />);
            case 4:
                return (<MuscularEndurance infoOpen={infoOpen} setInfoOpen={setInfoOpen} />);
            case 5:
                return (<SpeedAgility infoOpen={infoOpen} setInfoOpen={setInfoOpen} />);
            case 6:
                return (<CardioRespiratory infoOpen={infoOpen} setInfoOpen={setInfoOpen} />);
            default:
                return null;
        }
    }

    const closeDialogHandler = () => {
        setSubmitButtonClicked(false);
    }

    const renderDialog = () => {
        if (submitButtonClicked) {
            return (
                <SubmitTestSection
                    submitHandler={submitDataToBackend}
                    showDialog={submitButtonClicked}
                    closeDialogHandler={closeDialogHandler}
                />
            )
        }
    }

    const renderTestLoading = () => {
        if (loading) {
            return (
                <div className="test-complete-overlay">
                    <span class="loader">Analysing<span class="dot-1">.</span><span class="dot-1">.</span><span class="dot-3">.</span></span>
                </div>
            )
        }
    }
    const renderTestCompleteOverlay = () => {
        if (userState.testTokenId === '' && userState.userEditingTest == "") {
            return (
                <div className="test-complete-overlay">
                    <span>SportMatch test complete!</span>
                </div>
            )
        }
    }

    // On page load
    useEffect(() => {
        setVisited(testScreenNo);
    }, [testScreenNo])

    return (
        <div className="screen-sections test-sections">
            {/** <TestDateSection /> **/}
            {renderTestScreen()}
            {renderNavigationSection()}
            {renderDialog()}
            {renderTestLoading()}
            {renderTestCompleteOverlay()}
        </div>
    )
};

export default SportMatchTest;
