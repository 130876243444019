import React from "react";

const BodySizeShapeSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Body Shape and Size</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="body_size_and_shape" data-name="body size and shape">
            <circle className="cls-2" style={{fill: color}} cx="279.64" cy="84.78" r="39.07"/>
            <path className="cls-2" style={{fill: color}} d="M282.47,160.59" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M288.92,313.82" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M278.41,315.54" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M282.47,160.59" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M473.93,140.55H91.69a20,20,0,1,0,0,40.09H235v78.11c15.26.16,31.31.23,47.86.23,16.73,0,32.88-.07,48.16-.24v-78.1h143a20,20,0,0,0,0-40.09Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M235,290.45v46.23H235v157a20,20,0,0,0,40.08,0v-157h15.77v157a20,20,0,0,0,40.09,0v-157h0V290.35c-15.81.58-32.35.88-49.37.88C265.56,291.23,250,291,235,290.45Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M357,218.6v13.07c60.57,3.38,102.52,10.7,102.52,19.19,0,11.69-78.44,14.24-176.68,14.24s-179.09-2.55-179.09-14.24c0-8.49,41.95-15.81,102.52-19.19V218.6C129.42,223.65,75,236.19,75,250.86c0,19.12,92.49,34.63,206.58,34.63S488.18,270,488.18,250.86C488.18,236.19,433.79,223.65,357,218.6Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default BodySizeShapeSvg;
