import React from "react";

const WeightSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Weight</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="weight">
            <path className="cls-2" style={{fill: color}}
                  d="M399.2,124.08H348.31a76,76,0,0,0-129.69,0H167.73a43.57,43.57,0,0,0-43.52,45.61l11.06,235.66a43.58,43.58,0,0,0,43.52,41.52H388.14a43.58,43.58,0,0,0,43.52-41.52l11.06-235.66A43.57,43.57,0,0,0,399.2,124.08ZM235,336c6.18,37.88,11.59,63.39-4.64,71.12s-71.12-17-59.53-158.47c2.91-35.48,46.39-75.76,76.54-13.92S228.85,298.07,235,336Zm38.85-209.71a7.58,7.58,0,0,0-13.67,6.55l11.21,23.38H223.05c.11-.86.24-1.71.38-2.56l.15-.81c.1-.58.22-1.17.34-1.75.06-.3.13-.6.2-.9.13-.56.27-1.12.41-1.67.07-.28.14-.56.22-.84.18-.66.37-1.32.58-2,0-.16.09-.32.14-.48.26-.8.53-1.59.82-2.37l.27-.71c.21-.55.43-1.1.65-1.64.12-.29.25-.58.37-.86.22-.5.44-1,.67-1.48.13-.28.26-.57.4-.85.25-.52.52-1,.78-1.56.12-.22.23-.45.36-.68.38-.71.78-1.42,1.19-2.12.12-.21.25-.4.37-.6.31-.51.62-1,.94-1.52l.54-.8c.28-.43.57-.85.87-1.27l.58-.81c.31-.43.63-.85.95-1.27l.55-.72c.5-.62,1-1.24,1.53-1.85h0a60.78,60.78,0,0,1,92.29,0h0c.52.61,1,1.23,1.52,1.85l.56.72c.32.42.64.84.95,1.27l.58.81c.3.42.59.84.88,1.27.17.27.35.53.53.8.32.5.63,1,.94,1.52.12.2.25.39.37.6.41.7.81,1.41,1.19,2.12l.35.68c.27.52.54,1,.79,1.56.14.28.27.57.4.85.23.49.45,1,.67,1.48l.36.86c.23.54.45,1.09.66,1.64l.27.71c.29.78.56,1.57.82,2.37.05.16.09.32.14.48.2.65.4,1.31.58,2,.08.28.15.56.22.84.14.55.28,1.11.41,1.67.07.3.13.6.2.9.12.58.23,1.17.34,1.75l.15.81c.14.85.27,1.7.38,2.56H288.24Zm62.64,280.83c-16.23-7.73-10.82-33.24-4.64-71.12s-42.52-39.43-12.37-101.27,73.63-21.56,76.54,13.92C407.65,390.07,352.77,414.8,336.53,407.07Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default WeightSvg;
