import React, { useEffect, useRef, useState } from 'react';
import { BACKEND_URL, ENVIRONMENT_PATH } from '../../../../../store/constants';

import './style.css';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'
import { useSelector } from 'react-redux';

import Zoom from 'react-reveal/Zoom';

import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components";

const SportSelectionComponent = ({
    studentSelector = false,
    show = true,
    customSelectedSports = [],
    customUnselectedSports = [],
    closeHandler = null,
    saveHandler = null,
    setCustomSelectedSports = null
}) => {
    const [availableSports, setAvailableSports] = useState([]);
    const [unavailableSports, setUnavailableSports] = useState([]);
    const [loading, setLoading] = useState(false);

    const [hasLoaded, setLoaded] = useState(false);

    const unavailableSportContainerRef = useRef(null);
    const availableSportContainerRef = useRef(null);

    const state = useSelector(state => state);

    // React Toast Notifications Object
    const { addToast } = useToasts();



    const [selectedClusters, setSelectedClusters] = useState([
        {
            name: 'combat',
            status: false
        },
        {
            name: 'track & field',
            status: false
        },
        {
            name: 'water based sports',
            status: false
        },
        {
            name: 'field games',
            status: false
        },
        {
            name: 'aesthetic sports',
            status: false
        },
        {
            name: 'court games',
            status: false
        }
    ]);

    const initializeClusters = (result, studentClusters) => {
        var selectedClustersLocal = selectedClusters;

        if (studentClusters) {
            selectedClustersLocal.forEach(e => e.status = false);

            for (var i = 0; i < result.available_sports.length; i++) {
                if (result.available_sports[i].sport_cluster && result.available_sports[i].sport_cluster.toLowerCase() == "combat")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "combat").status = true;

                if (result.available_sports[i].sport_cluster && result.available_sports[i].sport_cluster.toLowerCase() == "track & field")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "track & field").status = true;

                if (result.available_sports[i].sport_cluster && result.available_sports[i].sport_cluster.toLowerCase() == "field games")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "field games").status = true;

                if (result.available_sports[i].sport_cluster && result.available_sports[i].sport_cluster.toLowerCase() == "court games")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "court games").status = true;

                if (result.available_sports[i].sport_cluster && result.available_sports[i].sport_cluster.toLowerCase() == "water based sports")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "water based sports").status = true;

                if (result.available_sports[i].sport_cluster && result.available_sports[i].sport_cluster.toLowerCase() == "aesthetic sports")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "aesthetic sports").status = true;
            }
        } else {
            selectedClustersLocal.forEach(e => e.status = true);

            for (var i = 0; i < result.unavailable_sports.length; i++) {
                if (result.unavailable_sports[i].sport_cluster && result.unavailable_sports[i].sport_cluster.toLowerCase() == "combat")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "combat").status = false;

                if (result.unavailable_sports[i].sport_cluster && result.unavailable_sports[i].sport_cluster.toLowerCase() == "track & field")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "track & field").status = false;

                if (result.unavailable_sports[i].sport_cluster && result.unavailable_sports[i].sport_cluster.toLowerCase() == "field games")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "field games").status = false;

                if (result.unavailable_sports[i].sport_cluster && result.unavailable_sports[i].sport_cluster.toLowerCase() == "court games")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "court games").status = false;

                if (result.unavailable_sports[i].sport_cluster && result.unavailable_sports[i].sport_cluster.toLowerCase() == "water based sports")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "water based sports").status = false;

                if (result.unavailable_sports[i].sport_cluster && result.unavailable_sports[i].sport_cluster.toLowerCase() == "aesthetic sports")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "aesthetic sports").status = false;
            }
        }

        setSelectedClusters(selectedClustersLocal);
    }

    useEffect(() => {
        if (studentSelector) {
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/orgsportsselect`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    method: 'get_sports_selected',
                    viewingAsStudent: true,
                    user_id: state.user.userBackendId
                })
            }).then(res => res.json().then(result => {
                if (result.available_sports) {
                    setAvailableSports(result.available_sports.sort(sortSportsAlphabetical));
                    setCustomSelectedSports(result.available_sports.sort(sortSportsAlphabetical));
                }
                if (result.unavailable_sports) {
                    setUnavailableSports(result.unavailable_sports.sort(sortSportsAlphabetical));
                }

                initializeClusters(result, true);

                saveHandler(result.available_sports.map(e => e.sport_id));

                setLoaded(true);
            }).catch(err => console.log("An error occured: ", err)))
                .catch(err => console.log("An error occured: ", err));
        } else {
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/orgsportsselect`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    method: 'get_sports_selected_and_unselected'
                })
            }).then(res => res.json().then(result => {
                if (result.available_sports) {
                    setAvailableSports(result.available_sports.sort(sortSportsAlphabetical));
                }
                if (result.unavailable_sports) {
                    setUnavailableSports(result.unavailable_sports.sort(sortSportsAlphabetical));
                }

                initializeClusters(result, false);

                setLoaded(true);
            }).catch(err => console.log("An error occured: ", err)))
                .catch(err => console.log("An error occured: ", err));
        }
    }, []);

    const removeSelectedButtonHandler = () => {
        var option;

        var includedSports = [];
        var disallowedSports = unavailableSports.map(e => e);

        var arrayOfOptionSportsId = [];

        var options = availableSportContainerRef.current.options;

        for (var i = 0; i < options.length; i++) {
            option = options[i];

            if (option.selected)
                arrayOfOptionSportsId.push(parseInt(option.getAttribute("sport_id")));
        }

        availableSports.forEach(object => {
            if (arrayOfOptionSportsId.includes(parseInt(object.sport_id))) {
                disallowedSports.push(object)
            } else {
                includedSports.push(object);
            }
        });


        includedSports = includedSports.sort(sortSportsAlphabetical);
        disallowedSports = disallowedSports.sort(sortSportsAlphabetical);

        setAvailableSports(includedSports);
        setUnavailableSports(disallowedSports);

        var selectedClustersLocal = selectedClusters;

        for (var i = 0; i < disallowedSports.length; i++) {
            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "combat")
                selectedClustersLocal.find(e => e.name.toLowerCase() == "combat").status = false;

            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "track & field")
                selectedClustersLocal.find(e => e.name.toLowerCase() == "track & field").status = false;

            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "field games")
                selectedClustersLocal.find(e => e.name.toLowerCase() == "field games").status = false;

            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "court games")
                selectedClustersLocal.find(e => e.name.toLowerCase() == "court games").status = false;

            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "water based sports")
                selectedClustersLocal.find(e => e.name.toLowerCase() == "water based sports").status = false;

            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "aesthetic sports")
                selectedClustersLocal.find(e => e.name.toLowerCase() == "aesthetic sports").status = false;
        }
    }

    const removeAllButtonHandler = () => {
        var newUnavailableSports = unavailableSports.map(e => e);
        var selectedClustersLocal = selectedClusters;

        for (var j = 0; j < availableSports.length; j++)
            newUnavailableSports.push(availableSports[j]);

        setAvailableSports([]);
        setUnavailableSports(newUnavailableSports.sort(sortSportsAlphabetical));
        selectedClustersLocal.forEach(e => e.status = false);

        setSelectedClusters(selectedClustersLocal);
    };

    // Error to display and log
    const displayError = (error = "") => {
        addToast("An error occured", {
            appearance: 'error',
            autoDismiss: true
        });
        console.log(`An error occured\n${error}`);
        setLoading(false);
    };

    const addSelectedButtonHandler = () => {
        var option;

        var includedSports = availableSports.map(e => e);;
        var disallowedSports = [];

        var combatCount = 0;
        var trackAndFieldCount = 0;
        var fieldGamesCount = 0;
        var courtGamesCount = 0;
        var waterBasedSportsCount = 0;
        var aestheticSportsCount = 0;

        var arrayOfOptionSportsId = [];

        var options = unavailableSportContainerRef.current.options;

        for (var i = 0; i < options.length; i++) {
            option = options[i];

            if (option.selected)
                arrayOfOptionSportsId.push(parseInt(option.getAttribute("sport_id")));
        }

        unavailableSports.forEach(object => {
            if (arrayOfOptionSportsId.includes(parseInt(object.sport_id))) {
                includedSports.push(object);
            } else {
                disallowedSports.push(object)
            }
        })

        setAvailableSports(includedSports.sort(sortSportsAlphabetical));
        setUnavailableSports(disallowedSports.sort(sortSportsAlphabetical));

        selectedClusters.forEach(e => e.status = true);

        var selectedClustersLocal = selectedClusters;
        
        for (var i = 0; i < disallowedSports.length; i++) {
            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "combat") {
                selectedClustersLocal.find(e => e.name.toLowerCase() == "combat").status = false;
                combatCount++;
            }
                
            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "track & field") {
                selectedClustersLocal.find(e => e.name.toLowerCase() == "track & field").status = false;
                trackAndFieldCount++;
            }
                
            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "field games") {
                selectedClustersLocal.find(e => e.name.toLowerCase() == "field games").status = false;
                fieldGamesCount++;
            }
                
            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "court games") {
                selectedClustersLocal.find(e => e.name.toLowerCase() == "court games").status = false;
                courtGamesCount++;
            }
                
            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "water based sports") {
                selectedClustersLocal.find(e => e.name.toLowerCase() == "water based sports").status = false;
                waterBasedSportsCount++;
            }
                
            if (disallowedSports[i].sport_cluster && disallowedSports[i].sport_cluster.toLowerCase() == "aesthetic sports") {
                selectedClustersLocal.find(e => e.name.toLowerCase() == "aesthetic sports").status = false;
                aestheticSportsCount++;
            }
        }

        for (var i = 0; i < includedSports.length; i++) {
            if (includedSports[i].sport_cluster && includedSports[i].sport_cluster.toLowerCase() == "combat") {
                combatCount++;
            }
                
            if (includedSports[i].sport_cluster && includedSports[i].sport_cluster.toLowerCase() == "track & field") {
                trackAndFieldCount++;
            }
                
            if (includedSports[i].sport_cluster && includedSports[i].sport_cluster.toLowerCase() == "field games") {
                fieldGamesCount++;
            }
                
            if (includedSports[i].sport_cluster && includedSports[i].sport_cluster.toLowerCase() == "court games") {
                courtGamesCount++;
            }
                
            if (includedSports[i].sport_cluster && includedSports[i].sport_cluster.toLowerCase() == "water based sports") {
                waterBasedSportsCount++;
            }
                
            if (includedSports[i].sport_cluster && includedSports[i].sport_cluster.toLowerCase() == "aesthetic sports") {
                aestheticSportsCount++;
            }
        }

        if(combatCount == 0) {
            selectedClustersLocal.find(e => e.name.toLowerCase() == "combat").status = false;
        }

        if(trackAndFieldCount == 0) {
            selectedClustersLocal.find(e => e.name.toLowerCase() == "track & field").status = false;
        }

        if(fieldGamesCount == 0) {
            selectedClustersLocal.find(e => e.name.toLowerCase() == "field games").status = false;
        }

        if(courtGamesCount == 0) {
            selectedClustersLocal.find(e => e.name.toLowerCase() == "court games").status = false;
        }

        if(waterBasedSportsCount == 0) {
            selectedClustersLocal.find(e => e.name.toLowerCase() == "water based sports").status = false;
        }

        if(aestheticSportsCount == 0) {
            selectedClustersLocal.find(e => e.name.toLowerCase() == "aesthetic sports").status = false;
        }

        setSelectedClusters(selectedClustersLocal);
    }

    const addAllButtonHandler = () => {
        var newAvailableSports = availableSports.map(e => e);

        for (var j = 0; j < unavailableSports.length; j++)
            newAvailableSports.push(unavailableSports[j]);

        setAvailableSports(newAvailableSports.sort(sortSportsAlphabetical));
        setUnavailableSports([]);

        if (!studentSelector) {
            selectedClusters.forEach(e => e.status = true);
        } else {
            var selectedClustersLocal = selectedClusters;
            selectedClustersLocal.forEach(e => e.status = false);

            for (var i = 0; i < newAvailableSports.length; i++) {
                if (newAvailableSports[i].sport_cluster && newAvailableSports[i].sport_cluster.toLowerCase() == "combat")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "combat").status = true;

                if (newAvailableSports[i].sport_cluster && newAvailableSports[i].sport_cluster.toLowerCase() == "track & field")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "track & field").status = true;

                if (newAvailableSports[i].sport_cluster && newAvailableSports[i].sport_cluster.toLowerCase() == "field games")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "field games").status = true;

                if (newAvailableSports[i].sport_cluster && newAvailableSports[i].sport_cluster.toLowerCase() == "court games")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "court games").status = true;

                if (newAvailableSports[i].sport_cluster && newAvailableSports[i].sport_cluster.toLowerCase() == "water based sports")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "water based sports").status = true;

                if (newAvailableSports[i].sport_cluster && newAvailableSports[i].sport_cluster.toLowerCase() == "aesthetic sports")
                    selectedClustersLocal.find(e => e.name.toLowerCase() == "aesthetic sports").status = true;
            }

            setSelectedClusters(selectedClustersLocal);
        }
    };

    const saveUnavailableSportsSelected = () => {
        return new Promise((resolve, reject) => {
            var unavailableSportIdsString = "";
            var unavailableIndex = 0;

            unavailableSports.forEach(object => {
                unavailableSportIdsString += object.sport_id + (unavailableIndex < unavailableSports.length - 1 ? "," : "");
                unavailableIndex++;
            })

            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/orgsportsselect`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    method: 'bulk_delete',
                    sport_ids: unavailableSportIdsString
                })
            }).then(() => resolve(unavailableSportIdsString.length != 0));
        })
    };

    const saveAvailableSports = () => {
        return new Promise((resolve, reject) => {
            var availableSportIdsString = "";
            var availableIndex = 0;

            availableSports.forEach(object => {
                availableSportIdsString += object.sport_id + (availableIndex < availableSports.length - 1 ? "," : "");
                availableIndex++;
            })

            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/orgsportsselect`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    method: 'enable_sports_bulk',
                    sport_ids: availableSportIdsString
                })
            }).then(() => resolve());
        })
    }

    const saveSportSelections = () => {
        setLoading(true);
        saveUnavailableSportsSelected().then((isDeletingSports) => {
            saveAvailableSports().then(() => {
                var requestString = "";

                if(isDeletingSports) {
                    requestString = "?using_org_sports=true";
                } else {
                    requestString = "?all_sports=true";
                }

                // Re-Initialize the top sports
                fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/orgtopsports${requestString}&save_sport_types_to_db=true&save_top_sports_to_db=true`, {
                    headers: {
                      'Accept': 'application/json'
                    },
                    credentials: "include"
                  }).then(() => {
                    addToast("Saved selected sports", {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    setLoading(false);
                  }).catch((err) => {
                    displayError(err);
                  });
            }).catch((err) => {
                displayError(err);
            });
        }).catch((err) => {
            displayError(err);
        });
    }

    const clusterButtonSelection = (type, checked, e = null) => {
        if (type != "") {
            var newAvailableSports = [];
            var disallowedSports = [];
            var selectedClustersLocal = selectedClusters;

            if (checked) {
                newAvailableSports = availableSports.map(e => e);
                disallowedSports = [];

                for (var j = 0; j < unavailableSports.length; j++) {
                    if (unavailableSports[j].sport_cluster && unavailableSports[j].sport_cluster.toLowerCase() == type.toLowerCase()) {
                        newAvailableSports.push(unavailableSports[j]);
                    } else {
                        disallowedSports.push(unavailableSports[j]);
                    }
                }

                selectedClustersLocal.find(e => e.name.toLowerCase() == type).status = true;
            } else {
                newAvailableSports = [];
                disallowedSports = unavailableSports.map(e => e)

                for (var j = 0; j < availableSports.length; j++) {
                    if (availableSports[j].sport_cluster && availableSports[j].sport_cluster.toLowerCase() == type.toLowerCase()) {
                        disallowedSports.push(availableSports[j]);
                    } else {
                        newAvailableSports.push(availableSports[j]);
                    }
                }

                selectedClustersLocal.find(e => e.name.toLowerCase() == type).status = false;
            }

            setAvailableSports(newAvailableSports.sort(sortSportsAlphabetical));
            setUnavailableSports(disallowedSports.sort(sortSportsAlphabetical));
            setSelectedClusters(selectedClustersLocal.sort(sortSportsAlphabetical));
        }
    };

    const renderClusterOptions = () => {
        var dom = [];

        if (hasLoaded) {
            dom.push(
                <div>
                    <div className="cluster-sport-selection-container">
                        <input
                            disabled={loading}
                            checked={selectedClusters.find(e => e.name.toLowerCase() == "combat").status}
                            onClick={(e) => clusterButtonSelection("combat", e.target.checked)} type="checkbox" />
                        <label>Combat sports</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input
                            disabled={loading}
                            checked={selectedClusters.find(e => e.name.toLowerCase() == "track & field").status}
                            onClick={(e) => clusterButtonSelection("track & field", e.target.checked)} type="checkbox" />
                        <label>Track & field athletics</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input
                            disabled={loading}
                            checked={selectedClusters.find(e => e.name.toLowerCase() == "field games").status}
                            onClick={(e) => clusterButtonSelection("field games", e.target.checked)} type="checkbox" />
                        <label>Field games</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input
                            disabled={loading}
                            checked={selectedClusters.find(e => e.name.toLowerCase() == "court games").status}
                            onClick={(e) => clusterButtonSelection("court games", e.target.checked)} type="checkbox" />
                        <label>Court games</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input
                            disabled={loading}
                            checked={selectedClusters.find(e => e.name.toLowerCase() == "water based sports").status}
                            onClick={(e) => clusterButtonSelection("water based sports", e.target.checked)} type="checkbox" />
                        <label>Water-based sports</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input
                            disabled={loading}
                            checked={selectedClusters.find(e => e.name.toLowerCase() == "aesthetic sports").status}
                            onClick={(e) => clusterButtonSelection("aesthetic sports", e.target.checked)} type="checkbox" />
                        <label>Aesthetic sports</label>
                    </div>
                </div>
            )
        } else {
            dom.push(
                <div>
                    <div className="cluster-sport-selection-container">
                        <input onClick={(e) => clusterButtonSelection("combat", e.target.checked)} type="checkbox" />
                        <label>Combat sports</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input onClick={(e) => clusterButtonSelection("track & field", e.target.checked)} type="checkbox" />
                        <label>Track & field athletics</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input onClick={(e) => clusterButtonSelection("field games", e.target.checked)} type="checkbox" />
                        <label>Field games</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input onClick={(e) => clusterButtonSelection("court games", e.target.checked)} type="checkbox" />
                        <label>Court games</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input onClick={(e) => clusterButtonSelection("water based sports", e.target.checked)} type="checkbox" />
                        <label>Water-based sports</label>
                    </div>
                    <div className="cluster-sport-selection-container">
                        <input onClick={(e) => clusterButtonSelection("aesthetic sports", e.target.checked)} type="checkbox" />
                        <label>Aesthetic sports</label>
                    </div>
                </div>
            )
        }

        return dom;
    }

    const sortSportsAlphabetical = (a, b) => {
        if (a.sport_name < b.sport_name) {
            return -1;
        }
        if (a.sport_name > b.sport_name) {
            return 1;
        }
        return 0;
    }

    // Styles to apply to SportMatch buttons within widget
    const Button = styled.button`
        background: ${state.organisationState.organisationPrimaryColor != "" ? (state.organisationState.organisationPrimaryColor  + " !important") : "#9a62b3"}
    `;

    const Heading = styled.span`
        color: ${state.organisationState.organisationPrimaryColor != "" ? (state.organisationState.organisationPrimaryColor  + " !important") : "#9a62b3"}
    `;

    return (
        <Zoom disabled={!studentSelector} duration={400}>
            <div className="sport-selector-container" style={{ display: show ? 'block' : 'none' }}>
                <div className="sport-selector-contianer-heading">
                    <span>Sports</span>
                </div>

                <div className="sport-selector-container-body">
                    <div className="sport-selector-sports-unavailable">
                        <div className="heading">
                            <Heading>Unselected Sports</Heading>
                        </div>

                        <div className="sport-selector-sport-container">
                            <select disabled={loading} multiple ref={unavailableSportContainerRef}>
                                {unavailableSports.map(e => <option key={e.sport_id} sport_obj={e} sport_id={e.sport_id}>{e.sport_name} {"(" + e.sport_gender + ")"}</option>)}
                            </select>
                        </div>
                    </div>

                    <div className="sport-selector-container-options">
                        {renderClusterOptions()}
                        <div className="sport-selector-buttons">
                            <Button disabled={loading} className="sportmatch-button" onClick={() => addAllButtonHandler()}>{"Add all"}</Button>
                            <Button disabled={loading} className="sportmatch-button" onClick={() => addSelectedButtonHandler()}>{"Add >>"}</Button>
                            <Button disabled={loading} className="sportmatch-button" onClick={() => removeAllButtonHandler()}>{"Remove all"}</Button>
                            <Button disabled={loading} className="sportmatch-button" onClick={() => removeSelectedButtonHandler()}>{"<< Remove"}</Button>
                        </div>

                    </div>

                    <div className="sport-selector-your-sports">
                        <div className="heading">
                            <Heading>Selected Sports</Heading>
                        </div>
                        <div className="sport-selector-sport-container">
                            <select disabled={loading} multiple ref={availableSportContainerRef}>
                                {availableSports.map(e => <option key={e.sport_id} sport_obj={e} sport_id={e.sport_id}>{e.sport_name} {"(" + e.sport_gender + ")"}</option>)}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="sport-selector-container-bottom-options">
                    <Button disabled={loading} className="save-button" onClick={() => {
                        if (saveHandler != null) {
                            saveHandler(availableSports.map(e => e.sport_id));

                            addToast("Saved selected sports", {
                                appearance: 'success',
                                autoDismiss: true
                            });

                            if(closeHandler != null) {
                                closeHandler();
                            }
                        } else {
                            saveSportSelections()
                        }
                    }}>Apply</Button>

                    {loading ? 
                        <div className="sport-selector-loading-container">
                            <CircularProgress disableShrink={true} size={20} />
                        </div> 
                        : null
                    } 

                    <button 
                        style={{display: closeHandler != null ? 'block' : 'none'}}
                        className="cancel-button" 
                        disabled={closeHandler == null || loading} 
                        onClick={closeHandler != null ? closeHandler : null}
                    >Cancel</button>
                </div>
            </div>
        </Zoom>

    )
}

export default SportSelectionComponent;