import React, { useRef, useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

// CSS
import './components.css';

// MATERIAL UI
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../store/constants';

import Chart from 'chart.js';

import moment from 'moment';
import { CheckIfUserSessionIsValid } from '../api/Helper';

import IM from 'convert-units';
import { ConvertToFeetAndInches } from '../helper/helper';
import { hexToRgbA } from '../helper/hexToRgbA';
import Axios from 'axios';

let cancelDownloadToken = Axios.CancelToken.source();

export const DownloadButton = ({
    test,
    loaded,
    isHeaderButton,
    title,
    link,
    _className,
    headerImages,
    anthropometryImages,
    customCallback,
    showNameInTitle = false,
    allowForButtonCancellationOnClick = true
}) => {
    const inputEl = useRef(null);
    const inputEl2 = useRef(null);
    const imageElement = useRef(null);

    const reduxState = useSelector(state => state);

    const [topFiveSports, setTopFiveSports] = useState([]);

    const dispatch = useDispatch();

    const userState = useSelector(state => state.user);

    const usingImperial = userState.userUOM == "Imperial";

    // Sport types
    const [powerSportTypeRank, setPowerSportTypeRank] = useState(1);
    const [speedSportTypeRank, setSpeedSportTypeRank] = useState(1);
    const [aerobicSportTypeRank, setAerobicSportTypeRank] = useState(1);
    const [gameFitnessSportTypeRank, setGameFitnessSportTypeRank] = useState(1);
    const [strengthSportTypeRank, setStrengthSportTypeRank] = useState(1);
    const [skillSportTypeRank, setSkillSportTypeRank] = useState(1);

    const [sportTypeCategoryRankings, setSportTypeCategoryRankings] = useState([]);
    const [reportDataset, setReportDataset] = useState([]);

    const [height, setHeight] = useState(1);
    const [weight, setWeight] = useState(1);
    const [armSpan, setArmSpan] = useState(1);
    const [forearmLength, setForearmLength] = useState(1);
    const [sittingHeight, setSittingHeight] = useState(1);

    const [sitAndReachLocalImage, setSitAndReachLocalImage] = useState(null);

    const [normSelected, setNormSelected] = useState(test.norm_selected || encodeURI("australian and nz"));

    const [loading, setLoading] = useState(false);

    const organisationLocalState = useSelector(state => state.organisationState);
    const primaryColor = organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgb(90, 83, 128)';
    const secondaryColor = organisationLocalState.organisationSecondaryColor != '' ? organisationLocalState.organisationSecondaryColor : 'rgb(119, 133, 153)';
    const accentColor = organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : 'rgb(84, 179, 133)';

    const getAverageGripStrength = (gripStrengthLeft, gripStrengthRight) => {
        var isNegativeOne = gripStrengthLeft == -1 && gripStrengthRight == -1;

        var gripStrengthLeftLocal = gripStrengthLeft;
        var gripStrengthRightLocal = gripStrengthRight;

        var hasGripStrengthLeft = gripStrengthLeftLocal != -1;
        var hasGripStrengthRight = gripStrengthRightLocal != -1;

        if (hasGripStrengthLeft && !hasGripStrengthRight) {
            gripStrengthRightLocal = gripStrengthLeftLocal;
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (hasGripStrengthRight && !hasGripStrengthLeft) {
            gripStrengthLeftLocal = gripStrengthRightLocal;
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (hasGripStrengthLeft && hasGripStrengthRight) {
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (isNegativeOne) {
            return -1;
        } else {
            return 0;
        }
    }

    const getScoreRankingForSportType = (name) => {
        var sportTypeName = "";

        if (name === "powerScore") {
            sportTypeName = "power";
        }

        if (name === "skillScore") {
            sportTypeName = "skill";
        }

        if (name === "strengthScore") {
            sportTypeName = "strength";
        }

        if (name === "gameFitnessScore") {
            sportTypeName = "game - fitness";
        }

        if (name === "speedScore") {
            sportTypeName = "speed";
        }

        if (name === "aerobicFitnessScore") {
            sportTypeName = "aerobic fitness";
        }

        var scoreCount1 = 0;
        var scoreRanking1 = 0;
        var scoreAverage1 = 0;

        var scoreCount2 = 0;
        var scoreRanking2 = 0;
        var scoreAverage2 = 0;
        var totalScore = 0;

        for (var x = 0; x < topFiveSports.length; x++) {

            var sportObject = topFiveSports[x];

            if (sportObject) {
                if (sportObject.sport_type1 === sportTypeName) {
                    scoreCount1 += 1;
                    scoreRanking1 += ((x + 1) * 1);
                }

                if (sportObject.sport_type2 === sportTypeName) {
                    scoreCount2 += 1;
                    scoreRanking2 += ((x + 1) * 1.2);
                }
            }
        }

        scoreAverage1 = (scoreRanking1 / scoreCount1);
        if (isNaN(scoreAverage1)) {
            scoreAverage1 = 0;
        }

        scoreAverage2 = (scoreRanking2 / scoreCount2);
        if (isNaN(scoreAverage2)) {
            scoreAverage2 = 0;
        }

        totalScore = (scoreAverage1 + scoreAverage2);

        return totalScore;
    }

    const getSportRankings = () => {
        var arrayOfCategories = [
            {
                name: "powerScore",
                value: 0
            },
            {
                name: "skillScore",
                value: 0
            },
            {
                name: "strengthScore",
                value: 0
            },
            {
                name: "aerobicFitnessScore",
                value: 0
            },
            {
                name: "gameFitnessScore",
                value: 0
            },
            {
                name: "speedScore",
                value: 0
            }
        ];

        for (var i = 0; i < arrayOfCategories.length; i++) {

            var categoryObject = arrayOfCategories[i];

            var finalScoreForCategory = getScoreRankingForSportType(categoryObject.name);

            if (categoryObject.name === "powerScore") {
                setPowerSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "skillScore") {
                setSkillSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "aerobicFitnessScore") {
                setAerobicSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "strengthScore") {
                setStrengthSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "gameFitnessScore") {
                setGameFitnessSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "speedScore") {
                setSpeedSportTypeRank(finalScoreForCategory);
            }
        }
    }

    const getObjectByName = (name, array) => {
        for (var x = 0; x < array.length; x++) {
            if (name === array[x].name) {
                return array[x];
            }
        }
    }

    const getReportData = () => {
        return new Promise((resolve, reject) => {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/Reports?&getTest=${test.test_id}&reports=true&gender=${test.user_gender}&name=${normSelected}&age=${test.user_age}`, {
                withCredentials: true,
                cancelToken: cancelDownloadToken.token
            }).then(res => {
                console.log("This is hit");
                var dataToUse = res.data[0];
                var percentileData = res.data[1];
                var adultPredictedData = res.data[2];
                var adultPredictedValuePercentiles = res.data[3];

                var obj = [
                    {
                        name: 'StudentID',
                        value: test.user_id + "",
                        nonFormatted: ''
                    },
                    {
                        name: 'Student_Group',
                        value: dataToUse.group != null ? dataToUse.group : test.user_group != null ? test.user_group : "",
                        nonFormatted: ''
                    },
                    {
                        name: 'testDate',
                        value: moment(dataToUse.date_of_test).format("DD/MM/YYYY"),
                        nonFormatted: ''
                    },
                    {
                        name: 'org_name',
                        value: userState.userOrganisationName,
                        nonFormatted: ''
                    },
                    {
                        name: 'height',
                        value: dataToUse.height !== '' ? (usingImperial ? IM(dataToUse.height).from('cm').to('in') : dataToUse.height) + ' cm' : '',
                        percentileRanking: percentileData.height ? percentileData.height : '',
                        predictedAdultValue: adultPredictedData.height ? (usingImperial ? IM(adultPredictedData.height).from('cm').to('in') : adultPredictedData.height) : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.height ? adultPredictedValuePercentiles.height : -1,
                        nonFormatted: dataToUse.height ? (usingImperial ? IM(dataToUse.height).from('cm').to('in') : dataToUse.height) + '' : ''
                    },
                    {
                        name: 'weight',
                        value: dataToUse.weight !== '' ? dataToUse.weight + ' kg' : '',
                        percentileRanking: percentileData.weight ? percentileData.weight : '',
                        predictedAdultValue: adultPredictedData.weight ? (usingImperial ? IM(adultPredictedData.weight).from('kg').to('lb') : adultPredictedData.weight) : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.weight ? adultPredictedValuePercentiles.weight : -1,
                        nonFormatted: dataToUse.weight ? dataToUse.weight + '' : ''
                    },
                    {
                        name: 'sitAndReach',
                        value: dataToUse.sit_reach != -1 ? (usingImperial ? IM(dataToUse.sit_reach).from('cm').to('in') : dataToUse.sit_reach) + ' cm' : '',
                        percentileRanking: percentileData.sit_reach ? percentileData.sit_reach : '',
                        predictedAdultValue: adultPredictedData.sit_reach ? (usingImperial ? IM(adultPredictedData.sit_reach).from('cm').to('in') : adultPredictedData.sit_reach) : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.sit_reach ? adultPredictedValuePercentiles.sit_reach : -1,
                        nonFormatted: dataToUse.sit_reach !== '' ? (usingImperial && dataToUse.sit_reach > 0 ? IM(dataToUse.sit_reach).from('cm').to('in') : dataToUse.sit_reach) + '' : ''
                    },
                    {
                        name: 'standingBroadJump',
                        value: dataToUse.standing_broad_jump != -1 ? (usingImperial ? ConvertToFeetAndInches(IM(dataToUse.standing_broad_jump).from('cm').to('in')) : dataToUse.standing_broad_jump) + ' cm' : '',
                        percentileRanking: percentileData.board_jump ? percentileData.board_jump : '',
                        predictedAdultValue: adultPredictedData.board_jump ? (usingImperial ? IM(adultPredictedData.board_jump).from('cm').to('in') : adultPredictedData.board_jump) : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.board_jump ? adultPredictedValuePercentiles.board_jump : -1,
                        nonFormatted: dataToUse.standing_broad_jump !== '' && dataToUse.standing_broad_jump != -1 ? (usingImperial && dataToUse.standing_broad_jump > 0 ? IM(dataToUse.standing_broad_jump).from('cm').to('in') : dataToUse.standing_broad_jump) + '' : ''
                    },
                    {
                        // TODO: calculate average
                        name: 'gripStrength',
                        value: getAverageGripStrength(dataToUse.grip_strength_left, dataToUse.grip_strength_right) != -1 ? (usingImperial ? IM(getAverageGripStrength(dataToUse.grip_strength_left, dataToUse.grip_strength_right)).from('kg').to('lb') : getAverageGripStrength(dataToUse.grip_strength_left, dataToUse.grip_strength_right)) + ' kg' : '',
                        percentileRanking: percentileData.grip_strength ? percentileData.grip_strength : '',
                        predictedAdultValue: adultPredictedData.grip_strength ? (usingImperial ? IM(adultPredictedData.grip_strength).from('kg').to('lb') : adultPredictedData.grip_strength) : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.grip_strength ? adultPredictedValuePercentiles.grip_strength : -1,
                        nonFormatted: getAverageGripStrength(dataToUse.grip_strength_left, dataToUse.grip_strength_right) !== 0 ? (usingImperial && getAverageGripStrength(dataToUse.grip_strength_left, dataToUse.grip_strength_right) > 0 ? IM(getAverageGripStrength(dataToUse.grip_strength_left, dataToUse.grip_strength_right)).from('kg').to('lb') : getAverageGripStrength(dataToUse.grip_strength_left, dataToUse.grip_strength_right)) + '' : ''
                    },
                    {
                        name: 'verticalJump',
                        value: dataToUse.vertical_jump != -1 ? (usingImperial ? IM(dataToUse.vertical_jump).from('cm').to('in') : parseFloat(dataToUse.vertical_jump).toFixed(1)) + ' cm' : '',
                        percentileRanking: percentileData.power ? percentileData.power : '',
                        predictedAdultValue: adultPredictedData.power ? (usingImperial ? IM(adultPredictedData.power).from('cm').to('in') : adultPredictedData.power) : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.power ? adultPredictedValuePercentiles.power : -1,
                        nonFormatted: dataToUse.vertical_jump !== '' ? (usingImperial && dataToUse.vertical_jump > 0 ? IM(dataToUse.vertical_jump).from('cm').to('in') : parseFloat(dataToUse.vertical_jump).toFixed(1)) + '' : '',
                    },
                    {
                        name: 'sitUps',
                        value: dataToUse.situps != -1 ? dataToUse.situps : '',
                        percentileRanking: percentileData.situps ? percentileData.situps : '',
                        predictedAdultValue: adultPredictedData.situps ? adultPredictedData.situps : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.situps ? adultPredictedValuePercentiles.situps : -1,
                        nonFormatted: dataToUse.situps !== '' ? dataToUse.situps + '' : ''
                    },
                    {
                        name: 'inclinedPullUps',
                        value: dataToUse.inclined_pull_ups != -1 ? dataToUse.inclined_pull_ups : '',
                        percentileRanking: percentileData.pullup ? percentileData.pullup : '',
                        predictedAdultValue: adultPredictedData.pullup ? adultPredictedData.pullup : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.pullup ? adultPredictedValuePercentiles.pullup : -1,
                        nonFormatted: dataToUse.inclined_pull_ups !== '' ? dataToUse.inclined_pull_ups + '' : ''
                    },
                    {
                        name: 'shuttleRun',
                        value: dataToUse.shuttle_run != -1 ? dataToUse.shuttle_run + ' sec' : '',
                        percentileRanking: percentileData.shuttle ? percentileData.shuttle : '',
                        predictedAdultValue: adultPredictedData.shuttle ? adultPredictedData.shuttle : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.shuttle ? adultPredictedValuePercentiles.shuttle : -1,
                        nonFormatted: dataToUse.shuttle_run !== '' ? dataToUse.shuttle_run + '' : ''
                    },
                    {
                        name: 'sprint',
                        value: dataToUse.speed !== '' ? (usingImperial ? IM(parseFloat(dataToUse.speed).toFixed(1)).from('km/h').to('m/h') : parseFloat(dataToUse.speed).toFixed(1)) + ' km/h' : '',
                        percentileRanking: percentileData.speed ? percentileData.speed : '',
                        predictedAdultValue: adultPredictedData.speed ? (usingImperial ? IM(adultPredictedData.speed).from('km/h').to('m/h') : adultPredictedData.speed) : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.speed ? adultPredictedValuePercentiles.speed : -1,
                        nonFormatted: dataToUse.speed !== '' ? (usingImperial && dataToUse.speed > 0 ? IM(parseFloat(dataToUse.speed).toFixed(1)).from('km/h').to('m/h') : parseFloat(dataToUse.speed).toFixed(1)) + '' : '',
                    },
                    {
                        name: 'walkRun',
                        value: dataToUse.walk_run != -1 ? moment.utc(dataToUse.walk_run * 1000).format('mm:ss') + " m:s" : '',
                        percentileRanking: percentileData.walk_run ? percentileData.walk_run : '',
                        predictedAdultValue: adultPredictedData.walk_run ? adultPredictedData.walk_run : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.walk_run ? adultPredictedValuePercentiles.walk_run : -1,
                        nonFormatted: dataToUse.walk_run !== '' ? dataToUse.walk_run + '' : ''
                    },
                    {
                        name: 'beepTest',
                        value: dataToUse.vo2Max !== -1 ? parseFloat(dataToUse.vo2Max).toFixed(1) + "" : '',
                        percentileRanking: percentileData.endurance ? percentileData.endurance : '',
                        predictedAdultValue: adultPredictedData.endurance ? adultPredictedData.endurance : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.endurance ? adultPredictedValuePercentiles.endurance : -1,
                        nonFormatted: dataToUse.vo2Max !== '' ? parseFloat(dataToUse.vo2Max).toFixed(1) + '' : '',
                    },
                    {
                        name: 'armSpan',
                        value: dataToUse.arm_span != -1 ? dataToUse.arm_span + ' cm' : '',
                        percentileRanking: percentileData.arm_span_height_ratio ? percentileData.arm_span_height_ratio : '0',
                        predictedAdultValue: adultPredictedData.arm_span_height_ratio ? adultPredictedData.arm_span_height_ratio : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.arm_span_height_ratio ? adultPredictedValuePercentiles.arm_span_height_ratio : -1,
                        nonFormatted: dataToUse.arm_span !== '' ? dataToUse.arm_span + '' : '',
                    },
                    {
                        name: 'forearmLength',
                        value: dataToUse.forearm_length !== -1 ? dataToUse.forearm_length + ' cm' : '',
                        percentileRanking: percentileData.brachial_index ? percentileData.brachial_index : '0',
                        predictedAdultValue: adultPredictedData.brachial_index ? adultPredictedData.brachial_index : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.brachial_index ? adultPredictedValuePercentiles.brachial_index : -1,
                        nonFormatted: dataToUse.forearm_length !== '' ? dataToUse.forearm_length + '' : '',
                    },
                    {
                        name: 'upperarmLength',
                        value: dataToUse.upper_arm_length !== -1 ? dataToUse.upper_arm_length + ' cm' : '',
                        percentileRanking: percentileData.brachial_index ? percentileData.brachial_index : '0',
                        predictedAdultValue: adultPredictedData.brachial_index ? adultPredictedData.brachial_index : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.brachial_index ? adultPredictedValuePercentiles.brachial_index : -1,
                        nonFormatted: dataToUse.upper_arm_length !== '' ? dataToUse.upper_arm_length + '' : '',
                    },
                    {
                        name: 'sittingHeight',
                        value: dataToUse.sitting_height !== -1 ? dataToUse.sitting_height + ' cm' : '',
                        percentileRanking: percentileData.sitting_height_ration ? percentileData.sitting_height_ration : '0',
                        predictedAdultValue: adultPredictedData.sitting_height_ration ? adultPredictedData.sitting_height_ration : -1,
                        predictedAdultPercentileRanking: adultPredictedValuePercentiles.sitting_height_ration ? adultPredictedValuePercentiles.sitting_height_ration : -1,
                        nonFormatted: dataToUse.sitting_height !== '' ? dataToUse.sitting_height + '' : '',
                    },
                    {
                        name: 'PredictedAdultFitnessId',
                        value: dataToUse.predicted_adult_fitness_id,
                        nonFormatted: ''
                    },
                    {
                        name: 'user_age',
                        value: dataToUse.user_age,
                        nonFormatted: ''
                    },
                    {
                        name: 'fitness_age',
                        value: dataToUse.fitness_age,
                        nonFormatted: ''
                    },
                    {
                        name: 'test_id',
                        value: dataToUse.test_no,
                        nonFormatted: ''
                    },
                    {
                        name: 'norm_name',
                        value: percentileData.norm_name
                    },
                    {
                        name: 'user_name',
                        value: dataToUse.user_name
                    }
                ];

                setReportDataset(obj);
                resolve(obj);
            });
        })
    }

    const getTop5Sports = (data, usingOrgSports) => {
        return new Promise((resolve, reject) => {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/FindMySport?predicted_adult_fitness_id=${getObjectByName("PredictedAdultFitnessId", data).value}${usingOrgSports ? "&using_org_sports=true" : ""}`, {
                withCredentials: true,
                cancelToken: cancelDownloadToken.token
            }).then(async function (res) {
                var sudoArray = [];

                for (var i = 0; i <= res.data.length - 1; i++) {
                    if (res.data[i].sport_type1 && res.data[i].sport_type2) {
                        sudoArray.push(
                            {
                                name: res.data[i].sport,
                                formattedName: res.data[i].sport,
                                imageUrl: res.data[i].image_path,
                                value: 0,
                                sport: res.data[i].sport,
                                sport_type1: res.data[i].sport_type1,
                                sport_type2: res.data[i].sport_type2
                            }
                        )
                    }
                }

                setTopFiveSports(sudoArray);
                resolve(sudoArray);
            });

            // Set the Anthropometry values (Anthropometry ChartJS graph)
            setHeight(getObjectByName("height", data).percentileRanking);
            setWeight(getObjectByName("weight", data).percentileRanking);
            setArmSpan(getObjectByName("armSpan", data).percentileRanking);
            setForearmLength(getObjectByName("forearmLength", data).percentileRanking);
            setSittingHeight(getObjectByName("sittingHeight", data).percentileRanking);
        })
    }

    useEffect(() => {
        getSportRankings();
    }, [topFiveSports]);

    useEffect(() => {
        var array = [
            { name: "power", value: powerSportTypeRank },
            { name: "skill", value: skillSportTypeRank },
            { name: "aerobic", value: aerobicSportTypeRank },
            { name: "strength", value: strengthSportTypeRank },
            { name: "game-fitness", value: gameFitnessSportTypeRank },
            { name: "speed", value: speedSportTypeRank }
        ];
        array = array.sort((a, b) => {
            return a.value - b.value
        });

        setSportTypeCategoryRankings(array);

    }, [powerSportTypeRank, skillSportTypeRank, aerobicSportTypeRank, strengthSportTypeRank, gameFitnessSportTypeRank, speedSportTypeRank]);

    useEffect(() => {
        cancelDownloadToken.cancel();
        cancelDownloadToken = Axios.CancelToken.source();
        setLoading(false);
    }, [
        reduxState.organisationState.genderFilters,
        JSON.stringify(reduxState.organisationState.groupNameFilters),
        reduxState.organisationState.dateRangeFromFilter,
        reduxState.organisationState.dateRangeToFilter,
        JSON.stringify(reduxState.organisationState.ageFilters),
    ]);

    // Button Handlers
    const buttonHandlerData = require('./ButtonHandlers.js');
    const downloadReportOnClickHandler = buttonHandlerData.generateReportPDF;

    // Sport Dataset
    const normDataset = require('../containers/screens/compareMyResults/data/sportsDataset.js');

    // Norms from Sport Dataset
    const sportNorms = normDataset.sportsNorms;

    return (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button onClick={() => {
                if (allowForButtonCancellationOnClick) {
                    cancelDownloadToken.cancel();
                    cancelDownloadToken = Axios.CancelToken.source();
                }

                getReportData().then((data) => {
                    getTop5Sports(data, true).then((top5Sports) => {
                        var arrayOfImageRefs = headerImages;

                        var anthropometryPercentages = {
                            height: parseFloat(height).toFixed(0) != 0 ? parseFloat(height).toFixed(0) + "%" : "--",
                            weight: parseFloat(weight).toFixed(0) != 0 ? parseFloat(weight).toFixed(0)  > 99 ? 99 + "%" : parseFloat(weight).toFixed(0) + "%" : "--",
                            armSpan: parseFloat(armSpan).toFixed(0) != 0 ? parseFloat(armSpan).toFixed(0) + "%" : "--",
                            sittingHeight: parseFloat(sittingHeight).toFixed(0) != 0 ? parseFloat(sittingHeight).toFixed(0) + "%" : "--",
                            brachialIndex: parseFloat(forearmLength).toFixed(0) != 0 ? parseFloat(forearmLength).toFixed(0) + "%" : "--",
                        }

                        var sportTypeRankings = {
                            powerRanking: (sportTypeCategoryRankings.findIndex(e => e.name == 'power') + 1),
                            strengthRanking: (sportTypeCategoryRankings.findIndex(e => e.name == 'strength') + 1),
                            speedRanking: (sportTypeCategoryRankings.findIndex(e => e.name == 'speed') + 1),
                            aerobicRanking: (sportTypeCategoryRankings.findIndex(e => e.name == 'aerobic') + 1),
                            gameFitnessRanking: (sportTypeCategoryRankings.findIndex(e => e.name == 'game-fitness') + 1),
                            skillRanking: (sportTypeCategoryRankings.findIndex(e => e.name == 'skill') + 1),
                        }

                        const logoBase64 = reduxState.organisationState.organisationLogo;

                        setLoading(true);

                        setTimeout(() => {
                            downloadReportOnClickHandler(
                                inputEl,
                                inputEl2,
                                data,
                                sportNorms,
                                top5Sports,
                                arrayOfImageRefs,
                                anthropometryImages,
                                anthropometryPercentages,
                                sportTypeRankings,
                                primaryColor,
                                secondaryColor,
                                accentColor,
                                usingImperial,
                                showNameInTitle,
                                logoBase64
                            );

                            setLoading(false);

                            if (typeof customCallback != 'undefined')
                                customCallback('Finished loading');
                        }, 500);
                    });
                })
            }} className={_className}>{title}</button>

            <a href={link}>
                <div style={{ display: 'none' }}>
                    <img ref={imageElement} style={{ width: '20px', height: '20px' }} src={""} />

                    <Radar ref={inputEl} height={350} width={350} legend={{ display: false }} data={{
                        labels: ['', '', '', '', '', ''],
                        datasets: [
                            {
                                label: 'My Second dataset',
                                backgroundColor: organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor, 0.3) : 'rgba(154, 98, 179, .3)',
                                borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgb(154, 98, 179)',
                                pointBackgroundColor: 'rgba(255,99,132,1)',
                                pointBorderColor: '#fff',
                                borderWidth: 1,
                                pointRadius: 0,
                                pointHoverBackgroundColor: '#fff',
                                pointHoverBorderColor: 'rgba(255,99,132,1)',
                                data: [powerSportTypeRank, strengthSportTypeRank, speedSportTypeRank, aerobicSportTypeRank, gameFitnessSportTypeRank, skillSportTypeRank],
                            }
                        ]
                    }}
                        options={{
                            responsive: false,
                            chartArea: { backgroundColor: 'green' },
                            scale: {
                                angleLines: {
                                    display: true,
                                },
                                pointLabels: {
                                    display: true,
                                    fontFamily: 'Dubai Regular'
                                },
                                ticks: {
                                    display: false,
                                    reverse: true,
                                    maxTicksLimit: 6,
                                    backdropColor: 'rgb(50, 168, 82)'
                                }
                            }
                        }} />

                    <Radar ref={inputEl2} height={350} width={350} legend={{ display: false }} data={{
                        labels: ['', '', '', '', ''],
                        datasets: [
                            {
                                label: 'My Second dataset',
                                backgroundColor: organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor, 0.3) : 'rgba(154, 98, 179, .3)',
                                borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgb(154, 98, 179)',
                                borderWidth: 1,
                                pointBackgroundColor: 'rgba(255,99,132,1)',
                                pointBorderColor: '#fff',
                                pointRadius: 0,
                                pointHoverBackgroundColor: '#fff',
                                pointHoverBorderColor: 'rgba(255,99,132,1)',
                                data: [
                                    parseFloat(height).toFixed(0),
                                    parseFloat(weight).toFixed(0),
                                    parseFloat(armSpan).toFixed(0),
                                    parseFloat(forearmLength).toFixed(0),
                                    parseFloat(sittingHeight).toFixed(0)
                                ]
                            }

                        ]
                    }}
                        options={{
                            responsive: false,
                            scale: {
                                angleLines: {
                                    display: true,
                                },
                                pointLabels: {
                                    display: false,
                                    fontFamily: 'Dubai Regular',
                                    fontSize: 14
                                },
                                ticks: {
                                    display: false,
                                    suggestedMin: 0,
                                    suggestedMax: 100,
                                    maxTicksLimit: 6,
                                    backdropColor: 'rgb(50, 168, 82)'
                                }
                            }
                        }} />
                </div>
            </a>

            <div className="export-pdf-loading-container" style={{ opacity: loading ? '1' : '0', right: isHeaderButton ? '-20px' : null }}>
                <CircularProgress disableShrink={true} size={20} />
            </div>
        </div>
    );
}

export const SidemenuButton = (title, event, currentScreen, changeToScreen) => {
    return (
        <div onClick={() => event(changeToScreen)} style={{ background: currentScreen === changeToScreen ? "#BDE0EF" : "" }} className="sidemenu-button">
            {title}
        </div>
    );
}
