import React, { useEffect } from "react"
import { useSelector } from "react-redux";

// const usingImperial = userState.userUOM == "Imperial"; 
import Axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AUS_NZ_NORM_NAME } from "../../../../store/constants";
import { LoadingReportEnum, useGenerateQASReport } from "./QASReportHelper";

const cancelDownloadToken = Axios.CancelToken.source();

export const QasReport = (props) => {
	const { loadingReport, doGenerateQASReport } = useGenerateQASReport();
	let test = props.test;
	let title = props.title;

	const userState = useSelector(state => state.user);
	const usingImperial = userState.userUOM == "Imperial";

	const normSelected = test.norm_selected || encodeURI(AUS_NZ_NORM_NAME);

	const requestQASReportParams = {
		test: test,
		normSelected: normSelected,
		usingImperial: usingImperial,
		downloadReport: true,
		sendAsEmail: false,
		cancelDownloadToken: cancelDownloadToken,
		refreshEmailLog: false
	}

	useEffect(() => {
		if (loadingReport === LoadingReportEnum.FINISHED_LOADING) {
			if (props.customCallback != null && typeof props.customCallback === 'function') {
				props.customCallback();
			}
		}
	}, [loadingReport]);

	return (
		<div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<button onClick={async () => doGenerateQASReport(requestQASReportParams)} className="download-button download-button-side">{title}</button>
			<div className="export-pdf-loading-container" style={{ opacity: loadingReport === LoadingReportEnum.LOADING ? '1' : '0' }}>
				<CircularProgress disableShrink={true} size={20} />
			</div>
		</div>
	)
}