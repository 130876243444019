import { BACKEND_URL, ENVIRONMENT_PATH } from "../store/constants";
import Axios from "axios";
import IM from 'convert-units';
import { CheckIfUserSessionIsValid } from "./Helper";
import { SendGridEmailRequest } from "./SendGrid/utilities";

export const LogoutUserEndpoint = () => {
    return new Promise((resolve, reject) => {
        fetch(BACKEND_URL + ENVIRONMENT_PATH + '/Logout', {
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include"
        }).then(res => resolve(res));
    })
}

export const SaveWeightings = (requestPayload: any) => {
    return new Promise((resolve, reject) => {
        fetch(BACKEND_URL + ENVIRONMENT_PATH + "/weightings", {
            method: 'POST',
            body: JSON.stringify(requestPayload),
            credentials: 'include'
        }).then(res => {
            res.json().then(result => {
                resolve(result);
            }).catch(err => console.log("An error occured: ", err));
        }).catch(err => console.log("An error occured: ", err));
    })
}

export const GetScoutingData = (data: any, cancelToken: any) => {
    const headers = {
        'Content-Type': 'text/plain'
    };

    return new Promise((resolve, reject) => {
        if (cancelToken) {
            Axios({
                method: 'post',
                url: BACKEND_URL + ENVIRONMENT_PATH + "/scouting",
                data: data,
                withCredentials: true,
                cancelToken: cancelToken.token,
                headers
            }).then(res => {
                resolve(res.data);
            });
        } else {
            fetch(BACKEND_URL + ENVIRONMENT_PATH + "/scouting", {
                method: 'POST',
                body: JSON.stringify(data),
                credentials: 'include'
            }).then(res => {
                res.json().then(result => {
                    resolve(result);
                }).catch(err => console.log("An error occured: ", err));
            }).catch(err => console.log("An error occured: ", err));
        }
    });
}

export const GetGroupReport = (params: any, cancelToken: any) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/groupreport`, {
            withCredentials: true,
            params: params,
            cancelToken: cancelToken.token
        }).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    })
}

export const GetTalentIDFromTable = (pageSizeParameter: any, filterBody: any, cancelToken: any) => {
    return new Promise((resolve, reject) => {
        if (cancelToken) {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/gettiofast${pageSizeParameter}`, {
                withCredentials: true,
                params: filterBody,
                cancelToken: cancelToken.token
            }).then(res => {
                resolve(res);
            })
        } else {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/gettiofast${pageSizeParameter}`, {
                withCredentials: true,
                params: filterBody
            }).then(res => {
                resolve(res);
            })
        }

    })
}

export const GetTalentIDData = (saveToDB: any, filters: any, cancelToken: any) => {
    return new Promise((resolve, reject) => {
        if (cancelToken) {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentID`, {
                withCredentials: true,
                params: filters,
                cancelToken: cancelToken.token
            }).then(res => {
                resolve(res);
            })
        } else {
            Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/TalentID`, {
                withCredentials: true,
                params: filters
            }).then(res => {
                resolve(res);
            })
        }
    })
}

export const UpdateStudentDetails = (data: any) => {
    const headers = {
        'Content-Type': 'text/plain'
    };

    return new Promise((resolve, reject) => {
        Axios(BACKEND_URL + ENVIRONMENT_PATH + '/UserDetails', {
            method: 'post',
            data: data,
            withCredentials: true,
            headers
        }).then(res => resolve(res));
    })
}

export const UpdateGlobalWeightings = (data: any, cancelToken: any) => {
    const headers = {
        'Content-Type': 'text/plain'
    };

    return new Promise((resolve, reject) => {
        Axios({
            method: 'post',
            url: BACKEND_URL + ENVIRONMENT_PATH + "/globalconfig",
            data: data,
            withCredentials: true,
            cancelToken: cancelToken.token,
            headers
        }).then(res => resolve(res));
    })
}

export const GetGlobalWeightings = (cancelToken: any) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/globalconfig`, {
            withCredentials: true,
            cancelToken: cancelToken.token
        }).then(res => {
            resolve(res);
        });
    })
}

export const GetStatusOnRefresh = (cancelToken: any) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/refreshstoredstats?poll_status=true`, {
            withCredentials: true,
            cancelToken: cancelToken.token
        }).then(res => {
            resolve(res);
        });
    })
}

export const requestTemplateType = () => {
    return new Promise((resolve, reject) => {
        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/GetExportTemplate`, {
            method: 'GET',
            credentials: 'include',
        }).then(res => {
            res.json().then(result => {
                resolve(result);
            }).catch(() => {
                reject();
            })
        }).catch(() => {
            reject();
        });
    });
}

export interface QASReportTestRequest {
    test_id: string;
    user_group: string;
    user_gender: string;
    user_age: string;
    user_email?: string;
}

export interface QASMetric {
    value: string;
    percentileRanking: string;
}

export interface QASReportDataResponse {
    age: string;
    username: string;
    dob: string;
    venue: string;
    testDate: string;
    height: QASMetric;
    arm_span: QASMetric;
    sitting_height: QASMetric;
    vertical_jump: QASMetric;
    inclined_pull_ups: QASMetric;
    shuttle_run: QASMetric;
    sprint: QASMetric;
    beep_test: QASMetric;
}

export const requestBackendQasReportData = (test: QASReportTestRequest, normSelected: string, usingImperial: boolean, cancelToken: any): Promise<QASReportDataResponse> => {
    return new Promise((resolve, reject) => {
        Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/Reports?&getTest=${test.test_id}&reports=true&gender=${test.user_gender}&name=${normSelected}&age=${test.user_age}`, {
            withCredentials: true,
            cancelToken: cancelToken.token
        }).then(res => {
            var dataToUse = res.data[0];
            var percentileData = res.data[1];

            var testDate = new Date(dataToUse.date_of_test);
            var dob = new Date(dataToUse.dob);

            const obj: QASReportDataResponse = {
                age: (Math.floor(dataToUse.user_age).toString()) ?? '',
                username: dataToUse.user_name ?? '',
                dob: isNaN(Date.parse(dataToUse.dob)) ? '' : String(dob.getMonth() + 1).padStart(2, '0') + '/' + dob.getFullYear(),
                venue: dataToUse.group ? dataToUse.group : test.user_group,
                testDate: isNaN(Date.parse(dataToUse.date_of_test)) ? '' : String(testDate.getDate()).padStart(2, '0') + '/' + String(testDate.getMonth() + 1).padStart(2, '0') + '/' + testDate.getFullYear(),
                height: {
                    value: dataToUse.height !== '' ? (usingImperial ? IM(dataToUse.height).from('cm').to('in') : dataToUse.height) + ' cm' : '',
                    percentileRanking: percentileData.height ? percentileData.height : '',
                },
                arm_span: {
                    value: dataToUse.arm_span != -1 ? dataToUse.arm_span + ' cm' : '',
                    percentileRanking: percentileData.arm_span ? percentileData.arm_span : '0',
                },
                sitting_height: {
                    value: dataToUse.sitting_height !== -1 ? dataToUse.sitting_height + ' cm' : '',
                    percentileRanking: percentileData.sitting_height_ration ? percentileData.sitting_height_ration : '0',
                },
                vertical_jump: {
                    value: dataToUse.vertical_jump != -1 ? (usingImperial ? IM(dataToUse.vertical_jump).from('cm').to('in') : parseFloat(dataToUse.vertical_jump).toFixed(1)) + ' cm' : '',
                    percentileRanking: percentileData.power ? percentileData.power : '',
                },
                inclined_pull_ups: {
                    value: dataToUse.inclined_pull_ups != -1 ? dataToUse.inclined_pull_ups : '',
                    percentileRanking: percentileData.pullup ? percentileData.pullup : '',
                },
                shuttle_run: {
                    value: dataToUse.shuttle_run != -1 ? dataToUse.shuttle_run + ' sec' : '',
                    percentileRanking: percentileData.shuttle ? percentileData.shuttle : '',
                },
                sprint: {
                    value: dataToUse.sprint_time !== '' && dataToUse.sprint_time !== -1 ? dataToUse.sprint_time + ' sec' : '',
                    percentileRanking: percentileData.speed ? percentileData.speed : '',
                },
                beep_test: {
                    value: dataToUse.beep_test != null && dataToUse.beep_test !== '-1' ? dataToUse.beep_test + "" : '',
                    percentileRanking: percentileData.endurance ? percentileData.endurance : '',
                },
            };

            // return obj;
            resolve(obj);
        });
    });
}

export const SendEmail = async (testId: string, data: SendGridEmailRequest, cancelToken: any) => {
    return new Promise((resolve, reject) => {
        // TODO: Add handling for successful response
        // TODO: Add handling for error
        Axios({
            method: 'post',
            url: BACKEND_URL + ENVIRONMENT_PATH + "/SendEmail?test_id=" + testId,
            data: data,
            withCredentials: true,
            cancelToken: cancelToken.token,
            headers: {
                'Content-Type': 'text/plain'
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })

}

export const RetrieveQASUserInfo = async (data: any, cancelToken: any) => {
    return new Promise((resolve, reject) => {
        Axios({
            method: 'post',
            url: BACKEND_URL + ENVIRONMENT_PATH + "/GetUserInfoForQAS",
            data: { studentIds: data },
            withCredentials: true,
            cancelToken: cancelToken.token,
            headers: {
                'Content-Type': 'text/plain'
            }
        }).then(response => {
            if (response.data != null) {
                resolve(response.data);
            } else {
                resolve(response);
            }
        }).catch(err => {
            reject(err);
        })
    })
}

export const DownloadEmailSendLog = () => {
    fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/downloadqasemaillog`, {
        method: "GET",
        credentials: "include",
    }).then(res => {
        res.text().then(res => {
            let data = `data:text/csv;charset=utf-8,${res}`;

            const encodedUri = encodeURI(data);

            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "email_send_logs.csv");

            document.body.appendChild(link);

            link.click();
        })
    })
}

export const StartQueue = async (requestPayload: any, cancelToken: any) => {
    return new Promise((resolve, reject) => {
        Axios({
            method: 'POST',
            url: `${BACKEND_URL}${ENVIRONMENT_PATH}/CreateQueue`,
            data: requestPayload,
            params: {thing: "true"},
            withCredentials: true,
            cancelToken: cancelToken.token,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}