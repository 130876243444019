import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';

export const CompareNorms = ({
    onChangeHandler = () => {},
    isLoading = false,
    selectedNorm = "world"
}) => {
    return (
        <div className="compare-norms" style={{ marginLeft: 'auto' }}>
            <div className="norm-selector-container">
                {isLoading && <CircularProgress disableShrink size={20} />}
                <div className="norm-selector">
                    <label>Compare norms</label>
                    <select onChange={onChangeHandler}>
                        <option value="world" selected={selectedNorm === "world" ? true : false}>
                            World
                        </option>
                        <option value="australian and nz" selected={selectedNorm === "australian and nz" ? true : false}>
                            Australian and NZ
                        </option>
                        <option value="european" selected={selectedNorm === "european" ? true : false}>
                            European
                        </option>
                        <option value="american" selected={selectedNorm === "american" ? true : false}>
                            American
                        </option>
                        <option value="singapore" selected={selectedNorm === "singapore" ? true : false}>
                            Singapore
                        </option>
                    </select>
                </div>
            </div>
        </div>
    )
}