import React from "react";

const SittingHeight3Svg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>sitting_height_3</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="sitting_height_3" data-name="sitting height 3">
            <circle className="cls-2" style={{fill: color}} cx="297.59" cy="104.55" r="38.59"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M306.79,325.58" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M296.41,327.28" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M408.21,354.78H384.83V73.13h23.38a5,5,0,1,0,0-10H351.46a5,5,0,0,0,0,10h23.37V354.78H351.46a5,5,0,0,0,0,10h56.75a5,5,0,1,0,0-10Z"
                  transform="translate(-2.83 -7.46)"/>
            <rect className="cls-2" style={{fill: color}} x="195.7" y="360.12" width="143.95" height="120.41" rx="18.85" ry="18.85"/>
            <path className="cls-2" style={{fill: color}}
                  d="M278.8,168.86,197.09,308.13a15.78,15.78,0,0,0-1.36,12.81,19.1,19.1,0,0,0,8.7,10.82c8.7,5.11,19.57,2.81,24.22-5.11l81.71-139.27a15.81,15.81,0,0,0,1.36-12.81,19.1,19.1,0,0,0-8.7-10.82C294.31,158.64,283.45,160.94,278.8,168.86Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M328.87,185.27c5.33-20.67-22.79-40.59-38.53-27a22.57,22.57,0,0,1,24.25,15.46A18.82,18.82,0,0,1,313,188.9l-58.29,99.34a275,275,0,0,0,4,29.15H237.56l-6.32,10.78a19.38,19.38,0,0,1-17,9.31,22.5,22.5,0,0,1-11.38-3.13,22.13,22.13,0,0,1-10-12.53,20.32,20.32,0,0,1-.83-4.43H171.89a22.5,22.5,0,0,0-22.5,22.5V451.33a22.5,22.5,0,1,0,45,0V362.39h95.27c1,0,2,0,2.9,0h.52c.49,0,1,0,1.44-.05l.25,0a22.29,22.29,0,0,0,4.66-.84,34.79,34.79,0,0,0,18.47-11.09c.65-.71,1.27-1.47,1.87-2.25.11-.13.21-.27.32-.41.49-.64,1-1.31,1.42-2l.24-.33.15-.24q.36-.56.72-1.14c.17-.29.35-.57.53-.87l.67-1.19c.16-.3.33-.59.49-.9s.43-.83.64-1.25l.45-.91.6-1.33c.14-.3.27-.6.4-.9.2-.47.39-.95.59-1.43.11-.29.23-.57.34-.86.19-.51.38-1,.56-1.53.1-.27.2-.54.29-.82.19-.54.36-1.09.54-1.64.08-.25.16-.49.23-.74.18-.59.35-1.18.51-1.78.06-.21.13-.43.19-.65.17-.63.33-1.27.48-1.91,0-.18.09-.36.13-.54.16-.68.31-1.37.45-2l.09-.43c.15-.73.28-1.46.41-2.19l.06-.31c.13-.76.25-1.53.35-2.3a1.52,1.52,0,0,0,0-.21c.12-.8.21-1.61.3-2.41a.34.34,0,0,0,0-.1c.09-.83.17-1.66.23-2.49v0q.23-2.91.24-5.76C332.58,280,310.24,257.57,328.87,185.27Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default SittingHeight3Svg;
