// REACTJS
import { CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { sortAndDeduplicateDiagnostics } from 'typescript';
import { GetStatusOnRefresh } from '../../../../../../api/endpoints';
import { setPendingRefreshCompletion } from '../../../../../../store/actions/OrganisationActions';

let cancelToken = Axios.CancelToken.source();

const RefreshDataIndicator = () => {
    const MAXIMUM_VALUE = 100;

    const interval = useRef(null);
    const organisationState = useSelector(state => state.organisationState);
    const [currentRefreshValue, setCurrentRefreshValue] = useState(0);
    const dispatch = useDispatch();

    // React Toast Notifications Object
    const { addToast } = useToasts();

    useEffect(() => {
        if (organisationState && organisationState.pendingRefreshCompletion) {
            interval.current = setInterval(() => {
                cancelToken.cancel();
                cancelToken = Axios.CancelToken.source();

                GetStatusOnRefresh(cancelToken).then(res => {
                    if (res && res.data && res.data.completion_status) {
                        var progress = parseFloat(res.data.completion_status) * 100;

                        setCurrentRefreshValue(parseFloat(progress).toFixed(2));
                        if (res.data.completion_status == -1) {
                            dispatch(setPendingRefreshCompletion(false));
                            clearInterval(interval.current);
                            addToast("Refreshed data successfully", {
                                appearance: 'success',
                                autoDismiss: true
                            });
                            setCurrentRefreshValue(0);
                        }
                    }
                })

            }, 3000)
        }
    }, [organisationState.pendingRefreshCompletion]);

    useEffect(() => () => {
        clearInterval(interval.current);
    }, []);

    return (
        <div style={{ display: organisationState.pendingRefreshCompletion ? "flex" : "none", alignItems: 'center' }}>
            <span>Refresh in progress: {currentRefreshValue}% / {MAXIMUM_VALUE}%</span>
            <CircularProgress disableShrink size={20} />
        </div>
    )
}

export default RefreshDataIndicator;