import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';

// Field Info
import { fieldInfo } from './fieldInfoContent.js';

// COMPONENTS
import { MyDetailsHeader } from '../../../components/MyDetailsHeader';
import {
    UserDefinedTextField,
    SliderField,
    RadioField
} from '../../../components/FormInputs.js';

// ACTIONS
import { setSitAndReach, setExpandedFlexibility, setUserInputSitAndReach } from '../../../store/actions/myDetails.js';

// ICONS
import SitReachSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/sitReachSvg.js';

const Flexibility = ({ infoOpen, setInfoOpen, fieldChanged, resetBtnHandler }) => {
    const state = useSelector(store => store.myDetails);
    const sportMatchState = useSelector(store => store.sportMatchTest);
    const flexibilitySectionExpanded = state.flexabilitySectionExpanded;
    const dispatch = useDispatch();
    const organisationStateLocal = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);
    
    // Local State
    const [expanded, setExpanded] = useState(false);

    // Functions enabling child components to dispatch actions
    const dispatchSitAndReach = (input) => {
        dispatch(setUserInputSitAndReach(input))
    }

    const expand = () => {
        dispatch(setExpandedFlexibility(!flexibilitySectionExpanded));
        setExpanded(!expanded);
    }

    const getFieldsChanged = () => {
        if(sportMatchState.userInputSitAndReach != "" && state.sitAndReach == "") {
            return true;
        } else {
            return false;
        }
    }

    const resetButtonHandler = () => {
        if(state.sitAndReach == "") {
            dispatch(setUserInputSitAndReach(""));
        }
    }

    const sitReachIcon = () => {
        return <SitReachSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'} />;
    }

    return (
        <div className="screen-section test-section">
            <MyDetailsHeader
                children={[{supplied: sportMatchState.userInputSitAndReach !== ''}]}
                title={"Flexibility"}
                icon={sitReachIcon()}
                isIconSvg={false}
                expand={expand}
                expanded={true}
                fieldChanged={getFieldsChanged()}
                resetBtnHandler={resetButtonHandler} />
            <div className={`myDetails-fields expanded`}>
                <SliderField
                    action={dispatchSitAndReach}
                    label={"Sit & reach"}
                    labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}  
                    placeholder={"cm"}
                    info={userState.userUOM == "Imperial" ? fieldInfo.sitAndReach_imperial : fieldInfo.sitAndReach}
                    enteredValue={sportMatchState.userInputSitAndReach}
                    minValue={0}
                    maxValue={60}
                    incrementalValue={userState.userUOM == "Imperial" ? 0.01 : 0.5}
                    infoOpen={infoOpen}  setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.sitAndReach != ""}
                />
            </div>
        </div>
    );
}

export default Flexibility;
