import React, { useState, useEffect } from 'react';

// COMPONENTS
import FieldInfo from './FieldInfo.js';

// MATERIALUI
import { NativeSelect, Select, MenuItem, Button, TextField } from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// MOMENTJS
import moment from 'moment';

// Actions
import {
    setUserBirthMonth,
    setUserBirthYear,
    setTestDateDay,
    setTestDateMonth,
    setTestDateYear,
    setUserAge,
    setUserBirthDay
} from '../store/actions/actions.js';

// CONSTANTS
import {
    B2C_ROLE_NAME,
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../store/constants';

import styled from 'styled-components';
import { ConvertToFeetAndInches, ConvertToFeetAndInchesAndGetFeet, ConvertToFeetAndInchesAndGetInches } from '../helper/helper.js';
import { render } from '@testing-library/react';

// MATERIAL UI STYLES
const useStyles = makeStyles(theme => ({
    outlined: {
        padding: '2px 15px'
    }
}));

const IM = require("convert-units");

/** Support multiple timezones? */
const renderDateValuesForBirthDates = (type) => {
    var domElem = [];

    if (type === 'Year') {
        // Render Year values
        var yearToDeduct = 0;
        var yearToRender = new Date().getFullYear() - yearToDeduct;

        for (var i = 0; i <= 80; i++) {
            domElem.push(<MenuItem value={yearToRender}>{yearToRender}</MenuItem>)
            yearToRender -= 1;
        }
    } else if (type === "Month") {
        // Render Month values
        for (var i = 0; i < 12; i++) {
            var monthName = moment().month(i).format("MMMM");
            domElem.push(<MenuItem value={i}>{monthName}</MenuItem>)
        }
    } else {
        // Render Day values
        for (var i = 0; i < 31; i++) {
            domElem.push(<MenuItem value={i + 1}>{i + 1}</MenuItem>)
        }
    }
    return domElem;
};

/** Support multiple timezones? */
const renderDateValuesForTestDate = (type, state) => {
    var domElem = [];

    if (type === 'Year') {
        // Render Year values
        var yearToDeduct = 0;
        var yearToRender = new Date().getFullYear() - yearToDeduct;

        for (var i = 0; i <= 11; i++) {
            domElem.push(<MenuItem value={yearToRender}>{yearToRender}</MenuItem>)
            yearToRender -= 1;
        }
    } else if (type === "Month") {
        // Render Month values
        for (var i = 0; i < 12; i++) {
            domElem.push(<MenuItem value={i + 1}>{i + 1}</MenuItem>)
        }
    } else {
        // Render Day values
        var maximumNumberOfDaysInMonth = 31;
        if (state.sportMatchTest.sportMatchTestDateMonth !== '') {

            if (state.sportMatchTest.sportMatchTestDateMonth === 2) {
                maximumNumberOfDaysInMonth = 29;
            }
            if (state.sportMatchTest.sportMatchTestDateMonth === 4) {
                maximumNumberOfDaysInMonth = 30;
            }
            if (state.sportMatchTest.sportMatchTestDateMonth === 6) {
                maximumNumberOfDaysInMonth = 30;
            }
            if (state.sportMatchTest.sportMatchTestDateMonth === 9) {
                maximumNumberOfDaysInMonth = 30;
            }
            if (state.sportMatchTest.sportMatchTestDateMonth === 11) {
                maximumNumberOfDaysInMonth = 30;
            }
        }
        for (var i = 0; i < maximumNumberOfDaysInMonth; i++) {
            domElem.push(<MenuItem value={i + 1}>{i + 1}</MenuItem>)
        }
    }
    return domElem;
};

export const SetToTodayTestDateButton = () => {
    const dispatch = useDispatch();
    return (
        <Button
            onClick={() => {
                // Set the date to today
                var dateMoment = moment(new Date(), 'DD/MM/YYYY');

                var day = dateMoment.date();
                var month = dateMoment.month() + 1;
                var year = dateMoment.year();

                dispatch(setTestDateDay(day));
                dispatch(setTestDateMonth(month));
                dispatch(setTestDateYear(year));
            }}
            style={{
                background: 'rgba(29, 179, 12)',
                borderRadius: '30px',
                paddingLeft: '25px',
                paddingRight: '25px'
            }}
            color={'primary'}
            variant={'contained'}
        >Set to today
        </Button>
    )
}

/**
 * TestDateDropdownFields
 *
 * Hosts the component for drop down date fields under the "Test date" section
 * (on the "Sport Match Test" screen)
 **/
export const TestDateDropdownFields = ({ _value, label, placeholder }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    return (
        <div className="myDetails-field test-date-field">
            <div className="test-date-container">
                <Select
                    onChange={(e) => dispatch(setTestDateDay(e.target.value))}
                    data-type={"Day"}
                    value={state.sportMatchTest.sportMatchTestDateDay}
                    displayEmpty
                    renderValue={selected => {
                        if (selected) {
                            return selected;
                        } else {
                            return <span>Day</span>;
                        }
                    }}>
                    <MenuItem disabled value="">
                        <span>Day</span>
                    </MenuItem>
                    {renderDateValuesForTestDate("Day", state)}
                </Select>
                <span className="test-dropdown-separator">/</span>
                <Select
                    onChange={(e) => dispatch(setTestDateMonth(e.target.value))}
                    data-type={"Month"}
                    value={state.sportMatchTest.sportMatchTestDateMonth}
                    displayEmpty
                    renderValue={selected => {
                        if (selected) {
                            return selected;
                        } else {
                            return <span>Month</span>;
                        }
                    }}>
                    <MenuItem disabled value="">
                        <span>Month</span>
                    </MenuItem>
                    {renderDateValuesForTestDate("Month", state)}
                </Select>
                <span className="test-dropdown-separator">/</span>
                <Select
                    onChange={(e) => dispatch(setTestDateYear(e.target.value))}
                    data-type={label}
                    value={state.sportMatchTest.sportMatchTestDateYear}
                    displayEmpty
                    renderValue={selected => {
                        if (selected) {
                            return selected;
                        } else {
                            return <span>Year</span>;
                        }
                    }}>
                    <MenuItem disabled value="">
                        <span>Year</span>
                    </MenuItem>
                    {renderDateValuesForTestDate("Year", state)}
                </Select>
            </div>

            <SetToTodayTestDateButton />
        </div>
    )
}

export const BirthDropdownField = ({ _value, label, placeholder, onChangeHandler }) => {

    const userState = useSelector(state => state.user);
    const organisationLocalState = useSelector(state => state.organisationState);

    const [localBirthMonth, setLocalBirthMonth] = useState(userState.userBirthMonth || "0");
    const [localBirthYear, setLocalBirthYear] = useState(userState.userBirthYear || "");
    const [localBirthDay, setLocalBirthDay] = useState(userState.userBirthDay || "");

    const usingB2CAthlete = userState.userRole == B2C_ROLE_NAME;

    const retrieveJSONOfUser = () => {
        var jsonRequest = "";
        var userGender = userState.userGender || "unspecified";
        var userFirstName = userState.userId || "";
        var userClassName = userState.userClassName || "";

        jsonRequest = {
            "user_id": typeof userState.userBackendId !== 'undefined' && userState.userBackendId !== "" ? userState.userBackendId : '2' + "", //field used to update the specific row in the db
            "username": userFirstName + "",
            "user_birth_month": localBirthMonth + "",
            "user_birth_year": localBirthYear + "",
            'gender': userGender + "",
            "user_class_name": userClassName + ""
        }

        if(localBirthDay && localBirthDay != "" && localBirthDay != "-1") {
            jsonRequest.user_birth_day = localBirthDay + "";
        }

        return jsonRequest;
    }

    useEffect(() => {
        if (localBirthMonth != "-1" && localBirthYear != "-1") {
            saveUser();
        }
    }, [localBirthDay, localBirthMonth, localBirthYear]);

    const saveUser = () => {
        var jsonRequest = retrieveJSONOfUser();

        fetch(BACKEND_URL + ENVIRONMENT_PATH + '/UserDetails', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(jsonRequest)
        }).then(async function (res) {
            res.json().then((data) => {
                if (!data.error) {
                    dispatch(setUserAge(data.age + ""));
                }
            }).catch(error => {
                console.log("An error occured: ", error);
            })
        })
    }

    const dispatch = useDispatch();
    const state = useSelector(state => state);

    var userMonth = state.user.userBirthMonth === -1 ? 0 : state.user.userBirthMonth;

    var duration;

    const renderAge = () => {
        if (state.myDetails.testDate != "") {
            if(state.user.userBirthYear != -1) {
                var dob = moment((userMonth + 1) + `${state.user.userBirthDay != -1 ? '/' + state.user.userBirthDay + '/' : '/01/'}` + state.user.userBirthYear);
                
                // to avoid rounding errors, make test date always be at the start of the month
                var testDate = new Date(state.myDetails.testDate);
                testDate.setDate(1);
    
                var age = moment.duration(moment(testDate).diff(dob)).asYears();
                duration = age.toFixed(2) + " years";
                return parseFloat(duration).toFixed(2) + " years";
            } else {
                return ""
            }
        } else if (state.user.userAge != "") {
            return parseFloat(state.user.userAge).toFixed(2) + " years";
        } else if (state.user.userBirthMonth !== -1 && state.user.userBirthYear !== -1) {
            var dob = moment((userMonth + 1) + `${state.user.userBirthDay != -1 ? '/' + state.user.userBirthDay + '/' : '/01/'}` + state.user.userBirthYear);
            
            // current date ignores day-of-month
            var currentDate = new Date();

            if(state.user.userBirthDay != -1) {
                currentDate.setDate(state.user.userBirthDay);
            } else {
                currentDate.setDate(1);
            }

            var age = moment.duration(moment(currentDate).diff(dob)).asYears();
            duration = (age - 0.1).toFixed(2) + " years";
            return duration;
        }
    }

    const useStyles = makeStyles({
        icon: {
            background: organisationLocalState.organisationPrimaryColor != "" ? organisationLocalState.organisationPrimaryColor + "!important" : "#9a62b3 !important"
        },
    });

    const classes = useStyles();

    return (
        <div className={"myDetails-field dob-field " + (usingB2CAthlete ? 'day_included' : '')}>
            <div className="myDetails-field-title">
                Date of birth&nbsp;<span className="myDetails-secondary-text">{usingB2CAthlete ? "(day/month/year)" : "(month/year)"}</span>
            </div>
            {usingB2CAthlete ?
                <>
                    <Select
                        onChange={(e) => {
                            dispatch(setUserBirthDay(e.target.value));
                            setLocalBirthDay(e.target.value)
                        }}
                        data-type={"Day"}
                        value={state.user.userBirthDay}
                        displayEmpty
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                            },
                        }}
                        renderValue={selected => {
                            if (selected && state.user.userBirthDay !== -1) {
                                return selected;
                            } else {
                                return <span>Day</span>;
                            }
                        }}>
                        <MenuItem disabled value="">
                            <span>Day</span>
                        </MenuItem>
                        {renderDateValuesForBirthDates("Day")}
                    </Select>
                    <span className="birth-dropdown-separator">/</span>
                </> : null}

            <Select
                onChange={(e) => {
                    dispatch(setUserBirthMonth(e.target.value));
                    setLocalBirthMonth(e.target.value)
                }}
                data-type={"Month"}
                value={moment().month(userMonth).format("MMMM")}
                displayEmpty
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                renderValue={selected => {
                    if (selected && state.user.userBirthMonth !== -1) {
                        return selected;
                    } else {
                        return <span>Month</span>;
                    }
                }}>
                <MenuItem disabled value="">
                    <span>Month</span>
                </MenuItem>
                {renderDateValuesForBirthDates("Month")}
            </Select>
            <span className="birth-dropdown-separator">/</span>
            <Select
                onChange={(e) => {
                    dispatch(setUserBirthYear(e.target.value));
                    setLocalBirthYear(e.target.value);
                }}
                data-type={label}
                value={state.user.userBirthYear}
                displayEmpty
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                renderValue={selected => {
                    if (selected && state.user.userBirthYear !== -1) {
                        return selected;
                    } else {
                        return <span>Year</span>;
                    }
                }}>
                <MenuItem disabled value="">
                    <span>Year</span>
                </MenuItem>
                {renderDateValuesForBirthDates("Year")}
            </Select>

            <div className="no-of-years" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '' }}>
                <span>
                    {renderAge()}
                </span>
            </div>
        </div>
    )
}

export const WalkRunTextField = ({ infoOpen, setInfoOpen: setInfoOpen, minuteValue, secondValue, label, action, info, confirmedValueProvided }) => {
    // const [infoOpen, setInfoOpen] = useState(false);
    const [labelVal, setLabelVal] = useState(label)
    const [minute, setMinute] = useState(minuteValue ? minuteValue : '');
    const [second, setSecond] = useState(secondValue ? secondValue : '');
    const organisationLocalState = useSelector(state => state.organisationState);

    useEffect(() => {
        if (minuteValue + "" == "") {
            setMinute("");
        }
    }, [minuteValue]);

    useEffect(() => {
        if (secondValue + "" == "") {
            setSecond("");
        }
    }, [secondValue]);

    // Renders the digits to allow them to be compatible with both option tags and values from state
    const renderDigit = (num) => {
        if (num < 10) {
            return "0" + num;
        } else {
            return "" + num;
        }
    }

    // Renders the option values for both minute and seconds
    const renderValues = () => {
        var domElement = [];

        for (var i = 0; i < 60; i++) {
            domElement.push(
                <option value={renderDigit(i)}>{i}</option>
            )
        }

        return domElement;
    }


    const MyNativeSelect = withStyles({
        icon: {
            background: organisationLocalState.organisationPrimaryColor ? (organisationLocalState.organisationPrimaryColor + " !important") : "#9a62b3 !important"
        }
    })(NativeSelect);

    return (
        <div className="myDetails-field drop-down-field">
            <div className="myDetails-field-title">
                {label}
                {info ? <div onClick={() => infoOpen === labelVal ? setInfoOpen("") : setInfoOpen(labelVal)} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i className="fa fa-info-circle"></i><div style={{ display: infoOpen === labelVal ? 'block' : 'none' }} className="myDetails-info-pointer"></div></div> : ""}
                {info && infoOpen === labelVal ? <FieldInfo info={info} /> : ""}
            </div>

            <MyNativeSelect
                onChange={action ? (e) => { action(e.target.value, 'min'); setMinute(e.target.value); } : null}
                data-type={"minute"}
                value={minute}
                displayEmpty
                disabled={confirmedValueProvided}
                renderValue={selected => {
                    if (selected) {
                        return selected;
                    } else {
                        return <span>&ensp;</span>;
                    }
                }}>
                {renderValues()}
            </MyNativeSelect>

            <span className="walk-run-colon">:</span>

            <MyNativeSelect
                onChange={action ? (e) => { action(e.target.value, 'sec'); setSecond(e.target.value); } : null}
                data-type={"second"}
                value={second}
                displayEmpty
                disabled={confirmedValueProvided}
                renderValue={selected => {
                    if (selected) {
                        return selected;
                    } else {
                        return <span>&ensp;</span>;
                    }
                }}>
                {renderValues()}
            </MyNativeSelect>
            <div className="minutesSecondsLabel">
                <span>minutes : seconds</span>
            </div>
        </div>
    );
}

export const UserDefinedTextField = ({ infoOpen, setInfoOpen: setInfoOpen, _value, label, placeholder, action, info, uom, disabled }) => {
    // const [infoOpen, setInfoOpen] = useState(false);
    const [value, setValue] = useState(_value);
    const [labelVal, setlabelVal] = useState(label);
    const organisationLocalState = useSelector(state => state.organisationState);

    return (
        <div className="myDetails-field">
            <div className="myDetails-field-title">
                {label}
                {uom ? <span className="label-uom">{"(" + uom + ")"}</span> : null}
                {info ? <div onClick={() => infoOpen === labelVal ? setInfoOpen("") : setInfoOpen(labelVal)} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i className="fa fa-info-circle"></i><div style={{ display: infoOpen === labelVal ? 'block' : 'none' }} className="myDetails-info-pointer"></div></div> : ""}
                {info && infoOpen === labelVal ? <FieldInfo info={info} /> : ""}
            </div>
            <TextField 
                disabled={disabled} 
                onChange={action ? (e) => { action(e.target.value); setValue(e.target.value); } : null} 
                value={value} 
                placeholder={placeholder} 
                size={'small'} 
                id="filled-basic"
                variant="filled"
            />
        </div>
    );
}

/** Controls for judging the test takers Speed (via calculating with Distance and Time) */
export const UserDefinedSprintTestControls = ({ infoOpen, setInfoOpen: setInfoOpen, distanceValue, timeValue, localDistanceValue, localTimeValue, setLocalDistanceValue, setLocalTimeValue, info, actionDistance, actionTime, distanceMinimum, distanceMaximum, minValue, maxValue, confirmedValueProvided }) => {
    const organisationLocalState = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    const FaderInput = styled.input`
         &::-webkit-slider-thumb {
             background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }
         &::-moz-range-thumb {
            background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }`;

    return (
        <div className="myDetails-field speed-controls">
            <div className="myDetails-field-title">
                Sprint
                {info ?
                    <div onClick={() => infoOpen === "Sprint" ? setInfoOpen("") : setInfoOpen("Sprint")} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>
                        <i className="fa fa-info-circle"></i>
                        <div style={{ display: infoOpen === "Sprint" ? 'block' : 'none' }} className="myDetails-info-pointer"></div>
                    </div>
                    : null}
                {info && infoOpen === "Sprint" ? <FieldInfo info={info} /> : ""}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                {/* Distance control */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
                    <span className="test-label-span distance-label">Distance <span className="test-uom">{userState.userUOM == "Imperial" ? "(yd)" : "(m)"} </span></span>
                    <input
                        onMouseUp={actionDistance ? (e) => actionDistance(e.target.value) : null}
                        onTouchEnd={actionDistance ? (e) => actionDistance(e.target.value) : null}
                        onChange={(e) => {
                            if (e.target.value < 0) {
                                alert('Entered number cannot be negative.');
                            }
                            else if (e.target.value > distanceMaximum) {
                                alert('Entered number cannot be higher than ' + distanceMaximum);
                            } else {
                                setLocalDistanceValue(parseFloat(e.target.value).toFixed(2));
                            }
                        }}
                        disabled={confirmedValueProvided}
                        className={confirmedValueProvided ? "myDetails-field-slider distance-control disabled" : "myDetails-field-slider distance-control"}
                        type="range"
                        min={distanceMinimum}
                        max={distanceMaximum}
                        value={localDistanceValue} step={userState.userUOM == "Imperial" ? 0.001 : 5} />
                    <input
                        onChange={(e) => {
                            if (e.target.value < 0) {
                                alert('Entered number cannot be negative.');
                            }
                            else if (e.target.value > distanceMaximum) {
                                alert(`${e.target.value} cannot be higher than ` + distanceMaximum);
                            } else {
                                setLocalDistanceValue(e.target.value);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 189) {
                                e.preventDefault();
                            }
                        }}
                        disabled={confirmedValueProvided}
                        min={parseFloat(distanceMinimum)}
                        max={parseFloat(distanceMaximum)}
                        step={0.5}
                        onBlur={actionDistance ? (e) => actionDistance(e.target.value) : null}
                        className="myDetails-field-slider-value parent-control"
                        type="number" value={localDistanceValue} />
                </div>

                {/** Time Control */}
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px' }}>
                    <span className="test-label-span time-label">Time <span className="test-uom">(s)</span></span>
                    <input
                        onMouseUp={actionTime ? (e) => actionTime(e.target.value) : null}
                        onTouchEnd={actionTime ? (e) => actionTime(e.target.value) : null}
                        onChange={(e) => {
                            if (e.target.value < 0) {
                                alert('Entered number cannot be negative.');
                            }
                            else if (e.target.value > maxValue) {
                                alert('Entered number cannot be higher than ' + maxValue);
                            } else {
                                setLocalTimeValue(e.target.value);
                            }
                        }}
                        disabled={confirmedValueProvided}
                        className={confirmedValueProvided ? "myDetails-field-slider time-control disabled" : "myDetails-field-slider time-control"}
                        type="range" min={minValue} max={maxValue} value={localTimeValue} step={0.1} />

                    <input
                        onChange={(e) => {
                            if (e.target.value < 0) {
                                alert('Entered number cannot be negative.');
                            }
                            else if (e.target.value > maxValue) {
                                alert('Entered number cannot be higher than ' + maxValue);
                            } else {
                                setLocalTimeValue(e.target.value);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 189) {
                                e.preventDefault();
                            }
                        }}
                        min={minValue}
                        max={maxValue}
                        disabled={confirmedValueProvided}
                        onBlur={actionTime ? (e) => actionTime(e.target.value) : null}
                        className="myDetails-field-slider-value parent-control"
                        type="number" value={localTimeValue} />
                </div>
            </div>

        </div>
    )
}

export const UserDefinedSprintSpeedValue = ({ infoOpen, setInfoOpen: setInfoOpen, averageSprintSpeed, topSpeed, info, averageSpeedInfo, maximumSpeedInfo }) => {
    // const [infoOpenAverageSpeed, setInfoOpenAverageSpeed] = useState(false);
    // const [infoOpenTopSpeed, setInfoOpenTopSpeed] = useState(false);
    const organisationLocalState = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    return (
        <React.Fragment>
            <div className="myDetails-field">
                <div className="myDetails-field-title">
                    Average sprint speed

                {averageSpeedInfo ?
                        <div onClick={() => infoOpen === "Average sprint speed" ? setInfoOpen("") : setInfoOpen("Average sprint speed")} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>
                            <i className="fa fa-info-circle"></i>
                            <div style={{ display: infoOpen === "Average sprint speed" ? 'block' : 'none' }} className="myDetails-info-pointer"></div>
                        </div>
                        : null}
                    {averageSpeedInfo && infoOpen === "Average sprint speed" ? <FieldInfo info={averageSpeedInfo} /> : ""}
                </div>

                <span className="speedAmountSpan" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>{averageSprintSpeed[0]} {userState.userUOM == "Imperial" ? "mph" : "km/h"}</span>
            </div>
            <div className="myDetails-field">
                <div className="myDetails-field-title">
                    Maximum sprint speed

                {maximumSpeedInfo ?
                        <div onClick={() => infoOpen === "Maximum sprint speed" ? setInfoOpen("") : setInfoOpen("Maximum sprint speed")} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>
                            <i className="fa fa-info-circle"></i>
                            <div style={{ display: infoOpen === "Maximum sprint speed" ? 'block' : 'none' }} className="myDetails-info-pointer"></div>
                        </div>
                        : null}
                    {maximumSpeedInfo && infoOpen === "Maximum sprint speed" ? <FieldInfo info={maximumSpeedInfo} /> : ""}
                </div>

                <span className="speedAmountSpan" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>{averageSprintSpeed[1]}  {userState.userUOM == "Imperial" ? "mph" : "km/h"}</span>
            </div>
        </React.Fragment>

    )
}

export const UserDefinedDropDownField = ({ infoOpen, setInfoOpen: setInfoOpen, _value, vo2MaxValue, label, placeholder, action, info, confirmedValueProvided }) => {
    // const [infoOpen, setInfoOpen] = useState(false);
    const [value, setValue] = useState(_value);
    const [labelVal, setlabelVal] = useState(label);

    const [vo2Max, setVo2Max] = useState(vo2MaxValue || 0);

    const organisationLocalState = useSelector(state => state.organisationState);
    const reduxState = useSelector(state => state);

    var gender = "";
    var speed = "";
    var weight = "";
    var age = "";
    var testDate = "";

    if (reduxState.user.userGender != "") gender = reduxState.user.userGender;

    if (reduxState.user.userAge != "") age = reduxState.user.userAge + "";

    if (reduxState.myDetails.testDate != null && reduxState.myDetails.testDate != "")
        testDate = moment(reduxState.myDetails.testDate).format("YYYY-MM-DD")

    if (reduxState.sportMatchTest.userInputSprintDistance != "" && reduxState.sportMatchTest.userInputSprintTime != "") {
        var localDistanceValue = reduxState.sportMatchTest.userInputSprintDistance;
        var localTimeValue = reduxState.sportMatchTest.userInputSprintTime;

        var averageSpeed = (parseFloat(localDistanceValue) / (parseFloat(localTimeValue))) * 60 * 60 / 1000;
        var metersPerMinute = (parseFloat(localDistanceValue) * (60 / parseFloat(localTimeValue)));
        var kilometersPerHour = parseFloat((metersPerMinute * 60) / 1000).toFixed(1);

        var topSpeedPercentage = ((-0.000007 * Math.pow(localDistanceValue, 4)) + (0.001809 * Math.pow(localDistanceValue, 3)) - (0.171475 * Math.pow(localDistanceValue, 2)) + (7.07494368 * localDistanceValue) - 8 - (0.028 * localDistanceValue));
        speed = ((averageSpeed / topSpeedPercentage) * 100) + "";
    }

    if (reduxState.sportMatchTest.userInputWeight != "")
        weight = reduxState.sportMatchTest.userInputWeight + "";

    useEffect(() => {
        if (_value == "" && vo2MaxValue == "") {
            setValue("");
            setVo2Max(0);
        }
    }, [_value]);

    // React Toast Notifications Object
    const { addToast } = useToasts();

    // The level supported by backend
    const safeLevelSupport = 16.00;

    useEffect(() => {
        getVO2MaxFromBackend();
    }, [reduxState.myDetails.testDate]);

    const getVO2MaxFromBackend = (value = _value) => {
        var requestString = "";

        if (value != "") {
            if (gender != "") requestString += ("&gender=" + gender);
            if (weight != "") requestString += ("&weight=" + weight);
            if (age != "") requestString += ("&age=" + age);
            if (testDate != "") requestString += ("&testDate=" + testDate);

            fetch(BACKEND_URL + ENVIRONMENT_PATH + '/BeepTestServlet?shuttle_test_20m=' + value + requestString, {
                headers: {
                    'Accept': 'application/json'
                },
                credentials: "include"
            }).then(async function (res) {
                res.json().then(function (data) {
                    var vo2MaxScore = 0;
                    if (data.average_vo2) {
                        vo2MaxScore = parseFloat(data.average_vo2);
                    } else {
                        vo2MaxScore = parseFloat(data.vo2).toFixed(1);
                    }

                    setVo2Max(vo2MaxScore);
                    action(value, vo2MaxScore);
                }).catch(error => {
                    console.log("Error: ", error);
                    addToast("Unable to save 'Beep Test' - internal server error", {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    setValue("");
                    action("");
                })
            });
        }
    }

    // On selection change, send a request to the backend
    const onSelectChange = (value) => {
        getVO2MaxFromBackend(value);
    }

    const getNumberOfShuttlesForLevel = level => {
        switch (level) {
            case 1:
                return 7;
            case 2:
                return 8;
            case 3:
                return 8;
            case 4:
                return 9;
            case 5:
                return 9;
            case 6:
                return 10;
            case 7:
                return 10;
            case 8:
                return 11;
            case 9:
                return 11;
            case 10:
                return 11;
            case 11:
                return 12;
            case 12:
                return 12;
            case 13:
                return 13;
            case 14:
                return 13;
            case 15:
                return 13;
            case 16:
                return 14;
            case 17:
                return 14;
            case 18:
                return 15;
            case 19:
                return 15;
            case 20:
                return 16;
            case 21:
                return 16;
        }
    }

    /** Renders beep test level and shuttle values */
    const renderBeepTestLevelAndShuttleValues = () => {
        var domElement = [];
        var shuttle = 0;
        var shuttleMaximum = 0;
        var level = 1;
        for (; level <= 21; level++) {
            var levelToUse = level;
            var shuttleToUse = 0;

            if (levelToUse > 16) {
                levelToUse = 16;
                shuttleToUse = 14;
            }

            if (shuttleToUse != 0) {
                shuttleMaximum = getNumberOfShuttlesForLevel(level);
                for (shuttle = 1; shuttle <= shuttleMaximum; shuttle++) {
                    domElement.push(
                        <option value={levelToUse + "-" + shuttleToUse}>
                            {level + "-" + shuttle}
                        </option>
                    )
                }
            } else {
                shuttleMaximum = getNumberOfShuttlesForLevel(level);
                for (shuttle = 1; shuttle <= shuttleMaximum; shuttle++) {
                    domElement.push(
                        <option value={level + "-" + shuttle}>
                            {level + "-" + shuttle}
                        </option>
                    )
                }
            }

        }

        return domElement;
    }

    const MyNativeSelect = withStyles({
        icon: {
            background: organisationLocalState.organisationPrimaryColor ? (organisationLocalState.organisationPrimaryColor + " !important") : "#9a62b3 !important"
        }
    })(NativeSelect);

    return (
        <div className="myDetails-field drop-down-field">
            <div className="myDetails-field-title">
                {label}
                {info ? <div onClick={() => infoOpen === labelVal ? setInfoOpen("") : setInfoOpen(labelVal)} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i className="fa fa-info-circle"></i><div style={{ display: infoOpen === labelVal ? 'block' : 'none' }} className="myDetails-info-pointer"></div></div> : ""}
                {info && infoOpen === labelVal ? <FieldInfo info={info} /> : ""}
            </div>
            <MyNativeSelect
                onChange={(e) => onSelectChange(e.target.value)}
                data-type={"beepTest"}
                value={_value}
                displayEmpty
                disabled={confirmedValueProvided}
                renderValue={selected => {
                    if (selected) {
                        return selected;
                    } else {
                        return <span>&ensp;</span>;
                    }
                }}>
                {renderBeepTestLevelAndShuttleValues()}
            </MyNativeSelect>
            <span className="level-span">level</span>
            <div style={{ display: 'none' }} className="vo2MaxSection">
                <span>VO</span>
                <span className="vo2MaxSecondText">2max </span>
                <span>:</span>
                <span className="vo2MaxText" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>{vo2Max} </span>
                <span>(mL/kg/min)</span>
            </div>
        </div>
    );
}

export const UserDefinedStudentIdTextField = ({ infoOpen, setInfoOpen: setInfoOpen, _value, label, placeholder, action, info, isB2CAthlete = false }) => {
    // const [infoOpen, setInfoOpen] = useState(false);
    const [value, setValue] = useState(_value || "");
    const [labelVal, setlabelVal] = useState(label);
    const userState = useSelector(state => state.user);
    const organisationLocalState = useSelector(state => state.organisationState);

    return (
        <div className="myDetails-field student-id-row">
            <div className="myDetails-field-title">
                {label}
                {info ? <div onClick={() => infoOpen === labelVal ? setInfoOpen("") : setInfoOpen(labelVal)} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i className="fa fa-info-circle"></i><div style={{ display: infoOpen === labelVal ? 'block' : 'none' }} className="myDetails-info-pointer"></div></div> : ""}
                {info && infoOpen === labelVal ? <FieldInfo info={info} /> : ""}
            </div>
            <TextField
                disabled={!isB2CAthlete}
                onChange={action ? (e) => { action(e.target.value); setValue(e.target.value); } : null}
                value={value}
                placeholder={placeholder}
                size={'small'}
                id="filled-basic"
                variant="filled"
            />
        </div>
    );
}

const getUOMValue = (convertTo, userState, value, explicitFromUOM = "") => {
    if (convertTo == "foot" || convertTo == "ft") {
        if (userState.userUOM == "Imperial") {
            return parseFloat(IM(value).from(explicitFromUOM != "" ? explicitFromUOM : "cm").to('ft')).toFixed(2);
        } else {
            return value;
        }
    } else if (convertTo == "lbs") {
        if (userState.userUOM == "Imperial") {
            return parseFloat(IM(value).from('kg').to('lb')).toFixed(2);
        } else {
            return value;
        }
    } else if (convertTo == "in") {
        if (userState.userUOM == "Imperial") {
            return parseFloat(IM(value).from('cm').to('in')).toFixed(2);
        } else {
            return value;
        }
    } else {
        return value;
    }
}

export const MuscularStrengthAndPowerSliderField = ({ infoOpen, setInfoOpen: setInfoOpen, label, action, info, labelUOM, enteredValue, informationOnly, minValue, maxValue, confirmedValueProvided }) => {
    // const [infoOpen, setInfoOpen] = useState(false);
    const [value, setValue] = useState(enteredValue || 0);
    const [labelVal, setLabelVal] = useState(label)
    const state = useSelector(state => state.myDetails);
    const sportMatchState = useSelector(state => state.sportMatchTest);
    const organisationLocalState = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    const [editFeetInches, setEditFeetInches] = useState(false);

    // Variable used to determine if the field can be a feet'inches" control
    const ableToUseFeetInches = labelUOM == "in" && label == "Standing broad jump";

    useEffect(() => {
        if (enteredValue == "") setValue(0)
    }, [enteredValue]);

    useEffect(() => {
        Array.from(document.getElementsByClassName("myDetails-field-slider")).forEach(elem => {
            elem.style.setProperty("--value", organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3')
        })
    }, [])

    // Renders the value for display
    const renderValue = (value = 0.00, returnType = "") => {
        var returnValue = "";

        if (labelUOM == "in" && label == "Standing broad jump") {
            if (returnType == "Feet") {
                returnValue = ConvertToFeetAndInchesAndGetFeet(value);
            } else if (returnType == "Inches") {
                returnValue = ConvertToFeetAndInchesAndGetInches(value);
            } else {
                returnValue = parseFloat((value / 12)).toFixed(3).split(".")[0] + "'" + parseFloat((value % 12)).toFixed(3).split(".")[0] + "\"";
            }
        } else {
            returnValue = value;
        }

        return returnValue;
    };

    var [feetInput, setFeetInput] = useState(renderValue(value, "Feet"));
    var [inchesInput, setInchesInput] = useState(renderValue(value, "Inches"));

    const averageGripStrengthCalculation = () => {
        var gripStrengthLeftLocal = sportMatchState.userInputGripStrengthLeft;
        var gripStrengthRightLocal = sportMatchState.userInputGripStrengthRight;

        var hasGripStrengthLeft = (gripStrengthLeftLocal !== '' && gripStrengthLeftLocal !== '0');
        var hasGripStrengthRight = (gripStrengthRightLocal !== '' && gripStrengthRightLocal !== '0');

        if (hasGripStrengthLeft && !hasGripStrengthRight) {
            gripStrengthRightLocal = gripStrengthLeftLocal;
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (hasGripStrengthRight && !hasGripStrengthLeft) {
            gripStrengthLeftLocal = gripStrengthRightLocal;
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (hasGripStrengthLeft && hasGripStrengthRight) {
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else {
            return 0;
        }
    }

    var gripStrengthAverage = averageGripStrengthCalculation();

    if (isNaN(gripStrengthAverage)) {
        gripStrengthAverage = 0;
    }

    const FaderInput = styled.input`
         &::-webkit-slider-thumb {
             background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }
         &::-moz-range-thumb {
            background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }`;

    const renderControls = () => {
        if (!informationOnly) {
            return (
                <React.Fragment>
                    <input
                        onMouseDown={() => setEditFeetInches(false)}
                        onChange={(e) => setValue(e.target.value)}
                        onMouseUp={(e) => action(e.target.value)}
                        onTouchEnd={action ? (e) => action(e.target.value) : null}
                        className={confirmedValueProvided ? "myDetails-field-slider disabled" : "myDetails-field-slider"}
                        disabled={confirmedValueProvided}
                        type="range"
                        min={typeof minValue !== 'undefined' ? minValue : '0'}
                        max={typeof maxValue !== 'undefined' ? maxValue : '0'}
                        value={value}
                        step={labelUOM == "foot" ? 0.01 : 0.5} />

                    {editFeetInches && label == "Standing broad jump" ?
                        <>
                            <span style={{ fontSize: '14px', marginLeft: '12px' }}>Ft:</span>
                            <input
                                onChange={(e) => {
                                    setFeetInput(e.target.value);
                                    setValue((e.target.value * 12) + parseFloat(inchesInput));
                                    action((e.target.value * 12) + parseFloat(inchesInput));

                                    if(e.target.value == 10) 
                                        if(inchesInput > 5) setInchesInput(5);
                                }}
                                disabled={confirmedValueProvided}
                                className="myDetails-field-slider-value feet-inches-control"
                                min={0}
                                max={10}
                                type="number" defaultValue={renderValue(value, "Feet")} step={1} />

                            <span style={{ fontSize: '14px', marginLeft: '8px' }}>In:</span>
                            <input
                                onChange={(e) => {
                                    setInchesInput(e.target.value);
                                    setValue(parseFloat(e.target.value) + parseFloat(feetInput * 12));
                                    action(parseFloat(e.target.value) + parseFloat(feetInput * 12));
                                }}
                                min={0}
                                max={feetInput == 10 ? 5 : 11.5}
                                disabled={confirmedValueProvided}
                                className="myDetails-field-slider-value feet-inches-control"
                                type="number" defaultValue={renderValue(value, "Inches")} step={0.5} />
                        </> :
                        <input
                            onClick={() => ableToUseFeetInches ? setEditFeetInches(true) : null}
                            onInput={(e) => {
                                if (e.target.value < 0) {
                                    alert('Entered number cannot be negative.');
                                }
                                else if (e.target.value > maxValue) {
                                    alert('Entered number cannot be higher than ' + maxValue);
                                } else {
                                    setValue(e.target.value);
                                    action(e.target.value);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.keyCode === 189 || isNaN(e.target.value)) {
                                    e.preventDefault();
                                }
                            }}
                            className="myDetails-field-slider-value"
                            type={ableToUseFeetInches ? "text" : "number"} value={renderValue(value)}
                            step={0.5}
                            disabled={confirmedValueProvided}
                            onBlur={action ? (e) => action(e.target.value) : null}
                            min={minValue}
                            max={maxValue}
                        />
                    }


                </React.Fragment>
            )
        } else {
            return (
                <div className="grip-strength-avarage">
                    <span style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>{gripStrengthAverage} {labelUOM}</span>
                </div>
            )
        }
    }

    return (
        <div className="myDetails-field">
            <div className="myDetails-field-title">
                {label}
                {labelUOM ? <span className="label-uom">{"(" + labelUOM + ")"}</span> : null}
                {info ? <div onClick={() => infoOpen === labelVal ? setInfoOpen("") : setInfoOpen(labelVal)} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i className="fa fa-info-circle"></i><div style={{ display: infoOpen === labelVal ? 'block' : 'none' }} className="myDetails-info-pointer"></div></div> : ""}
                {info && infoOpen === labelVal ? <FieldInfo info={info} /> : ""}
            </div>
            {renderControls()}
        </div>
    );
};

export const RenderFeetInchesFields = ({
    action,
    enteredValue,
    feetMaxValue,
    inchesMaxValue,
    confirmedValueProvided }) => {

    const [editFeetInches, setEditFeetInches] = useState(false);
    const [value, setValue] = useState(enteredValue || 0);
    const userState = useSelector(state => state.user);

    // Renders the value for display
    const renderValue = (value = 0.00, returnType = "") => {
        var returnValue = "";
        if (returnType != "") {
            if (returnType == "Feet") {
                returnValue = ConvertToFeetAndInchesAndGetFeet(value);
            } else {
                returnValue = ConvertToFeetAndInchesAndGetInches(value);
            }
        } else {
            returnValue = ConvertToFeetAndInches(value);
        }

        return returnValue;
    }

    var [feetInput, setFeetInput] = useState(renderValue(value, "Feet"));
    var [inchesInput, setInchesInput] = useState(renderValue(value, "Inches"));

    return (
        <>
            {editFeetInches ?
                <>
                    <span style={{ fontSize: '14px', marginLeft: '12px' }}>Ft:</span>
                    <input
                        onChange={(e) => {
                            setFeetInput(e.target.value);
                            setValue((e.target.value * 12) + parseFloat(inchesInput));
                            action((e.target.value * 12) + parseFloat(inchesInput));
                        }}
                        disabled={confirmedValueProvided}
                        className="myDetails-field-slider-value feet-inches-control"
                        min={0}
                        max={feetMaxValue}
                        type="number" defaultValue={renderValue(value, "Feet")} step={1} />

                    <span style={{ fontSize: '14px', marginLeft: '8px' }}>In:</span>
                    <input
                        onChange={(e) => {
                            setInchesInput(e.target.value);
                            setValue(parseFloat(e.target.value) + parseFloat(feetInput * 12));
                            action(parseFloat(e.target.value) + parseFloat(feetInput * 12));
                        }}
                        disabled={confirmedValueProvided}
                        className="myDetails-field-slider-value feet-inches-control"
                        min={0}
                        max={inchesMaxValue}
                        type="number" defaultValue={renderValue(value, "Inches")} step={0.5} />
                </>
                :
                <input
                    onClick={() => setEditFeetInches(true)}
                    onKeyDown={(e) => {
                        if (e.keyCode === 189 || isNaN(e.target.value)) {
                            e.preventDefault();
                        }
                    }}
                    onBlur={action ? (e) => action(e.target.value) : null}
                    disabled={confirmedValueProvided}
                    className="myDetails-field-slider-value"
                    type="text" value={renderValue(value)} step={0.1} />}
        </>
    )
}

export const BodySizeAndShapeSliderFields = ({
    infoOpen,
    setInfoOpen: setInfoOpen,
    label,
    showValidation,
    requiredField,
    action,
    info,
    labelUOM,
    required,
    enteredValue,
    minValue,
    convertMinimum = true,
    maxValue,
    confirmedValueProvided,
    step,
    fixed = -1,
    formatToFeetAndInches = false
}) => {
    const [value, setValue] = useState(enteredValue || 0);
    const [labelVal, setlabelVal] = useState(label);
    const organisationLocalState = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    var minimumValue = parseFloat(convertMinimum ? getUOMValue(labelUOM, userState, minValue) : minValue);
    var maximumValue = parseFloat(getUOMValue(labelUOM, userState, maxValue));

    const [editFeetInches, setEditFeetInches] = useState(false);

    // Variable used to determine if the field can be a feet'inches" control
    const ableToUseFeetInches = 
        labelUOM == "foot" && label == "Height"
        || label == "Height" && labelUOM == "in"
        || label == "Arm span" && labelUOM == "in";

    useEffect(() => {
        if (enteredValue == "") setValue(0);
    }, [enteredValue]);

    useEffect(() => {
        Array.from(document.getElementsByClassName("myDetails-field-slider")).forEach(elem => {
            elem.style.setProperty("--value", organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3')
        })
    }, [])

    // Renders the value for display
    const renderValue = (value = 0.00, returnType = "") => {
        var returnValue = "";

        if (formatToFeetAndInches) {
            if (returnType != "") {
                if (returnType == "Feet") {
                    returnValue = ConvertToFeetAndInchesAndGetFeet(value);
                } else {
                    returnValue = ConvertToFeetAndInchesAndGetInches(value);
                }
            } else {
                returnValue = ConvertToFeetAndInches(value);
            }
        } else {
            returnValue = fixed != -1 ? parseFloat(value).toFixed(fixed) : value;
        }

        return returnValue;
    }

    var [feetInput, setFeetInput] = useState(renderValue(value, "Feet"));
    var [inchesInput, setInchesInput] = useState(renderValue(value, "Inches"));

    const FaderInput = styled.input`
         &::-webkit-slider-thumb {
             background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }
         &::-moz-range-thumb {
            background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }`;

    return (
        <div className={requiredField ? `required-field-${label.toLowerCase()} ` + `myDetails-field` : `myDetails-field`}>
            <div className="myDetails-field-title">
                {label} {labelUOM ? <span className="label-uom">{"(" + labelUOM + ")"}</span> : null}
                {required ? <span className='required-asterisk' style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>*</span> : null}
                {showValidation ? <span className="validation-message">{`You need to enter your ${label.toLowerCase()}!`}</span> : null}
                {info ? <div onClick={() => infoOpen === labelVal ? setInfoOpen("") : setInfoOpen(labelVal)} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i className="fa fa-info-circle"></i><div style={{ display: infoOpen === labelVal ? 'block' : 'none' }} className="myDetails-info-pointer"></div></div> : ""}
                {info && infoOpen === labelVal ? <FieldInfo info={info} /> : ""}
            </div>
            <input
                onMouseDown={() => setEditFeetInches(false)}
                onMouseUp={action ? (e) => action(e.target.value) : null}
                onTouchEnd={action ? (e) => action(e.target.value) : null}
                onChange={(e) => setValue(e.target.value)}
                disabled={confirmedValueProvided}
                className={confirmedValueProvided ? "myDetails-field-slider disabled" : "myDetails-field-slider"}
                type="range" min={typeof minimumValue !== 'undefined' ? minimumValue : '0'}
                max={typeof maximumValue !== 'undefined' ? maximumValue : '100'}
                value={value} step={userState.userUOM == "Imperial" ? (step ? step : 0.1) : 0.1} />

            {editFeetInches && (label == "Height" || label == "Arm span") ?
                <>
                    <span style={{ fontSize: '14px', marginLeft: '12px' }}>Ft:</span>
                    <input
                        onChange={(e) => {
                            setFeetInput(e.target.value);
                            setValue((e.target.value * 12) + parseFloat(inchesInput));
                            action((e.target.value * 12) + parseFloat(inchesInput));
                        }}
                        disabled={confirmedValueProvided}
                        className="myDetails-field-slider-value feet-inches-control"
                        min={0}
                        max={7}
                        type="number" defaultValue={renderValue(value, "Feet")} step={1} />

                    <span style={{ fontSize: '14px', marginLeft: '8px' }}>In:</span>
                    <input
                        onChange={(e) => {
                            setInchesInput(e.target.value);
                            setValue(parseFloat(e.target.value) + parseFloat(feetInput * 12));
                            action(parseFloat(e.target.value) + parseFloat(feetInput * 12));
                        }}
                        disabled={confirmedValueProvided}
                        className="myDetails-field-slider-value feet-inches-control"
                        type="number" defaultValue={renderValue(value, "Inches")} step={0.5} />
                </>
                :
                <input
                    onClick={() => (ableToUseFeetInches) ? setEditFeetInches(true) : null}
                    onChange={(e) => {
                        if (e.target.value < 0) {
                            alert('Entered number cannot be negative.');
                        } else if (e.target.value > maximumValue) {
                            alert('Entered number cannot be higher than ' + maximumValue);
                        } else {
                            setValue(e.target.value);
                            action(e.target.value);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === 189 || isNaN(e.target.value)) {
                            e.preventDefault();
                        }
                    }}
                    onBlur={action ? (e) => action(e.target.value) : null}
                    disabled={confirmedValueProvided}
                    className="myDetails-field-slider-value"
                    min={typeof minimumValue !== 'undefined' ? minimumValue : '0'}
                    max={typeof maximumValue !== 'undefined' ? maximumValue : '100'}
                    type={ableToUseFeetInches ? "text" : "number"} value={renderValue(value)} step={0.1} />}

        </div>
    );
}

export const SliderField = ({ infoOpen, setInfoOpen: setInfoOpen, label, action, info, enteredValue, minValue, maxValue, labelUOM, incrementalValue, confirmedValueProvided }) => {
    // const [infoOpen, setInfoOpen] = useState(false);
    const [value, setValue] = useState(enteredValue || 0);
    const [labelVal, setlabelVal] = useState(label);
    const organisationLocalState = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    var minimumValue = parseFloat(getUOMValue(labelUOM, userState, minValue));
    var maximumValue = parseFloat(getUOMValue(labelUOM, userState, maxValue));

    useEffect(() => {
        if (enteredValue == "") {
            setValue(0);
        }
    }, [enteredValue])

    useEffect(() => {
        Array.from(document.getElementsByClassName("myDetails-field-slider")).forEach(elem => {
            elem.style.setProperty("--value", organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3')
        })
    }, [])

    const FaderInput = styled.input`
         &::-webkit-slider-thumb {
             background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }
         &::-moz-range-thumb {
            background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }`;

    return (
        <div className="myDetails-field">
            <div className="myDetails-field-title">
                {label}
                {labelUOM ? <span className="label-uom">{"(" + labelUOM + ")"}</span> : null}
                {info ? <div onClick={() => infoOpen === labelVal ? setInfoOpen("") : setInfoOpen(labelVal)} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i className="fa fa-info-circle"></i><div style={{ display: infoOpen === labelVal ? 'block' : 'none' }} className="myDetails-info-pointer"></div></div> : ""}
                {info && infoOpen === labelVal ? <FieldInfo info={info} /> : ""}
            </div>
            <input
                onMouseUp={action ? (e) => action(e.target.value) : null}
                onTouchEnd={action ? (e) => action(e.target.value) : null}
                onChange={(e) => setValue(e.target.value)}
                disabled={confirmedValueProvided}
                className={confirmedValueProvided ? "myDetails-field-slider disabled" : "myDetails-field-slider"}
                type="range" min={typeof minimumValue !== 'undefined' ? minimumValue : '0'}
                max={typeof maximumValue !== 'undefined' ? maximumValue : '0'}
                value={value} step={incrementalValue ? incrementalValue : 1} />
            <input
                onChange={(e) => {
                    if (e.target.value < 0) {
                        alert('Entered number cannot be negative.');
                    }
                    else if (e.target.value > maximumValue) {
                        alert('Entered number cannot be higher than ' + maximumValue);
                    } else {
                        setValue(e.target.value);
                        action(e.target.value);
                    }

                }}
                onKeyDown={(e) => {
                    if (e.keyCode === 189) {
                        e.preventDefault();
                    }
                }}
                onBlur={action ? (e) => action(e.target.value) : null}
                className="myDetails-field-slider-value"
                type="number" value={value}
                disabled={confirmedValueProvided}
                min={typeof minimumValue !== 'undefined' ? minimumValue : '0'}
                max={typeof maximumValue !== 'undefined' ? maximumValue : '0'}
            />
        </div>
    );
}

export const RadioFieldsGenderSelection = ({ infoOpen, setInfoOpen: setInfoOpen, onChange, genderSelected, label, info }) => {
    // const [infoOpen, setInfoOpen] = useState(false);
    const [value, setValue] = useState(genderSelected || "");
    const organisationLocalState = useSelector(state => state.organisationState);

    const onGenderSelectionChange = (input) => {
        onChange(input);
        setValue(input);
    };

    let style = {
        border: organisationLocalState.organisationPrimaryColor != '' ? '3px solid ' + organisationLocalState.organisationPrimaryColor : '3px solid #9a62b3',
        background: value === 'male' ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : '',
        // hover: {backgroundColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}
    }

    const FaderSpan = styled.span`
         &:hover {
             background-color: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          
          }`;

    return (
        <div className={"myDetails-field"}>
            <div className="myDetails-field-title">
                {label}
                {info ? <div class="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i class="fa fa-info-circle"></i><div class="myDetails-info-pointer"></div></div> : ""}
                {info ? <FieldInfo info={info} /> : ""}
            </div>
            <div className="gender-selection-container">
                <label className={"gender-section"}>
                    <input checked={value === 'male'} id="male-gender-radio" onChange={() => onGenderSelectionChange("male")} type="radio" name="gender" value="male" />
                    <FaderSpan className="checkmark" style={style}></FaderSpan>
                    <label className="gender-selection" for="male-gender-radio">Male</label>
                </label>
                <label className="gender-section">
                    <input checked={value === 'female'} id="female-gender-radio" onChange={() => onGenderSelectionChange("female")} type="radio" name="gender" value="female" />
                    <FaderSpan className="checkmark" style={{ border: organisationLocalState.organisationPrimaryColor != '' ? '3px solid ' + organisationLocalState.organisationPrimaryColor : '3px solid #9a62b3', background: value === 'female' ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : '' }}></FaderSpan>
                    <label className="gender-selection" for="female-gender-radio">Female</label>
                </label>
            </div>


        </div>
    )
};

export const RadioField = ({ label, info }) => {
    const [infoOpen, setInfoOpen] = useState(false);
    const [value, setValue] = useState("");
    const organisationLocalState = useSelector(state => state.organisationState);
    return (
        <div className="myDetails-field">
            <div className="myDetails-field-title">
                {label}
                {info ? <div class="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i class="fa fa-info-circle"></i><div class="myDetails-info-pointer"></div></div> : ""}
                {info ? <FieldInfo info={info} /> : ""}
            </div>
            <input onChange={() => setValue("male")} type="radio" name="gender" value="male" /><span className="gender-selection">Male</span>
            <input onChange={() => setValue("female")} type="radio" name="gender" value="female" /><span className="gender-selection">Female</span>
        </div>
    );
}
