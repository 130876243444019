import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// CONSTANTS
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../store/constants'

import IM from 'convert-units';
import { ConvertToFeetAndInches } from '../../../helper/helper.js';
import { hexToRgbA } from '../../../helper/hexToRgbA.js';
import { CircularProgress } from '@material-ui/core';

const convertNumToDoubleDigitString = (num) => {
    var numFormatted = "";
    if (num < 10) {
        numFormatted = "0" + num;
    } else {
        numFormatted = num + "";
    }

    return numFormatted;
}

const convertMinuteSecondTimeFormatToMilliseconds = (str) => {
    str = str + "";
    var minutes = 0, seconds = 0;
    if (str.indexOf(":") !== -1) {
        minutes = str.split(":")[0];
        seconds = str.split(":")[1];
    }

    return (minutes * 60000) + (seconds * 1000);
}

const dummyBestStatistics = [
    {
        name: `Height (#)`,
        score: 220,
        unit: `CM`,
        isTimed: false
    },
    {
        name: `Weight (kg)`,
        score: 150,
        unit: `KG`,
        isTimed: false
    },
    {
        name: `Sit & reach (cm)`,
        score: 60,
        unit: `DECIMAL`,
        isTimed: false
    },
    {
        name: `Standing broad jump (cm)`,
        score: 320,
        unit: `DECIMAL`,
        isTimed: false
    },
    {
        name: `Grip strength (kg)`,
        score: 90,
        unit: `DECIMAL`,
        isTimed: false
    },
    {
        name: `Vertical jump (cm)`,
        score: 110,
        unit: `DECIMAL`,
        isTimed: false
    },
    {
        name: `Bent-knee sit-ups`,
        score: 100,
        unit: `NUMBER`,
        isTimed: false
    },
    {
        name: `Inclined pull-ups`,
        score: 50,
        unit: `NUMBER`,
        isTimed: false
    },
    {
        name: `4 x 10 m shuttle run (s)`,
        score: 7,
        isTimed: true,
        unit: `SECONDS`,
        zeroPercentScore: 30
    },
    {
        name: `Maximum sprint speed (km/h)`,
        score: 30,
        isTimed: false,
        unit: `DECIMAL`,
        zeroPercentScore: 2
    },
    {
        name: `1.6 km run / walk (min:s)`,
        score: 240,
        isTimed: true,
        unit: `MINUTES:SECONDS`,
        zeroPercentScore: 1200
    },
    {
        name: `Aerobic fitness (mL/kg/min)`,
        score: 90,
        unit: `DECIMAL`,
        isTimed: false
    }
];

/**
 * Calculates the percentage from the best (i.e. if the best was 95 and the score to compare it to is 5, it would be a 5% return)
 * num1 = best score
 * num2 = compared data parameter
 */
const calculatePercentageFromBest = (num1, num2) => {
    return (num2 / num1) * 100;
}

/**
 * Calculates the percentage from the best for time based scores (i.e. lower is better)
 * @param {object} bestScoreObj the best score
 * @param {object} comparisonScoreObj the score to compare against the best
 * @param {string} type the type of score to compare (i.e. beep test, shuttle run, etc.)
 */
const calculatePercentageFromBestForTimeScores = (bestScoreObj, comparisonScoreObj) => {

    var zeroPercentScore = bestScoreObj.zeroPercentScore;
    var oneHundredPercentScore = bestScoreObj.score;
    var testScore = comparisonScoreObj.score;

    if (testScore >= zeroPercentScore) {
        return 0;
    }

    var differenceBetweenBestAndWorstScore = zeroPercentScore - oneHundredPercentScore;
    var differenceBetweenTestScoreAndZeroScore = zeroPercentScore - testScore;

    var percentageAmount = (differenceBetweenTestScoreAndZeroScore / differenceBetweenBestAndWorstScore) * 100;

    return percentageAmount;
}

/**
 * Finds the best test from an array of test objects based off a name input
 * @param {string} name the name of the test to look for
 */
const findBestTestFromTestName = name => {
    for (var i = 0; i < dummyBestStatistics.length; i++) {
        if (dummyBestStatistics[i].name === name)
            return dummyBestStatistics[i];
    }
}

const getGenderSelected = (str) => {
    switch (str) {
        case "sort-by-all":
            return "all";
        case "sort-by-male":
            return "male";
        case "sort-by-female":
            return "female";
        default:
            return "all";
    }
}

/** Builds the Sports Attributes section depending on which sport the user selected */
const EliteAthleteProfiles = () => {
    const images = require.context('../../../images', true);

    // Redux state
    const state = useSelector(state => state).myDetails;
    const adultState = useSelector(state => state).adultValues;
    const userState = useSelector(state => state).user;
    const organisationLocalState = useSelector(state => state.organisationState);

    const [sortByOptionSelected, setSortByOptionSelected] = useState("sort-by-all");

    // List of sports to render
    const [userSports, setUserSports] = useState([]);

    // Used for setting the user sport selected
    const [userSport, setUserSport] = useState([]);
    const [hasRenderedFirstSport, setHasRenderedFirstSport] = useState(false);
    const [sportDescription, setSportDescription] = useState("");
    const [selectedImage, setSelectedImage] = useState("Archery Female.jpg");

    const [loadingSportData, setLoadingSportData] = useState(false);

    const sportSelectionOnChangeHandler = (val, gender, imagepath, description) => {
        if (imagepath) setSelectedImage(imagepath);
        if (description) setSportDescription(description);

        setLoadingSportData(true);

        var valueSelected = encodeURIComponent(val);

        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/EliteAthleteProfilesServlet?getAthleteNorms=true&eliteNormName=${valueSelected}&gender=${gender}`, {
            credentials: "include"
        }).then((data) => {
            data.json().then((result) => {
                setLoadingSportData(false);
                var objArray = [
                    {
                        name: "Height (#)",
                        score: result.Height,
                        unit: "CM",
                        isTimed: false
                    },
                    {
                        name: "Weight (kg)",
                        score: result.Weight,
                        unit: "KG",
                        isTimed: false
                    },
                    {
                        name: "Sit & reach (cm)",
                        score: parseFloat(result["Sit and reach"]).toFixed(2),
                        unit: "DECIMAL",
                        isTimed: false
                    },
                    {
                        name: "Standing broad jump (cm)",
                        score: parseFloat(result["Standing broad jump"]).toFixed(2),
                        unit: "DECIMAL",
                        isTimed: false
                    },
                    {
                        name: "Grip strength (kg)",
                        score: parseFloat(result["Grip strength"]).toFixed(2),
                        unit: "DECIMAL",
                        isTimed: false
                    },
                    {
                        name: "Vertical jump (cm)",
                        score: parseFloat(result.Power).toFixed(2),
                        unit: "DECIMAL",
                        isTimed: false
                    },
                    {
                        name: "Bent-knee sit-ups",
                        score: parseFloat(result.Situps).toFixed(2),
                        unit: "DECIMAL",
                        isTimed: false
                    },
                    {
                        name: "Inclined pull-ups",
                        score: parseFloat(result.Pullup).toFixed(2),
                        unit: "NUMBER",
                        isTimed: false
                    },
                    {
                        name: "4 x 10 m shuttle run (s)",
                        score: result.Shuttle,
                        isTimed: true,
                        unit: "SECONDS",
                        zeroPercentScore: 25000
                    },
                    {
                        name: "Maximum sprint speed (km/h)",
                        score: result.Speed,
                        isTimed: false,
                        unit: "DECIMAL",
                        zeroPercentScore: 2
                    },
                    {
                        name: "1.6 km run / walk (min:s)",
                        score: result["Walk run"],
                        isTimed: true,
                        unit: "MINUTES:SECONDS",
                        zeroPercentScore: 960000
                    },
                    {
                        name: "Aerobic fitness (mL/kg/min)",
                        score: result.Endurance,
                        unit: "DECIMAL",
                        isTimed: false
                    }
                ]
                setUserSport(objArray);
            }).catch(() => {
                console.log("Unable to pass data returned into JSON format");
                setLoadingSportData(false);
            })
        }).catch(() => {
            console.log("Exception hit, unable to get Athlete data back from backend");
            setLoadingSportData(false);
        })
    }

    const findEliteTestFromTestName = name => {
        if (userSport.length !== 0) {
            for (var i = 0; i < userSport.length; i++) {
                if (userSport[i].name === name) {
                    return userSport[i];
                }
            }
        }
    }

    const getAverageGripStrength = () => {
        var gripStrengthLeftLocal = state.leftGripStrength;
        var gripStrengthRightLocal = state.rightGripStrength;

        var hasGripStrengthLeft = (gripStrengthLeftLocal !== '' && gripStrengthLeftLocal !== '0');
        var hasGripStrengthRight = (gripStrengthRightLocal !== '' && gripStrengthRightLocal !== '0');

        if (hasGripStrengthLeft && !hasGripStrengthRight) {
            gripStrengthRightLocal = gripStrengthLeftLocal;
            return parseFloat((parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2).toFixed(2);
        } else if (hasGripStrengthRight && !hasGripStrengthLeft) {
            gripStrengthLeftLocal = gripStrengthRightLocal;
            return parseFloat((parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2).toFixed(2);
        } else if (hasGripStrengthLeft && hasGripStrengthRight) {
            return parseFloat((parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2).toFixed(2);
        } else {
            return parseFloat(0).toFixed(2);
        }
    }

    // User scores
    var usersHeightScore = !isNaN(state.height) && state.height.length !== 0 ? parseFloat(state.height) : 0;
    var usersWeightScore = !isNaN(state.weight) && state.weight.length !== 0 ? parseFloat(state.weight) : 0;
    var usersSitUpsScore = !isNaN(state.sitUps) && state.sitUps.length !== 0 ? parseFloat(state.sitUps).toFixed(1) : 0;
    var usersStandingBroadJumpScore = !isNaN(state.standingBroadJump) && state.standingBroadJump.length !== 0 ? parseFloat(state.standingBroadJump).toFixed(2) : 0;
    var usersSitAndReachScore = !isNaN(state.sitAndReach) && state.sitAndReach.length !== 0 ? parseInt(state.sitAndReach) : 0;
    var usersInclinedPullUpsScore = !isNaN(state.inclinedPullUps) && state.inclinedPullUps.length !== 0 ? parseInt(state.inclinedPullUps) : 0;
    var usersShuttleRunScore = !isNaN(state.shuttleRun) && state.shuttleRun.length !== 0 ? parseFloat(state.shuttleRun).toFixed(2) : 0;
    var usersRunWalkScore = '00:00';

    if (state.runWalkMinutes !== '' && state.runWalkSeconds !== '') {
        usersRunWalkScore = state.runWalkMinutes + ":" + state.runWalkSeconds;
    }

    var user50MSprintScore = 0;

    if (state.sprintDistance !== '' && state.sprintTime !== '') {
        var distance = state.sprintDistance;

        if(userState.userUOM == "Imperial") distance = (distance * 3);

        if(userState.userUOM == "Imperial") distance = IM(distance).from("ft").to("m");

        var averageSpeed = (parseFloat(distance) / (parseFloat(state.sprintTime))) * 60 * 60 / 1000;

        var topSpeedPercentage = ((-0.000007 * Math.pow(distance, 4)) + (0.001809 * Math.pow(distance, 3)) - (0.171475 * Math.pow(distance, 2)) + (7.07494368 * distance) - 8 - (0.028 * distance));

        var topSpeed = (averageSpeed / topSpeedPercentage) * 100;

        if(userState.userUOM == "Imperial") {
            user50MSprintScore = (parseFloat(topSpeed) / 1.609).toFixed(2);
        } else {
            user50MSprintScore = topSpeed.toFixed(2);
        }
    }

    var userGripStrengthScore = getAverageGripStrength();

    var userVerticalJumpScore = !isNaN(state.verticalJump) && state.verticalJump.length !== 0 ? parseFloat(state.verticalJump).toFixed(2) : 0;
    var userShuttleBeepTestcore = !isNaN(state.vo2Max) && state.vo2Max.length !== 0 ? parseFloat(state.vo2Max).toFixed(2) : 0;

    var usersRunWalkScoreInMilliseconds = convertMinuteSecondTimeFormatToMilliseconds(usersRunWalkScore);

    const userTestData = [
        {
            name: "Height (#)",
            score: usersHeightScore,
            isProvided: parseFloat(usersHeightScore).toFixed(1) != 0,
            unit: "CM",
            isTimed: false
        },
        {
            name: "Weight (kg)",
            score: usersWeightScore,
            isProvided: parseFloat(usersWeightScore).toFixed(1) != 0,
            unit: userState.userUOM == "Imperial" ? "LBS" : "KG",
            isTimed: false
        },
        {
            name: "Sit & reach (cm)",
            score: usersSitAndReachScore,
            isProvided: parseFloat(usersSitAndReachScore).toFixed(1) != 0,
            unit: "DECIMAL",
            isTimed: false
        },
        {
            name: "Standing broad jump (cm)",
            score: usersStandingBroadJumpScore,
            isProvided: parseFloat(usersStandingBroadJumpScore).toFixed(1) != 0,
            unit: "DECIMAL",
            isTimed: false
        },
        {
            name: "Grip strength (kg)",
            score: userGripStrengthScore,
            isProvided: parseFloat(userGripStrengthScore).toFixed(1) != 0,
            unit: "DECIMAL",
            isTimed: false
        },
        {
            name: "Vertical jump (cm)",
            score: userVerticalJumpScore,
            isProvided: parseFloat(userVerticalJumpScore).toFixed(1) != 0,
            unit: "DECIMAL",
            isTimed: false
        },
        {
            name: "Bent-knee sit-ups",
            score: usersSitUpsScore,
            isProvided: parseFloat(usersSitUpsScore).toFixed(0) != 0,
            unit: "NUMBER",
            isTimed: false
        },
        {
            name: "Inclined pull-ups",
            score: usersInclinedPullUpsScore,
            isProvided: parseFloat(usersInclinedPullUpsScore).toFixed(0) != 0,
            unit: "NUMBER",
            isTimed: false
        },
        {
            name: "4 x 10 m shuttle run (s)",
            score: usersShuttleRunScore,
            isProvided: parseFloat(usersShuttleRunScore).toFixed(1) != 0,
            isTimed: true,
            unit: "SECONDS",
            zeroPercentScore: 25000
        },
        {
            name: "Maximum sprint speed (km/h)",
            score: user50MSprintScore,
            isProvided: parseFloat(user50MSprintScore).toFixed(1) != 0,
            isTimed: false,
            unit: "DECIMAL",
            zeroPercentScore: 2
        },
        {
            name: "1.6 km run / walk (min:s)",
            score: (usersRunWalkScoreInMilliseconds / 1000),
            isProvided: parseFloat(usersRunWalkScoreInMilliseconds).toFixed(1) != 0,
            isTimed: true,
            unit: "MINUTES:SECONDS",
            zeroPercentScore: 960000
        },
        {
            name: "Aerobic fitness (mL/kg/min)",
            score: userShuttleBeepTestcore,
            isProvided: parseFloat(userShuttleBeepTestcore).toFixed(1) != 0,
            unit: "DECIMAL",
            isTimed: false
        }
    ]

    /**
     * Sorts the list of tests by order of data filled out from user -> no data
     * @param {object[]} userDataSet
     * @param {object[]} eliteDataSet
     */
    const orderByDataEntryFromUser = (userDataSet, eliteDataSet) => {
        /**
         * Steps to achieve this:
         * 1) Go through the list of objects in the userDataSet
         * 2) If a specific object has data enter (i.e. score is not 0), then find that object in the eliteDataSet - and add it to the top of the new eliteDataSet
         * 3) Return the new eliteDataSet after iterating through each object in the userDataSet
         */

        var userTestsWithData = [];
        var userTestsWithNoData = [];
        var newEliteDataSet = [];

        for (var i = 0; i < userDataSet.length; i++) {
            if (userDataSet[i].score !== 0) {
                var obj = findEliteTestFromTestName(userDataSet[i].name);
                userTestsWithData.push(obj);
            } else {
                var obj = findEliteTestFromTestName(userDataSet[i].name);
                userTestsWithNoData.push(obj);
            }
        }

        newEliteDataSet = userTestsWithData.concat(userTestsWithNoData);

        return newEliteDataSet;
    };

    // Applies UOM depending on the name of the data and if user has imperial selected or not
    const applyUOMForName = (name, usingImperial) => {
        switch (name) {
            // Height
            case "Height (#)":
                if (usingImperial) {
                    name = "Height (in)";
                } else {
                    name = "Height (cm)"
                }
                break;

            // Weight
            case "Weight (kg)":
                if (usingImperial) {
                    name = "Weight (lb)";
                } else {
                    name = "Weight (kg)"
                }
                break;

            // Sit & reach
            case "Sit & reach (cm)":
                if (usingImperial) {
                    name = "Sit & reach (in)";
                } else {
                    name = "Sit & reach (cm)"
                }
                break;

            // Standing broad jump
            case "Standing broad jump (cm)":
                if (usingImperial) {
                    name = "Standing broad jump (in)";
                } else {
                    name = "Standing broad jump (cm)"
                }
                break;

            // Grip strength
            case "Grip strength (kg)":
                if (usingImperial) {
                    name = "Grip strength (lb)";
                } else {
                    name = "Grip strength (kg)"
                }
                break;

            // Vertical jump
            case "Vertical jump (cm)":
                if (usingImperial) {
                    name = "Vertical jump (in)";
                } else {
                    name = "Vertical jump (cm)"
                }
                break;

            // 4 x 10 shuttle run
            case "4 x 10 m shuttle run (s)":
                if (usingImperial) {
                    name = "4 x 11 yd shuttle run (s)";
                } else {
                    name = "4 x 10 m shuttle run (s)"
                }
                break;

            // Maximum sprint speed
            case "Maximum sprint speed (km/h)":
                if (usingImperial) {
                    name = "Maximum sprint speed (mph)";
                } else {
                    name = "Maximum sprint speed (km/h)"
                }
                break;

            // 1.6km run/walk
            case "1.6 km run / walk (min:s)":
                if (usingImperial) {
                    name = "1 mile run / walk (min:s)";
                } else {
                    name = "1.6 km run / walk (min:s)"
                }
                break;

            // Aerobic fitness
            case "Aerobic fitness (mL/kg/min)":
                name = "Aerobic fitness (mL/kg/min)"
                break;
        }

        return name;
    };

    // Converts imperial values to metric depending on the type of object being passed through
    const getMetricOfValue = obj => {
        switch(obj.name) {
            // Height
            case "Height (#)":
                return IM(obj.score).from('in').to('cm');

            // Weight
            case "Weight (kg)":
                return IM(obj.score).from('lb').to('kg');

            // Sit & reach
            case "Sit & reach (cm)":
                return IM(obj.score).from('in').to('cm');

            // Standing broad jump
            case "Standing broad jump (cm)":
                return IM(obj.score).from('in').to('cm');

            // Grip strength
            case "Grip strength (kg)":
                return IM(obj.score).from('lb').to('kg');

            // Vertical jump
            case "Vertical jump (cm)":
                return IM(obj.score).from('in').to('cm');

            // 4 x 10 shuttle run
            case "4 x 10 m shuttle run (s)":
                return obj.score;

            // Maximum sprint speed
            case "Maximum sprint speed (km/h)":
                return IM(obj.score).from('m/h').to('km/h');

            // 1.6km run/walk
            case "1.6 km run / walk (min:s)":
                return obj.score;

            // Aerobic fitness
            case "Aerobic fitness (mL/kg/min)":
                return obj.score;

            // Other
            default: return obj.score;
        }
    }

    /**
     * Goes through and returns a sorted list of user results from filled in to not filled in
     */
    const getReorderedUserDataBasedOnDataInput = (userDataSet) => {
        var userTestsWithData = [];
        var userTestsWithNoData = [];
        var newUserDataSet = [];

        for (var i = 0; i < userDataSet.length; i++) {
            if (userDataSet[i].score !== 0) {
                userTestsWithData.push(userDataSet[i]);
            } else {
                userTestsWithNoData.push(userDataSet[i]);
            }
        }

        newUserDataSet = userTestsWithData.concat(userTestsWithNoData);

        return newUserDataSet;
    }
    const noResultsBlock = obj => {
        if (obj.length === 0) {
            return true
        } else {
            return false
        }
    }

    const buildEliteSportFarRightBoxes = obj => {
        var elemResults = [];

        if (obj.length !== 0) {
            obj = getReorderedUserDataBasedOnDataInput(obj); // Changes the order of the results based off what the user has entered

            for (var i = 0; i < obj.length; i++) {
                var athleteScore = findEliteTestFromTestName(obj[i].name);

                if (typeof athleteScore !== 'undefined') {
                    var result = "";
                    var athleteResult = "";

                    if (obj[i].unit === "KG") {
                        //  + " kg"
                        result = obj[i].score;
                        athleteResult = parseFloat(athleteScore.score).toFixed(0) + "%";
                    }
                    if (obj[i].unit === "LBS") {
                        result = parseFloat(obj[i].score).toFixed(1);
                    }
                    if (obj[i].unit === "CM") {
                        //  + " cm"
                        result = obj[i].score;
                        athleteResult = parseFloat(athleteScore.score).toFixed(0) + "%";
                    }
                    if (obj[i].unit === "NUMBER") { // Numbers without any decimals required
                        result = Math.round(obj[i].score).toFixed(0);
                        athleteResult = Math.round(athleteScore.score).toFixed(0) + "%";
                    }
                    if (obj[i].unit === "DECIMAL") {
                        var studentScoreFormatted = obj[i].score + "";
                        var athleteScoreFormatted = athleteScore.score + "";
                        // var studentIntegerPart = "";
                        // var studentFractionalPart = "";

                        if (studentScoreFormatted.indexOf(".") !== -1) {
                            //     studentIntegerPart = studentScoreFormatted.split(".")[0];
                            //     if (studentScoreFormatted.split(".")[1].length > 1) {
                            //         studentFractionalPart = studentScoreFormatted.split(".")[1].substring(0, 1);
                            //     } else {
                            //         studentFractionalPart = "0"; // due to the fractional part being less than 10, it will be 0
                            //     }

                            result = parseFloat(obj[i].score).toFixed(1);

                            //result = studentIntegerPart + "." + studentFractionalPart;
                        } else {
                            result = parseFloat(obj[i].score).toFixed(1);
                        }

                        if (athleteScoreFormatted.indexOf(".") !== -1) {
                            athleteResult = parseFloat(athleteScore.score).toFixed(1);
                        } else {
                            athleteResult = parseFloat(athleteScore.score).toFixed(1);
                        }

                        athleteResult = parseFloat(athleteResult).toFixed(0) + "%";

                        athleteScoreFormatted = parseFloat(athleteScore.score).toFixed(0) + "%";

                    }
                    if (obj[i].unit === "SECONDS") {
                        // + " sec"
                        result = parseFloat(obj[i].score).toFixed(2);
                        athleteResult = athleteScore.score.toFixed(0) + "%";
                    }
                    if (obj[i].unit === "MINUTES:SECONDS") {
                        var studentMinutes = "";
                        var studentSeconds = "";
                        var athleteMinutes = "";
                        var athleteSeconds = "";

                        studentMinutes = parseFloat(obj[i].score / 60) + "";

                        if (studentMinutes.indexOf(".") !== -1) {
                            studentMinutes = parseFloat(studentMinutes.substr(0, studentMinutes.indexOf(".")));
                        }

                        studentMinutes = convertNumToDoubleDigitString(studentMinutes);

                        studentSeconds = parseFloat(obj[i].score % 60) + "";

                        if (studentSeconds.indexOf(".") !== -1) {
                            studentSeconds = parseFloat(studentSeconds.substr(0, studentSeconds.indexOf(".")));
                        }

                        studentSeconds = convertNumToDoubleDigitString(studentSeconds);

                        athleteMinutes = parseFloat(athleteScore.score / 60) + "";

                        if (athleteMinutes.indexOf(".") !== -1) {
                            athleteMinutes = parseFloat(athleteMinutes.substr(0, athleteMinutes.indexOf(".")));
                        }

                        athleteMinutes = convertNumToDoubleDigitString(athleteMinutes);

                        athleteSeconds = parseFloat(athleteScore.score % 60) + "";

                        if (athleteSeconds.indexOf(".") !== -1) {
                            athleteSeconds = parseFloat(athleteSeconds.substr(0, athleteSeconds.indexOf(".")));
                        }

                        athleteSeconds = convertNumToDoubleDigitString(athleteSeconds);

                        result = studentMinutes + ":" + studentSeconds;
                        athleteResult = athleteMinutes + ":" + athleteSeconds;

                        athleteResult = parseFloat(athleteScore.score).toFixed(0) + "%"
                    }

                    elemResults.push(
                        {
                            athleteResult: athleteResult,
                            studentResult: result,
                            provided: obj[i].isProvided
                        }
                    );

                }

            }
        }
        return elemResults;
    }

    const getBrandColorInBar = (color) => {
        var mainString = 'linear-gradient(to left, ';
        var firstColor = hexToRgbA(color, 1);
        var secondColor = hexToRgbA(color, 0.1);

        return mainString + firstColor + ', ' + secondColor + ')';
    }

    const buildEliteSportsSectionRightHandSide = obj => {
        var elem = [];

        if (obj !== null && obj.length !== 0) {
            obj = orderByDataEntryFromUser(userTestData, obj);

            var elemResults = buildEliteSportFarRightBoxes(userTestData);

            for (var i = 0; i < obj.length; i++) {
                var bestScoreObj = findBestTestFromTestName(obj[i].name);
                var calculatedElitePercentageFromBest = 0;
                var calculatedUserPercentageFromBest = 0;

                var userTestObj = {
                    name: "",
                    score: 0,
                    isTimed: false
                };

                for (var x = 0; x < userTestData.length; x++) {
                    if (userTestData[x].name === obj[i].name) userTestObj = userTestData[x];
                }

                // Get Elite athlete percentage
                if (bestScoreObj.isTimed) {
                    calculatedElitePercentageFromBest = obj[i].score;
                } else {
                    calculatedElitePercentageFromBest = obj[i].score;
                }

                // Calculate user percentage
                if (bestScoreObj.isTimed) {
                    if (userTestObj.score === 0) {
                        calculatedUserPercentageFromBest = 0;
                    } else {
                        calculatedUserPercentageFromBest = calculatePercentageFromBestForTimeScores(bestScoreObj, userTestObj, userTestObj.name);
                    }
                    
                } else {
                    if(userState.userUOM == "Imperial") {
                        calculatedUserPercentageFromBest = calculatePercentageFromBest(bestScoreObj.score, getMetricOfValue(userTestObj));
                    } else {
                        calculatedUserPercentageFromBest = calculatePercentageFromBest(bestScoreObj.score, userTestObj.score);
                    }
                }

                elem.push(
                    <div className="elite-profile-sport-container-individual">
                        <div className="elite-profile-sport-box">
                            <span>{applyUOMForName(obj[i].name, userState.userUOM == "Imperial")}</span>
                        </div>
                        <div className="individual-bar-graph-container">
                            <div className="elite-profile-sport-graph" style={{ width: calculatedElitePercentageFromBest + "%", background: organisationLocalState.organisationPrimaryColor != '' ? getBrandColorInBar(organisationLocalState.organisationPrimaryColor) : 'linear-gradient(to left, rgb(154, 98, 179, 1), rgb(154, 98, 179, 0.1))' }}></div>
                            {elemResults[i].provided ?
                                <div className="loggedin-profile-sport-graph" style={{ width: calculatedUserPercentageFromBest + "%", background: organisationLocalState.organisationAccentColor != '' ? getBrandColorInBar(organisationLocalState.organisationAccentColor) : 'linear-gradient(to left, rgb(80, 179, 131, 1), rgb(80, 179, 131, 0.1))' }}>
                                    <span 
                                        className="loggedin-profile-sport-graph-bubble" 
                                        style={{ color: organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50b383' }}>
                                            {(obj[i].name == "Height (#)" || obj[i].name == "Standing broad jump (cm)") && userState.userUOM == "Imperial" ? ConvertToFeetAndInches(elemResults[i].studentResult) : elemResults[i].studentResult}
                                    </span>
                                </div> : null
                            }
                        </div>
                    </div>
                );
            }
        }
        return elem;
    };

    const getImageFromSelectedSportIndex = () => {
        try {
            var image = images("./" + selectedImage);
            return image;
        } catch (err) {
            console.log("Error = ", err);
        }
    }

    const renderSports = () => {
        var dom = [];

        if (userSports.length !== 0) {
            var firstSport = userSports[0];

            for (var i = 0; i < userSports.length; i++) {
                if (userSports[i].hasOwnProperty("distance")) {
                    var distanceFormatted = parseFloat(userSports[i].distance).toFixed(2);

                    if (distanceFormatted == 0.00) {
                        distanceFormatted = 0.01;
                    }

                    dom.push(
                        <option
                            selected={hasRenderedFirstSport && i == 0 ? true : false}
                            key={"item_" + i}
                            distance={userSports[i].distance}
                            imagepath={userSports[i].image_path}
                            gender={userSports[i].sport_gender}
                            description={userSports[i].sport_description}
                            value={userSports[i].sport_name}>{(i + 1) + ". " + userSports[i].sport_name} {sortByOptionSelected === 'sort-by-all' || sortByOptionSelected === 'sort-by-sportmatch-list' ? "(" + userSports[i].sport_gender + ")" : ""}</option>
                    )
                } else {
                    dom.push(
                        <option
                            selected={hasRenderedFirstSport && i == 0 ? true : false}
                            key={"item_" + i}
                            imagepath={userSports[i].image_path}
                            gender={userSports[i].sport_gender}
                            description={userSports[i].sport_description}
                            value={userSports[i].sport_name}>{userSports[i].sport_name} {sortByOptionSelected === 'sort-by-all' || sortByOptionSelected === 'sort-by-sportmatch-list' ? "(" + userSports[i].sport_gender + ")" : ""}</option>
                    )
                }

            }

            if (!hasRenderedFirstSport) {
                sportSelectionOnChangeHandler(firstSport.sport_name, firstSport.sport_gender, firstSport.image_path);
                setHasRenderedFirstSport(true);
            }
        }



        return dom;
    };

    const renderSportsAvailable = () => {
        if (sortByOptionSelected == "sort-by-sportmatch-list") {
            fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/EliteAthleteProfilesServlet?sort-options=sport-match-list&using_org_sports=true&pafid=${adultState.predictedAdultFitnessId}&gender=${userState.userGender}`,
                { credentials: "include" }
            ).then((data) => {
                data.json().then((result) => {
                    var sportsSudo = [];
                    for (var i = 0; i < result.length; i++) {
                        sportsSudo.push(result[i]);
                    }
                    // sportsSudo.sort((a, b) => a.sport_name > b.sport_name ? 1 : -1);
                    setUserSports(sportsSudo);

                    setSportDescription(result[0].sport_description);
                    setSelectedImage(result[0].image_path);
                    sportSelectionOnChangeHandler(result[0].sport_name, result[0].sport_gender, result[0].image_path);
                    if (document.getElementsByClassName("sport-selection-container")[0]) {
                        document.getElementsByClassName("sport-selection-container")[0].selectedIndex = 0;
                    }
                    document.getElementById("sportSelect").selectedIndex = "0";


                    // simulateMouseClick(element);
                }).catch(() => {
                    console.log("Unable to pass data returned into JSON format");
                })
            }).catch(() => {
                console.log("Exception hit, unable to get Athlete data back from backend");
            })
        } else {
            fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/EliteAthleteProfilesServlet?gender=${getGenderSelected(sortByOptionSelected)}`,
                { credentials: "include" }
            ).then((data) => {
                data.json().then((result) => {
                    var sportsSudo = [];
                    for (var i = 0; i < result.length; i++) {
                        sportsSudo.push(result[i]);
                    }

                    setUserSports(sportsSudo);

                    setSportDescription(result[0].sport_description);
                    setSelectedImage(result[0].image_path);
                    sportSelectionOnChangeHandler(result[0].sport_name, result[0].sport_gender, result[0].image_path);
                    if (document.getElementsByClassName("sport-selection-container")[0]) {
                        document.getElementsByClassName("sport-selection-container")[0].selectedIndex = 0;
                    }
                    document.getElementById("sportSelect").selectedIndex = "0";

                    // simulateMouseClick(element);
                }).catch(() => {
                    console.log("Unable to pass data returned into JSON format");
                })
            }).catch(() => {
                console.log("Exception hit, unable to get Athlete data back from backend");
            });
        }
    }

    useEffect(() => {
        renderSportsAvailable();
    }, [sortByOptionSelected]);

    const onSortByClick = (e) => {
        if (sortByOptionSelected !== e.target.id) {
            setSortByOptionSelected(e.target.id);
        }
    }

    return (
        <div className="screen">
            <div className="elite-athlete-general-container elite-athlete-container-wrapper">
                <div className="elite-athlete-general-container elite-athlete-left-container">
                    <div className="elite-athlete-image-container">
                        <img src={getImageFromSelectedSportIndex()} height={"auto"} width={"100%"} alt="Sport" />
                        <div className="sport-description-container">
                            <div>
                                <span>
                                    {sportDescription}
                                </span>
                            </div>

                        </div>
                    </div>
                    <div className="elite-athlete-sports-heading-left-container">
                        {loadingSportData && <> <CircularProgress disableShrink size={20} /> &ensp;</>}
                        <span className="elite-athlete-sports-heading-left">Sports</span>
                    </div>

                    <select onChange={(e) => sportSelectionOnChangeHandler(e.target.value, e.target.options[e.target.selectedIndex].getAttribute("gender"), e.target.options[e.target.selectedIndex].getAttribute("imagepath"), e.target.options[e.target.selectedIndex].getAttribute("description"))} className="sport-selection-container" size={90}>
                        {renderSports()}
                    </select>

                    {/** Sort by container */}
                    <div className="sort-by-container">
                        <div className="sort-by-section">
                            <span>Sort by:</span>
                        </div>
                        <div className="sort-by-radiobuttons">
                            <div>
                                <input checked id="sort-by-all" type="radio" name="sort-by" onClick={onSortByClick} />
                                <label for="sort-by-all" className={"sort-by-radio-visible" + (sortByOptionSelected === "sort-by-all" ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: sortByOptionSelected === "sort-by-all" ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : '' }}></label>
                                <label for="sort-by-all">All</label>
                            </div>

                            <div>
                                <input id="sort-by-male" type="radio" name="sort-by" onClick={onSortByClick} />
                                <label for="sort-by-male" className={"sort-by-radio-visible" + (sortByOptionSelected === "sort-by-male" ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: sortByOptionSelected === "sort-by-male" ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : '' }}></label>
                                <label for="sort-by-male">Male</label>
                            </div>

                            <div>
                                <input id="sort-by-female" type="radio" name="sort-by" onClick={onSortByClick} />
                                <label for="sort-by-female" className={"sort-by-radio-visible" + (sortByOptionSelected === "sort-by-female" ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: sortByOptionSelected === "sort-by-female" ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : '' }}></label>
                                <label for="sort-by-female">Female</label>
                            </div>

                            <div>
                                <input id="sort-by-sportmatch-list" type="radio" name="sort-by" onClick={onSortByClick} />
                                <label for="sort-by-sportmatch-list" className={"sort-by-radio-visible" + (sortByOptionSelected === "sort-by-sportmatch-list" ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: sortByOptionSelected === "sort-by-sportmatch-list" ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : '' }}></label>
                                <label for="sort-by-sportmatch-list">My SportMatch list</label>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="elite-athlete-general-container elite-athlete-right-container">
                    {!noResultsBlock(userSport) ? <React.Fragment>
                        <div className="elite-profile-sport-container">
                            {buildEliteSportsSectionRightHandSide(userSport)}

                            <div className="graident-1"></div>
                            <div className="graident-2"></div>
                            <div className="graident-3"></div>
                            <div className="graident-4"></div>
                        </div>

                    </React.Fragment>
                        : <div style={{ width: "99%", position: 'relative', left: "10px" }}><div class="no-previous-tests"><span>You have not yet completed a SportMatch test</span></div></div>}
                </div>
            </div>
        </div>
    );
}

export default EliteAthleteProfiles;
