import React from "react";

const AerobicSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Aerobic</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="aerobic_fitness" data-name="aerobic fitness">
            <path className="cls-2" style={{fill: color}}
                  d="M328.7,339.16l-21.35,86.25a9.23,9.23,0,0,1-9,7l-.56,0a9.24,9.24,0,0,1-8.61-8.1L268.58,254.9l-22.17,91a9.24,9.24,0,0,1-17.77.62l-7.37-23.1-7.16,12.82A9.25,9.25,0,0,1,206,341H137.73c23.48,25.35,54.22,51.55,90.15,82.17,14.35,12.23,30.62,26.1,47.52,40.88a12.24,12.24,0,0,0,16.12,0c16.91-14.79,33.18-28.65,47.54-40.88,36.8-31.37,68.16-58.09,91.84-84Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M200.63,322.54l14.9-26.66a9.23,9.23,0,0,1,16.86,1.69l3.85,12.07,26.4-108.4a9.23,9.23,0,0,1,18.13,1.06l20.71,170,11-44.55a9.23,9.23,0,0,1,9-7h125c21-27.24,32.88-54.61,32.88-86.63,0-64.36-44.75-112.89-104.08-112.89-21.26,0-40.76,6.73-57.94,20-16.47,12.74-27.44,29-33.9,40.77-6.45-11.8-17.42-28-33.89-40.77-17.18-13.29-36.68-20-57.94-20-59.33,0-104.08,48.53-104.08,112.89,0,32.74,12.39,60.62,34.32,88.47Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default AerobicSvg;
