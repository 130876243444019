import { Card, CardContent, Dialog, Slide } from '@material-ui/core';
import React from 'react';

/** Required to show the dialog appearing from the bottom of the screen */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  /**
   * SportMatchDialog - Is a component which contains a generic dialog
   * @param {func} cancelButtonHandler Event handler for "Cancelling the dialog"
   * @param {func} submitButtonHandler Event handler for "Submitting the dialog"
   * @param {string} message Determins if the dialog should be shown to the UI
   * @param {string} show Determins if the dialog should be shown to the UI 
   * @returns DOM element of Dialog
   */
const SportMatchDialog = ({show, message, submitButtonHandler, cancelButtonHandler}) => {
    return (
        <Dialog  open={show} TransitionComponent={Transition} keepMounted onClose={null}>
            <Card className={"first-time-making-test-card"}>
                <CardContent>
                    {message}

                    <div className="card-button-container">
                        <button style={{ marginLeft: 'auto', width: '80px' }} onClick={() => cancelButtonHandler()}>Cancel</button>
                        <button onClick={() => submitButtonHandler()} className="begin-btn">Yes</button>
                    </div>
                </CardContent>
            </Card>
        </Dialog>
    )
}

export default SportMatchDialog;