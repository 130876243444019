// REACT
import React, { useRef, useState, useEffect } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import { generateGroupReportPDF } from "./GroupReportPDF.js";

import GroupReportResources from "../groupReport/GroupReportResources.js";

// MOMENTJS
import moment from "moment";

// STYLES
import "./style.css";

// REACT FILE READER
import ReactFileReader from "react-file-reader";

// MATERIAL UI COMPONENTS
import Button from "@material-ui/core/Button";
import {
	Menu,
	MenuItem,
	Card,
	CardContent,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

// REACT TOAST NOTIFICATIONS
import { useToasts } from "react-toast-notifications";

// USER DEFINED COMPONENTS
import StudentRowComponent from "../../../../components/organizationAdminComponents/classInfoComponents/StudentRowComponent.js";
import NewStudentComponent from "./NewStudentComponent.js";

// SHEETJS
import XLSX from "xlsx";

import { AUS_NZ_NORM_NAME, BACKEND_URL, ENVIRONMENT_PATH, QAS_ORG_NAME } from "../../../../store/constants";

// CHARTJS
import Chart from "chart.js";
import "chartjs-plugin-labels";
import Filter from "../filter/index.js";

// JQUERY
import $ from "jquery";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { DownloadButton } from "../../../../components/Buttons.js";
import { CheckIfUserSessionIsValid, ConvertValueToImperial, createQueueRequestArray } from "../../../../api/Helper.js";

import IM from 'convert-units';
import { ConvertToFeetAndInches, checkIfNullOrStringEmptyOrMinusOne, toFixedNoRounding } from "../../../../helper/helper.js";
import { hexToRgbA } from "../../../../helper/hexToRgbA.js";
import Axios from "axios";
import SportMatchDialog from "../../../../components/sportMatchDialogComponent.js";
import {
	calculateAverageCompareResults,
	getResultOverviewCSVData,
	getTestDataResultObjectsForCSV,
	getWorksheetHeadings,
	getWorksheetTestDataHeadings,
	TemplateTypeEnum
} from "./classExport/worksheetFields";
import { QasReport } from "../../reports/QasReport/QasReportResource.js";
import { useGenerateQASReport, LoadingReportEnum } from "../../reports/QasReport/QASReportHelper.ts";
import { RetrieveQASUserInfo, DownloadEmailSendLog, StartQueue, requestTemplateType } from "../../../../api/endpoints.ts";

/** Required to show the dialog appearing from the bottom of the screen */
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

let cancelOrganisationSource = Axios.CancelToken.source();
let cancelIndividualReportToken = Axios.CancelToken.source();
let cancelGroupReportToken = Axios.CancelToken.source();

const cancelQASUserRetrieval = Axios.CancelToken.source();
const cancelQASGenReport = Axios.CancelToken.source();
const cancelQueueProcessToken = Axios.CancelToken.source();

const ClassInfoScreen = ({ modeDisplay, disableUserEditing = false, type, setModeDisplay, className }) => {
	const { loadingReport, doGenerateQASReport } = useGenerateQASReport();

	const state = useSelector(state => state);

	const isQas = state.user.userOrganisationName == QAS_ORG_NAME;

	const dispatch = useDispatch();

	// Header images for Group report
	const sitAndReachImage = useRef(null);
	const standingBroadJumpImage = useRef(null);
	const bentKneeSitUpsImage = useRef(null);
	const gripStrengthImage = useRef(null);
	const verticalJumpImage = useRef(null);
	const inclinedPullUpsImage = useRef(null);
	const shuttleRunImage = useRef(null);
	const sprintSpeedImage = useRef(null);
	const walkRunImage = useRef(null);
	const beepTestImage = useRef(null);

	// Anthropometry Images
	const heightImage = useRef(null);
	const weightImage = useRef(null);
	const armSpanImage = useRef(null);
	const brachialIndexImage = useRef(null);
	const sittingHeightImage = useRef(null);

	// Loading indicators
	const [importLoading, setImportLoading] = useState(false);
	const [individualReportLoading, setIndividualReportLoading] = useState(false);
	const [exportUsersLoading, setExportUsersLoading] = useState(false);
	const [deleteUserLoading, setDeleteUserLoading] = useState(false);
	const [assignTokenLoading, setAssignTokenLoading] = useState(false);
	const [resetTestsLoading, setResetTestsLoading] = useState(false);

	const [elements, setElements] = useState([]);

	// confirm dialogs
	const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
	const [showResetTestDialog, setShowResetTestDialog] = useState(false);

	// Number of clicks made (manual)
	const [numberOfClicks, setNumberOfClicks] = useState(0);

	// Number of reports generated
	const [numberOfReportsGenerated, setNumberOfReportsGenerated] = useState(0);

	// Used to control whether document download interval should run or not
	const downloadDocumentInterval = useRef(null);

	const controller = useSelector((state) => state.user.fetchController);

	const [classUsersDisplayed, setClassUsersDisplayed] = useState([]);
	const [classUsers, setClassUsers] = useState([]);
	const [userTests, setUserTests] = useState([]);
	const [loading, setLoading] = useState(false);

	const userState = useSelector((state) => state.user);
	const reduxState = useSelector((state) => state);

	const organisationLocalState = useSelector(
		(state) => state.organisationState
	);

	const [fetchSize, setFetchSize] = useState(0);
	const [pageSize, setPageSize] = useState(20);

	const usingImperial = userState.userUOM == "Imperial";

	// React Toast Notifications Object
	const { addToast } = useToasts();

	const [anchorEl, setAnchorEl] = useState(null);
	const [localUserIdsSelected, setLocalUserIdsSelected] = useState([]);
	const [groupsAvailable, setGroupsAvailable] = useState([]);

	const [testingLink, setTestingLink] = useState("");

	const [csvFileUpload, setCSVFileUpload] = useState(null);
	const [csvFileWithLines, setCSVFileWithLines] = useState(null);
	const [csvFileUploadPercentage, setCSVFileUploadPercentage] = useState(0);

	const primaryColor =
		organisationLocalState.organisationPrimaryColor != ""
			? organisationLocalState.organisationPrimaryColor
			: "rgb(90, 83, 128)";
	const secondaryColor =
		organisationLocalState.organisationSecondaryColor != ""
			? organisationLocalState.organisationSecondaryColor
			: "rgb(119, 133, 153)";
	const accentColor =
		organisationLocalState.organisationAccentColor != ""
			? organisationLocalState.organisationAccentColor
			: "rgb(80, 179, 131)";

	const handleCSVFiles = (files) => {
		var reader = new FileReader();
		reader.onload = function (e) {
			var allTextLines = reader.result.split(/\r\n|\n/);

			setCSVFileWithLines(allTextLines);
			setCSVFileUpload(reader.result);
		};
		reader.readAsText(files[0]);
	};

	const searchTimeoutRef = useRef(null);
	const searchRef = useRef(null);

	const buildStringOfUserIds = () => {
		var userIds = "";

		for (var i = 0; i < localUserIdsSelected.length; i++) {
			userIds +=
				localUserIdsSelected[i] +
				(i === localUserIdsSelected.length - 1 ? "" : ",");
		}

		return userIds;
	};

	const retrieveListOfAvailableGroupName = (data) => {
		if (data[0]) {
			var arrayOfGroupNames = [];
			var jsonGroupData = data[0].org_groups;

			if (jsonGroupData.length > 0) {
				for (var i = 0; i < jsonGroupData.length; i++) {
					if (jsonGroupData[i]) {
						if (!arrayOfGroupNames.includes(jsonGroupData[i])) {
							arrayOfGroupNames.push(jsonGroupData[i]);
						}
					}
				}
			}

			setGroupsAvailable(arrayOfGroupNames);
		}
	};

	useEffect(() => {
		fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/GetTestingLink`, {
			method: "GET",
			credentials: "include",
		}).then((res) => {
			CheckIfUserSessionIsValid(dispatch).then(() => {
				res.json().then((result) => {
					setTestingLink(result.link);
				}).catch((error) => {
					addToast(`An error occured`, {
						autoDismiss: true,
						appearance: "error",
					});
				});
			})
		}).catch((er) => {
			addToast(`An error occured`, {
				autoDismiss: true,
				appearance: "error",
			});
		});
	}, []);

	const deleteUserDialogOnClickHandler = (action) => {
		setShowDeleteUserDialog(false);
		if (action)
			deleteUsersOnClickHandler();
	}

	const resetTestDialogOnClickHandler = (action) => {
		setShowResetTestDialog();
		if (action)
			resetTestDetailsHandler();
	}

	const deleteUsersOnClickHandler = () => {
		var userIds = buildStringOfUserIds();

		var urlencoded = new URLSearchParams();
		urlencoded.append("user_ids", userIds);

		CheckIfUserSessionIsValid(dispatch).then(() => {
			setDeleteUserLoading(true);
			fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=delete_users`, {
				method: 'POST',
				headers: {
					Accept: "application/json",
				},
				credentials: "include",
				body: urlencoded
			}
			).then((res) => {
				CheckIfUserSessionIsValid(dispatch).then(() => {
					$(".view-tests-btn:not(.collapsed)").click();
					setAnchorEl(null); // Hiding the select menu
					res.json().then((data) => {
						var numberOfRowsAffected = data.noOfDeletedUsers;
						renderUsers(false);
						addToast(
							"Successfully removed user(s): Number of users affected: " +
							numberOfRowsAffected,
							{
								appearance: "success",
								autoDismiss: true,
							}
						);
						setDeleteUserLoading(false);
					}).catch(() => setDeleteUserLoading(false));
				}).catch(() => setDeleteUserLoading(false));
			}).catch(() => setDeleteUserLoading(false));
		})
	};

	const getUserTestsById = (student_id) => {
		var userTestsToSendBack = [];
		for (var i = 0; i < userTests.length; i++) {
			if (userTests[i].user_id === student_id) {
				userTestsToSendBack.push(userTests[i]);
			}
		}
		return userTestsToSendBack;
	};

	const [classImportFile, setClassImportFile] = useState(null);

	const [showStartTestDialog, setShowStartTestDialog] = useState(false);

	const [userReport, setUserReport] = useState([]);

	const [userTestAges, setUserTestAges] = useState([]);
	const [appliedUserAges, setAppliedUserAges] = useState(false);

	const uploadClassImportFile = (e) => {
		if (e.target.files && e.target.files[0]) {
			var files = e.target.files,
				f = files[0];
			var reader = new FileReader();
			reader.onload = function (e) {
				var data = new Uint8Array(e.target.result);
				var workbook = XLSX.read(data, { type: "array" });

				var first_sheet_name = workbook.SheetNames[0];
				var worksheet = workbook.Sheets[first_sheet_name];
				setClassImportFile(XLSX.utils.sheet_to_json(worksheet));
			};
			reader.readAsArrayBuffer(f);
		}

		e.target.value = "";
	};

	const renderUsers = (continuedLoading = false) => {
		if (
			reduxState.organisationState.groupNameFilters.length == 0 &&
			reduxState.organisationState.genderFilters.length == 0 &&
			reduxState.organisationState.ageFilters.length == 0 &&
			reduxState.organisationState.dateRangeFromFilter.length == 0 &&
			reduxState.organisationState.dateRangeToFilter.length == 0
		) {
			Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=retrieveUsers&fetchPage=${fetchSize}&pageSize=${pageSize}`, {
				withCredentials: true,
				cancelToken: cancelOrganisationSource.token
			}).then(res => {
				var students = [];

				if (continuedLoading) {
					students = students.concat(classUsers);
				}

				if (groupsAvailable.length == 0) {
					retrieveListOfAvailableGroupName(res.data);
				}

				for (var i = 1; i < res.data.length; i++) {
					var row = res.data[i];
					students.push(row.student_details);
				}

				setClassUsers(students);
			}).catch((error) => {
				if (Axios.isCancel(error)) {
					// TODO: Display error on screen
				}
			});
		} else {
			var filterString = filterStringBuilder();
			if (continuedLoading == false) {

				Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=retrieveUsers` + encodeURI(filterString) + `&filtered=true&fetchPage=${fetchSize}&pageSize=${pageSize}`, {
					withCredentials: true,
					cancelToken: cancelOrganisationSource.token
				}).then(res => {
					CheckIfUserSessionIsValid(dispatch).then(() => {
						var students = [];
						var tests = [];

						if (continuedLoading) {
							students = students.concat(classUsers);
							tests.concat(userTests);
						}

						if (groupsAvailable.length == 0) {
							retrieveListOfAvailableGroupName(res.data);
						}

						for (var i = 1; i < res.data.length; i++) {
							var row = res.data[i];
							if(row.student_tests.length > 0) {
								students.push(row.student_details);
								for (var x = 0; x < row.student_tests.length; x++) {
									tests.push(row.student_tests[x]);
								}
							}
						}

						setClassUsers(students);
						setUserTests(tests);
					})
				}).catch((error) => {
					if (Axios.isCancel(error)) {
						// TODO: Display error on screen
					}
				});
			}
		}

		setLocalUserIdsSelected([]);
	};

	/**
	 * exportTestDataInCSV
	 * 
	 * Commits test data (raw values) to CSV row
	 * @param {*} singleRow The CSV row to modify
	 * @param {*} test The test with raw test values returned from the backend
	 */
	const exportTestDataInCSV = (singleRow, test) => {
		var arrayOfDataValues = getTestDataResultObjectsForCSV(test);

		if (test != null) {
			var outputValue = "";
			var object = {};

			for (var i = 0; i < arrayOfDataValues.length; i++) {
				object = arrayOfDataValues[i];
				outputValue = "";

				if (object.name) {
					if (object.name != "yoyo" && object.name != "shuttleLevel") {
						if (object.result && object.result != "" && !isNaN(object.result) && object.result > 0) {
							if (usingImperial && object.imperialProperties.enabled) {
								if (object.imperialProperties.convertFeetAndInches) {
									outputValue = ConvertToFeetAndInches(parseFloat(IM(object.result).from(object.imperialProperties.from).to(object.imperialProperties.to)));
								} else {
									outputValue = ConvertValueToImperial(object.result, object.imperialProperties.from, object.imperialProperties.to, object.imperialProperties.formatAmount, object.name == "sprintDistance");
								}
							} else {
								outputValue = parseFloat(parseFloat(object.result).toFixed(object.metricFormatAmount));
							}
						}
					} else {
						// TODO: ensure that "shuttleLevel" supports "-1" as unavailable
						if (object.result != -1) {
							outputValue = object.result;
						}
					}
				}

				singleRow.push(outputValue);
			}
		}
	}

	const requestFromBackendCSVData = (temparray, ws_data, templateType = '') => {
		return new Promise((resolve, reject) => {
			var startDateFilter = "";
			var endDateFilter = "";
			var paramString = "";
			var requestUrl = 'DownloadGroupDataCSV';

			if (reduxState.organisationState.dateRangeFromFilter) {
				startDateFilter = moment(reduxState.organisationState.dateRangeFromFilter).format("DD/MM/YYYY");
				paramString = "?startDateFilter=" + startDateFilter;
			}

			if (reduxState.organisationState.dateRangeFromFilter && reduxState.organisationState.dateRangeToFilter) {
				endDateFilter = moment(reduxState.organisationState.dateRangeToFilter).format("DD/MM/YYYY");
				paramString += "&endDateFilter=" + endDateFilter;
			}

			requestUrl += paramString;

			if (templateType !== "") {
				if (paramString != "") {
					requestUrl += `&templateType=${templateType}`;
				} else {
					requestUrl += `?templateType=${templateType}`;
				}
			}

			fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/${requestUrl}`, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify(temparray),
			}).then((res) => {
				CheckIfUserSessionIsValid(dispatch).then(() => {
					res.json().then((result) => {
						for (var i = 0; i < result.length; i++) {
							const singleRow = [];
							var object = result[i];
							let student = object.student;

							var categories = null;
							var test = null;
							var predictedAdultFitness = null;
							var topSports = null;
							var compareResults = null;

							if (object.array_of_categories != null) {
								categories = object.array_of_categories;
							}

							if (object.raw_data != null) {
								test = object.raw_data;

								if (test.predictedAdultFitnesses[0] != null) {
									predictedAdultFitness = test.predictedAdultFitnesses[0];
								}
							} else {
								continue;
							}

							if (object.sports != null) {
								topSports = object.sports;
							}

							if (object.compare_results != null) {
								compareResults = object.compare_results;
							}

							if (templateType === 'qas_template') {
								singleRow.push(student.email ?? ''); // User ID (new template)
								singleRow.push(student.username); // Username
								singleRow.push(
									test != null ? (usingImperial ? moment(test.testDate).format("MM/DD/YYYY") : moment(test.testDate).format("DD/MM/YYYY")) : ""
								); // Test date
								singleRow.push(
									test.group != null ? test.group : ""
								); // Group (new template)
								singleRow.push(student.gender); // Gender
								singleRow.push(parseFloat(student.user_age).toFixed(2)); // User age

								// User height
								singleRow.push(
									test != null && test.height >= 0
										? parseFloat(test.height.toFixed(1)) : ""
								);

								// Height comparison percentage
								singleRow.push(
									compareResults != null && compareResults.height >= 0
										? parseFloat(compareResults.height.toFixed(1))
										: ""
								);

								// Father height
								singleRow.push(
									test.fathersHeight != null && test.fathersHeight != ""
										? parseFloat(test.fathersHeight.toFixed(1)) : ""
								);

								// Mother height
								singleRow.push(
									test.mothersHeight != null && test.mothersHeight != ""
										? parseFloat(test.mothersHeight.toFixed(1)) : ""
								);

								// Predicted height
								singleRow.push(predictedAdultFitness?.height != null &&
									predictedAdultFitness?.height !== ""
									? parseFloat(predictedAdultFitness?.height.toFixed(1)) : ""
								);

								// User weight
								singleRow.push(
									test.weight != null && test.weight != ""
										? parseFloat(test.weight.toFixed(1)) : ""
								);

								// Weight comparison percentage
								singleRow.push(
									compareResults != null && compareResults.weight >= 0
										? parseFloat(compareResults.weight.toFixed(1))
										: ""
								);

								// Arm Span
								singleRow.push(
									test.armSpan != null && test.armSpan != ""
										? parseFloat(test.armSpan.toFixed(1)) : ""
								);

								// Arm span percentile
								singleRow.push(
									compareResults != null && compareResults.arm_span >= 0
										? parseFloat(compareResults.arm_span.toFixed(1))
										: ""
								);

								// Arm span height ratio
								if (test.armSpan != null && test.armSpan != "" &&
									test.height != null && test.height != "") {
									singleRow.push(`${parseFloat((test.armSpan / test.height) * 100).toFixed(1)}%`);
								} else {
									singleRow.push(``);
								}

								// Sitting height
								singleRow.push(
									test.sittingHeight != null && test.sittingHeight != ""
										? parseFloat(test.sittingHeight.toFixed(1)) : ""
								);

								// Sitting height comparison percentage
								singleRow.push(
									compareResults != null && compareResults.sitting_height_ration >= 0
										? parseFloat(compareResults.sitting_height_ration.toFixed(1))
										: ""
								);

								// Sitting height ratio
								if (test.sittingHeight != null && test.sittingHeight != "" &&
									test.height != null && test.height != "") {
									singleRow.push(parseFloat(parseFloat((test.sittingHeight / test.height) * 100).toFixed(2)));
								} else {
									singleRow.push("");
								}

								singleRow.push(
									test.phv_index != null && test.phv_index != ""
										? parseFloat(test.phv_index.toFixed(2)) : ""
								);

								// Vertical Jump
								singleRow.push(
									test.verticalJump != null && test.verticalJump != ""
										? parseFloat(test.verticalJump.toFixed(1)) : ""
								);

								// Vertical jump comparison percentage
								singleRow.push(
									compareResults != null && compareResults.power >= 0
										? parseFloat(compareResults.power.toFixed(1))
										: ""
								);

								// Inclined pull-ups
								singleRow.push(
									test.inclinedPullup != null && test.inclinedPullup != ""
										? parseFloat(test.inclinedPullup.toFixed(1)) : ""
								);

								// Pull ups comparison percentage
								singleRow.push(
									compareResults != null && compareResults.pullup >= 0
										? parseFloat(compareResults.pullup.toFixed(1))
										: ""
								);

								// Sprint time
								singleRow.push(
									test.sprintTime != null && test.sprintTime != ""
										? parseFloat(test.sprintTime.toFixed(2)) : ""
								);

								// Sprint speed comparison percentage
								singleRow.push(
									compareResults != null && compareResults.speed >= 0
										? parseFloat(compareResults.speed.toFixed(1))
										: ""
								);

								// Shuttle run
								singleRow.push(
									test.shuttleRun != null && test.shuttleRun != ""
										? parseFloat(test.shuttleRun.toFixed(2)) : ""
								);

								// 4 x 10 m shuttle comparison percentage
								singleRow.push(
									compareResults != null && compareResults.shuttle >= 0
										? parseFloat(compareResults.shuttle.toFixed(1))
										: ""
								);

								// Shuttle beep
								singleRow.push(
									test.shuttleLevel != null && test.shuttleLevel != "-1"
										? test.shuttleLevel : ""
								);

								singleRow.push(
									compareResults != null &&
										!checkIfNullOrStringEmptyOrMinusOne(compareResults.endurance) &&
										!isNaN(compareResults.endurance) ? parseFloat(toFixedNoRounding(compareResults.endurance, 1)) : ''
								);
								
								var averagePercentile = null;
								if(compareResults!= null) {
									averagePercentile = calculateAverageCompareResults(compareResults, TemplateTypeEnum.QAS_TEMPLATE);
								}

								// Average %'ile rank
								singleRow.push(
									`${(isNaN(averagePercentile) ? 0 : parseFloat(averagePercentile.toFixed(1)))}%`
								);

								singleRow.push(test.maturation_age != null ? test.maturation_age : "");
							} else {
								singleRow.push(student.username); // Username
								singleRow.push(
									test != null ? (usingImperial ? moment(test.testDate).format("MM/DD/YYYY") : moment(test.testDate).format("DD/MM/YYYY")) : ""
								); // Test date
								singleRow.push(student.gender); // Gender
								singleRow.push(parseFloat(student.user_age)); // User age
								singleRow.push(
									predictedAdultFitness != null
										? parseFloat(predictedAdultFitness.predicted_fitness_age.toFixed(1))
										: ""
								); // User fitness age

								// Find my sport fields
								singleRow.push(topSports != null ? topSports[0].sport : ""); // Top sport 1
								singleRow.push(
									topSports != null && topSports[0].final_distance >= 0
										? parseFloat(topSports[0].final_distance.toFixed(1))
										: ""
								); // Top sport 1 OZ Score
								singleRow.push(topSports != null ? topSports[1].sport : ""); // Top sport 2
								singleRow.push(
									topSports != null && topSports[1].final_distance >= 0
										? parseFloat(topSports[1].final_distance.toFixed(1))
										: ""
								); // Top sport 2 OZ Score
								singleRow.push(topSports != null ? topSports[2].sport : ""); // Top sport 3
								singleRow.push(
									topSports != null && topSports[2].final_distance >= 0
										? parseFloat(topSports[2].final_distance.toFixed(1))
										: ""
								); // Top sport 3 OZ Score
								singleRow.push(topSports != null ? topSports[3].sport : ""); // Top sport 4
								singleRow.push(
									topSports != null && topSports[3].final_distance >= 0
										? parseFloat(topSports[3].final_distance.toFixed(1))
										: ""
								); // Top sport 4 OZ Score
								singleRow.push(topSports != null ? topSports[4].sport : ""); // Top sport 5
								singleRow.push(
									topSports != null && topSports[4].final_distance >= 0
										? parseFloat(topSports[4].final_distance.toFixed(1))
										: ""
								); // Top sport 5 OZ Score

								singleRow.push(categories != null ? categories[0].name : "");
								singleRow.push(
									categories != null ? parseFloat(categories[0].value.toFixed(1)) : ""
								);
								singleRow.push(categories != null ? categories[1].name : "");
								singleRow.push(
									categories != null ? parseFloat(categories[1].value.toFixed(1)) : ""
								);
								singleRow.push(categories != null ? categories[2].name : "");
								singleRow.push(
									categories != null ? parseFloat(categories[2].value.toFixed(1)) : ""
								);
								singleRow.push(categories != null ? categories[3].name : "");
								singleRow.push(
									categories != null ? parseFloat(categories[3].value.toFixed(1)) : ""
								);
								singleRow.push(categories != null ? categories[4].name : "");
								singleRow.push(
									categories != null ? parseFloat(categories[4].value.toFixed(1)) : ""
								);
								singleRow.push(categories != null ? categories[5].name : "");
								singleRow.push(
									categories != null ? parseFloat(categories[5].value.toFixed(1)) : ""
								);

								//Compare my results fields

								// Sit and reach comparison percentage
								getResultOverviewCSVData(singleRow, compareResults);

								var averagePercentile = calculateAverageCompareResults(compareResults, TemplateTypeEnum.NORMAL);

								singleRow.push(
									`${(isNaN(averagePercentile) ? 0 : parseFloat(averagePercentile.toFixed(2)))}%`
								);

								if (organisationLocalState.plan != "Starter") {
									if (test) {
										exportTestDataInCSV(singleRow, test);
									}
								}
							}

							singleRow.push(); // TODO: Average percentile

							ws_data.push(singleRow);
							resolve();
						}
					}).catch(err => {
						setExportUsersLoading(false);
						if (localUserIdsSelected.length == 0) {
							addToast("No users selected", {
								appearance: "error",
								autoDismiss: true,
							});
						}
						reject();
					});
				}).catch(err => {
					setExportUsersLoading(false);
					reject();
				})
			}).catch(err => {
				setExportUsersLoading(false);
				reject();
			});
		})
	}

	const getOrgDefaultPassword = async () => {
		return new Promise(async (resolve, reject) => {
			await fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=get_org_details`, { credentials: "include" }).then(res => {
				res.json().then(result => {
					if (result.org_default_password) {
						resolve(result.org_default_password);
					} else {
						// TODO: Display error on screen
						reject();
					}
				}).catch(exception => {
					// TODO: Display error on screen
					reject();
				});
			}).catch(err => {
				// TODO: Display error on screen
				reject();
			})
		});
	}

	/**
	 * Exports a CSV of student name and default password
	 */
	const testTakerUserDetailsExport = async () => {
		setExportUsersLoading(true);
		var defaultPassword = "";

		await getOrgDefaultPassword().then(result => {
			defaultPassword = result;
		});

		var wb = XLSX.utils.book_new(); // Instantiate a new workbook
		wb.SheetNames.push("user_export");

		var ws_data = [
			["Username", "Default Password"]
		];

		for (var i = 0; i < classUsers.length; i++) {
			if (localUserIdsSelected.length > 0) {
				if (localUserIdsSelected.includes(classUsers[i]["user_id"])) {
					ws_data.push([classUsers[i]["user_name"], defaultPassword]);
				}
			} else {
				ws_data.push([classUsers[i]["user_name"], defaultPassword]);
			}
		}

		var ws = XLSX.utils.aoa_to_sheet(ws_data);
		wb.Sheets["user_export"] = ws;

		XLSX.writeFile(wb, "Export user data (csv) from Test takers.xlsx");
		setExportUsersLoading(false);
	}

	/**
	 * Exports a CSV of the test data completed of all students
	 */
	const testTakerDownloadFileHandler = async () => {
		const templateTypeRequestObj = await requestTemplateType();
		const templateType = templateTypeRequestObj.template_type ?? '';

		setExportUsersLoading(true);

		var i, j, temparray, chunk = 10;
		var atEndOfChunks = false;

		var wb = XLSX.utils.book_new(); // Instantiate a new workbook
		wb.SheetNames.push("class_import");

		var testDateHeading = "(dd/mm/yyyy)";

		if (usingImperial) testDateHeading = "(mm/dd/yyyy)";

		var ws_data = getWorksheetHeadings(testDateHeading, usingImperial, templateType);

		if (organisationLocalState.plan !== "Starter" && templateType !== 'qas_template') {
			ws_data[0].push(...getWorksheetTestDataHeadings(usingImperial))
		}

		if (localUserIdsSelected.length == 0) {
			var classUserIds = [];

			for (var i = 0; i < classUsersDisplayed.length; i++) classUserIds.push(classUsersDisplayed[i]["user_id"]);

			for (i = 0, j = classUserIds.length; i < j; i += chunk) {
				temparray = classUserIds.slice(i, i + chunk);

				if (temparray.length < chunk) atEndOfChunks = true;

				await requestFromBackendCSVData(temparray, ws_data, templateType);
			}
		} else {
			for (i = 0, j = localUserIdsSelected.length; i < j; i += chunk) {
				temparray = localUserIdsSelected.slice(i, i + chunk);

				if (temparray.length < chunk) atEndOfChunks = true;

				await requestFromBackendCSVData(temparray, ws_data, templateType);
			}
		}

		if (atEndOfChunks) {
			var ws = XLSX.utils.aoa_to_sheet(ws_data);
			wb.Sheets["class_import"] = ws;

			XLSX.writeFile(wb, "Export class data (csv) from Results overview screen.xlsx");

			setExportUsersLoading(false);
		}
	};

	useEffect(() => {
		cancelOrganisationSource.cancel();
		cancelOrganisationSource = Axios.CancelToken.source();

		cancelIndividualReportToken.cancel();
		cancelIndividualReportToken = Axios.CancelToken.source();

		cancelGroupReportToken.cancel();
		cancelGroupReportToken = Axios.CancelToken.source();

		renderUsers(false);
	}, [
		reduxState.organisationState.genderFilters,
		JSON.stringify(reduxState.organisationState.groupNameFilters),
		JSON.stringify(reduxState.organisationState.ageFilters),
		reduxState.organisationState.dateRangeFromFilter,
		reduxState.organisationState.dateRangeToFilter,
		fetchSize,
	]);

	const filterStringBuilder = () => {
		var string = "";

		if (reduxState.organisationState.groupNameFilters.length > 0) {
			var arrayString = reduxState.organisationState.groupNameFilters.map(
				(e) => e
			);
			string += `&classFilter=${arrayString}`;
		}

		if (reduxState.organisationState.genderFilters.length > 0) {
			string += `&genderFilter=${reduxState.organisationState.genderFilters}`;
		}

		if (reduxState.organisationState.ageFilters.length > 0) {
			var arrayString = reduxState.organisationState.ageFilters.map((e) => e);
			string += `&ageFilter=${arrayString}`;
		}

		// Date range filtering
		if (
			reduxState.organisationState.dateRangeFromFilter instanceof Date &&
			reduxState.organisationState.dateRangeToFilter instanceof Date
		) {
			string +=
				"&dateRangeFrom=" +
				moment(reduxState.organisationState.dateRangeFromFilter).format(
					"DD/MM/YYYY"
				);
			string +=
				"&dateRangeTo=" +
				moment(reduxState.organisationState.dateRangeToFilter).format(
					"DD/MM/YYYY"
				);
		}

		return string;
	};

	const setIdsSelected = (array) => {
		setLocalUserIdsSelected(array);
	};

	const renderStudents = () => {
		var dom = [];
		for (var i = 0; i < classUsersDisplayed.length; i++) {
			dom.push(
				<StudentRowComponent
					key={classUsersDisplayed[i].user_id}
					validToken={classUsersDisplayed[i].token}
					studentName={
						classUsersDisplayed[i].user_name
							? classUsersDisplayed[i].user_name
							: ""
					}
					studentGender={
						classUsersDisplayed[i].user_gender
							? classUsersDisplayed[i].user_gender
							: ""
					}
					studentDOB={
						classUsersDisplayed[i].user_dob
							? moment(classUsersDisplayed[i].user_dob).format("MM/YYYY")
							: ""
					}
					studentGroup={
						classUsersDisplayed[i].user_classname
							? classUsersDisplayed[i].user_classname
							: ""
					}
					studentTests={getUserTestsById(classUsersDisplayed[i].user_id)}
					studentId={classUsersDisplayed[i].user_id}
					localUserIdsSelectedArray={localUserIdsSelected}
					setIdsSelected={setIdsSelected}
					index={i}
					iconsForReport={[
						sitAndReachImage,
						standingBroadJumpImage,
						bentKneeSitUpsImage,
						gripStrengthImage,
						verticalJumpImage,
						inclinedPullUpsImage,
						shuttleRunImage,
						sprintSpeedImage,
						walkRunImage,
						beepTestImage,
						heightImage,
						weightImage,
						armSpanImage,
						brachialIndexImage,
						sittingHeightImage,
					]}
				/>
			);
		}

		return dom;
	};

	/**
	 * Exports the current required template for importing class data
	 */
	const exportImportTemplate = () => {
		var wb = XLSX.utils.book_new(); // Instantiate a new workbook
		wb.SheetNames.push("class_import");

		var ws_data = [];

		if (organisationLocalState.plan !== "Starter") {
			ws_data = [
				[
					"User ID",
					"Class name",
					"Gender (M/F)",
					"Birth month (01 = January)",
					"Birth year (yyyy)",
					"Password",
				],
				["john_example", "Mr. Example", "M", "5", "2007", "examplePassword"],
			];
		} else {
			ws_data = [
				[
					"User ID",
					"Gender (M/F)",
					"Birth month (01 = January)",
					"Birth year (yyyy)",
					"Password",
				],
				["john_example", "M", "5", "2007", "examplePassword"],
			];
		}

		var ws = XLSX.utils.aoa_to_sheet(ws_data);
		wb.Sheets["class_import"] = ws;

		XLSX.writeFile(wb, "Download user template from Test Takers screen.csv");
	};

	/** Renders the input button, so when the user clicks on the "Import" button, it will trigger the hidden input element used for uploading files */
	const renderClassImportButton = () => {
		return (
			<input
				type="file"
				onChange={(file) => uploadClassImportFile(file)}
				style={{ display: "none" }}
			/>
		);
	};

	const getErrorMessage = (msg) => {
		var errorObject = {
			error: true,
			message: "",
		};

		errorObject.message = msg;

		return errorObject;
	};

	const importOnClick = async () => {
		// Perform validation checking before sending it to the backend
		var errorObject = null;

		for (var i = 0; i < classImportFile.length; i++) {
			var obj = classImportFile[i];

			if (obj["user_id"] === null) {
				errorObject = getErrorMessage("User Id invalid on row: " + (i + 1));
			}

			if (obj["name"] === null) {
				errorObject = getErrorMessage("Name invalid on row: " + (i + 1));
			}

			if (obj["gender"] === null) {
				errorObject = getErrorMessage("Gender invalid on row: " + (i + 1));
			}

			if (obj["date_of_birth"] === null) {
				errorObject = getErrorMessage(
					"Date of birth invalid on row: " + (i + 1)
				);
			}

			if (obj["password"] === null) {
				errorObject = getErrorMessage("Password invalid on row: " + (i + 1));
			}
		}

		var d = {
			type: "array",
			data: classImportFile,
		};

		if (classImportFile !== null && errorObject === null) {
			CheckIfUserSessionIsValid(dispatch).then(() => {
				fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/CreateUser?type=array`, {
					method: "POST",
					headers: {
						Accept: "application/json",
					},
					credentials: "include",
					body: JSON.stringify(d),
				}).then((res) => {
					CheckIfUserSessionIsValid(dispatch).then(() => {
						setImportLoading(true);
						res.json().then((data) => {
							setImportLoading(false);
							if (data.success) {
								addToast("Class imported successfully", {
									appearance: "success",
									autoDismiss: true,
								});
								renderUsers(false);
							} else {
								addToast(data.message + "\n" + "Line: " + data.line, {
									appearance: "error",
									autoDismiss: true,
								});
							}
						}).catch(err => {
							setImportLoading(false);
							addToast("An error occured attempting to upload users", {
								appearance: "error",
								autoDismiss: true,
							});
						});
					})
				});
			})

		} else if (errorObject !== null && errorObject.error) {
			addToast("Error - " + errorObject.message, {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	const selectAllButtonHandler = () => {
		var arrayOfAllStudentIds = [];

		if (localUserIdsSelected.length == classUsersDisplayed.length) {
			setLocalUserIdsSelected([]);
		} else {
			for (var i = 0; i < classUsersDisplayed.length; i++) {
				arrayOfAllStudentIds.push(classUsersDisplayed[i].user_id);
			}

			setLocalUserIdsSelected(arrayOfAllStudentIds);
		}
	};

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (elem) => {
		setAnchorEl(null);
	};

	const initializeRadarChart = (data) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				var chartCtx = document.createElement("canvas");
				chartCtx.setAttribute("height", "300px");

				if (primaryColor == "rgb(90, 83, 128)") {
					var radarBackgroundColor = "rgba(154, 98, 179, .3)";
					var radarBackgroundBorderColor = "rgb(154, 98, 179)";
				} else {
					var radarBackgroundColor = hexToRgbA(primaryColor, 0.3);
					var radarBackgroundBorderColor = primaryColor;
				}

				var chartData = {
					labels: ["", "", "", "", ""],
					datasets: [
						{
							label: "My Second dataset",
							backgroundColor: radarBackgroundColor,
							borderColor: radarBackgroundBorderColor,
							borderWidth: 1,
							pointBackgroundColor: "rgba(255,99,132,1)",
							pointBorderColor: "#fff",
							pointRadius: 0,
							pointHoverBackgroundColor: "#fff",
							pointHoverBorderColor: "rgba(255,99,132,1)",
							data: [
								data.averaged_norms.height,
								data.averaged_norms.weight,
								data.averaged_norms.arm_span,
								data.averaged_norms.forearm_length,
								data.averaged_norms.sitting_height
							],
						},
					],
				};

				var chartOptions = {
					legend: {
						display: false,
					},
					animation: {
						onComplete: function () {
							resolve(ChartJSDOM);
						},
					},
					responsive: false,
					maintainAspectRatio: false,
					scale: {
						angleLines: {
							display: true,
						},
						pointLabels: {
							display: false,
							fontFamily: "Dubai Regular",
							fontSize: 14,
						},
						ticks: {
							display: false,
							suggestedMin: 0,
							suggestedMax: 100,
							maxTicksLimit: 6,
							backdropColor: "rgb(50, 168, 82)",
						},
					},
				};

				const ChartJSDOM = new Chart(chartCtx, {
					type: "radar",
					data: chartData,
					options: chartOptions,
					width: 800,
					height: 800,
				});

				document.querySelector("body").appendChild(ChartJSDOM.canvas);
			}, 2000);
		});
	};

	const generateFilterString = (isNew) => {
		// Checking if filters have been applied
		var filters = "";
		if (organisationLocalState.genderFilters.length != 0) {
			if (isNew) {
				filters += "?gender=" + organisationLocalState.genderFilters;
			} else {
				filters += "&gender=" + organisationLocalState.genderFilters;
			}
		}
		if (organisationLocalState.groupNameFilters.length != 0) {
			if (isNew) {
				if (filters != "") {
					filters += "&groups=" + btoa("[" + organisationLocalState.groupNameFilters.map(e => e) + "]");
				} else {
					filters += "?groups=" + btoa("[" + organisationLocalState.groupNameFilters.map(e => e) + "]");
				}
			} else {
				filters += "&groups=" + btoa("[" + organisationLocalState.groupNameFilters.map(e => e) + "]");
			}
		}
		if (organisationLocalState.ageFilters.length != 0) {
			if (isNew) {
				if (filters != "") {
					filters += "&ages=" + btoa("[" + organisationLocalState.ageFilters.map(e => "\"" + e + "\"") + "]");
				} else {
					filters += "?ages=" + btoa("[" + organisationLocalState.ageFilters.map(e => "\"" + e + "\"") + "]");
				}
			} else {
				filters += "&ages=" + btoa("[" + organisationLocalState.ageFilters.map(e => "\"" + e + "\"") + "]");
			}
		}
		if (organisationLocalState.dateRangeFromFilter.length != 0) {
			if (isNew) {
				if (filters != "") {
					filters += "&startDate=" + moment(organisationLocalState.dateRangeFromFilter).format('DD/MM/YYYY');
				} else {
					filters += "?startDate=" + moment(organisationLocalState.dateRangeFromFilter).format('DD/MM/YYYY');
				}
			} else {
				filters += "&startDate=" + moment(organisationLocalState.dateRangeFromFilter).format('DD/MM/YYYY');
			}
		}
		if (organisationLocalState.dateRangeToFilter.length != 0) {
			if (isNew) {
				if (filters != "") {
					filters += "&endDate=" + moment(organisationLocalState.dateRangeToFilter).format('DD/MM/YYYY');
				} else {
					filters += "?endDate=" + moment(organisationLocalState.dateRangeToFilter).format('DD/MM/YYYY');
				}
			} else {
				filters += "&endDate=" + moment(organisationLocalState.dateRangeToFilter).format('DD/MM/YYYY');
			}
		}

		return filters;
	};

	const getSportRankings = () => {
		return new Promise((resolve, reject) => {
			if (generateFilterString() == "") {
				Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/sporttypes`, {
					withCredentials: true,
					cancelToken: cancelGroupReportToken.token
				}).then(res => {
					CheckIfUserSessionIsValid(dispatch).then(() => {
						var array = [
							{
								name: "powerScore",
								value: res.data.sport_types[0].power_score
							},
							{
								name: "skillScore",
								value: res.data.sport_types[0].skill_score
							},
							{
								name: "speedScore",
								value: res.data.sport_types[0].speed_score
							},
							{
								name: "strengthScore",
								value: res.data.sport_types[0].strength_score
							},
							{
								name: "gameFitnessScore",
								value: res.data.sport_types[0].game_fitness_score
							},
							{
								name: "aerobicFitnessScore",
								value: res.data.sport_types[0].aerobic_fitness_score
							},
						]
						resolve(array);
					})
				});
			} else {
				Axios.get(`${BACKEND_URL + ENVIRONMENT_PATH}/orgtopsports?all_sports=true${generateFilterString(false)}`, {
					withCredentials: true,
					cancelToken: cancelGroupReportToken.token
				}).then(res => {
					CheckIfUserSessionIsValid(dispatch).then(() => {
						resolve(res.data.top_sport_types);
					})
				});
			}
		})
	};

	const getAverageSportRanking = (sports) => {
		var averageRanking = 0;
		var count = 0;
		for (var i = 0; i < sports.length; i++) {
			averageRanking += sports[i].final_distance;
			count++;
		}

		return averageRanking / count;
	};

	const getPercentageOfSportsOver60 = (sports) => {
		var numberOfSportsFound = 0;
		var count = 0;

		var finalPercentage = 0;

		for (var i = 0; i < sports.length; i++) {
			if (sports[i].final_distance > 60) {
				numberOfSportsFound++;
			}
			count++;
		}

		finalPercentage = (numberOfSportsFound / count) * 100;

		return finalPercentage;
	};

	const generatePDF = (result) => {
		if (result.top_sports.length > 0) {
			getSportRankings().then(rankings => {
				var averageSportRanking = getAverageSportRanking(result.top_sports);

				var percentageOfSportsOver60 = getPercentageOfSportsOver60(
					result.top_sports
				);

				initializeRadarChart(result).then((radarChartResult) => {
					const HeaderImages = [
						sitAndReachImage,
						standingBroadJumpImage,
						bentKneeSitUpsImage,
						gripStrengthImage,
						verticalJumpImage,
						inclinedPullUpsImage,
						shuttleRunImage,
						sprintSpeedImage,
						walkRunImage,
						beepTestImage,
					];

					const anthropometryIcons = [
						heightImage,
						weightImage,
						armSpanImage,
						brachialIndexImage,
						sittingHeightImage
					];

					generateGroupReportPDF(
						result,
						HeaderImages,
						anthropometryIcons,
						radarChartResult,
						null,
						averageSportRanking,
						percentageOfSportsOver60,
						rankings,
						primaryColor,
						secondaryColor,
						accentColor,
						null,
						usingImperial,
						reduxState.organisationState.organisationLogo
					);

					setLoading(false);
				});
			});
		}
	};

	const downloadGroupReport = () => {
		setLoading(true);
		var reportDataset = [];
		setUserTestAges([]);
		setUserReport([]);
		setAppliedUserAges(false);

		cancelGroupReportToken.cancel();
		cancelGroupReportToken = Axios.CancelToken.source();

		const headers = {
			'Content-Type': 'text/plain'
		};

		var usersAge = 8;

		if (userState.userBirthYear !== -1 && userState.userBirthMonth !== -1) {
			var dob = moment(
				userState.userBirthMonth + 1 + "/01/" + userState.userBirthYear
			);
			usersAge = moment
				.duration(moment(new Date()).diff(dob))
				.asYears()
				.toFixed(0);
		}

		var stringOfLocalUserIds = btoa(JSON.stringify(localUserIdsSelected));

		if (localUserIdsSelected.length > 0) {
			var urlencoded = new URLSearchParams();
			urlencoded.append("studentIds", stringOfLocalUserIds);

			Axios({
				url: `${BACKEND_URL + ENVIRONMENT_PATH}/groupreport?using_org_sports=true`,
				method: 'post',
				withCredentials: true,
				cancelToken: cancelGroupReportToken.token,
				data: { studentIds: stringOfLocalUserIds },
				headers
			}).then(res => {
				CheckIfUserSessionIsValid(dispatch).then(() => {
					generatePDF(res.data);
				})
			});
		} else {
			// Check if the filters are applied (both age and group name)
			const UserAgeFiltersInRedux = reduxState.organisationState.ageFilters;
			const UserGroupFiltersInRedux =
				reduxState.organisationState.groupNameFilters;
			const UserGenderFiltersInRedux =
				reduxState.organisationState.genderFilters;

			var jsonObject = {};

			var requestString = "groupreport";

			// Check for group filters
			if (UserGroupFiltersInRedux.length > 0) {
				if (UserGroupFiltersInRedux.length == 1) {
					requestString += "?group=" + UserGroupFiltersInRedux[0];
					jsonObject["group"] = UserGroupFiltersInRedux[0];
				} else {
					requestString +=
						"?groups=" +
						btoa("[" + UserGroupFiltersInRedux.map((e) => e) + "]");
					jsonObject["groups"] = btoa("[" + UserGroupFiltersInRedux.map((e) => e) + "]");
				}
			} else {
				requestString +=
					"?groups=" + btoa("[" + groupsAvailable.map((e) => e) + "]");
				jsonObject["groups"] = btoa("[" + groupsAvailable.map((e) => e) + "]");
			}

			// Check for age filters
			if (UserAgeFiltersInRedux.length > 0) {
				if (requestString != "groupreport") {
					requestString += "&selectedAges=";
				} else {
					requestString += "?selectedAges=";
				}

				var selectedAges64 = btoa("[" + UserAgeFiltersInRedux.toString() + "]");

				requestString += selectedAges64;

				jsonObject["selectedAges"] = selectedAges64;
			} else {
				if (requestString != "groupreport") {
					requestString += "&selectedAges=";
				} else {
					requestString += "?selectedAges=";
				}

				requestString += btoa("[8,9,10,11,12,13,14,15,16,17,18]");

				jsonObject["selectedAges"] = btoa("[8,9,10,11,12,13,14,15,16,17,18]");
			}

			// Check for gender filters
			if (UserGenderFiltersInRedux.length > 0) {
				if (requestString != "groupreport") {
					requestString += "&gender=";
				} else {
					requestString += "?gender=";
				}

				requestString += UserGenderFiltersInRedux;

				jsonObject["gender"] = UserGenderFiltersInRedux;
			}

			Axios({
				url: `${BACKEND_URL + ENVIRONMENT_PATH}/groupreport`,
				method: 'post',
				withCredentials: true,
				data: jsonObject,
				cancelToken: cancelGroupReportToken.token,
				headers
			}).then(res => {
				CheckIfUserSessionIsValid(dispatch).then(() => {
					if (res.data.response != "error") {
						generatePDF(res.data);
					} else {
						alert(res.data.message);
					}
					setLoading(false);
				})
			});
		}
	};

	const verifyTestsOfSelectedUsers = () => {
		setAnchorEl(null); // Hiding the select menu
		if (localUserIdsSelected.length > 0) {
			fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/BulkVerify`, {
				method: "POST",
				headers: {
					Accept: "application/json",
				},
				credentials: "include",
				body: btoa(localUserIdsSelected.map((e) => e)),
			}).then((res) => {
				CheckIfUserSessionIsValid(dispatch).then(() => {
					res.json().then((result) => {
						addToast(result.message, {
							appearance: result.status,
							autoDismiss: true,
						});

						setFetchSize(0);
					});
				})
			});
		}
	};

	// Resets the details of the selected users
	const resetTestDetailsHandler = () => {
		var userIds = buildStringOfUserIds();

		setResetTestsLoading(true);

		fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=remove_test_data&user_ids=${userIds}`,
			{
				headers: {
					Accept: "application/json",
				},
				credentials: "include",
			}
		).then((res) => {
			CheckIfUserSessionIsValid(dispatch).then(() => {
				setResetTestsLoading(false);
				handleClose();
				setAnchorEl(null); // Hiding the select menu
				res.json().then((data) => {
					setFetchSize(0);
					addToast(
						"Successfully removed test data of user(s): Number of users affected: " +
						data.noOfUsersAffected,
						{
							appearance: "success",
							autoDismiss: true,
						}
					);
				});
			})

		});
	};

	const searchTextFieldOnChangeHandler = (name) => {
		var classUsersLocal = classUsers;
		var newClassUsersLocal = [];

		if (searchTimeoutRef.current != null) {
			clearTimeout(searchTimeoutRef.current);
		}

		searchTimeoutRef.current = setTimeout(() => {
			searchTimeoutRef.current = null;
			for (var i = 0; i < classUsers.length; i++) {
				if (classUsers[i].user_name.includes(name)) {
					newClassUsersLocal.push(classUsers[i]);
				}
			}

			setClassUsersDisplayed(newClassUsersLocal);
		}, 100);
	};

	const assignTokenToStudents = () => {
		if (localUserIdsSelected.length > 0) {
			if (localUserIdsSelected.length == 1) {
				setAssignTokenLoading(true);
				fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/assigntokentostudent`, {
					method: "POST",
					headers: {
						Accept: "application/json",
					},
					credentials: "include",
					body: JSON.stringify({ user_id: "" + localUserIdsSelected[0] + "" }),
				}).then((res) => {
					CheckIfUserSessionIsValid(dispatch).then(() => {
						res.json().then((result) => {
							if (result.result.toLowerCase() == "success") {
								addToast("Successfully added token(s)", {
									appearance: "success",
									autoDismiss: true,
								});
								renderUsers(false);
							} else {
								addToast(result.result, {
									appearance: "error",
									autoDismiss: true,
								});
								renderUsers(false);
							}
							setAssignTokenLoading(false);
						}).catch((error) => {
							// TODO: Display error on screen
							setAssignTokenLoading(false);
						});
					}).catch(() => setAssignTokenLoading(false));
				}).catch((er) => {
					// TODO: Display error on screen
					setAssignTokenLoading(false);
				});
			} else {
				setAssignTokenLoading(true);
				fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/assigntokentostudent`, {
					method: "POST",
					headers: {
						Accept: "application/json",
					},
					credentials: "include",
					body: JSON.stringify({
						user_ids: localUserIdsSelected.map((e) => e),
					}),
				}).then((res) => {
					CheckIfUserSessionIsValid(dispatch).then(() => {
						res.json().then((result) => {
							if (result.result.toLowerCase() == "success") {
								addToast("Successfully added token(s)", {
									appearance: "success",
									autoDismiss: true,
								});
							} else {
								addToast(result.result, {
									appearance: "error",
									autoDismiss: true,
								});
							}
							setAssignTokenLoading(false);
						}).catch((error) => {
							// TODO: Display error on screen
							setAssignTokenLoading(false);
						});
					}).catch(() => setAssignTokenLoading(false));
				}).catch((er) => {
					// TODO: Display error on screen
					setAssignTokenLoading(false);
				});
			}
		}
	};

	const renderHeader = () => {
		if (type == "students") {
			return (
				<div className="list-controls-container">
					<input
						ref={searchRef}
						id="student-search-text-field"
						type="text"
						placeholder="Search..."
						onChange={(e) => searchTextFieldOnChangeHandler(e.target.value)}
					/>
					<input
						id="graph-view-btn"
						type="button"
						name="graph-view-btn"
						value="Graph view"
						onClick={() => {
							setModeDisplay("graph");
						}}
					/>
				</div>
			);
		} else {
			return (
				<div className="class-info-header">
					<Button variant="contained" onClick={exportImportTemplate}>
						Download user template
					</Button>

					<Button component="label" variant="contained">
						Upload user template
						{renderClassImportButton()}
					</Button>

					<div className="import-btn-container">
						<Button
							variant="contained"
							onClick={() => {
								if (classImportFile) {
									importOnClick();
								} else {
									addToast(
										'You need to upload a file first (using the "Upload user template" button)',
										{
											appearance: "error",
											autoDismiss: true,
										}
									);
								}
							}}
						>
							Import
						</Button>

						{importLoading ? <CircularProgress style={{ padding: '0px 5px' }} disableShrink={true} size={20} /> : null}
					</div>

					<Button
						variant="contained"
						onClick={testTakerUserDetailsExport}
					>
						Export user templates {exportUsersLoading ? <CircularProgress style={{ padding: '0px 5px' }} disableShrink={true} size={20} /> : null}
					</Button>

					<div className="select-all-btn-container main-btn-container">
						<Button
							variant="contained"
							onClick={() => selectAllButtonHandler()}
							style={{
								background:
									organisationLocalState.organisationPrimaryColor != ""
										? organisationLocalState.organisationPrimaryColor
										: "",
							}}
						>
							Select/De-select all
						</Button>
					</div>
				</div>
			);
		}
	};

	const downloadSeparateReportsHandler = async () => {
		cancelIndividualReportToken.cancel();
		cancelIndividualReportToken = Axios.CancelToken.source();

		var arrayOfDownloadBtns = [];
		setElements([]);
		// Calling the endpoint for retrieving user test data (most recent test)

		var urlencoded = new URLSearchParams();
		urlencoded.append("userId", localUserIdsSelected.map(e => e));

		Axios({
			method: 'post',
			url: `${BACKEND_URL + ENVIRONMENT_PATH}/GetUserTest`,
			data: urlencoded,
			withCredentials: true,
			cancelToken: cancelIndividualReportToken.token,
			headers: {
				'Content-Type': `application/x-www-form-urlencoded; boundary=${urlencoded._boundary}`,
			},
		}).then(res => {
			CheckIfUserSessionIsValid(dispatch).then(() => {
				setIndividualReportLoading(true);
				var isASingleUser = false;
				var numberOfUsersWithNoTests = 0;

				if (localUserIdsSelected.length == 1) isASingleUser = true;

				let numberOfCompletedReports = 0;

				if (isASingleUser == false) {
					for (var i = 0; i < localUserIdsSelected.length; i++) {
						var test = res.data.student_tests[localUserIdsSelected[i]][0];

						if (!test) {
							numberOfUsersWithNoTests++;
							continue;
						}

						arrayOfDownloadBtns.push(
							isQas ?
								<QasReport
									test={test}
									title={'Download'}
									customCallback={(message) => {
										numberOfCompletedReports++;
										setNumberOfReportsGenerated(numberOfCompletedReports);
										if (numberOfCompletedReports == (localUserIdsSelected.length - numberOfUsersWithNoTests)) {
											setIndividualReportLoading(false);
										}
									}}
									classname={"download-button"}
								/> :
								<DownloadButton
									test={test}
									loaded={true}
									isHeaderButton={true}
									title={'Download'}
									link={''}
									_className={"download-button"}
									headerImages={[
										sitAndReachImage,
										standingBroadJumpImage,
										bentKneeSitUpsImage,
										gripStrengthImage,
										verticalJumpImage,
										inclinedPullUpsImage,
										shuttleRunImage,
										sprintSpeedImage,
										walkRunImage,
										beepTestImage,
										heightImage,
										weightImage,
										armSpanImage,
										brachialIndexImage,
										sittingHeightImage,
									]}
									anthropometryImages={[
										heightImage,
										weightImage,
										armSpanImage,
										brachialIndexImage,
										sittingHeightImage
									]}
									customCallback={(message) => {
										numberOfCompletedReports++;
										setNumberOfReportsGenerated(numberOfCompletedReports);
										if (numberOfCompletedReports == (localUserIdsSelected.length - numberOfUsersWithNoTests)) {
											setIndividualReportLoading(false);
										}
									}}
									showNameInTitle={true}
									allowForButtonCancellationOnClick={false}
								/>
						);
					}
				} else {
					var test = res.data.student_tests[0];
					if (!test) {
						setIndividualReportLoading(false);
						return;
					}

					arrayOfDownloadBtns.push(
						isQas ?
							<QasReport
								test={test}
								title={'Download'}
								customCallback={(message) => {
									numberOfCompletedReports++;
									if (numberOfCompletedReports == localUserIdsSelected.length) {
										setIndividualReportLoading(false);
									}
								}}
								classname={"download-button"}
							/> :
							<DownloadButton
								test={test}
								loaded={true}
								isHeaderButton={true}
								title={'Download'}
								link={''}
								_className={"download-button"}
								headerImages={[
									sitAndReachImage,
									standingBroadJumpImage,
									bentKneeSitUpsImage,
									gripStrengthImage,
									verticalJumpImage,
									inclinedPullUpsImage,
									shuttleRunImage,
									sprintSpeedImage,
									walkRunImage,
									beepTestImage,
									heightImage,
									weightImage,
									armSpanImage,
									brachialIndexImage,
									sittingHeightImage,
								]}
								anthropometryImages={[
									heightImage,
									weightImage,
									armSpanImage,
									brachialIndexImage,
									sittingHeightImage
								]}
								customCallback={(message) => {
									numberOfCompletedReports++;
									if (numberOfCompletedReports == localUserIdsSelected.length) {
										setIndividualReportLoading(false);
									}
								}}
								showNameInTitle={true}
							/>
					)
				}

				setElements(
					<div className="hidden-download-btn">
						{arrayOfDownloadBtns}
					</div>
				)
			})
		});
	};

	// TODO: Make this account for the actual change state - rather than a set timeout
	const awaitForNumberOfReportsGeneratedToMatch = async (_numberOfClicks) => {
		return new Promise(async (resolve, reject) => {
			downloadDocumentInterval.current = setTimeout(() => {
				resolve();
			}, 3000);
		});
	}

	const iterateThroughButtons = async () => {
		var numberOfButtons = $(".hidden-download-btn > div");

		for (var i = 0; i < numberOfButtons.length; i++) {
			var element = $(numberOfButtons).get(i);
			var button = $(element).find(".download-button");

			$(button).click();

			setNumberOfClicks((numberOfClicks + 1));

			await awaitForNumberOfReportsGeneratedToMatch((numberOfClicks + 1)); //wait for 3 seconds
		}
	}

	// On unmount, clear interval controller for document interval downloader
	useEffect(() => {
		return () => {
			clearTimeout(downloadDocumentInterval.current);
		}
	}, []);

	useEffect(() => {
		iterateThroughButtons();
	}, [elements]);

	useEffect(() => {
		if (searchRef.current != null) {
			searchTextFieldOnChangeHandler(searchRef.current.value);
		} else {
			searchTextFieldOnChangeHandler("");
		}
	}, [classUsers]);

	// Converts a string of feet'inches" to inches
	const convertFeetInchesToInches = (index, splittedRows) => {
		if (splittedRows.length >= index) {
			var splittedValue = (splittedRows[index] + "").trim();
			var finalValue = 0;
			splittedValue = splittedValue.replace(/[”"]/g, " ")
			splittedValue = splittedValue.replace(/[’']/g, " ")
			splittedValue = splittedValue.split(" ");
			finalValue = parseFloat(splittedValue[0] * 12) + parseFloat(splittedValue[1]);
			splittedRows[index] = finalValue;
		}
	}

	// Uploads users from CSV
	const uploadCSVOfUsers = async () => {
		const testIds = [];
		var csvRowCount = 0;
		if (csvFileWithLines != null) {
			var payload = "";
			var payloadNo = 1;

			// Split the file into increments of 20
			for (var i = 1; i < csvFileWithLines.length; i++) {
				var row = csvFileWithLines[i];

				// If user is using imperial - then convert the feet'inches" to inches
				if (usingImperial && row.indexOf(",") != -1) {
					var splittedRows = row.split(",");

					for (var j = 0; j < splittedRows.length; j++) {
						var string = splittedRows[j] + "";
						if (string.includes("\"") || string.includes("\”") || string.includes("’") || string.includes("'")) {
							convertFeetInchesToInches(j, splittedRows);
						}
					}

					row = "";
					for (var a = 0; a < splittedRows.length; a++) {
						if (a != splittedRows.length - 1) {
							row += splittedRows[a] + ","
						} else {
							row += splittedRows[a];
						}
					}
				}

				if (row != '') {
					csvRowCount++;
				}

				payload += (row + "END");

				if (i % 20 == 0) {
					payloadNo++;
					const successfulResults = await uploadCSVEndpoint(payload, payloadNo);

					if (isQas) {
						for (const successfulResult of successfulResults.array_of_successful_tests) {
							testIds.push(successfulResult.id + "");
						}
					}

					setCSVFileUploadPercentage((i / csvFileWithLines.length) * 100);
					payload = "";
				} else if (i == csvFileWithLines.length - 1) {
					setCSVFileUploadPercentage(100);
					const successfulResults = await uploadCSVEndpoint(payload, payloadNo);

					if(isQas) {
						for (const successfulResult of successfulResults.array_of_successful_tests) {
							testIds.push(successfulResult.id + "");
						}
					}

					setCSVFileUploadPercentage(100);
				}
			}

			setCSVFileUploadPercentage(100);

			if (isQas) {
				console.log("Test Ids = ", testIds);
				const arrayOfSuccessfulIds = createQueueRequestArray(testIds);
				var urlencoded = new URLSearchParams();
				urlencoded.append("items", JSON.stringify(arrayOfSuccessfulIds));
				await StartQueue(urlencoded, cancelQueueProcessToken);

				addToast(`Upload completed - Please allow up to 1 hour for reports to be received via email`, {
					appearance: "success",
					autoDismiss: true,
				});
			} else {
				// TODO: get only the number of successful rows, rather than all the rows of the CSV
				addToast(`Successfully uploaded ${csvRowCount} tests`, {
					appearance: "success",
					autoDismiss: true,
				});
			}

			setImportLoading(false);
			renderUsers(false);
		}
	}

	const uploadCSVEndpoint = (payload, payloadNo) => {
		return new Promise((resolve, reject) => {
			fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/uploadtestsfromcsv?payloadNo=${payloadNo}&using_imperial=${usingImperial}`,
				{
					method: "POST",
					headers: {
						Accept: "application/json",
					},
					body: payload,
					credentials: "include",
				}).then(res => {
					res.json().then(result => {
						resolve(result);
					}).catch(er => {
						reject(er);
						// TODO: Display error on screen
					});

				})
		})
	}

	const sendEmailHandler = async () => {
		if (localUserIdsSelected.length > 0) {
			const response = await RetrieveQASUserInfo(JSON.stringify(localUserIdsSelected), cancelQASUserRetrieval);

			let emailSent = false;

			if (Array.isArray(response.qas_student_data)) {
				if (response.qas_student_data.length > 0) {
					for (let i = 0; i < response.qas_student_data.length; i++) {
						const qasTestParam = {
							test_id: response.qas_student_data[i].test_id,
							user_group: response.qas_student_data[i]?.user_group ?? '',
							user_gender: response.qas_student_data[i].user_gender ?? '',
							user_age: response.qas_student_data[i]?.user_age ?? '',
							user_email: response.qas_student_data[i]?.user_email ?? ''
						}

						const canSendEmail =
							!checkIfNullOrStringEmptyOrMinusOne(qasTestParam.user_email) &&
							!checkIfNullOrStringEmptyOrMinusOne(qasTestParam.user_age) &&
							!checkIfNullOrStringEmptyOrMinusOne(qasTestParam.user_gender);

						if (canSendEmail) {
							const requestQASReportParams = {
								test: qasTestParam,
								normSelected: encodeURI(AUS_NZ_NORM_NAME),
								usingImperial: false,
								downloadReport: false,
								sendAsEmail: true,
								cancelDownloadToken: cancelQASGenReport,
								refreshEmailLog: i === 0
							}

							await doGenerateQASReport(requestQASReportParams);
							emailSent = true;
						}

						if (i == response.qas_student_data.length - 1) {
							if (emailSent) {
								await DownloadEmailSendLog();
								addToast(
									`Emails sent`,
									{
										appearance: "success",
										autoDismiss: true,
									}
								);
							} else {
								addToast(
									`No emails sent, please check users selected`,
									{
										appearance: "error",
										autoDismiss: true,
									}
								);
							}

						}
					}
				} else {
					addToast(
						`No test found for selected users`,
						{
							appearance: "error",
							autoDismiss: true,
						}
					);
				}
			}
		}
	}

	return (
		<div
			className={`${className}`}
			style={{ display: modeDisplay != "list" ? "none" : "flex" }}
		>
			<div className="more-options" onClick={handleMenu}>
				<i class="fa fa-ellipsis-v"></i>
			</div>

			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={anchorEl !== null}
				onClose={handleClose}
			>
				<MenuItem onClick={() => setShowResetTestDialog(true)} data-type={"profile"}>
					Reset test details {resetTestsLoading && <CircularProgress disableShrink size={20} />}
				</MenuItem>

				<MenuItem
					onClick={assignTokenToStudents}
					data-type={"assign-test-tokens"}
				>
					Assign test tokens
				</MenuItem>
				<MenuItem onClick={verifyTestsOfSelectedUsers}>Verify results</MenuItem>
				<MenuItem onClick={() => setShowDeleteUserDialog(true)} data-type={"logout"}>
					Delete user&ensp;{deleteUserLoading ? <CircularProgress disableShrink={true} size={20} /> : null}
				</MenuItem>
				<MenuItem onClick={downloadSeparateReportsHandler}>
					Download separate reports&ensp;{individualReportLoading ? <CircularProgress disableShrink={true} size={20} /> : null}
				</MenuItem>
				<MenuItem onClick={downloadGroupReport}>
					Download group report
					<div
						className="export-group-report-loading-container-wrap"
						style={{ position: "absolute", top: "8px", right: "20px" }}
					>
						{type != "organisationAdmin" ? (
							<div
								className="export-group-report-loading-container"
								style={{ opacity: loading ? "1" : "0" }}
							>
								<CircularProgress disableShrink={true} size={20} />
							</div>
						) : null}
					</div>
				</MenuItem>
				{isQas && <MenuItem onClick={sendEmailHandler} disabled={localUserIdsSelected.length == 0}>
					Send email
					{/* TODO: Make below into a component */}
					<div
						className="export-group-report-loading-container-wrap"
						style={{ position: "absolute", top: "8px", right: "20px" }}
					>
						<div
							className="export-group-report-loading-container"
							style={{ opacity: loadingReport === LoadingReportEnum.LOADING ? "1" : "0" }}
						>
							<CircularProgress disableShrink={true} size={20} />
						</div>
					</div>
				</MenuItem>}

			</Menu>

			{/* Confirmation dialog for reseting test detail */}
			<SportMatchDialog
				show={showResetTestDialog}
				message={`Are you sure you want to reset ${localUserIdsSelected.length > 1 ? 'these tests' : 'this test'}?`}
				submitButtonHandler={() => resetTestDialogOnClickHandler(true)}
				cancelButtonHandler={() => resetTestDialogOnClickHandler(false)}
			/>

			{/* Confirmation dialog for deleting user */}
			<SportMatchDialog
				show={showDeleteUserDialog}
				message={`Are you sure you want to delete ${localUserIdsSelected.length > 1 ? 'these users' : 'this user'}?`}
				submitButtonHandler={() => deleteUserDialogOnClickHandler(true)}
				cancelButtonHandler={() => deleteUserDialogOnClickHandler(false)}
			/>

			<div
				className="screen-sections class-info-screen-section"
				style={{ width: "100%" }}
			>
				{type != "students" ? <Filter /> : null}

				<div className="screen-section" style={{ position: "relative" }}>
					<div className="result-list-top-buttons-top"
						style={{
							display: type == "students" ? "flex" : "none",
						}}
					>
						<Button
							variant="contained"
							onClick={testTakerDownloadFileHandler}
							id="export-class-data-csv-btn"
						>
							Export class data (csv){exportUsersLoading ? <CircularProgress style={{ padding: '0px 5px' }} disableShrink={true} size={20} /> : null}
						</Button>

						<span className="loading-percent-indicator">{csvFileUploadPercentage != 0 ? "Percentage of upload complete: " + parseFloat(csvFileUploadPercentage).toFixed(1) + "%" : null}</span>
					</div>

					<div className="result-list-top-buttons-bottom"
						style={{
							display: type == "students" ? "flex" : "none"
						}}
					>
						<button
							onClick={() => {
								fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/genusertestcsvtemplate`,
									{
										headers: {
											Accept: "application/json",
										},
										credentials: "include",
									}
								).then((res) => {
									CheckIfUserSessionIsValid(dispatch).then(() => {
										res.text().then((result) => {

											if (usingImperial) {
												result = result.replaceAll("(kg)", "(lb)");
												result = result.replaceAll("(km/h)", "(mph)");
												result = result.replaceAll("(cm)", "(in)");
												result = result.replaceAll("(m)", "(yd)");
											}

											var wb = XLSX.utils.book_new(); // Instantiate a new workbook
											wb.SheetNames.push("class_import");

											var arrayOfValues = result
												.split("\n")[0]
												.split(",")
												.map((e) => e.replace(/"/g, ""));

											arrayOfValues = arrayOfValues.map(e => e.replace(/feetinchestemplate/g, "(feet'inches\" e.g. 6'2\")"));

											var ws_data = [arrayOfValues];

											var ws = XLSX.utils.aoa_to_sheet(ws_data);
											wb.Sheets["class_import"] = ws;

											XLSX.writeFile(wb, "Download results template from Results overview screen.csv");
										}).catch((err) => {
											// TODO: Display error on screen
										});
									})
								}).catch((error) => {
									// TODO: Display error on screen
								});
							}}
							id="download-import-template-btn"
						>
							Download results template
						</button>

						<ReactFileReader handleFiles={handleCSVFiles} fileTypes={".csv"}>
							<button id="upload-test-data">Upload test data</button>
						</ReactFileReader>

						<button
							onClick={() => {
								if (csvFileUpload != null) {
									CheckIfUserSessionIsValid(dispatch).then(() => {
										setImportLoading(true);
										uploadCSVOfUsers();
										// fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/uploadtestsfromcsv`,
										//   {
										//     method: "POST",
										//     headers: {
										//       Accept: "application/json",
										//     },
										//     body: csvFileUpload,
										//     credentials: "include",
										//   }
										// ).then((res) => {
										//   CheckIfUserSessionIsValid(dispatch).then(() => {
										//     res.json().then((result) => {
										//       setImportLoading(false);
										//       if (result.status && result.status == "error") {
										//         addToast(
										//           `Unable to find organisation - please login again`,
										//           {
										//             appearance: "error",
										//             autoDismiss: true,
										//           }
										//         );
										//       } else {
										//         if (result.status && result.status == "warning") {
										//           var wb = XLSX.utils.book_new(); // Instantiate a new workbook
										//           wb.SheetNames.push("invalid_rows");

										//           var stringOfWarnings = result.warning_payload.map(e => e + ",");
										//           console.log("String of warnings: ", stringOfWarnings);

										//           var arrayOfValues = result.warning_payload;
										//           var arrayOfArrays = [];

										//           for (var i = 0; i < result.warning_payload.length; i++) {
										//             arrayOfArrays.push([result.warning_payload[i]]);
										//           }

										//           var ws_data = arrayOfArrays;

										//           var ws = XLSX.utils.aoa_to_sheet(ws_data);
										//           wb.Sheets["invalid_rows"] = ws;

										//           XLSX.writeFile(wb, "Invalid rows.csv");

										//           addToast(
										//             `${result.message}`,
										//             {
										//               appearance: "warning",
										//               autoDismiss: true,
										//             }
										//           );
										//         } else {
										//           addToast(
										//             `Successfully uploaded ${result.array_of_successful_tests.length} tests`,
										//             {
										//               appearance: "success",
										//               autoDismiss: true,
										//             }
										//           );
										//         }
										//         renderUsers(false);
										//       }
										//     }).catch(err => {
										//       setImportLoading(false);
										//       addToast(`An error occured attempting to upload the users`, {
										//           appearance: "error",
										//           autoDismiss: true,
										//       });
										//     });
										//   })
										// }).catch((error) => {
										//   setImportLoading(false);
										//   console.log("Error = ", error);
										// });
									})

								} else {
									addToast(`Please select a file to upload`, {
										appearance: "error",
										autoDismiss: true,
									});
								}
							}}
							className={importLoading ? "import-tests-btn importing" : "import-tests-btn"}
						>
							Import results

							{importLoading ? <CircularProgress style={{ padding: '0px 5px' }} disableShrink={true} size={20} /> : null}
						</button>

						<div
							className={`select-all-btn-container main-btn-container ${type == "students" ? "results-overview-selectall" : ""
								}`}
						>
							<Button
								variant="contained"
								onClick={() => selectAllButtonHandler()}
								style={{
									background:
										organisationLocalState.organisationPrimaryColor != ""
											? organisationLocalState.organisationPrimaryColor
											: "",
								}}
							>
								Select/De-select all
							</Button>
						</div>
					</div>

					{type == "students" ? (
						<div className="select-all-row-laptop">
							<div
								className={`select-all-btn-container main-btn-container ${type == "students"
									? "results-overview-selectall-laptop-btn"
									: ""
									}`}
							>
								<Button
									variant="contained"
									onClick={() => selectAllButtonHandler()}
								>
									Select/De-select all
								</Button>
							</div>
						</div>
					) : null}

					{renderHeader()}
					<div
						className={`modify-student-section ${type == "students" ? "results-overview-students" : ""
							}`}
					>
						<div className="modify-student-body">
							<NewStudentComponent renderUsers={renderUsers} />

							<div className="modify-student-header">
								<div className="modify-student-header-column-heading">
									<span>Username</span>
								</div>

								<div className="modify-student-header-column-heading">
									<span>Gender</span>
								</div>

								<div className="modify-student-header-column-heading">
									<span>Date of birth</span>
								</div>

								<div className="modify-student-header-column-heading">
									<span>Group</span>
								</div>
							</div>

							{renderStudents()}

							<GroupReportResources
								anthropometryIcons={[
									heightImage,
									weightImage,
									armSpanImage,
									brachialIndexImage,
									sittingHeightImage,
								]}
								headerImageReferrences={[
									sitAndReachImage,
									standingBroadJumpImage,
									bentKneeSitUpsImage,
									gripStrengthImage,
									verticalJumpImage,
									inclinedPullUpsImage,
									shuttleRunImage,
									sprintSpeedImage,
									walkRunImage,
									beepTestImage,
								]}
								loaded={userTests.length != 0}
							/>
						</div>
					</div>

					<div className="main-btn-container">
						<Button
							variant="contained"
							onClick={() => {
								setShowStartTestDialog(true);
							}}
							style={{
								background:
									organisationLocalState.organisationPrimaryColor != ""
										? organisationLocalState.organisationPrimaryColor
										: "",
							}}
						>
							Start testing!
						</Button>
					</div>
				</div>
			</div>

			{elements}

			{/** Card for sending users to the SportMatch site */}
			<Dialog
				className="start-test-dialog"
				open={showStartTestDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={null}
			>
				<Card>
					<CardContent>
						<div className="card-body">
							<h3>Start testing!</h3>

							<span>Instruct your students to go to the following site:</span>
							<span className="link">{testingLink}</span>

							<span>
								To login, instruct your students to enter their Student ID and
								the password you have assigned.{" "}
							</span>
						</div>

						<div className="card-button-container">
							<Button
								variant="contained"
								onClick={() => {
									setShowStartTestDialog(false);
								}}
							>
								Close
							</Button>
						</div>
					</CardContent>
				</Card>
			</Dialog>
		</div>
	);
};

export default ClassInfoScreen;
