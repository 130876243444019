interface TestObject {
    height: string;
    weight: string;
    armSpan: string;
    forearmLength: string;
    gripStrengthLeft: string;
    gripStrengthRight: string;
    inclinedPullup: string;
    shuttleRun: string;
    shuttleTest20m: string;
    sitReach: string;
    sittingHeight: string;
    situps: string;
    speed: string;
    sprintDistance: string;
    sprintTime: string;
    standingBroadJump: string;
    upperArmLength: string;
    verticalJump: string;
    walkRun: string;
    yoyo: string;
    shuttleLevel: string;
}

interface CompareResults {
    sit_reach: number;
    board_jump: number;
    grip_strength: number;
    power: number;
    situps: number;
    pullup: number;
    shuttle: number;
    speed: number;
    walk_run: number;
    endurance: number;
    height: number;
    weight: number;
    arm_span_height_ratio: number;
    sitting_height_ration: number;
    arm_span: number;
}

export enum TemplateTypeEnum {
    QAS_TEMPLATE = 'QAS_TEMPLATE',
    NORMAL = 'NORMAL'
}

export const getWorksheetHeadings = (testDateHeading: string, usingImperial: boolean, templateType?: string) => {
    if (templateType == 'qas_template') {
        return [
            [
                `User ID`,
                `Name`,
                `Test date ${testDateHeading}`,
                `Group`,
                `Gender`,
                `Age (yr)`,
                `Height (${usingImperial ? 'in' : 'cm'})`,
                `Height (${usingImperial ? 'in' : 'cm'}) %`,
                `Dad height (${usingImperial ? 'in' : 'cm'})`,
                `Mum height (${usingImperial ? 'in' : 'cm'})`,
                `Predicted height (${usingImperial ? 'in' : 'cm'})`,
                `Weight (${usingImperial ? 'lb' : 'kg'})`,
                `Weight (${usingImperial ? 'lb' : 'kg'}) %`,
                `Arm Span (${usingImperial ? 'in' : 'cm'})`,
                `Arm span (${usingImperial ? 'in' : 'cm'}) %`,
                `Arm span:height ratio`,
                `Sitting Height (${usingImperial ? 'in' : 'cm'})`,
                `Sitting height (${usingImperial ? 'in' : 'cm'}) %`,
                `Sitting ht : Height ratio`,
                `Peak Height Velocity Index`,
                `Vertical Jump (${usingImperial ? 'in' : 'cm'})`,
                `Vertical jump (${usingImperial ? 'in' : 'cm'}) %`,
                `Inclined pull-ups ( in 30 s)`,
                `Inclined pull-ups ( in 30 s) %`,
                `Sprint Time (s)`,
                `Sprint time %'ile`,
                usingImperial ? `4 x 11 yd shuttle run (s)` : `4 x 10 m shuttle run (s)`,
                usingImperial ? `4 x 11 yd shuttle run (s) %` : `4 x 10 m shuttle run (s) %`,
                `Shuttle (beep) test`,
                `Predicted VO2 Beep test %ile`,
                `Average %'ile rank`,
                `Maturation Age`
            ],
        ];
    } else {
        return [
            [
                `User ID`,
                `Test date ${testDateHeading}`,
                `Gender`,
                `Age (yr)`,
                `Fitness Age (yr)`,
                `Top Sport 1`,
                `Top Sport 1 OZ score`,
                `Top Sport 2`,
                `Top Sport 2 OZ score`,
                `Top Sport 3`,
                `Top Sport 3 OZ score`,
                `Top Sport 4`,
                `Top Sport 4 OZ score`,
                `Top Sport 5`,
                `Top Sport 5 OZ score`,
                `Sport TYPE 1`,
                `Sport TYPE 1 score`,
                `Sport TYPE 2`,
                `Sport TYPE 2 score`,
                `Sport TYPE 3`,
                `Sport TYPE 3 score`,
                `Sport TYPE 4`,
                `Sport TYPE 4 score`,
                `Sport TYPE 5`,
                `Sport TYPE 5 score`,
                `Sport TYPE 6`,
                `Sport TYPE 6 score`,
                `Sit & reach (${usingImperial ? 'in' : 'cm'}) %`,
                `Standing broad jump (${usingImperial ? 'in' : 'cm'}) %`,
                `Average grip strength (${usingImperial ? 'lb' : 'kg'}) %`,
                `Vertical jump (${usingImperial ? 'in' : 'cm'}) %`,
                `Bent-knee sit-ups ( in 60 s) %`,
                `Inclined pull-ups ( in 30 s) %`,
                usingImperial ? `4 x 11 yd shuttle run (s) %` : `4 x 10 m shuttle run (s) %`,
                `Maximum sprint speed (${usingImperial ? 'mph' : 'km/h'}) %`,
                usingImperial ? `1 mile run/walk (min:s) %` : `1.6 km run/walk (min:s) %`,
                `Aerobic fitness %`,
                `Height (${usingImperial ? 'in' : 'cm'}) %`,
                `Weight (${usingImperial ? 'lb' : 'kg'}) %`,
                `Arm span (${usingImperial ? 'in' : 'cm'}) %`,
                // `Brachial index`,
                `Sitting height (${usingImperial ? 'in' : 'cm'}) %`,
                `Average %'ile rank`, //??
            ],
        ];
    }
}

export const getWorksheetTestDataHeadings = (usingImperial: boolean) => {
    return [
        `Height (${usingImperial ? 'in' : 'cm'})`,
        `Weight (${usingImperial ? 'lb' : 'kg'})`,
        `Arm Span (${usingImperial ? 'in' : 'cm'})`,
        `Sitting Height (${usingImperial ? 'in' : 'cm'})`,
        `Forearm Length (${usingImperial ? 'in' : 'cm'})`,
        `Upperarm Length (${usingImperial ? 'in' : 'cm'})`,
        `Sit & reach (${usingImperial ? 'in' : 'cm'})`,
        `Standing Broad Jump (${usingImperial ? 'in' : 'cm'})`,
        `Grip strength - left (${usingImperial ? 'lb' : 'kg'})`,
        `Grip strength - right (${usingImperial ? 'lb' : 'kg'})`,
        `Vertical Jump (${usingImperial ? 'in' : 'cm'})`,
        `Bent-knee sit-ups ( in 60 s)`,
        `Inclined pull-ups ( in 30 s)`,
        usingImperial ? `4 x 11 yd shuttle run (s)` : `4 x 10 m shuttle run (s)`,
        `Sprint Distance (${usingImperial ? 'yd' : 'm'})`,
        `Sprint Time (s)`,
        `Maximum sprint speed (${usingImperial ? 'mph' : 'km/h'})`,
        usingImperial ? `1 mile run/walk (min:s)` : `1.6 km run/walk (min:s)`,
        `Shuttle (beep) test`,
        `Yo-Yo Test`,
        `Aerobic fitness (VO2max)`
    ]
}

export const getTestDataResultObjectsForCSV = (test: TestObject): Array<object> => {
    return [
        {
            name: "height",
            result: test.height,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: true,
                from: "cm",
                to: "in",
                formatAmount: 2,
            },
            metricFormatAmount: 1,
        },
        {
            name: "weight",
            result: test.weight,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: "kg",
                to: "lb",
                formatAmount: 1,
            },
            metricFormatAmount: 1,
        },
        {
            name: "armSpan",
            result: test.armSpan,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: true,
                from: "cm",
                to: "in",
                formatAmount: 2,
            },
            metricFormatAmount: 1,
        },
        {
            name: 'sittingHeight',
            result: test.sittingHeight,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'cm',
                to: 'in',
                formatAmount: 1
            },
            metricFormatAmount: 1
        },
        {
            name: 'forearmLength',
            result: test.forearmLength,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'cm',
                to: 'in',
                formatAmount: 2
            },
            metricFormatAmount: 1
        },
        {
            name: 'upperArmLength',
            result: test.upperArmLength,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'cm',
                to: 'in',
                formatAmount: 2
            },
            metricFormatAmount: 1
        },
        {
            name: 'sitReach',
            result: test.sitReach,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'cm',
                to: 'in',
                formatAmount: 2
            },
            metricFormatAmount: 1
        },
        {
            name: 'standingBroadJump',
            result: test.standingBroadJump,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: true,
                from: 'cm',
                to: 'in',
                formatAmount: 2
            },
            metricFormatAmount: 1
        },
        {
            name: 'gripStrengthLeft',
            result: test.gripStrengthLeft,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'kg',
                to: 'lb',
                formatAmount: 1
            },
            metricFormatAmount: 1
        },
        {
            name: 'gripStrengthRight',
            result: test.gripStrengthRight,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'kg',
                to: 'lb',
                formatAmount: 1
            },
            metricFormatAmount: 1
        },
        {
            name: 'verticalJump',
            result: test.verticalJump,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'cm',
                to: 'in',
                formatAmount: 1
            },
            metricFormatAmount: 1
        },
        {
            name: 'situps',
            result: test.situps,
            imperialProperties: {
                enabled: false
            },
            metricFormatAmount: 1
        },
        {
            name: 'inclinedPullup',
            result: test.inclinedPullup,
            imperialProperties: {
                enabled: false
            },
            metricFormatAmount: 1
        },
        {
            name: 'shuttleRun',
            result: test.shuttleRun,
            imperialProperties: {
                enabled: false
            },
            metricFormatAmount: 1
        },
        {
            name: 'sprintDistance',
            result: test.sprintDistance,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'm',
                to: 'ft',
                formatAmount: 2
            },
            metricFormatAmount: 1
        },
        {
            name: 'sprintTime',
            result: test.sprintTime,
            imperialProperties: {
                enabled: false
            },
            metricFormatAmount: 1
        },
        {
            name: 'speed',
            result: test.speed,
            imperialProperties: {
                enabled: true,
                convertFeetAndInches: false,
                from: 'km/h',
                to: 'm/h',
                formatAmount: 2
            },
            metricFormatAmount: 1
        },
        {
            name: 'walkRun',
            result: test.walkRun,
            imperialProperties: {
                enabled: false
            },
            metricFormatAmount: 1
        },
        {
            name: 'shuttleLevel',
            result: test.shuttleLevel,
            imperialProperties: {
                enabled: false
            },
            metricFormatAmount: 1
        },
        {
            name: 'yoyo',
            result: test.yoyo,
            imperialProperties: {
                enabled: false
            },
            metricFormatAmount: 1
        },
        {
            name: 'shuttleTest20m',
            result: test.shuttleTest20m,
            imperialProperties: {
                enabled: false
            },
            metricFormatAmount: 1
        }
    ]
}

export const getResultOverviewCSVData = (singleRow: Array<any>, compareResults: CompareResults) => {
    singleRow.push(
        compareResults != null && compareResults.sit_reach >= 0
            ? parseFloat(compareResults.sit_reach.toFixed(2))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.board_jump >= 0
            ? parseFloat(compareResults.board_jump.toFixed(2))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.grip_strength >= 0
            ? parseFloat(compareResults.grip_strength.toFixed(1))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.power >= 0
            ? parseFloat(compareResults.power.toFixed(1))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.situps >= 0
            ? parseFloat(compareResults.situps.toFixed(2))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.pullup >= 0
            ? parseFloat(compareResults.pullup.toFixed(1))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.shuttle >= 0
            ? parseFloat(compareResults.shuttle.toFixed(1))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.speed >= 0
            ? parseFloat(compareResults.speed.toFixed(2))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.walk_run >= 0
            ? parseFloat(compareResults.walk_run.toFixed(2))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.endurance >= 0
            ? parseFloat(compareResults.endurance.toFixed(2))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.height >= 0
            ? parseFloat(compareResults.height.toFixed(1))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.weight >= 0
            ? parseFloat(compareResults.weight.toFixed(1))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.arm_span_height_ratio >= 0
            ? parseFloat(compareResults.arm_span_height_ratio.toFixed(1))
            : ""
    );

    singleRow.push(
        compareResults != null && compareResults.sitting_height_ration >= 0
            ? parseFloat(compareResults.sitting_height_ration.toFixed(2))
            : ""
    );

    return singleRow;
}

export const calculateAverageCompareResults = (data: CompareResults, templateType: TemplateTypeEnum) => {
    let count = 0;
    let average = 0;

    if (data) {
        if (templateType === TemplateTypeEnum.NORMAL) {
            if (!isNaN(data.sit_reach)) count++;
            if (!isNaN(data.board_jump)) count++;
            if (!isNaN(data.grip_strength)) count++;
            if (!isNaN(data.power)) count++;
            if (!isNaN(data.situps)) count++;
            if (!isNaN(data.pullup)) count++;
            if (!isNaN(data.shuttle)) count++;
            if (!isNaN(data.speed)) count++;
            if (!isNaN(data.walk_run)) count++;
            if (!isNaN(data.endurance)) count++;
            if (!isNaN(data.height)) count++;
            if (!isNaN(data.weight)) count++;
            if (!isNaN(data.arm_span_height_ratio)) count++;
            if (!isNaN(data.sitting_height_ration)) count++;

            if (count > 0) {
                average =
                    (
                        (data.sit_reach ? data.sit_reach : 0) +
                        (data.board_jump ? data.board_jump : 0) +
                        (data.grip_strength ? data.grip_strength : 0) +
                        (data.power ? data.power : 0) +
                        (data.situps ? data.situps : 0) +
                        (data.pullup ? data.pullup : 0) +
                        (data.shuttle ? data.shuttle : 0) +
                        (data.speed ? data.speed : 0) +
                        (data.walk_run ? data.walk_run : 0) +
                        (data.endurance ? data.endurance : 0) +
                        (data.height ? data.height : 0) +
                        (data.weight ? data.weight : 0) +
                        (data.arm_span_height_ratio ? data.arm_span_height_ratio : 0) +
                        (data.sitting_height_ration ? data.sitting_height_ration : 0)
                    ) / count;
            }
        }

        if (templateType === TemplateTypeEnum.QAS_TEMPLATE) {
            if (!isNaN(data.height)) count++;
            if (!isNaN(data.weight)) count++;
            if (!isNaN(data.arm_span)) count++;
            if (!isNaN(data.sitting_height_ration)) count++;
            if (!isNaN(data.power)) count++;
            if (!isNaN(data.pullup)) count++;
            if (!isNaN(data.speed)) count++;
            if (!isNaN(data.shuttle)) count++;
            if (!isNaN(data.endurance)) count++;

            if (count > 0) {
                average =
                    (
                        (data.height ? data.height : 0) +
                        (data.weight ? data.weight : 0) +
                        (data.arm_span ? data.arm_span : 0) +
                        (data.sitting_height_ration ? data.sitting_height_ration : 0) +
                        (data.power ? data.power : 0) +
                        (data.pullup ? data.pullup : 0) +
                        (data.speed ? data.speed : 0) +
                        (data.shuttle ? data.shuttle : 0) +
                        (data.endurance ? data.endurance : 0)
                    ) / count;
            }
        }
    }

    return average;
}