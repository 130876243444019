import React from "react";

const ArmSpan1Svg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Arm Span</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="arm_span_1" data-name="arm span 1">
            <path className="cls-2" style={{fill: color}} d="M283.93,242.5" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M283.93,242.5" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M482.63,220H85.24a22.5,22.5,0,1,0,0,45H230V378.61a53.89,53.89,0,0,0,53.89,53.89h0a53.89,53.89,0,0,0,53.89-53.89V265H482.63a22.5,22.5,0,0,0,0-45Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M324.81,156.42h0a41.86,41.86,0,1,0-31.43,50.16A41.94,41.94,0,0,0,324.81,156.42Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M490.42,160.79a5,5,0,0,0-5,5v13.62H327.78a46.38,46.38,0,0,1-4.5,10H485.42V203a5,5,0,1,0,10,0V165.79A5,5,0,0,0,490.42,160.79Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M240.23,179.41H82.45V165.79a5,5,0,0,0-10,0V203a5,5,0,0,0,10,0V189.41H244.71A45.14,45.14,0,0,1,240.23,179.41Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default ArmSpan1Svg;
