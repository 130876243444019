// REACT
import React, { useState, useEffect } from 'react';

// MATERIAL UI
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications';

// CONSTANTS
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../store/constants';
import { useDispatch, useSelector } from 'react-redux';
import { CheckIfUserIsLoggedIn } from '../../../../api/Helper.js';

const NewStudentComponent = ({ renderUsers }) => {
    const dispatch = useDispatch();
    const [nameVal, setNameVal] = useState("");
    const [genderVal, setGenderVal] = useState("");
    const [dobVal, setDobVal] = useState("");
    const [classNameVal, setClassNameVal] = useState("");

    const orgState = useSelector(state => state.organisationState);

    const { addToast } = useToasts();

    const resetNewUserFields = () => {
        setNameVal("");
        setGenderVal("");
        setDobVal("");
        setClassNameVal("");
    }

    const submitNewUser = () => {
        var json = {
            "type": "single",
            "user_name": nameVal,
            "gender": genderVal,
            "dob": dobVal,
            "class_name": orgState.plan == "Starter" ? "" : classNameVal
        };

        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/CheckSessionIsValid`, {
            credentials: 'include'
        }).then(res => {
            CheckIfUserIsLoggedIn(res, dispatch).then(() => {
                fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/CreateUser`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(json),
                    credentials: "include"
                }).then((res) => {
                    res.json().then(async (data) => {
                        if (data.success) {
                            await renderUsers();
                            resetNewUserFields();
                            addToast("New student created", {
                                appearance: 'success',
                                autoDismiss: true
                            });
                        } else {
                            addToast("Unable to create user", {
                                appearance: 'error',
                                autoDismiss: true
                            });
                        }
                    });
                })
            })
        }).catch(() => { })
    }

    return (
        <div className="body-row">
            <div className="text-field-large">
                <input type="text" value={nameVal} onChange={(e) => setNameVal(e.target.value)} variant="filled" placeholder={""} />
            </div>
            <div className="text-field-large">
                <input type="text" value={genderVal} onChange={(e) => setGenderVal(e.target.value)} variant="filled" placeholder={""} />
            </div>
            <div className="text-field-large">
                <input type="text" value={dobVal} onChange={(e) => setDobVal(e.target.value)} variant="filled" placeholder={""} />
            </div>
            <div className="text-field-large">
                <input disabled={orgState.plan == "Starter"} type="text" value={classNameVal} onChange={(e) => setClassNameVal(e.target.value)} variant="filled" placeholder={""} />
            </div>

            <Button onClick={submitNewUser} variant="contained">
                Add +
            </Button>
        </div>


    )
}

export default NewStudentComponent;