import React, { useState, useEffect } from 'react';

// MATERIAL UI
import { NativeSelect, Select, MenuItem, Button, TextField } from '@material-ui/core';
import { Card, CardContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import SignupStageTwo from './SignupStageTwo.js';
import SignupStageThree from './SignupStageThree.js';
import SignupStageFour from './Signup/StageFour/SignupStageFour.js';
import SignupSuccess from './Signup/Success/SignupSuccess.js';

import {
  BACKEND_URL,
  ENVIRONMENT_PATH
} from '../../store/constants';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications';

const zxcvbn = require("zxcvbn");

/** Required to show the dialog appearing from the bottom of the screen */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// TODO: Build Singup inputs section. Build seperate components
// for each signup inputs screen and include here. Store state locally for time being
const Signup = ({ signup }) => {
  const { addToast } = useToasts();
  const [signupOrgName, setSignupOrgName] = useState("");
  const [signupOrgType, setSignupOrgType] = useState("");
  const [signupAddress1, setSignupAddress1] = useState("");
  const [signupAddress2, setSignupAddress2] = useState("");
  const [signupState, setSignupState] = useState("");
  const [signupPostcode, setSignupPostcode] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupContact, setSignupContact] = useState("");
  const [signupCountry, setSignupCountry] = useState("");
  const [signupPwd, setSignupPwd] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [signupPwdConfirm, setSignupPwdConfirm] = useState("");

  const [signupStage, setSignupStage] = useState(0);

  const [organisationName, setOrganisationName] = useState("");
  const [organisationLoginLink, setOrganisationLoginLink] = useState("");
  const [organisationPassword, setOrganisationPassword] = useState("");
  const [showOrganisationCreatedDialog, setShowOrganisationCreatedDialog] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState(0);
  const [planType, setPlanType] = useState('');

  const passwordOnChangeHandler = value => {
    setPasswordScore(zxcvbn(value).score);
    setSignupPwd(value);
  }

  const passwordConfirmedOnChangeHandler = value => {
    setSignupPwdConfirm(value);
  }

  const renderPasswordScore = () => {
    var scoreWord = "poor";

    switch (passwordScore) {
      case 0:
        scoreWord = "poor";
        break;
      case 1:
        scoreWord = "poor";
        break;
      case 2:
        scoreWord = "weak";
        break;
      case 3:
        scoreWord = "average";
        break;
      case 4:
        scoreWord = "excellent";
        break;
    }
    return (
      <div className="password-strength-levels">
        <div className={`level-${scoreWord} first-level ${passwordScore < 2 ? 'empty' : ''}`}></div>
        <div className={`level-${scoreWord} second-level ${passwordScore < 3 ? 'empty' : ''}`}></div>
        <div className={`level-${scoreWord} third-level ${passwordScore < 4 ? 'empty' : ''}`}></div>

        <span className={`level-${scoreWord} password-level-span`}>{scoreWord}</span>
      </div>
    )
  }

  const goToLoginLink = () => {
    window.location.href = organisationLoginLink;
  }

  const renderContent = () => {
    if (signupStage == 0) {
      return (
        <div className="signup-wrapper">
          <div className="signup-step-circle-wrapper"><div className="signup-step-circle selected" id="signup-step-circle-1"></div></div>
          <div className="signup-step-dash"></div>
          <div className="signup-step-circle-wrapper"><div className="signup-step-circle" id="signup-step-circle-2"></div></div>
          <div className="signup-step-dash"></div>
          <div className="signup-step-circle-wrapper"><div className="signup-step-circle" id="signup-step-circle-3"></div></div>

          <div className="signup-title">
            Organisation Details
          </div>

          <div className="signup-box">
            <div className="signup-box-container">

              <div className="signup-field signup-box-organisation-name">
                <div className="signup-field-title signup-box-organisation-name-title">
                  Organisation Name *
                </div>
                <input className="signup-field-text" type="text" name="organisation-name" id="signup-box-organisation-name-input" onChange={(e) => setSignupOrgName(e.target.value)} />
                {/*<div>{signupOrgName}</div>*/}
              </div>

              <div className="signup-field signup-box-organisation-type">
                <div className="signup-field-title signup-box-organisation-type-title">
                  Organisation Type *
                </div>
                <Select
                  displayEmpty
                  onChange={(e) => {
                    var value = e.target.value;
                    setSignupOrgType(value);
                  }}
                  value={signupOrgType}
                  renderValue={selected => {
                    if (selected) {
                      return selected;
                    } else {
                      return <span></span>;
                    }
                  }}>
                  <MenuItem value="Independent school">
                    <span>Independent school</span>
                  </MenuItem>

                  <MenuItem value="Government school">
                    <span>Government school</span>
                  </MenuItem>

                  <MenuItem value="Tertiary institution">
                    <span>Tertiary institution</span>
                  </MenuItem>

                  <MenuItem value="Sports institute/training centre">
                    <span>Sports institute/training centre</span>
                  </MenuItem>

                  <MenuItem value="Gym/fitness centre">
                    <span>Gym/fitness centre</span>
                  </MenuItem>

                  <MenuItem value="Private testing consultancy">
                    <span>Private testing consultancy</span>
                  </MenuItem>

                  <MenuItem value="Sports club">
                    <span>Sports club</span>
                  </MenuItem>

                  <MenuItem value="Government department">
                    <span>Government department</span>
                  </MenuItem>

                  <MenuItem value="Other">
                    <span>Other</span>
                  </MenuItem>
                </Select>
              </div>

              <div className="signup-field signup-box-address-1">
                <div className="signup-field-title signup-box-address-1-title">
                  Address Line 1 *
                </div>
                <input className="signup-field-text" type="text" name="address-1" id="signup-box-address-1-input" onChange={(e) => setSignupAddress1(e.target.value)} />
              </div>

              <div className="signup-field signup-box-address-2">
                <div className="signup-field-title signup-box-address-2-title">
                  Address Line 2
                </div>
                <input className="signup-field-text" type="text" name="address-2" id="signup-box-address-2-input" onChange={(e) => setSignupAddress2(e.target.value)} />
              </div>

              <div className="signup-field signup-box-state">
                <div className="signup-field-title signup-box-state-title">
                  State
                </div>
                <input className="signup-field-text" type="text" name="state" id="signup-box-state-input" onChange={(e) => setSignupState(e.target.value)} />
              </div>

              <div className="signup-field signup-box-postcode">
                <div className="signup-field-title signup-box-postcode-title">
                  Postcode *
                </div>
                <input className="signup-field-text" type="text" name="postcode" id="signup-box-postcode-input" onChange={(e) => setSignupPostcode(e.target.value)} />
              </div>

              <div className="signup-field signup-box-country">
                <div className="signup-field-title signup-box-country-title">
                  Country *
                </div>
                <input className="signup-field-text" type="text" name="country" id="signup-box-country-input" onChange={(e) => setSignupCountry(e.target.value)} />
              </div>

              <div className="signup-field signup-box-contact">
                <div className="signup-field-title signup-box-contact-title">
                  Contact Number
                </div>
                <input className="signup-field-text" type="text" name="contact" id="signup-box-contact-input" onChange={(e) => setSignupContact(e.target.value)} />
              </div>

              <div className="signup-field signup-box-email">
                <div className="signup-field-title signup-box-email-title">
                  Email *
                </div>
                <input className="signup-field-text" type="text" name="email" id="signup-box-email-input" onChange={(e) => setSignupEmail(e.target.value)} />
              </div>

              <div className="signup-field signup-box-password">
                <div className="signup-field-title signup-box-password-title">
                  Password *
                </div>
                <input className="signup-field-text" type="text" name="password" id="signup-box-pwd-input" onChange={(e) => setSignupPwd(e.target.value)} />
              </div>

              <div className="signup-field signup-box-password-confirm">
                <div className="signup-field-title signup-box-password-confirm-title">
                  Password Confirm *
                </div>
                <input className="signup-field-text" type="text" name="password-confirm" id="signup-box-pwd-confirm-input" onChange={(e) => passwordOnChangeHandler(e.target.value)} />
              </div>

              <div className="signup-field ">
                <div className="signup-field-title password-strength">
                  Password strength
                </div>
                {renderPasswordScore()}
              </div>

            </div>
          </div>

          <div className="signup-next-button" id="signup-next-button-step-1">
            <input type="submit" value="Next" onClick={() => {
              signup({
                organisation_name: signupOrgName,
                organisation_type: signupOrgType,
                organisation_address_1: signupAddress1,
                organisation_address_2: signupAddress2,
                organisation_state: signupState,
                organisation_postcode: signupPostcode,
                organisation_email: signupEmail,
                organisation_contact_no: signupContact,
                organisation_country: signupCountry,
                organisation_password: signupPwd,
                organisation_confirmed_password: signupPwdConfirm
              });

              setSignupStage(1);
            }} />
          </div>

        </div>
      );
    } else if (signupStage == 1) {
      return (
        <SignupStageTwo setSignupStage={() => setSignupStage(2)} backStage={() => setSignupStage(0)} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
      )

    } else if (signupStage == 2) {
      return (
        <SignupStageThree setSignupStage={() => setSignupStage(3)} backStage={() => setSignupStage(1)} planSelected={selectedPlan} />
      )
    } else if(signupStage == 3) {
        return <SignupStageFour setSignupStage={() => {
          fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=create_organisation&orgName=${signupOrgName}&orgType=${signupOrgType}&orgAddress1=${signupAddress1}&orgState=${signupState}&orgPostcode=${signupPostcode}&orgEmail=${signupEmail}&orgContactNo=${signupContact}&orgCountry=${signupCountry}&orgPassword=${signupPwd}`)
            .then((res) => {
              res.json().then((result) => {
                setShowOrganisationCreatedDialog(true);
                setOrganisationLoginLink(result.org_link);
                setOrganisationName(result.org_name);
                setOrganisationPassword(result.org_password);
                addToast("Organisation is created", {
                  appearance: 'success',
                  autoDismiss: true
                });

                setSignupStage(4);
              })
            })
        }} 
        backStage={() => {
          setSignupStage(2);
        }}
        />
    } else if(signupStage == 4) {
      return <SignupSuccess buttonClickHandler={goToLoginLink} />
    }
  }

  return (
    <React.Fragment>
      {renderContent()}

      <Dialog open={showOrganisationCreatedDialog} TransitionComponent={Transition} keepMounted onClose={null}>
        <Card>
          <CardContent>
            <div className="card-body">
              <h2>Organisation created</h2>
              <hr />
              <span>
                Organisation name: {organisationName}
                <br />
                Organisation password: {organisationPassword}
                <br />Link to login: {organisationLoginLink}
              </span>
            </div>

            <div className="card-button-container">
              <Button variant="contained" onClick={() => {
                setShowOrganisationCreatedDialog(false);
              }}>
                Close
              </Button>
            </div>
          </CardContent>
        </Card>
      </Dialog>
    </React.Fragment>
  )

}

export default Signup;
