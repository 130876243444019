import React from "react";

const SprintSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Sprint Speed</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="_50_m_sprint" data-name="50 m sprint">
            <path className="cls-2" style={{fill: color}} d="M190.08,211.83H75.5a6.43,6.43,0,1,1,0-12.86H190.08a6.43,6.43,0,1,1,0,12.86Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M253.7,274.09H117.78a6.44,6.44,0,1,1,0-12.87H253.7a6.44,6.44,0,0,1,0,12.87Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M103.16,344.53H41.52a6.44,6.44,0,0,1,0-12.87h61.64a6.44,6.44,0,0,1,0,12.87Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M252.51,135.73H127.66a6.43,6.43,0,1,1,0-12.86H252.51a6.43,6.43,0,1,1,0,12.86Z"
                  transform="translate(-2.83 -7.46)"/>
            <g id="Group_181" data-name="Group 181">
                <path id="Path_16" data-name="Path 16" className="cls-2" style={{fill: color}}
                      d="M398.2,189.26A41.26,41.26,0,1,0,356.94,148a41.26,41.26,0,0,0,41.26,41.26Z"
                      transform="translate(-2.83 -7.46)"/>
                <path id="Path_17" data-name="Path 17" className="cls-2" style={{fill: color}}
                      d="M466.07,177.38c-23.69,33.19-55.41,37-89.55,16.76-2.33-1.37-12.63-7-14.92-8.41-54.92-32.6-109.77-19.81-146.56,31.7-15.63,21.91,20.52,42.68,36,21,19-26.61,43.18-34.31,69.61-25.88-13.53,23.49-25.37,46.84-42.06,80s-53.58,59.67-89,39c-25.56-15-48.62,21.78-23.13,36.68,48.36,28.27,105.08,10.91,134-25.84a22.67,22.67,0,0,0,3.23,1.43c23.65,8.24,54.66,30.18,64.1,38s25.67,47.39,35.3,67.29c11.7,24.15,49.45,6.56,37.71-17.69-10.92-22.59-29.25-67.65-43.56-79.06-11.47-9.14-33.74-26.16-52.63-35.82q19.17-37.12,40.09-73.3c44.65,13.68,87.1-2.43,117.32-44.78C517.69,176.49,481.54,155.72,466.07,177.38Z"
                      transform="translate(-2.83 -7.46)"/>
            </g>
        </g>
    </svg>
)

export default SprintSvg;
