import React from 'react';

const FieldInfo = ({info}) => {
    return (
        <div className="field-info" dangerouslySetInnerHTML={{ __html: info }}>
        </div>
    );
}

export default FieldInfo;
