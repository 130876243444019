import React from "react";

const MuscularStrengthSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Muscular Strength</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="muscular_strength_and_power" data-name="muscular strength and power">
            <path id="Path_18" data-name="Path 18" className="cls-2" style={{fill: color}}
                  d="M446.59,401.12c-1.06,6.87-2,13.31-2.74,19.23A32.41,32.41,0,0,1,422.26,447c-40.79,14.08-133.84,37.52-187.17-17.76A13,13,0,0,0,222,425.88c-13.74,4.15-44.08,10.86-77,2.18-43.24-11.39-59.85-66.27-59.85-111V283.4a24.85,24.85,0,0,1,21.59-24.68,26.51,26.51,0,0,1,5.84-.13c35,3.93,59.19,19.26,76.16,35a207.18,207.18,0,0,1,22.91,25.66l0,.07a5.94,5.94,0,0,0,8.33,1,6.17,6.17,0,0,0,.66-.6,82.49,82.49,0,0,1,127.08,7.94,3.08,3.08,0,0,0,4.29.7,3.12,3.12,0,0,0,1.27-2.34C356.27,267.5,380,188.9,384,176a7.86,7.86,0,0,0,.19-3.91c-.89-4.31-2.5-9.87-3.8-15.1a7.91,7.91,0,0,0-6.27-5.89c-35.64-6.55-37.45-8.76-46.61-15.6a6.68,6.68,0,0,0-6.62-.78c-34.71,14.91-40-2.2-40-2.2-10.6-35.71-10.44-29.4-9.53-50a17.85,17.85,0,0,1,12.34-16.2c16.18-5.25,43.63-12.38,52.44-14.64a11.48,11.48,0,0,1,6.39.2c21.34,7,76.19,47,76.19,47,12.67,10.29,21.09,25.19,22.44,45.2,8.62,128.42,29.15,104.54,5.45,257Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default MuscularStrengthSvg;
