import { 
    SET_SELECTED_USER_IDS,
    SET_ORGANISATION_NAME,
    SET_ORGANISATION_TYPE,
    SET_ORGANISATION_ADDRESS_LINE_1,
    SET_ORGANISATION_STATE,
    SET_ORGANISATION_POSTCODE,
    SET_ORGANISATION_COUNTRY,
    SET_PRIMARY_COLOR,
    SET_SECONDARY_COLOR,
    SET_ACCENT_COLOR,
    SET_FILTER_GENDER,
    SET_FILTER_AGES,
    SET_FILTER_GROUP_NAMES,
    SET_DATE_RANGE_FROM_FILTER,
    SET_DATE_RANGE_TO_FILTER,
    SET_ORG_LOGO,
    SET_ACCESS_USER,
    SET_PLAN,
    SET_PENDING_REFRESH_COMPLETION
} from '../OrganisationConstants.js';

export const setSelectedUserIds = (input) => {
    return {
        type: SET_SELECTED_USER_IDS,
        payload: input
    }
}

export const setAccessUser = (input) => {
    return {
        type: SET_ACCESS_USER,
        payload: input
    }
}

export const setOrganisationType = (input) => {
    return {
        type: SET_ORGANISATION_TYPE,
        payload: input
    }
}

export const setOrganisationAddressLine1 = (input) => {
    return {
        type: SET_ORGANISATION_ADDRESS_LINE_1,
        payload: input
    }
}

export const setOrganisationState = (input) => {
    return {
        type: SET_ORGANISATION_STATE,
        payload: input
    }
}

export const setOrganisationPostcode = (input) => {
    return {
        type: SET_ORGANISATION_POSTCODE,
        payload: input
    }
}

export const setOrganisationCountry = (input) => {
    return {
        type: SET_ORGANISATION_COUNTRY,
        payload: input
    }
}


export const setPrimaryColor = (input) => {
    return {
        type: SET_PRIMARY_COLOR,
        payload: input
    }
}

export const setSecondaryColor = (input) => {
    return {
        type: SET_SECONDARY_COLOR,
        payload: input
    }
}

export const setAccentColor = (input) => {
    return {
        type: SET_ACCENT_COLOR,
        payload: input
    }
}

export const setOrgLogo = (input) => {
    return {
        type: SET_ORG_LOGO,
        payload: input
    }
}

export const setFilterGroupNames = (input) => {
    return {
        type: SET_FILTER_GROUP_NAMES,
        payload: input
    }
}

export const setFilterAges = (input) => {
    return {
        type: SET_FILTER_AGES,
        payload: input
    }
}

export const setFilterGender = (input) => {
    return {
        type: SET_FILTER_GENDER,
        payload: input
    }
}

export const setDateRangeFromFilter = (input) => {
    return {
        type: SET_DATE_RANGE_FROM_FILTER,
        payload: input
    }
}

export const setDateRangeToFilter = (input) => {
    return {
        type: SET_DATE_RANGE_TO_FILTER,
        payload: input
    }
}

export const setPlan = (input) => {
    return {
        type: SET_PLAN,
        payload: input
    }
}

export const setPendingRefreshCompletion = (input) => {
    return {
        type: SET_PENDING_REFRESH_COMPLETION,
        payload: input
    }
}