exports.sportsDataset = [
    { name: 'sitAndReach', label: 'Sit & reach' },
    { name: 'standingBroadJump', label: 'Standing broad jump' },
    { name: 'gripStrength', label: 'Grip strength' },
    { name: 'verticalJump', label: 'Vertical jump' },
    { name: 'sitUps', label: 'Bent-knee sit-ups' },
    { name: 'inclinedPullUps', label: 'Inclined pull-ups' },
    { name: 'shuttleRun', label: '4 x 10 m shuttle run' },
    { name: 'sprint', label: 'Maximum sprint speed' },
    { name: 'runWalk', label: '1.6 km run/walk' },
    { name: 'aerobicFitness', label: 'Aerobic fitness' }
]

exports.sportsNorms = [
    [
        {
            name: 'Bent-knee sit-ups',
            normValue: 40.8
        },
        {
            name: 'Standing broad jump',
            normValue: 217.5
        },
        {
            name: 'Sit & reach',
            normValue: 38.4
        },
        {
            name: 'Inclined pull-ups',
            normValue: 34.7
        },
        {
            name: '4 x 10 m shuttle run',
            normValue: 10.30
        },
        {
            name: '1.6 km run/walk',
            normValue: 525.7
        },
        {
            name: 'Grip strength',
            normValue: 48.0
        },
        // Unknown Norm (not recorded in supplied documentation)
        {
            name: 'Maximum sprint speed',
            normValue: 12
        },
        {
            name: 'Vertical jump',
            normValue: 45.4
        },
        {
            name: 'Aerobic fitness',
            normValue: 90
        }
    ],
    [
        {
            name: 'Bent-knee sit-ups',
            normValue: 40.8
        },
        {
            name: 'Standing broad jump',
            normValue: 217.5
        },
        {
            name: 'Sit & reach',
            normValue: 38.4
        },
        {
            name: 'Inclined pull-ups',
            normValue: 34.7
        },
        {
            name: '4 x 10 m shuttle run',
            normValue: 10.30
        },
        {
            name: '1.6 km run/walk',
            normValue: 525.7
        },
        {
            name: 'Grip strength',
            normValue: 48.0
        },
        // Unknown Norm (not recorded in supplied documentation)
        {
            name: 'Maximum sprint speed',
            normValue: 12
        },
        {
            name: 'Vertical Jump',
            normValue: 45.4
        },
        {
            name: 'Aerobic fitness',
            normValue: 90
        }
    ],
    [
        {
            name: 'Bent-knee sit-ups',
            normValue: 40.8
        },
        {
            name: 'Standing broad jump',
            normValue: 217.5
        },
        {
            name: 'Sit & reach',
            normValue: 38.4
        },
        {
            name: 'Inclined pull-ups',
            normValue: 34.7
        },
        {
            name: '4 x 10 m shuttle run',
            normValue: 10.30
        },
        {
            name: '1.6 km run/walk',
            normValue: 525.7
        },
        {
            name: 'Grip strength',
            normValue: 48.0
        },
        // Unknown Norm (not recorded in supplied documentation)
        {
            name: 'Maximum sprint speed',
            normValue: 12
        },
        {
            name: 'Vertical Jump',
            normValue: 45.4
        },
        {
            name: 'Aerobic fitness',
            normValue: 90
        }
    ],
    [
        {
            name: 'Bent-knee sit-ups',
            normValue: 40.8
        },
        {
            name: 'Standing broad jump',
            normValue: 217.5
        },
        {
            name: 'Sit & reach',
            normValue: 38.4
        },
        {
            name: 'Inclined pull-ups',
            normValue: 34.7
        },
        {
            name: '4 x 10 m shuttle run',
            normValue: 10.30
        },
        {
            name: '1.6 km run/walk',
            normValue: 525.7
        },
        {
            name: 'Grip strength',
            normValue: 48.0
        },
        // Unknown Norm (not recorded in supplied documentation)
        {
            name: 'Maximum sprint speed',
            normValue: 12
        },
        {
            name: 'Vertical Jump',
            normValue: 45.4
        },
        {
            name: 'Aerobic fitness',
            normValue: 90
        }
    ],
    [
        {
            name: 'Bent-knee sit-ups',
            normValue: 40.8
        },
        {
            name: 'Standing broad jump',
            normValue: 217.5
        },
        {
            name: 'Sit & reach',
            normValue: 38.4
        },
        {
            name: 'Inclined pull-ups',
            normValue: 34.7
        },
        {
            name: '4 x 10 m shuttle run',
            normValue: 10.30
        },
        {
            name: '1.6 km run/walk',
            normValue: 525.7
        },
        {
            name: 'Grip strength',
            normValue: 48.0
        },
        // Unknown Norm (not recorded in supplied documentation)
        {
            name: 'Maximum sprint speed',
            normValue: 12
        },
        {
            name: 'Vertical Jump',
            normValue: 45.4
        },
        {
            name: 'Aerobic fitness',
            normValue: 90
        }
    ]

]