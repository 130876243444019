// REACT
import React, { useState, useEffect } from 'react';

// CONSTANTS
import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../../store/constants';

import SearchIcon from '../../../../../assets/Organisation/TalentID/SearchIcon.js';
import {useDispatch, useSelector} from "react-redux";

import $ from 'jquery';
import { CheckIfUserSessionIsValid } from '../../../../../api/Helper.js';
import FilterByComponent from './FilterByComponent/FilterByComponent.js';

const SportSection = ({ 
    setLoading = () => {},
    sportSelectionHandler, 
    allowMultipleSports = true,
    setSportSelectionRequestStringHandler,
    hideFilterBy = false,
    setFilterBySelection = () => {},
    hideSortBy = false
}) => {
    const dispatch = useDispatch();

    const organisationLocalState = useSelector(state => state.organisationState);

    const images = require.context('../../../../../images', true);

    const [sports, setSports] = useState([]);

    const [selectedImage, setSelectedImage] = useState("");

    const [selectedSport, setSelectedSport] = useState([]);

    const [sportDescription, setSportDescription] = useState("");

    
    const [filter, setFilter] = useState('organisation');

    const [selectedFilter, setSelectedFilter] = useState('all');

    const [searchTerm, setSearchTerm] = useState("");

    const [confirmedSearchTerm, setConfirmedSearchTerm] = useState("");

    const [filteringOutDiplicatedSports, setFilteringOutDiplicatedSports] = useState([]);

    const getImageFromSelectedSportIndex = () => {
        try {
            var image = images("./" + selectedImage);
            return image;
        } catch (err) {
            console.log("Error = ", err);
        }
    }

    // The API call to get the sports and set them to the state
    const renderSportsList = () => {
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/EliteAthleteProfilesServlet?gender=${selectedFilter == "sportmatch-list" ? "sportmatch-list" : selectedFilter}${filter == "organisation" ? "&using_org_sports=true" : ""}`,
            {credentials: "include"}
        ).then((data) => {
            CheckIfUserSessionIsValid(dispatch).then(() => {
                data.json().then((result) => {
                    
                    setSports(result);
                    setSelectedImage(result[0].image_path);
                    setSportDescription(result[0].sport_description);
                }).catch(() => {
                    console.log("Unable to pass data returned into JSON format");
                })
            })
        }).catch(() => {
            console.log("Exception hit, unable to get Athlete data back from backend");
        })
    }

    useEffect(() => {
        setFilterBySelection(filter)
        renderSportsList();
    }, [selectedFilter, filter]);

    const renderSports = () => {
        var dom = [];

        if (sports.length !== 0) {
            for (var i = 0; i < sports.length; i++) {
                if(sports[i].sport_name.toLowerCase().includes(confirmedSearchTerm.toLowerCase())) {

                    dom.push(
                        <option
                            imagepath={sports[i].image_path}
                            gender={sports[i].sport_gender}
                            description={sports[i].sport_description}
                            value={sports[i].sport_name}>{sports[i].sport_name} {"(" + sports[i].sport_gender + ")"}</option>
                    )
                }

            }
        }

        return dom;
    };

    const selectHandler = (e) => {
        var options = e && e.target.selectedOptions || [];
        var arrayOfMultipleSports = [];
        var selectedSs = [];
        setLoading(true);

        for(var i = 0; i < options.length; i++) {
            arrayOfMultipleSports.push({name: options[i].value, gender: options[i].getAttribute("gender")});
            selectedSs.push(options[i].value);
        }

        sportSelectionHandler(arrayOfMultipleSports);
        setSelectedSport(selectedSs);

        setSelectedImage(e && e.target.options[e.target.selectedIndex].getAttribute("imagepath") || "American football (lineman).jpg");
        setSportDescription(e && e.target.options[e.target.selectedIndex].getAttribute("description") || 'In gridiron (American) football, a lineman is a player who specialises in play at the line of scrimmage. The linemen of the team currently in possession of the ball are the offensive line, while linemen on the opposing team are the defensive line. Linemen are usually the largest players on the field in both height and weight, since their positions usually require less running and more strength than skill positions.');
    }

    const clearHandler = () => {
        selectHandler('');
        setSportSelectionRequestStringHandler();
        $(".sport-selection-container").val('');
    }

    const renderSortByComponent = () => {
        return (
                <div className="sort-by-container">
                    <div className="sort-by-section">
                        <span>Sort by:</span>
                    </div>
                    <div className="sort-by-radiobuttons">
                        {/* <div>
                            <input checked id="sort-by-all" type="radio" name="sort-by" onClick={() => setSelectedFilter('all')} />
                            <label for="sort-by-all" className={"sort-by-radio-visible" + (selectedFilter === "all" ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: selectedFilter === "all" ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : ''}}></label>
                            <label for="sort-by-all">All</label>
                        </div> */}

                        <div style={{position: 'relative'}}>
                            <input checked={selectedFilter == "male"} id="sort-by-male" type="checkbox" name="sort-by" onClick={(e) => {
                                setSelectedFilter(e.target.checked ? "male" : "all");
                            }} />
                            <label for="sort-by-male" className={"sort-by-radio-visible" + (selectedFilter === "male" ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: selectedFilter === "male" ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : ''}}></label>
                            <label for="sort-by-male">Male</label>
                        </div>

                        <div style={{position: 'relative'}}>
                            <input checked={selectedFilter == "female"} id="sort-by-female" type="checkbox" name="sort-by" onClick={(e) => {
                                setSelectedFilter(e.target.checked ? "female" : "all");
                            }} />
                            <label for="sort-by-female" className={"sort-by-radio-visible" + (selectedFilter === "female" ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: selectedFilter === "female" ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : ''}}></label>
                            <label for="sort-by-female">Female</label>
                        </div>

                        <div style={{position: 'relative'}}>
                            <input checked={selectedFilter == "sportmatch-list"} id="sort-by-sportmatch-list" type="checkbox" name="sort-by" onClick={(e) => {
                                setSelectedFilter(e.target.checked ? "sportmatch-list" : "all");
                            }} />
                            <label for="sort-by-sportmatch-list" className={"sort-by-radio-visible" + (selectedFilter === "sportmatch-list" ? " selected" : "")} style={{ borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3', backgroundColor: selectedFilter === "sportmatch-list" ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : ''}}></label>
                            <label for="sort-by-sportmatch-list">Group SportMatch list</label>
                        </div>
                    </div>
                </div>
        )
    }

    return (
        <div className="admin-sport-selector sport-section-container">
            <div className="elite-athlete-general-container elite-athlete-left-container">
                <div className="elite-athlete-image-container">
                    <img src={getImageFromSelectedSportIndex()} height={"auto"} width={"100%"} alt="Sport" />
                    <div className="sport-description-container">
                        <div>
                            <span>
                                {sportDescription}
                            </span>
                        </div>

                    </div>
                </div>

                <div className="search-container">
                    <span>Sports</span>
                    <input type="text"  onChange={e => {
                        //setSearchTerm(e.target.value);
                        setConfirmedSearchTerm(e.target.value)
                    }} placeholder={"Search... "} />
                    <div onClick={() => setConfirmedSearchTerm(searchTerm)}>
                        <SearchIcon />
                    </div>
                </div>

                {allowMultipleSports && <span style={{fontSize: '12px', marginTop: '5px'}}>Hold down <b>CTRL / ⌘</b> to select multiple sports</span>}
                
                <select multiple={allowMultipleSports} onChange={selectHandler} className="sport-selection-container" size={90}>
                    {renderSports()}
                </select>

                {allowMultipleSports && <button onClick={clearHandler} className="sports-section-selection-clear" style={{ background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}>Clear Selection</button>}

                {!hideFilterBy && <FilterByComponent onChange={(value) => setFilter(value)} />}
                
                {!hideSortBy && renderSortByComponent()}
            </div>
        </div>
    )
}

export default SportSection;
