import { useDispatch } from "react-redux";
import { setUserId } from "../store/actions/actions";
import { BACKEND_URL, ENVIRONMENT_PATH } from "../store/constants";
import { LogoutUserEndpoint as LogoutUser } from "./endpoints";
import IM from 'convert-units';

export const CheckIfUserIsLoggedIn = (res, dispatch) => {
    return new Promise((resolve, reject) => {
        if(res.status == 304) {
            LogoutUser().then(res => {
                dispatch(setUserId(""));
                window.localStorage.clear();
            })
        } else {
            var clonedJSON = res.clone();
            clonedJSON.json().then(result => {
                if(result.error == "no-session") {
                    LogoutUser().then(res => {
                        dispatch(setUserId(""));
                        window.localStorage.clear();
                    })
                } else {
                    resolve();
                }
            }).catch(() => {
                resolve();
            })
        }
    })
}

export const CheckIfUserSessionIsValid = (dispatch) => {
    return new Promise((resolve, reject) => {
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/CheckSessionIsValid`, {
            credentials: 'include'
        }).then(res => {
            CheckIfUserIsLoggedIn(res, dispatch).then(() => {
                resolve();
            })
        }).catch(() => { })
    })
}

export const ConvertValueToImperial = (data, fromValue, toValue, fixedAmount, sprintDistanceFlag) => {
    var returnValue = 0;

    if(data && data != "" && !isNaN(data)) {
        if(sprintDistanceFlag) {
            returnValue = parseFloat((parseFloat(IM(data).from(fromValue).to(toValue)) / 3).toFixed(fixedAmount));
        } else {
            returnValue = parseFloat(parseFloat(IM(data).from(fromValue).to(toValue)).toFixed(fixedAmount));
        }
    } 

    return returnValue;
};


// This function shows a message if the users browser width is less than 600px
export const displayMobileDeterMessage = (addToast) => {
    if(window.innerWidth < 600) {
        setTimeout(() => {
            addToast("This application is best viewed on desktop", {
                appearance: 'error',
                autoDismiss: false
            });
        }, 500)
    }
}

export const createQueueRequestArray = (testIds) => {
    const tempArray = [];

    for(const testId of testIds) {
        tempArray.push({
            testId: testId + "" ?? "",
            queueType: 'PDF'
        });
    }

    return tempArray;
}