import RenderStudentResults from './GroupReportResults.js';
import fonts from './GroupReportFonts';
import { useSelector } from 'react-redux';

import IM from 'convert-units';

export const generateGroupReportPDF = async (
    data, 
    headerIcons, 
    anthropometryIcons, 
    ChartJSDOM, 
    PieChartResult, 
    averageSportRanking, 
    percentageOfSportsOver60, 
    sportTypePercentages, 
    primaryColor, 
    secondaryColor,
    accentColor,
    customCallback,
    usingImperial,
    organisationLogo
    ) => {
    const TopSports = data.top_sports;

    const SCALE_FOR_PERCENTGES = 1.55; // The amount to scale the percentages down (less makes the bars/position larger)

    const images = require.context('../../../../images', true);

    const getFitnessAndActualAge = (actualAge, fitnessAge) => {
        var ageForStudent = (20 - actualAge);
        var fitnessAgeForStudent = (fitnessAge - 10);

        if (ageForStudent < 1) {
            ageForStudent = 1;
        }

        if(ageForStudent > 10) {
            ageForStudent = 10;
        }

        if (fitnessAgeForStudent < 1) {
            fitnessAgeForStudent = 1;
        }

        if (fitnessAgeForStudent > 20) {
            fitnessAgeForStudent = 20;
        }

        var calcaultedAgePosition = (187 - (2.7 * ageForStudent));
        var calcaultedFitnessAgePosition = (180 - (2.6 * fitnessAgeForStudent));

        return {
            age_pos: calcaultedAgePosition,
            fitness_age_pos: calcaultedFitnessAgePosition
        }
    }

    // Gets an instantiated image by using a passed in name
    const getImageFromSelectedSportName = name => {
        var indexOfFullStop = name.indexOf(".");
        var newName = name.slice(0, indexOfFullStop) + "circle.png";
        try {
            var sudoImage = new Image();
            var image = images("./" + newName);
            sudoImage.src = image;

            return sudoImage;
        } catch (err) {
            console.log("Error = ", err);
        }
    }

    // Formats the sport name provided to have a capital letter at the beginning
    const formatSportName = (name) => {
        return name.charAt(0).toUpperCase() + name.substring(1);
    }

    var jsPDF = require('jspdf');

    var doc = new jsPDF();

    // add custom font to file
    fonts(doc);

    doc.addFont("Dubai-Bold.ttf", "Dubai-Bold", "Bold");

    // Header
    doc.setFillColor(primaryColor); // header color

    //TODO: set to 'Alegreya Sans' (need to download a custom font-family)
    doc.setFont('AlegreyaSans-Black', 'bold');
    doc.setTextColor('white');

    doc.setFontSize(12);
    //doc.setFont("ConsolasHex", "Bold");
    //doc.text("apples", 20, 20);

    doc.rect(0, 0, 210, 20, 'F');

    doc.setFillColor(secondaryColor); // secondary header color

    doc.rect(0, 11, 210, 19, 'F');

    var usingOrgColors = (primaryColor == "rgb(90, 83, 128)") && (secondaryColor == "rgb(119, 133, 153)") && (accentColor == "rgb(80, 179, 131)");

    // Foreground
    if(usingOrgColors) {
        doc.setFillColor(189, 224, 239);
        doc.rect(0, 20, 210, 277, 'F');
    }

    // Content
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 13, 200, 280, 'F');

    // StudentID (text)
    doc.setFontSize(13);
    doc.setFontSize(11);

    // Student ID text
    doc.setTextColor('black');
    doc.setFont('Dubai-Bold', 'Bold');
    doc.text("Organisation Name:", 20, 22);

    doc.setFont('Dubai-Regular', 'Bold');
    doc.setFontSize(9);
    doc.text(data.org_name + "", 54, 22);

    // Test Date Text
    doc.setFont('Dubai-Bold', 'Bold');
    doc.setFontSize(11);
    //doc.text("Test date:", 85, 22);

    doc.setFont('Dubai-Regular', 'Bold');
    doc.setFontSize(8);
    doc.text('', 102, 22);

    doc.setFont('Dubai-Bold', 'Bold');
    doc.setFontSize(11);
    doc.text("Class:", 170, 22);

    doc.setFont('Dubai-Regular', 'Bold');
    doc.setFontSize(9);
    doc.text('', 180, 22);

    doc.setFontSize(13);
    doc.setFont('AlegreyaSans-Black', 'Bold');
    doc.setTextColor('white');
    doc.text('SportMatch', 20, 5);
    doc.text('Group report', 190, 5, 'right');

    doc.setFontSize(8.5);
    doc.text(`${data.org_name + " - " + data.group_name}`, 190, 9, 'right');

    // First Graph Container
    doc.setFillColor(246, 250, 252);
    doc.rect(10, 27, 190, 110, 'F');

    // Text per button section
    doc.setTextColor('black');
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    doc.setFont('Dubai-Regular', 'Bold');

    var standingBroadJumps = doc.splitTextToSize('Standing broad jump', 14);
    var bentKneeSitUpsText = doc.splitTextToSize('Bent-knee sit-ups', 14);
    var gripStrengthSplit = doc.splitTextToSize('Grip strength', 12);
    var verticalJumpSplit = doc.splitTextToSize('Vertical jump', 12);
    var inclinePullUpsSplit = doc.splitTextToSize('Inclined pull-ups', 12);
    var shuttleSportSplit = doc.splitTextToSize(usingImperial ? '4 x 11 yd shuttle' : '4 x 10 m shuttle', 12);
    var sprintTimeSplit = doc.splitTextToSize('Sprint speed', 8);
    var kmRunTextSplit = doc.splitTextToSize(usingImperial ? '1 mile run/walk' : '1.6 km run/walk', 12);
    var suttleBeepTestSplit = doc.splitTextToSize('Aerobic fitness', 14);

    var arrayOfBoxTexts = ["Sit & reach", standingBroadJumps, bentKneeSitUpsText, gripStrengthSplit, verticalJumpSplit, inclinePullUpsSplit, shuttleSportSplit, sprintTimeSplit, kmRunTextSplit, suttleBeepTestSplit];

    var xForBoxText = 23.5;

    if (headerIcons != null) {
        for (var i = 0; i < 10; i++) {
            var canvas = headerIcons[i].current.toDataURL("image/png", 1.0);
            doc.addImage(canvas, 'PNG', xForBoxText - 5, 30, 10, 12, undefined, 'FAST');
            xForBoxText += 16.5;
        }
    }

    var xForBox = 16;

    // Boxs at the top of the reports
    doc.setFillColor(255, 255, 255);

    for (var i = 0; i < 10; i++) {
        doc.rect(xForBox, 40, 15, 8, 'F'); // 1
        xForBox += 16.5;
    }

    xForBoxText = 23.5;

    for (var i = 0; i < 10; i++) {
        if (arrayOfBoxTexts[i] == "Sit & reach") {
            doc.text(arrayOfBoxTexts[i], xForBoxText, 45, 'center');
        } else {
            doc.text(arrayOfBoxTexts[i], xForBoxText, 44, 'center');
        }

        xForBoxText += 16.5;
    }

    doc.setFontStyle('normal');

    // Main Graph Rectangles
    doc.setFillColor(196, 223, 237);
    doc.rect(15, 49, 180, 10, 'F');

    // Good
    doc.setFillColor(209, 230, 241);
    doc.rect(15, 59, 180, 13, 'F');

    // Average
    doc.setFillColor(223, 238, 245);
    doc.rect(15, 72, 180, 28, 'F');

    // Need Imporvement
    doc.setFillColor(233, 244, 248);
    doc.rect(15, 100, 180, 28, 'F');

    // Bar color
    doc.setFillColor(primaryColor);
    
    var sitAndReachPercentage = data.averaged_norms.sit_reach;
    var standingBroadJumpPercentage = data.averaged_norms.standing_broad_jump;
    var gripStrengthPercentage = data.averaged_norms.grip_strength;
    var verticalJumpPercentage = data.averaged_norms.vertical_jump;
    var sitUpsPercentage = data.averaged_norms.situps;
    var inclinedPullUpsPercentage = data.averaged_norms.inclined_pullup;
    var shuttleRunPercentage = data.averaged_norms.shuttle_run;
    var sprintPercentage = data.averaged_norms.sprint_speed;
    var walkRunPercentage = data.averaged_norms.walk_run;
    var aerobicFitnessPercentage = data.averaged_norms.shuttle_test_20m;

    var numberOfFieldsAvailable = 0;

    if (sitAndReachPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (standingBroadJumpPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (gripStrengthPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (verticalJumpPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (sitUpsPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (inclinedPullUpsPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (shuttleRunPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (sprintPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (walkRunPercentage != 0) {
        numberOfFieldsAvailable++;
    }
    if (aerobicFitnessPercentage != 0) {
        numberOfFieldsAvailable++;
    }

    var averageRanking = 0;

    if (numberOfFieldsAvailable != 0) {
        averageRanking = (
            (
                (sitAndReachPercentage != 0 ? sitAndReachPercentage : 0) +
                (standingBroadJumpPercentage != 0 ? standingBroadJumpPercentage : 0) +
                (gripStrengthPercentage != 0 ? gripStrengthPercentage : 0) +
                (verticalJumpPercentage != 0 ? verticalJumpPercentage : 0) +
                (sitUpsPercentage != 0 ? sitUpsPercentage : 0) +
                (inclinedPullUpsPercentage != 0 ? inclinedPullUpsPercentage : 0) +
                (shuttleRunPercentage != 0 ? shuttleRunPercentage : 0) +
                (sprintPercentage != 0 ? sprintPercentage : 0) +
                (walkRunPercentage != 0 ? walkRunPercentage : 0) +
                (aerobicFitnessPercentage != 0 ? aerobicFitnessPercentage : 0)
            ) / numberOfFieldsAvailable
        )
    }

    var arrayOfBarPercentages = [
        sitAndReachPercentage,
        standingBroadJumpPercentage,
        sitUpsPercentage,
        gripStrengthPercentage,
        verticalJumpPercentage,
        inclinedPullUpsPercentage,
        shuttleRunPercentage,
        sprintPercentage,
        walkRunPercentage,
        aerobicFitnessPercentage
    ]

    var xAxisForBar = 20;

    for (var i = 0; i < arrayOfBarPercentages.length; i++) {
        if (arrayOfBarPercentages[i] && arrayOfBarPercentages[i] !== 0) {
            doc.rect(xAxisForBar, 120 - (parseFloat(arrayOfBarPercentages[i]) / SCALE_FOR_PERCENTGES), 8, (parseFloat(arrayOfBarPercentages[i]) / SCALE_FOR_PERCENTGES), 'F'); // 1 //done // Student result
            doc.setFillColor(primaryColor);
        }
        xAxisForBar += 16.5;
    }

    // Bottom of main graph
    doc.setFont('Dubai-Regular', 'Bold');
    doc.setFontSize(9);
    doc.text('Average ranking: ', 18, 133);

    doc.setTextColor(accentColor);
    doc.setFontSize(12);
    doc.setFont('AlegreyaSans-Black', 'Bold');
    doc.text(parseFloat(averageRanking).toFixed(1) + "%", 41, 133);

    doc.setTextColor('black');

    // Key: My results
    doc.setFillColor(primaryColor);
    doc.rect(55, 129, 6.5, 6.5, 'F');
    doc.setFont('Dubai-Regular', 'Bold');
    doc.setFontSize(9);
    doc.text('My results', 63, 133);

    doc.setFontSize(7);

    // Line width of square
    // Key: Predicited adult values
    doc.setLineWidth(1);
    doc.setDrawColor(accentColor);
    doc.setFillColor(255, 255, 255);
    doc.rect(80, 129.5, 5.5, 5.5, 'FD');

    doc.setFillColor(primaryColor);
    doc.setFillColor(255, 255, 255);

    // Outliers for graph (count)
    doc.setLineWidth(1.3);
    doc.setDrawColor(accentColor);
    doc.setFont('Dubai-Regular', 'Bold');

    // Your score (thing which appears just above your score bar)
    doc.setFillColor(255, 255, 255);
    doc.setFontSize(7);

    // Calculation for score placement:
    //
    // Rectangle Specs (for 0.00):
    // x = your record x - 2
    // y = your record y - 5
    // w = 7mm
    // h = 3.5mm
    //
    // Text Specs (for 0.00):
    // x = rect x + 1.05mm
    // y = rect y + 2.75mm

    /**
    * Method for working out score placement
    */
    // Your Score 1 (percentiles)

    var sitAndReachStudentScore = (usingImperial ? IM(data.averages.sit_reach).from('cm').to('in') : data.averages.sit_reach);
    var standingBroadJumpStudentScore = (usingImperial ? IM(data.averages.standing_broad_jump).from('cm').to('in') : data.averages.standing_broad_jump);
    var gripStrengthStudentScore = (usingImperial ? IM(data.averages.grip_strength).from('kg').to('lb') : data.averages.grip_strength);
    var verticalJumpStudentScore = (usingImperial ? IM(data.averages.vertical_jump).from('cm').to('in') : data.averages.vertical_jump);
    var sitUpsStudentScore = data.averages.situps;
    var inclinedPullUpsStudentScore = data.averages.inclined_pullup;
    var shuttleRunStudentScore = data.averages.shuttle_run;
    var sprintSpeedStudentScore = (usingImperial ? IM(parseFloat(data.averages.sprint_speed).toFixed(1)).from('km/h').to('m/h') : data.averages.sprint_speed);
    var walkRunStudentScore = data.averages.walk_run;
    var aerobicFitnessStudentScore = data.averages.shuttle_test_20m;

    // Count the number of tests performed for each individual test component
    var sitAndReachCount = data.counts.sit_reach;
    var standingBroadJumpCount = data.counts.standing_broad_jump;
    var gripStrengthCount = data.counts.grip_strength;
    var verticalJumpCount = data.counts.vertical_jump;
    var sitUpsCount = data.counts.situps;
    var inclinedPullUpsCount = data.counts.inclined_pullup;
    var shuttleRunCount = data.counts.shuttle_run;
    var sprintCount = data.counts.sprint_speed;
    var walkRunCount = data.counts.walk_run;
    var aerobicFitnessCount = data.counts.shuttle_test_20m;

    RenderStudentResults({
        doc,
        // Sit and Reach
        sitAndReachStudentScore,
        sitAndReachPercentage,
        sitAndReachCount,
        // Standing broad jump
        standingBroadJumpStudentScore,
        standingBroadJumpPercentage,
        standingBroadJumpCount,
        // Sit ups
        sitUpsStudentScore,
        sitUpsPercentage,
        sitUpsCount,
        // Grip strength
        gripStrengthStudentScore,
        gripStrengthPercentage,
        gripStrengthCount,
        // Vertical jump
        verticalJumpStudentScore,
        verticalJumpPercentage,
        verticalJumpCount,
        // Inclined pull ups
        inclinedPullUpsStudentScore,
        inclinedPullUpsPercentage,
        inclinedPullUpsCount,
        // Shuttle run
        shuttleRunStudentScore,
        shuttleRunPercentage,
        shuttleRunCount,
        // Sprint speed
        sprintSpeedStudentScore,
        sprintPercentage,
        sprintCount,
        // Walk run
        walkRunStudentScore,
        walkRunPercentage,
        walkRunCount,
        // Aerobic fitness (combination of Yo-Yo and Shuttle (beep) test)
        aerobicFitnessStudentScore,
        aerobicFitnessPercentage,
        aerobicFitnessCount,
        // Colors
        primaryColor,
        secondaryColor,
        accentColor,
        // Scale
        SCALE_FOR_PERCENTGES,
        usingImperial
    });

    // Side of Compare results graph (Best, Good, Average, etc.)
    doc.setFontSize(7);

    // My Fitness Age Container
    var calculatedAgeAverage = 0;
    var calculatedFitnessAgeAverage = 0;

    for(var i = 0; i < data.student_ages.length; i++) {
        calculatedAgeAverage += data.student_ages[i].age;
    }

    calculatedAgeAverage = calculatedAgeAverage / data.student_ages.length;

    doc.setFillColor(246, 250, 252);
    doc.rect(140, 147, 59, 61, 'F');

    doc.setFillColor(255, 255, 255);
    doc.rect(145, 149, 49, 49, 'F');

    doc.setFontSize(12);

    doc.setFont("Dubai-Regular", "Bold");
    doc.setFontSize(9);
    doc.text("Actual age:", 145, 204);

    doc.setFont("Dubai-Bold", "Bold");
    
    // Age and Fitness age variables
    var averageAgeFloat = parseFloat(data.average_age).toFixed(2);
    var averageFitnessAgeFloat = parseFloat(data.average_fitness_age).toFixed(2);
    var differenceBetweenBothAges = parseFloat(averageFitnessAgeFloat - averageAgeFloat).toFixed(2);

    doc.text(averageAgeFloat + "", 160, 204);

    doc.setFont("Dubai-Regular", "Bold");
    doc.text("Fitness age:", 171, 204);

    doc.setFont("Dubai-Bold", "Bold");

    doc.text(averageFitnessAgeFloat + "", 187, 204);

    if (differenceBetweenBothAges > 0) {
        doc.text("Difference is +" + differenceBetweenBothAges + " yr", 158, 208);
    } else {
        doc.text("Difference is -" + differenceBetweenBothAges + " yr", 158, 208);
    }

    if(TopSports.length > 0) {
        doc.setFillColor(246, 250, 252);
        doc.rect(10, 167, 127, 41, 'F');
    
        doc.setFillColor(255, 255, 255);
        doc.rect(12, 170, 23, 33, 'F');
    
        doc.setFillColor(1, 1, 1);
    
        doc.addImage(getImageFromSelectedSportName(TopSports[0].sport.image_path), 'JPEG', 14, 172, 19, 19, undefined, "FAST"); // Up to here
    
        var sportName = doc.splitTextToSize("1. " + formatSportName(TopSports[0].sport.sport_name), 21);
    
        doc.setFont("Dubai-Bold", "Bold");
        doc.text(sportName, 23.5, 194, 'center');
    }

    if(TopSports.length > 1) {
        // No. 2 Sport Container
        doc.setFillColor(255, 255, 255);
        doc.rect(37, 170, 23, 33, 'F');

        doc.setFillColor(1, 1, 1);

        doc.addImage(getImageFromSelectedSportName(TopSports[1].sport.image_path), 'JPEG', 39, 172, 19, 19, undefined, "FAST");

        doc.setFontStyle('bold');
        doc.setFontSize(8);
        sportName = doc.splitTextToSize("2. " + formatSportName(TopSports[1].sport.sport_name), 21);

        doc.setFont("Dubai-Bold", "Bold");
        doc.text(sportName, 48.5, 194, 'center');
    }

    if(TopSports.length > 2) {
        // No. 3 Sport Container
        doc.setFillColor(255, 255, 255);
        doc.rect(62, 170, 23, 33, 'F');

        doc.setFillColor(1, 1, 1);// +12mm

        doc.addImage(getImageFromSelectedSportName(TopSports[2].sport.image_path), 'JPEG', 64, 172, 19, 19, undefined, "FAST");

        sportName = doc.splitTextToSize("3. " + formatSportName(TopSports[2].sport.sport_name), 21);

        doc.setFont("Dubai-Bold", "Bold");

        doc.text(sportName, 73.5, 194, 'center');
    }

    if(TopSports.length > 3) {
        // No. 4 Sport Container
        doc.setFillColor(255, 255, 255);
        doc.rect(87, 170, 23, 33, 'F');

        doc.setFillColor(1, 1, 1);

        doc.addImage(getImageFromSelectedSportName(TopSports[3].sport.image_path), 'JPEG', 89, 172, 19, 19, undefined, "FAST");

        sportName = doc.splitTextToSize("4. " + formatSportName(TopSports[3].sport.sport_name), 21);
        doc.setFont("Dubai-Bold", "Bold");

        doc.text(sportName, 98.5, 194, 'center');
    }

    // Average sport ranking
    doc.text("Average SportMatch score = " + parseFloat(averageSportRanking).toFixed(1), 12, 206);

    doc.text("% of SportMatch scores >60 = " + parseFloat(percentageOfSportsOver60).toFixed(1), 60, 206);

    if(TopSports.length > 4) {
        // No. 5 Sport Container
        doc.setFillColor(255, 255, 255);
        doc.rect(112, 170, 23, 33, 'F');

        doc.setFillColor(1, 1, 1);

        doc.addImage(getImageFromSelectedSportName(TopSports[4].sport.image_path), 'JPEG', 114, 172, 19, 19, undefined, "FAST");

        sportName = doc.splitTextToSize("5. " + formatSportName(TopSports[4].sport.sport_name), 21);
        doc.setFont("Dubai-Bold", "Bold");

        doc.text(sportName, 123.5, 194, 'center');
    }

    doc.setFontSize(12);
    doc.setFontStyle('normal');
    doc.setFont('Dubai-Bold', 'Bold');
    doc.setTextColor(accentColor);

    doc.setFontSize(20);
    doc.setFont('AlegreyaSans-Black', 'Bold');

    sportName = formatFirstSportName(sportName, TopSports, doc);

    doc.setFontStyle('normal');

    doc.setTextColor(0, 0, 0);

    // My Sport Matches Container
    doc.setFillColor(246, 250, 252);
    doc.rect(10, 224, 190, 70, 'F');

    doc.setFont('Dubai-Regular', 'Bold');
    doc.setFontSize(9);
    doc.text('Number of tests', 89, 133);

    doc.text(`${data.selected_norms} norms comparison`, 147, 133);

    doc.setFontSize(16);

    //Comparing your fitness test results heading
    doc.setFillColor(primaryColor);
    doc.roundedRect(10, 23, 190, 7, 2, 2, 'F');
    doc.setFontSize(10);
    doc.setTextColor('white');
    doc.setFontStyle('normal');
    doc.setFont("Dubai-Bold", "Bold");
    doc.text('Comparing my fitness test results', 13, 27.5);
    //doc.rect(10, 23, 190, 7, 'F');

    // Top 5 sports based on fitness test results heading

    doc.setFillColor(primaryColor);
    doc.roundedRect(10, 139, 190, 7, 2, 2, 'F');
    doc.setFontSize(10);
    doc.setTextColor('white');
    //doc.setFontStyle('bold');
    doc.setFont("Dubai-Bold", "Bold");
    doc.text('Top 5 sports based on fitness test results', 13, 143.5);
    doc.text('Group fitness age', 140, 143.5);

    // My Top Sport types heading

    doc.setFillColor(primaryColor);
    doc.roundedRect(10, 215, 190, 7, 2, 2, 'F');
    doc.setFontSize(10);
    doc.setTextColor('white');
    //doc.setFontStyle('bold');
    doc.setFont("Dubai-Bold", "Bold");
    doc.text('Group top sport types (%\'ile)', 13, 219.5);
    doc.text('Group body size and proportion summary (%\'ile)', 120, 219.5);

    // My Fitness Age Content
    doc.setFillColor(0, 0, 0);
    doc.rect(155, 150, 35, 35, 'F'); // Box border
    doc.setFillColor(255, 255, 255);
    doc.rect(155.5, 150.5, 34, 34, 'F');
    doc.setTextColor('black');
    doc.setFontStyle('normal');
    doc.setFont("Dubai-Regular", "Bold");
    doc.text(149, 181, 'Fitness age (years)', null, 90); //Fitness Age (years) text
    doc.text(159, 191, 'Actual age (years)'); //Actual age (years)

    // Line
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(155, 185, 190, 150);

    // Good in Fitness Age
    doc.setFontSize(7);
    doc.text(157, 154, 'Good');

    // Needs improvment in Fitness Age
    var needsImprovementFitnessAgeSplit = doc.splitTextToSize('Needs improvement', 16);
    doc.text(needsImprovementFitnessAgeSplit, 188, 178, 'right');

    var ageForCalculation = (20 - data.average_age);
    var fitnessAgeForCalculation = (data.average_fitness_age - 10);

    if (ageForCalculation < 1) {
        ageForCalculation = 1;
    }

    if (ageForCalculation > 20) {
        ageForCalculation = 20;
    }

    if(ageForCalculation > 10) {
        ageForCalculation = 10;
    }

    var calcaultedAgePosition = (187 - (2.7 * ageForCalculation));
    var calcaultedFitnessAgePosition = (180 - (2.6 * fitnessAgeForCalculation));

    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);

    // Printing the rest of the fitness age / actual age circles
    doc.setFillColor(primaryColor);
    for(var i = 0; i < data.student_ages.length; i++) {
        var posObj = getFitnessAndActualAge(data.student_ages[i].age, data.student_ages[i].fitness_age);
        doc.circle(posObj.age_pos, posObj.fitness_age_pos, 1.3, 'F'); // Current users age and fitness age
    }

    // Report age and fitness age (current)
    doc.setDrawColor(accentColor);
    doc.setFillColor(accentColor);
    doc.circle(calcaultedAgePosition, calcaultedFitnessAgePosition, 2, 'FD'); // Current users age and fitness age

    // Axis Lines
    doc.setLineWidth(.3);
    doc.setDrawColor(0, 0, 0);
    doc.line(173, 185, 173, 183);
    doc.line(155, 168, 157, 168);

    // Line Indicators (vertical)
    doc.text(151, 185, '10');
    doc.text(151, 169, '15');
    doc.text(151, 152, '20');

    // Line Indicators (horizontal)
    doc.text(155, 187.5, '10');
    doc.text(172, 187.5, '15');
    doc.text(187, 187.5, '20');

    doc.setFillColor(255, 255, 255);

    // Rendering the anthropometry icons
    // Height SVG
    canvas = anthropometryIcons[0].current.toDataURL("image/png", 1.0);
    doc.addImage(canvas, 'PNG', 146, 227, 12, 14, undefined, 'FAST');

    doc.setDrawColor(209, 209, 209);
    doc.setLineWidth(0.1);
    doc.setFillColor(255, 255, 255);

    var xForPercentageValue = 161.3;

    // x, y, w, h, FD
    doc.rect(156, 230, 15, 7, 'FD');
    // text = x+3, y+3
    doc.text('Height', 160, 233);
    doc.text(parseFloat(data.averaged_norms.height).toFixed(1) + "%", xForPercentageValue, 235.5);
    doc.setFillColor(255, 255, 255);

    // Weight SVG
    canvas = anthropometryIcons[1].current.toDataURL("image/png", 1.0);
    doc.addImage(canvas, 'PNG', 173, 246, 12, 14, undefined, 'FAST');

    xForPercentageValue = 190.3;

    doc.rect(184, 248, 15, 7, 'FD');
    doc.text('Weight', 188, 251);
    doc.text(parseFloat(data.averaged_norms.weight).toFixed(1) + "%", xForPercentageValue, 253.5);
    doc.setFillColor(255, 255, 255);

    // Arm span SVG
    canvas = anthropometryIcons[2].current.toDataURL("image/png", 1.0);
    doc.addImage(canvas, 'PNG', 163, 280, 12, 14, undefined, 'FAST');

    xForPercentageValue = 181.3;

    doc.rect(175, 282, 15, 7, 'FD');
    doc.text('Arm span', 178, 285);
    doc.text(parseFloat(data.averaged_norms.arm_span).toFixed(1) + "%", xForPercentageValue, 287.5);
    doc.setFillColor(255, 255, 255);

    // Sitting height SVG
    canvas = anthropometryIcons[3].current.toDataURL("image/png", 1.0);
    doc.addImage(canvas, 'PNG', 128, 280, 12, 14, undefined, 'FAST');

    xForPercentageValue = 119.3;

    doc.rect(114, 282, 15, 7, 'FD');
    doc.text('Brachial index', 114.5, 285);
    doc.text(parseFloat(data.averaged_norms.forearm_length).toFixed(1) + "%", xForPercentageValue, 287.5);
    doc.setFillColor(255, 255, 255);

    // Brachial Index SVG
    canvas = anthropometryIcons[4].current.toDataURL("image/png", 1.0);
    doc.addImage(canvas, 'PNG', 120, 246, 12, 14, undefined, 'FAST');

    xForPercentageValue = 111.3;

    doc.rect(105, 248, 15, 7, 'FD');
    doc.text('Sitting height', 105.5, 251);
    doc.text(parseFloat(data.averaged_norms.sitting_height).toFixed(1) + "%", xForPercentageValue, 253.5);
    doc.setFillColor(255, 255, 255);

    // Rectangles for "My top sport types" section
    doc.setFontSize(9);

    // Drawing the text
    doc.setTextColor(primaryColor);
    doc.setFontSize(7);
    doc.setTextColor("black");
    doc.setFont("Dubai-Regular", "Bold");

    doc.text('Best', 181, 55);
    doc.text('Good', 181, 66);
    doc.text('Average', 181, 85);
    doc.text('Needs', 181, 112);
    doc.text("imporvement", 181, 114);

    if (ChartJSDOM) {
        var canvas2 = ChartJSDOM.canvas;
        var newCanvas2Img = canvas2.toDataURL("image/png", 1.0);
        doc.addImage(newCanvas2Img, 'PNG', 131, 240, 43, 43, undefined, "FAST");
    }

    var yAxis = 180;
    var xAxis = 3;

    doc.line(20 - xAxis, yAxis + 50, 20 - xAxis, yAxis + 100); // y line
    doc.line(20 - xAxis, yAxis + 100, 108.3 - xAxis, yAxis + 100); // x line

    doc.line(20 - xAxis, yAxis + 90, 108.3 - xAxis, yAxis + 90); // 10
    doc.line(20 - xAxis, yAxis + 80, 108.3 - xAxis, yAxis + 80); // 20
    doc.line(20 - xAxis, yAxis + 70, 108.3 - xAxis, yAxis + 70); // 30
    doc.line(20 - xAxis, yAxis + 60, 108.3 - xAxis, yAxis + 60); // 40
    doc.line(20 - xAxis, yAxis + 50, 108.3 - xAxis, yAxis + 50); // 50

    // Y labels
    doc.setFontSize(8);
    doc.text('10', 14 - xAxis, yAxis + 91.5);
    doc.text('20', 14 - xAxis, yAxis + 81.5);
    doc.text('30', 14 - xAxis, yAxis + 71.5);
    doc.text('40', 14 - xAxis, yAxis + 61.5);
    doc.text('50', 14 - xAxis, yAxis + 51.5);

    doc.text('Power', 22 - xAxis, yAxis + 106.5);
    doc.text('Strength', 36.5 - xAxis, yAxis + 106.5);
    doc.text('Speed', 52.5 - xAxis, yAxis + 106.5);

    var aerobicFitnessWrappedText = doc.splitTextToSize('Aerobic fitness', 10);
    doc.text(aerobicFitnessWrappedText, 67 - xAxis, yAxis + 104.5);

    var gameFitnessWrappedText = doc.splitTextToSize('Game fitness', 10);
    doc.text(gameFitnessWrappedText, 82 - xAxis, yAxis + 104.5);

    doc.text('Skill', 99 - xAxis, yAxis + 106.5);

    var powerSportTypePercentage = 0;
    var strengthSportTypePercentage = 0;
    var speedSportTypePercentage = 0;
    var aerobicFitnessSportTypePercentage = 0;
    var gameFitnessSportTypePercentage = 0;
    var skillSportTypePercentage = 0;

    // Percentages of top sport types to be displayed on the group report PDF
    powerSportTypePercentage = 50 - parseFloat(findSportTypePercentageInArray(sportTypePercentages, 'powerScore').value).toFixed(0);
    skillSportTypePercentage = 50 - parseFloat(findSportTypePercentageInArray(sportTypePercentages, 'skillScore').value).toFixed(0);
    strengthSportTypePercentage = 50 - parseFloat(findSportTypePercentageInArray(sportTypePercentages, 'strengthScore').value).toFixed(0);
    aerobicFitnessSportTypePercentage = 50 - parseFloat(findSportTypePercentageInArray(sportTypePercentages, 'aerobicFitnessScore').value).toFixed(0);
    gameFitnessSportTypePercentage = 50 - parseFloat(findSportTypePercentageInArray(sportTypePercentages, 'gameFitnessScore').value).toFixed(0);
    speedSportTypePercentage = 50 - parseFloat(findSportTypePercentageInArray(sportTypePercentages, 'speedScore').value).toFixed(0);

    // Bars
    doc.setFillColor(primaryColor);
    doc.rect(22.5 - xAxis, yAxis + 100 - powerSportTypePercentage, 8, 0 + powerSportTypePercentage, 'F');
    doc.rect(37.5 - xAxis, yAxis + 100 - strengthSportTypePercentage, 8, 0 + strengthSportTypePercentage, 'F');
    doc.rect(52.5 - xAxis, yAxis + 100 - speedSportTypePercentage, 8, 0 + speedSportTypePercentage, 'F');
    doc.rect(67.5 - xAxis, yAxis + 100 - aerobicFitnessSportTypePercentage, 8, 0 + aerobicFitnessSportTypePercentage, 'F');
    doc.rect(82.5 - xAxis, yAxis + 100 - gameFitnessSportTypePercentage, 8, 0 + gameFitnessSportTypePercentage, 'F');
    doc.rect(97.5 - xAxis, yAxis + 100 - skillSportTypePercentage, 8, 0 + skillSportTypePercentage, 'F');

    // Score indicators
    doc.setFillColor(255, 255, 255);
    doc.rect(22.5 - xAxis, yAxis + 93 - powerSportTypePercentage, 8, 5, 'FD');
    doc.text(powerSportTypePercentage + "%", 23.5 - xAxis, yAxis + 96.5 - powerSportTypePercentage);

    doc.setFillColor(255, 255, 255);
    doc.rect(37.5 - xAxis, yAxis + 93 - strengthSportTypePercentage, 8, 5, 'FD');
    doc.text(strengthSportTypePercentage + "%", 38.5 - xAxis, yAxis + 96.5 - strengthSportTypePercentage);

    doc.setFillColor(255, 255, 255);
    doc.rect(52.5 - xAxis, yAxis + 93 - speedSportTypePercentage, 8, 5, 'FD');
    doc.text(speedSportTypePercentage + "%", 53.5 - xAxis, yAxis + 96.5 - speedSportTypePercentage);

    doc.setFillColor(255, 255, 255);
    doc.rect(67.5 - xAxis, yAxis + 93 - aerobicFitnessSportTypePercentage, 8, 5, 'FD');
    doc.text(aerobicFitnessSportTypePercentage + "%", 68.5 - xAxis, yAxis + 96.5 - aerobicFitnessSportTypePercentage);

    doc.setFillColor(255, 255, 255);
    doc.rect(82.5 - xAxis, yAxis + 93 - gameFitnessSportTypePercentage, 8, 5, 'FD');
    doc.text(gameFitnessSportTypePercentage + "%", 83.5 - xAxis, yAxis + 96.5 - gameFitnessSportTypePercentage);

    doc.setFillColor(255, 255, 255);
    doc.rect(97.5 - xAxis, yAxis + 93 - skillSportTypePercentage, 8, 5, 'FD');
    doc.text(skillSportTypePercentage + "%", 98.5 - xAxis, yAxis + 96.5 - skillSportTypePercentage);

    // Render next page with filters listed
    if (false) {
        doc.addPage();
        doc.setFontSize(13);

        doc.setFillColor(primaryColor); // header color
        doc.rect(0, 0, 210, 20, 'F');

        doc.setFillColor(secondaryColor); // secondary header color
        doc.rect(0, 11, 210, 19, 'F');

        doc.setFont('AlegreyaSans-Black', 'Bold');
        doc.setTextColor('white');
        doc.text('SportMatch', 20, 5);
        doc.text('Group report', 190, 5, 'right');

        doc.setFontSize(8.5);
        doc.text(`${data.group_name}`, 190, 9, 'right');

        // Foreground
        doc.setFillColor(189, 224, 239);
        doc.rect(0, 20, 210, 277, 'F');

        // Content
        doc.setFillColor(255, 255, 255);
        doc.rect(5, 13, 200, 280, 'F');

        doc.setTextColor("black");
        doc.setFontSize(16);
        doc.text("Filters selected:", 7, 18);
        doc.setFont("Dubai-Regular", "Bold");
        doc.text("- None", 7, 23);
    }

    var orgLogoToUser = "";

    if(organisationLogo && organisationLogo.includes("base64;,")) {
        orgLogoToUser = organisationLogo.replaceAll("base64;,", "base64,");
    } else {
        orgLogoToUser = organisationLogo;
    }

    if(orgLogoToUser && orgLogoToUser.length > 0) {
        doc.addImage(orgLogoToUser, 'PNG', 5, 2, 13, 13);
    }

    doc.save('group_report' + Date.now() + '.pdf');

    if(typeof customCallback != 'undefined' && customCallback != null)
        customCallback();

    return true;
};

// Formats the sport name to allow for text-wrapping
// Is only for the first sport name (not second, third, etc.)
function formatFirstSportName(sportName, TopSports, doc) {
    if(TopSports.length > 0) {
        sportName = TopSports[0].sport.sport_name + "";

        var firstSportName = [];
    
        if (sportName.length > 23) {
            firstSportName.push(sportName.substring(0, 22) + "-");
    
            firstSportName.push(sportName.substring(22, sportName.length));
    
            doc.text("1. " + firstSportName[0], 47.5, 158);
            doc.text(firstSportName[1], 52.5, 164);
        }
        else {
            doc.text("1. " + sportName, 47.5, 158);
        }
    }

    return sportName;
}

function findSportTypePercentageInArray(array, type) {
    for(var i = 0; i < array.length; i++) {
        if(array[i].name == type) {
            return array[i];
        }
    }
}

