import {
    SET_SELECTED_USER_IDS,
    SET_ORGANISATION_NAME,
    SET_ORGANISATION_TYPE,
    SET_ORGANISATION_ADDRESS_LINE_1,
    SET_ORGANISATION_STATE,
    SET_ORGANISATION_POSTCODE,
    SET_ORGANISATION_COUNTRY,
    SET_PRIMARY_COLOR,
    SET_SECONDARY_COLOR,
    SET_ACCENT_COLOR,
    SET_ORG_LOGO,
    SET_FILTER_GENDER,
    SET_FILTER_AGES,
    SET_FILTER_GROUP_NAMES,
    SET_DATE_RANGE_FROM_FILTER,
    SET_DATE_RANGE_TO_FILTER,
    SET_ACCESS_USER,
    SET_PLAN,
    SET_PENDING_REFRESH_COMPLETION
} from '../OrganisationConstants.js';

const initialState = {
    selectedUserIds: [],
    organisationName: "",
    organisationType: "",
    organisationAddressLine1: "",
    organisationState: "",
    organisationPostcode: "",
    organisationCountry: "",
    organisationPrimaryColor: localStorage.getItem("organisationPrimaryColor") || '',
    organisationSecondaryColor: localStorage.getItem("organisationSecondaryColor") || '',
    organisationAccentColor: localStorage.getItem("organisationAccentColor") || '',
    organisationLogo: "",
    // Filters
    genderFilters: [],
    ageFilters: [],
    groupNameFilters: [],
    dateRangeFromFilter: "",
    dateRangeToFilter: "",
    // Access user
    accessUser: localStorage.getItem("accessUser") || "",
    // Plan
    plan: localStorage.getItem("plan") || "",
    pendingRefreshCompletion: false
}

export const OrganisationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SELECTED_USER_IDS:
            return { ...state, selectedUserIds: action.payload }
        case SET_ORGANISATION_NAME:
            return { ...state, organisationName: action.payload }
        case SET_ORGANISATION_TYPE:
            return { ...state, organisationType: action.payload }
        case SET_ORGANISATION_ADDRESS_LINE_1:
            return { ...state, organisationAddressLine1: action.payload }
        case SET_ORGANISATION_STATE:
            return { ...state, organisationState: action.payload }
        case SET_ORGANISATION_POSTCODE:
            return { ...state, organisationPostcode: action.payload }
        case SET_ORGANISATION_COUNTRY:
            return { ...state, organisationCountry: action.payload }
        case SET_PRIMARY_COLOR:
            localStorage.setItem("organisationPrimaryColor", action.payload);
            return { ...state, organisationPrimaryColor: action.payload }
        case SET_SECONDARY_COLOR:
            localStorage.setItem("organisationSecondaryColor", action.payload);
            return { ...state, organisationSecondaryColor: action.payload }
        case SET_ACCENT_COLOR:
            localStorage.setItem("organisationAccentColor", action.payload);
            return { ...state, organisationAccentColor: action.payload }
        case SET_ORG_LOGO:
            return { ...state, organisationLogo: action.payload }
        // Filters
        case SET_FILTER_GENDER:
            return { ...state, genderFilters: action.payload }
        case SET_FILTER_AGES:
            return { ...state, ageFilters: action.payload }
        case SET_FILTER_GROUP_NAMES:
            return { ...state, groupNameFilters: action.payload }
        case SET_DATE_RANGE_FROM_FILTER:
            return { ...state, dateRangeFromFilter: action.payload }
        case SET_DATE_RANGE_TO_FILTER:
            return { ...state, dateRangeToFilter: action.payload }
        // Access user
        case SET_ACCESS_USER:
            localStorage.setItem("accessUser", action.payload);
            return { ...state, accessUser: action.payload }
        // Plan
        case SET_PLAN:
            localStorage.setItem("plan", action.payload);
            return { ...state, plan: action.payload }
        case SET_PENDING_REFRESH_COMPLETION:
            return { ...state, pendingRefreshCompletion: action.payload }
        default:
            return state;
    }
}