import React from 'react';

import { createPortal } from 'react-dom';

export const ListedUsersComponent = ({type, data, addTestButtonHandler, resetPasswordButtonHandler, viewUsersInOrgButtonHandler, accessUserButtonHandler}) => {

    const renderAccessOrganisationButton = (orgID, orgName) => {
        return (
            <button onClick={() => {
                accessUserButtonHandler(orgID + "", orgName + "", true);
            }}>Access as organisation</button>
        )
    }

    const renderRows = () => {
        var dom = [];

        if(data) {
            for (var i = 0; i < data.length; i++) {
                dom.push(
                    <div key={data[i].organisation_id} className="row">
                        <div className="listed-user-col-1">
                            <span className="name-field">{type == "organisation_list" ? data[i].name : data[i].first_name}</span>
                        </div>
                        <div className="listed-user-col-2">
                            <button data-org-id={typeof data[i] != 'undefined' && data[i].organisation_id != null ? data[i].organisation_id : ""} data-field={type == "organisation_list" ? data[i].name : data[i].user_id} onClick={(e) => {
                                if(type == "organisation_list") {
                                    addTestButtonHandler(e.currentTarget.getAttribute("data-field"), e.currentTarget.getAttribute("data-org-id"));
                                } else {
                                    resetPasswordButtonHandler(e.currentTarget.getAttribute("data-field"));
                                }
                            }}>
                                {type == "organisation_list" ? "Add tests" : "Reset password"}
                            </button>
                            <button style={{display: type == 'organisation_list' ? 'none' : ''}} data-field-name={type != "organisation_list" ? data[i].first_name : ""} data-id={type == "organisation_list" ? data[i].organisation_id : data[i].user_id} onClick={(e) => {
                                if(type == "organisation_list") {
                                    viewUsersInOrgButtonHandler(e.currentTarget.getAttribute("data-id") + "");
                                } else {
                                    accessUserButtonHandler(e.currentTarget.getAttribute("data-id") + "", e.currentTarget.getAttribute("data-field-name") + "", false);
                                }
                            }}>{type == "organisation_list" ? "View users" : "Access as user"} {type == "organisation_list" ? <i style={{marginLeft: '10px'}} class="fa fas fa-chevron-right"></i> : null}</button>
                        
                            {type == "organisation_list" ? renderAccessOrganisationButton(data[i].organisation_id, data[i].name) : null}
                        </div>
                    </div>
                )
            }
        }


        return dom;
    }

    return (
        <div>
            <div className="listed-users-header">
                <div className="name-heading">
                    <span>{type == "organisation_list" ? "Organisation name" : "User name"}</span>
                </div>
                <div className="actions-heading">
                    <span>Actions</span>
                </div>
            </div>

            <div className="listed-users-body">
                {renderRows()}
            </div>
        </div>
    )
}