import React, { useState, useEffect } from 'react';
import './login.css';
// COMPONENTS
import Login from './Login';
import Signup from './Signup';

import { useSelector, useDispatch } from 'react-redux';

import {
    BACKEND_URL,
    ENVIRONMENT_PATH,
    MARKETING_DOMAIN
} from '../../store/constants';

import {
    changeScreen,
    setUserId,
    setTestTokenId,
    setUserPassword,
    setUserBirthMonth,
    setUserBirthYear,
    setUserGender,
    setUserClassName,
    setUserRole,
    setUserAge,
    setUserViewingTest,
    setFirstTimeViewingTest,
    setUserOrganisationName,
    setUserFitnessAge,
    setTestDateDay,
    setTestDateMonth,
    setTestDateYear,
    setSportMatchBodyShapeAndSizeSectionVisited,
    setSportMatchFlexibilitySectionVisited,
    setSportMatchMuscularStrengthAndPowerSectionVisited,
    setSportMatchMuscularEnduranceSectionVisited,
    setSportMatchSpeedAndAgilitySectionVisited,
    setSportMatchAerobicFitnessSectionVisited,
    setAdultSitAndReach,
    setAdultStandingBroadJump,
    setAdultGripStrength,
    setAdultLeftGripStrength,
    setAdultRightGripStrength,
    setAdultVerticalJump,
    setAdultSitUps,
    setAdultInclinedPullUps,
    setAdultShuttleRun,
    setAdult50mSprint,
    setAdultWalkRun,
    setAdultShuttleBeepTest,
    setAdultVo2Max,
    setAdultHeight,
    setAdultWeight,
    setAdultSittingHeight,
    setAdultForearmLength,
    setAdultArmSpan,
    setPredictedAdultFitnessId,
    setTestId,
    setUserPreviousTests,
    setUserBackendId,
    setUserEditingTest, setUserUOM, setUserBirthDay, setUserAddressCountry, setUserAddressState, setUserEmail, setTokenAllocationType
} from '../../store/actions/actions.js';

import {
    setStudentId,
    setMothersHeight,
    setFathersHeight,
    setExpandedMyInfo,
    setExpandedFlexibility,
    setExpandedMuscularStrengthAndPower,
    setExpandedMuscularEndurance,
    setExpandedSpeedAndAgility,
    setExpandedCardiorespiratoryFitness,
    setExpandedBodySizeAndShape,
    setSitAndReach,
    setStandingBroadJump,
    setGripStrength,
    setLeftGripStrength,
    setRightGripStrength,
    setVerticalJump,
    setSitUps,
    setInclinedPullUps,
    setShuttleRun,
    setSprint,
    setSprintDistance,
    setSprintTime,
    setRunWalkMinutes,
    setRunWalkSeconds,
    setVo2Max,
    setBeepTest,
    setHeight,
    setWeight,
    setArmSpan,
    setForearmLength,
    setUpperArmLength,
    setSittingHeight,
    setTestDate,
    setUserInputHeight,
    setUserInputWeight,
    setUserInputArmSpan,
    setUserInputSittingHeight,
    setUserInputForearmLength,
    setUserInputUpperArmLength,
    setUserInputSitAndReach,
    setUserInputStandingBroadJump,
    setUserInputGripStrengthLeft,
    setUserInputGripStrengthRight,
    setUserInputVerticalJump,
    setUserInputBentKneeSitUps,
    setUserInputInclinedPullUps,
    setUserInputShuttleRun,
    setUserInputSprintDistance,
    setUserInputSprintTime,
    setUserInputWalkRunMinutes,
    setUserInputWalkRunSeconds,
    setUserInputShuttleBeepTest,
    setUserInputVO2MAX,
    setAllFieldsProvided,
    setUserInputYoyoTest,
    setUserInputYoyoStage,
    setUserInputYoyoVO2MAX,
    setUserInputBeepTestVO2MAX,
    setYoyoStage,
    setYoyoTest
} from '../../store/actions/myDetails.js';

import {
    setPrimaryColor,
    setSecondaryColor,
    setAccentColor,
    setFilterGroupNames,
    setFilterAges,
    setFilterGender,
    setDateRangeFromFilter,
    setDateRangeToFilter,
    setOrgLogo,
    setAccessUser,
    setPlan,
    setPendingRefreshCompletion
} from '../../store/actions/OrganisationActions.js';

import {
    setNormNameFilter,
    setNormGenderFilter,
    setNormAgeFilter
} from '../../store/actions/adminActions.js';
import { GetOrganisationIDFromURL } from '../../helper/helper';
import { displayMobileDeterMessage } from '../../api/Helper';
import { useToasts } from 'react-toast-notifications/dist/ToastProvider';
import { Button } from '@material-ui/core';



// TODO: Style left section of login/signup screen
const User = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);

    const [signupData, setSignupData] = useState({});

    const [schoolName, setSchoolName] = useState("");
    const [orgId, setOrgId] = useState("");

    // React Toast Notifications Object
    const { addToast } = useToasts();

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    /** On click handler for the buttons */
    const buttonOnClickHandler = (name) => {
        if (name === 'login') {
            setShowLogin(true);
            setShowSignup(false);
        } else {
            window.location.href = `${MARKETING_DOMAIN}`;
        }
    };

    useEffect(() => {
        displayMobileDeterMessage(addToast);
    }, []);

    useEffect(() => {
        dispatch(changeScreen(1))
        dispatch(setUserId(''))
        dispatch(setAccessUser(""));
        dispatch(setTestTokenId(''))
        dispatch(setUserPassword(''))
        dispatch(setUserBirthDay(-1))
        dispatch(setUserBirthMonth(-1))
        dispatch(setUserBirthYear(-1))
        dispatch(setUserGender(''))
        dispatch(setUserClassName(''))
        dispatch(setUserRole(''))
        dispatch(setUserAge(''))
        dispatch(setUserViewingTest(false))
        dispatch(setFirstTimeViewingTest(false))
        dispatch(setUserOrganisationName(''))
        dispatch(setUserAddressCountry(''))
        dispatch(setUserEmail(''))
        dispatch(setUserAddressState(''))
        dispatch(setUserFitnessAge(0.0))
        dispatch(setTestDateDay(''))
        dispatch(setTestDateMonth(''))
        dispatch(setTestDateYear(''))
        dispatch(setSportMatchBodyShapeAndSizeSectionVisited(false))
        dispatch(setSportMatchFlexibilitySectionVisited(false))
        dispatch(setSportMatchMuscularStrengthAndPowerSectionVisited(false))
        dispatch(setSportMatchMuscularEnduranceSectionVisited(false))
        dispatch(setSportMatchSpeedAndAgilitySectionVisited(false))
        dispatch(setSportMatchAerobicFitnessSectionVisited(false))
        dispatch(setAdultSitAndReach(0))
        dispatch(setAdultStandingBroadJump(0))
        dispatch(setAdultGripStrength(0))
        dispatch(setAdultLeftGripStrength(0))
        dispatch(setAdultRightGripStrength(0))
        dispatch(setAdultVerticalJump(0))
        dispatch(setAdultSitUps(0))
        dispatch(setAdultInclinedPullUps(0))
        dispatch(setAdultShuttleRun(0))
        dispatch(setAdult50mSprint(0))
        dispatch(setAdultWalkRun(0))
        dispatch(setAdultShuttleBeepTest(0))
        dispatch(setAdultVo2Max(0))
        dispatch(setAdultHeight(0))
        dispatch(setAdultWeight(0))
        dispatch(setAdultSittingHeight(0))
        dispatch(setAdultForearmLength(0))
        dispatch(setAdultArmSpan(0))
        dispatch(setPredictedAdultFitnessId(0))
        dispatch(setTestId(0))
        dispatch(setUserPreviousTests([]))
        dispatch(setUserBackendId(''))
        dispatch(setStudentId(''));
        dispatch(setMothersHeight(''));
        dispatch(setUserEditingTest(''));
        dispatch(setFathersHeight(''));
        dispatch(setExpandedMyInfo(true));
        dispatch(setExpandedFlexibility(false));
        dispatch(setExpandedMuscularStrengthAndPower(false));
        dispatch(setExpandedMuscularEndurance(false));
        dispatch(setExpandedSpeedAndAgility(false));
        dispatch(setExpandedCardiorespiratoryFitness(false));
        dispatch(setExpandedBodySizeAndShape(false));
        dispatch(setSitAndReach(''));
        dispatch(setStandingBroadJump(''));
        dispatch(setGripStrength(''));
        dispatch(setLeftGripStrength(''));
        dispatch(setRightGripStrength(''));
        dispatch(setVerticalJump(''));
        dispatch(setSitUps(''));
        dispatch(setInclinedPullUps(''));
        dispatch(setShuttleRun(''));
        dispatch(setSprint(''));
        dispatch(setSprintDistance(''));
        dispatch(setSprintTime(''));
        dispatch(setRunWalkMinutes(''));
        dispatch(setRunWalkSeconds(''));
        dispatch(setVo2Max(''));
        dispatch(setBeepTest(''));
        dispatch(setHeight(''));
        dispatch(setWeight(''));
        dispatch(setArmSpan(''));
        dispatch(setAllFieldsProvided(false));
        dispatch(setForearmLength(''));
        dispatch(setUpperArmLength(''));
        dispatch(setSittingHeight(''));
        dispatch(setTestDate(new Date()));
        dispatch(setPlan(""));
        dispatch(setYoyoStage(''));
        dispatch(setYoyoTest(''));

        dispatch(setPrimaryColor(""));
        dispatch(setSecondaryColor(""));
        dispatch(setAccentColor(""));

        dispatch(setOrgLogo(""));

        dispatch(setUserInputHeight(""));
        dispatch(setUserInputWeight(""));
        dispatch(setUserInputArmSpan(""));
        dispatch(setUserInputSittingHeight(""));
        dispatch(setUserInputForearmLength(""));
        dispatch(setUserInputUpperArmLength(""));
        dispatch(setUserInputSitAndReach(""));
        dispatch(setUserInputStandingBroadJump(""));
        dispatch(setUserInputGripStrengthLeft(""));
        dispatch(setUserInputGripStrengthRight(""));
        dispatch(setUserInputVerticalJump(""));
        dispatch(setUserInputBentKneeSitUps(""));
        dispatch(setUserInputInclinedPullUps(""));
        dispatch(setUserInputShuttleRun(""));
        dispatch(setUserInputSprintDistance(""));
        dispatch(setUserInputSprintTime(""));
        dispatch(setUserInputWalkRunMinutes(""));
        dispatch(setUserInputWalkRunSeconds(""));
        dispatch(setUserInputShuttleBeepTest(""));
        dispatch(setUserInputYoyoTest(''));
        dispatch(setUserInputYoyoStage(''));
        dispatch(setUserInputYoyoVO2MAX(''));
        dispatch(setUserInputVO2MAX(""));
        dispatch(setUserInputBeepTestVO2MAX(''));

        dispatch(setUserUOM(""));
        dispatch(setTokenAllocationType(""));

        // Resetting organisation filters
        dispatch(setFilterGroupNames([]));
        dispatch(setFilterAges([]));
        dispatch(setFilterGender([]));
        dispatch(setDateRangeFromFilter(""));
        dispatch(setDateRangeToFilter(""));

        // Resetting admin filters
        dispatch(setNormNameFilter([]));
        dispatch(setNormGenderFilter([]));
        dispatch(setNormAgeFilter([]));

        dispatch(setPendingRefreshCompletion(false));

        const search = window.location.href;
        const searchSplitted = search.split("/");

        if (searchSplitted.length > 0) {
            var orgName = searchSplitted[searchSplitted.length - 1];

            if (orgName == "") orgName = null;

            fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=get_org_name&org_name=${orgName}`).then((res) => {
                res.json().then((result) => {
                    setSchoolName(result.org_name);
                    setOrgId(result.org_id && result.org_id != "" ? result.org_id : "123");
                })
            }).catch(err => {
                console.log("An error was thrown");
            })
        }
    }, [])

    const renderSchoolName = () => {
        if (schoolName != "") {
            return (
                <span className="school-name">For {schoolName}</span>
            )
        } else {
            return null;
        }
    }

    return (
        <div className="login-screen-container">
            <div className="login-screen-background">
                <div className="login-screen-background-seperator"></div>
            </div>
            <div className="login-screen">
                <div className="login-screen-left">
                    <div className="login-screen-left-inner">
                        <div className="login-screen-title">
                            <span className="login-screen-title-upper">Welcome to</span>
                            <br />
                            <span className="login-screen-title-middle">Sport</span>
                            <span className="login-screen-title-lower">Match</span>
                        </div>
                        {renderSchoolName()}
                        <div className="login-screen-desc">
                            The World’s sport profiling and talent ID platform
                        </div>
                        <div onClick={() => buttonOnClickHandler("login")} className="login-screen-login-button">
                            <a>Login</a>
                        </div>
                        <div onClick={() => buttonOnClickHandler()} className="login-screen-signup-button">
                            <a>Sign up</a>
                        </div>
                    </div>
                </div>
                <div className="login-screen-right">
                    <div style={{ display: showLogin ? 'initial' : 'none' }}>
                        <Login orgId={orgId} />
                    </div>
                    <div style={{ display: showSignup ? 'initial' : 'none' }}>
                        <Signup signup={(data) => {
                            setSignupData(data);
                        }} />
                    </div>
                </div>
            </div>


        </div>
    );
}

export default User;
