export const fieldInfo = {
    height: `<b><u>How to measure height:</u></b><br>
    To measure height the person should stand without shoes on and their back against the stadiometer (or wall if you don’t have a stadiometer). 
    Place the feet together and flat on the floor. Keep the head steady and looking straight ahead. When ready, 
    the person should take a big breath in while a teacher or another student adjusts the stadiometer so that it is touching the tallest part of the head 
    (or using a ruler to mark the level of the top of the head on the wall). Have the assistant read the measurement and record the score.`,

    weight: `<b><u>How to measure weight:</u></b><br>
    To measure weight the person will need to remove their shoes, heavy pieces of clothing (e.g. jumper/tracksuit pants) and any items in their pockets. 
    The person should stand still on the scales until they get a stable result (using bathroom/portable scales). 
    It is recommended that this measurement be taken before eating a meal to get a more accurate reading. 
    Have the assistant read the weight measurement and record the score.`,

    armSpan: `<b><u>How to measure arm span:</u></b><br>
    To measure arm span the participant will need to stand upright with the arms extended horizontally out to the sides. 
    The arms must be straight with fingers outstretched. A teacher or another student will then measure the distance between the fingertips. 
    This is called the ‘arm span’. Have the assistant read the measurement and record the score. 
    This distance is used to calculate the arm span-to-height percentage by using the following formula: Arm span (%) = [arm span / height] x 100. `,

    sittingHeight: `<b><u>How to measure sitting height:</u></b><br>
    The person will need to sit on a flat box or bench in an upright position. 
    The shoulders and lower part of the back should, where possible, be against a wall or stadiometer. 
    The person is instructed to take and hold a deep breath while a teacher or another student adjusts the stadiometer until it is touching the tallest part of the head 
    (or using a ruler mark the level of the top of the head on the wall). 
    Have the assistant read the measurement from the top of the box or bench to the tallest part of the head. 
    Record the score. Calculate the sitting height-to-height percentage by using the following formula: 
    Sitting height (%) = [sitting height / height] x 100.`,

    forearmLength: `<b><u>How to measure forearm length:</u></b><br>
    The forearm length is used to determine the relative length of the lower arm (forearm) to the upper arm – called a ‘Brachial index’. 
    To measure forearm length requires a ruler/segmometer and a teacher or another student to assist. 
    Position the forearm on a table or flat surface. Note the elbow is bent at a 90° angle. 
    Take the horizontal measurement from the elbow to the wrist. 
    Technically, this is from the proximal margin of the ulna at the elbow – called the olecranon process – to the distal end of the ulna at the wrist. 
    Record the length.`,

    upperArmLength: `<b><u>How to measure upper arm length:</u></b><br>
    The upper arm length is used to determine the relative length of the lower arm (forearm) to the upper arm – called a ‘Brachial index’. 
    To measure upper arm length the participant will need a ruler/segmometer and a teacher or another student to assist. 
    Position the forearm on a table or flat surface with the elbow bent at a 90° angle. 
    Take the vertical measurement from the lateral part of the shoulder blade (acromion process) to the flat table surface below the elbow. 
    Record the length. The value is used to calculate the forearm-to-upper arm percentage 
    (also called the Brachial index) by using the following formula: Brachial index (%) = [forearm length  /upper arm length ] x 100.`,

    sitAndReach: `<b><u>Fitness component tested</u></b><br>
        The sit & reach test measures flexibility at the hip, which is primarily determined by the elasticity of the muscles and connective tissues in the lower back, 
        and hamstring group at the back of the legs.<br><br>
        <b><u>The aim of the test</u></b><br>
        To reach as far forward as possible using a slow controlled movement pattern. To record a positive measure, 
        the participant needs to stretch forward past their starting point (i.e., it is a floating zero point so there can be no negative scores).
        Provide two attempts but it is important to perform some stretching activities for the back and legs before undertaking the tests.<br><br>
        <b><u>How to perform the test</u></b><br>
        Starting position:  The person should sit with the head, shoulders and back against the back-rest or wall, place both feet against the foot-rest,
        feet shoulder width apart, thumbs interlocked, fingers together, arms stretched out to the level of the end of the ruler.<br>
        The reach: In one controlled movement the person should stretch forward as far as possible, fingers sliding along the ruler. 
        They should hold the extended position for about 2 seconds while the distance to the fingertips is measured in centimetres on the slider.`,

    sitAndReach_imperial: `<b><u>Fitness component tested</u></b><br>
        The sit & reach test measures flexibility at the hip, which is primarily determined by the elasticity of the muscles and connective tissues in the lower back, 
        and hamstring group at the back of the legs.<br><br>
        <b><u>The aim of the test</u></b><br>
        To reach as far forward as possible using a slow controlled movement pattern. To record a positive measure, 
        the participant needs to stretch forward past their starting point (i.e., it is a floating zero point so there can be no negative scores).
        Provide two attempts but it is important to perform some stretching activities for the back and legs before undertaking the tests.<br><br>
        <b><u>How to perform the test</u></b><br>
        Starting position:  The person should sit with the head, shoulders and back against the back-rest or wall, place both feet against the foot-rest,
        feet shoulder width apart, thumbs interlocked, fingers together, arms stretched out to the level of the end of the ruler.<br>
        The reach: In one controlled movement the person should stretch forward as far as possible, fingers sliding along the ruler. 
        They should hold the extended position for about 2 seconds while the distance to the fingertips is measured in inches on the slider.`,

    standingBroadJump: `<b><u>Fitness component tested</u></b><br>
        The standing broad jump measures explosive power generated in the legs.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test the aim is to jump as far forward as possible. Participants have two attempts and the best score is recorded.<br><br>
        <b><u>How to perform the test</u></b><br>
        Starting position: Standing with two feet behind the take-off line, feet shoulder width apart.<br>
        Preparation and jump: Swing arms back, bend knees to ~90°, swing arms forward, take-off from two feet, and jump forward as far as possible.<br>
        Landing: The person should land on two feet, hold the position, and try not to fall back. The distance jumped is the distance from the take-off line to the heel nearest to the take-off line.`,

    gripStrength: `<b><u>Fitness components tested</u></b><br>
        The grip strength test is a measure of the forearm strength and is a good indicator of overall upper-body strength. The average of the left and right grip strength values is used in further calculations. If only one measure is entered that value will be used in the SportMatch calculations.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test the aim is to record the maximal force on a grip dynamometer (measured in kilograms).<br><br>
        <b><u>How to perform the test</u></b><br>
        Adjust the handle to best fit the hand size before the test. For each side the grip dynamometer should be held in the hand above the head. On the instruction the person should squeeze the handles as hard as possible as they bring the dynamometer downwards in front of the body and finally to the side of the thigh (without touching the thigh).`,

     gripStrength_imperial: `<b><u>Fitness components tested</u></b><br>
        The grip strength test is a measure of the forearm strength and is a good indicator of overall upper-body strength. The average of the left and right grip strength values is used in further calculations. If only one measure is entered that value will be used in the SportMatch calculations.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test the aim is to record the maximal force on a grip dynamometer (measured in pounds).<br><br>
        <b><u>How to perform the test</u></b><br>
        Adjust the handle to best fit the hand size before the test. For each side the grip dynamometer should be held in the hand above the head. On the instruction the person should squeeze the handles as hard as possible as they bring the dynamometer downwards in front of the body and finally to the side of the thigh (without touching the thigh).`,


    verticalJump: `<b><u>Fitness component tested</u></b><br>
        The Vertical jump measures the power in the legs.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test the aim is to jump as high as possible in an explosive movement upwards. Participants have two attempts and the best score is recorded.<br><br>
        <b><u>How to perform the test</u></b><br>
        Starting position: Standing on two feet under the vertical jump apparatus or adjacent to a wall. The feet should be about shoulder width apart. The person should raise one hand straight above the head and record the height from the ground to the top of the outstretched fingers. Ensure to record this as the starting height.
        <br>
        The jump: Swing the arms back and down, bend knees to approximately 90° (called a countermovement), swing arms up and forward, take-off from two feet, and jump as high as possible with one outstretched arm. Record the height difference between the starting height and maximum height jumped, this is the standing countermovement vertical jump height. The jump height can be measured using a chalk mark against a wall or using jumping apparatus such as a Vertec VJ system of slats.`,

    sitUps: `<b><u>Fitness component tested</u></b><br>
        The bent-knee sit-up test measures muscular strength and endurance of the stomach muscles, also known as abdominal muscles.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test the aim is to complete as many sit-ups as possible in 60 seconds.<br><br>
        <b><u>How to perform the test</u></b><br>
        Starting position: Lying on the back, the knees should be bent to approximately a 90° angle, with feet shoulder width apart. A partner may stand on the person’s shoes and/or hold the ankles in position during this test.
        <br>
        Sit-up: Cup the ears with the hands, the stomach muscles should be used to pull the body up towards the knees, touch the knees with the elbows. This is one sit-up.
        <br>
        Down: Lower the body back to the floor, until the person is in the starting position. The shoulder blades must touch the floor. Repeat the movement as many times as possible in 60 seconds.`,

    inclinedPullUps: `<b><u>Fitness component tested</u></b><br>
        The inclined pull-up test measures muscular strength and endurance of the upper body.<br><br>        
        <b><u>The aim of the test</u></b><br>
        In this test the aim is to complete as many inclined pull-ups as possible in 30 seconds.<br><br>        
        <b><u>How to perform the test</u></b><br>
        Starting position: Hands should be face up under the horizontal bar, chin rod directly over the eyes, palms facing forward, hands shoulder width apart, elbows straight, heels resting on floor. The body should be at about 30° from horizontal.
        <br>
        The pull-up: The person should pull their chest towards the bar. The body should be lifted until the chin reaches the chin rod while keeping the body straight. This is one pull-up.
        <br>
        Down: Return the body to the starting position to repeat the movement as many times as possible in 30 seconds.`,

    shuttleRun: `<b><u>Fitness components tested</u></b><br>
        The shuttle run test measures speed, agility and coordination.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test you are aiming to complete the shuttle run as fast as you can. You have two attempts.<br><br>
        <b><u>How to perform the test</u></b><br>
        Starting position: Standing with the front foot behind the start line.
        <br>
        The run: On the command ‘go’ the person runs 10 m to the opposite line, picks up the bean bag/cone and returns and leave it at the start line. This is repeated to collect the second bean bag/cone. There is a final sprint over the finish line (carrying the bean bag/cone). Ensure to accurately record the time to the nearest 0.1 seconds.
        `,

    shuttleRun_imperial: `<b><u>Fitness components tested</u></b><br>
        The shuttle run test measures speed, agility and coordination.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test you are aiming to complete the shuttle run as fast as you can. You have two attempts.<br><br>
        <b><u>How to perform the test</u></b><br>
        Starting position: Standing with the front foot behind the start line.
        <br>
        The run: On the command ‘go’ the person runs 11 yd to the opposite line, picks up the bean bag/cone and returns and leave it at the start line. This is repeated to collect the second bean bag/cone. There is a final sprint over the finish line (carrying the bean bag/cone). Ensure to accurately record the time to the nearest 0.1 seconds.
        `,

    sprint: `<b><u>Fitness components tested</u></b><br>
        The sprint can be performed over any precise distance from 10 - 100 m. The exact distance must be entered into SportMatch along with the recorded sprint time (s).
        <br><br>
        <b><u>Fitness components tested</u></b><br>
        The sprint test is a measure of speed and anaerobic power
        <br><br>
        <b><u>The aim of the test</u></b><br>
        In this test you are aiming to record the fastest time to cover the distance chosen between 10 – 100 m. 
        <br><br>
        <b><u>How to perform the test</u></b><br>
        Take up the starting position on the start line. The distance should be recorded accurately. A teacher or another student will give you a “ready, set, go” countdown before the test begins. You will then complete the sprint as fast as possible. Ensure you record your time to the nearest 0.1 seconds.
        `,

    sprint_imperial: `<b><u>Fitness components tested</u></b><br>
        The sprint can be performed over any precise distance from 21.87 – 109.36 yards. The exact distance must be entered into SportMatch along with the recorded sprint time (s).
        <br><br>
        <b><u>Fitness components tested</u></b><br>
        The sprint test is a measure of speed and anaerobic power
        <br><br>
        <b><u>The aim of the test</u></b><br>
        In this test you are aiming to record the fastest time to cover the distance chosen between 21.87 – 109.36 yards. 
        <br><br>
        <b><u>How to perform the test</u></b><br>
        Take up the starting position on the start line. The distance should be recorded accurately. A teacher or another student will give you a “ready, set, go” countdown before the test begins. You will then complete the sprint as fast as possible. Ensure you record your time to the nearest 0.1 seconds.
        `,

    averageSpeed: 'The Average sprint speed is based on the sprint distance covered in the time recorded and reported in km/h.',

    averageSpeed_imperial: 'The Average sprint speed is based on the sprint distance covered in the time recorded and reported in mph.',

    maximumSpeed: 'The Maximum sprint speed is calculated based on the Average sprint speed for the distance covered, relative to the ' +
        'distance where the maximum sprint is typically recorded. This is at the 50-60 m split of a 100 m race. Up until this distance the ' +
        'speed is increasing and beyond this distance the speed begins to decrease. The Maximum sprint speed is reported in km/h.',

    maximumSpeed_imperial: 'The Maximum sprint speed is calculated based on the Average sprint speed for the distance covered, relative to the ' +
        'distance where the maximum sprint is typically recorded. This is at the 54.6 - 65.6 yard split of a 109.3 yard race. Up until this distance the ' +
        'speed is increasing and beyond this distance the speed begins to decrease. The Maximum sprint speed is reported in mph.',

    runWalk: `<b><u>Fitness component tested</u></b><br>
        The 1.6 km walk is a measure of cardio-respiratory endurance (aerobic endurance) and muscular
        endurance of the legs.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test the aim is to complete a 1.6 km distance as fast as possible. <br><br>
        <b><u>How to perform the test</u></b><br>
        On the command ‘go’, the person should start to run the 1.6 km course as fast as possible. If they become tired they can jog or walk to recover then run again. Ensure to accurately record the time to the nearest 1 second.`,

    beepTest: `<b><u>Fitness components tested</u></b><br>
        The shuttle (beep) test is a measure of aerobic fitness (cardiorespiratory endurance) and muscular endurance of the legs.<br><br>
        <b><u>The aim of the test</u></b><br>
        In this test the aim is to complete as many levels of the standardised shuttle test as possible. The shuttle (beep) test is freely available on YouTube: 
        <br>
        <a href="https://www.youtube.com/watch?v=4dSzlN8e9HE">https://www.youtube.com/watch?v=4dSzlN8e9HE</a>
        <br><br>
        <b><u>How to perform the test</u></b><br>
        On the command ‘go’ the person starts to complete the 20 m shuttles in time with the instructions on the shuttle test audio. If they fail to reach the opposite line, they are given a warning and encouraged to catch up to the instructions. If they fail to reach the line a second time, their test will finish. The person may stop at any time but it is designed as a maximal-effort test protocol. Record the final stage and shuttle level completed. This is entered in to SportMatch and used to calculate a maximal aerobic fitness level (VO2max) measured in mL/kg/min.`,

    fathersHeight: `Father’s height  – in order to more accurately predict the student’s final [adult] height the
        student can enter their biological father’s height. This gives an estimate ± 2 of the final adult
        height.`,

    mothersHeight: `Mother’s height  – in order to more accurately predict the student’s final [adult] height the
        student can enter their biological mother’s height. This gives an estimate ± 2 of the final adult
        height.`

};
