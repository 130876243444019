import React, {useState} from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

import { Card, CardActions, CardContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';
import styled from 'styled-components';

import {
    changeScreen, setUserGender
} from '../../../store/actions/actions.js';
import { BirthDropdownField } from '../../../components/FormInputs';
/** Required to show the dialog appearing from the bottom of the screen */

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
}); 

 const GenderConfirmation = ({ showDialog, onSubmitClick: onSubmitClick }) => {
     const [gender, setGender] = useState("");
     const reduxState = useSelector(state => state);
     const dispatch = useDispatch();

     const organisationState = reduxState.organisationState;

    const saveAndChange = () =>{
        onSubmitClick(gender)
    }

    const FaderSpan = styled.span`
    &:hover {
        background-color: ${organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'};
    }`;

    let style = {
        border: organisationState.organisationPrimaryColor != '' ? '3px solid ' + organisationState.organisationPrimaryColor : '3px solid #9a62b3',
        background: gender === 'male' ? (organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3') : ''
    }

    return (
        <Dialog open={showDialog} TransitionComponent={Transition} keepMounted onClose={null}>
            <Card className="first-time-making-test-card dob-checker">
                <CardContent>
                    <span className="card-body-text-span">
                        Please select your gender<br/><br/>
                    </span>

                    <div className="gender-selection-container">
                        <label className={"gender-section"}>
                            <input checked={gender === 'male'} id="male-gender-radio" onChange={() => setGender("male")} type="radio" name="gender" value="male" />
                            <FaderSpan className="checkmark" style={style}></FaderSpan>
                            <label className="gender-selection" for="male-gender-radio">Male</label>
                        </label>
                        <label className="gender-section">
                            <input checked={gender === 'female'} id="female-gender-radio" onChange={() => setGender("female")} type="radio" name="gender" value="female" />
                            <FaderSpan className="checkmark" style={{ border: organisationState.organisationPrimaryColor != '' ? '3px solid ' + organisationState.organisationPrimaryColor : '3px solid #9a62b3', background: gender === 'female' ? (organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3') : '' }}></FaderSpan>
                            <label className="gender-selection" for="female-gender-radio">Female</label>
                        </label>
                    </div>

                    <div className="card-button-container">
                        <button onClick={() =>  {
                            saveAndChange(gender);
                            dispatch(setUserGender(gender));
                        }} disabled={gender == ""} className="begin-btn">Submit</button>
                    </div>
                </CardContent>
            </Card>
        </Dialog>
    );
}
export default GenderConfirmation;