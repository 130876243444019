// REACT
import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DownloadSvg from "../../assets/svg/downloadSvg";

import moment from 'moment';

const ReportRow = ({
    name,
    group,
    student,
    overlap,
    sport,
    downloadIndividualHandler,
    testDate
}) => {
    const organisationLocalState = useSelector(state => state.organisationState);
    const [loading, setLoading] = useState(false);

    return (
        <div className="test-row">
            <div>{name}</div>
            <div>{group != null ? group : ""}</div>
            <div>
                {testDate != null
                    ? moment(student.test_date).format("DD/MM/YYYY")
                    : "unavailable"}
            </div>
            <div>{overlap}</div>
            <div className="sport-name">{sport}</div>
            <div className="download-container">
                <div onClick={() => {
                    if (downloadIndividualHandler) {
                        setLoading(true);
                        downloadIndividualHandler(name).then(res => {
                            setLoading(false);
                        })
                    }
                }} className="talent-id-download-icon">
                    {loading && <CircularProgress disableShrink size={24} />}
                    <DownloadSvg color={organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'} />
                </div>
            </div>
        </div>
    )
}

export default ReportRow;