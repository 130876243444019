import { combineReducers } from 'redux';

// Importing reducers
import { screenReducer, eliteAthletesProfilesRuducer, userReducer } from './reducers/reducers.js';
import { myDetailsReducer } from './reducers/myDetails.js';
import { compareMyResultsReducer } from './reducers/compareMyResults.js';
import { sportMatchTestReducer } from './reducers/sportMatchTestReducer.js';
import { adultValuesReducer } from './reducers/adultValuesReducer.js';
import { OrganisationReducer } from './reducers/OrganisationReducer.js'
import { AdminReducer } from './reducers/adminReducer.js';

export const rootReducer = combineReducers({
  myDetails: myDetailsReducer,
  sportMatchTest: sportMatchTestReducer,
  compareMyResults: compareMyResultsReducer,
  user: userReducer,
  adultValues: adultValuesReducer,
  organisationState: OrganisationReducer,
  adminState: AdminReducer
})
