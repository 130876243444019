export const hasBodyShapeAndSizeFilledOut = (reduxState) => {
    if (reduxState.sportMatchTest.userInputHeight != "" 
    && reduxState.sportMatchTest.userInputWeight != "" 
    && reduxState.sportMatchTest.userInputArmSpan != "" 
    && reduxState.sportMatchTest.userInputSittingHeight != "" 
    && reduxState.sportMatchTest.userInputForearmLength != "" 
    && reduxState.sportMatchTest.userInputUpperArmLength != "") {
        return "filled-out";
    }
}

export const hasFlexibilityFilledOut = (reduxState) => {
    if (reduxState.sportMatchTest.userInputSitAndReach != "") {
        return "filled-out";
    }
}

export const hasMuscularStrengthAndPowerFilledOut = (reduxState) => {
    if (reduxState.sportMatchTest.userInputStandingBroadJump != ""
        && reduxState.sportMatchTest.userInputGripStrengthLeft != ""
        && reduxState.sportMatchTest.userInputGripStrengthRight != ""
        && reduxState.sportMatchTest.userInputVerticalJump != "") {
            return "filled-out";
        }
}

export const hasMuscularEnduranceFilledOut = (reduxState) => {
    if (reduxState.sportMatchTest.userInputBentKneeSitUps != ""
    && reduxState.sportMatchTest.userInputInclinedPullUps != "") {
        return "filled-out";
    }
}

export const hasSpeedAndAgilityFilledOut = (reduxState) => {
    if (reduxState.sportMatchTest.userInputShuttleRun != ""
        && reduxState.sportMatchTest.userInputSprintDistance != ""
        && reduxState.sportMatchTest.userInputSprintTime != "") {
            return "filled-out";
        }
}

export const hasAerobicFitnessFilledOut = (reduxState) => {
    if ((reduxState.sportMatchTest.userInputWalkRunMinutes != "" || reduxState.sportMatchTest.userInputWalkRunSeconds != "")
        && reduxState.sportMatchTest.userInputShuttleBeepTest != "") {
            return "filled-out";
        }
}