import React, { useState, useEffect } from 'react';

// REDUX
import { useSelector } from 'react-redux';

// MATERIAL UI COMPONENTS
import Button from '@material-ui/core/Button';
import { Card, CardContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

// IMAGES
import ForearmLengthImage from '../../../assets/findMySportAssets/forearm_length.png';
import SittingHeightImage from '../../../assets/findMySportAssets/sitting_height.png';

// Arm span images
import ArmSpan1Svg from '../../../assets/findMySportAssets/svgFiles/ArmSpan1Svg.js';
import ArmSpan2Svg from '../../../assets/findMySportAssets/svgFiles/ArmSpan2Svg.js';
import ArmSpan3Svg from '../../../assets/findMySportAssets/svgFiles/ArmSpan3Svg.js';
import ArmSpan4Svg from '../../../assets/findMySportAssets/svgFiles/ArmSpan4Svg.js';
import ArmSpan5Svg from '../../../assets/findMySportAssets/svgFiles/ArmSpan5Svg.js';

// Forearm length images
import ForearmLength1Svg from '../../../assets/findMySportAssets/svgFiles/ForearmLength1Svg.js';
import ForearmLength2Svg from '../../../assets/findMySportAssets/svgFiles/ForearmLength2Svg.js';
import ForearmLength3Svg from '../../../assets/findMySportAssets/svgFiles/ForearmLength3Svg.js';
import ForearmLength4Svg from '../../../assets/findMySportAssets/svgFiles/ForearmLength4Svg.js';
import ForearmLength5Svg from '../../../assets/findMySportAssets/svgFiles/ForearmLength5Svg.js';

// Sitting height images
import SittingHeight1Svg from '../../../assets/findMySportAssets/svgFiles/SittingHeight1Svg.js';
import SittingHeight2Svg from '../../../assets/findMySportAssets/svgFiles/SittingHeight2Svg.js';
import SittingHeight3Svg from '../../../assets/findMySportAssets/svgFiles/SittingHeight3Svg.js';
import SittingHeight4Svg from '../../../assets/findMySportAssets/svgFiles/SittingHeight4Svg.js';
import SittingHeight5Svg from '../../../assets/findMySportAssets/svgFiles/SittingHeight5Svg.js';

// SVGs
import CaretUpSvg from '../../../assets/findMySportAssets/caretUpSvg.js';
import CaretDownSvg from '../../../assets/findMySportAssets/caretDownSvg.js';

/** Required to show the dialog appearing from the bottom of the screen */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BodySizeAndShapeSection = ({onChangeHandler, resetBackToDefaultValues, armSpan, forearmLength, sittingHeight, setArmSpan, setFormarmLength, setSittingHeight, backClickHandler }) => {
    const [infoPopupOpen, setInfoPopupOpen] = useState(false);

    const reduxState = useSelector(state => state.adultValues);

    const organisationStateLocal = useSelector(state => state.organisationState);

    const generateSittingHeightImage = () => {
        switch (sittingHeight) {
            case 1:
                return <SittingHeight1Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 2:
                return <SittingHeight2Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 3:
                return <SittingHeight3Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 4:
                return <SittingHeight4Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 5:
                return <SittingHeight5Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            default:
                return <SittingHeight1Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
        }
    }

    const generateArmSpanImage = () => {
        switch (armSpan) {
            case 1:
                return <ArmSpan1Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 2:
                return <ArmSpan2Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 3:
                return <ArmSpan3Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 4:
                return <ArmSpan4Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 5:
                return <ArmSpan5Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            default:
                return <ArmSpan1Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
        }
    }

    const generateForearmLengthImage = () => {
        switch (forearmLength) {
            case 1:
                return <ForearmLength1Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 2:
                return <ForearmLength2Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 3:
                return <ForearmLength3Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 4:
                return <ForearmLength4Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            case 5:
                return <ForearmLength5Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
            default:
                return <ForearmLength1Svg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'}/>;
        }
    }
    const generateValue = (type) => {
        let result;
        if(type === 'armSpan'){
            result = armSpan === 0 ? '-': armSpan
        }
        if(type === 'sittingHeight'){
            result = sittingHeight === 0 ? '-': sittingHeight
        }
        if(type === 'forearmLength'){
            result = forearmLength === 0 ? '-': forearmLength
        }
        return result;
    }
    return (
        <div className="screen-section my-results-section find-my-sport-body-shape-and-size" style={{ height: '440px' }}>
            <div className="my-results-section-header">
                <span className="my-results-span">Body size and shape</span>

                <Button style={{ backgroundColor: 'white', color: 'black' }} onClick={() => {
                    setInfoPopupOpen(true)
                }} variant={'contained'}>Info</Button>

                <Button onClick={resetBackToDefaultValues} className="reset-button" variant={'contained'} style={{ 'backgroundColor': organisationStateLocal.organisationPrimaryColor != "" ? organisationStateLocal.organisationPrimaryColor : "" }}>Reset</Button>
            </div>

            <div className="left-arrow-section" onClick={() => backClickHandler()}>
                <span style={{ color: organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#9a62b3'}}>
                    {"<"}
                </span>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-around', maxWidth: '1000px', marginLeft: '20px' }}>

                <div>
                    <div className={`body-shape-and-size-icon`}>
                        {generateArmSpanImage()}
                    </div>

                    <div className="section-container">
                        <div className="caret-container">
                            <div style={{ top: '15px' }} onClick={() => {
                                var amount = armSpan;
                                if (amount < 5) {
                                    setArmSpan(amount += 1);
                                    onChangeHandler();
                                }
                            }}>
                                <CaretUpSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#9a62b3'}/>
                            </div>

                            <div style={{ top: '14px' }} onClick={() => {
                                var amount = armSpan;
                                if (amount > 0) {
                                    setArmSpan(amount -= 1);
                                    onChangeHandler();
                                }
                            }}>
                                <CaretDownSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#9a62b3'}/>
                            </div>
                        </div>

                        <input onChange={onChangeHandler} type="text" value={generateValue('armSpan')} style={{color: organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5a5380'}}/>
                    </div>
                </div>
                <div>
                    <div className={`body-shape-and-size-icon`}>
                        {generateSittingHeightImage()}
                    </div>

                    <div className="section-container">
                        <div className="caret-container">
                            <div style={{ top: '15px' }} onClick={() => {
                                var amount = sittingHeight;
                                if (amount < 5) {
                                    setSittingHeight(amount += 1);
                                    onChangeHandler();
                                }
                            }}>
                                <CaretUpSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#9a62b3'}/>
                            </div>

                            <div style={{ top: '14px' }} onClick={() => {
                                var amount = sittingHeight;
                                if (amount > 0) {
                                    setSittingHeight(amount -= 1);
                                    onChangeHandler();
                                }
                            }}>
                                <CaretDownSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#9a62b3'}/>
                            </div>
                        </div>

                        <input type="text" value={generateValue('sittingHeight')} style={{color: organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5a5380'}}/>
                    </div>
                </div>
                <div>
                    <div className={`body-shape-and-size-icon`}>
                        {generateForearmLengthImage()}
                    </div>

                    <div className="section-container">
                        <div className="caret-container">
                            <div style={{ top: '15px' }} onClick={() => {
                                var amount = forearmLength;
                                if (amount < 5) {
                                    setFormarmLength(amount += 1);
                                    onChangeHandler();
                                }
                            }}>
                                <CaretUpSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#9a62b3'}/>
                            </div>

                            <div style={{ top: '14px' }} onClick={() => {
                                var amount = forearmLength;
                                if (amount > 0) {
                                    setFormarmLength(amount -= 1);
                                    onChangeHandler();
                                }
                            }}>
                                <CaretDownSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#9a62b3'}/>
                            </div>
                        </div>

                        <input type="text" value={generateValue('forearmLength')} style={{color: organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5a5380'}}/>
                    </div>
                </div>
            </div>

            <Dialog className="find-my-sport-info-popup" open={infoPopupOpen} TransitionComponent={Transition} keepMounted onClose={null}>
                <Card>
                    <CardContent>
                        <div className="card-body">
                            <h2>Info</h2>
                            <hr />
                            <span>
                                Use the ‘up’ and ‘down’ arrows to adjust the numbers representing the arm span, sitting height and brachial index of the active profile. There are 5 categories for each
                                variable representing quintiles within the population.
                                <br /><br />
                                The longest measure is represented by a ‘5’ (for example, a ‘5’ for arm span means a relatively long arm span
                                compared to height), whereas a shorter measurement is represented by a ‘1’.

                                After these variables are altered the user can click ‘What-if’ to see how the changes impact the ‘Top 5 sports’ and ‘Top sport types’ selected in SportMatch.
                            </span>
                        </div>

                        <div className="card-button-container">
                            <Button variant="contained" onClick={() => {
                                setInfoPopupOpen(false);
                            }}>
                                Close
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Dialog>
        </div>
    )
}

export default BodySizeAndShapeSection;
