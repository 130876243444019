import React, { useState, useEffect } from 'react';

import './style.css';

const SignupSuccess = ({ buttonClickHandler }) => {
    return (
        <React.Fragment>
            <div className="signup-wrapper signup-success">
                <div className="signup-box">
                    <div className="signup-box-container">
                        <span>Congratulations, you are now able to use SportMatch!</span>
                    </div>
                </div>

                <div className="proceed-btn">
                    <input type="button" value="Proceed" onClick={() => buttonClickHandler()} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default SignupSuccess;