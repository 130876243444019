import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
// Field Info
import { fieldInfo } from './fieldInfoContent.js';
// COMPONENTS
import { MyDetailsHeader } from '../../../components/MyDetailsHeader';
import {
    UserDefinedTextField,
    SliderField,
    RadioField
} from '../../../components/FormInputs.js';
// ACTIONS
import {
    setSitUps,
    setInclinedPullUps,
    setExpandedMuscularEndurance,
    setUserInputBentKneeSitUps,
    setUserInputInclinedPullUps
} from '../../../store/actions/myDetails.js';

// ICONS
import InclinedPullUpsSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/InclinedPullUpsSvg.js';

const MuscularEndurance = ({ infoOpen, setInfoOpen: setInfoOpen }) => {
    const state = useSelector(store => store.myDetails);
    const sportMatchState = useSelector(store => store.sportMatchTest);
    const muscularEnduranceExpanded = state.muscularEnduranceSectionExpanded;
    const dispatch = useDispatch();
    const organisationStateLocal = useSelector(state => state.organisationState);
    // Local State
    const [expanded, setExpanded] = useState(false);

    // Functions enabling child components to dispatch actions
    const dispatchSitUps = (input) => {
        dispatch(setUserInputBentKneeSitUps(input))
    }
    const dispatchInclinedPullUps = (input) => {
        dispatch(setUserInputInclinedPullUps(input))
    }

    const expand = () => {
        dispatch(setExpandedMuscularEndurance(!muscularEnduranceExpanded));
    }

    const getFieldsChanged = () => {
        if (sportMatchState.userInputBentKneeSitUps != "" && state.sitUps == "") {
            return true;
        } else if (sportMatchState.userInputInclinedPullUps != "" && state.inclinedPullUps == "") {
            return true;
        } else {
            return false;
        }
    }

    const resetButtonHandler = () => {
        if (state.sitUps == "") {
            dispatch(setUserInputBentKneeSitUps(""));
        }

        if (state.inclinedPullUps == "") {
            dispatch(setUserInputInclinedPullUps(""));
        }
    }

    const inclinedPullUpsIcon = () => {
        return <InclinedPullUpsSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'} />;
    }

    return (
        <div className="screen-section test-section">
            <MyDetailsHeader
                children={[]}
                title={"Muscular endurance"}
                icon={inclinedPullUpsIcon()} isIconSvg={false} expand={expand} expanded={true}
                fieldChanged={getFieldsChanged()}
                resetBtnHandler={resetButtonHandler}
            />
            <div className={`myDetails-fields expanded`}>
                <SliderField action={dispatchSitUps} label={"Bent-knee sit-ups"} info={fieldInfo.sitUps} enteredValue={sportMatchState.userInputBentKneeSitUps} minValue={0} maxValue={100}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.sitUps != ""} />
                <SliderField action={dispatchInclinedPullUps} label={"Inclined pull-ups"} info={fieldInfo.inclinedPullUps} enteredValue={sportMatchState.userInputInclinedPullUps} minValue={0} maxValue={50}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.inclinedPullUps != ""} />
            </div>
        </div>
    );
}

export default MuscularEndurance;
