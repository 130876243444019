import React, { useState, useEffect, useRef } from 'react';

import './style.css';
import { ListOfUsersComponent } from '../../superAdmin/organisationList';

export const AccessUserComponent = () => {
    return (
        <div className="access-user-container">
            <ListOfUsersComponent type="organisationAdmin" />
        </div>
    )
}