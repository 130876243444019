import React, { useState, useEffect } from 'react';

import './style.css';

const SignupStageFour = ({ setSignupStage, backStage }) => {

    const [selectedUnit, setSelectedUnit] = useState('');

    return (
        <React.Fragment>
            <div className="signup-wrapper signup-stage-four">
                <div className="signup-title">
                    Units
                </div>

                <div className="signup-box">
                    <div className="signup-box-container">
                        <span className="unit-title">What is your preferred system of measurement?</span>

                        <div className="unit-selection-container">
                            <div className={"unit-box" + (selectedUnit == 'metric' ? ' selected' : '')} onClick={() => setSelectedUnit('metric')}>
                                <span className="unit-value">165 cm</span>
                                <span className="unit-name">Metric</span>
                            </div>

                            <div className={"unit-box" + (selectedUnit == 'imperial' ? ' selected' : '')} onClick={() => setSelectedUnit('imperial')}>
                                <span className="unit-value">5' 4"</span>
                                <span className="unit-name">Imperial</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="signup-next-button">
                    <input type="submit" value="Previous" onClick={() => {
                        backStage();
                    }} />
                    <input className={selectedUnit == '' ? 'disabled' : ''} disabled={selectedUnit == ''} type="submit" value="Submit" 
                    onClick={() => setSignupStage()} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default SignupStageFour;