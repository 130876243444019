import React from 'react';

// REDUX
import { useSelector } from 'react-redux';

// MATERIAL UI
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const AccountDetailsSelectField = ({ onChangeHandler, labelText, defaultMenuItem, menuItems, accountDetailsValue }) => {

    const organisationStateLocal = useSelector(state => state.organisationState);

    const renderMenuItems = () => {
        var domObj = [];

        menuItems.forEach((value, index) => {
            domObj.push(
                <MenuItem value={value}>
                    <span>{value}</span>
                </MenuItem>
            )
        });

        return domObj;
    }

    const useStyles = makeStyles({
        icon: {
            background: organisationStateLocal.organisationPrimaryColor != "" ? organisationStateLocal.organisationPrimaryColor + "!important" : "#9a62b3 !important"
        },
    });

    const classes = useStyles();

    return (
        <div className="input-container">
            <div className="input-label">{labelText}</div>
            <Select
                onChange={e => onChangeHandler(e.target.value)}
                data-type={"organization-type"}
                value={accountDetailsValue}
                displayEmpty
                defaultValue={defaultMenuItem}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                renderValue={selected => {
                    if (selected) {
                        return selected;
                    } else {
                        return <span className="disabled-option">Select a type</span>;
                    }
                }}>
                <MenuItem disabled value="">
                    <span className="disabled-option">Select a type</span>
                </MenuItem>
                {renderMenuItems()}
            </Select>

        </div>
    )
}

export default AccountDetailsSelectField;