import { 
    SET_NORM_NAME_FILTER,
    SET_NORM_AGE_FILTER,
    SET_NORM_GENDER_FILTER
} from '../adminConstants.js';

export const setNormNameFilter = (input) => {
    return {
        type: SET_NORM_NAME_FILTER,
        payload: input
    }
}

export const setNormGenderFilter = (input) => {
    return {
        type: SET_NORM_GENDER_FILTER,
        payload: input
    }
}

export const setNormAgeFilter = (input) => {
    return {
        type: SET_NORM_AGE_FILTER,
        payload: input
    }
}