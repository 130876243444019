import {
    CHANGE_SCREEN,
    CHANGE_SELECTED_SPORT,
    SET_USER_ID,
    SET_FIRST_TIME_VIEWING_TEST,
    SET_TEST_TOKEN_ID,
    SET_USER_PASSWORD,
    SET_USER_BIRTH_MONTH,
    SET_USER_BIRTH_YEAR,
    SET_USER_FITNESS_AGE,
    SET_USER_GENDER,
    SET_USER_CLASS_NAME,
    SET_USER_ROLE,
    SET_USER_AGE,
    SET_USER_VIEWING_TEST,
    SET_USER_ORGANISATION_NAME,
    SET_USER_PREVIOUS_TESTS,
    SET_USER_BACKEND_ID,
    SET_UOM,
    RESET_USER_DETAILS,
    SET_USER_EDITING_TEST,
    SET_USER_BIRTH_DAY,
    SET_USER_ADDRESS_COUNTRY,
    SET_USER_ADDRESS_STATE,
    SET_USER_EMAIL,
    SET_TOKEN_ALLOCATION_TYPE
} from '../constants';

const resettedState = {
    screen: 1,
    selectedSport: 0,
    userId: '',
    firstTimeViewingTest: false,
    testTokenId: '',
    password: '',
    userBirthMonth: -1,
    userBirthYear: -1,
    userFitnessAge: 0.0,
    userGender: '',
    userClassName: '',
    userRole: '',
    fetchController: null,
    userAge: '',
    userViewingTest: false,
    userOrganisationName: '',
    userPreviousTests: [],
    userBackendId: '',
    userUOM: '',
    tokenAllocationType: ''
}

const initialState = {
    screen: parseInt(localStorage.getItem("screen")) || 1,
    selectedSport: parseInt(localStorage.getItem("selectedSport")) || 0,
    userId: localStorage.getItem("userId") || '',
    firstTimeViewingTest: localStorage.getItem("firstTimeViewingTest") || false,
    testTokenId: localStorage.getItem("testTokenId") || '',
    password: localStorage.getItem("password") || '',
    userBirthDay: parseInt(localStorage.getItem("userBithDay")) || -1,
    userBirthMonth: parseInt(localStorage.getItem("userBirthMonth")) || -1,
    userBirthYear: parseInt(localStorage.getItem("userBirthYear")) || -1,
    userFitnessAge: parseFloat(localStorage.getItem('userFitnessAge')) || 0.0,
    userGender: localStorage.getItem("gender") || '',
    userClassName: localStorage.getItem("userClassName") || '',
    userRole: localStorage.getItem("userRole") || '',
    fetchController: new AbortController,
    userAge: localStorage.getItem("userAge") || "",
    userViewingTest: localStorage.getItem("userViewingTest") || false,
    userEditingTest: localStorage.getItem("userEditingTest") || '',
    userOrganisationName: localStorage.getItem("userOrganisationName") || '',
    addressCountry: localStorage.getItem("addressCountry") || '',
    addressState: localStorage.getItem("addressState") || '',
    userEmail: localStorage.getItem("userEmail") || '',
    userPreviousTests: localStorage.getItem("userPreviousTests") || [],
    userBackendId: localStorage.getItem("userBackendId") || '',
    userUOM: localStorage.getItem("userUOM") || '',
    tokenAllocationType: localStorage.getItem("tokenAllocationType") || ""
}


export const eliteAthletesProfilesRuducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CHANGE_SELECTED_SPORT:
            localStorage.setItem("selectedSport", action.payload);
            return { ...state, selectedSport: action.payload };
        default:
            return state;
    }
}

export const userReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CHANGE_SCREEN:
            localStorage.setItem("screen", action.payload);
            return { ...state, screen: action.payload };
        case RESET_USER_DETAILS:
            state = resettedState;
            return resettedState;
        case SET_USER_ID:
            localStorage.setItem("userId", action.payload);
            return { ...state, userId: action.payload };
        case SET_TEST_TOKEN_ID:
            localStorage.setItem("testTokenId", action.payload);
            return { ...state, testTokenId: action.payload };
        case SET_FIRST_TIME_VIEWING_TEST:
            localStorage.setItem("firstTimeViewingTest", action.payload);
            return { ...state, firstTimeViewingTest: action.payload };
        case SET_USER_EDITING_TEST:
            localStorage.setItem("userEditingTest", action.payload);
            return { ...state, userEditingTest: action.payload };
        case SET_USER_PASSWORD:
            localStorage.setItem("password", action.payload);
            return { ...state, password: action.payload };
        case SET_USER_BIRTH_DAY:
            localStorage.setItem("userBirthDay", action.payload);
            return { ...state, userBirthDay: action.payload };
        case SET_USER_BIRTH_MONTH:
            localStorage.setItem("userBirthMonth", action.payload);
            return { ...state, userBirthMonth: action.payload };
        case SET_USER_BIRTH_YEAR:
            localStorage.setItem("userBirthYear", action.payload);
            return { ...state, userBirthYear: action.payload };
        case SET_USER_FITNESS_AGE:
            localStorage.setItem("userFitnessAge", action.payload);
            return { ...state, userFitnessAge: action.payload };
        case SET_USER_GENDER:
            localStorage.setItem("gender", action.payload);
            return { ...state, userGender: action.payload };
        case SET_USER_CLASS_NAME:
            localStorage.setItem("userClassName", action.payload);
            return { ...state, userClassName: action.payload };
        case SET_USER_ROLE:
            localStorage.setItem("userRole", action.payload);
            return { ...state, userRole: action.payload };
        case SET_USER_AGE:
            localStorage.setItem("userAge", action.payload);
            return { ...state, userAge: action.payload };
        case SET_USER_VIEWING_TEST:
            localStorage.setItem("userViewingTest", action.payload);
            return { ...state, userViewingTest: action.payload };
        case SET_USER_ORGANISATION_NAME:
            localStorage.setItem("userOrganisationName", action.payload);
            return { ...state, userOrganisationName: action.payload };
        case SET_USER_ADDRESS_COUNTRY:
            localStorage.setItem("addressCountry", action.payload);
            return { ...state, addressCountry: action.payload };
        case SET_USER_ADDRESS_STATE:
            localStorage.setItem("addressState", action.payload);
            return { ...state, addressState: action.payload };
        case SET_USER_EMAIL:
            localStorage.setItem("userEmail", action.payload);
            return { ...state, userEmail: action.payload };
        case SET_USER_PREVIOUS_TESTS:
            localStorage.setItem("userPreviousTests", action.payload);
            return { ...state, userPreviousTests: action.payload };
        case SET_USER_BACKEND_ID:
            localStorage.setItem("userBackendId", action.payload);
            return { ...state, userBackendId: action.payload };
        case SET_UOM:
            localStorage.setItem("userUOM", action.payload);
            return { ...state, userUOM: action.payload };
        case SET_TOKEN_ALLOCATION_TYPE:
            localStorage.setItem("tokenAllocationType", action.payload);
            return { ...state, tokenAllocationType: action.payload }
        default:
            return state;
    }
}