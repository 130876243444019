import React from "react";

const SittingHeight4Svg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>sitting_height_4</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="sitting_height_4" data-name="sitting height 4">
            <circle className="cls-2" style={{fill: color}} cx="297.59" cy="97.39" r="38.59"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M306.79,325.58" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M296.41,327.28" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M408.21,354.78H384.83V62.5h23.38a5,5,0,0,0,0-10H351.46a5,5,0,0,0,0,10h23.37V354.78H351.46a5,5,0,0,0,0,10h56.75a5,5,0,1,0,0-10Z"
                  transform="translate(-2.83 -7.46)"/>
            <rect className="cls-2" style={{fill: color}} x="195.7" y="360.12" width="143.95" height="120.41" rx="18.85" ry="18.85"/>
            <path className="cls-2" style={{fill: color}}
                  d="M278.81,161.86,197.1,306.2c-4.68,8.25-1.38,19.29,7.35,24.6a18.6,18.6,0,0,0,14.25,2.26,15.91,15.91,0,0,0,9.94-7.57l81.71-144.34c4.68-8.26,1.38-19.29-7.35-24.6a19.18,19.18,0,0,0-9.89-2.82,17.72,17.72,0,0,0-4.36.55A16,16,0,0,0,278.81,161.86Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M332.44,304.56c.14-27.51-22.2-50.71-3.57-125.65,5.34-21.45-22.86-42.12-38.59-28a21.84,21.84,0,0,1,14.27,3c10.1,6.15,13.88,19,8.41,28.65l-58.3,103A283.56,283.56,0,0,0,259,317.39H236.67L231.25,327a18.91,18.91,0,0,1-11.79,9,20.52,20.52,0,0,1-5.12.64,22.13,22.13,0,0,1-11.45-3.25,22.61,22.61,0,0,1-10.66-16H171.89a22.5,22.5,0,0,0-22.5,22.5V451.33a22.5,22.5,0,1,0,45,0V362.39h95.27c1,0,2,0,2.9,0h.52c.49,0,1,0,1.44-.05l.25,0a22.29,22.29,0,0,0,4.66-.84,34.73,34.73,0,0,0,18.47-11.09h0a42,42,0,0,0,3.86-5c6.89-10.39,10.49-24.87,10.68-38.18h0C332.42,306.34,332.44,305.45,332.44,304.56Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default SittingHeight4Svg;
