import React from "react";

const ForearmLength2Svg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Forearm Length</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="forearm_length_2" data-name="forearm length 2">
            <circle className="cls-2" style={{fill: color}} cx="320.24" cy="112.38" r="56.49"/>
            <path className="cls-2" style={{fill: color}} d="M313.74,213.49" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M323.06,435" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M307.88,437.49" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M313.74,213.49" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M339.53,359.21a4.19,4.19,0,0,0-4.1,4.28v11.66H186.66V363.49a4.1,4.1,0,1,0-8.2,0v31.86a4.1,4.1,0,1,0,8.2,0V383.7H335.43v11.65a4.1,4.1,0,1,0,8.19,0V363.49A4.19,4.19,0,0,0,339.53,359.21Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M313.69,193.06a27.22,27.22,0,0,0-27.19,27.19v83.09H203.76a27.19,27.19,0,1,0,0,54.37H313.69a27.22,27.22,0,0,0,27.19-27.18V220.25A27.22,27.22,0,0,0,313.69,193.06Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M355.39,229.65c8.23-31.93-38.05-62.63-60-35.85-14.58,17.75-34.11,59.7-43.86,106.54h32V220.25a30.19,30.19,0,1,1,60.38,0V330.53a30.22,30.22,0,0,1-30.19,30.18H245.46q.14,5.76.58,11.44h86.39v-8.66a7.1,7.1,0,1,1,14.19,0v31.86a7.1,7.1,0,1,1-14.19,0V386.7h-85c6.31,61.18,18,89.8,54.11,97.42,38.67,8.17,58.91-38.18,59.11-77S328.11,335.47,355.39,229.65Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default ForearmLength2Svg;
