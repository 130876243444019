import React from "react";

const SittingHeight1Svg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Sitting Height</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="sitting_height_1" data-name="sitting height 1">
            <circle className="cls-2" style={{fill: color}} cx="297.59" cy="119.22" r="38.59"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M306.79,325.58" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M296.41,327.28" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M300.42,174.23" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M408.21,354.78H384.83V94.08h23.38a5,5,0,0,0,0-10H351.46a5,5,0,0,0,0,10h23.37v260.7H351.46a5,5,0,0,0,0,10h56.75a5,5,0,1,0,0-10Z"
                  transform="translate(-2.83 -7.46)"/>
            <rect className="cls-2" style={{fill: color}} x="195.7" y="360.12" width="143.95" height="120.41" rx="18.85" ry="18.85"/>
            <path className="cls-2" style={{fill: color}}
                  d="M278.77,182.68,197.06,311.93a13.58,13.58,0,0,0-1.62,10.79,17.88,17.88,0,0,0,8.95,10.94c8.74,4.76,19.63,2.64,24.28-4.73l81.72-129.26A13.56,13.56,0,0,0,312,188.89,17.85,17.85,0,0,0,303.06,178a20.8,20.8,0,0,0-9.92-2.54C287.26,175.41,281.75,178,278.77,182.68Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M332.4,308.2l0-1h-.09c-1.57-23.37-21.32-45.15-3.47-109.42,5.31-19.12-22.57-37.53-38.35-25.23a23.68,23.68,0,0,1,14,2.73,20.94,20.94,0,0,1,10.43,12.82,16.6,16.6,0,0,1-2,13.15l-58.26,92.15c.91,9,2,17,3.38,24H239.52l-8.31,13.14c-3.53,5.59-10.05,8.65-16.94,8.65A23.64,23.64,0,0,1,203,336.29a20.91,20.91,0,0,1-10.43-12.82,17,17,0,0,1-.45-6.08H171.89a22.5,22.5,0,0,0-22.5,22.5V451.33a22.5,22.5,0,1,0,45,0V362.39h95.27c1,0,2,0,2.9,0h.52c.49,0,1,0,1.44-.05l.25,0a22.59,22.59,0,0,0,4.61-.83,35.5,35.5,0,0,0,12.2-5.42c13.68-8.12,20.77-28.14,20.86-45.74C332.45,309.62,332.43,308.91,332.4,308.2Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default SittingHeight1Svg;
