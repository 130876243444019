import moment from 'moment';

export const ConvertToFeetAndInches = value => {
    var num = 0;

    num = parseFloat((value / 12)).toFixed(3).split(".")[0]
        + "'" + parseFloat((value % 12)).toFixed(3).split(".")[0]
        + (parseFloat((value % 12)).toFixed(3).split(".")[1] != undefined && parseFloat((value % 12)).toFixed(3).split(".")[1].charAt(0) < 5 ? "" : ".5")
        + "\"";

    return num;
}

// Converts the value to feet and inches and gets feet
export const ConvertToFeetAndInchesAndGetFeet = value => {
    var num = 0;

    num = parseFloat((value / 12)).toFixed(3).split(".")[0];

    return num;
}

// Converts the value to feet and inches and gets inches
export const ConvertToFeetAndInchesAndGetInches = value => {
    var num = 0;

    num = parseFloat((value % 12)).toFixed(3).split(".")[0]
        + (parseFloat((value % 12)).toFixed(3).split(".")[1] != undefined && parseFloat((value % 12)).toFixed(3).split(".")[1].charAt(0) < 5 ? "" : ".5");

    return num;
}

export const GenerateFilterString = (isFirstQuery, reduxState) => {
    var filters = "";
    if (isFirstQuery) filters += "?thing=true";
    if (reduxState.organisationState.genderFilters.length != 0) {
        filters += "&gender=" + reduxState.organisationState.genderFilters;
    }
    if (reduxState.organisationState.groupNameFilters.length != 0) {
        filters += "&groups=" + btoa("[" + reduxState.organisationState.groupNameFilters.map(e => e) + "]");
    }
    if (reduxState.organisationState.ageFilters.length != 0) {
        filters += "&ages=" + btoa("[" + reduxState.organisationState.ageFilters.map(e => "\"" + e + "\"") + "]");
    }
    if (reduxState.organisationState.dateRangeFromFilter.length != 0) {
        filters += "&startDate=" + moment(reduxState.organisationState.dateRangeFromFilter).format('DD/MM/YYYY');
    }
    if (reduxState.organisationState.dateRangeToFilter.length != 0) {
        filters += "&endDate=" + moment(reduxState.organisationState.dateRangeToFilter).format('DD/MM/YYYY');
    }

    return filters;
}

// New function for generating filter parameters
// Old function to be deprecated
export const GenerateFilterParams = (reduxState) => {
    var params = new URLSearchParams();
    if (reduxState.organisationState.genderFilters.length != 0) params.append("gender", reduxState.organisationState.genderFilters);
    if (reduxState.organisationState.groupNameFilters.length != 0) params.append("groups", btoa("[" + reduxState.organisationState.groupNameFilters.map(e => e) + "]"));
    if (reduxState.organisationState.ageFilters.length != 0) params.append("ages", btoa("[" + reduxState.organisationState.ageFilters.map(e => "\"" + e + "\"") + "]"));
    if (reduxState.organisationState.dateRangeFromFilter.length != 0) params.append("startDate", moment(reduxState.organisationState.dateRangeFromFilter).format('DD/MM/YYYY'));
    if (reduxState.organisationState.dateRangeToFilter.length != 0) params.append("endDate", moment(reduxState.organisationState.dateRangeToFilter).format('DD/MM/YYYY'));

    return params;
}

export const GenerateFilterWithSportsArrayParams = (reduxState, sports) => {
    var params = GenerateFilterParams(reduxState);
    params.append("sportnamearray", sports)

    return params;
}

export const GetOrganisationIDFromURL = () => {
    var search = window.location.pathname;
    var organisationId = "";

    if (search.indexOf("/") != -1) {
        var searchURLSplitted = search.split("/");

        if (searchURLSplitted.length > 1) {
            organisationId = searchURLSplitted[1];
        }
    }

    return organisationId;
};

// Retrieves the JSON of a user, with the exception of an object being injected into the function
// If an object is present, it will use the values of that instead for specific properties from the user state
export const retrieveJSONOfUser = (obj, state, usingB2CAthlete, IM) => {
    var jsonRequest = "";
    var userGender = state.user.userGender || "unspecified";
    var userFirstName = state.user.userId || "";
    var userClassName = state.user.userClassName || "";
    var uom = state.user.userUOM;

    var motherHeight = uom == "Imperial" ? IM(state.myDetails.mothersHeight).from('in').to('cm') : state.myDetails.mothersHeight;
    var fatherHeight = uom == "Imperial" ? IM(state.myDetails.fathersHeight).from('in').to('cm') : state.myDetails.fathersHeight;

    var userBirthMonth = state.user.userBirthMonth;
    var userBirthYear = state.user.userBirthYear;

    var userEmail = state.user.userEmail;
    var userAddressState = state.user.addressState;
    var userAddressCountry = state.user.addressCountry;

    if (obj.name == "gender") {
        userGender = obj.value;
    }

    if (obj.name == "mother_height") {
        motherHeight = obj.value;
    }

    if (obj.name == "father_height") {
        fatherHeight = obj.value;
    }

    if (obj.name == "email") userEmail = obj.value;
    if (obj.name == "address_state") userAddressState = obj.value;
    if (obj.name == "address_country") userAddressCountry = obj.value;

    jsonRequest = {
        "user_id": typeof state.user.userBackendId !== 'undefined' && state.user.userBackendId !== "" ? state.user.userBackendId : '2' + "", //field used to update the specific row in the db
        "username": userFirstName + "",
        "user_birth_month": userBirthMonth + "",
        "user_birth_year": userBirthYear + "",
        'gender': userGender + "",
        "user_class_name": userClassName + "",
        "mother_height": motherHeight + "",
        "father_height": fatherHeight + "",
    }

    if (usingB2CAthlete) {
        jsonRequest['user_email'] = userEmail + "";
        jsonRequest['user_address_state'] = userAddressState + "";
        jsonRequest['user_address_country'] = userAddressCountry + "";
    }

    return jsonRequest;
}

export const checkIfNullOrStringEmptyOrMinusOne = (value) => {
    if (value != null && value !== '' && value !== '-1') return false;

    return true;
}

export const toFixedNoRounding = (num, fixed) => {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
}