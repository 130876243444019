import React, { useState, useEffect } from 'react';

export const NormsRow = ({normObj: normObj, onChangeHandler: onChangeHandler}) => {
    const renderNormData = object => {
        var dom = [];

        var keys = Object.keys(object);

        for (var x = 1; x < keys.length; x++) {
            dom.push(
                <input onChange={(e) => onChangeHandler(e.target.getAttribute("fieldType"), normObj.norm_id, e.target.value)} type="text" fieldType={keys[x]} defaultValue={object[keys[x]]} className="norm-data-cell" />
            )
        }

        return dom;
    }

    return (
        <div style={{ display: 'flex' }}>{renderNormData(normObj)}</div>
    )
}