import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConvertToFeetAndInches, ConvertToFeetAndInchesAndGetFeet, ConvertToFeetAndInchesAndGetInches } from "../../helper/helper";
import FieldInfo from "../FieldInfo";
import styled from 'styled-components';

const IM = require("convert-units");

export const ParentSliderField = ({ infoOpen, setInfoOpen: setInfoOpen, label, usingUOM, action, info, enteredValue, minValue, maxValue }) => {
    const [value, setValue] = useState(0);
    const [labelVal, setlabelVal] = useState(label);
    const [isChecked, setIsChecked] = useState(false);
    const organisationLocalState = useSelector(state => state.organisationState);
    const usingImperial = useSelector(state => state.user.userUOM == "Imperial");

    const [editFeetInches, setEditFeetInches] = useState(false);

    var [feetInput, setFeetInput] = useState(ConvertToFeetAndInchesAndGetFeet(value));
    var [inchesInput, setInchesInput] = useState(ConvertToFeetAndInchesAndGetInches(value));

    useEffect(() => {
        let convertedValue = IM(enteredValue).from('in').to('cm') == "-1" ? "-1" : enteredValue;
        setValue(convertedValue != "-1" ? convertedValue : ((maxValue + minValue) / 2));
        setIsChecked(convertedValue == "-1");

        Array.from(document.getElementsByClassName("myDetails-field-slider")).forEach(elem => {
            elem.style.setProperty("--value", organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3')
        })
    }, []);

    /** When the user clicks on the "I don't know" radio button for either field */
    const dontKnowChecked = (element) => {
        var valueChecked = element.target.checked;
        setIsChecked(valueChecked);
        if (valueChecked) {
            setValue((maxValue + minValue) / 2);
            action(usingImperial ? IM("-1").from('cm').to('in') : "-1");
        } else {
            action((maxValue + minValue) / 2);
        }
    };

    const FaderSpan = styled.span`
    &:hover {
        background-color: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
     }`;

    return (
        <div className="myDetails-field parent-slider">
            <div className="myDetails-field-title">
                {label}
                {info ? <div onClick={() => infoOpen === labelVal ? setInfoOpen("") : setInfoOpen(labelVal)} className="myDetails-field-info" style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3' }}><i className="fa fa-info-circle"></i><div style={{ display: infoOpen === labelVal ? 'block' : 'none' }} className="myDetails-info-pointer"></div></div> : ""}
                {info && infoOpen === labelVal ? <FieldInfo info={info} /> : ""}
            </div>
            <input
                onMouseDown={() => setEditFeetInches(false)}
                disabled={isChecked}
                onMouseUp={action ? (e) => {
                    var val = e.target.value;
                    setFeetInput(ConvertToFeetAndInchesAndGetFeet(val));
                    setInchesInput(ConvertToFeetAndInchesAndGetInches(val));
                    action(val);
                } : null}
                onTouchEnd={action ? (e) => {
                    var val = e.target.value;
                    setFeetInput(ConvertToFeetAndInchesAndGetFeet(val));
                    setInchesInput(ConvertToFeetAndInchesAndGetInches(val));
                    action(val);
                } : null}
                onChange={(e) => setValue(e.target.value)}
                className="myDetails-field-slider"
                type="range" min={minValue} max={maxValue} value={value} />

            {editFeetInches && usingUOM == "Imperial" ?
                <>
                    <span style={{ fontSize: '14px', marginLeft: '12px' }}>Ft:</span>
                    <input
                        onChange={(e) => {
                            setFeetInput(e.target.value);
                            setValue((e.target.value * 12) + parseFloat(inchesInput));
                            action((e.target.value * 12) + parseFloat(inchesInput));
                        }}
                        className="myDetails-field-slider-value feet-inches-control"
                        min={0}
                        max={7}
                        type="number" defaultValue={feetInput} step={1} />
                    <span style={{ fontSize: '14px', marginLeft: '8px' }}>In:</span>
                    <input
                        onChange={(e) => {
                            setInchesInput(e.target.value);
                            setValue(parseFloat(e.target.value) + parseFloat(feetInput * 12));
                            action(parseFloat(e.target.value) + parseFloat(feetInput * 12));
                        }}
                        min={0}
                        max={11.5}
                        className="myDetails-field-slider-value feet-inches-control"
                        type="number" defaultValue={inchesInput} step={0.5} />
                </>

                : <input
                    onClick={() => setEditFeetInches(true)}
                    style={{ opacity: isChecked ? '0' : '1' }}
                    disabled={isChecked}
                    onChange={(e) => {
                        setValue(e.target.value);
                        action(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === 189) {
                            e.preventDefault();
                        }
                    }}
                    min={minValue}
                    max={maxValue}
                    onBlur={action ? (e) => action(e.target.value) : null}
                    className="myDetails-field-slider-value parent-control"
                    type={usingUOM == "Imperial" ? "text" : "number"}
                    value={usingUOM == "Imperial" ? ConvertToFeetAndInches(value) : value} />
            }

            <label className="parent-height-dontknow">
                <input onChange={e => dontKnowChecked(e)} id="parent-height-dontknow-input" type="checkbox" checked={isChecked} name={"parent-height-unsure-" + label} value="Don't know" />
                <FaderSpan className="checkmark" style={{ border: organisationLocalState.organisationPrimaryColor != '' ? '3px solid ' + organisationLocalState.organisationPrimaryColor : '3px solid #9a62b3', background: isChecked ? (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') : '' }}></FaderSpan>
                <label className="parent-height-dontknow-label" for={"parent-height-unsure-" + label}>Don't know</label>
            </label>
        </div>
    );
}