import React from 'react';
import { useSelector } from "react-redux";

const SportMatchBasicProblem = ({ label, items }) => {
    const organisationLocalState = useSelector(state => state.organisationState);

    const renderItems = () => {
        var domObj = [];

        items.forEach((value, index) => {
            domObj.push(
                <div className="item-container">
                    <i class="fa fas fa-star" style={{ color: organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : ''}}></i>
                    <span className="item-label">{value}</span>
                </div>
            )
        });

        return domObj;
    }
    return (
        <div className="sportmatch-plan-container">
            <div className="heading-label">{label}</div>
            <div className="sportmatch-plan-items-container">
                {renderItems()}
            </div>
        </div>
    )
}

export default SportMatchBasicProblem;
