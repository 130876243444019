import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

import enAU from 'date-fns/locale/en-AU';

// STYLE
import styled from 'styled-components';

import {
    setFilterAges,
    setFilterGroupNames,
    setFilterGender,
    setDateRangeFromFilter,
    setDateRangeToFilter
} from '../../../../store/actions/OrganisationActions.js';
import { setNormAdminTest, setNormAgeFilter, setNormGenderFilter, setNormNameFilter } from '../../../../store/actions/adminActions.js';

const FilterComponent = ({ type, showFilterFunction, show, listOfGroups, normNames }) => {
    var enAULocale = {
        ...enAU,
        options: {
            weekStartsOn: 1,
            firstWeekContainsDate: 1
        }
    }

    const dispatch = useDispatch(); // Redux dispatch

    const reduxState = useSelector(state => state); // Redux state

    const [genderFilter, setGenderFilter] = useState("all"); // Gender fields (local)

    const [dateRangeFrom, setDateRangeFrom] = useState(reduxState.organisationState.dateRangeFromFilter);
    const [dateRangeTo, setDateRangeTo] = useState(reduxState.organisationState.dateRangeToFilter);

    // Age fields
    const [ageSelections, setAgeSelections] = useState(reduxState.organisationState.ageFilters);
    const [groupNameSelections, setGroupNameSelections] = useState(reduxState.organisationState.groupNameFilters);

    // Admin fields
    const [ageAdminSelections, setAgeAdminSelections] = useState([]);
    const [normNameAdminSelections, setNormNameAdminSelections] = useState([]);

    const organisationLocalState = useSelector(state => state.organisationState);

    const FaderInput = styled.input`
         &::after {
             border: ${ '2.6px solid ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') };
             background: white;
          }
         &:checked::after {
            border: ${ '3px solid ' + (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3') };
            background: ${organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'};
          }`;

    const ageChangeHandler = (age, checked) => {
        var arrayOfAges = [];

        if (type != "admin") {
            arrayOfAges = ageSelections;
        } else {
            arrayOfAges = ageAdminSelections;
        }

        if (checked) {
            if (arrayOfAges.indexOf(age) === -1) {
                arrayOfAges.push(age);
            }
        } else {
            if (arrayOfAges.indexOf(age) != -1) {
                var index = arrayOfAges.indexOf(age);
                arrayOfAges.splice(index, 1);
            }
        }
        if (arrayOfAges.length > 0) {
            document.getElementById("age-not-all-radio").checked = true;
        } else {
            document.getElementById("age-all-radio").checked = true;
        }

        if (type != "admin") {
            setAgeSelections(arrayOfAges);
            dispatch(setFilterAges(arrayOfAges));
        } else {
            setAgeAdminSelections(arrayOfAges);
            dispatch(setNormAgeFilter(arrayOfAges));
        }

    };

    const renderAges = () => {
        var dom = [];

        for (var i = 8; i <= 18; i++) {
            dom.push(
                <div>
                    <FaderInput defaultChecked={ageSelections.includes(i + "") || ageAdminSelections.includes(i + "")} onChange={(e) => ageChangeHandler(e.target.value, e.target.checked)} id={`age-${i}`} className="age-checkbox" type="checkbox" value={i} />
                    <label htmlFor={`age-${i}`}>{i}</label>
                </div>
            )
        }

        return dom;
    };

    const modifyNamesToFilter = (name, checked) => {
        var arrayOfNames = [];

        if (type != "admin") {
            arrayOfNames = groupNameSelections;
        } else {
            arrayOfNames = normNameAdminSelections;
        }

        if (checked) {
            if (arrayOfNames.indexOf(name) === -1) {
                arrayOfNames.push(name);
            }
        } else {
            if (arrayOfNames.indexOf(name) != -1) {
                var index = arrayOfNames.indexOf(name);
                arrayOfNames.splice(index, 1);
            }
        }

        if (arrayOfNames.length > 0) {
            document.getElementById("not-all-selected-radio").checked = true;
        } else {
            if(type != "admin") {
                document.getElementById("name-all-radio-btn").checked = true;
            } else {
                document.getElementById("norm-name-all-radio").checked = true;
            }
        }

        if (type != "admin") {
            setGroupNameSelections(arrayOfNames);
            dispatch(setFilterGroupNames(arrayOfNames));
        } else {
            setNormNameAdminSelections(arrayOfNames);
            dispatch(setNormNameFilter(arrayOfNames));
        }
    };

    const allNamesRadioChangeHandler = (checked) => {
        if (checked) {
            setNormNameAdminSelections([]);
            dispatch(setNormNameFilter([]));
            document.getElementById("norm-name-all-radio").checked = true;

            var nameCheckboxElements = document.getElementsByClassName("name-checkbox");
            Array.from(nameCheckboxElements).forEach(e => {
                e.checked = false;
            });
        }
    };

    const allGroupNameRadioChangeHandler = (checked) => {
        if (checked) {
            setGroupNameSelections([]);
            dispatch(setFilterGroupNames([]));
            document.getElementById("name-all-radio-btn").checked = true;

            var groupCheckboxElements = document.getElementsByClassName("group-checkbox");
            Array.from(groupCheckboxElements).forEach(e => {
                e.checked = false;
            });
        }
    };

    const allAgeRadioChangeHandler = (checked) => {
        if (checked) {
            if (type != "admin") {
                dispatch(setFilterAges([]));
                setAgeSelections([]);
            } else {
                dispatch(setNormAgeFilter([]));
                setAgeAdminSelections([])
            }

            document.getElementById("age-all-radio").checked = true;

            var ageCheckBoxElements = document.getElementsByClassName("age-checkbox");
            Array.from(ageCheckBoxElements).forEach(e => {
                e.checked = false;
            });
        }
    };

    const renderGroups = () => {
        var dom = [];

        if (type != "admin") {
            for (var i = 0; i < listOfGroups.length; i++) {
                dom.push(
                    <div className="group-container">
                        <FaderInput defaultChecked={reduxState.organisationState.groupNameFilters.includes(listOfGroups[i])} className="group-checkbox" onChange={(e) => modifyNamesToFilter(e.target.value, e.target.checked)} id={`group-${listOfGroups[i]}`} type="checkbox" value={`${listOfGroups[i]}`} />
                        <label for={`group-${listOfGroups[i]}`} >{listOfGroups[i]}</label>
                    </div>
                )
            }
        } else {
            for (var i = 0; i < normNames.length; i++) {
                dom.push(
                    <div className="norm-names-container">
                        <input defaultChecked={reduxState.adminState.normNameFilter.includes(normNames[i])} className="name-checkbox" onChange={(e) => modifyNamesToFilter(e.target.value, e.target.checked)} id={`group-${normNames[i]}`} type="checkbox" value={`${normNames[i]}`} />
                        <label for={`norm-${normNames[i]}`} >{normNames[i]}</label>
                    </div>
                )
            }
        }

        return dom;
    };

    const resetOnClick = () => {
        if (type != "admin") {
            setGenderFilter("all");
            setDateRangeFrom("");
            setDateRangeTo("");
            setAgeSelections([]);
            setGroupNameSelections([]);
            dispatch(setFilterGender(""));
            dispatch(setFilterGroupNames([]));
            dispatch(setDateRangeFromFilter(""));
            dispatch(setDateRangeToFilter(""));
            dispatch(setFilterAges([]));

            // Visually deselecting the elements
            var groupCheckboxElements = document.getElementsByClassName("group-checkbox");
            Array.from(groupCheckboxElements).forEach(e => {
                e.checked = false;
            });

            document.getElementById("name-all-radio-btn").checked = true;
        } else {
            setAgeAdminSelections([]);
            dispatch(setNormGenderFilter([]));
            dispatch(setNormAgeFilter([]));
            dispatch(setNormNameFilter([]));

            // Visually deselecting the elements
            var nameCheckboxElements = document.getElementsByClassName("name-checkbox");
            Array.from(nameCheckboxElements).forEach(e => {
                e.checked = false;
            });

            document.getElementById("norm-name-all-radio").checked = true;
        }

        var ageSelectionElements = document.getElementsByClassName("age-checkbox");
        Array.from(ageSelectionElements).forEach(e => {
            e.checked = false;
        });

        document.getElementById("radio-gender-all").checked = true;
        document.getElementById("age-all-radio").checked = true;
    }

    const renderMultiSelectComponent = () => {
        if (type == "admin") {
            return (
                <div className="filter-options-norm-container">
                    <div className="heading-filter-norm-name">
                        <span className="filter-heading">Norm name</span>
                        <div>
                            <input defaultChecked={reduxState.organisationState.groupNameFilters.length == 0} onClick={(e) => allNamesRadioChangeHandler(e.target.checked)} id="norm-name-all-radio" name="norm-name-radio-btns" defaultChecked={true} type="radio" />
                            <label for="norm-name-all-radio">All</label>
                        </div>

                        <input id="not-all-selected-radio" style={{ display: "none" }} type="radio" name="norm-name-radio-btns" checked={groupNameSelections.length > 0 ? true : false} />
                    </div>

                    <div className="norm-select-dropdown">
                        {renderGroups()}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="filter-options-group-container">
                    <div className="heading-filter-group-name">
                        <span className="filter-heading">Group name</span>
                        <div>
                            <FaderInput defaultChecked={reduxState.organisationState.groupNameFilters.length == 0} onClick={(e) => allGroupNameRadioChangeHandler(e.target.checked)} id="name-all-radio-btn" name="group-name-radio-btns" defaultChecked={true} type="radio" />
                            <label for="name-all-radio-btn">All</label>
                        </div>

                        <input id="not-all-selected-radio" style={{ display: "none" }} type="radio" name="group-name-radio-btns" checked={groupNameSelections.length > 0 ? true : false} />
                    </div>

                    <div className="group-select-dropdown">
                        {renderGroups()}
                    </div>
                </div>
            )
        }
    }

    return (
        <div style={{width: '100%', position: 'absolute', height: '100%',  zIndex: 300, visibility: show ? 'visible' : 'hidden'}} onClick={() => showFilterFunction()}>
            <div className={"filter-options-container" + (type == "admin" ? " admin-norm-filters" : "")} style={{ opacity: show ? '100%' : '0%', visibility: show ? "visible" : "hidden", zIndex: 301 }} onClick={(e) => e.stopPropagation()}>
                <div className="filter-by-heading">
                    <span>Filter by:</span>
                </div>

                <div className="filter-options-body">
                    <div>
                        <div className="filter-options-gender-container">
                            <span className="filter-heading">Gender</span>
                            <div>
                                <FaderInput defaultChecked={reduxState.organisationState.genderFilters.includes("all") || reduxState.organisationState.genderFilters.length == 0} id="radio-gender-all" type="radio" name="gender" value="all" onClick={() => type != "admin" ? dispatch(setFilterGender("")) : dispatch(setNormGenderFilter(""))} />
                                <label for="radio-gender-all">All</label>
                            </div>
                            <div>
                                <FaderInput defaultChecked={reduxState.organisationState.genderFilters.includes("male") || reduxState.adminState.normGenderFilter.includes("male")} id="radio-male" className="gender-radiobtn" name="gender" type="radio" value="male" onClick={() => type != "admin" ? dispatch(setFilterGender("male")) : dispatch(setNormGenderFilter("male"))} />
                                <label for="radio-male">Male</label>
                            </div>
                            <div>
                                <FaderInput defaultChecked={reduxState.organisationState.genderFilters.includes("female") || reduxState.adminState.normGenderFilter.includes("female")} id="radio-female" className="gender-radiobtn" name="gender" type="radio" value="female" onClick={() => type != "admin" ? dispatch(setFilterGender("female")) : dispatch(setNormGenderFilter("female"))} />
                                <label for="radio-female">Female</label>
                            </div>
                        </div>

                        {type != "admin" ?
                            <div className="filter-options-daterange-container">
                                <span className="filter-heading">Date range</span>
                                <div>
                                    <span>From</span>
                                    <DatePicker
                                        onChange={e => {
                                            setDateRangeFrom(e);
                                            dispatch(setDateRangeFromFilter(e));
                                        }}
                                        dateFormat={'dd/MM/yyyy'}
                                        selected={dateRangeFrom}
                                    />
                                </div>

                                <div>
                                    <span>To</span>
                                    <DatePicker
                                        onChange={e => {
                                            setDateRangeTo(e);
                                            dispatch(setDateRangeToFilter(e));
                                        }}
                                        dateFormat={'dd/MM/yyyy'}
                                        selected={dateRangeTo}
                                    />
                                </div>
                            </div> : null}
                    </div>

                    <div className="filter-options-age-container">
                        <span className="filter-heading">Age</span>
                        <div>
                            <FaderInput onClick={(e) => allAgeRadioChangeHandler(e.target.checked)} id="age-all-radio" name="age-radio-btns" defaultChecked={type != "admin" ? ageSelections.length == 0 : ageAdminSelections.length == 0} type="radio" />
                            <label for="age-all-radio">All</label>
                        </div>

                        <input id="age-not-all-radio" type="radio" name="age-radio-btns" style={{ display: 'none' }} defaultChecked={ageSelections.length != 0} />

                        {renderAges()}
                    </div>
                    {renderMultiSelectComponent()}
                </div>

                {/* Reset button */}
                <div className="filter-reset-btn-container">
                    <input className="reset-filter-btn" type="button" value="Reset" onClick={() => resetOnClick()} />
                </div>
            </div>
        </div>

    )
}

export default FilterComponent;
