import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// COMPONENTS
import MyDetails from './myDetails/MyDetails.js';
import SportMatchTest from './sportMatchTest/index.js';
import CompareMyResults from './compareMyResults/CompareMyResults.js';
import FindMySport from './findMySport/FindMySport.js';
import EliteAthleteProfiles from './eliteAthleteProfiles/EliteAthleteProfiles.js';
import MyFitnessAge from './myFitnessAge/MyFitnessAge.js';
import Reports from './reports/Reports.js';
import TalentID from './organizationAdminScreens/talentID/index.js';

// ORGANIZATION ADMIN SCREENS
import Dashboard from './organizationAdminScreens/dashboard/index.js';
import AccountDetailsScreen from './organizationAdminScreens/accountDetails/index.js';
import ClassInfoScreen from './organizationAdminScreens/classInfo/index.js';
import TeacherResources from './organizationAdminScreens/Resources/index.js';
import { AccessUserComponent } from './organizationAdminScreens/accessUser/index.js';
import ResultsOverviewComponent from './organizationAdminScreens/resultsOverview/index.js';
import { EditNormComponent } from './superAdmin/editNorms/index.js';
import { ListOfUsersComponent } from './superAdmin/organisationList/index.js';
import TalentIDSuperUser from './superAdmin/talentID/index.js';
import scoutComponent from './organizationAdminScreens/scoutComponent/index.js';
import ScoutComponent from './organizationAdminScreens/scoutComponent/index.js';
import CustomWeighting from './superAdmin/customWeighting/index.js';
import { B2C_ROLE_NAME } from '../../store/constants';

const Screen = ({ setTestStartDate: setTestStartDate }) => {
    const screen = useSelector(state => state.user.screen);
    const state = useSelector(state => state);


    if (state.user.userRole === 'admin' && state.organisationState.accessUser == "") {
        switch (screen) {
            case 1:
                return (
                    <ListOfUsersComponent />
                )
            case 3:
                return (
                    <TalentIDSuperUser />
                )
            case 4:
                return (
                    <CustomWeighting />
                )
            default:
                return (
                    <EditNormComponent />
                );
        }
    } else if (state.user.userRole === 'organisation' || state.user.userRole === 'teacher') {
        switch (screen) {
            case 1:
                return <Dashboard />
            case 2:
                return <ResultsOverviewComponent />
            case 3:
                return <TeacherResources />
            case 4:
                return <ClassInfoScreen modeDisplay={"list"} type={"groups"} className="test-takers" />
            case 5:
                return <TalentID />
            case 6:
                return <AccountDetailsScreen />
            case 7:
                return <AccessUserComponent />
            case 8:
                return <ScoutComponent />
            default:
                return (
                    <div>
                        In progress....
                    </div>
                );
        }
    } else if(state.user.userRole === B2C_ROLE_NAME) {
        switch (screen) {
            case 1:
                return <SportMatchTest setTestStartDate={setTestStartDate} />
            case 2:
                return <FindMySport />
            case 3:
                return <CompareMyResults />
            case 4:
                return <EliteAthleteProfiles />
            case 5:
                return <MyFitnessAge />
            case 6:
                return <Reports />
            case 7:
                return <MyDetails setTestStartDate={setTestStartDate} />
            default:
                return (
                    <div>
                        In Progress....
                    </div>
                );
        }
    } else {
        switch (screen) {
            case 1:
                return <MyDetails setTestStartDate={setTestStartDate} />
            case 2:
                return <SportMatchTest setTestStartDate={setTestStartDate} />
            case 3:
                return <FindMySport />
            case 4:
                return <CompareMyResults />
            case 5:
                return <EliteAthleteProfiles />
            case 6:
                return <MyFitnessAge />
            case 7:
                return <Reports />
            default:
                return (
                    <div>
                        In Progress....
                    </div>
                );
        }
    }

}

export default Screen;
