import { 
    CHANGE_SCREEN, 
    CHANGE_SELECTED_SPORT, 
    SET_USER_ID, 
    SET_TEST_TOKEN_ID, 
    SET_USER_PASSWORD, 
    SET_USER_BIRTH_MONTH, 
    SET_USER_BIRTH_YEAR,
    SET_USER_FITNESS_AGE,
    SET_USER_GENDER,
    SET_USER_CLASS_NAME,
    SET_USER_ORGANISATION_NAME,
    SET_USER_ROLE,
    SET_USER_AGE,
    SET_USER_VIEWING_TEST,
    SET_FIRST_TIME_VIEWING_TEST, 
    SET_SPORT_MATCH_TEST_DATE_DAY,
    SET_SPORT_MATCH_TEST_DATE_MONTH,
    SET_SPORT_MATCH_TEST_DATE_YEAR,
    SET_ADULT_SIT_AND_REACH,
    SET_SPORT_MATCH_BODY_SHAPE_AND_SIZE_SECTION_VISITED,
    SET_SPORT_MATCH_FLEXIBILITY_SECTION_VISITED,
    SET_SPORT_MATCH_MUSCULAR_STRENGTH_AND_POWER_SECTION_VISITED,
    SET_SPORT_MATCH_MUSCULAR_ENDURANCE_SECTION_VISITED,
    SET_SPORT_MATCH_SPEED_AND_AGILITY_SECTION_VISITED,
    SET_SPORT_MATCH_AEROBIC_FITNESS_SECTION_VISITED,
    SET_ADULT_STANDING_BROAD_JUMP,
    SET_ADULT_GRIP_STRENGTH,
    SET_ADULT_LEFT_GRIP_STRENGTH,
    SET_ADULT_RIGHT_GRIP_STRENGTH,
    SET_ADULT_VERTICAL_JUMP,
    SET_ADULT_SIT_UPS,
    SET_ADULT_INCLINED_PULL_UPS,
    SET_ADULT_SHUTTLE_RUN,
    SET_ADULT_50M_SPRINT,
    SET_ADULT_WALK_RUN,
    SET_ADULT_SHUTTLE_BEEP_TEST,
    SET_ADULT_VO2_MAX,
    SET_ADULT_HEIGHT,
    SET_ADULT_WEIGHT,
    SET_ADULT_ARM_SPAN,
    SET_ADULT_FOREARM_LENGTH,
    SET_ADULT_SITTING_HEIGHT,
    SET_PREDICTED_ADULT_FITNESS_ID,
    SET_TEST_ID,
    SET_USER_PREVIOUS_TESTS,
    SET_USER_BACKEND_ID,
    RESET_APP,
    RESET_USER_DETAILS,
    SET_USER_EDITING_TEST,
    SET_FETCH_CONTROLLER, 
    SET_UOM, 
    SET_USER_BIRTH_DAY, 
    SET_USER_ADDRESS_COUNTRY, 
    SET_USER_ADDRESS_STATE, 
    SET_USER_EMAIL, 
    SET_TOKEN_ALLOCATION_TYPE
} from '../constants';

export const changeScreen = (screen) => {
    return {
        type: CHANGE_SCREEN,
        payload: screen
    }
}

export const removeScreenData = (screen) => {
    return {
        type: "REMOVE_SCREEN_DATA",
        payload: screen
    }
}

export const changeSelectedSport = (input) => {
    return {
        type: CHANGE_SELECTED_SPORT,
        payload: input
    }
}

export const setUserId = (input) => {
    return {
        type: SET_USER_ID,
        payload: input
    }
}

export const setFetchController = (input) => {
    return {
        type: SET_FETCH_CONTROLLER,
        payload: input
    }
}

export const setTestTokenId = (input) => {
    return {
        type: SET_TEST_TOKEN_ID,
        payload: input
    }
}

export const setUserPassword = (input) => {
    return {
        type: SET_USER_PASSWORD,
        payload: input
    }
}

export const setUserBirthDay = (input) => {
    return {
        type: SET_USER_BIRTH_DAY,
        payload: input
    }
}

export const setUserBirthMonth = (input) => {
    return {
        type: SET_USER_BIRTH_MONTH,
        payload: input
    }
}

export const setUserBirthYear = (input) => {
    return {
        type: SET_USER_BIRTH_YEAR,
        payload: input
    }
}

export const setUserGender = (input) => {
    return {
        type: SET_USER_GENDER,
        payload: input
    }
}

export const setUserClassName = (input) => {
    return {
        type: SET_USER_CLASS_NAME,
        payload: input
    }
}

export const setUserRole = (input) => {
    return {
        type: SET_USER_ROLE,
        payload: input
    }
}

export const setUserAge = (input) => {
    return {
        type: SET_USER_AGE,
        payload: input
    }
}

export const setUserViewingTest = (input) => {
    return {
        type: SET_USER_VIEWING_TEST,
        payload: input
    }
}

export const setUserEditingTest = (input) => {
    return {
        type: SET_USER_EDITING_TEST,
        payload: input
    }
}

export const setFirstTimeViewingTest = (input) => {
    return {
        type: SET_FIRST_TIME_VIEWING_TEST,
        payload: input
    }
}

export const setUserOrganisationName = (input) => {
    return {
        type: SET_USER_ORGANISATION_NAME,
        payload: input
    }
}

export const setUserAddressCountry = (input) => {
    return {
        type: SET_USER_ADDRESS_COUNTRY,
        payload: input
    }
}

export const setUserAddressState = (input) => {
    return {
        type: SET_USER_ADDRESS_STATE,
        payload: input
    }
}

export const setUserEmail = (input) => {
    return {
        type: SET_USER_EMAIL,
        payload: input
    }
}

export const setUserFitnessAge = (input) => {
    return {
        type: SET_USER_FITNESS_AGE,
        payload: input
    }
}

/** Actions for setting the Test Date */
export const setTestDateDay = (input) => {
    return {
        type: SET_SPORT_MATCH_TEST_DATE_DAY,
        payload: input
    }
}

export const setTestDateMonth = (input) => {
    return {
        type: SET_SPORT_MATCH_TEST_DATE_MONTH,
        payload: input
    }
}

export const setTestDateYear = (input) => {
    return {
        type: SET_SPORT_MATCH_TEST_DATE_YEAR,
        payload: input
    }
}

// SportMatch test sections visited
export const setSportMatchBodyShapeAndSizeSectionVisited = (input) => {
    return {
        type: SET_SPORT_MATCH_BODY_SHAPE_AND_SIZE_SECTION_VISITED,
        payload: input
    }
}
export const setSportMatchFlexibilitySectionVisited = (input) => {
    return {
        type: SET_SPORT_MATCH_FLEXIBILITY_SECTION_VISITED,
        payload: input
    }
}
export const setSportMatchMuscularStrengthAndPowerSectionVisited = (input) => {
    return {
        type: SET_SPORT_MATCH_MUSCULAR_STRENGTH_AND_POWER_SECTION_VISITED,
        payload: input
    }
}
export const setSportMatchMuscularEnduranceSectionVisited = (input) => {
    return {
        type: SET_SPORT_MATCH_MUSCULAR_ENDURANCE_SECTION_VISITED,
        payload: input
    }
}
export const setSportMatchSpeedAndAgilitySectionVisited = (input) => {
    return {
        type: SET_SPORT_MATCH_SPEED_AND_AGILITY_SECTION_VISITED,
        payload: input
    }
}
export const setSportMatchAerobicFitnessSectionVisited = (input) => {
    return {
        type: SET_SPORT_MATCH_AEROBIC_FITNESS_SECTION_VISITED,
        payload: input
    }
}


// Adult Values

export const setAdultSitAndReach = (input) => {
    return {
        type: SET_ADULT_SIT_AND_REACH,
        payload: input
    }
}

export const setAdultStandingBroadJump = (input) => {
    return {
        type: SET_ADULT_STANDING_BROAD_JUMP,
        payload: input
    }
}

export const setAdultGripStrength = (input) => {
    return {
        type: SET_ADULT_GRIP_STRENGTH,
        payload: input
    }
}

export const setAdultLeftGripStrength = (input) => {
    return {
        type: SET_ADULT_LEFT_GRIP_STRENGTH,
        payload: input
    }
}

export const setAdultRightGripStrength = (input) => {
    return {
        type: SET_ADULT_RIGHT_GRIP_STRENGTH,
        payload: input
    }
}

export const setAdultVerticalJump = (input) => {
    return {
        type: SET_ADULT_VERTICAL_JUMP,
        payload: input
    }
}

export const setAdultSitUps = (input) => {
    return {
        type: SET_ADULT_SIT_UPS,
        payload: input
    }
}

export const setAdultInclinedPullUps = (input) => {
    return {
        type: SET_ADULT_INCLINED_PULL_UPS,
        payload: input
    }
}

export const setAdultShuttleRun = (input) => {
    return {
        type: SET_ADULT_SHUTTLE_RUN,
        payload: input
    }
}

export const setAdult50mSprint = (input) => {
    return {
        type: SET_ADULT_50M_SPRINT,
        payload: input
    }
}

export const setAdultWalkRun = (input) => {
    return {
        type: SET_ADULT_WALK_RUN,
        payload: input
    }
}

export const setAdultShuttleBeepTest = (input) => {
    return {
        type: SET_ADULT_SHUTTLE_BEEP_TEST,
        payload: input
    }
}

export const setAdultVo2Max = (input) => {
    return {
        type: SET_ADULT_VO2_MAX,
        payload: input
    }
}

export const setAdultHeight = (input) => {
    return {
        type: SET_ADULT_HEIGHT,
        payload: input
    }
}
export const setAdultWeight = (input) => {
    return {
        type: SET_ADULT_WEIGHT,
        payload: input
    }
}
export const setAdultSittingHeight = (input) => {
    return {
        type: SET_ADULT_SITTING_HEIGHT,
        payload: input
    }
}
export const setAdultForearmLength = (input) => {
    return {
        type: SET_ADULT_FOREARM_LENGTH,
        payload: input
    }
}
export const setAdultArmSpan = (input) => {
    return {
        type: SET_ADULT_ARM_SPAN,
        payload: input
    }
}

export const setPredictedAdultFitnessId = (input) => {
    return {
        type: SET_PREDICTED_ADULT_FITNESS_ID,
        payload: input
    }
}

export const setTestId = (input) => {
    return {
        type: SET_TEST_ID,
        payload: input
    }
}

export const setUserPreviousTests = (input) => {
    return {
        type: SET_USER_PREVIOUS_TESTS,
        payload: input
    }
}

export const setUserBackendId = (input) => {
    return {
        type: SET_USER_BACKEND_ID,
        payload: input
    }
}

export const resetAdultValues = (input) => {
    return {
        type: RESET_APP,
        payload: input
    }
}


export const resetUserDetailsValues = (input) => {
    return {
        type: RESET_USER_DETAILS,
        payload: input
    }
}

export const setUserUOM = (input) => {
    return {
        type: SET_UOM,
        payload: input
    }
}

export const setTokenAllocationType = (input) => {
    return {
        type: SET_TOKEN_ALLOCATION_TYPE,
        payload: input
    }
}