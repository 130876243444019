import React from 'react';
// REDUX
import { useSelector } from 'react-redux';

import $ from 'jquery';

import HeightSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/HeightSvg.js';
import WeightSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/WeightSvg.js';
import SitAndReachSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/sitReachSvg.js';
import StandingBoradJumpSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/StandingBroadJumpSvg.js';
import InclinedPullUpsSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/InclinedPullUpsSvg.js';
import ShuttleRunSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/ShuttleRunSvg.js';
import GripStrengthSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/GripStrengthSvg.js';
import WalkRunSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/RunWalkSvg.js';
import VerticalJumpSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/VerticalJumpSvg.js';
import SprintSpeedSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/SprintSvg.js';
import SitUpsSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/BentKneeSitUpsSvg.js';
import BeepTestSvg from '../../assets/compareMyResultsIcons/16032020/svgFiles/ShuttleBeepTestSvg.js';

const renderImage = (sportName, primaryColor) => {
    if (sportName.toLowerCase() === "height") {
        return <div className="find-my-sport-icon"><HeightSvg color={primaryColor} /></div>
    }
    if (sportName.toLowerCase() === "height") {
        return <div className="find-my-sport-icon"><HeightSvg color={primaryColor} /></div>
    }

    switch (sportName.toLowerCase()) {
        case "height":
            return <div className="find-my-sport-icon"><HeightSvg color={primaryColor} /></div>
        case "weight":
            return <div className="find-my-sport-icon"><WeightSvg color={primaryColor} /></div>
        case "vertical jump (cm)":
        case "vertical jump (ft)":
            return <div className="find-my-sport-icon"><VerticalJumpSvg color={primaryColor} /></div>
        case "sit & reach (cm)":
        case "sit & reach (in)":
            return <div className="find-my-sport-icon"><SitAndReachSvg color={primaryColor} /></div>
        case "standing broad jump (cm)":
        case "standing broad jump (in)":
            return <div className="find-my-sport-icon"><StandingBoradJumpSvg color={primaryColor} /></div>
        case "maximum sprint speed (km/h)":
        case "maximum sprint speed (mph)":
            return <div className="find-my-sport-icon"><SprintSpeedSvg color={primaryColor} /></div>
        case "inclined pull‑ups":
            return <div className="find-my-sport-icon"><InclinedPullUpsSvg color={primaryColor} /></div>
        case "4 x 10 m shuttle run (s)":
        case "4 x 11 yd shuttle run (s)":
            return <div className="find-my-sport-icon"><ShuttleRunSvg color={primaryColor} /></div>
        case "grip strength (kg)":
        case "grip strength (lb)":
            return <div className="find-my-sport-icon"><GripStrengthSvg color={primaryColor} /></div>
        case "1.6 km run/walk (min:s)":
        case "1 mile run/walk (min:s)":
            return <div className="find-my-sport-icon"><WalkRunSvg color={primaryColor} /></div>
        case "bent-knee sit-ups":
            return <div className="find-my-sport-icon"><SitUpsSvg color={primaryColor} /></div>
        case "aerobic fitness (ml/kg/min)":
        case "aerobic fitness (ml/lb/min)":
            return <div className="find-my-sport-icon"><BeepTestSvg color={primaryColor} /></div>
    }
}

const MyResultBox = ({ showAdultValues, sportName, uom, studentScore, adultScore }) => {
    const organisationLocalState = useSelector(state => state.organisationState);
    var primaryColor = organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#5A5380';

    var cw = $('.find-my-sport-icon').width();
    $('.find-my-sport-icon').css({ 'height': cw + 'px' });
    window.onload = window.onresize = function (event) {
        var cw = $('.find-my-sport-icon').width();
        $('.find-my-sport-icon').css({ 'height': cw + 'px' });
    };

    return (
        <React.Fragment>
            {/** Square element */}
            <div className="my-results-square-container">
                {/** Image in Square Element */}
                {renderImage(sportName, primaryColor)}

                {/** Square top row */}
                <div className={"my-results-square-container-row-top"}>
                    <span>{sportName} {uom !== "" ? "(" + uom + ")" : ""}</span>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MyResultBox;
