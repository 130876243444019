import React, { useState, useEffect, useRef } from 'react';

// MATERIAL UI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../../store/constants';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux';
import { CheckIfUserSessionIsValid } from '../../../../api/Helper.js';
import { CircularProgress } from '@material-ui/core';

// ZXCVBN - Password rating
const zxcvbn = require("zxcvbn");

const UploadTeacher = () => {
    const dispatch = useDispatch();

    // Password for new added teacher
    const [teacherPasswordScore, setTeacherPasswordScore] = useState(0);
    const [passwordError, setPasswordErr] = useState("");
    
    // Loading Indicator
    const [loading, setLoading] = useState(false);

    var teacherUsernameRef = useRef(null);
    var teacherPasswordRef = useRef(null);

    // React Toast Notifications Object
    const { addToast } = useToasts();

    const renderPasswordScore = (score) => {
        var scoreWord = "poor";

        switch (score) {
            case 0:
                scoreWord = "poor";
                break;
            case 1:
                scoreWord = "poor";
                break;
            case 2:
                scoreWord = "weak";
                break;
            case 3:
                scoreWord = "average";
                break;
            case 4:
                scoreWord = "excellent";
                break;
        }
        return (
            <div className="password-strength-levels">
                <div className={`level-${scoreWord} first-level ${score < 2 ? 'empty' : ''}`}></div>
                <div className={`level-${scoreWord} second-level ${score < 3 ? 'empty' : ''}`}></div>
                <div className={`level-${scoreWord} third-level ${score < 4 ? 'empty' : ''}`}></div>
                <span className={`level-${scoreWord} password-level-span`}>{scoreWord}</span>
            </div>
        )
    }

    const teacherPasswordChangeHandler = value => {

        const passwordInputValue = value;

		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
		const minLengthRegExp = /.{8,}/;
		const maxLengthRegExp = /^.{8,64}$/;

		const passwordLength = passwordInputValue.length;
		const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
		const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
		const digitsPassword = digitsRegExp.test(passwordInputValue);
		const specialCharPassword = specialCharRegExp.test(passwordInputValue);
		const minLengthPassword = minLengthRegExp.test(passwordInputValue);
		const maxLengthPassword = maxLengthRegExp.test(passwordInputValue);

		let errMsg = "";

        if (passwordLength === 0) {
            errMsg += "Password is empty";
        }
        if (!uppercasePassword) {
            errMsg += "At least one Uppercase";
        } 
        if (!lowercasePassword) {
            errMsg += "At least one Lowercase";
        } 
        if (!digitsPassword) {
            errMsg += "At least one digit";
        }
        if (!specialCharPassword) {
            errMsg += "At least one Special Characters";
        }
        if (!minLengthPassword) {
            errMsg += "At least minumum 8 characters";
        }
        if (!maxLengthPassword) {
            errMsg += "Maximum length is 64 characters";
        }

        setTeacherPasswordScore(zxcvbn(value).score);

        setPasswordErr(errMsg);

    };

    const createBtnHandler = () => {
        return new Promise((resolve, reject) => {
            var username = teacherUsernameRef.value;
            var password = teacherPasswordRef.value;
    
            if (username != "" && password != "" && passwordError == "") {
                var json = {
                    method: 'create_teacher',
                    username: username,
                    password: password
                };
    
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/Organisation`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json'
                        },
                        credentials: "include",
                        body: JSON.stringify(json)
                    }).then(res => {
                        CheckIfUserSessionIsValid(dispatch).then(() => {
                            res.json().then(result => {
                                if(result.status == 'success') {
                                    addToast(result.message, {
                                        appearance: 'success',
                                        autoDismiss: true
                                    });
                                    resolve();
                                } else if(result.status == 'error') {
                                    addToast(result.message, {
                                        appearance: 'error',
                                        autoDismiss: true
                                    });
                                    reject();
                                }
                            }).catch(er => {
                                console.log("Error occured parsing JSON: ", er);
            
                                addToast("An error occured", {
                                    appearance: 'error',
                                    autoDismiss: true
                                });
                                reject();
                            })
                        })
        
                    }).catch(error => {
                        console.log("An error occured creating connection to backend: ", error);
        
                        addToast("An error occured", {
                            appearance: 'error',
                            autoDismiss: true
                        });
                        reject();
                    })
                })
            } else if (username == null || password == "") {
                addToast("Name/Password cannot be empty", {
                    appearance: 'error',
                    autoDismiss: true
                });
                setLoading(false);
            } 
            else if (passwordError != "") {
                addToast("Please follow the hint to set a strong password", {
                    appearance: 'error',
                    autoDismiss: true
                });
                setLoading(false);
            } 
            else {
                addToast("Please follow the hint to set a strong password", {
                    appearance: 'error',
                    autoDismiss: true
                });
                setLoading(false);
            }
        });
    }

    return (
        <div className="upload-teacher-container">
            <div className="row heading-row">
                <span className="heading">Create Teacher</span>
            </div>

            <div className="upload-teacher-body">
                <div className="row">
                    <label for="teacher-name-input">Name:</label>
                    <TextField id="teacher-name-input" variant="filled" inputRef={el => teacherUsernameRef = el} />
                </div>

                <div className="row">
                    <label for="teacher-password-input">Password:</label>
                    <TextField id="teacher-password-input" variant="filled" type="password" inputRef={el => teacherPasswordRef = el} onChange={(e) => teacherPasswordChangeHandler(e.target.value)} />
                </div>
                <p class="text-danger">Password must contain at least 1 uppercase, 1 lowercase, a number, a special character and minumum 8 characters.</p>

                <div className="row">
                    {renderPasswordScore(teacherPasswordScore)}
                </div>

                <div className="row">
                    {loading && <CircularProgress disableShrink size={24} />}
                    <Button variant="contained" onClick={() => {
                        setLoading(true);
                        createBtnHandler().then(() => {
                            setLoading(false);
                        }).catch(() => {
                            setLoading(false);
                        })
                    }} >
                        Create teacher
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default UploadTeacher;
