import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';

// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// CONVERT UNITS
import IM from 'convert-units';

// REDUX ACTIONS
import {
    changeScreen,
    setTestId,
    setTestTokenId,
    setPredictedAdultFitnessId,
    setAdultSitAndReach,
    setAdultStandingBroadJump,
    setAdultGripStrength,
    setAdultVerticalJump,
    setAdultSitUps,
    setAdultInclinedPullUps,
    setAdultShuttleRun,
    setAdult50mSprint,
    setAdultWalkRun,
    setAdultShuttleBeepTest,
    setAdultVo2Max,
    setAdultHeight,
    setAdultWeight,
    setAdultSittingHeight,
    setAdultForearmLength,
    setAdultArmSpan,
    setUserViewingTest,
    setUserEditingTest,
    setSportMatchBodyShapeAndSizeSectionVisited,
    setSportMatchFlexibilitySectionVisited,
    setSportMatchMuscularStrengthAndPowerSectionVisited,
    setSportMatchMuscularEnduranceSectionVisited,
    setSportMatchSpeedAndAgilitySectionVisited,
    setSportMatchAerobicFitnessSectionVisited,
    setUserUOM,
    setUserFitnessAge,
} from "../../../store/actions/actions.js"

import {
    setHeight,
    setWeight,
    setArmSpan,
    setForearmLength,
    setUpperArmLength,
    setSitAndReach,
    setStandingBroadJump,
    setSittingHeight,
    setLeftGripStrength,
    setRightGripStrength,
    setGripStrength,
    setVerticalJump,
    setSitUps,
    setInclinedPullUps,
    setShuttleRun,
    setSprint,
    setSprintDistance,
    setSprintTime,
    setRunWalkMinutes,
    setRunWalkSeconds,
    setVo2Max,
    setUserInputHeight,
    setUserInputWeight,
    setUserInputArmSpan,
    setUserInputForearmLength,
    setUserInputUpperArmLength,
    setUserInputSitAndReach,
    setUserInputStandingBroadJump,
    setUserInputGripStrengthLeft,
    setUserInputGripStrengthRight,
    setUserInputVerticalJump,
    setUserInputBentKneeSitUps,
    setUserInputInclinedPullUps,
    setUserInputShuttleRun,
    setUserInputSprintDistance,
    setUserInputSprintTime,
    setUserInputWalkRunMinutes,
    setUserInputWalkRunSeconds,
    setUserInputShuttleBeepTest,
    setUserInputVO2MAX,
    setUserInputSittingHeight,
    setAllFieldsProvided,
    setBeepTest,
    setUserInputYoyoTest,
    setUserInputYoyoStage,
    setUserInputYoyoVO2MAX,
    setUserInputBeepTestVO2MAX,
    setYoyoTest,
    setYoyoStage
} from '../../../store/actions/myDetails.js';

// BACKEND URL
import {
    B2C_ROLE_NAME,
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../store/constants';

import PreviousTestElement from './PreviousTestElement.js';
import { hexToRgbA } from '../../../helper/hexToRgbA.js';

const PreviousTest = ({setTestStartDate:setTestStartDate}) => {
    const dispatch = useDispatch();

    // Redux State
    const state = useSelector(state => state);
    const organisationLocalState = useSelector(state => state.organisationState);

    const [userPreviousTests, setUserPreviousTests] = useState([]);

    const [loading, setLoading] = useState(false);

    const convertToImperial = (data, from, to) => {
        if(from && to && from != '' && to != '') {
            if(to == 'yd') {
                return (IM(data).from(from).to('ft') / 3) + "";
            } else {
                return IM(data).from(from).to(to) + "";
            }
        } else {
            return data;
        }
    }

    // Get the test id, and retrieve 'comparative' norms from that id (which happens when navigating to the screen)
    const getSelectedTestAndShowScreen = (testNo) => {
        setLoading(true);
        dispatch(setTestId(testNo + ""));

        fetch(BACKEND_URL + ENVIRONMENT_PATH + "/usertest", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    method: "retrieve_test_data",
                    test_id: testNo + ""
                })
            }
        ).then(res => {
            res.json().then(result => {
                var hasBodyShapeAndSizeVisited = false;
                var hasFlexibilityVisited = false;
                var hasMuscularStrengthAndPowerVisited = false;
                var hasMuscularEnduranceVisited = false;
                var hasSpeedAndAgilityVisited = false;
                var hasAerobicFitnessVisited = false;

                var isUsingImperialMesurements = state.user.userUOM == "Imperial";

                var allFieldsProvided = true;

                if (result && result.height && result.height != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setHeight(convertToImperial(result.height, 'cm', 'in')));
                        dispatch(setUserInputHeight(convertToImperial(result.height, 'cm', 'in')));
                    } else {
                        dispatch(setHeight(result.height + ""));
                        dispatch(setUserInputHeight(result.height + ""));
                    }

                    hasBodyShapeAndSizeVisited = true;
                } else {
                    dispatch(setHeight(""));
                    dispatch(setUserInputHeight(""));
                    allFieldsProvided = false;
                }

                if (result && result.weight && result.weight != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setWeight(parseFloat(convertToImperial(result.weight, 'kg', 'lb')).toFixed(2)));
                        dispatch(setUserInputWeight(parseFloat(convertToImperial(result.weight, 'kg', 'lb')).toFixed(2)));
                    } else {
                        dispatch(setWeight(result.weight + ""));
                        dispatch(setUserInputWeight(result.weight + ""));
                    }

                    hasBodyShapeAndSizeVisited = true;
                } else {
                    dispatch(setWeight(""));
                    dispatch(setUserInputWeight(""));
                    allFieldsProvided = false;
                }

                if (result && result.armSpan && result.armSpan != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setArmSpan(parseFloat(convertToImperial(result.armSpan, 'cm', 'in')).toFixed(2)));
                        dispatch(setUserInputArmSpan(parseFloat(convertToImperial(result.armSpan, 'cm', 'in')).toFixed(2)));
                    } else {
                        dispatch(setArmSpan(result.armSpan + ""));
                        dispatch(setUserInputArmSpan(result.armSpan + ""));
                    }

                    hasBodyShapeAndSizeVisited = true;
                } else {
                    dispatch(setArmSpan(""));
                    dispatch(setUserInputArmSpan(""));
                    allFieldsProvided = false;
                }

                if (result && result.sittingHeight && result.sittingHeight != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setSittingHeight(parseFloat(convertToImperial(result.sittingHeight, 'cm' ,'in')).toFixed(2)));
                        dispatch(setUserInputSittingHeight(parseFloat(convertToImperial(result.sittingHeight, 'cm', 'in')).toFixed(2)));
                    } else {
                        dispatch(setSittingHeight(result.sittingHeight + ""));
                        dispatch(setUserInputSittingHeight(result.sittingHeight + ""));
                    }

                    hasBodyShapeAndSizeVisited = true;
                } else {
                    dispatch(setSittingHeight(""));
                    dispatch(setUserInputSittingHeight(""));
                    allFieldsProvided = false;
                }

                if (result && result.forearmLength && result.forearmLength != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setForearmLength(parseFloat(convertToImperial(result.forearmLength, 'cm' ,'in')).toFixed(2)));
                        dispatch(setUserInputForearmLength(parseFloat(convertToImperial(result.forearmLength, 'cm' ,'in')).toFixed(2)));
                    } else {
                        dispatch(setForearmLength(result.forearmLength + ""));
                        dispatch(setUserInputForearmLength(result.forearmLength + ""));
                    }

                    hasBodyShapeAndSizeVisited = true;
                } else {
                    dispatch(setForearmLength(""));
                    dispatch(setUserInputForearmLength(""));
                    allFieldsProvided = false;
                }

                if (result && result.upperArmLength && result.upperArmLength != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setUpperArmLength(parseFloat(convertToImperial(result.upperArmLength, 'cm' ,'in')).toFixed(2)));
                        dispatch(setUserInputUpperArmLength(parseFloat(convertToImperial(result.upperArmLength, 'cm' ,'in')).toFixed(2)));
                    } else {
                        dispatch(setUpperArmLength(result.upperArmLength + ""));
                        dispatch(setUserInputUpperArmLength(result.upperArmLength + ""));
                    }

                    hasBodyShapeAndSizeVisited = true;
                } else {
                    dispatch(setUpperArmLength(""));
                    dispatch(setUserInputUpperArmLength(""));
                    allFieldsProvided = false;
                }

                if (result && result.sitReach && result.sitReach != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setSitAndReach(parseFloat(convertToImperial(result.sitReach, 'cm' ,'in')).toFixed(2)));
                        dispatch(setUserInputSitAndReach(parseFloat(convertToImperial(result.sitReach, 'cm' ,'in')).toFixed(2)));
                    } else {
                        dispatch(setSitAndReach(result.sitReach + ""));
                        dispatch(setUserInputSitAndReach(result.sitReach + ""));
                    }

                    hasFlexibilityVisited = true;
                } else {
                    dispatch(setSitAndReach(""));
                    dispatch(setUserInputSitAndReach(""));
                    allFieldsProvided = false;
                }

                if (result && result.standingBroadJump && result.standingBroadJump != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setStandingBroadJump(convertToImperial(result.standingBroadJump, 'cm' ,'in')));
                        dispatch(setUserInputStandingBroadJump(convertToImperial(result.standingBroadJump, 'cm' ,'in')));
                    } else {
                        dispatch(setStandingBroadJump(result.standingBroadJump + ""));
                        dispatch(setUserInputStandingBroadJump(result.standingBroadJump + ""));
                    }

                    hasMuscularStrengthAndPowerVisited = true;
                } else {
                    dispatch(setStandingBroadJump(""));
                    dispatch(setUserInputStandingBroadJump(""));
                    allFieldsProvided = false;
                }

                if (result && result.gripStrengthLeft && result.gripStrengthLeft != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setLeftGripStrength(parseFloat(convertToImperial(result.gripStrengthLeft, 'kg' ,'lb')).toFixed(1)));
                        dispatch(setUserInputGripStrengthLeft(parseFloat(convertToImperial(result.gripStrengthLeft, 'kg' ,'lb')).toFixed(1)));
                    } else {
                        dispatch(setLeftGripStrength(result.gripStrengthLeft + ""));
                        dispatch(setUserInputGripStrengthLeft(result.gripStrengthLeft + ""));
                    }

                    hasMuscularStrengthAndPowerVisited = true;
                } else {
                    dispatch(setLeftGripStrength(""));
                    dispatch(setUserInputGripStrengthLeft(""));
                    allFieldsProvided = false;
                }

                if (result && result.gripStrengthRight && result.gripStrengthRight != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setRightGripStrength(parseFloat(convertToImperial(result.gripStrengthRight, 'kg' ,'lb')).toFixed(1)));
                        dispatch(setUserInputGripStrengthRight(parseFloat(convertToImperial(result.gripStrengthRight, 'kg' ,'lb')).toFixed(1)));
                    } else {
                        dispatch(setRightGripStrength(result.gripStrengthRight + ""));
                        dispatch(setUserInputGripStrengthRight(result.gripStrengthRight + ""));
                    }

                    hasMuscularStrengthAndPowerVisited = true;
                } else {
                    dispatch(setRightGripStrength(""));
                    dispatch(setUserInputGripStrengthRight(""));
                    allFieldsProvided = false;
                }

                if (result && result.verticalJump && result.verticalJump != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setVerticalJump(parseFloat(convertToImperial(result.verticalJump, 'cm' ,'in')).toFixed(1)));
                        dispatch(setUserInputVerticalJump(parseFloat(convertToImperial(result.verticalJump, 'cm' ,'in')).toFixed(1)));
                    } else {
                        dispatch(setVerticalJump(result.verticalJump + ""));
                        dispatch(setUserInputVerticalJump(result.verticalJump + ""));
                    }

                    hasMuscularStrengthAndPowerVisited = true;
                } else {
                    dispatch(setVerticalJump(""));
                    dispatch(setUserInputVerticalJump(""));
                    allFieldsProvided = false;
                }

                if (result && result.situps && result.situps != -1) {
                    dispatch(setSitUps(result.situps + ""));
                    dispatch(setUserInputBentKneeSitUps(result.situps + ""));
                    hasMuscularEnduranceVisited = true;
                } else {
                    dispatch(setSitUps(""));
                    dispatch(setUserInputBentKneeSitUps(""));
                    allFieldsProvided = false;
                }

                if (result && result.inclinedPullup && result.inclinedPullup != -1) {
                    dispatch(setInclinedPullUps(result.inclinedPullup + ""));
                    dispatch(setUserInputInclinedPullUps(result.inclinedPullup + ""));
                    hasMuscularEnduranceVisited = true;
                } else {
                    dispatch(setInclinedPullUps(""));
                    dispatch(setUserInputInclinedPullUps(""));
                    allFieldsProvided = false;
                }

                if (result && result.shuttleRun && result.shuttleRun != -1) {
                    dispatch(setShuttleRun(result.shuttleRun + ""));
                    dispatch(setUserInputShuttleRun(result.shuttleRun + ""));
                    hasSpeedAndAgilityVisited = true;
                } else {
                    dispatch(setShuttleRun(""));
                    dispatch(setUserInputShuttleRun(""));
                    allFieldsProvided = false;
                }

                if (result && result.speed && result.speed != -1) {
                    if(isUsingImperialMesurements) {
                        
                    } else {
                        dispatch(setSprint(result.speed + ""));
                    }

                    hasSpeedAndAgilityVisited = true;
                } else {
                    dispatch(setSprint(""));
                    allFieldsProvided = false;
                }

                if (result && result.sprintDistance && result.sprintDistance != -1) {
                    if(isUsingImperialMesurements) {
                        dispatch(setSprintDistance(parseFloat(convertToImperial(result.sprintDistance, 'm' ,'yd')).toFixed(2)));
                        dispatch(setUserInputSprintDistance(parseFloat(convertToImperial(result.sprintDistance, 'm' ,'yd')).toFixed(2)));
                    } else {
                        dispatch(setSprintDistance(result.sprintDistance + ""));
                        dispatch(setUserInputSprintDistance(result.sprintDistance + ""));
                    }

                    hasSpeedAndAgilityVisited = true;
                } else {
                    dispatch(setSprintDistance(""));
                    dispatch(setUserInputSprintDistance(""));
                    allFieldsProvided = false;
                }

                if (result && result.sprintTime && result.sprintTime != -1) {
                    dispatch(setSprintTime(result.sprintTime + ""));
                    dispatch(setUserInputSprintTime(result.sprintTime + ""));
                    hasSpeedAndAgilityVisited = true;
                } else {
                    dispatch(setSprintTime(""));
                    dispatch(setUserInputSprintTime(""));
                    allFieldsProvided = false;
                }

                if (result && result.walkRun && result.walkRun != -1 && result.walkRun > 60) {
                    var minutes = parseFloat(result.walkRun / 60).toFixed(0);

                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    } else {
                        minutes = minutes + "";
                    }

                    dispatch(setRunWalkMinutes(minutes));
                    dispatch(setUserInputWalkRunMinutes(minutes));
                    hasAerobicFitnessVisited = true;
                } else {
                    dispatch(setRunWalkMinutes(""));
                    dispatch(setUserInputWalkRunMinutes(""));
                }

                if (result && result.walkRun && result.walkRun != -1 && result.walkRun > 0) {
                    var seconds = parseFloat(result.walkRun % 60).toFixed(0);

                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    } else {
                        seconds = seconds + "";
                    }

                    dispatch(setRunWalkSeconds(seconds));
                    dispatch(setUserInputWalkRunSeconds(seconds));
                    hasAerobicFitnessVisited = true;
                } else {
                    dispatch(setRunWalkSeconds(""));
                    dispatch(setUserInputWalkRunSeconds(""));
                    allFieldsProvided = false;
                }

                // Shuttle level
                if (result && result.shuttleLevel && result.shuttleLevel != -1) {
                    dispatch(setBeepTest(result.shuttleLevel));
                    dispatch(setUserInputShuttleBeepTest(result.shuttleLevel));
                }

                if(result && result.yoyo && result.yoyo != -1) {
                    var yoyoArray = result.yoyo.split("-");
                    var yoyoString = yoyoArray[1] + "-" + yoyoArray[2];

                    dispatch(setYoyoTest(yoyoString));
                    dispatch(setUserInputYoyoTest(yoyoString));

                    dispatch(setYoyoStage(yoyoArray[0]));
                    dispatch(setUserInputYoyoStage(yoyoArray[0]));
                }

                // VO2Max level
                if (result && result.shuttleTest20m && result.shuttleTest20m != -1) {
                    dispatch(setVo2Max(parseFloat(result.shuttleTest20m).toFixed(1) + ""));
                    dispatch(setUserInputVO2MAX(parseFloat(result.shuttleTest20m).toFixed(1) + ""));
                    hasAerobicFitnessVisited = true;
                } else {
                    dispatch(setVo2Max(""));
                    dispatch(setUserInputVO2MAX(""));
                    allFieldsProvided = false;
                }

                // Setting predicted adult fitness values in state
                if (result && result.predictedAdultFitnesses && result.predictedAdultFitnesses.length != 0) {
                    var predictedAdultFitnessObj = result.predictedAdultFitnesses[result.predictedAdultFitnesses.length - 1];

                    if (predictedAdultFitnessObj.predicted_adult_fitness_id) {
                        dispatch(setPredictedAdultFitnessId(predictedAdultFitnessObj.predicted_adult_fitness_id + ""));
                    }

                    if(isUsingImperialMesurements) {
                        dispatch(setAdultSitAndReach(parseFloat(IM(predictedAdultFitnessObj.sit_reach).from('cm').to('in')).toFixed(2)));
                        dispatch(setAdultStandingBroadJump(parseFloat(IM(predictedAdultFitnessObj.board_jump).from('cm').to('in')).toFixed(2)));
                        dispatch(setAdultGripStrength(parseFloat(IM(predictedAdultFitnessObj.grip_strength).from('kg').to('lb')).toFixed(1)));
                        dispatch(setAdultVerticalJump(parseFloat(IM(predictedAdultFitnessObj.power).from('cm').to('in')).toFixed(1)));
                        dispatch(setAdultSitUps(predictedAdultFitnessObj.situps));
                        dispatch(setAdultInclinedPullUps(predictedAdultFitnessObj.pullup));
                        dispatch(setAdultShuttleRun(predictedAdultFitnessObj.shuttle));
                        dispatch(setAdult50mSprint(parseFloat(IM(predictedAdultFitnessObj.speed).from('km/h').to('m/h')).toFixed(2)));
                        dispatch(setAdultWalkRun(predictedAdultFitnessObj.walk_run));
                        dispatch(setAdultVo2Max(predictedAdultFitnessObj.endurance));
                        dispatch(setAdultHeight(parseFloat(IM(predictedAdultFitnessObj.height).from('cm').to('in'))));
                        dispatch(setAdultWeight(parseFloat(IM(predictedAdultFitnessObj.weight).from('kg').to('lb')).toFixed(2)));
                        dispatch(setAdultSittingHeight(predictedAdultFitnessObj.sitting_height_ration));
                        dispatch(setAdultForearmLength(predictedAdultFitnessObj.brachial_index));
                        dispatch(setAdultArmSpan(predictedAdultFitnessObj.arm_span_height_ratio));
                        dispatch(setUserFitnessAge(predictedAdultFitnessObj.predicted_fitness_age));
                    } else {
                        dispatch(setAdultSitAndReach(predictedAdultFitnessObj.sit_reach));
                        dispatch(setAdultStandingBroadJump(predictedAdultFitnessObj.board_jump));
                        dispatch(setAdultGripStrength(predictedAdultFitnessObj.grip_strength));
                        dispatch(setAdultVerticalJump(predictedAdultFitnessObj.power));
                        dispatch(setAdultSitUps(predictedAdultFitnessObj.situps));
                        dispatch(setAdultInclinedPullUps(predictedAdultFitnessObj.pullup));
                        dispatch(setAdultShuttleRun(predictedAdultFitnessObj.shuttle));
                        dispatch(setAdult50mSprint(predictedAdultFitnessObj.speed));
                        dispatch(setAdultWalkRun(predictedAdultFitnessObj.walk_run));
                        dispatch(setAdultVo2Max(predictedAdultFitnessObj.endurance));
                        dispatch(setAdultHeight(predictedAdultFitnessObj.height));
                        dispatch(setAdultWeight(predictedAdultFitnessObj.weight));
                        dispatch(setAdultSittingHeight(predictedAdultFitnessObj.sitting_height_ration));
                        dispatch(setAdultForearmLength(predictedAdultFitnessObj.brachial_index));
                        dispatch(setAdultArmSpan(predictedAdultFitnessObj.arm_span_height_ratio));
                        dispatch(setUserFitnessAge(predictedAdultFitnessObj.predicted_fitness_age));
                    }


                }

                if(allFieldsProvided) {
                    dispatch(setAllFieldsProvided(true));
                } else {
                    dispatch(setAllFieldsProvided(false));
                }

                if(hasBodyShapeAndSizeVisited) {
                    dispatch(setSportMatchBodyShapeAndSizeSectionVisited(true));
                } else {
                    dispatch(setSportMatchBodyShapeAndSizeSectionVisited(false));
                }

                if(hasFlexibilityVisited) {
                    dispatch(setSportMatchFlexibilitySectionVisited(true));
                } else {
                    dispatch(setSportMatchFlexibilitySectionVisited(false));
                }

                if(hasMuscularStrengthAndPowerVisited) {
                    dispatch(setSportMatchMuscularStrengthAndPowerSectionVisited(true));
                } else {
                    dispatch(setSportMatchMuscularStrengthAndPowerSectionVisited(false));
                }

                if(hasMuscularEnduranceVisited) {
                    dispatch(setSportMatchMuscularEnduranceSectionVisited(true));
                } else {
                    dispatch(setSportMatchMuscularEnduranceSectionVisited(false));
                }

                if(hasSpeedAndAgilityVisited) {
                    dispatch(setSportMatchSpeedAndAgilitySectionVisited(true));
                } else {
                    dispatch(setSportMatchSpeedAndAgilitySectionVisited(false));
                }

                if(hasAerobicFitnessVisited) {
                    dispatch(setSportMatchAerobicFitnessSectionVisited(true));
                } else {
                    dispatch(setSportMatchAerobicFitnessSectionVisited(false));
                }

                dispatch(setUserEditingTest(testNo + ""));
                setLoading(false);
            })
        })
    }

    useEffect(() => {
        // Retrieve use previous tests
        fetch(BACKEND_URL + ENVIRONMENT_PATH + '/UserDetails', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({user_id: state.user.userBackendId, getPreviousTests: true})
        }).then(function (res) {
            res.json().then(function (data) {
                if (data.length != 0) {
                    var userTests = [];
                    for (var i = 0; i < data.length; i++) {
                        var isFirstItem = i == 0;
                        var userTest = data[i].user_test;
                        var predictedAdultValues = data[i].predicted_adult_fitness_object;

                        if (userTest) {
                            if (isFirstItem) {
                                if (data[i].user_test.predicted_adult_fitness_id) {
                                    //dispatch(setPredictedAdultFitnessId(data[i].user_test.predicted_adult_fitness_id));
                                }
                            }

                            userTests.push(data[i].user_test);
                            if (i == data.length - 1) {
                                setUserPreviousTests(userTests);
                            }
                        }
                    }
                }
            }).catch(() => {

            })
        }).catch(() => {

        });

        fetch(BACKEND_URL + ENVIRONMENT_PATH + '/UserDetails', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({user_id: state.user.userBackendId, get_user_details: true})
        }).then(function (res) {
            res.json().then(function (data) {
                if (data.testTokenId) {
                    dispatch(setTestTokenId(data.testTokenId));
                }
            }).catch(err => {
                console.log("An error was thrown");
            })
        }).catch(err => {
            console.log("An error was thrown");
        });

    }, []);

    const closeHandler = () => {
        // Resetting the test data and the user inputs back to defaults, allowing the user to set a new test
        setLoading(true);
        dispatch(setTestId(""));
        dispatch(setHeight(""));
        dispatch(setUserInputHeight(""));
        dispatch(setWeight(""));
        dispatch(setUserInputWeight(""));
        dispatch(setArmSpan(""));
        dispatch(setUserInputArmSpan(""));
        dispatch(setSittingHeight(""));
        dispatch(setUserInputSittingHeight(""));
        dispatch(setForearmLength(""));
        dispatch(setUserInputForearmLength(""));
        dispatch(setUpperArmLength(""));
        dispatch(setUserInputUpperArmLength(""));
        dispatch(setSitAndReach(""));
        dispatch(setUserInputSitAndReach(""));
        dispatch(setStandingBroadJump(""));
        dispatch(setUserInputStandingBroadJump(""));
        dispatch(setLeftGripStrength(""));
        dispatch(setUserInputGripStrengthLeft(""));
        dispatch(setRightGripStrength(""));
        dispatch(setUserInputGripStrengthRight(""));
        dispatch(setVerticalJump(""));
        dispatch(setUserInputVerticalJump(""));
        dispatch(setSitUps(""));
        dispatch(setUserInputBentKneeSitUps(""));
        dispatch(setInclinedPullUps(""));
        dispatch(setUserInputInclinedPullUps(""));
        dispatch(setShuttleRun(""));
        dispatch(setUserInputShuttleRun(""));
        dispatch(setSprint(""));
        dispatch(setSprintDistance(""));
        dispatch(setUserInputSprintDistance(""));
        dispatch(setSprintTime(""));
        dispatch(setUserInputSprintTime(""));
        dispatch(setRunWalkMinutes(""));
        dispatch(setUserInputWalkRunMinutes(""));
        dispatch(setRunWalkSeconds(""));
        dispatch(setUserInputWalkRunSeconds(""));
        dispatch(setVo2Max(""));
        dispatch(setUserInputShuttleBeepTest(""));
        dispatch(setUserInputVO2MAX(""));
        dispatch(setUserInputYoyoTest(''));
        dispatch(setUserInputYoyoStage(''));
        dispatch(setUserInputYoyoVO2MAX(''));
        dispatch(setUserInputBeepTestVO2MAX(''));
        dispatch(setAllFieldsProvided(false));
        dispatch(setSportMatchBodyShapeAndSizeSectionVisited(false));
        dispatch(setSportMatchFlexibilitySectionVisited(false));
        dispatch(setSportMatchMuscularStrengthAndPowerSectionVisited(false));
        dispatch(setSportMatchMuscularEnduranceSectionVisited(false));
        dispatch(setSportMatchSpeedAndAgilitySectionVisited(false));
        dispatch(setSportMatchAerobicFitnessSectionVisited(false));
        dispatch(setUserEditingTest(""));
        dispatch(setBeepTest(''));
        dispatch(setYoyoStage(''));
        dispatch(setYoyoTest(''));
        setLoading(false);
    }

    const renderPastTestResults = ({setTestStartDate:setTestStartDate}) => {
        var dom = [];

        if (userPreviousTests !== null) {
            if (userPreviousTests.length !== 0) {
                dom.push(
                    <div className="test-reports-heading">
                        <span class="test-num text">Test number</span>
                        <span class="test-date text">Date of test</span>
                        <span class="actual-age text">Actual age</span>
                        <span class="fit-age text">Fitness age</span>
                        <span ></span>
                    </div>
                );

                for (var i = 0; i < userPreviousTests.length; i++) {
                    dom.push(
                        <PreviousTestElement
                            testElement={userPreviousTests[i]}
                            onClickHandler={getSelectedTestAndShowScreen}
                            onCloseHandler={closeHandler}
                            loaded={loading}
                            setTestStartDate={setTestStartDate}
                        />
                    );
                }

                return <div className="test-reports-body">{dom}</div>;
            } else {
                dom.push(
                    <React.Fragment>
                        <div className="no-previous-tests">
                            <span style={{color: organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor,0.5) : 'rgb(154, 98, 179, 0.5)'}}>You have not yet completed a SportMatch test</span>
                        </div>
                    </React.Fragment>
                )
            }
        }

        return dom;
    };

    const moreButtonOnClickHandler = () => {
        if(state.user.userRole == B2C_ROLE_NAME) {
            dispatch(changeScreen(6));
        } else {
            dispatch(changeScreen(7));
        }
    }

    const renderMoreButton = () => {
        if (state.user.userPreviousTests.length !== 0) {
            var previousTestsJSON = JSON.parse(state.user.userPreviousTests);

            if (previousTestsJSON.tests !== 0) {
                return (
                    <button 
                        className="past-test-results-more-btn" 
                        onClick={moreButtonOnClickHandler} 
                        style={{background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : ''}}
                    >More</button>
                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    return (
        <div className="screen-section previous-tests-section">
            <div className="previous-test-results-heading">
                <span>Past test results</span>
                {renderMoreButton()}
            </div>
            {renderPastTestResults({setTestStartDate:setTestStartDate})}
        </div>
    );
}

export default PreviousTest;
