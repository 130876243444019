import React, {useState} from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

import { Card, CardActions, CardContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';

import {
    changeScreen
} from '../../../store/actions/actions.js';
import { BirthDropdownField } from '../../../components/FormInputs';
/** Required to show the dialog appearing from the bottom of the screen */

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
}); 

 const AgeMessageCard = ({ showDialog, setAgeValid:setAgeValid,onSubmitClick: onSubmitClick }) => {
     
    const reduxState = useSelector(state => state);
    
    const saveAndChange = () =>{
        setAgeValid(false);
        onSubmitClick();
    }
    const checkAge = () => {
        var dob = moment((reduxState.user.userBirthMonth + 1) + '/01/' + reduxState.user.userBirthYear);
        var age = moment.duration(moment(new Date()).diff(dob)).asYears();
        if ((reduxState.user.userBirthMonth === -1 && reduxState.user.userBirthYear === -1)
        || (age < 7)) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <Dialog open={showDialog} TransitionComponent={Transition} keepMounted onClose={null}>
            <Card className="first-time-making-test-card dob-checker">
                <CardContent>
                    <span className="card-body-text-span">
                        You date of birth is invalid<br/>
                        Please enter your month and year of birth to continue<br/><br/>
                    </span>

                    <BirthDropdownField/>
                    <div className="card-button-container">
                        {/* <button style={{marginLeft: 'auto', width: '80px'}} onClick={() => dispatch(changeScreen(1))}>Back</button> */}
                        <button onClick={()=>  saveAndChange()} disabled={checkAge() ? true : false} className="begin-btn">Submit</button>
                    </div>
                </CardContent>
            </Card>
        </Dialog>
    );
}
export default AgeMessageCard;