import React from "react";

const ShuttleRunSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Shuttle Run</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="_4_x_10_m_shuttle" data-name="4 x 10 m shuttle">
            <circle className="cls-2" style={{fill: color}} cx="296.95" cy="109.7" r="43.86"/>
            <path className="cls-2" style={{fill: color}}
                  d="M421.39,120.88A22.48,22.48,0,0,0,394.9,138.5c-6.52,32.43-14.25,41.37-18.29,43.81-7.63,4.63-26.05-.73-47.38-6.94-14.25-4.15-30.41-8.85-48.74-12.3-39.14-7.37-75.89,2.1-106.28,27.39-22,18.3-32.84,38.4-34,40.63l0,0a22.59,22.59,0,1,0,40.34,19.94c4-7,29.71-47.67,78.94-45.26l-30,113.93a87.08,87.08,0,0,0-1.74,8.77,37.65,37.65,0,0,1-.93,4.79c-10.51,25-21.17,38.4-34.56,43.54-15.6,6-39,1.74-69.45-12.6a22.5,22.5,0,0,0-19.16,40.72c27.7,13,51.87,19.51,73,19.51a87.72,87.72,0,0,0,31.67-5.61c29.62-11.37,46.17-37.94,56.32-59.91,1.42-1.42,3-1.62,5.3-1.62,27.11,5.27,59.2,14.9,66.5,21.87,10.18,9.72,12.26,28.49,15.33,63.6q.45,5.16.94,10.62a22.51,22.51,0,0,0,22.38,20.46,20.49,20.49,0,0,0,2.07-.1,22.5,22.5,0,0,0,20.37-24.44c-.33-3.59-.63-7.08-.93-10.47-3.44-39.29-6.16-70.32-29.08-92.21-6.72-6.42-20.14-16.14-59-26.54-3.09-.82-6.11-1.59-9-2.3,12.59-28,15.84-58.26,18.23-80.53.6-5.6,1.33-12.43,2.16-17.74,30.12,8.74,56.29,15.62,80,1.27,19.16-11.6,31.21-34.25,39.09-73.43A22.49,22.49,0,0,0,421.39,120.88Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default ShuttleRunSvg;
