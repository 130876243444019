import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
// Field Info
import { fieldInfo } from './fieldInfoContent.js';
// COMPONENTS
import { MyDetailsHeader } from '../../../components/MyDetailsHeader';
import {
    UserDefinedTextField,
    WalkRunTextField,
    SliderField,
    RadioField,
    UserDefinedDropDownField
} from '../../../components/FormInputs.js';
// ACTIONS
import {
    setRunWalkMinutes,
    setRunWalkSeconds,
    setBeepTest,
    setExpandedCardiorespiratoryFitness,
    setVo2Max,
    setUserInputWalkRunMinutes,
    setUserInputWalkRunSeconds,
    setUserInputShuttleBeepTest,
    setUserInputVO2MAX,
    setUserInputYoyoTest,
    setUserInputYoyoStage,
    setUserInputYoyoVO2MAX,
    setUserInputBeepTestVO2MAX
 } from '../../../store/actions/myDetails.js';

 // ICONS
import AerobicSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/AerobicSvg.js';
import { YoyoTestSection } from '../../../components/yoyoTest/yoyoTestComponent.js';

const CardioRespiratory = ({infoOpen, setInfoOpen:setInfoOpen}) => {
    const dispatch = useDispatch();
    const state = useSelector(store => store.myDetails);
    const sportMatchState = useSelector(store => store.sportMatchTest);
    const cardiorespiratoryFitnessSectionExpanded = state.cardiorespiratoryFitnessSectionExpanded;
    const organisationStateLocal = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    // Local State
    const [expanded, setExpanded] = useState(false);

    // Functions enabling child components to dispatch actions
    const dispatchRunWalk = (input, type) => {
        if(type === "min") {
            if(parseInt(input) < 10) {
                dispatch(setUserInputWalkRunMinutes("0" + parseInt(input)));
            } else {
                dispatch(setUserInputWalkRunMinutes(input));
            }
        } else {
            if(parseInt(input) < 10) {
                dispatch(setUserInputWalkRunSeconds("0" + parseInt(input)));
            } else {
                dispatch(setUserInputWalkRunSeconds(input));
            }
        }
    }
    const dispatchBeepTest = (input, vo2Max) => {
        if(vo2Max) {
            dispatch(setUserInputShuttleBeepTest(input));
            dispatchVo2MaxValue(vo2Max, 'beeptest');
        }
    }

    const expand = () => {
        dispatch(setExpandedCardiorespiratoryFitness(!cardiorespiratoryFitnessSectionExpanded));
        setExpanded(!expanded);
    }

    const dispatchVo2MaxValue = (value, type) => {
        var yoyoVO2 = 0;
        var beeptestVO2 = 0;
        var finalUserVO2 = 0;

        if(type == "yoyo") {
            yoyoVO2 = value;
            beeptestVO2 = sportMatchState.userInputBeepTestVO2MAX ? sportMatchState.userInputBeepTestVO2MAX : 0;
            dispatch(setUserInputYoyoVO2MAX(yoyoVO2));
        }

        if(type == "beeptest") {
            beeptestVO2 = value;
            yoyoVO2 = sportMatchState.userInputYoyoVO2MAX ? sportMatchState.userInputYoyoVO2MAX : 0;
            dispatch(setUserInputBeepTestVO2MAX(beeptestVO2));
        }

        if(beeptestVO2 != 0 && yoyoVO2 != 0) {
            finalUserVO2 = parseFloat((parseFloat(beeptestVO2) + parseFloat(yoyoVO2)) / 2);
        } else {
            finalUserVO2 = parseFloat(parseFloat(beeptestVO2) + parseFloat(yoyoVO2));
        }

        dispatch(setUserInputVO2MAX(finalUserVO2));
    }

    const getFieldsChanged = () => {
        if (sportMatchState.userInputWalkRunMinutes != "" && state.runWalkMinutes == "") {
            return true;
        } else if (sportMatchState.userInputWalkRunSeconds != "" && state.runWalkSeconds == "") {
            return true;
        } else if (sportMatchState.userInputVO2MAX != "" && state.vo2Max == "") {
            return true;
        } else {
            return false;
        }
    }

    const resetButtonHandler = () => {
        if (state.runWalkMinutes == "") {
            dispatch(setUserInputWalkRunMinutes(""));
        }

        if (state.runWalkSeconds == "") {
            dispatch(setUserInputWalkRunSeconds(""));
        }

        if (state.vo2Max == "") {
            dispatch(setUserInputVO2MAX(""));
            dispatch(setUserInputShuttleBeepTest(""));
        }

        dispatch(setUserInputYoyoTest(''));
        dispatch(setUserInputYoyoStage('1'));
        dispatch(setUserInputYoyoVO2MAX(''));
        dispatch(setUserInputBeepTestVO2MAX(''));
    }

    const aerobicIcon = () => {
        return <AerobicSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'} />;
    }

    return (
        <div className="screen-section test-section">
            <MyDetailsHeader
                children={[]}
                title={"Aerobic fitness"}
                icon={aerobicIcon()} isIconSvg={false} expand={expand} expanded={true}
                fieldChanged={getFieldsChanged()}
                resetBtnHandler={resetButtonHandler} />
            <div className={`myDetails-fields aerobic-fitness-fields expanded`}>
                <WalkRunTextField
                    minuteValue={sportMatchState.userInputWalkRunMinutes}
                    secondValue={sportMatchState.userInputWalkRunSeconds}
                    action={dispatchRunWalk}
                    label={userState.userUOM == "Imperial" ? "1 mile run/walk" : "1.6 km run/walk"}
                    placeholder={"mins:sec"}
                    info={fieldInfo.runWalk}
                    minValue={4} maxValue={20}
                    confirmedValueProvided={state.runWalkMinutes != "" || state.runWalkSeconds != ""}
                    infoOpen={infoOpen}  setInfoOpen={setInfoOpen}
                /> {/** Maximum converted into minutes */}
                <UserDefinedDropDownField
                    _value={sportMatchState.userInputShuttleBeepTest}
                    vo2MaxValue={sportMatchState.userInputVO2MAX}
                    action={dispatchBeepTest}
                    label={"Shuttle (beep) test"}
                    placeholder={"score"}
                    info={fieldInfo.beepTest}
                    confirmedValueProvided={state.beepTest != ""}
                    infoOpen={infoOpen}  setInfoOpen={setInfoOpen}
                />
                <YoyoTestSection 
                    userVO2MAX={sportMatchState.userInputVO2MAX} 
                    confirmedValueProvided={state.yoyoTest != ""}
                    action={(vo2) => dispatchVo2MaxValue(vo2, 'yoyo')} 
                />
            </div>
        </div>
    );
}

export default CardioRespiratory;
