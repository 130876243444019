import React from "react";

const ArmSpanSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>arm_span_4</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="arm_span_4" data-name="arm span 4">
            <path className="cls-2" style={{fill: color}} d="M283.74,242.5" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M283.74,242.5" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M506.69,220H60.79a22.5,22.5,0,0,0,0,45H229.85V378.61a53.89,53.89,0,0,0,53.89,53.89h0a53.89,53.89,0,0,0,53.89-53.89V265H506.69a22.5,22.5,0,1,0,0-45Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M306,130.31a41.67,41.67,0,0,0-22.15-6.4,42.29,42.29,0,0,0-9.45,1.08,41.86,41.86,0,1,0,50.17,31.43h0A41.6,41.6,0,0,0,306,130.31Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M515.44,160.79a5,5,0,0,0-5,5v13.62H327.54a45.7,45.7,0,0,1-4.51,10H510.44V203a5,5,0,1,0,10,0V165.79A5,5,0,0,0,515.44,160.79Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M240,179.41H57V165.79a5,5,0,0,0-10,0V203a5,5,0,0,0,10,0V189.41h187.4A45.61,45.61,0,0,1,240,179.41Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default ArmSpanSvg;
