import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
// Field Info
import { fieldInfo } from './fieldInfoContent.js';
// COMPONENTS
import { MyDetailsHeader } from '../../../components/MyDetailsHeader';
import {
    UserDefinedTextField,
    MuscularStrengthAndPowerSliderField,
    RadioField
} from '../../../components/FormInputs.js';
// ACTIONS
import {
    setStandingBroadJump,
    setLeftGripStrength,
    setRightGripStrength,
    setVerticalJump,
    setExpandedMuscularStrengthAndPower,
    setUserInputStandingBroadJump,
    setUserInputGripStrengthLeft,
    setUserInputGripStrengthRight,
    setUserInputVerticalJump
} from '../../../store/actions/myDetails.js';

import IM from 'imperial-metric';

// ICONS
import MuscularStrengthSvg from '../../../assets/compareMyResultsIcons/16032020/svgFiles/MuscularStrengthSvg.js';

const StrengthPower = ({ infoOpen, setInfoOpen: setInfoOpen }) => {
    const state = useSelector(store => store.myDetails);
    const sportMatchState = useSelector(store => store.sportMatchTest);
    const muscularStrengthAndPowerExpanded = state.muscularStrengthAndPowerSectionExpanded;
    const dispatch = useDispatch();
    const organisationStateLocal = useSelector(state => state.organisationState);
    const userState = useSelector(state => state.user);

    // Local State
    const [expanded, setExpanded] = useState(false);

    // Functions enabling child components to dispatch actions
    const dispatchStandingBroadJump = (input) => {
        dispatch(setUserInputStandingBroadJump(input))
    }
    const dispatchLeftGripStrength = (input) => {
        dispatch(setUserInputGripStrengthLeft(input))
    }
    const dispatchRightGripStrength = (input) => {
        dispatch(setUserInputGripStrengthRight(input))
    }
    const dispatchVerticalJump = (input) => {
        dispatch(setUserInputVerticalJump(input))
    }

    const expand = () => {
        dispatch(setExpandedMuscularStrengthAndPower(!muscularStrengthAndPowerExpanded));
        setExpanded(!expanded);
    }

    const getFieldsChanged = () => {
        if (sportMatchState.userInputStandingBroadJump != "" && state.standingBroadJump == "") {
            return true;
        } else if (sportMatchState.userInputGripStrengthLeft != "" && state.leftGripStrength == "") {
            return true;
        } else if (sportMatchState.userInputGripStrengthRight != "" && state.rightGripStrength == "") {
            return true;
        } else if (sportMatchState.userInputVerticalJump != "" && state.verticalJump == "") {
            return true;
        } else {
            return false;
        }
    }

    const resetButtonHandler = () => {
        if (state.standingBroadJump == "") {
            dispatch(setUserInputStandingBroadJump(""));
        }

        if (state.leftGripStrength == "") {
            dispatch(setUserInputGripStrengthLeft(""));
        }

        if (state.rightGripStrength == "") {
            dispatch(setUserInputGripStrengthRight(""));
        }

        if (state.verticalJump == "") {
            dispatch(setUserInputVerticalJump(""));
        }
    }

    const muscularStrengthIcon = () => {
        return <MuscularStrengthSvg color={organisationStateLocal.organisationPrimaryColor != '' ? organisationStateLocal.organisationPrimaryColor : '#5A5380'} />;
    }

    return (
        <div className="screen-section test-section">
            <MyDetailsHeader
                children={[]}
                title={"Muscular strength and power"}
                icon={muscularStrengthIcon()}
                isIconSvg={false}
                expand={expand}
                expanded={true}
                fieldChanged={getFieldsChanged()}
                resetBtnHandler={resetButtonHandler}
            />
            <div className={`myDetails-fields expanded`}>
                <MuscularStrengthAndPowerSliderField 
                    action={dispatchStandingBroadJump} 
                    label={"Standing broad jump"} 
                    labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}
                    info={fieldInfo.standingBroadJump} 
                    enteredValue={sportMatchState.userInputStandingBroadJump} 
                    minValue={0} maxValue={userState.userUOM == "Imperial" ? 125 : 320}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.standingBroadJump != ""} />

                <MuscularStrengthAndPowerSliderField 
                    action={dispatchLeftGripStrength} 
                    label={"Grip strength - left"} 
                    labelUOM={userState.userUOM == "Imperial" ? "lbs" : "kg"}  
                    info={userState.userUOM == "Imperial" ? fieldInfo.gripStrength_imperial : fieldInfo.gripStrength} 
                    enteredValue={sportMatchState.userInputGripStrengthLeft} 
                    minValue={0} maxValue={userState.userUOM == "Imperial" ? 198 : 90}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.leftGripStrength != ""} />

                <MuscularStrengthAndPowerSliderField 
                    action={dispatchRightGripStrength} 
                    label={"Grip strength - right"} 
                    labelUOM={userState.userUOM == "Imperial" ? "lbs" : "kg"}  
                    info={userState.userUOM == "Imperial" ? fieldInfo.gripStrength_imperial : fieldInfo.gripStrength} 
                    enteredValue={sportMatchState.userInputGripStrengthRight} 
                    minValue={0} maxValue={userState.userUOM == "Imperial" ? 198 : 90}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.rightGripStrength != ""} />

                <MuscularStrengthAndPowerSliderField 
                    action={() => { }} 
                    label={"Average grip strength"} 
                    labelUOM={userState.userUOM == "Imperial" ? "lbs" : "kg"}  
                    info={userState.userUOM == "Imperial" ? fieldInfo.gripStrength_imperial : fieldInfo.gripStrength} 
                    enteredValue={0} informationOnly={true}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen} />

                <MuscularStrengthAndPowerSliderField 
                    action={dispatchVerticalJump} 
                    label={"Vertical jump"} 
                    labelUOM={userState.userUOM == "Imperial" ? "in" : "cm"}  
                    info={fieldInfo.verticalJump} 
                    enteredValue={sportMatchState.userInputVerticalJump} 
                    minValue={0} maxValue={userState.userUOM == "Imperial" ? 43 : 110}
                    infoOpen={infoOpen} setInfoOpen={setInfoOpen}
                    confirmedValueProvided={state.verticalJump != ""} />
            </div>
        </div>
    );
}

export default StrengthPower;
