import React from 'react';
import { MARKETING_DOMAIN } from '../../../../store/constants';

import './style.css';

export default function InaccessibleComponent () {
    return (
        <div className="inaccessible-feature-body">
            <div className="inaccessible-feature-container">
                <span className="inaccessible-feature-span">Unable to view in this package - <a href={`${MARKETING_DOMAIN}`}>upgrade plan to access</a></span>
            </div>
        </div>
    )
}