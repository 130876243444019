import React from "react";

const ArmSpan2Svg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>arm_span_2</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="arm_span_2" data-name="arm span 2">
            <path className="cls-2" style={{fill: color}} d="M284,242.5" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M284,336.52" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M284,338.45" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M284,242.5" transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M490.69,220H77.33a22.5,22.5,0,1,0,0,45H230.12V378.61A53.89,53.89,0,0,0,284,432.5h0a53.89,53.89,0,0,0,53.89-53.89V265H490.69a22.5,22.5,0,1,0,0-45Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}} d="M248.54,143.56a41.86,41.86,0,1,0,76.27,12.86h0a41.86,41.86,0,0,0-76.27-12.86Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M498.79,160.79a5,5,0,0,0-5,5v13.62h-166a46.38,46.38,0,0,1-4.5,10H493.79V203a5,5,0,1,0,10,0V165.79A5,5,0,0,0,498.79,160.79Z"
                  transform="translate(-2.83 -7.46)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M240.23,179.41h-166V165.79a5,5,0,0,0-10,0V203a5,5,0,0,0,10,0V189.41H244.71A45.14,45.14,0,0,1,240.23,179.41Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default ArmSpan2Svg;
