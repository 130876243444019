import React, { useState, useEffect } from 'react';

// REDUX
import { useSelector } from 'react-redux';

// CHARTJS
import { Radar } from 'react-chartjs-2';

const SportTypes = ({ topFiveSports, powerSportType, speedSportType, aerobicFitnessSportType, gameFitnessSportType, skillSportType, strengthSportType }) => {
    const organisationLocalState = useSelector(state => state.organisationState);

    const getRankingForEachSportType = () => {
        // Array of sport type rankings
        var arrayOfSportTypeValues = [
            {name: 'power', value: powerSportType},
            {name: 'speed', value: speedSportType},
            {name: 'aerobic-fitness', value: aerobicFitnessSportType},
            {name: 'game-fitness', value: gameFitnessSportType},
            {name: 'skill', value: skillSportType},
            {name: 'strength', value: strengthSportType},
        ];

        var sortedArrayOfSportTypeValues = arrayOfSportTypeValues.sort(function(a, b) {
            return a.value - b.value;
        });

        return ([
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'power') + 1) + '. Power'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'strength') + 1) + '. Strength'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'speed') + 1) + '. Speed'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'aerobic-fitness') + 1) + '. Aerobic fitness'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'game-fitness') + 1) + '. Game fitness'],
            [(sortedArrayOfSportTypeValues.findIndex(s => s.name === 'skill') + 1) + '. Skill']
        ])
    }

    return (
        <div className="screen-section my-results-section sport-types">
            <div className="top-5-sports-heading">
                <span>Top sport types</span>
            </div>
            <div className="sport-types-chart-container">
                <Radar
                    height={310}
                    width={370}
                    legend={{ display: false }}
                    data={{
                        labels: getRankingForEachSportType(),
                        datasets: [
                            {
                                label: 'My Second dataset2',
                                backgroundColor: organisationLocalState.organisationPrimaryColor ? organisationLocalState.organisationPrimaryColor + "a3" : 'rgb(154, 98, 179, .3)',
                                borderColor: organisationLocalState.organisationPrimaryColor ? organisationLocalState.organisationPrimaryColor + "a3" : 'rgb(154, 98, 179, .3)',
                                pointBackgroundColor: 'rgba(255,99,132,1)',
                                pointBorderColor: '#fff',
                                pointRadius: 0,
                                pointHoverBackgroundColor: '#fff',
                                pointHoverBorderColor: 'rgba(255,99,132,1)',
                                data: [powerSportType, strengthSportType, speedSportType, aerobicFitnessSportType, gameFitnessSportType, skillSportType],
                            }
                        ]
                    }}
                    options={{
                        responsive: false,
                        scale: {
                            angleLines: {
                                display: true,
                            },
                            pointLabels: {
                                display: true,
                                fontSize: 15
                            },
                            ticks: {
                                display: false,
                                reverse: true,
                                maxTicksLimit: 6,
                                backdropColor: 'rgb(50, 168, 82)'
                            }
                        }
                    }} />
            </div>

        </div>
    )
}

export default SportTypes;
