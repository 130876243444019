import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import '../style.css';
import { BACKEND_URL, ENVIRONMENT_PATH } from '../../../../store/constants';
import { NormsRow } from './normsRow';
import { useToasts } from 'react-toast-notifications';

// SVG
import { ReactComponent as SaveIcon } from '../../../../assets/save-solid.svg';
import FilterIcon from '../../../../assets/filter.png';
import { createPortal } from 'react-dom';
import FilterComponent from '../../organizationAdminScreens/classInfo/FilterComponent';

import $ from "jquery";
import { CircularProgress } from '@material-ui/core';
import { AdminLoadingIndicator } from './loadingIndicator';

export const EditNormComponent = () => {
    const reduxState = useSelector(state => state);
    const { addToast } = useToasts();

    const [normData, setNormData] = useState([]);
    const [orderedNormData, setOrderedNormData] = useState([]);
    const [editedNorms, setEditedNorms] = useState({});

    const [normNames, setNormNames] = useState([]);

    const [showFilterMenu, setShowFilterMenu] = useState(false);

    const [frontendRenderedRows, setFrontendRenderedRows] = useState([]);

    // Loading indicators
    const [loadingNorms, setLoadingNorms] = useState(false);
    const [savingNorms, setSavingNorms] = useState(false);

    // Loading indicator text
    const [loadingText, setLoadingText] = useState("Loading");

    const filterStringBuilder = () => {
        var string = "";

        if (reduxState.adminState.normNameFilter.length > 0) {
            var arrayString = reduxState.adminState.normNameFilter.map(e => "'" + e + "'");
            string += `&nameFilter=${arrayString}`;
        }

        if (reduxState.adminState.normGenderFilter.length > 0) {
            string += `&genderFilter=${reduxState.adminState.normGenderFilter}`;
        }

        if (reduxState.adminState.normAgeFilter.length > 0) {
            var arrayString = reduxState.adminState.normAgeFilter.map(e => e);
            string += `&ageFilter=${arrayString}`;
        }

        return string;
    }

    const sortedList = [
        "norm_id",
        "Name", "Gender",
        "Age", "Height",
        "Sport type 1", "Sport type 2",
        "Height (SD)", "Weight",
        "Weight (SD)", "Situps",
        "Situps (SD)", "Standing broad jump",
        "Standing broad jump (SD)", "Sit and reach",
        "Sit and reach (SD)", "Pullup",
        "Pullup (SD)", "Shuttle",
        "Shuttle (SD)", "Walk run",
        "Walk run (SD)", "Grip strength",
        "Grip strength (SD)", "Speed",
        "Speed (SD)", "Endurance",
        "Endurance (SD)", "Power",
        "Power (SD)", "Arm span height ratio",
        "Arm span height ratio (SD)", "Sitting height ration",
        "Sitting height ration (SD)", "Brachial index",
        "Brachial index (SD)"]

    useEffect(() => {
        setLoadingNorms(true);
        setLoadingText("Loading");
        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/norms?admin=true`, {
            method: 'GET',
            credentials: 'include'
        }).then(res => {
            res.json().then(result => {
                if (result.norm_data) setNormData(result.norm_data);
                if (result.norm_names) setNormNames(result.norm_names);
            }).catch(err => {
                console.log("An error occured: ", err);
            })
        }).catch(er => {
            console.log("An error occured: ", er);
        });
    }, []);

    useEffect(() => {
        var arrayOfOrderedData = [];

        if(normData.length == 0) setOrderedNormData(arrayOfOrderedData);

        for (var i = 0; i < normData.length; i++) {
            var object = normData[i];
            var orderedObject = {};

            for (var l = 0; l < sortedList.length; l++) {
                orderedObject[sortedList[l]] = object[sortedList[l]];
            }

            Object.keys(object).forEach(function (key) {
                orderedObject[key] = object[key];
            });

            arrayOfOrderedData.push(orderedObject);

            if(i == (normData.length - 1)) {
                setOrderedNormData(arrayOfOrderedData);
                renderRows(arrayOfOrderedData);
            } 
        }
    }, [normData]);

    useEffect(() => {
        var filterString = filterStringBuilder();
        setLoadingNorms(true);
        setLoadingText("Loading");
        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/norms?admin=true&filtered=true${encodeURI(filterString)}`, {
            method: 'GET',
            credentials: 'include'
        }).then(res => {
            res.json().then(result => {
                if (result.norm_data) setNormData(result.norm_data);
            }).catch(err => {
                console.log("An error occured: ", err);
            })
        }).catch(er => {
            console.log("An error occured: ", er);
        });
    },
    [
        JSON.stringify(reduxState.adminState.normGenderFilter),
        JSON.stringify(reduxState.adminState.normNameFilter),
        JSON.stringify(reduxState.adminState.normAgeFilter)
    ])

    const onChangeHandler = (type, normId, value) => {
        var editedNormsLocal = editedNorms;
        if (!editedNormsLocal[normId + ""])
            editedNormsLocal[normId + ""] = {};

        if (!editedNormsLocal[normId + ""][type.toLowerCase()])
            editedNormsLocal[normId + ""][type.toLowerCase()] = {};

        editedNormsLocal[normId + ""][type.toLowerCase()] = value;
        setEditedNorms(editedNormsLocal);
    }

    const saveHandler = () => {
        setSavingNorms(true);
        setLoadingText("Saving, please wait");

        var singleRequestObject = {};
        var arrayOfMultipleRequestObjects = [];

        if (Object.keys(editedNorms).length == 1) {
            var singleRequestObject = editedNorms[Object.keys(editedNorms)[0]];

            singleRequestObject["norms_id"] = Object.keys(editedNorms)[0];
            arrayOfMultipleRequestObjects.push(singleRequestObject);
        } else {
            for (var i = 0; i < Object.keys(editedNorms).length; i++) {
                var singleRequestObject = editedNorms[Object.keys(editedNorms)[i]];
                singleRequestObject["norms_id"] = Object.keys(editedNorms)[i];
                arrayOfMultipleRequestObjects.push(singleRequestObject);
            }
        }

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/norms`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(arrayOfMultipleRequestObjects)
        }).then(res => {
            res.json().then(result => {
                addToast(`Norm is updated`, {
                    appearance: 'success',
                    autoDismiss: true
                });
                setSavingNorms(false);
                setLoadingText("Loading");
            }).catch(er => {
                addToast(`An error occured, no data was saved`, {
                    appearance: 'error',
                    autoDismiss: true
                });
                setSavingNorms(false);
                setLoadingText("Loading");
            })
        }).catch(error => {
            addToast(`An error occured, no data was saved`, {
                appearance: 'error',
                autoDismiss: true
            });
            setSavingNorms(false);
            setLoadingText("Loading");
        })
    }

    const renderRows = (data) => {
        var dom = [];
        for(var i = 0; i < data.length; i++) {
            dom.push(
                <NormsRow key={data[i].norm_id} normObj={data[i]} onChangeHandler={onChangeHandler} />
            )

            if(i == (data.length - 1)) {
                setLoadingNorms(false);
                setFrontendRenderedRows(dom);
            } 
        }
    }

    $('#norms-wrapper').on('scroll',function(){
        $('.norms-heading').scrollLeft($(this).scrollLeft());
    });

    return (
        <div className="screen-section admin-screen-section" style={{ overflow: 'visible' }}>
            <div id="norms-wrapper" style={{ width: '100%', overflowX: 'scroll' }}>

                {(loadingNorms || savingNorms) && <AdminLoadingIndicator value={loadingText} />}

                <div className="norms-heading">
                    {orderedNormData[0] ? Object.keys(orderedNormData[0]).map(e => {
                        var value = e;
                        if (value.includes("(SD)")) {
                            value = value.split("(SD)");

                            if (value[0] === 'Sitting height ration ') {
                                value[0] = 'Sitting height ratio';
                            }

                            return (
                                <div className="norm-name">
                                    <span>{value[0]}</span>
                                    <span className="sd">(standard deviation)</span>
                                </div>
                            )
                        } else {
                            if (value != "norm_id") {
                                if (value === 'Sitting height ration') value = 'Sitting height ratio';

                                return (
                                    <div className="norm-name">{value}</div>
                                )
                            }
                        }
                    }) : null}
                </div>

                <div className="norm-data-container">
                    {frontendRenderedRows}
                </div>
            </div>

            {createPortal(
                <div id="filter-widget-admin">
                    <FilterComponent type={"admin"} showFilterFunction={() => setShowFilterMenu(!showFilterMenu)} show={showFilterMenu} listOfGroups={[]} normNames={normNames} />
                    <img onClick={() => setShowFilterMenu(!showFilterMenu)} id="filter-admin-icon" src={FilterIcon} />
                    <div onClick={() => saveHandler()} className="save-icon-admin" style={{ position: 'absolute', top: '35px', right: '60px' }}>
                        <SaveIcon />
                    </div>
                </div>, document.getElementById("root"))}

        </div>
    )
};
