import React from "react";

const VerticalJumpSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Vertical Jump</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="vertical_jump" data-name="vertical jump">
            <circle className="cls-2" style={{fill: color}} cx="283.45" cy="111.87" r="36.7"
                    transform="translate(105.73 353.96) rotate(-76.51)"/>
            <path className="cls-2" style={{fill: color}}
                  d="M394.34,64.17a18.83,18.83,0,0,0-37.62,1.61c0,.46,1.27,46.61-23.81,72.79-12,12.56-28.21,18.66-49.46,18.66-25.06,0-46.64,9-64.15,26.76-47.64,48.31-46.82,147.11-46.76,151.29a18.83,18.83,0,0,0,18.82,18.56h.27a18.83,18.83,0,0,0,18.56-19.08c-.31-21.91,4.45-81.15,28.17-115V488.9a18.83,18.83,0,1,0,37.65,0V335h14.88V488.9a18.83,18.83,0,1,0,37.65,0V186.4a93.88,93.88,0,0,0,31.55-21.79C396.57,126.54,394.45,66.7,394.34,64.17Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default VerticalJumpSvg;
