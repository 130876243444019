import React, { useState, useEffect } from 'react';

export const ScoutSliderHeading = ({label, uom, value, setValue = null, loadedData}) => {
    return (
        <div style={{ transform: "translate(0px, 15px)"}}>
            <div style={{minWidth: '150px', width: '150px'}}></div>
            <div className="stats-selector-container">
                <div className="stats-holder">
                    <span className="stats-indicator">2/3</span>
                    <span>1</span>
                    <span>2</span>
                    <span></span>
                </div>

                <div className="slider">
                    <span style={{ position: 'relative', left: '20px'}}>x 1/3</span>
                    <input style={{visibility: 'hidden'}} disabled={!loadedData} type="range" onChange={e => setValue(e.target.value)} value={value} min={0} max={4} />
                    <span style={{ position: 'relative', left: '-15px'}}>x 3</span>
                </div>
            </div>
        </div>
    )
}