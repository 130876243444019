import React, { useState, useEffect, useRef } from 'react';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// STYLES
import './style.css';

// USER DEFINED COMPONENTS
import { AccountDetailsTextField } from '../../../../components/organizationAdminComponents/accountDetailsComponents/AccountDetailsField.tsx';
import AccountDetailsSelectField from '../../../../components/organizationAdminComponents/accountDetailsComponents/AccountDetailsSelectField.js';
import AccountDetailsTypography from '../../../../components/organizationAdminComponents/accountDetailsComponents/AccountDetailsTypography.js';
import SportMatchBasicProgram from '../../../../components/organizationAdminComponents/accountDetailsComponents/SportMatchBasicProgram.js';

// MATERIAL UI COMPONENTS
import Button from '@material-ui/core/Button';

// MATERIAL UI DIALOG COMPONENTS
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

// COMPONENTS
import UploadTeacher from './UploadTeacherComponent.js';

// MATERIAL UI
import CircularProgress from '@material-ui/core/CircularProgress';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'

import {
    BACKEND_URL,
    ENVIRONMENT_PATH,
    MARKETING_DOMAIN
} from '../../../../store/constants';

// REDUX ACTIONS
import {
    setPrimaryColor,
    setSecondaryColor,
    setAccentColor
} from '../../../../store/actions/OrganisationActions.js';

import InputColor from 'react-input-color';

// MOMENTJS
import moment from 'moment';

// JQUERY
import $ from "jquery";
import { setUserId, setUserUOM, setTokenAllocationType } from '../../../../store/actions/actions';
import { CheckIfUserIsLoggedIn, CheckIfUserSessionIsValid } from '../../../../api/Helper';
import SportSelectionComponent from './SportSelectionComponent/sportSelectionComponent';
import BrandLogo from './brandLogo';

const zxcvbn = require("zxcvbn");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AccountDetailsScreen = () => {
    const dispatch = useDispatch();

    // React Toast Notifications Object
    const { addToast } = useToasts();

    // State for storing password of organisation itself
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);

    // State for storing default password for organisation
    const [openChangeDefaultPasswordDialog, setOpenChangeDefaultPasswordDialog] = useState(false);

    const reduxState = useSelector(state => state);

    const [intialRender, setInitialRender] = useState(false);

    const [organisationID, setOrganisationID] = useState("");
    const [organisationName, setOrganisationName] = useState("");
    const [organisationType, setOrganisationType] = useState("");
    const [organisationAddressLine1, setOrganisationAddressLine1] = useState("");
    const [organisationState, setOrganisationState] = useState("");
    const [organisationPostcode, setOrganisationPostcode] = useState("");
    const [organisationCountry, setOrganisationCountry] = useState("");

    // Unit of mesurement
    const usingImperial = reduxState.user.userUOM == "Imperial";
    const [uom, setUOM] = useState(reduxState.user.userUOM);

    // Token Allocation type
    const [tokenAllocationTypeLocal, setTokenAllocationTypeLocal] = useState(reduxState.user.tokenAllocationType)

    // Password for current logged in user
    const [currentPwd, setCurrentPwd] = useState("");
    const [signupPwd, setSignupPwd] = useState("");
    const [signupPwdConfirm, setSignupPwdConfirm] = useState("");
    const [passwordScore, setPasswordScore] = useState(0);
    const [passwordError, setPasswordErr] = useState("");

    // Organisation link
    const [organisationLink, setOrganisationLink] = useState("");

    // Default password fields
    const [currentDefaultPassword, setCurrentDefaultPassword] = useState("");
    const [newDefaultPassword, setNewDefaultPassword] = useState("");
    const [confirmNewDefaultPassword, setConfirmNewDefaultPassword] = useState("");
    const [newDefaultPasswordScore, setNewDefaultPasswordScore] = useState(0);

    // Brand color change
    const [primaryColor, setLocalPrimaryColor] = useState('');
    const [secondaryColor, setLocalSecondaryColor] = useState("");
    const [accentColor, setLocalAccentColor] = useState("");

    const [primaryColorToUse, setPrimaryColorToUse] = useState('#9a62b3');
    const [secondaryColorToUse, setSecondaryColorToUse] = useState('#778599');
    const [accentColorToUse, setAccentColorToUse] = useState('#50B383');

    const [plans, setPlans] = useState([]);

    // Loading indicators
    const [loadingPlans, setLoadingPlans] = useState(true);
    const [accountDetailsLoading, setAccountDetailsLoading] = useState(true);
    const [accountCredentialsLoading, setAccountCredentialsLoading] = useState(true);
    const [accountDefaultPasswordLoading, setAccountDefaultPasswordLoading] = useState(true);

    const [saveDefaultPasswordLoading, setSaveDefaultPasswordLoading] = useState(false);
    const [brandColorLoading, setBrandColorLoading] = useState(false);

    const timeoutRef = useRef(null);

    // init load
    useEffect(() => {
        CheckIfUserSessionIsValid(dispatch).then(() => {
            fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=get_org_details`,
                { credentials: "include" }
            ).then(res => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    res.json().then(result => {
                        if (result.org_id) {
                            setOrganisationID(result.org_id);
                        }
                        if (result.name) {
                            setOrganisationName(result.name);
                        }
                        if (result.type) {
                            setOrganisationType(result.type);
                        }
                        if (result.address_line_1) {
                            setOrganisationAddressLine1(result.address_line_1);
                        }
                        if (result.state) {
                            setOrganisationState(result.state);
                        }
                        if (result.postcode) {
                            setOrganisationPostcode(result.postcode);
                        }
                        if (result.country) {
                            setOrganisationCountry(result.country);
                        }
                        if (result.org_default_password) {
                            setCurrentDefaultPassword(result.org_default_password);
                        }
                        if (result.org_primary_color) {
                            setPrimaryColorToUse(result.org_primary_color);
                            dispatch(setPrimaryColor(result.org_primary_color));
                        }
                        if (result.org_secondary_color) {
                            setSecondaryColorToUse(result.org_secondary_color);
                            dispatch(setSecondaryColor(result.org_secondary_color));
                        }
                        if (result.org_accent_color) {
                            setAccentColorToUse(result.org_accent_color);
                            dispatch(setAccentColor(result.org_accent_color));
                        }

                        if (result.admin_id && result.admin_id != "")
                            dispatch(setUserId(result.admin_id))

                        if (result.uom) {
                            setUOM(result.uom);
                            dispatch(setUserUOM(result.uom));
                        }
                    }).then(() => {
                        setInitialRender(true);
                    }).catch(() => {
                        setInitialRender(true);
                        setAccountDefaultPasswordLoading(false);
                        setAccountCredentialsLoading(false);
                        setAccountDetailsLoading(false);
                    });

                    setAccountDefaultPasswordLoading(false);
                    setAccountCredentialsLoading(false);
                    setAccountDetailsLoading(false);
                }).catch(() => {
                    setAccountCredentialsLoading(false);
                    setAccountDefaultPasswordLoading(false);
                    setAccountDetailsLoading(false);
                    setInitialRender(true);
                });
            }).catch(err => {
                console.log("Error: ", err);
                setInitialRender(true);
            });
        })
    }, [])

    useEffect(() => {
        var urlencoded = new URLSearchParams();
        urlencoded.append("fromOrg", true);

        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=get_app_link`, {
            credentials: 'include',
            method: 'POST',
            body: urlencoded
        }).then(res => {
            CheckIfUserIsLoggedIn(res, dispatch).then(() => {
                res.json().then(result => {
                    setOrganisationLink(result.link);
                }).catch(err => console.log("An error occured: ", err));
            })
        }).catch(err => console.log("An error occured: ", err))
    }, [])

    // Get current and active plans for the organisation
    useEffect(() => {
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/getorgplansfororganisation`, {
            method: 'GET',
            credentials: 'include'
        }).then(res => {
            CheckIfUserSessionIsValid(dispatch).then(() => {
                res.json().then(result => {
                    var mainPlans = result.main_plan;
                    var boosterPacks = result.booster_packs;
                    var freePacks = result.free_packs;

                    var packsInTotal = [];
                    packsInTotal.concat(mainPlans);
                    packsInTotal.concat(boosterPacks);
                    packsInTotal.concat(freePacks[0]);

                    setLoadingPlans(false);
                    setPlans(result);
                }).catch(er => {
                    setLoadingPlans(false);
                    console.log("An error occured: ", er);
                })
            });
        }).catch(error => {
            setLoadingPlans(false);
            console.log("An error occured: ", error);
        })
    }, []);

    const modifyOrgDefaultPassword = (reqObj) => {
        return new Promise((resolve, reject) => {
            CheckIfUserSessionIsValid(dispatch).then(() => {
                fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/orgdefaultpassword`, {
                    method: 'POST',
                    credentials: "include",
                    body: JSON.stringify(reqObj)
                }).then(res => {
                    CheckIfUserSessionIsValid(dispatch).then(() => {
                        res.json().then(result => {
                            if (Object.keys(result).includes("default_password")) {
                                setCurrentDefaultPassword(result.default_password);
                            } else {
                                setCurrentDefaultPassword("sportmatch1");
                            }
                            resolve(result);
                        }).catch(error => {
                            console.log("An error occured: ", error);
                            resolve();
                        })
                    })
                }).catch(er => {
                    console.log("Error = ", er);
                    resolve();
                })
            })
        })
    }

    useEffect(() => {
        if (intialRender) {
            if (timeoutRef.current != null) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null;
                setAccountDetailsLoading(true);

                var string = "&name=" + organisationName
                    + "&type=" + organisationType
                    + "&addressLine1=" + organisationAddressLine1
                    + "&state=" + organisationState
                    + "&postcode=" + organisationPostcode
                    + "&country=" + organisationCountry
                    + "&uom=" + uom
                    + "&tokenAllocationType=" + tokenAllocationTypeLocal;

                CheckIfUserSessionIsValid(dispatch).then(() => {
                    fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/Organisation?method=set_organisation_details${string}`,
                        { credentials: "include" }).then(res => {
                            CheckIfUserSessionIsValid(dispatch).then(() => {
                                res.json().then(result => {
                                    setAccountDetailsLoading(false);
                                })
                            })
                        })
                })
            }, 500);
        }
    }, [organisationName, organisationCountry, organisationPostcode, organisationState, organisationType, organisationAddressLine1, uom, tokenAllocationTypeLocal])


    const renderPasswordScore = (score) => {
        var scoreWord = "poor";

        switch (score) {
            case 0:
                scoreWord = "poor";
                break;
            case 1:
                scoreWord = "poor";
                break;
            case 2:
                scoreWord = "weak";
                break;
            case 3:
                scoreWord = "average";
                break;
            case 4:
                scoreWord = "excellent";
                break;
        }
        return (
            <div className="password-strength-levels">
                <div className={`level-${scoreWord} first-level ${score < 2 ? 'empty' : ''}`}></div>
                <div className={`level-${scoreWord} second-level ${score < 3 ? 'empty' : ''}`}></div>
                <div className={`level-${scoreWord} third-level ${score < 4 ? 'empty' : ''}`}></div>

                <span className={`level-${scoreWord} password-level-span`}>{scoreWord}</span>
            </div>
        )
    }

    const passwordCurrentOnChangeHandler = value => {
        setPasswordScore(zxcvbn(value).score);
    }

    const passwordOnChangeHandler = obj => {
        if (obj.type == "org_password") {
            setPasswordScore(zxcvbn(obj.value).score);
            setSignupPwd(obj.value);
        } else {
            setNewDefaultPasswordScore(zxcvbn(obj.value).score);
            setNewDefaultPassword(obj.value);
        }
    }

    const passwordConfirmedOnChangeHandler = obj => {
        if (obj.type == "org_password") {

            const passwordInputValue = obj.value;

            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp = /.{8,}/;
            const maxLengthRegExp = /^.{8,64}$/;

            const passwordLength = passwordInputValue.length;
            const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
            const digitsPassword = digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword = minLengthRegExp.test(passwordInputValue);
            const maxLengthPassword = maxLengthRegExp.test(passwordInputValue);

            let errMsg = "";

            if (passwordLength === 0) {
                errMsg += "Password is empty";
            }
            if (!uppercasePassword) {
                errMsg += "At least one Uppercase";
            } 
            if (!lowercasePassword) {
                errMsg += "At least one Lowercase";
            } 
            if (!digitsPassword) {
                errMsg += "At least one digit";
            }
            if (!specialCharPassword) {
                errMsg += "At least one Special Characters";
            }
            if (!minLengthPassword) {
                errMsg += "At least minumum 8 characters";
            }
            if (!maxLengthPassword) {
                errMsg += "Maximum length is 64 characters";
            }

            setPasswordErr(errMsg);

            setSignupPwdConfirm(obj.value);
        } else {
            setConfirmNewDefaultPassword(obj.value)
        }
    }

    const passwordDialogCloseBtnHandler = () => {
        setPasswordScore(0);
        setSignupPwd("");
        setSignupPwdConfirm("");
        setCurrentPwd("");
        setOpenChangePasswordDialog(false);

        // Resetting default password fields
        setOpenChangeDefaultPasswordDialog(false);
        setNewDefaultPasswordScore(0);
        setConfirmNewDefaultPassword("");
        setNewDefaultPassword("");
    }

    const saveNewPasswordBtnHandler = () => {
        setOpenChangePasswordDialog(false);

        var jsonRequest = {
            "password": signupPwd,
            "current_password": currentPwd
        };

        CheckIfUserSessionIsValid(dispatch).then(() => {
            fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/Organisation`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify(jsonRequest)
            }).then(res => {
                CheckIfUserSessionIsValid(dispatch).then(() => {
                    res.json().then(result => {
                        if (result.status == "failed") {
                            addToast(result.message, {
                                appearance: 'error',
                                autoDismiss: true
                            });
                        } else if (result.status == "success") {
                            addToast(result.message, {
                                appearance: 'success',
                                autoDismiss: true
                            });
                            if (reduxState.user.userRole === 'organisation') {
                                fetch(`${MARKETING_DOMAIN}wp-json/hestia-child/v1/update_password/`, {
                                    "method": "POST",
                                    "headers": {
                                        "Content-Type": "application/x-www-form-urlencoded"
                                    },
                                    "data": {
                                        "username": reduxState.user.userId,
                                        "new_password": signupPwd
                                    }
                                });
                            }
                        }
                    }).catch(err => {
                        console.log("An error occured: ", err);
                    })
                })
            }).catch(err => {
                console.log("An error occured: ", err);
            })
        })
    };

    const setPreviewBrandColors = () => {
        if (reduxState.organisationState.plan !== "Starter" && reduxState.organisationState.plan !== "Professional") {
            $('.body-container').css('background-color', 'white');
            $('.body-container .sidemenu-container .sidemenu-user-container').css('border-bottom-color', 'white');

            var primaryColorHex = "";
            var secondaryColorHex = "";
            var accentColorHex = "";

            if (typeof primaryColor == "object") primaryColorHex = primaryColor.hex;
            if (typeof secondaryColor == "object") secondaryColorHex = secondaryColor.hex;
            if (typeof accentColor == "object") accentColorHex = accentColor.hex;

            if (primaryColorHex && primaryColorHex.indexOf("#") == -1) primaryColorHex = `#${primaryColorHex}`;
            if (secondaryColorHex && secondaryColorHex.indexOf("#") == -1) secondaryColorHex = `#${secondaryColorHex}`;
            if (accentColorHex && accentColorHex.indexOf("#") == -1) accentColorHex = `#${accentColorHex}`;

            dispatch(setPrimaryColor(primaryColorHex));
            dispatch(setSecondaryColor(secondaryColorHex));
            dispatch(setAccentColor(accentColorHex));
        }
    }

    const setBrandColors = () => {
        if (reduxState.organisationState.plan !== "Starter" && reduxState.organisationState.plan !== "Professional") {
            $('.body-container').css('background-color', 'white');
            $('.body-container .sidemenu-container .sidemenu-user-container').css('border-bottom-color', 'white');

            var primaryColorHex = "";
            var secondaryColorHex = "";
            var accentColorHex = "";

            if (typeof primaryColor == "object") primaryColorHex = primaryColor.hex;
            if (typeof secondaryColor == "object") secondaryColorHex = secondaryColor.hex;
            if (typeof accentColor == "object") accentColorHex = accentColor.hex;

            if (primaryColorHex.indexOf("#") == -1) primaryColorHex = `#${primaryColorHex}`;
            if (secondaryColorHex.indexOf("#") == -1) secondaryColorHex = `#${secondaryColorHex}`;
            if (accentColorHex.indexOf("#") == -1) accentColorHex = `#${accentColorHex}`;

            var jsonRequest = {
                "brand_primary_colour": primaryColorHex,
                "brand_secondary_colour": secondaryColorHex,
                "brand_accent_colour": accentColorHex
            };

            setBrandColorLoading(true);

            CheckIfUserSessionIsValid(dispatch).then(() => {
                fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/Organisation`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json'
                    },
                    credentials: "include",
                    body: JSON.stringify(jsonRequest)
                }).then(res => {
                    CheckIfUserSessionIsValid(dispatch).then(() => {
                        res.json().then(result => {
                            if (result.status == "failed") {
                                addToast(result.message, {
                                    appearance: 'error',
                                    autoDismiss: true
                                });
                            } else if (result.status == "success") {
                                addToast(result.message, {
                                    appearance: 'success',
                                    autoDismiss: true
                                });

                                dispatch(setPrimaryColor(primaryColorHex));
                                dispatch(setSecondaryColor(secondaryColorHex));
                                dispatch(setAccentColor(accentColorHex));

                                // setLocalPrimaryColor(primaryColorHex);
                                // setLocalSecondaryColor(secondaryColorHex);
                                // setLocalAccentColor(accentColorHex);

                                setPrimaryColorToUse(primaryColorHex);
                                setSecondaryColorToUse(secondaryColorHex);
                                setAccentColorToUse(accentColorHex);
                                setBrandColorLoading(false);
                            }
                        }).catch(err => {
                            console.log("An error occured: ", err);
                            setBrandColorLoading(false);
                        })
                    })
                }).catch(err => {
                    console.log("An error occured: ", err);
                });
            })
        }
    }

    const defaultButtonClickHandler = () => {
        var jsonRequest = {
            "set_to_default_colors": true,
        };

        setBrandColorLoading(true);

        fetch(`${BACKEND_URL}${ENVIRONMENT_PATH}/Organisation`, {
            method: "POST",
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(jsonRequest)
        }).then(res => {
            CheckIfUserSessionIsValid(dispatch).then(() => {
                res.json().then(result => {
                    if (result.status == "failed") {
                        addToast(result.message, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                        setBrandColorLoading(false);
                    } else if (result.status == "success") {
                        addToast(result.message, {
                            appearance: 'success',
                            autoDismiss: true
                        });

                        setBrandColorLoading(false);

                        dispatch(setPrimaryColor(""));
                        dispatch(setSecondaryColor(""));
                        dispatch(setAccentColor(""));

                        setLocalPrimaryColor("#9a62b3");
                        setLocalSecondaryColor("#778599");
                        setLocalAccentColor("#50B383");

                        setPrimaryColorToUse("#9a62b3");
                        setSecondaryColorToUse("#778599");
                        setAccentColorToUse("#50B383");

                        $('.body-container').css('background-color', 'rgb(90, 83, 128)');
                        $('.body-container .sidemenu-container .sidemenu-user-container').css('border-bottom-color', 'rgb(90, 83, 128)');
                        $('body .color-square:eq(0)').find('span').find('span').css('background-color', '#9a62b3');
                        $('body .color-square:eq(1)').find('span').find('span').css('background-color', '#778599');
                        $('body .color-square:eq(2)').find('span').find('span').css('background-color', '#50B383');
                        $(".header-container").css('background-color', '');
                    }
                }).catch(err => {
                    console.log("An error occured: ", err);
                    setBrandColorLoading(false);
                })
            })
        }).catch(err => {
            console.log("An error occured: ", err);
            setBrandColorLoading(false);
        });
    };

    // Renders the details of a SportMatch plan (i.e. what the plan contains, the expiry date, etc.)
    const renderPlanDetails = (plan, type) => {
        if (type == "booster" || type == "main") {
            if (plan.plan && plan.plan.name && plan.plan.number_of_tests) {
                return <SportMatchBasicProgram label={plan.plan.name} items={["Result comparison", `${plan.plan.number_of_tests} tests`]} />
            }
        }

        if (type == "free_token") {
            return <SportMatchBasicProgram label={plan.plan.name} items={[`${plan.free_tests} tests`]} />;
        }

        return null;
    }

    const renderPlans = () => {
        var dom = [];
        var renewalDateForMainPackage;

        if (plans.main_plan) {
            var plan = plans.main_plan;

            dom.push(renderPlanDetails(plan, "main"));

            renewalDateForMainPackage =
                <>
                    <div className="current-plan-renewal-date">
                        <div className="label-text">Renewal date:</div>
                        <div className="label-description">{moment(plan.end_date).format("DD/MM/YYYY")}</div>
                    </div>
                </>;
        }

        if (plans.booster_packs) {
            for (var i = 0; i < plans.booster_packs.length; i++) {
                var plan = plans.booster_packs[i];

                dom.push(renderPlanDetails(plan, "booster"));
            }
        }

        if (plans.free_packs) {
            for (var i = 0; i < plans.free_packs.length; i++) {
                var plan = plans.free_packs[i];

                dom.push(renderPlanDetails(plan, "free_token"));
            }
        }

        dom.push(renewalDateForMainPackage);

        return dom;
    }

    return (
        <React.Fragment>
            <div className="screen-sections account-details">
                <div className="screen-section" style={{ paddingTop: '10px' }}>
                    <div className="my-account-details-header-container">
                        <span>Sport Selector</span>
                    </div>

                    <SportSelectionComponent />
                    {/** Header */}
                    <div className="my-account-details-header-container">
                        <span>My account details</span>
                        &ensp;{accountDetailsLoading ? <CircularProgress disableShrink={true} size={20} /> : null}
                    </div>

                    <div className="my-account-details-body-container">
                        <AccountDetailsTextField onChangeHandler={(value) => setOrganisationName(value)} accountDetailsValue={organisationName} labelText="Organisation name" placeholderText="Organisation name" />
                        <AccountDetailsSelectField onChangeHandler={(value) => setOrganisationType(value)} accountDetailsValue={organisationType} labelText="Organisation type" defaultMenuItem=""
                            menuItems={["Independent school",
                                "Government school",
                                "Tertiary institution",
                                "Sports institute/training centre",
                                "Gym/fitness centre",
                                "Private testing consultancy",
                                "Sports club",
                                "Government department",
                                "Other"
                            ]}
                        />
                        <AccountDetailsTextField onChangeHandler={(value) => setOrganisationAddressLine1(value)} accountDetailsValue={organisationAddressLine1} labelText="Address line 1" placeholderText="1 Lorem Ipsum" />
                        {/* shouldn't there be a onChangeHandler here as well? */}
                        <AccountDetailsTextField onChangeHandler={() => { }} accountDetailsValue="" labelText="Address line 2" placeholderText="Lorem Ipsum" />
                        <AccountDetailsTextField onChangeHandler={(value) => setOrganisationState(value)} accountDetailsValue={organisationState} labelText="State / province" placeholderText="Lorem" />
                        <AccountDetailsTextField onChangeHandler={(value) => setOrganisationPostcode(value)} accountDetailsValue={organisationPostcode} labelText="Postal / zip code" placeholderText="0000" />
                        <AccountDetailsTextField onChangeHandler={(value) => setOrganisationCountry(value)} accountDetailsValue={organisationCountry} labelText="Country" placeholderText="Lorem" />
                        <AccountDetailsSelectField onChangeHandler={(value) => {
                            setUOM(value);
                            dispatch(setUserUOM(value));
                        }} accountDetailsValue={uom} labelText="Unit of mesurement" defaultMenuItem="Metric"
                            menuItems={["Metric",
                                "Imperial"
                            ]}
                        />
                        <AccountDetailsSelectField 
                            onChangeHandler={(value) => {
                                setTokenAllocationTypeLocal(value);
                                dispatch(setTokenAllocationType(value))
                            }} 
                            labelText="Token Allocation Method" 
                            defaultMenuItem="Manual Allocation"
                            accountDetailsValue={tokenAllocationTypeLocal === "" ? "Manual Allocation" : tokenAllocationTypeLocal}
                            menuItems={["Manual Allocation", "Automatic Allocation"]} 
                        />
                    </div>
                </div>

                <div className="screen-section" style={{ display: 'flex', backgroundColor: 'initial', boxShadow: 'none' }}>
                    <div className="account-credentials-screen-container">
                        {/** Header */}
                        <div className="my-account-details-header-container">
                            <span>Account credentials</span>
                            &ensp;{accountCredentialsLoading ? <CircularProgress disableShrink={true} size={20} /> : null}
                        </div>

                        {/** Body */}
                        <div className="my-account-details-body-container">
                            <AccountDetailsTypography type={"organisationLink"} labelText="Your organisation link: " labelDescriptionText={organisationLink} />
                            <AccountDetailsTypography labelText="Your organisation ID: " labelDescriptionText={intialRender ? organisationID : ""} />
                            <AccountDetailsTypography labelText="Organisation admin user ID: " labelDescriptionText={intialRender ? reduxState.user.userId : "Loading plase wait"} />

                            <div className="account-credentials-change-password-container">
                                <Button onClick={() => setOpenChangePasswordDialog(!openChangePasswordDialog)} className="change-password-btn" variant="contained">
                                    Change password
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="default-password-container">
                        <div className="my-account-details-header-container">
                            <span>Default password</span>
                            &ensp;{accountDefaultPasswordLoading ? <CircularProgress disableShrink={true} size={20} /> : null}
                        </div>
                        <div className="my-account-details-body-container">
                            <div class="account-credentials-container">
                                <div class="label-text">Your password:</div>
                                <div class="label-description">{currentDefaultPassword}</div>
                            </div>
                            <div className="account-credentials-change-password-container">
                                <Button onClick={() => setOpenChangeDefaultPasswordDialog(!openChangeDefaultPasswordDialog)} className="change-password-btn" variant="contained">
                                    Change password
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="screen-section teacher-currentplan-section" style={{ display: 'flex' }}>
                    {/* Current plan section */}
                    <div className="current-plan-container">
                        {/** Header */}
                        <div className="my-account-details-header-container">
                            <span>Current plan</span>

                            <form action={`${MARKETING_DOMAIN}`} method="post" encType="multipart/form-data">
                                <Button type="submit" variant="contained">
                                    Change
                                </Button>
                            </form>
                        </div>

                        {/** Body */}
                        <div className="my-account-details-body-container">
                            {loadingPlans ? <CircularProgress disableShrink={true} size={20} /> : null}
                            {renderPlans()}
                        </div>
                    </div>

                    {/* Upload teacher section */}
                    <UploadTeacher />
                </div>

                <div className="screen-section screen-plan-container" style={{ backgroundColor: "transparent" }}>

                    {/** Please upgrade plan div - only show this if they don't have the plan available */}
                    <div className="please-upgrade-container" style={{ display: reduxState.organisationState.plan != "Starter" && reduxState.organisationState.plan != "Professional" ? "none" : null }}>
                        <div className="please-upgrade-message-container">
                            <div>
                                <span>Please upgrade your plan to receive this functionality</span>
                            </div>
                        </div>
                    </div>

                    {/** Brand Color picker  */}
                    <div className="screen-section plan-section">
                        {/** Header */}
                        <div className="my-account-details-header-container plan-heading" style={{ alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>Brand colours</span>
                            {brandColorLoading && <CircularProgress disableShrink size={20} />}
                        </div>

                        {/** Body */}
                        <div className="my-account-details-body-container">
                            <div className="color-row">
                                <div className="color-section">
                                    <div className="color-square">
                                        <InputColor initialValue={primaryColorToUse} onChange={setLocalPrimaryColor} />
                                    </div>
                                    <div className="color-label">
                                        <span>Default</span>
                                    </div>
                                </div>

                                <div className="color-name">
                                    Primary colour
                                </div>
                            </div>

                            <div className="color-row">
                                <div className="color-section">
                                    <div className="color-square">
                                        <InputColor initialValue={secondaryColorToUse} onChange={setLocalSecondaryColor} />
                                    </div>
                                    <div className="color-label">
                                        <span>Default</span>
                                    </div>
                                </div>
                                <div className="color-name">
                                    Secondary colour
                                </div>
                            </div>

                            <div className="color-row">
                                <div className="color-section">
                                    <div className="color-square">
                                        <InputColor initialValue={accentColorToUse} onChange={setLocalAccentColor} />
                                    </div>
                                    <div className="color-label">
                                        <span>Default</span>
                                    </div>
                                </div>
                                <div className="color-name">
                                    Accent colour
                                </div>
                            </div>
                        </div>

                        <div className="brand-button-container">
                            <Button onClick={() => setPreviewBrandColors()} variant="contained">
                                Preview
                            </Button>

                            <Button onClick={() => setBrandColors()} variant="contained">
                                Apply
                            </Button>

                            <Button onClick={() => defaultButtonClickHandler()} variant="contained">
                                Default
                            </Button>
                        </div>

                    </div>

                    {/** Brand Logo picker  */}
                    <BrandLogo />
                </div>

            </div>

            {/* Dialog for default password */}
            <Dialog
                open={openChangeDefaultPasswordDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenChangeDefaultPasswordDialog(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="change-password-dialog"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    Change default password
                    <hr />
                </DialogTitle>
                <DialogContent>
                    <div className="change-password-dialog-container">
                        <span>New default password:</span>
                        <input
                            value={newDefaultPassword}
                            type="text"
                            onChange={(e) => passwordOnChangeHandler({ type: 'org_default_password', value: e.target.value })}
                        />

                        <span>Confirm default password:</span>
                        <input
                            value={confirmNewDefaultPassword}
                            type="text"
                            onChange={(e) => passwordConfirmedOnChangeHandler({ type: "org_default_password", value: e.target.value })}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => passwordDialogCloseBtnHandler()} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={newDefaultPassword == "" || confirmNewDefaultPassword == "" || (newDefaultPassword != confirmNewDefaultPassword)}
                        onClick={() => {
                            setSaveDefaultPasswordLoading(true);
                            modifyOrgDefaultPassword({ method: 'set_password', default_password: newDefaultPassword }).then(() => {
                                passwordDialogCloseBtnHandler();
                                addToast("Default password has been set", {
                                    appearance: 'success',
                                    autoDismiss: true
                                });
                                setCurrentDefaultPassword(newDefaultPassword);
                                setSaveDefaultPasswordLoading(false);
                            });
                        }}
                        color="primary"
                    >
                        Save
                    </Button>
                    {saveDefaultPasswordLoading && <CircularProgress disableShrink size={24} />}
                </DialogActions>
            </Dialog>

            {/* Dialog for password */}
            <Dialog
                open={openChangePasswordDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenChangePasswordDialog(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="change-password-dialog"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    Change password
                    <hr />
                </DialogTitle>
                <DialogContent>
                    <div className="change-password-dialog-container">
                        <span>Current password:</span>
                        <input value={currentPwd} type="password" onChange={(e) => setCurrentPwd(e.target.value)} />

                        <span>New password:</span>
                        <input
                            value={signupPwd}
                            type="password"
                            onChange={(e) => passwordOnChangeHandler({ type: 'org_password', value: e.target.value })}
                        />
                        <span>Confirm password:</span>
                        <input value={signupPwdConfirm} type="password" onChange={(e) => passwordConfirmedOnChangeHandler({ type: "org_password", value: e.target.value })} />

                        <span>Password strength</span>
                        {renderPasswordScore(passwordScore)}

                        <p class="text-danger">Password must contain at least 1 uppercase, 1 lowercase, a number, a special character and minumum 8 characters.</p>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => passwordDialogCloseBtnHandler()} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={currentPwd == "" || signupPwd == "" || signupPwdConfirm == "" || passwordError != "" || signupPwd != signupPwdConfirm} onClick={() => saveNewPasswordBtnHandler()} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default AccountDetailsScreen;
