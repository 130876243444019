import React, { FC } from 'react';

// MATERIAL UI
import { TextField } from '@material-ui/core';

interface OwnProps {
    onChangeHandler: (el: string) => void;
    labelText: string;
    placeholderText: string;
    accountDetailsValue: string;
}

export const AccountDetailsTextField: FC<OwnProps> = ({ labelText, onChangeHandler, placeholderText, accountDetailsValue }) => {
    return (
        <div className="input-container">
            <div className="input-label">{labelText}</div>
            <TextField 
                onChange={(e) => onChangeHandler(e.target.value)} 
                variant="filled" 
                placeholder={placeholderText} 
                value={accountDetailsValue} 
            />
        </div>
    )
}