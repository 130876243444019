import {
    SET_STUDENT_ID,
    SET_MOTHERS_HEIGHT,
    SET_FATHERS_HEIGHT,
    SET_SIT_AND_REACH,
    SET_STANDING_BROAD_JUMP,
    SET_GRIP_STRENGTH,
    SET_LEFT_GRIP_STRENGTH,
    SET_RIGHT_GRIP_STRENGTH,
    SET_VERTICAL_JUMP,
    SET_SIT_UPS,
    SET_INCLINED_PULL_UPS,
    SET_SHUTTLE_RUN,
    SET_SPRINT,
    SET_SPRINT_DISTANCE,
    SET_SPRINT_TIME,
    SET_RUN_WALK_MINUTES,
    SET_RUN_WALK_SECONDS,
    SET_BEEP_TEST,
    SET_YOYO_TEST,
    SET_YOYO_STAGE,
    SET_VO2_MAX,
    SET_YOYO_VO2_MAX,
    SET_HEIGHT,
    SET_WEIGHT,
    SET_ARM_SPAN,
    SET_FOREARM_LENGTH,
    SET_UPPER_ARM_LENGTH,
    SET_SITTING_HEIGHT,
    SET_TEST_DATE,
    SET_INFO_SECTION_EXPANDED,
    SET_FLEXABILITY_SECTION_EXPANDED,
    SET_MUSCULAR_STRENGTH_AND_POWER_EXPANDED,
    SET_MUSCULAR_ENDURANCE_EXPANDED,
    SET_SPEED_AND_AGILITY_EXPANDED,
    SET_CARDIORESPIRATORY_FITNESS_EXPANDED,
    SET_BODY_SIZE_AND_SHAPE_EXPANDED,
    RESET_USER_DATA,
    SET_USER_INPUT_HEIGHT,
    SET_USER_INPUT_WEIGHT,
    SET_USER_INPUT_ARM_SPAN,
    SET_USER_INPUT_SITTING_HEIGHT,
    SET_USER_INPUT_FOREARM_LENGTH,
    SET_USER_INPUT_UPPER_ARM_LENGTH,
    SET_USER_INPUT_SIT_AND_REACH,
    SET_USER_INPUT_STANDING_BROAD_JUMP,
    SET_USER_INPUT_GRIP_STRENGTH_LEFT,
    SET_USER_INPUT_GRIP_STRENGTH_RIGHT,
    SET_USER_INPUT_VERTICAL_JUMP,
    SET_USER_INPUT_BENT_KNEE_SIT_UPS,
    SET_USER_INPUT_INCLINED_PULL_UPS,
    SET_USER_INPUT_SHUTTLE_RUN,
    SET_USER_INPUT_SPRINT_DISTANCE,
    SET_USER_INPUT_SPRINT_TIME,
    SET_USER_INPUT_WALK_RUN_MINUTES,
    SET_USER_INPUT_WALK_RUN_SECONDS,
    SET_USER_INPUT_SHUTTLE_BEEP_TEST,
    SET_USER_INPUT_VO2_MAX,
    SET_ALL_FIELDS_PROVIDED,
    SET_USER_INPUT_YOYO_TEST,
    SET_USER_INPUT_YOYO_STAGE,
    SET_USER_INPUT_YOYO_VO2_MAX,
    SET_USER_INPUT_BEEP_TEST_VO2_MAX
} from '../constants';

//My Info
export const setStudentId = (input) => {
    return {
        type: SET_STUDENT_ID,
        payload: input
    }
}
export const setMothersHeight = (input) => {
    return {
        type: SET_MOTHERS_HEIGHT,
        payload: input
    }
}
export const setFathersHeight = (input) => {
    return {
        type: SET_FATHERS_HEIGHT,
        payload: input
    }
}

// Section collapsing/expanding
export const setExpandedMyInfo = (input) => {
    return {
        type: SET_INFO_SECTION_EXPANDED,
        payload: input
    }
}
export const setExpandedFlexibility = (input) => {
    return {
        type: SET_FLEXABILITY_SECTION_EXPANDED,
        payload: input
    }
}
export const setExpandedMuscularStrengthAndPower = (input) => {
    return {
        type: SET_MUSCULAR_STRENGTH_AND_POWER_EXPANDED,
        payload: input
    }
}
export const setExpandedMuscularEndurance = (input) => {
    return {
        type: SET_MUSCULAR_ENDURANCE_EXPANDED,
        payload: input
    }
}
export const setExpandedSpeedAndAgility = (input) => {
    return {
        type: SET_SPEED_AND_AGILITY_EXPANDED,
        payload: input
    }
}
export const setExpandedCardiorespiratoryFitness = (input) => {
    return {
        type: SET_CARDIORESPIRATORY_FITNESS_EXPANDED,
        payload: input
    }
}
export const setExpandedBodySizeAndShape = (input) => {
    return {
        type: SET_BODY_SIZE_AND_SHAPE_EXPANDED,
        payload: input
    }
}

// Flexibility
export const setSitAndReach = (input) => {
    return {
        type: SET_SIT_AND_REACH,
        payload: input
    }
}

// Muscular Strength and Power
export const setStandingBroadJump = (input) => {
    return {
        type: SET_STANDING_BROAD_JUMP,
        payload: input
    }
}
export const setGripStrength = (input) => {
    return {
        type: SET_GRIP_STRENGTH,
        payload: input
    }
}
export const setLeftGripStrength = (input) => {
    return {
        type: SET_LEFT_GRIP_STRENGTH,
        payload: input
    }
}
export const setRightGripStrength = (input) => {
    return {
        type: SET_RIGHT_GRIP_STRENGTH,
        payload: input
    }
}
export const setVerticalJump = (input) => {
    return {
        type: SET_VERTICAL_JUMP,
        payload: input
    }
}

// Muscular endurance
export const setSitUps = (input) => {
    return {
        type: SET_SIT_UPS,
        payload: input
    }
}
export const setInclinedPullUps = (input) => {
    return {
        type: SET_INCLINED_PULL_UPS,
        payload: input
    }
}

// Speed and Agility
export const setShuttleRun = (input) => {
    return {
        type: SET_SHUTTLE_RUN,
        payload: input
    }
}
export const setSprint = (input) => {
    return {
        type: SET_SPRINT,
        payload: input
    }
}

export const setSprintDistance = (input) => {
    return {
        type: SET_SPRINT_DISTANCE,
        payload: input
    }
}

export const setSprintTime = (input) => {
    return {
        type: SET_SPRINT_TIME,
        payload: input
    }
}

// Cardiorespiratory Fitness
export const setRunWalkMinutes = (input) => {
    return {
        type: SET_RUN_WALK_MINUTES,
        payload: input
    }
}

export const setRunWalkSeconds = (input) => {
    return {
        type: SET_RUN_WALK_SECONDS,
        payload: input
    }
}

export const setVo2Max = (input) => {
    return {
        type: SET_VO2_MAX,
        payload: input
    }
}

export const setYoyoVo2Max = (input) => {
    return {
        type: SET_YOYO_VO2_MAX,
        payload: input
    }
}

export const setBeepTest = (input) => {
    return {
        type: SET_BEEP_TEST,
        payload: input
    }
}

export const setYoyoTest = (input) => {
    return {
        type: SET_YOYO_TEST,
        payload: input
    }
}

export const setYoyoStage = (input) => {
    return {
        type: SET_YOYO_STAGE,
        payload: input
    }
}

// Body Shape and Size
export const setHeight = (input) => {
    return {
        type: SET_HEIGHT,
        payload: input
    }
}
export const setWeight = (input) => {
    return {
        type: SET_WEIGHT,
        payload: input
    }
}
export const setArmSpan = (input) => {
    return {
        type: SET_ARM_SPAN,
        payload: input
    }
}
export const setForearmLength = (input) => {
    return {
        type: SET_FOREARM_LENGTH,
        payload: input
    }
}
export const setUpperArmLength = (input) => {
    return {
        type: SET_UPPER_ARM_LENGTH,
        payload: input
    }
}
export const setSittingHeight = (input) => {
    return {
        type: SET_SITTING_HEIGHT,
        payload: input
    }
}
export const setTestDate = (input) => {
    return {
        type: SET_TEST_DATE,
        payload: input
    }
}

export const resetUserTestData = (input) => {
    return {
        type: "RESET_DATA",
        payload: input
    }
}

/** Actions for setting user test inputs (different from what are shown on "Find my sport", "Compare my results", etc.) */
export const setUserInputHeight = input => {
    return {
        type: SET_USER_INPUT_HEIGHT,
        payload: input
    }
}

export const setUserInputWeight = input => {
    return {
        type: SET_USER_INPUT_WEIGHT,
        payload: input
    }
}

export const setUserInputArmSpan = input => {
    return {
        type: SET_USER_INPUT_ARM_SPAN,
        payload: input
    }
}

export const setUserInputSittingHeight = input => {
    return {
        type: SET_USER_INPUT_SITTING_HEIGHT,
        payload: input
    }
}

export const setUserInputForearmLength = input => {
    return {
        type: SET_USER_INPUT_FOREARM_LENGTH,
        payload: input
    }
}

export const setUserInputUpperArmLength = input => {
    return {
        type: SET_USER_INPUT_UPPER_ARM_LENGTH,
        payload: input
    }
}

export const setUserInputSitAndReach = input => {
    return {
        type: SET_USER_INPUT_SIT_AND_REACH,
        payload: input
    }
}

export const setUserInputStandingBroadJump = input => {
    return {
        type: SET_USER_INPUT_STANDING_BROAD_JUMP,
        payload: input
    }
}

export const setUserInputGripStrengthLeft = input => {
    return {
        type: SET_USER_INPUT_GRIP_STRENGTH_LEFT,
        payload: input
    }
}

export const setUserInputGripStrengthRight = input => {
    return {
        type: SET_USER_INPUT_GRIP_STRENGTH_RIGHT,
        payload: input
    }
}

export const setUserInputVerticalJump = input => {
    return {
        type: SET_USER_INPUT_VERTICAL_JUMP,
        payload: input
    }
}

export const setUserInputBentKneeSitUps = input => {
    return {
        type: SET_USER_INPUT_BENT_KNEE_SIT_UPS,
        payload: input
    }
}

export const setUserInputInclinedPullUps = input => {
    return {
        type: SET_USER_INPUT_INCLINED_PULL_UPS,
        payload: input
    }
}

export const setUserInputShuttleRun = input => {
    return {
        type: SET_USER_INPUT_SHUTTLE_RUN,
        payload: input
    }
}

export const setUserInputSprintDistance = input => {
    return {
        type: SET_USER_INPUT_SPRINT_DISTANCE,
        payload: input
    }
}

export const setUserInputSprintTime = input => {
    return {
        type: SET_USER_INPUT_SPRINT_TIME,
        payload: input
    }
}

export const setUserInputWalkRunMinutes = input => {
    return {
        type: SET_USER_INPUT_WALK_RUN_MINUTES,
        payload: input
    }
}

export const setUserInputWalkRunSeconds = input => {
    return {
        type: SET_USER_INPUT_WALK_RUN_SECONDS,
        payload: input
    }
}

export const setUserInputShuttleBeepTest = input => {
    return {
        type: SET_USER_INPUT_SHUTTLE_BEEP_TEST,
        payload: input
    }
}

export const setUserInputYoyoTest = input => {
    return {
        type: SET_USER_INPUT_YOYO_TEST,
        payload: input
    }
}

export const setUserInputYoyoStage = input => {
    return {
        type: SET_USER_INPUT_YOYO_STAGE,
        payload: input
    }
}

export const setUserInputVO2MAX = input => {
    return {
        type: SET_USER_INPUT_VO2_MAX,
        payload: input
    }
}

export const setUserInputBeepTestVO2MAX = input => {
    return {
        type: SET_USER_INPUT_BEEP_TEST_VO2_MAX,
        payload: input
    }
}

export const setUserInputYoyoVO2MAX = input => {
    return {
        type: SET_USER_INPUT_YOYO_VO2_MAX,
        payload: input
    }
}

export const setAllFieldsProvided = input => {
    return {
        type: SET_ALL_FIELDS_PROVIDED,
        payload: input
    }
}