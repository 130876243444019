import React from "react";

const SpeedAgilitySvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Speed + Agility</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="speed_and_agility" data-name="speed and agility">
            <g id="runer-silhouette-running-fast">
                <g id="_36" data-name="36">
                    <g id="Group_181" data-name="Group 181">
                        <path id="Path_16" data-name="Path 16" className="cls-2" style={{fill: color}}
                              d="M352.83,166.76a48.45,48.45,0,1,0-48.45-48.44,48.43,48.43,0,0,0,48.45,48.44Z"
                              transform="translate(-2.83 -7.46)"/>
                        <path id="Path_17" data-name="Path 17" className="cls-2" style={{fill: color}}
                              d="M432.52,152.81c-27.82,39-65.07,43.48-105.15,19.68-2.73-1.61-14.84-8.28-17.52-9.88-64.49-38.28-128.9-23.26-172.09,37.23C119.4,225.56,161.86,250,180,224.52c22.32-31.25,50.69-40.29,81.73-30.39-15.89,27.58-29.78,55-49.39,93.95s-62.91,70.06-104.51,45.73c-30-17.54-57.09,25.58-27.16,43.07,56.79,33.2,123.39,12.82,157.39-30.33a27.78,27.78,0,0,0,3.79,1.67c27.77,9.68,64.18,35.44,75.28,44.57s30.14,55.64,41.45,79c13.73,28.36,58.06,7.71,44.28-20.77-12.82-26.52-34.35-79.44-51.15-92.82-13.47-10.74-39.62-30.73-61.8-42.07q22.51-43.59,47.08-86.07c52.43,16.06,102.27-2.86,137.76-52.58C493.13,151.77,450.69,127.38,432.52,152.81Z"
                              transform="translate(-2.83 -7.46)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SpeedAgilitySvg;
