export const SET_SELECTED_USER_IDS = "SET_SELECTED_USER_IDS";
export const SET_ORGANISATION_NAME = "SET_ORGANISATION_NAME";
export const SET_ORGANISATION_TYPE = "SET_ORGANISATION_TYPE";
export const SET_ORGANISATION_ADDRESS_LINE_1 = "SET_ORGANISATION_ADDRESS_LINE_1";
export const SET_ORGANISATION_STATE = "SET_ORGANISATION_STATE";
export const SET_ORGANISATION_POSTCODE = "SET_ORGANISATION_POSTCODE";
export const SET_ORGANISATION_COUNTRY = "SET_ORGANISATION_COUNTRY";

// BRAND RELATED FIELDS
export const SET_PRIMARY_COLOR = "SET_PRIMARY_COLOR";
export const SET_SECONDARY_COLOR = "SET_SECONDARY_COLOR";
export const SET_ACCENT_COLOR = "SET_ACCENT_COLOR";
export const SET_ORG_LOGO = "SET_ORG_LOGO";

// Filter actions
export const SET_FILTER_GENDER = "SET_FILTER_GENDER";
export const SET_FILTER_AGES = "SET_FILTER_AGES";
export const SET_FILTER_GROUP_NAMES = "SET_FILTER_GROUP_NAMES";
export const SET_DATE_RANGE_FROM_FILTER = "SET_DATE_RANGE_FROM_FILTER";
export const SET_DATE_RANGE_TO_FILTER = "SET_DATE_RANGE_TO_FILTER";

// Access user action
export const SET_ACCESS_USER = "SET_ACCESS_USER";

// Plan Action
export const SET_PLAN = "SET_PLAN";

// Refresh data constant
export const SET_PENDING_REFRESH_COMPLETION = "SET_PENDING_REFRESH_COMPLETION";