import React from "react";

const SitReachSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 552">
        <defs>
        </defs>
        <title>Sit and Reach</title>
        <g id="Layer_32" data-name="Layer 32">
            <ellipse className="cls-1" cx="281" cy="276" rx="281" ry="276" style={{fill: '#fff'}}/>
        </g>
        <g id="sit_and_reach_flexibility" data-name="sit and reach / flexibility">
            <circle className="cls-2" cx="270.93" cy="134.64" r="50.53" style={{fill: color}}/>
            <path className="cls-2" style={{fill: color}}
                  d="M441.63,365.36h-255c-2.37-55.74,27.42-103,40.58-121l149,104a25.93,25.93,0,0,0,29.68-42.52L237.15,188.1a25.92,25.92,0,0,0-17.77-4.5c-43.76,5-77.63,29.29-97.94,70.27-16.39,33.07-21,71.41-22,97.75a63.23,63.23,0,0,0,63.22,65.58h279a25.92,25.92,0,1,0,0-51.84Z"
                  transform="translate(-2.83 -7.46)"/>
        </g>
    </svg>
)

export default SitReachSvg;
