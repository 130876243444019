import { useEffect } from "react";
import { ConvertToFeetAndInches } from "../../../../helper/helper";

const RenderStudentResults = ({
    doc,
    // Sit and Reach
    sitAndReachStudentScore,
    sitAndReachPercentage,
    sitAndReachCount,
    // Standing broad jump
    standingBroadJumpStudentScore,
    standingBroadJumpPercentage,
    standingBroadJumpCount,
    // Sit ups
    sitUpsStudentScore,
    sitUpsPercentage,
    sitUpsCount,
    // Grip strength
    gripStrengthStudentScore,
    gripStrengthPercentage,
    gripStrengthCount,
    // Vertical jump
    verticalJumpStudentScore,
    verticalJumpPercentage,
    verticalJumpCount,
    // Inclined pull ups
    inclinedPullUpsStudentScore,
    inclinedPullUpsPercentage,
    inclinedPullUpsCount,
    // Shuttle run
    shuttleRunStudentScore,
    shuttleRunPercentage,
    shuttleRunCount,
    // Sprint speed
    sprintSpeedStudentScore,
    sprintPercentage,
    sprintCount,
    // Walk run
    walkRunStudentScore,
    walkRunPercentage,
    walkRunCount,
    // Shuttle beep test
    aerobicFitnessStudentScore,
    aerobicFitnessPercentage,
    aerobicFitnessCount,
    // Colors
    primaryColor,
    secondaryColor,
    accentColor,
    // Scale
    SCALE_FOR_PERCENTGES,
    usingImperial = false
}) => {
    var arrayOfPercentilesAndPercentages = [
        {
            name: "Sit and reach",
            percentage: sitAndReachPercentage,
            studentValue: sitAndReachStudentScore,
            count: sitAndReachCount,
            isTimed: false
        },
        {
            name: "Standing broad jump",
            percentage: standingBroadJumpPercentage,
            studentValue: standingBroadJumpStudentScore,
            count: standingBroadJumpCount,
            isTimed: false
        },
        {
            name: "Sit ups",
            percentage: sitUpsPercentage,
            studentValue: sitUpsStudentScore,
            count: sitUpsCount,
            isTimed: false
        },
        {
            name: "Grip strength",
            percentage: gripStrengthPercentage,
            studentValue: gripStrengthStudentScore,
            count: gripStrengthCount,
            isTimed: false
        },
        {
            name: "Vertical jump",
            percentage: verticalJumpPercentage,
            studentValue: verticalJumpStudentScore,
            count: verticalJumpCount,
            isTimed: false
        },
        {
            name: "Inclined pull ups",
            percentage: inclinedPullUpsPercentage,
            studentValue: inclinedPullUpsStudentScore,
            count: inclinedPullUpsCount,
            isTimed: false
        },
        {
            name: "Shuttle run",
            percentage: shuttleRunPercentage,
            studentValue: shuttleRunStudentScore,
            count: shuttleRunCount,
            isTimed: false
        },
        {
            name: "Sprint",
            percentage: sprintPercentage,
            studentValue: sprintSpeedStudentScore,
            count: sprintCount,
            isTimed: false
        },
        {
            name: "Walk run",
            percentage: walkRunPercentage,
            studentValue: walkRunStudentScore,
            count: walkRunCount,
            isTimed: true
        },
        {
            name: "Beep test",
            percentage: aerobicFitnessPercentage,
            studentValue: aerobicFitnessStudentScore,
            count: aerobicFitnessCount,
            isTimed: false
        }
    ];

    var xPos = 19.25;

    const formatToMinutesSeconds = (value) => {
        var secondsFloat = parseFloat(value);

        var minutes = "";
        var seconds = "";

        minutes = parseFloat(secondsFloat / 60) + "";
        seconds = parseFloat(secondsFloat % 60).toFixed(0);

        if (minutes.indexOf(".") !== -1) {
            minutes = parseFloat(minutes.substring(0, minutes.indexOf(".")));
        }

        minutes += "";

        if (seconds < 10) {
            seconds = "0" + seconds;
        } else {
            seconds += "";
        }

        return minutes + ":" + seconds;
    };

    const formatValue = (obj, type) => {
        if (obj.name == "Shuttle run" || obj.name == "Sprint") {
            if (type == "student") {
                return parseFloat(obj.studentValue).toFixed(1);
            } else {
                return parseFloat(obj.adultValue).toFixed(1);
            }
        } else {
            if (type == "student") {
                return parseFloat(obj.studentValue).toFixed(0);
            } else {
                return parseFloat(obj.adultValue).toFixed(0);
            }
        }
    }

    // Renders the box which appears above the bar
    const renderBox = (obj, xPos) => {
        if (obj) {
            // Position for the student box
            var studentScore = obj.studentValue;
            var studentPercentage = obj.percentage;

            // Determined position
            var xPositionForBox = xPos;
            var xPositionForTest = xPositionForBox;

            if (obj.isTimed) {
                if (studentScore != -1) {
                    studentScore = formatToMinutesSeconds(studentScore);
                }
            }


            if(usingImperial && obj.name == "Standing broad jump") {
                if(studentScore != -1 && studentScore != '') studentScore = ConvertToFeetAndInches(studentScore);
            }

            // Placing the student box
            if (studentScore && studentScore !== 0) {
                if (studentScore !== '' && studentScore !== "-1" && studentScore !== "-1.0" && studentScore !== "0:00") {
                    if(!obj.isTimed) {
                        if(usingImperial) {
                            if(obj.name !== "Standing broad jump") {
                                studentScore = formatValue(obj, 'student');
                            }
                        } else {
                            studentScore = formatValue(obj, 'student');
                        }
                    }

                    if (!obj.isTimed) {
                        if (studentScore.length == 3) {
                            xPositionForTest += .8;
                        }
                        if (studentScore.length == 2) {
                            xPositionForTest += 1;
                        }
                        if (studentScore.length == 1) {
                            xPositionForTest += 2;
                        }
                    } else {
                        if (studentScore.length == 4) {
                            xPositionForTest = xPositionForTest + .3;
                        }
                        if (studentScore.length == 5) {
                            xPositionForTest = xPositionForTest;
                        }
                    }

                    var x1PointForTriangle = xPositionForBox - 1.2;
                    var y1PointForTriangle = 120 - (parseFloat(studentPercentage) / SCALE_FOR_PERCENTGES) - 2.1;
                    var x2PointForTriangle = xPositionForBox + 5.9;
                    var y2PointForTriangle = 120 - (parseFloat(studentPercentage) / SCALE_FOR_PERCENTGES) - 2.1;
                    var x3PointForTriangle = ((x1PointForTriangle + x2PointForTriangle) / 2);
                    var y3PointForTriangle = 120 - (parseFloat(studentPercentage) / SCALE_FOR_PERCENTGES);

                    doc.rect(xPositionForBox + 1.25, 120 - (parseFloat(studentPercentage) / SCALE_FOR_PERCENTGES) - 5.5, 7, 3.5, 'F');
                    doc.triangle(x1PointForTriangle + 2.40, y1PointForTriangle, x2PointForTriangle + 2.40, y2PointForTriangle, x3PointForTriangle + 2.40, y3PointForTriangle, 'F');

                    doc.text(studentScore !== '' && studentScore !== -1 ? studentScore : '0', xPositionForTest + 2.25, 120 - (parseFloat(studentPercentage) / SCALE_FOR_PERCENTGES) - 5 + 2.75);

                    doc.setFillColor(255, 255, 255);
                }
            }
        }
    };

    // Renders the percentage box at the bottom of the bars
    const renderCountBoxes = (obj, xPos) => {
        var xPosForText = xPos + 1.2;

        var content = "";

        if (obj.count !== "") {
            content = parseFloat(obj.count).toFixed(0);
        } else {
            content = "-";
        }

        if (content.length == 1) {
            xPosForText += 2.5;
        }

        if (content.length == 2) {
            xPosForText += 1.4;
        }

        if (content.length == 3) {
            xPosForText += .8;
        }

        doc.setDrawColor(accentColor != '' ? accentColor : '84, 179, 132');

        doc.setLineWidth(0.5);

        doc.rect(xPos + 0.5, 120, 8.5, 4.5, 'FD');
        doc.setFontSize(8);
        doc.setFont("Dubai-Regular", "Bold");
        doc.text(content, xPosForText, 123.5);
        doc.setFontStyle("normal");
        doc.setFillColor(255, 255, 255);
    }

    for (var i = 0; i < 12; i++) {
        renderBox(arrayOfPercentilesAndPercentages[i], xPos);

        xPos += 16.5;
    }

    xPos = 19.25;

    for (var i = 0; i < 10; i++) {
        renderCountBoxes(arrayOfPercentilesAndPercentages[i], xPos);

        xPos += 16.5;
    }
}

export default RenderStudentResults;
