// CHARTJS
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import { generateGroupReportPDF } from '../classInfo/GroupReportPDF';

import moment from 'moment';
import { ENVIRONMENT_PATH, BACKEND_URL } from '../../../../store/constants';
import { useSelector } from 'react-redux';
import { hexToRgbA } from '../../../../helper/hexToRgbA';

const GroupReport = (organisationLocalState, data, headerIcons, anthropometryIcons, radarRef, customCallback, usingImperial) => {
  
    var sportTypePercentages = new Map();
    const primaryColor =
    organisationLocalState.organisationPrimaryColor != ""
      ? organisationLocalState.organisationPrimaryColor
      : "rgb(90, 83, 128)";
  const secondaryColor =
    organisationLocalState.organisationSecondaryColor != ""
      ? organisationLocalState.organisationSecondaryColor
      : "rgb(119, 133, 153)";
  const accentColor =
    organisationLocalState.organisationAccentColor != ""
      ? organisationLocalState.organisationAccentColor
      : "rgb(90, 83, 128)";

    const initializeRadarChart = (data) => {
        
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                var chartCtx = document.createElement("canvas");
                chartCtx.setAttribute("height", "300px");

                var chartData = {
                    labels: ['', '', '', '', ''],
                    datasets: [
                        {
                            label: 'My Second dataset',
                            backgroundColor: organisationLocalState.organisationPrimaryColor != '' ? hexToRgbA(organisationLocalState.organisationPrimaryColor, 0.3) : 'rgba(154, 98, 179, .3)',
                            borderColor: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : 'rgb(154, 98, 179)',
                            borderWidth: 1,
                            pointBackgroundColor: 'rgba(255,99,132,1)',
                            pointBorderColor: '#fff',
                            pointRadius: 0,
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(255,99,132,1)',
                            data: [
                                data.averaged_norms.height,
                                data.averaged_norms.weight,
                                data.averaged_norms.arm_span,
                                data.averaged_norms.sitting_height,
                                data.averaged_norms.forearm_length
                            ]
                        }
                    ]
                };

                var chartOptions = {
                    legend: {
                        display: false
                    },
                    animation: {
                        onComplete: function () {
                            resolve(ChartJSDOM);
                        }
                    },
                    responsive: false,
                    maintainAspectRatio: false,
                    scale: {
                        angleLines: {
                            display: true,
                        },
                        pointLabels: {
                            display: false,
                            fontFamily: 'Dubai Regular',
                            fontSize: 14
                        },
                        ticks: {
                            display: false,
                            suggestedMin: 0,
                            suggestedMax: 100,
                            maxTicksLimit: 6,
                            backdropColor: 'rgb(50, 168, 82)'
                        }
                    }
                };

                const ChartJSDOM = new Chart(chartCtx, {
                    type: "radar",
                    data: chartData,
                    options: chartOptions,
                    width: 800,
                    height: 800
                });

                document.querySelector("body").appendChild(ChartJSDOM.canvas);
            }, 2000)
        })
    };

    const initializePieChart = (rankings) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                var pieChartCtx = document.createElement("canvas");
                pieChartCtx.setAttribute("height", "550px");
                pieChartCtx.setAttribute("width", "760px");

                let data = {
                    datasets: [
                        {
                            data: [
                                parseFloat(rankings[0].value).toFixed(1),
                                parseFloat(rankings[1].value).toFixed(1),
                                parseFloat(rankings[2].value).toFixed(1),
                                parseFloat(rankings[3].value).toFixed(1),
                                parseFloat(rankings[4].value).toFixed(1),
                                parseFloat(rankings[5].value).toFixed(1)
                            ],
                            backgroundColor: [
                                'rgb(28, 185, 170)',
                                'rgb(55, 70, 73)',
                                'rgb(241, 199, 48)',
                                'rgb(251, 96, 97)',
                                'rgb(95, 107, 109)',
                                'rgb(141, 213, 234)'
                            ],
                            labels: ['Skill', 'Power', 'Strength', 'Speed', 'Aerobic fitness', 'Game fitness'],
                            borderWidth: 0
                        },
                    ],
                }

                let options = {
                    plugins: {
                        labels: {
                            fontColor: 'black',
                            position: 'outside',
                            render: (args) => {
                                sportTypePercentages.set(args.dataset.labels[args.index], args.percentage);
                                return `${args.dataset.labels[args.index]}\n${args.percentage}%`;
                            }
                        }
                    },
                    animation: {
                        onComplete: function () {
                            resolve(PieChartJSDOM);
                        }
                    },
                    responsive: false,
                    maintainAspectRatio: false
                }

                const PieChartJSDOM = new Chart(pieChartCtx, {
                    type: "pie",
                    data: data,
                    options: options
                });

                document.querySelector("body").appendChild(PieChartJSDOM.canvas);

            }, 2000);
        })
    }

    const getPercentageOfSportsOver60 = sports => {
        var numberOfSportsFound = 0;
        var count = 0;

        var finalPercentage = 0;

        for (var i = 0; i < sports.length; i++) {
            if (sports[i].final_distance > 60) {
                numberOfSportsFound++;
            }
            count++;
        }

        finalPercentage = (numberOfSportsFound / count) * 100;

        return finalPercentage;
    }

    const getAverageSportRanking = sports => {
        var averageRanking = 0;
        var count = 0;
        for (var i = 0; i < sports.length; i++) {
            averageRanking += sports[i].final_distance;
            count++;
        }

        return averageRanking / count;
    }

    const getScoreRankingForSportType = (name, sports) => {
        var sportTypeName = "";

        if (name === "powerScore") {
            sportTypeName = "power";
        }

        if (name === "skillScore") {
            sportTypeName = "skill";
        }

        if (name === "strengthScore") {
            sportTypeName = "strength";
        }

        if (name === "gameFitnessScore") {
            sportTypeName = "game - fitness";
        }

        if (name === "speedScore") {
            sportTypeName = "speed";
        }

        if (name === "aerobicFitnessScore") {
            sportTypeName = "aerobic fitness";
        }

        var scoreCount1 = 0;
        var scoreRanking1 = 0;
        var scoreAverage1 = 0;

        var scoreCount2 = 0;
        var scoreRanking2 = 0;
        var scoreAverage2 = 0;

        for (var y = 0; y < 20; y++) {
            var sportObject = sports[y].sport;

            if(sportObject) {
                if (sportObject.sport_type1 === sportTypeName) {
                    scoreCount1 += 1;
                    scoreRanking1 += ((y + 1) * 1);
                }
                if (sportObject.sport_type2 === sportTypeName) {
                    scoreCount2 += 1;
                    scoreRanking2 += ((y + 1) * 1.2);
                }
            }
        }

        scoreAverage1 = (scoreRanking1 / scoreCount1);
        scoreAverage2 = (scoreRanking2 / scoreCount2);

        var returnedValue = (scoreAverage1 + scoreAverage2);

        return returnedValue;
    }

    const generateFilterString = (isNew) => {
        // Checking if filters have been applied
        var filters = "";
        if (organisationLocalState.genderFilters.length != 0) {
          if (isNew) {
            filters += "?gender=" + organisationLocalState.genderFilters;
          } else {
            filters += "&gender=" + organisationLocalState.genderFilters;
          }
        }
        if (organisationLocalState.groupNameFilters.length != 0) {
          if (isNew) {
            if (filters != "") {
              filters += "&groups=" + btoa("[" + organisationLocalState.groupNameFilters.map(e => e) + "]");
            } else {
              filters += "?groups=" + btoa("[" + organisationLocalState.groupNameFilters.map(e => e) + "]");
            }
          } else {
            filters += "&groups=" + btoa("[" + organisationLocalState.groupNameFilters.map(e => e) + "]");
          }
        }
        if (organisationLocalState.ageFilters.length != 0) {
          if (isNew) {
            if (filters != "") {
              filters += "&ages=" + btoa("[" + organisationLocalState.ageFilters.map(e => "\"" + e + "\"") + "]");
            } else {
              filters += "?ages=" + btoa("[" + organisationLocalState.ageFilters.map(e => "\"" + e + "\"") + "]");
            }
          } else {
            filters += "&ages=" + btoa("[" + organisationLocalState.ageFilters.map(e => "\"" + e + "\"") + "]");
          }
        }
        if (organisationLocalState.dateRangeFromFilter.length != 0) {
          if (isNew) {
            if (filters != "") {
              filters += "&startDate=" + moment(organisationLocalState.dateRangeFromFilter).format('DD/MM/YYYY');
            } else {
              filters += "?startDate=" + moment(organisationLocalState.dateRangeFromFilter).format('DD/MM/YYYY');
            }
          } else {
            filters += "&startDate=" + moment(organisationLocalState.dateRangeFromFilter).format('DD/MM/YYYY');
          }
        }
        if (organisationLocalState.dateRangeToFilter.length != 0) {
          if (isNew) {
            if (filters != "") {
              filters += "&endDate=" + moment(organisationLocalState.dateRangeToFilter).format('DD/MM/YYYY');
            } else {
              filters += "?endDate=" + moment(organisationLocalState.dateRangeToFilter).format('DD/MM/YYYY');
            }
          } else {
            filters += "&endDate=" + moment(organisationLocalState.dateRangeToFilter).format('DD/MM/YYYY');
          }
        }
    
        return filters;
      }

    // Gets the sport rankings
    const getSportRankings = () => {
        return new Promise((resolve, reject) => {
            fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/orgtopsports?all_sports=true&groups=${btoa("[" + data.group_name + "]")}`, {
              headers: {
                'Accept': 'application/json'
              },
              credentials: "include"
            }).then(res => {
              res.json().then(result => {
                resolve(result.top_sport_types);
              });
            })
          })
    }

    getSportRankings().then(rankings => {
        var averageSportRanking = getAverageSportRanking(data.top_sports);

        var percentageOfSportsOver60 = getPercentageOfSportsOver60(data.top_sports);
    
        initializeRadarChart(data).then((radarChartResult) => {
            initializePieChart(rankings).then((pieChartResult) => {
                generateGroupReportPDF(
                    data,
                    headerIcons,
                    anthropometryIcons,
                    radarChartResult,
                    null,
                    averageSportRanking,
                    percentageOfSportsOver60,
                    rankings,
                    primaryColor,
                    secondaryColor,
                    accentColor,
                    customCallback,
                    usingImperial,
                    organisationLocalState.organisationLogo
                );
            });
        });
    })
}

export default GroupReport
