import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { SwitchTransition, CSSTransition } from "react-transition-group";

// STYLES
import './style.css';
import 'rc-slider/assets/index.css';

// USER DEFINED COMPONENTS
import MyResultBox from '../../../components/findMySportComponents/myResultsBox.js';
import SportTypes from './SportTypes.js';

// MATERIAL UI COMPONENTS
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

// REACT TOAST NOTIFICATIONS
import { useToasts } from 'react-toast-notifications'

import {
    BACKEND_URL,
    ENVIRONMENT_PATH
} from '../../../store/constants';

// USER DEFINED COMPONENTS
import BodySizeAndShapeComponent from './BodySizeAndShapeSection.js';
import FindMySportBars from './FindMySportBars.js';
import SportSelectionComponent from '../organizationAdminScreens/accountDetails/SportSelectionComponent/sportSelectionComponent';

// Convert Units
import IM from 'convert-units';

const FindMySport = () => {
    const reduxStateGlobal = useSelector(state => state);
    const reduxState = useSelector(state => state.adultValues);
    const organisationLocalState = useSelector(state => state.organisationState);

    const [barsUpdated, setBarsUpdates] = useState(false);
    const [showBodySizeAndShapeSection, setShowBodySizeAndShapeSection] = useState(false);

    const [powerSportTypeRank, setPowerSportTypeRank] = useState(1);
    const [speedSportTypeRank, setSpeedSportTypeRank] = useState(1);
    const [aerobicSportTypeRank, setAerobicSportTypeRank] = useState(1);
    const [gameFitnessSportTypeRank, setGameFitnessSportTypeRank] = useState(1);
    const [strengthSportTypeRank, setStrengthSportTypeRank] = useState(1);
    const [skillSportTypeRank, setSkillSportTypeRank] = useState(1);

    const [customSelectedSports, setCustomSelectedSports] = useState([]);
    const [customUnselectedSports, setCustomUnselectedSports] = useState([]);

    const [showSportSelectionPopup, setShowSportSelectionPopup] = useState(false);

    const [whatIfButtonLoading, setWhatIfButtonLoading] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);

    const usingImperial = reduxStateGlobal.user.userUOM == "Imperial";

    const [trans, setTrans] = React.useState(true);

    // React Toast Notifications Object
    const { addToast } = useToasts();

    var userRunWalkSpeedScore = "";

    if (reduxStateGlobal.myDetails.runWalkMinutes !== "") {
        userRunWalkSpeedScore += (parseFloat(reduxStateGlobal.myDetails.runWalkMinutes) * 60);
    }

    if (reduxStateGlobal.myDetails.runWalkSeconds !== "") {
        userRunWalkSpeedScore = parseFloat(userRunWalkSpeedScore) + parseFloat(reduxStateGlobal.myDetails.runWalkSeconds);
    }

    const bodySizeAndShapeArrowClickHandler = () => {
        setShowBodySizeAndShapeSection(true);
        setTrans(trans => !trans);
    }

    const backArrowClickHandler = () => {
        setShowBodySizeAndShapeSection(false);
        setTrans(trans => !trans);
    }

    const getRanking = (i) => {
        if (i === 0) {
            return 5;
        }
        if (i === 1) {
            return 4;
        }
        if (i === 2) {
            return 3;
        }
        if (i === 3) {
            return 2;
        }
        if (i === 4) {
            return 1;
        }
    }

    const getScoreRankingForSportType = (name) => {
        var sportTypeName = "";

        if (name === "powerScore") {
            sportTypeName = "power";
        }

        if (name === "skillScore") {
            sportTypeName = "skill";
        }

        if (name === "strengthScore") {
            sportTypeName = "strength";
        }

        if (name === "gameFitnessScore") {
            sportTypeName = "game - fitness";
        }

        if (name === "speedScore") {
            sportTypeName = "speed";
        }

        if (name === "aerobicFitnessScore") {
            sportTypeName = "aerobic fitness";
        }

        var scoreCount1 = 0;
        var scoreRanking1 = 0;
        var scoreAverage1 = 0;
        var scoreFinal1 = 0;

        var scoreCount2 = 0;
        var scoreRanking2 = 0;
        var scoreAverage2 = 0;
        var scoreFinal2 = 0;

        var totalSportCount = 0;
        var totalScore = 0;
        var rankingForSportType = 0;

        for (var x = 0; x < topFiveSports.length; x++) {
            var sportObject = topFiveSports[x];

            if (sportObject) {
                var ranking = getRanking(x);

                if (sportObject.sport_type1 === sportTypeName) {
                    scoreCount1 += 1;
                    scoreRanking1 += ((x+1) * 1);
                }
                if (sportObject.sport_type2 === sportTypeName) {
                    scoreCount2 += 1;
                    scoreRanking2 += ((x+1) * 1.2);
                }
            }
        }

        scoreAverage1 = (scoreRanking1 / scoreCount1);
        if (isNaN(scoreAverage1)) {
            scoreAverage1 = 0;
        }
        //scoreFinal1 = (scoreAverage1 * scoreCount1);

        scoreAverage2 = (scoreRanking2 / scoreCount2);
        if (isNaN(scoreAverage2)) {
            scoreAverage2 = 0;
        }
        //scoreFinal2 = (scoreAverage2 * scoreCount2 * 1.2);

        totalScore = (scoreAverage1 + scoreAverage2);

        return totalScore;
    }

    // Work out Ranking of each sport type
    const getSportRankings = () => {
        var arrayOfCategories = [
            {
                name: "powerScore",
                value: 0
            },
            {
                name: "skillScore",
                value: 0
            },
            {
                name: "strengthScore",
                value: 0
            },
            {
                name: "aerobicFitnessScore",
                value: 0
            },
            {
                name: "gameFitnessScore",
                value: 0
            },
            {
                name: "speedScore",
                value: 0
            }
        ];

        for (var i = 0; i < arrayOfCategories.length; i++) {

            var categoryObject = arrayOfCategories[i];

            var finalScoreForCategory = getScoreRankingForSportType(categoryObject.name);

            if (categoryObject.name === "powerScore") {
                setPowerSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "skillScore") {
                setSkillSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "aerobicFitnessScore") {
                setAerobicSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "strengthScore") {
                setStrengthSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "gameFitnessScore") {
                setGameFitnessSportTypeRank(finalScoreForCategory);
            }
            if (categoryObject.name === "speedScore") {
                setSpeedSportTypeRank(finalScoreForCategory);
            }
        }
    }

    const calculateSprintSpeed = (sprintDistance, sprintTime) => {
        if (sprintDistance !== '' && sprintDistance !== 0 && sprintTime !== '' && sprintTime !== 0) {

            if(usingImperial) sprintDistance = (sprintDistance * 3);

            if(usingImperial) sprintDistance = IM(sprintDistance).from("ft").to("m");

            var averageSpeed = (parseFloat(sprintDistance) / (parseFloat(sprintTime))) * 60 * 60 / 1000;

            var metersPerMinute = (parseFloat(sprintDistance) * (60 / parseFloat(sprintTime)));
            var kilometersPerHour = parseFloat((metersPerMinute * 60) / 1000).toFixed(1);
            var topSpeedPercentage = ((-0.000007 * Math.pow(sprintDistance, 4)) + (0.001809 * Math.pow(sprintDistance, 3)) - (0.171475 * Math.pow(sprintDistance, 2)) + (7.07494368 * sprintDistance) - 8 - (0.028 * sprintDistance));

            var topSpeed = (averageSpeed / topSpeedPercentage) * 100;

            if(usingImperial) {
                topSpeed = parseFloat((topSpeed / 1.609)).toFixed(2);
            } else {
                topSpeed = parseFloat(topSpeed).toFixed(1);
            }

            return topSpeed;
        } else {
            return "";
        }
    }

    const myResultsHeaderRef = React.createRef();
    const [adultHeightScore, setAdultHeightScore] = useState(0);
    const [adultWeightScore, setAdultWeightScore] = useState(0);
    const [adultVerticalJumpScore, setAdultVerticalJumpScore] = useState(0);
    const [adultStandingBroadJumpScore, setAdultStandingBroadJumpScore] = useState(0);
    const [adultSitAndReachScore, setAdultSitAndReachScore] = useState(0);
    const [adultSprintSpeedScore, setAdultSprintSpeedScore] = useState(0);
    const [adultInlinedPullUpsScore, setAdultInclinedPullUpsScore] = useState(0);
    const [adultShuttleRunScore, setAdultShuttleRunScore] = useState(0);
    const [adultGripStrenthScore, setAdultGripStrengthScore] = useState(0);
    const [adultWalkRunScore, setAdultWalkRunScore] = useState(0);
    const [adultSitUpsScore, setAdultSitUpsScore] = useState(0);
    const [adultShuttleBeepTestScore, setAdultShuttleBeepTestScore] = useState(0);
    const [showAdultValues, setShowAdultValues] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [armspanLocal, setArmspanLocal] = useState(0);
    const [sittingHeightLocal, setSittingHeightLocal] = useState(0);
    const [forearmLengthLocal, setForearmLengthLocal] = useState(0);

    const [topFiveSports, setTopFiveSports] = useState([]);

    // Setting user defined values (not Adult)
    var userHeightScore = reduxStateGlobal.myDetails.height !== "" ? reduxStateGlobal.myDetails.height : "";
    var userWeightScore = reduxStateGlobal.myDetails.weight !== "" ? reduxStateGlobal.myDetails.weight : "";
    var userVerticalJumpScore = reduxStateGlobal.myDetails.verticalJump !== "" ? reduxStateGlobal.myDetails.verticalJump : "";
    var userStandingBroadJumpScore = reduxStateGlobal.myDetails.standingBroadJump !== "" ? reduxStateGlobal.myDetails.standingBroadJump : "";
    var userSitAndReachScore = reduxStateGlobal.myDetails.sitAndReach !== "" ? reduxStateGlobal.myDetails.sitAndReach : "";
    var userSprintSpeedScore = calculateSprintSpeed(reduxStateGlobal.myDetails.sprintDistance, reduxStateGlobal.myDetails.sprintTime);
    var userInclinedPullUpsScore = reduxStateGlobal.myDetails.inclinedPullUps !== "" ? reduxStateGlobal.myDetails.inclinedPullUps : "";
    var userShuttleRunScore = reduxStateGlobal.myDetails.shuttleRun !== "" ? reduxStateGlobal.myDetails.shuttleRun : "";

    // Working out Gripstrength average
    var userGripStrengthLeftScore = reduxStateGlobal.myDetails.leftGripStrength !== "" ? reduxStateGlobal.myDetails.leftGripStrength : "0";
    var userGripStrengthRightScore = reduxStateGlobal.myDetails.rightGripStrength !== "" ? reduxStateGlobal.myDetails.rightGripStrength : "0";

    const getAverageGripStrength = () => {
        var gripStrengthLeftLocal = userGripStrengthLeftScore;
        var gripStrengthRightLocal = userGripStrengthRightScore;

        var hasGripStrengthLeft = (gripStrengthLeftLocal !== '' && gripStrengthLeftLocal !== '0');
        var hasGripStrengthRight = (gripStrengthRightLocal !== '' && gripStrengthRightLocal !== '0');

        if (hasGripStrengthLeft && !hasGripStrengthRight) {
            gripStrengthRightLocal = gripStrengthLeftLocal;
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (hasGripStrengthRight && !hasGripStrengthLeft) {
            gripStrengthLeftLocal = gripStrengthRightLocal;
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else if (hasGripStrengthLeft && hasGripStrengthRight) {
            return (parseFloat(gripStrengthLeftLocal) + parseFloat(gripStrengthRightLocal)) / 2;
        } else {
            return 0;
        }
    }

    var userGripStrengthScore = getAverageGripStrength();
    var userSitUpsScore = reduxStateGlobal.myDetails.sitUps !== "" ? reduxStateGlobal.myDetails.sitUps : "";
    var userShuttleBeepTestScore = reduxStateGlobal.myDetails.vo2Max !== "" ? reduxStateGlobal.myDetails.vo2Max : "";

    // Hover over status for each user test
    const [userHeightThumbHoverOver, setUserHeightThumbHoverOver] = useState(false);
    const [userWeightThumbHoverOver, setUserWeightThumbHoverOver] = useState(false);
    const [userVerticalJumpThumbHoverOver, setUserVerticalJumpThumbHoverOver] = useState(false);
    const [userStandingBroadJumpThumbHoverOver, setUserStandingBroadJumpThumbHoverOver] = useState(false);
    const [userSitAndReachThumbHoverOver, setUserSitAndReachThumbHoverOver] = useState(false);
    const [userSprintSpeedThumbHoverOver, setUserSprintSpeedThumbHoverOver] = useState(false);
    const [userInclinedPullUpsThumbHoverOver, setUserInclinedPullUpsThumbHoverOver] = useState(false);
    const [userShuttleRunThumbHoverOver, setUserShuttleRunThumbHoverOver] = useState(false);
    const [userGripStrengthThumbHoverOver, setUserGripStrengthThumbHoverOver] = useState(false);
    const [userSitUpsThumbHoverOver, setUserSitUpsThumbHoverOver] = useState(false);
    const [userRunWalkThumbHoverOver, setUserRunWalkThumbHoverOver] = useState(false);
    const [userShuttleBeepTestThumbHoverOver, setUserShuttleBeepTestThumbHoverOver] = useState(false);

    // State variables relating to timed based variables
    const [sprintSpeedUseDisplayedValue, setSprintSpeedUseDisplayedValue] = useState(false);
    const [shuttleRunUseDisplayedValue, setShuttleRunUseDisplayedValue] = useState(false);
    const [walkRunUseDisplayedValue, setWalkRunUseDisplayedValue] = useState(false);
    const [vO2MaxUseDisplayedValue, setVO2MaxUseDisplayedValue] = useState(false);

    const whatIfClickHandler = () => {
        var urlParameters = "";
        setWhatIfButtonLoading(true);
        
        urlParameters += ("height=" + (usingImperial ? IM(adultHeightScore).from('in').to('cm') : adultHeightScore) + "&");
        urlParameters += ("weight=" + (usingImperial ? IM(adultWeightScore).from('lb').to('kg') : adultWeightScore) + "&");
        urlParameters += ("verticalJump=" + (usingImperial ? IM(adultVerticalJumpScore).from('in').to('cm') : adultVerticalJumpScore) + "&");
        urlParameters += ("standingBroadJump=" + (usingImperial ? IM(adultStandingBroadJumpScore).from('in').to('cm') : adultStandingBroadJumpScore) + "&");
        urlParameters += ("sitAndReach=" + (usingImperial ? IM(adultSitAndReachScore).from('in').to('cm') : adultSitAndReachScore) + "&");
        urlParameters += ("sprintSpeed=" + (sprintSpeedUseDisplayedValue ? (usingImperial ? IM(adultSprintSpeedScore).from('m/h').to('km/h') : adultSprintSpeedScore) : 0) + "&");
        urlParameters += ("inclinedPullUps=" + adultInlinedPullUpsScore + "&");
        urlParameters += ("shuttleRun=" + (shuttleRunUseDisplayedValue ? adultShuttleRunScore : 0) + "&");
        urlParameters += ("gripStrength=" + (usingImperial ? IM(adultGripStrenthScore).from('lb').to('kg') : adultGripStrenthScore) + "&");
        urlParameters += ("walkRun=" + (walkRunUseDisplayedValue ? adultWalkRunScore : 0) + "&");
        urlParameters += ("sitUps=" + adultSitUpsScore + "&");
        urlParameters += ("shuttleBeepTest=" + (vO2MaxUseDisplayedValue ? adultShuttleBeepTestScore : 0) + "&");
        urlParameters += ("armSpan=" + armspanLocal + "&");
        urlParameters += ("sittingHeight=" + sittingHeightLocal + "&");
        urlParameters += ("forearmLength=" + forearmLengthLocal + "&");
        urlParameters += "sudoTop5Sports=true";

        // Get top 5 sports from backend
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/WhatIfServlet?predicted_adult_fitness_id=${reduxState.predictedAdultFitnessId}&${urlParameters}&using_org_sports=true${customSelectedSports.length != 0 ? "&using_sports=" + customSelectedSports : ""}`, {
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include"
        }).then(async function (res) {
            res.json().then(function (data) {
                setBarsUpdates(false);
                var sudoArray = [];

                for (var i = 0; i <= data.length; i++) {
                    sudoArray.push(data[i]);
                }

                setWhatIfButtonLoading(false);
                setTopFiveSports(sudoArray);
            }).catch((error) => {
                console.log("An error has occured within the internal server");
                addToast("Unable to access 'Top 5 Sports' - internal server error", {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
        });

    }

    const setBodySizeAndShapeArmSpan = (num) => {
        setArmspanLocal(num);
    }

    const setBodyShapeAndSizeSittingHeight = (num) => {
        setSittingHeightLocal(num);
    }

    const setBodyShapeAndSizeForearmLength = (num) => {
        setForearmLengthLocal(num);
    }

    const renderMainComponent = () => {
        if (showBodySizeAndShapeSection) {
            return <BodySizeAndShapeComponent
                resetBackToDefaultValues={loadInitialValues}
                armSpan={armspanLocal}
                forearmLength={forearmLengthLocal}
                sittingHeight={sittingHeightLocal}
                setArmSpan={setBodySizeAndShapeArmSpan}
                setFormarmLength={setBodyShapeAndSizeForearmLength}
                setSittingHeight={setBodyShapeAndSizeSittingHeight}
                backClickHandler={backArrowClickHandler}
                onChangeHandler={() => AdultChangeHandler(1, 2)}
            />
        } else {
            return (
                <div className="screen-section my-results-section" style={{ height: '440px' }}>
                    {/** Header of "My Results" section */}
                    <div className="my-results-section-header">
                        <span className="my-results-span">My results</span>

                        {/** Show adult value section */}
                        <div onClick={() => { setShowAdultValues(!showAdultValues) }} className="adult-values-container">
                            <div className="show-adult-values-radiobutton" style={{border: organisationLocalState.organisationPrimaryColor != '' ? '3px solid ' + organisationLocalState.organisationPrimaryColor : '3px solid #9A62B3'}}>
                                <div className={"show-adult-values-radiobutton-inner" + (!showAdultValues ? " hide" : "")} style={{background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9A62B3'}}></div>
                            </div>
                            <span className="show-adult-value-span">Show adult values</span>
                        </div>

                        <Button 
                            onClick={() => {
                                setResetLoading(true);
                                loadInitialValues();
                            }} 
                            className="reset-button" 
                            variant={'contained'} 
                            style={{background: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : ''}}
                        >
                            Reset {resetLoading && <CircularProgress disableShrink size={20} />}
                        </Button>
                    </div>

                    {/** Content of "My Results" section */}
                    <div className="my-results-section-body">
                        {/** Row for top square elements */}
                        <div className="my-results-section-body-row-top" ref={myResultsHeaderRef}>
                            <MyResultBox showAdultValues={showAdultValues} sportName="Height" uom={usingImperial ? 'in' : 'cm'} studentScore={userHeightScore} adultScore={adultHeightScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName="Weight" uom={usingImperial ? 'lb' : 'kg'} studentScore={userWeightScore} adultScore={adultWeightScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName={usingImperial ? "Sit & reach (in)" : "Sit & reach (cm)"} uom="" studentScore={userSitAndReachScore} adultScore={adultSitAndReachScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName={usingImperial ? "Standing broad jump (in)" : "Standing broad jump (cm)"} uom="" studentScore={userStandingBroadJumpScore} adultScore={adultStandingBroadJumpScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName={usingImperial ? "Grip strength (lb)" : "Grip strength (kg)"} uom="" studentScore={userGripStrengthScore} adultScore={adultGripStrenthScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName={usingImperial ? "Vertical jump (ft)" : "Vertical jump (cm)"} uom="" studentScore={userVerticalJumpScore} adultScore={adultVerticalJumpScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName="Bent-knee sit-ups" uom="" studentScore={userSitUpsScore} adultScore={adultSitUpsScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName="Inclined pull&#8209;ups" uom="" studentScore={userInclinedPullUpsScore} adultScore={adultInlinedPullUpsScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName={usingImperial ? "4 x 11 yd shuttle run (s)" : "4 x 10 m shuttle run (s)"} uom="" studentScore={userShuttleRunScore} adultScore={adultShuttleRunScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName={usingImperial ? "Maximum sprint speed (mph)" : "Maximum sprint speed (km/h)"} uom="" studentScore={userSprintSpeedScore} adultScore={adultSprintSpeedScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName={usingImperial ? "1 mile run/walk (min:s)" : "1.6 km run/walk (min:s)"} uom="" studentScore={userRunWalkSpeedScore} adultScore={adultWalkRunScore} />
                            <MyResultBox showAdultValues={showAdultValues} sportName={"Aerobic fitness (mL/kg/min)"} uom="" studentScore={userShuttleBeepTestScore} adultScore={adultShuttleBeepTestScore} />
                        </div>
                        {/** Row for bars below square elements */}
                        <div className="my-results-section-body-row-bottom" onScroll={resultsBodyScrollHandler}>
                            <FindMySportBars
                                userScore={userHeightScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultHeightScore}
                                userThumbHoverOver={userHeightThumbHoverOver}
                                setAdultScore={setAdultHeightScore}
                                setUserThumbHoverOver={setUserHeightThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={usingImperial ? IM(100).from('cm').to('in') : 100}
                                maximum={usingImperial ? IM(220).from('cm').to('in') : 220}
                                isTimedBased={false}
                                isHeightMesurement
                                onMouseUpHandler={() => AdultChangeHandler(adultHeightScore, reduxState.adultHeight)}
                            />
                            <FindMySportBars
                                userScore={userWeightScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultWeightScore}
                                userThumbHoverOver={userWeightThumbHoverOver}
                                setAdultScore={setAdultWeightScore}
                                setUserThumbHoverOver={setUserWeightThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={usingImperial ? IM(20).from('kg').to('lb') : 20}
                                maximum={usingImperial ? IM(150).from('kg').to('lb') : 150}
                                isTimedBased={false}
                                onMouseUpHandler={() => AdultChangeHandler(adultWeightScore, reduxState.adultWeight)}
                            />
                            <FindMySportBars
                                userScore={userSitAndReachScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultSitAndReachScore}
                                userThumbHoverOver={userSitAndReachThumbHoverOver}
                                setAdultScore={setAdultSitAndReachScore}
                                setUserThumbHoverOver={setUserSitAndReachThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={0}
                                maximum={usingImperial ? IM(60).from('cm').to('in') : 60}
                                isTimedBased={false}
                                onMouseUpHandler={() => AdultChangeHandler(adultSitAndReachScore, reduxState.adultSitAndReach)}
                            />
                            <FindMySportBars
                                userScore={userStandingBroadJumpScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultStandingBroadJumpScore}
                                userThumbHoverOver={userStandingBroadJumpThumbHoverOver}
                                setAdultScore={setAdultStandingBroadJumpScore}
                                setUserThumbHoverOver={setUserStandingBroadJumpThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={0}
                                maximum={usingImperial ? IM(320).from('cm').to('in') : 320}
                                isTimedBased={false}
                                isHeightMesurement
                                step={"1"}
                                onMouseUpHandler={() => AdultChangeHandler(adultStandingBroadJumpScore, reduxState.adultStandingBroadJump)}
                            />
                            <FindMySportBars
                                userScore={userGripStrengthScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultGripStrenthScore}
                                userThumbHoverOver={userGripStrengthThumbHoverOver}
                                setAdultScore={setAdultGripStrengthScore}
                                setUserThumbHoverOver={setUserGripStrengthThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={0}
                                maximum={usingImperial ? IM(90).from('kg').to('lb') : 90}
                                isTimedBased={false}
                                onMouseUpHandler={() => AdultChangeHandler(adultGripStrenthScore, reduxState.adultGripStrength)}
                            />
                            <FindMySportBars
                                userScore={userVerticalJumpScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultVerticalJumpScore}
                                userThumbHoverOver={userVerticalJumpThumbHoverOver}
                                setAdultScore={setAdultVerticalJumpScore}
                                setUserThumbHoverOver={setUserVerticalJumpThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={0}
                                maximum={usingImperial ? IM(110).from('cm').to('in') : 110}
                                isTimedBased={false}
                                onMouseUpHandler={() => AdultChangeHandler(adultVerticalJumpScore, reduxState.adultVerticalJump)}
                            />
                            <FindMySportBars
                                userScore={userSitUpsScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultSitUpsScore}
                                userThumbHoverOver={userSitUpsThumbHoverOver}
                                setAdultScore={setAdultSitUpsScore}
                                setUserThumbHoverOver={setUserSitUpsThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={0}
                                maximum={100}
                                isTimedBased={false}
                                step={"1"}
                                onMouseUpHandler={() => AdultChangeHandler(adultSitUpsScore, reduxState.adultSitUps)}
                            />
                            <FindMySportBars
                                userScore={userInclinedPullUpsScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultInlinedPullUpsScore}
                                userThumbHoverOver={userInclinedPullUpsThumbHoverOver}
                                setAdultScore={setAdultInclinedPullUpsScore}
                                setUserThumbHoverOver={setUserInclinedPullUpsThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={0}
                                maximum={50}
                                isTimedBased={false}
                                step={"1"}
                                onMouseUpHandler={() => AdultChangeHandler(adultInlinedPullUpsScore, reduxState.adultInclinedPullUps)}
                            />
                            <FindMySportBars
                                userScore={userShuttleRunScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultShuttleRunScore}
                                userThumbHoverOver={userShuttleRunThumbHoverOver}
                                setAdultScore={setAdultShuttleRunScore}
                                setUserThumbHoverOver={setUserShuttleRunThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={7}
                                maximum={20}
                                isTimedBased={true}
                                step={"0.1"}
                                onMouseUpHandler={() => AdultChangeHandler(adultShuttleRunScore, reduxState.adultShuttleRun)}
                            />
                            <FindMySportBars
                                userScore={userSprintSpeedScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultSprintSpeedScore}
                                userThumbHoverOver={userSprintSpeedThumbHoverOver}
                                setAdultScore={setAdultSprintSpeedScore}
                                setUserThumbHoverOver={setUserSprintSpeedThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={usingImperial ? IM(2).from('km/h').to('m/h') : 2}
                                maximum={usingImperial ? IM(40).from('km/h').to('m/h') : 40}
                                isTimedBased={false}
                                onMouseUpHandler={() => AdultChangeHandler(adultSprintSpeedScore, reduxState.adult50mSprint)}
                            />
                            <FindMySportBars
                                userScore={userRunWalkSpeedScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultWalkRunScore}
                                userThumbHoverOver={userRunWalkThumbHoverOver}
                                setAdultScore={setAdultWalkRunScore}
                                setUserThumbHoverOver={setUserRunWalkThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={240}
                                maximum={1200}
                                isTimedBased={true}
                                minuteSecondsFormatter={formatToMinutesSeconds}
                                onMouseUpHandler={() => AdultChangeHandler(adultWalkRunScore, reduxState.adultWalkRun)}
                            />
                            <FindMySportBars
                                userScore={userShuttleBeepTestScore}
                                showAdultValues={showAdultValues}
                                adultScore={adultShuttleBeepTestScore}
                                userThumbHoverOver={userShuttleBeepTestThumbHoverOver}
                                setAdultScore={setAdultShuttleBeepTestScore}
                                setUserThumbHoverOver={setUserShuttleBeepTestThumbHoverOver}
                                calculatePercentage={calculatePercentage}
                                minimum={20}
                                maximum={90}
                                isTimedBased={false}
                                onMouseUpHandler={() => AdultChangeHandler(adultShuttleBeepTestScore, reduxState.adultVo2Max)}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }
    const AdultChangeHandler = (a, b) => {
        if (a !== b) {
            setBarsUpdates(true);
        }
    }

    useEffect(() => {
        loadInitialValues();
    }, []);

    /**
     * This function checks all timed values, and determines whether we want to send the actual value, or 0 for the backend.
     * We peform this check as we have the displayed values being the values read by the backend - in theroy we should be using
     * a 'fake' (visible) vlaue shown to the client, but this is a much faster approach - and keeps the code consistent (i.e. not
     * having to make a 'fake' value for each of the variables here).
     */
    const checkTimedValues = () => {
        if(reduxState.adult50mSprint != 0) setSprintSpeedUseDisplayedValue(true);
        if(reduxState.adultShuttleRun != 0) setShuttleRunUseDisplayedValue(true);
        if(reduxState.adultWalkRun != 0) setWalkRunUseDisplayedValue(true);
        if(reduxState.adultVo2Max != 0) setVO2MaxUseDisplayedValue(true);
    }

    const loadInitialValues = () => {
        checkTimedValues();
        setAdultHeightScore(reduxState.adultHeight);
        setAdultWeightScore(reduxState.adultWeight);
        setAdultVerticalJumpScore(reduxState.adultVerticalJump);
        setAdultStandingBroadJumpScore(reduxState.adultStandingBroadJump);
        setAdultSitAndReachScore(reduxState.adultSitAndReach);
        setAdultSprintSpeedScore(reduxState.adult50mSprint != 0 ? reduxState.adult50mSprint : 2);
        setAdultInclinedPullUpsScore(reduxState.adultInclinedPullUps);
        setAdultShuttleRunScore(reduxState.adultShuttleRun != 0 ? reduxState.adultShuttleRun : 20);
        setAdultGripStrengthScore(reduxState.adultGripStrength);
        setAdultWalkRunScore(reduxState.adultWalkRun != 0 ? reduxState.adultWalkRun : 1200);
        setAdultSitUpsScore(reduxState.adultSitUps);
        setAdultShuttleBeepTestScore(reduxState.adultVo2Max != 0 ? reduxState.adultVo2Max : 20);

        setCustomSelectedSports([]);
        setCustomUnselectedSports([]);

        setArmspanLocal(reduxState.adultArmSpan);
        setSittingHeightLocal(reduxState.adultSittingHeight);
        setForearmLengthLocal(reduxState.adultForearmLength);

        // Get top 5 sports from backend
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/FindMySport?predicted_adult_fitness_id=${reduxState.predictedAdultFitnessId}&using_org_sports=true`, {
            headers: {
                'Accept': 'application/json'
            },
            credentials: "include"
        }).then(async function (res) {
            res.json().then(function (data) {
                var sudoArray = [];

                for (var i = 0; i <= data.length; i++) {
                    sudoArray.push(data[i]);
                }
                setTopFiveSports(sudoArray);
                setResetLoading(false);

            }).catch((error) => {
                console.log("Error = ", error);
                setResetLoading(false);
                addToast("Unable to access 'Top 5 Sports' - internal server error", {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
        });
    }

    useEffect(() => {
        getSportRankings();
    }, [topFiveSports]);

    const formatToMinutesSeconds = input => {
        var minutes, seconds;

        if (input >= 60) {
            minutes = parseFloat(input) / 60;
            seconds = parseFloat(input) % 60;
        } else {
            minutes = 0;
            seconds = input;
        }

        if (minutes < 10) {
            minutes = ("0" + minutes);
        }

        minutes += "";

        if (seconds < 10) {
            seconds = ("0" + seconds);
        }

        seconds += "";

        if (seconds.indexOf(".") !== -1) {
            seconds = seconds.split(".")[0];
        }

        return minutes.split(".")[0] + ":" + seconds;
    }

    const renderTopFiveSports = () => {
        const images = require.context('../../../images', true);
        var image;
        var domElement = [];

        if (topFiveSports.length !== 0) {
            for (var i = 0; i < 5; i++) {
                if (topFiveSports[i]) {
                    try {
                        image = images("./" + topFiveSports[i].image_path);
                    } catch (err) {
                        console.log("Error = ", err);
                    }

                    domElement.push(
                        <div key={topFiveSports[i].sport} className="top-5-sport-division">
                            <div className={"image" + (i === 0 ? ' first' : '')}>
                                <img src={image} style={{ width: i === 0 ? '120px' : '100px', height: i === 0 ? '120px' : '100px', borderRadius: '50%' }} />
                            </div>
                            <span>{i + 1}. {topFiveSports[i].sport}</span>
                        </div>
                    )
                }
            }
        }

        return domElement;
    }

    const resultsBodyScrollHandler = (e) => {
        myResultsHeaderRef.current.scrollLeft = e.target.scrollLeft;
    }

    const calculatePercentage = (min, num1, num2) => {
        num1 = num1 - min;
        num2 = num2 - min;

        return (num1 / num2) * 100;
    };

    return (
        <React.Fragment>
            <SwitchTransition>
                <CSSTransition
                    key={trans}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                    classNames="fade"
                >
                    {renderMainComponent()}

                </CSSTransition>
            </SwitchTransition>

            <div style={{ display: showBodySizeAndShapeSection ? 'none' : 'flex' }} className="right-arrow-section" onClick={() => bodySizeAndShapeArrowClickHandler()}>
                <span style={{ color: organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '#9a62b3'}}>
                    &gt;
                </span>
            </div>

            <div className="top-5-sports-bottom-section">
                <div className="screen-section my-results-section top-5-sport">
                    <div className="top-5-sports-heading">
                        <span>Top 5 sports</span>
                            <div>
                                <button 
                                    className="sportmatch-button change-sport-selection-btn" 
                                    style={{ 
                                        background: (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '')
                                    }} onClick={() => setShowSportSelectionPopup(true)}>Change sport selections</button>
                                <button 
                                    className="whatif-btn" 
                                    style={{ background: barsUpdated === true ? (organisationLocalState.organisationAccentColor != '' ? organisationLocalState.organisationAccentColor : '#50B383') : (organisationLocalState.organisationPrimaryColor != '' ? organisationLocalState.organisationPrimaryColor : '') }} 
                                    onClick={() => whatIfClickHandler()}
                                >What if..? {whatIfButtonLoading && <CircularProgress disableShrink size={20} />}</button>
                            </div>
                        </div>
                    <div className="top-5-sports-container">
                        {renderTopFiveSports()}
                    </div>
                </div>

                <SportTypes
                    topFiveSports={topFiveSports}
                    powerSportType={powerSportTypeRank}
                    speedSportType={speedSportTypeRank}
                    gameFitnessSportType={gameFitnessSportTypeRank}
                    strengthSportType={strengthSportTypeRank}
                    skillSportType={skillSportTypeRank}
                    aerobicFitnessSportType={aerobicSportTypeRank}
                />
            </div>

            <div className="sport-selection-component-popup" style={{display: showSportSelectionPopup ? 'flex' : 'none'}}>
                <SportSelectionComponent 
                    studentSelector={true}
                    show={showSportSelectionPopup} 
                    customSelectedSports={customSelectedSports}
                    customUnselectedSports={customUnselectedSports}
                    setCustomSelectedSports={setCustomSelectedSports}
                    saveHandler={(sports) => {
                        setCustomSelectedSports(sports);
                    }}
                    closeHandler={() => setShowSportSelectionPopup(false)}
                />
                <div id="overlay"></div>
            </div>

        </React.Fragment>

    );
}

export default FindMySport;
