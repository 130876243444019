// REACT
import React, { useState, useEffect } from 'react';

// Images
import DownloadImage from '../../../../assets/download.png';
import UpImage from '../../../../assets/workbook_resources/up.png';
import DownImage from '../../../../assets/workbook_resources/down.png';

import DownloadSVG from '../../../../assets/svg/downloadSvg.js';
import UpArrowSVG from '../../../../assets/svg/upArrowSvg.js';
import DownArrowSVG from '../../../../assets/svg/downArrowSvg.js';

// PDF Resource
import all_junior_workbooks from '../../../../assets/workbook_resources/all_junior_workbooks.pdf';
import junior_workbook_1 from '../../../../assets/workbook_resources/1_junior_workbook.pdf';
import junior_workbook_2 from '../../../../assets/workbook_resources/2_junior_workbook.pdf';
import junior_workbook_3 from '../../../../assets/workbook_resources/3_junior_workbook.pdf';
import junior_workbook_4 from '../../../../assets/workbook_resources/4_junior_workbook.pdf';
import junior_workbook_answers from '../../../../assets/workbook_resources/junior_workbook_answers.pdf';

import all_middle_workbooks from '../../../../assets/workbook_resources/all_middle_workbooks.pdf';
import middle_workbook_1 from '../../../../assets/workbook_resources/1_middle_workbook.pdf';
import middle_workbook_2 from '../../../../assets/workbook_resources/2_middle_workbook.pdf';
import middle_workbook_3 from '../../../../assets/workbook_resources/3_middle_workbook.pdf';
import middle_workbook_4 from '../../../../assets/workbook_resources/4_middle_workbook.pdf';
import middle_quizzes from '../../../../assets/workbook_resources/middle_quizzes.pdf';
import middle_workbook_answers from '../../../../assets/workbook_resources/middle_workbook_answers.pdf';

import all_senior_workbooks from '../../../../assets/workbook_resources/all_senior_workbooks.pdf';
import senior_workbook_1 from '../../../../assets/workbook_resources/1_senior_workbook.pdf';
import senior_workbook_2 from '../../../../assets/workbook_resources/2_senior_workbook.pdf';
import senior_workbook_3 from '../../../../assets/workbook_resources/3_senior_workbook.pdf';
import senior_workbook_4 from '../../../../assets/workbook_resources/4_senior_workbook.pdf';

import protocols from '../../../../assets/workbook_resources/protocols.pdf';
import at_home_test_protocols from '../../../../assets/workbook_resources/at_home_protocols.pdf';

import './style.css';
import Button from "@material-ui/core/Button/Button";
import { BACKEND_URL, ENVIRONMENT_PATH, MARKETING_DOMAIN } from '../../../../store/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Dialog, CardContent, Slide, makeStyles } from '@material-ui/core';
import { CheckIfUserIsLoggedIn } from '../../../../api/Helper';



const TeacherResources = () => {
    /** Required to show the dialog appearing from the bottom of the screen */
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const dispatch = useDispatch();

    const [showPrimaryWorkBook, setShowPrimaryWorkBook] = useState(false);
    const [showSeniorWorkBook, setShowSeniorWorkBook] = useState(false);
    const [showMiddleWorkBook, setShowMiddleWorkBook] = useState(false);
    const [showTestingProtocols, setShowTestingProtocols] = useState(false);

    const [showUnavailablePackageDialog, setShowUnavailablePackageDialog] = useState(false);

    const organisationState = useSelector(state => state.organisationState);

    const renderPrimaryWorkBookIcon = () => {
        if (!showPrimaryWorkBook) {
            return (
                <DownArrowSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
            )
        } else {
            return (
                <UpArrowSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
            )
        }
    }

    // Determine if the user is logged in on the backend
    useEffect(() => {
        fetch(`${BACKEND_URL + ENVIRONMENT_PATH}/CheckSessionIsValid`, {
            credentials: 'include'
        }).then(res => {
            CheckIfUserIsLoggedIn(res, dispatch);
        }).catch(() => {})
    }, []);

    const renderSeniorWorkBookIcon = () => {
        if (!showSeniorWorkBook) {
            return (
                <DownArrowSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
            )
        } else {
            return (
                <UpArrowSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
            )
        }
    }

    const renderMiddleWorkBookIcon = () => {
        if (!showMiddleWorkBook) {
            return (
                <DownArrowSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
            )
        } else {
            return (
                <UpArrowSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
            )
        }
    }

    const renderTestingProtocolsIcon = () => {
        if (!showTestingProtocols) {
            return (
                <DownArrowSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
            )
        } else {
            return (
                <UpArrowSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
            )
        }
    }

    const useStyles = makeStyles({
        root: {
            height: 'initial',
        }
    });

    const classes = useStyles();

    const renderDialogPopup = () => {
        return (
            <Dialog
                open={showUnavailablePackageDialog}
                TransitionComponent={Transition}
                onClose={null}
            >
                <Card>
                    <CardContent className={classes.root}>
                        <div className="card-body">
                            <h3>Unavailable in package</h3>

                            <span>This feature is unavailable in this package - <a href={`${MARKETING_DOMAIN}`}>click here</a> to change package.</span>
                        </div>

                        <div className="card-button-container">
                            <Button variant="contained" onClick={() => setShowUnavailablePackageDialog(false)}>
                                Close
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Dialog>
        )
    }

    return (
        <div className="container teacher-resource">
            <div className='resource-box'>
                <div className="column">
                    <div className="resource">
                        <div className={"header"}>
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Primary school students</span>

                            <div onClick={(e) => { e.stopPropagation(); setShowPrimaryWorkBook(!showPrimaryWorkBook); }} className={showPrimaryWorkBook ? "collapse-arrow" : "collapse-arrow collapsed"}>
                                {renderPrimaryWorkBookIcon()}
                            </div>

                            <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : all_junior_workbooks} className={"resource-download" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")}>
                                <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                            </a>
                        </div>

                        <div className="resource sub-resource" style={{ display: showPrimaryWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 1 | Elite athletes and fitness</span>

                                <a target="_blank" href={junior_workbook_1}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className="resource sub-resource" style={{ display: showPrimaryWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 2 | My fitness</span>

                                <a target="_blank" href={junior_workbook_2}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className={"resource sub-resource" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")} style={{ display: showPrimaryWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 3 | My fitness progress</span>

                                <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : junior_workbook_3}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className={"resource sub-resource" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")} style={{ display: showPrimaryWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 4 | Can I be an elite athlete?</span>

                                <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : junior_workbook_4}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className="resource sub-resource" style={{ display: showPrimaryWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook Answers</span>

                                <a target="_blank" href={junior_workbook_answers}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="column">
                    <div className="resource">
                        <div className={"header"}>
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Middle school students</span>

                            <div onClick={(e) => { e.stopPropagation(); setShowMiddleWorkBook(!showMiddleWorkBook); }} className={showMiddleWorkBook ? "collapse-arrow" : "collapse-arrow collapsed"}>
                                {renderMiddleWorkBookIcon()}
                            </div>

                            <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : all_middle_workbooks} className={"resource-download" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")}>
                                <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                            </a>
                        </div>

                        <div className="resource sub-resource" style={{ display: showMiddleWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 1 | Elite athletes</span>

                                <a target="_blank" href={middle_workbook_1}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className="resource sub-resource" style={{ display: showMiddleWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 2 | Measuring elite athletes</span>

                                <a target="_blank" href={middle_workbook_2}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className={"resource sub-resource" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")} style={{ display: showMiddleWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 3 | Components of fitness</span>

                                <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : middle_workbook_3}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className={"resource sub-resource" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")} style={{ display: showMiddleWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 4 | Specificity of fitness</span>

                                <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : middle_workbook_4}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className="resource sub-resource" style={{ display: showMiddleWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Quizzes</span>

                                <a target="_blank" href={middle_quizzes}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className="resource sub-resource" style={{ display: showMiddleWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook Answers</span>

                                <a target="_blank" href={middle_workbook_answers}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="column">
                    <div className="resource">
                        <div className="header">
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Senior students</span>

                            <div onClick={(e) => { e.stopPropagation(); setShowSeniorWorkBook(!showSeniorWorkBook); }} className={showSeniorWorkBook ? "collapse-arrow" : "collapse-arrow collapsed"}>
                                {renderSeniorWorkBookIcon()}
                            </div>

                            <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : all_senior_workbooks} className={"resource-download" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")}>
                                <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                            </a>
                        </div>

                        <div className={"resource sub-resource" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")} style={{ display: showSeniorWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 1 | Fitness and energy system requirements for sports</span>

                                <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : senior_workbook_1}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className={"resource sub-resource" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")} style={{ display: showSeniorWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 2 | Identifying suitability to sports based on performance testing</span>

                                <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : senior_workbook_2}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className={"resource sub-resource" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")} style={{ display: showSeniorWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 3 | Talent identification in sport</span>

                                <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : senior_workbook_3}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                        <div className="resource sub-resource" style={{ display: showSeniorWorkBook ? "block" : "none" }}>
                            <div className="header">
                                <span>Workbook 4 | The relationships between anthropometry, biomechanics and sports</span>

                                <a target="_blank" href={senior_workbook_4}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="column">
                    <div className="resource">
                        <div className="header">
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>SportMatch fitness testing protocols</span>

                            <div onClick={(e) => { e.stopPropagation(); setShowTestingProtocols(!showTestingProtocols); }} className={showTestingProtocols ? "collapse-arrow" : "collapse-arrow collapsed"}>
                                {renderTestingProtocolsIcon()}
                            </div>

                            <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : protocols} className={"resource-download" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")}>
                                <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                            </a>
                        </div>

                        <div className={"resource sub-resource" + (organisationState.plan == "Starter" ? " unavailable-resource" : "")} style={{ display: showTestingProtocols ? "block" : "none" }}>
                            <div className="header">
                                <span>At home test protocols</span>

                                <a onClick={organisationState.plan == "Starter" ? () => setShowUnavailablePackageDialog(true) : null} target="_blank" href={organisationState.plan == "Starter" ? null : at_home_test_protocols}>
                                    <DownloadSVG color={organisationState.organisationPrimaryColor != '' ? organisationState.organisationPrimaryColor : '#9a62b3'}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showUnavailablePackageDialog ? renderDialogPopup() : null}
        </div>
    )
}

export default TeacherResources;
