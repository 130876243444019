import Axios from "axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { QASReportTestRequest, requestBackendQasReportData, SendEmail } from "../../../../api/endpoints";
import { SendGridEmailRequest } from "../../../../api/SendGrid/utilities";
import {
    armSpanImage,
    beepTestImage,
    heightImage,
    inclinedPullUpImage,
    shuttleRunImage,
    sittingHeightImage,
    sprintSpeedImage,
    verticalJumpImage,
    header1,
    header2,
    govLogo,
    sportMatch1Line,
    sportMatch2Lines
} from "./QasReportImages";
import { generateQasReportPDF } from "./QasReportPDF";

const cancelDownloadToken = Axios.CancelToken.source();

export interface GenerateQASReport {
    test: QASReportTestRequest;
    normSelected: string;
    usingImperial: boolean;
    downloadReport: boolean;
    sendAsEmail: boolean;
    cancelDownloadToken: any;
    refreshEmailLog: boolean;
}

export enum LoadingReportEnum {
    INITIALISE = 'INITIALISE',
    LOADING = 'LOADING',
    FINISHED_LOADING = 'FINISHED_LOADING'
}

type GenerateQASReportHook = {
    loadingReport: LoadingReportEnum,
    doGenerateQASReport: (param: GenerateQASReport) => Promise<void>
}

/**
 * useGenerateQASReport
 * 
 * Hook for settng up static resources, and generate PDF document
 */
export function useGenerateQASReport(): GenerateQASReportHook {
    const [loadingReport, setLoadingReport] = useState<LoadingReportEnum>(LoadingReportEnum.INITIALISE);
    const userState = useSelector(state => state) as any;

    const imageRefs = [
        heightImage,
        armSpanImage,
        sittingHeightImage,
        verticalJumpImage,
        inclinedPullUpImage,
        beepTestImage,
        shuttleRunImage,
        sprintSpeedImage
    ];

    const doGenerateQASReport = useCallback(async (param: GenerateQASReport): Promise<void> => {
        setLoadingReport(LoadingReportEnum.LOADING);
        const result = await requestBackendQasReportData(param.test, param.normSelected, param.usingImperial, param.cancelDownloadToken);

        const binaryQASReport = await generateQasReportPDF('#A5003B', result.username, result.dob, result.age.toString(), result.venue, result.testDate, param.downloadReport, [
            {
                'image': imageRefs[0],
                'data': result.height.value ?? '',
                'percentage': result.height.percentileRanking ?? 0.0
            },
            {
                'image': imageRefs[1],
                'data': result.arm_span.value ?? '',
                'percentage': result.arm_span.percentileRanking ?? 0.0
            },
            {
                'image': imageRefs[2],
                'data': result.sitting_height.value ?? '',
                'percentage': result.sitting_height.percentileRanking ?? 0.0
            }
        ], [
            {
                'image': imageRefs[3],
                'data': result.vertical_jump.value ?? '',
                'percentage': result.vertical_jump.percentileRanking ?? 0.0
            },
            {
                'image': imageRefs[4],
                'data': result.inclined_pull_ups.value ?? '',
                'percentage': result.inclined_pull_ups.percentileRanking ?? 0.0
            },
            {
                'image': imageRefs[5],
                'data': result.beep_test.value ?? '',
                'percentage': result.beep_test.percentileRanking ?? 0.0
            },
            // Change the agility
            // {
            //     'image': imageRefs[6],
            //     'data': result.shuttle_run.value ?? '',
            //     'percentage': result.shuttle_run.percentileRanking ?? 0.0
            // },
            {
                'image': imageRefs[7],
                'data': result.sprint.value ?? '',
                'percentage': result.sprint.percentileRanking ?? 0.0
            }
        ], header1, header2, govLogo, sportMatch1Line, sportMatch2Lines);

        if (param.sendAsEmail) {
            if (userState.user?.userEmail != null && userState.user?.userEmail !== '') {
                const sendEmailRequest: SendGridEmailRequest = {
                    emailTo: param.test.user_email ?? userState.user.userEmail,
                    emailAttachment: binaryQASReport,
                    refreshEmailLog: param.refreshEmailLog
                }

                await SendEmail(param.test.test_id, sendEmailRequest, cancelDownloadToken);
                setLoadingReport(LoadingReportEnum.FINISHED_LOADING);
            } else {
                setLoadingReport(LoadingReportEnum.FINISHED_LOADING);
            }
        } else {
            setLoadingReport(LoadingReportEnum.FINISHED_LOADING);
        }
    }, []);

    return {
        loadingReport,
        doGenerateQASReport
    }
}