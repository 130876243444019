import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

//ACTIONS
import { setUserId, setUserPassword } from '../../store/actions/actions.js';

import {
  BACKEND_URL,
  ENVIRONMENT_PATH
} from '../../store/constants';

const Logout = () => {
    const dispatch = useDispatch();

    dispatch(setUserId(""));

    useEffect(() => {
        fetch( BACKEND_URL + '/sportmatch-backend/Logout', {
            headers: {
              'Accept': 'application/json'
            },
            credentials: "include"
          }).then(async function(res) {
            res.json().then(function(data) {
                dispatch(setUserId(""));
                // setCookie("autoLogUsr", "");
                window.location.href = '/';
            })
          })
    }, []);

    function setCookie(name, value) {
      document.cookie = name +'='+ value +'; Path=/;';
    }

    return (
        <div>Logging out</div>
    )
}

export default Logout;